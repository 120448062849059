import React from 'react';
import {observer} from 'mobx-react';
import BaseEditableRow from "../base/BaseEditableRow";
import {computed} from "mobx";
import appState from "../../state/AppState";
import InfoBlock from "../../components/InfoBlock";
import translate from "../../translator/translate";
import BudgetModel from "../../models/BudgetModel";
import GqlErrors from "../../components/status/GqlErrors";
import WorkOrderModel from "../../models/WorkOrderModel";
import Select2Component from "../../components/fields/Select2Component";

@observer
class OrderFormBudgetRow extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });

    constructor(props) {
        super(props);
        this.foreingKeyfield = "orderId";
        this.state = {
            change: 0
        }
    }


    @computed get mobxListado() {
        return appState.budgetState.budget;
    }

    setMobxListado(newValue) {
        appState.budgetState.budget = newValue;
    }

    async componentDidMount() {
        super.componentDidMount();
    }


    getModelTable() {
        return new BudgetModel();
    }


    render() {
        const t = translate;
        let budget = this.props.row;
        let workOrderCode = appState.workOrderState.workOrders.map((workor) => {
            let workOrderFounded = new WorkOrderModel();
            if (workor.id == budget.workOrderId) {
                workOrderFounded = workor;
            }
            return workOrderFounded.code;
        });
        let options = [
            { label: "Borrador", value: BudgetModel.CODE_BORRADOR },
            { label: "Aprobado", value: BudgetModel.CODE_APPROVED },
            { label: "No aprobado", value: BudgetModel.CODE_NOT_APPROVED },
            { label: "Anulado", value: BudgetModel.CODE_CANCEL },
            { label: "Pendiente ERP", value: BudgetModel.CODE_PENDING_ERP },
        ];
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <tr>
                    <td scope="row">
                        {budget.code}
                    </td>
                    {this.props.fromOrder &&
                    <td scope="row">
                        {workOrderCode}
                    </td>
                    }
                    <td scope="row">
                        <Select2Component
                            value={budget.status}
                            showView={true}
                            name={"status"}
                            options={options}
                        />
                    </td>
                    <td scope="row">
                        {budget.comments}
                    </td>
                    {/*<td>*/}
                        {/*{budget.totalTime || 0}{' h'}*/}
                    {/*</td>*/}
                    <td>
                        {budget.quantityArticles || 0}
                    </td>
                    <td>
                        {budget.totalPrizeWithTax ? parseFloat(budget.totalPrizeWithTax).toFixed(2) + " €" :
                            budget.totalPrizeWithTax === 0 ? "-" : "-"}
                    </td>
                    <td>
                        {this.props.disabled ?
                            <div title={budget.editable ? "Editar" : "Ver"} className="edit inactive">
                                {budget.editable ? <span className="fas fa-edit" /> : <span className="fas fa-eye" />}
                            </div>
                            :
                            <div onClick={() => this.props.setEditable( budget)} title={budget.editable ? "Editar" : "Ver"} >
                                {budget.editable ? <span className="fas fa-edit" /> : <span className="fas fa-eye" />}
                            </div>

                        }
                    </td>
                </tr>
            </InfoBlock>
        );
    }
}

export default OrderFormBudgetRow
