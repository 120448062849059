import React from 'react';
import {observer} from 'mobx-react';
import GroupOfField from "../components/layout/GroupOfField";
import PageTitle from "../components/PageTitle"
import BaseForm from "./base/BaseForm";
import FormButtons from "../components/FormButtons";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import translate from "../translator/translate";
import PropsUtil from "../util/PropsUtil";
import TabsComponent from "../components/fields/TabsComponent";
import TabsData from "../layout/TabsData";
import OrderModel from "../models/OrderModel";
import WorkOrderModel from "../models/WorkOrderModel";
import ArticleModel from "../models/ArticleModel";
import ClientModel from "../models/ClientModel";
import ScheduleVisitModel from "../models/ScheduleVisitModel";
import AssetModel from "../models/AssetModel";
import util from "../util/Util";
import PageTitleRightModal from "../components/modals/PageTitleRightModal";
import InfoBlock from "../components/InfoBlock";
import UserModel from "../models/UserModel";
import appState from '../state/AppState';
import AssetModelModel from "../models/AssetModelModel";
import MoreButtons from "../components/MoreButtons";
import {toJS} from "mobx";
import GqlErrors from "../components/status/GqlErrors";
import VehicleModel from "../models/VehicleModel";
import TypifiedValueModel from "../models/TypifiedValueModel";
import SubcontractModel from "../models/SubcontractModel";
import SpinnerClipLoaderInPage from "../network/SpinnerClipLoaderInPage";
import OrderActions from "./orders/OrderActions";
import WorkOrderActions from "./workOrders/WorkOrderActions";
import AssetActions from "./assets/AssetActions";
import ClientActions from "./clients/ClientActions";
import AssetModelActions from "./assetModels/AssetModelActions";
import VehicleActions from "./vehicles/VehicleActions";
import UserActions from "./users/UserActions";
import UploadFileComponentSortable from "../components/UploadFileComponentSortable";
import ArticleActions from "./articles/ArticleActions";

@observer
class PageDocument extends BaseForm {
    nameMainType = this.props.model;

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            loading: true
        }
        if (this.nameMainType === "user") {
            this.tabsData.isClient = appState.userState.isUserRoleClient();
        }
    }

    getModelQuery() {
        let modelos = {
            "order": new OrderModel(),
            "workOrder": new WorkOrderModel(),
            "asset": new AssetModel(),
            "article": new ArticleModel(),
            "client": new ClientModel(),
            "clientPos": new ClientModel(),
            "subcontract": new SubcontractModel(),
            "vehicle": new VehicleModel(),
            "user": new UserModel(),
            "scheduleVisit": new ScheduleVisitModel(),
            "assetModel": new AssetModelModel(),
        };
        let result = modelos[this.nameMainType];
        result.addRelatedTable("documents");
        return result;
    }

    getMoreButtons() {
        let modelos = {
            "order": <OrderActions integracionId={this.graphDataMainType.id} model={this.graphDataMainType}
                                   getModelQuery={() => this.getModelQuery()}
                                   setErrors={(errors) => this.setErrors(errors)} />,
            "workOrder": <WorkOrderActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                           setErrors={(errors) => this.setErrors(errors)} />,
            "asset": <AssetActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                   setErrors={(errors) => this.setErrors(errors)} />,
            "client": <ClientActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                     setErrors={(errors) => this.setErrors(errors)} />,
            "clientPos": <ClientActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                        setErrors={(errors) => this.setErrors(errors)} />,
            "assetModel": <AssetModelActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                             setErrors={(errors) => this.setErrors(errors)} />,
            "vehicle": <VehicleActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                       setErrors={(errors) => this.setErrors(errors)} />,
            "user": <UserActions fromForm model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                 setErrors={(errors) => this.setErrors(errors)} />,
            "article": <ArticleActions fromForm model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                 setErrors={(errors) => this.setErrors(errors)} />,
        };
        return modelos[this.nameMainType];
    }

    async componentDidMount() {
        await super.componentDidMount();
        if (!this.tabsData.isClient && this.nameMainType === "user") {
            this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);
        }
        this.setState({ loading: false })
    }

    getModelQueryForView() {
        let modelos = {
            "order": new OrderModel(),
            "workOrder": new WorkOrderModel(),
            "asset": new AssetModel(),
            "article": new ArticleModel(),
            "subcontract": new SubcontractModel(),
            "client": new ClientModel(),
            "vehicle": new VehicleModel(),
            "user": new UserModel(),
            "scheduleVisit": new ScheduleVisitModel(),
            "assetModel": new AssetModelModel(),
        };
        let result = modelos[this.nameMainType];
        if (this.nameMainType === "order") {
            result.addRelatedTable("workOrder");
            result.addRelatedTable("workOrder.workOrderCartArticle");
            result.addRelatedTable("budget");
            result.addRelatedTable("workOrderCartArticle");
            result.addRelatedTable("slot");
            result.addRelatedTable("client");
        } else if (this.nameMainType === "workOrder") {
            result.addRelatedTable("budget");
            result.addRelatedTable("workOrderCartArticle");
            result.addRelatedTable("documents");
            result.addRelatedTable("order");
            result.addRelatedTable("order.slot");
        }
        result.addRelatedTable("documents");
        return result;
    }


    async formDidSave(model, previousId) {
        await this.actualizaListadWorkOrders(model, previousId);
    }

    async actualizaListadWorkOrders(model, previousId) {
        if (this.props.orderId != null) {
            if (util.hasValue(this.props.id)) {
                for (let i = 0; i < appState.workOrderState.workOrders.length; i++) {
                    if (appState.workOrderState.workOrders[i].id == model.id) {
                        appState.workOrderState.workOrders[i] = model.toPlainObject()
                    }
                }
            } else {
                appState.workOrderState.workOrders.push(model.toPlainObject());
            }
        }
    }

    tabsDocument() {
        let tipos = {
            "order": this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType)),
            "workOrder": this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order)),
            "asset": this.tabsData.getDataAssets(),
            "article": this.tabsData.getDataArticles(),
            "subcontract": this.tabsData.getDataSubcontract(),
            "client": this.tabsData.getDataClient(),
            "clientPos": this.tabsData.getDataPosClient(),
            "scheduleVisit": this.tabsData.getDataScheduleVisit(),
            "user": this.tabsData.getDataUser(),
            "vehicle": this.tabsData.getDataVehicle(),
            "assetModel": this.tabsData.getDataAssetModels(),
        };
        return tipos[this.nameMainType];
    }


    renderImpl() {
        const t = translate;
        let id = this.getFormId() || this.props.id;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let model = this.graphDataMainType;
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    {this.getMoreButtons()}
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={id}
                                tabs={this.tabsDocument()}
                                fromRightModal={this.props.fromRightModal}
                                active={"Documentos"}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            <MoreButtons
                                onReloadData={() => this.reloadData()}>
                                {this.getMoreButtons()}
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={id}
                                tabs={this.tabsDocument()}
                                active={"Documentos"}
                            />
                            }
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}
                            >
                                <div className={'card mb-3'}>

                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking}
                                    />
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                                    <GroupOfField title={t('Documentacion')} icon="fas fa-book">
                                        <SpinnerClipLoaderInPage loading={this.state.loading}>

                                            <UploadFileComponentSortable
                                                title={t("Subida de Archivos")}
                                                onChangePrevious={() => this.onChangePrevious()}
                                                required={true}
                                                model={model}
                                                name={'uploadFiles'}
                                                setDocuments={(e) => this.setDocuments(e)}
                                                documents={model.documents}
                                                baseFolder={this.nameMainType + "/" + this.getFormId() + "/documents"}
                                            />
                                        </SpinnerClipLoaderInPage>
                                    </GroupOfField>
                                </div>
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>


        )
    }

    setDocuments(documents) {
        this.graphDataMainType.documents = documents
    }

    onChangePrevious() {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
    }
}

export default PageDocument
