import AbstractModel from "./AbstractModel";

export default class UserOnlinesModel extends AbstractModel {
    userId;
    connectionType;
    time;
    appVersion;
    nameMainType = "userOnline";
    graphFindByIdOperation = "userOnline";
    graphFindOperation = "userOnlinesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "userId": { subscription: true },
            "connectionType": { subscription: true },
            "time": { subscription: true },
            "appVersion": { subscription: true },
        };

        return result;
    }

};
