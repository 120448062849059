import React, {Component} from 'react';
import {observer} from 'mobx-react';
import translate from "../../translator/translate";

@observer
class ListPageCounts extends Component {
    static defaultProps = {
        /** Connection respuesta de Graphql con el número de elementos mostrados */
        connection: null,
        numEntriesPerPage: 1,
    };

    constructor(props) {
        super(props);
    }

    getParamPagination(params) {
        let result = params.get('pagination');
        if (this.props.fromRightModal) {
            result = params.get('modalPagination');
        }
        return result;
    }

    getParamPage(params) {
        let result = params.get('page');
        if (this.props.fromRightModal) {
            result = params.get('modalPage');
        }
        return result;
    }

    getPagination() {
        const params = new URLSearchParams(this.props.location.search);
        let pagination = parseInt(this.getParamPagination(params));
        if (pagination == 0 || isNaN(pagination)) {
            pagination = this.props.numEntriesPerPage;
        }
        return pagination;
    }

    render() {
        const t = translate;
        const params = new URLSearchParams(this.props.location.search);
        let page = parseInt(this.getParamPage(params));
        if (page == 0 || isNaN(page)) {
            page = 1;
        }

        let props = this.props;
        let totalCount = props.listData?.connection?.totalCount;

        let from = this.getPagination() * (page - 1) + 1;
        let to = from + (this.props.listData?.connection?.items ? (this.props.listData?.connection?.items.length - 1) : 1);
        if(this.props.fromWMS){
            totalCount=this.props.wmsLength;
            from=this.props.wmsLength;
            to=this.props.wmsLength;
        }
        let texto = t('Mostrando FROM TO de TOTALCOUNT resultados');
        texto = texto.replace("FROM", from + "-");
        texto = texto.replace("TO", to);
        texto = texto.replace("TOTALCOUNT", totalCount);
        if (totalCount === 0) {
            texto = t('No hay resultados');
        }

        return (
            <div className='listPageCount mr-auto'>
                <span>{texto}</span>
                {totalCount === 0 &&
                <span>{this.props.noResultText}</span>
                }
            </div>
        );
    }
}

export default ListPageCounts
