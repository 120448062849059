import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import translate from '../translator/translate'
import { Animated } from 'react-animated-css'
import AppLogger from '../util/AppLogger'
import VsfButtonNew from '../components/VsfButtonNew'
import appState from '../state/AppState'
import Overlay from '../components/modals/Overlay'
import VsfLink from '../components/VsfLink'
import TypifiedValueModel from '../models/TypifiedValueModel'
import BudgetModel from '../models/BudgetModel'
import BaseAlertsButtons from './Base/BaseAlertsButtons'
import util from '../util/Util'

@observer
class WarningButton extends BaseAlertsButtons {
    constructor (props) {
        super(props)
        this.state = { alert: false }
    }

    loadData () {

    }

    getUrlPdtApproved () {
        let idTypified = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'PDT_APPROVED').id
        let url = '/order/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + idTypified + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + appState.typifiedState.getValue(idTypified) + '\"}]'
        return url
    }

    getUrlTechniciansWithoutScheduledToday () {
        let date = util.getMoment().format('YYYYMMDD')
        return '/schedule/bytechnical?withoutTech=' + date + '&date=' + date
    }

    getUrlNotApproved () {
        let idTypified = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'NOT_APPROVED').id
        let url = '/order/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + idTypified + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + appState.typifiedState.getValue(idTypified) + '\"}]'
        return url
    }

    getUrlBudgetPending () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_BORRADOR + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Pendiente de aprobar\"}]'
        return url
    }

    getUrlBudgetPendingOrder () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_APPROVED + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Presupuestos aprobados\"}]'
        return url
    }

    getUrlBudgetNotApprovedOrder () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_NOT_APPROVED + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Presupuestos rechazados\"}]'
        return url
    }

    getUrlValidations () {
        return '/config/validates'
    }

    getUrlTechsWithoutEndDay () {
        return '/userWithoutEndDay/ls/'
    }

    getWorkOrdersReturnedFollowed () {
        let url = '/workOrder/ls?filters='
        let statusId = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, 'END_WORK_ORDER').id
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${statusId}","filterOperator":"NEQ","fieldLabel":"Sin Finalizar"}, {"fieldKey":"hasReturned","fieldName":"hasReturned","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Devuelta"}]`
        return url
    }

    getWorkOrdersWithPossibleWarranty () {
        let url = '/workOrder/ls?filters='
        url += `[{"fieldKey":"warrantyPossible","fieldName":"warrantyPossible","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Sí"}, {"fieldKey":"warrantyRevised","fieldName":"warrantyRevised","fieldValue":"N","filterOperator":"EQ","fieldLabel":"No"}]`
        return url
    }

    getBudgetsAccepted () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_APPROVED
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Aceptado"}]`
        return url
    }

    getBudgetsPending () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_PENDING_ERP
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Pendiente ERP"}]`
        return url
    }

    getBudgetsExpired () {
        let url = '/budget/ls?filters='
        let fiveDays = util.getMoment().add(5, 'day')
        let date = util.getMomentFromDateWithoutTimezone(fiveDays.format('YYYY-MM-DDTHH:mm:ss')).toISOString()
        let status = BudgetModel.CODE_PENDING_ERP
        let today = util.getMoment().toISOString()
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Pendiente ERP"},{"fieldKey":"endDateBudget","fieldName":"endDateBudget","fieldValue":"${today}","filterOperator":"GTEQ","fieldLabel":"${today}"},{"fieldKey":"endDateBudget","fieldName":"endDateBudget","fieldValue":"${date}","filterOperator":"LTEQ","fieldLabel":"${date}"}]`
        return url
    }

    getBudgetsDenied () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_NOT_APPROVED
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Denegado"}]`
        return url
    }

    render () {
        const t = translate
        let totalAlarms = appState.alertState.pdtApproval
        totalAlarms += appState.alertState.workOrdersReturnedFollowed
        totalAlarms += appState.alertState.workOrdersPossibleWarranty
        totalAlarms += appState.alertState.budgetsExpired
        return (
            <div className="header-alerts dropdown"
                style={{
                    marginRight: '0',
                    padding: '12px 7px 12px 7px'
                }}>

                <VsfButtonNew type="button" className="alerts-dropdown btn warning--alert btn--alert text-warning"
                    icon={'fa fa-exclamation-circle'}
                    onClick={() => this.setState({ alert: true })}>
                    <i className="badge badge-warning" style={{ padding: '2px 4px' }}>{totalAlarms + ' '}</i>
                </VsfButtonNew>

                {this.state.alert &&
                <Animated style={{
                    zIndex: 100,
                    position: 'absolute',
                    top: 10,
                    left: 0,
                    right: 0
                }}
                animationInDelay={150} animationIn="zoomIn" animationInDuration={250}
                isVisible={true}>
                    <Overlay show={this.state.alert} style={{ backgroundColor: 'transparent' }}
                        onClick={_ => this.setState({ alert: false })} />
                    <dl className="dropdown-sublist" style={{
                        top: 10,
                        right: 22,
                        width: 420
                    }}>
                        {totalAlarms === 0 ?
                            <dt className="dropdown-sublist__head">
                                {t('No tienes notificaciones pendientes')}
                            </dt>
                            :
                            <>
                                <dt className="dropdown-sublist__head">
                                    {totalAlarms === 1 ?
                                        t('Tienes #total# notificacion').replace('#total#', totalAlarms)
                                        : t('Tienes #total# notificaciones').replace('#total#', totalAlarms)}
                                </dt>
                                {appState.alertState.pdtApproval !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlPdtApproved()}>
                                        {t('Pendientes de aprobacion')}
                                        <i className="badge badge-warning">{appState.alertState.pdtApproval}</i>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.workOrdersReturnedFollowed !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getWorkOrdersReturnedFollowed()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.workOrdersReturnedFollowed}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Seguimiento de Órdenes de Trabajo Devueltas')}</div>

                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.workOrdersPossibleWarranty !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getWorkOrdersWithPossibleWarranty()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.workOrdersPossibleWarranty}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Órdenes de Trabajo Con Posible Garantía Pendiente')}</div>

                                    </VsfLink>
                                </dd>
                                }

                                {appState.alertState.budgetsExpired !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getBudgetsExpired()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.budgetsExpired}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Presupuestos proximos a caducar')}</div>

                                    </VsfLink>
                                </dd>
                                }
                            </>
                        }

                    </dl>
                </Animated>

                }
            </div>
        )

    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default withRouter(WarningButton)
