import React from 'react';
import {observer} from 'mobx-react';
import BaseEditableRow from "../base/BaseEditableRow";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import util from "../../util/Util";
import WorkLogModel from "../../models/WorkLogModel";
import OFFER from "../../config/OFFER";

@observer
class OrderSummaryWorkLogs extends BaseEditableRow {

    constructor(props) {
        super(props);
    }

    getTimeSlot(arrWorkLogs, action) {
        let timeSlot;
        let workLogs = arrWorkLogs;
        for (let workLog of workLogs) {
            if (workLog.slotId == this.props.slot.id && workLog.action == WorkLogModel[action]) {
                timeSlot = workLog.time;
            }
        }
        return timeSlot;
    }

    renderData(workLogs, action) {
        let data = [];
        let workLogModel = new WorkLogModel();
        let workLogsCopy = workLogs.slice(0).reverse();
        let timeSlot = this.getTimeSlot(workLogs, action);
        for (let workLog of workLogsCopy) {
            if (workLog.action == WorkLogModel[action] &&
                ((util.getMoment(workLog.time).format("YYYYMMDDHH") <= util.getMoment(timeSlot).format("YYYYMMDDHH") &&
                    workLog.slotId == this.props.slot.id))) {
                data.push(workLog);
            }
        }
        if (data.length != 0) {
            return (
                <div className={"row"}>
                    <span className={"col-3"} style={{
                        fontSize:' 0.75rem',
                        color: '#6C757D',
                        lineHeight: '14px',
                        marginBottom: 6,
                        fontWeight: '400',
                        textTransform: 'capitalize',
                    }}>
                        {workLogModel.getTitle(action)}
                    </span>
                    <div className={"column col-12"}>
                        {data.map((result) => (
                            <div className={"row "}>
                                {/*<span key={result.id} style={{*/}
                                {/*    color: '#6C757D',*/}
                                {/*    fontSize: 12,*/}
                                {/*}}>*/}
                                {/*    {action === "TASKS" ? util.getMoment(result.time).format("YYYY-MM-DD HH:mm") : ""}*/}
                                {/*</span>*/}
                                {(util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(action)]) &&
                                JSON.parse(result.data)[workLogModel.nameForData(action)].reverse().map((obj) => (
                                    <>
                                        {util.hasValue(obj.comment) && <div className={"col-12"}>
                                    <span style={{
                                        marginBottom: 6,
                                        color: '#6C757D',
                                        textTransform: 'none',
                                    }} key={obj.id}>
                                         <span
                                             className={action === WorkLogModel.COMMENTS_DISPATCHER ? " table-comment-dispatcher" : " table-comment"}>   <span>{obj.comment}</span></span>
                                    </span>
                                        </div>
                                        }
                                    </>
                                ))}
                                {(util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(action)]) &&
                                JSON.parse(result.data)[workLogModel.nameForData(action)].reverse().map(obj => (
                                    <div className={"col-12"} style={{marginBottom: 6,}}>
  <span style={{
      color: '#6C757D',
  }} key={obj.id}>
                                        {util.hasValue(obj.title) ? obj.title + "  " : ""}
      {OFFER.code_partes_trabajo && util.hasValue(obj.code) && obj.code + "  "}
      {typeof obj.value === "boolean" ? (obj.value ? "Si" : "No") : (obj.value)}
                                    </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

            )
        }
    }

    renderFlavorData(workLogs, action) {
        let data = [];
        const t = translate;
        let workLogsCopy = workLogs.slice(0).reverse();
        let workLogModel = new WorkLogModel();
        let timeSlot = this.getTimeSlot(workLogs, action);
        let lastPlanogramInWorkLogs=null;
        for (let workLog of workLogsCopy) {
            if (workLog.action === WorkLogModel[action] &&
                util.getMoment(workLog.time).format("YYYYMMDDHHmm") <= util.getMoment(timeSlot).format("YYYYMMDDHHmm")) {
                lastPlanogramInWorkLogs=new WorkLogModel();
                lastPlanogramInWorkLogs.hidrate(workLog);
                break;
            }
        }
        let carriles=[];
        if (lastPlanogramInWorkLogs!=null) {
            carriles = lastPlanogramInWorkLogs.getFlavourAsTitleValue();
            this.log({"renderFlavorData": carriles});
        }
        if (carriles.length != 0) {
            return (
                <div>
                    <span style={{color: 'red'}}>{workLogModel.getTitle(action) + ":"}</span>
                    {carriles.map(carrilArrayTitleValue => (
                        <div>
                            {carrilArrayTitleValue.map(objTitleValue => (
                                <div style={{flexDirection: 'row'}}>
                                    <span>
                                        {objTitleValue.title}: {objTitleValue.value }
                                    </span>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )
        }
    }

    renderArticlesUsed(workOrder) {
        const t = translate;
        let articles = workOrder.workOrderCartArticle?.filter(item => item.slotId === this.props.slot.id);
        return (
            <>
                {articles?.length > 0 &&
                <div className={"row"}>
                    <span className={"col-3"} style={{
                        fontSize:' 0.75rem',
                        color: '#6C757D',
                        lineHeight: '14px',
                        fontWeight: '400',
                        marginBottom: 6,
                        textTransform: 'capitalize',
                    }}>{t("PIEZAS USADAS") + ":"}
                    </span>
                    <div className={"column col-12"}>
                        {articles.map((workOrderArticle) => (
                            <div className={"row col-12"}>
                            <span style={{
                                marginBottom: 6,
                                color: '#6C757D',
                                fontSize: 12,
                            }}>
                                {workOrderArticle.articleCode}
                                {workOrderArticle.stockLote && " - " + t("Lote") + ": " + workOrderArticle.stockLote}
                                {workOrderArticle.description && " - " + workOrderArticle.description}{" "}
                                {workOrderArticle.quantity}
                                {util.hasValue(workOrderArticle.article?.saleUnit) ? " " + t(workOrderArticle.article?.saleUnit) : " "}
                            </span>
                            </div>
                        ))}
                    </div>
                </div>
                }
            </>
        )
    }

    renderState(workOrder) {
        const t = translate;
        return (
            <div className={"row"}>
                <span className={"col-3"}
                      style={{marginBottom: 6, color: '#6C757D', fontSize: 12,}}>{t("ESTADO") + ":"}
                </span>
                <div className={"column"}>
                    <span style={{marginBottom: 6, color: '#6C757D', fontSize: 12,}}>
                        {workOrder.statusWorkOrder?.value}
                    </span>
                </div>
            </div>
        )
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let workLogs = [];
        if (this.props.workLogs && this.props.workLogs.length > 0) {
            workLogs = this.props.workLogs;
        }
        let workOrder = this.props.workOrder;
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                {this.renderData(workLogs, WorkLogModel.COMMENTS_DISPATCHER)}
                {this.renderData(workLogs, WorkLogModel.COMMENTS)}
                {this.renderData(workLogs, WorkLogModel.DIAGNOSIS)}
                {this.renderData(workLogs, WorkLogModel.TASKS)}
                {this.renderData(workLogs, WorkLogModel.WORK_ORDER_TEST_QUALITY)}
                {this.renderFlavorData(workLogs, WorkLogModel.FLAVOR_PLANOGRAM)}
                {this.renderData(workLogs, WorkLogModel.CHANGE_REASONS)}
                {this.renderArticlesUsed(workOrder)}
                {/*{this.props.fromWO && this.renderState(workOrder)}*/}
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default OrderSummaryWorkLogs
