import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";


class SubcontractContactState extends BaseModelState {

    @observable subcontractContacts = [];


}

export default SubcontractContactState;
