import AbstractModel from "./AbstractModel";
import UserModel from "./UserModel";
import DocumentModel from "./DocumentModel";

export default class VehicleModel extends AbstractModel {
    id;
    plate;
    model;
    brand;
    subInventory;

    parentId;

    nameMainType = "vehicle";
    graphFindByIdOperation = "vehicle";
    graphFindOperation = "vehiclesConnection";
    graphExportOperation = "vehiclesExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "plate": "",
            "model": "",
            "brand": "",
            "subInventory":"",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "users", "Relation", UserModel);
        return result;
    }

};





















