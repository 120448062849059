import React from 'react'
import { observer } from 'mobx-react'
import BaseForm from '../base/BaseForm'
import { observable } from 'mobx'
import translate from '../../translator/translate'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import MoreButtons from '../../components/MoreButtons'
import GqlErrors from '../../components/status/GqlErrors'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import SlotModel from '../../models/SlotModel'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import DateInputHoursMobiscroll from '../../components/fields/DateInputHoursMobiscroll'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import RelatedTableConfig from '../../models/RelatedTableConfig'
import VsfButtonNew from '../../components/VsfButtonNew'
import FetchProxy from '../../network/FetchProxy'
import config from '../../config/config'
import GraphException from '../../network/GraphException'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import OrderSummaryWorkLogs from '../orders/OrderSummaryWorkLogs'
import WorkOrderDetailRow from '../../components/WorkOrderDetailRow'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import SlotActions from './SlotActions'
import OFFER from '../../config/OFFER'
import WorkLogModel from '../../models/WorkLogModel'

@observer

class SlotForm extends BaseForm {

    @observable textPopUp = "¿Seguro que desea enviar a Netsuite las Sales Order?";
    @observable salesOrderSent = false;

    constructor(props) {
        super(props);
        this.nameMainType = "slot";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.loadEndVisit();
        this.setState({ loading: false })
    }

    async loadEndVisit() {
        let workLogQuery = new WorkLogModel();
        workLogQuery.clientId = this.graphDataMainType.client?.id;
        workLogQuery.slotId = this.graphDataMainType?.id;
        workLogQuery.action = WorkLogModel.END_VISIT;
        workLogQuery.userId = this.graphDataMainType.userId;
        let workLog = await workLogQuery.findFirst();
        if (workLog) {
            let withFirm = !util.hasValue(JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.dontSign);
            this.setState({
                satisfaction: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.satisfaction,
                contact: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.contact,
                mailSalesUser: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.mailSalesUser,
                withFirm
            });
            this.log({
                loadEndVisit: 1,
                withFirm,
                contact: this.state.contact,
                mailSalesUser: this.state.mailSalesUser
            })
        }
    }

    downloadPDF(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadPDF(slot.id);
    }

    downloadPDFSOUnites(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadPDFSOUnites(slot.id);
    }

    downloadExcelRelatorio(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadExcelRelatorio(slot.id);
    }
    openUserModal(userId) {
        this.propsUtil.changeUrlRequest({
            "rightModal": "modaluserls",
            "userId": userId,
            "rightModalTab": "general"
        });
    }

    getModelQuery() {
        let result = new SlotModel();
        return result;
    }

    getModelQueryForView() {
        let result = new SlotModel();
        result.addRelatedTableOnlyFields("order", new RelatedTableConfig(["code", "id"]));
        result.addRelatedTableOnlyFields("order.workOrder", new RelatedTableConfig(["code", "id"]));
        result.addRelatedTableOnlyFields("typeWorkOrder", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("client", new RelatedTableConfig(["name"]));
        result.addRelatedTableOnlyFields("user", new RelatedTableConfig(["firstName", "lastName"]));
        result.addRelatedTable("workLog");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.workLog");
        result.addRelatedTable("workOrder.workOrderCartArticle");
        result.addRelatedTable("workOrder.workOrderCartArticle.article");
        result.addRelatedTable("workOrder.asset");
        result.addRelatedTable("workOrder.asset.assetModel");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    workOrdersCodes() {
        let slot = this.graphDataMainType;
        let workOrder = this.graphDataMainType.order?.workOrder;
        let result = '';
        if (workOrder && workOrder.length > 0) {
            for (let workor of workOrder) {
                if (util.perteneceA("-1", slot.workOrderIds)) {
                    result += workor.code + ' ';
                } else if (util.perteneceA(workor.id, slot.workOrderIds))
                    result += workor.code + ' ';
            }
        }
        return result;
    }

    renderImpl() {
        const t = translate;

        let slot = this.graphDataMainType;
        if (slot == null) {
            slot = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let modalClasses = util.getModalClasses();
        let workOrderCode = this.workOrdersCodes();

        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"edit"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <SlotActions fromRightModal={this.props.fromRightModal}
                                                 setErrors={(errors) => this.setErrors(errors)}
                                                 model={this.graphDataMainType}
                                                 getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>

                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataSlot()}
                                active={"Datos Generales"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" "}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            >
                                <SlotActions fromRightModal={this.props.fromRightModal}
                                             setErrors={(errors) => this.setErrors(errors)}
                                             model={this.graphDataMainType}
                                             getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }


                    <div className={'card mb-3'}>
                        <SpinnerClipLoaderInPage loading={this.state.loading}>
                            <div className={modalClasses.body}>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataSlot()}
                                    active={"Datos Generales"}
                                />
                                }

                                <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                   error={this.graphStatus.mutationError}
                                                   working={this.graphStatus.networkWorking}
                                />

                                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />


                                <GroupOfField title={t("Datos")}
                                              subtitle={t("Visita")}
                                              icon="fas fa-file-invoice">

                                    <InputTypeComponent
                                        value={slot.code}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"code"}
                                        title={t("Codigo de Visita")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-md-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.code}
                                    />

                                    <InputTypeComponent
                                        value={slot.order?.code}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"code"}
                                        title={t("Pedido")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.code}
                                    />
                                    <InputTypeComponent
                                        value={slot.workOrderIds === "-1" ? "Todas las órdenes del pedido" : workOrderCode}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"workOrderIds"}
                                        title={t("O.T. Relacionadas")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.workOrderIds}
                                    />
                                    <InputTypeComponent
                                        value={slot.client?.name}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"name"}
                                        title={t("Establecimiento")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.posZoneId}
                                    />

                                    <InputTypeComponent
                                        value={util.hasValue(slot.user?.firstName) ? (slot.user?.firstName + " " + slot.user?.lastName) : t("No asignado")}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"firstName"}
                                        title={t("Técnico asignado")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.firstName}
                                    />


                                    <DateInputHoursMobiscroll
                                        prefix={"fas fa-calendar-alt"}
                                        editable={this.editable}
                                        value={slot.scheduledTime}
                                        allowEmptyDate={true}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"scheduledTime"}
                                        title={t("Fecha de planificación")}
                                        classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-3' : 'col-md-7 col-lg-6 col-xl-3 pr-md-8 pl-md-8'}
                                        type={"text"}
                                        errors={errorsMapped.scheduledTime}
                                        styleCustomProps={true}
                                        readOnly={true}
                                    />
                                    <DateInputFieldMobiscroll
                                        prefix={"fas fa-calendar-alt"}
                                        editable={this.editable}
                                        value={slot.scheduledTimeUntil}
                                        allowEmptyDate={true}
                                        readOnly={true}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"scheduledTimeUntil"}
                                        title={t("Fecha hasta")}
                                        classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-3' : 'col-md-7 col-lg-6 col-xl-3 pr-md-8 pl-md-8'}
                                        type={"text"}
                                        errors={errorsMapped.scheduledTimeUntil}
                                        styleCustomProps={true}
                                    />
                                    <InputTypeComponent
                                        value={slot.scheduledDuration}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"scheduledDuration"}
                                        title={t("Duración")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.scheduledDuration}
                                    />

                                    <InputTypeComponent
                                        value={slot.isFinished ? t("Sí") : t("No")}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"isFinished"}
                                        title={t("Finalizado")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-md-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.isFinished}
                                    />

                                    <InputTypeComponent
                                        value={slot.satisfactionInterpreted}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"satisfactionInterpreted"}
                                        title={t("Satisfacción")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.satisfactionInterpreted}
                                    />

                                    <InputTypeComponent
                                        value={slot.clientSign ? t("Sí") : t("No")}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"clientSign"}
                                        title={t("Firma Cliente")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.clientSign}
                                    />

                                    <InputTypeComponent
                                        value={slot.fileSign ? t("Sí") : t("No")}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"fileSign"}
                                        title={t("Firma Archivo")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.fileSign}
                                    />

                                    <InputTypeComponent
                                        value={slot.signContactName}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"signContactName"}
                                        title={t("Firma Contacto")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-3"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.signContactName}
                                    />
                                    {OFFER.destinatario_parte ?
                                        <>
                                            <InputTypeComponent
                                                value={this.state.mailSalesUser?.email}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                title={t("Destinatario parte de trabajo")}
                                                classGroup={"col-lg-3"}
                                                type={"text"}
                                                readOnly={true}
                                            />
                                            <div className="col-6" />
                                        </> :
                                        <div className="col-9" />

                                    }
                                    <DateInputHoursMobiscroll
                                        prefix={"fas fa-calendar-alt"}
                                        editable={this.editable}
                                        value={slot.integrationErpDate}
                                        allowEmptyDate={true}
                                        readOnly={true}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"integrationErpDate"}
                                        title={t("Fecha Envío a ERP")}
                                        classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-3' : 'col-md-7 col-lg-6 col-xl-3 pr-md-8 pl-md-8'}
                                        type={"text"}
                                        errors={errorsMapped.integrationErpDate}
                                        styleCustomProps={true}
                                    />
                                    <InputTypeComponent
                                        value={slot.integrationErpStatus === null ? "No enviado" : slot.integrationErpStatus + " - " + util.localizeIsoDateMinutes(slot.integrationErpDate) + " - " + slot.integrationErpMessage}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"integrationErpStatus"}
                                        title={t("Estado ERP")}
                                        placeholder=""
                                        editable={this.editable}
                                        classGroup={"col-lg-9"}
                                        type={"text"}
                                        readOnly={true}
                                        errors={errorsMapped.integrationErpStatus}
                                    />


                                    <div className="links-row mt-3 col-12">
                                        {(slot.integrationErpStatus !== SlotModel.CODE_INTEGRATION_SUCCESS) &&
                                        <VsfButtonNew className="btn btn--orange"
                                                      label={t("Enviar SO")}
                                                      disabled={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS}
                                                      onClick={() => this.setState({ sendIntegration: true })}>
                                        </VsfButtonNew>
                                        }
                                        <a href={this.downloadPDF(slot)} target={'_blank'}>
                                            <VsfButtonNew className="btn btn--orange" label={t("Descargar PDF")}
                                                          onClick={() => this.downloadPDF(slot)}>

                                            </VsfButtonNew>
                                        </a>

                                        <a href={this.downloadExcelRelatorio(slot)} target={'_blank'}>
                                            <VsfButtonNew className="btn btn--orange" onClick={() => this.downloadExcelRelatorio(slot)}>
                                                {t("Relatorio Parte de trabajo")}
                                            </VsfButtonNew>
                                        </a>
                                   {slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS && util.hasValue(slot.integrationErpMessage) &&
                                    <a href={this.downloadPDFSOUnites(slot)} target={'_blank'}>
                                        <VsfButtonNew className="btn btn--orange"
                                                      onClick={() => this.downloadPDFSOUnites(slot)}>
                                            {t("Descargar Todos los PT")}
                                        </VsfButtonNew>
                                    </a>
                                    }
                                    </div>
                                    <AlertModal isOpen={this.state.sendIntegration}>
                                        <PopUp
                                            title={t('Enviar un mensaje')}
                                            icon="exclamationTriangleIcon"
                                            classNameText={this.salesOrderSent ? (slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_FAIL ? "text-danger" : "text-success") : " "}
                                            text={this.textPopUp}
                                            hideButton1={this.salesOrderSent}
                                            label1={'Cerrar'}
                                            loading3={this.state.loadingERP}
                                            loadedError3={this.gqlErrors?.length !== 0}
                                            label3={'Aceptar'}
                                            disabled3={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS}
                                            onClick1={() => {
                                                if (this.salesOrderSent) {
                                                    window.location.reload()
                                                } else {
                                                    this.closeSlotFinished()
                                                }
                                            }}
                                            onClick3={() => this.sendSlotFinished(slot)}
                                        />
                                    </AlertModal>
                                </GroupOfField>
                                <GroupOfField title={t("Detalles")}
                                              subtitle={t("Visita")}
                                              icon="fas fa-file-invoice" />
                                <div className="c-modal-info__cell__body__table">
                                    <table className="w-100 mb-3">
                                        <tbody>
                                        <tr>
                                            <td colSpan={10}
                                                className={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS ? "text-success" :
                                                    slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_FAIL ? "text-danger" : ""}
                                                style={{ 'padding': '25px 0 15px 0' }}>
                                                {slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS &&
                                                <>{t(slot.integrationErpStatus)}{" - "}{util.localizeIsoDateMinutes(slot.integrationErpDate)} </>}
                                                {" - "}{slot.integrationErpMessage}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t("Tipo")}</th>
                                            <th>{t("O.trabajo")}</th>
                                            <th>{t("Estado")}</th>
                                            <th>{t("Equipo matrícula")}</th>
                                            <th>{t("Tipo")}</th>
                                            <th>{t("Subtipo")}</th>
                                            <th>{t("Duración")}</th>
                                        </tr>

                                        {slot.workOrder?.map((workOrder) => (
                                            <WorkOrderDetailRow
                                                workOrder={workOrder} key={workOrder?.id}
                                                slot={slot} fromSummary>
                                                <tr>
                                                    <td colSpan={8}>
                                                        <OrderSummaryWorkLogs
                                                            workLogs={workOrder.workLog}
                                                            workOrder={workOrder}
                                                            slot={slot}
                                                            workOrderCartArticles={workOrder.workOrderCartArticle}
                                                        />
                                                    </td>
                                                </tr>
                                            </WorkOrderDetailRow>
                                        ))}


                                        </tbody>

                                    </table>

                                </div>
                            </div>
                        </SpinnerClipLoaderInPage>
                    </div>
                </div>

            </InfoBlock>
        )

    }

    getModelTable() {
        return new SlotModel();
    }

    async sendSlotFinished(slot) {
        this.setState({ loadingERP: true });
        let slotId = slot.id;
        const t = translate;
        let httpApi = new FetchProxy();
        httpApi.withAuthorization = true;
        try {
            let url; //config.apiHostInt + "/api/integration/slotFinished";
            url = config.apiRestHostBaseUrl + "/slotFinished";
            let response = await httpApi.fetchUrlPost(url, { "slotId": slotId });
            let responseJson = await response.json();
            if (responseJson.status !== "OK") {
                let gqlErrors = new GraphException().getErrorsFromException(responseJson);
                this.log({ gqlErrors, responseJson });
                this.setState({ loadingError: true, loadingERP: false });
                this.gqlErrors = gqlErrors;
                this.textPopUp = t("Ha habido un error con el envío") + "." + responseJson.data?.message;
                slot.integrationErpStatus = responseJson.status;
                slot.integrationErpMessage = responseJson.data?.message
            } else {
                this.textPopUp = t("Gracias, todo ha ido correctamente. Ya se ha envíado al ERP") + "." + responseJson.data?.message;
                slot.integrationErpStatus = SlotModel.CODE_INTEGRATION_SUCCESS;
            }
            this.log({ respuestaJson: 1, responseJson });

        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
            this.log(e);
            slot.integrationErpStatus = SlotModel.CODE_INTEGRATION_FAIL;
        }
        this.salesOrderSent = true;
        this.setState({ loadingERP: false });
    }

    closeSlotFinished() {
        this.setState({ sendIntegration: false });
        this.textPopUp = "¿Seguro que desea enviar a Netsuite las Sales Order?";
        this.gqlErrors = [];
    }

    downloadPDF(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadPDF(slot.id);
    }
}

export default SlotForm
