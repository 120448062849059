import AbstractModel from "./AbstractModel";
import WorkOrderModel from "./WorkOrderModel";
import OrderModel from "./OrderModel";
import ClientModel from "./ClientModel";
import SlotModel from "./SlotModel";
import WorkLogModel from "./WorkLogModel";


export default class SlotShortModel extends AbstractModel {

    slotUserRole;
    userId;
    workOrderIds;
    code;
    isFinished;
    scheduledTime;

    nameMainType = "slot";
    graphFindByIdOperation = "slot";
    graphFindOperation = "slotsConnection";
    graphExportOperation = "slotsExport";

    newModel() {
        return new SlotShortModel();
    }

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "slotUserRole": "",
            "code": { readonly: true },
            "userId": "",
            "workOrderIds": "",
            "orderId": "",
            "isFinished": "",
            "scheduledDuration": "",
            "clientId": "",
            "isStarted": "",
            "scheduledTime": "",
            "scheduledEndTime": "",
            "viewedByTechnical": "",
            "viewedByTechnicalDate": "",
            "isDateTimeFixed": "",
            "typeWorkOrderId": "",
        };
        this.addRelatedTableGeneric(result, "workOrder", "Relation", WorkOrderModel);
        this.addRelatedTableGeneric(result, "workLog", "Relation", WorkLogModel);
        this.addRelatedTableGeneric(result, "order", "RelationOne", OrderModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "childSlots", "Relation", SlotModel);
        return result;
    }


};





















