import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {Route, Router, Switch} from 'react-router-dom';
import {createBrowserHistory} from "history";
import posed from 'react-pose';
import SpinnerClipLoaderComponent from "./network/SpinnerClipLoaderComponent";
import AppLogger from "./util/AppLogger";
import AppWrapperNotAuth from "./layout/AppWrapperNotAuth";
import appState from "./state/AppState";
import translate from "./translator/translate";

const ErrorBoundary = React.lazy(() => import("./components/ErrorBoundary"));
const LoginPage = React.lazy(() => import('./pages/FormLogin'));
const PasswordReset = React.lazy(() => import('./pages/PasswordReset'));
const Page404 = React.lazy(() => import("./pages/Page404"));

const RouteContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        delay: 0,
    },
    visible: {
        opacity: 1,
        transition: { duration: 0 }
    },
    exit: {
        y: 0,
        opacity: 0,
    }
});

/** Permite que al hacer click la p�gina suba al TOP **/
const history = createBrowserHistory();

/** Fin pagina subir a TOP */

@observer
class LoginRouter extends Component {

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props?.location?.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    componentDidMount() {
        this.checkRoute()

    }

    checkRoute() {
        if (this.ifPathStartsWith("/externalOrder")) {
            appState.loginState.dataUser.email = "ibolanos@nuddo.com";
            appState.loginState.dataUser.password = "Password1!";
            appState.loginState.doLogin();
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        let props = this.props;
        let PoseGroupEmpty = React.Fragment;
        const t = translate;
        let Try = ErrorBoundary;
        return (
            <Router hashType2='hashbang' history={history}>
                <AppWrapperNotAuth>
                    <React.Suspense fallback={<SpinnerClipLoaderComponent />}>
                        <Route render={({ location }) => (
                            <PoseGroupEmpty>
                                <RouteContainer key={location.pathname}>
                                    {this.log({render:1,loc:location.pathname})}
                                    <Switch>
                                        <Route path="/password/reset/" key={"password-reset"} render={routeprops => (
                                            <PasswordReset key="password-reset" {...routeprops} {...props} />
                                        )} />
                                        <Route path="/wms/main" key="wms-main" exact={true}
                                               render={routeprops => (
                                                   <Try>{t("Autorization Error. Please reopen the application and try again")}</Try>
                                               )} />
                                        <Route path="/" key={"/"} render={routeprops => (
                                            <LoginPage key="home" {...routeprops} {...props} />
                                        )} />
                                        <Route path="*" key={"*"} render={routeprops => (
                                            <Page404 key="page404" {...routeprops} {...props} />
                                        )} />
                                    </Switch>
                                </RouteContainer>
                            </PoseGroupEmpty>
                        )} />
                    </React.Suspense>
                </AppWrapperNotAuth>
            </Router>
        );
    }
}

export default LoginRouter;
