import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppLogger from "../../util/AppLogger";
import GraphException from "../../network/GraphException";
import appState from "../../state/AppState";

@observer
class BaseActions extends Component {


    async deleteModel(lista) {
        let modelo = this.props.getModelQuery();
        modelo.id = this.props.model.id;
        try {
            await modelo.remove();
            if (this.props.fromRightModal) {
                this.closeModal();
            } else {
                this.propsUtil.changeUrl("/" + lista + "/ls/")
            }
        } catch (e) {
            let graphExceptionModel = new GraphException();
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e);
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors;
            if (errors.length > 0) {
                this.props.setErrors(errors);
            }
        }
        this.closeModal();
    }

    urlForHistoric() {
        let model = this.props.model;
        let modelo = this.props.getModelQuery();
        let url = '/'+modelo.nameMainType+'/form/' + model.id + '/history';
        return url;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    closeModal() {
        appState.layoutState.menu = false;
    }

}

export default BaseActions;
