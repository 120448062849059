import ApolloProxy from "../network/ApolloProxy";
import {gql} from "apollo-boost/lib/index";
import apolloClient from "../storage/ApolloClientInstance";
import util from '../util/Util';
import AppLogger from "../util/AppLogger";
import AppException from "../util/AppException";
import appState from "../state/AppState";
import GraphException from "../network/GraphException";

export default class Model {

    id;
    orderBy = "createdAt";
    orderMode = "ASC";
    nameMainType = "";
    first = 5000;
    graphFindOperation = "";
    graphFindByIdOperation = "";
    graphExportOperation = "";
    disctinctQuery = false;
    refineWhere = [];
    indexVariableQuery = 1;
    /**
     * Listado de filtros a a plicar en la llamada a Find() Ejemplo: [{"fieldName":"status","fieldValue":"sta1"}]
     * @type {Array}
     */
    filters = [];

    //Indica si debemos buscar información relacionada
    _relatedTables = {};

    // Campos iniciales para que solo se haga persist de los cambiados
    _fieldsOnLoad = {}


    getArrayFields() {
        let result = {
            "id": {type: "CodeField", subscription: true, noImport: true},
        };
        return result;
    }

    /**
     * Indica que este objeto ya tiene los campos iniciales cargados. De esa forma se llevará un registro de lo que cambia para que solo se actualice lo cambiado. Sólo el primer nivel (no relaciones anidadas)
     */
    setAndGetInitialState() {
        let result = this.toPlainObjectMutation();
        //result={};
        this._fieldsOnLoad = result;
        return result;
    }

    recoverInitialState(plainObj) {
        this._fieldsOnLoad = plainObj;
    }

    async persist() {
        let apolloProxy = new ApolloProxy(apolloClient);
        let modelToSavePlain = this.toPlainObjectMutation();
        let modelToSavePrevious = this._fieldsOnLoad;
        for (let key in modelToSavePrevious) {
            // this.log("key:" + key + " " + modelToSavePrevious[key] + " " + modelToSavePlain[key]);
            if (modelToSavePrevious[key] === modelToSavePlain[key] && key !== "saveOptions") {
                delete modelToSavePlain[key];
            }
        }
        delete modelToSavePlain["id"];
        let id = this.id;
        if (util.esVacio(id)) {
            id = null;
        }
        let variables = {
            id
        };
        if (!this.hasMutationIdParams()) {
            variables = {};
        }
        variables[this.nameMainType] = modelToSavePlain;
        let {operationUpdate} = this.getVariablesMutation();
        let mutationRaw = this.getGraphQLMutationUpdate();
        let mutation = gql`${mutationRaw}`;
        const resultQuery = await apolloProxy.mutate({mutation, mutationRaw, variables, errorPolicy: 'all'});
        for (let fieldName of this.getResponseFieldsFromMutation()) {
            if (resultQuery.data?.[operationUpdate] == null) {
                throw new GraphException({
                    message: "GraphException. Error processing request (003)",
                    errors: resultQuery?.errors
                });
            } else {
                this[fieldName] = resultQuery.data?.[operationUpdate]?.[fieldName];
            }
        }
        return resultQuery;
    }

    getArrayForExcelImports() {
        let result = {};

        return result;
    }

    /**
     * Los campos que al guardar con el API se obtendrán (calculados) desde GQL
     * @returns {string[]}
     */
    getResponseFieldsFromMutation() {
        return ["id"];
    }

    /**
     * A partir de un objeto json rellena este objeto con todas sus relaciones
     * @param obj
     */
    hidrate(obj) {
        if (obj != null) {
            let arrayFields = this.getArrayFields();
            for (let [nameField, value] of Object.entries(arrayFields)) {
                if (obj[nameField] != null) {
                    //Reviso si hay algún campo con relaciones 1-n
                    if (value.type === "Relation") {
                        let Relation = value.childType;
                        let listado = [];
                        for (let relationChild of obj[nameField]) {
                            let relation = new Relation();
                            //Añado las tablas relacionadas que empiecen por este nombre de campo
                            this.addRelatedTablesToModel(nameField, relation);
                            relation.hidrate(relationChild);
                            listado.push(relation);
                        }
                        this[nameField] = listado;
                    } else if (value.type === "RelationOne") {
                        let Relation = value.childType;
                        let relation = new Relation();
                        //Añado las tablas relacionadas que empiecen por este nombre de campo
                        this.addRelatedTablesToModel(nameField, relation);
                        relation.hidrate(obj[nameField]);
                        this[nameField] = relation;
                    } else {
                        this[nameField] = obj[nameField];
                    }
                }
            }
        }
    }

    /**
     * Si tengo un array de tablas relacionadas
     * Devuelve un array con las que empiecen por el prefijo (sin el prefijo)
     * Por ejemplo:
     *   workOrder
     *   workOrder.slot
     * getRelatedTablesWithPrefix("workOrder")=>["slot"]^lkasdjfljasdklf
     * @param prefix
     */
    getRelatedTablesWithPrefix(prefix) {
        let strCompare = prefix + ".";
        let result = [];
        for (let nameTable of Object.keys(this._relatedTables)) {
            if (nameTable.startsWith(strCompare)) {
                result[nameTable.substr(strCompare.length)] = this._relatedTables[nameTable];
            }
        }
        return result;
    }

    addRelatedTablesToModel(nameField, model) {
        //Añado las tablas relacionadas que empiecen por este nombre de campo
        let tablasRelacionadas = this.getRelatedTablesWithPrefix(nameField);
        for (let tablaRelacionada in tablasRelacionadas) {
            model.addRelatedTableOnlyFields(tablaRelacionada, tablasRelacionadas[tablaRelacionada]);
        }
    }

    toPlainObjectMutation() {
        return this.toPlainObject(true);
    }

    toPlainObject(forMutation) {
        let result = {};
        let arrayFields = this.getArrayFields();
        if (forMutation) {
            arrayFields = this.getArrayFieldsMutation();
        }
        for (let [nameField, value] of Object.entries(arrayFields)) {
            if (this[nameField] != null) {
                //Reviso si hay algún campo con relaciones 1-n
                if (value.type === "Relation") {
                    let Relation = value.childType;
                    let listado = [];
                    for (let relationChild of this[nameField]) {
                        let relation = new Relation();
                        //Añado las tablas relacionadas que empiecen por este nombre de campo
                        this.addRelatedTablesToModel(nameField, relation);
                        relation.hidrate(relationChild);
                        listado.push(relation.toPlainObject(forMutation));
                    }
                    result[nameField] = listado;
                } else if (value.type === "RelationOne") {
                    let Relation = value.childType;
                    let relation = new Relation();
                    //Añado las tablas relacionadas que empiecen por este nombre de campo
                    this.addRelatedTablesToModel(nameField, relation);
                    relation.hidrate(this[nameField]);
                    result[nameField] = relation;
                } else {
                    let valueForField = this[nameField];
                    if (value.type === "IntField") {
                        valueForField = util.str2int(valueForField);
                    } else if (value.type === "DateTimeField") {
                        if (valueForField === "") {
                            valueForField = null;
                        }
                    } else if (value.type === "BoolField") {
                        if (typeof valueForField === "boolean") {
                        } else {
                            valueForField = (util.str2int(valueForField) !== 0);
                        }
                    } else if (value.type === "EnumField") {
                        if (valueForField === "") {
                            valueForField = null;
                        }
                    }
                    result[nameField] = valueForField;
                }
            } else {
                if (!forMutation) {
                    result[nameField] = null;
                }
            }
        }
        return result;
    }


    getArrayFieldsMutation() {
        let allFields = this.getArrayFields();
        let result = {};
        for (let [field, value] of Object.entries(allFields)) {
            if (this[field] != null) {
                if (!value.readonly) {
                    result[field] = value;
                }
            }
        }

        return result;
    }

    getFieldsForGraphQLQuery() {
        return Object.keys(this.getArrayFields());
    }

    hasMutationIdParams() {
        return true;
    }

    hasQueryIdParams() {
        return true;
    }

    getGraphQLMutationUpdate() {
        let responseFieldsArr = this.getResponseFieldsFromMutation();
        let responseFieldsStr = responseFieldsArr.join(",");
        let {
            nameMainType,
            operationPascal,
            operationUpdate,
            inputType,
            inputVariable
        } = this.getVariablesMutation();
        let isWithIdHeader = ', $id: ID';
        let isWithIdBody = ', id: $id';
        if (!this.hasMutationIdParams()) {
            isWithIdHeader = "";
            isWithIdBody = "";
        }
        let queryDebug = this.getQueryLogger();
        let result = `
            mutation ${operationPascal}(${inputVariable}: ${inputType}! ${isWithIdHeader}) {
                ${operationUpdate}(${nameMainType}: ${inputVariable}${isWithIdBody}) {
                    ${responseFieldsStr}
                }
                ${queryDebug}
            }
        `;
        return result;
    }

    getGraphQLMutationDelete() {
        let {
            operationPascalDelete,
            operationDelete,
        } = this.getVariablesMutation();
        let i = this.indexVariableQuery || "";
        let result = gql`
            mutation ${operationPascalDelete}( $id: ID) {
                ${operationDelete}(id: $id) {
                id
            }
            }
        `;
        return result;
    }


    getVariablesMutation() {
        let nameMainType = this.nameMainType;
        let nameMainTypePascal = util.toPascalCase(nameMainType);
        let operationPascal = "CreateOrModify" + nameMainTypePascal;
        let operationUpdate = nameMainType + "Update";
        let operationCreate = nameMainType + "Create";
        let operationDelete = nameMainType + "Delete";
        let operationPascalDelete = util.toPascalCase(operationDelete);
        let inputType = nameMainTypePascal + "InputType";
        let inputVariable = "$" + nameMainType;
        return {
            nameMainType,
            nameMainTypePascal,
            operationPascal,
            operationUpdate,
            operationCreate,
            operationDelete,
            operationPascalDelete,
            inputType,
            inputVariable
        }
    }

    getGraphQLDeleteVariables() {
        let i = this.indexVariableQuery || "";
        let result = {
            ['id' + i]: this.id
        };
        return result;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLMutationDeleteBody() {
        let {
            operationDelete,
        } = this.getVariablesMutation();
        let i = this.indexVariableQuery || "";
        let result = `
                ${operationDelete}(id: $id${i}) {
                id
            }            
        `;
        return result;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLDeleteParameters() {
        let i = this.indexVariableQuery || "";
        return `$id${i}: ID`;
    }

    getQueryLogger() {
        let queryDebug = "";
        if (appState.layoutState.infoDebuggerEnabled) {
            queryDebug = "loggers { type time ellapsedMs message }";
        }
        return queryDebug;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindQuery() {
        let nameQuery = util.toPascalCase(this.graphFindOperation);
        let parameters = this.getGraphQLFindQueryParameters();
        let body = this.getGraphQLFindQueryBody();
        let queryDebug = this.getQueryLogger();
        let result = `
            query ${nameQuery}(${parameters}) {
                ${body}
                ${queryDebug}
            }
        `;
        return result;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLSubsciptionQuery() {
        let body = this.getGraphQLSubcriptionQueryBody();
        let subcription = ` subscription {${body}}`;
        let result = gql`
            ${subcription}
        `;
        return result;
    }

    getGraphQLSubcriptionQueryBody() {
        let {nameMainType} = this.getGraphQLMutationVariables();
        let filters = this.getFiltersForSubscriptions(this.getFiltersForGraph());
        let fields = this.getFieldsForSubscription();
        let result = `
                ${nameMainType}(query:{filters:${filters}}) {
                    ${fields}
            }            
        `;
        return result;
    }

    getFiltersForSubscriptions(filters) {
        let result = "[";
        let i = 0;
        for (let obj of filters) {
            result += "{";
            for (let [key, value] of Object.entries(obj)) {
                if (key === "filterOperator") {
                    result += `${key}:${value}`;
                } else {
                    result += `${key}:"${value}",`;
                }
            }
            if (++i === filters.length) {
                result += "}"
            } else {
                result += "},"
            }
        }
        result += "]";
        return result;
    }

    getGraphQLMutationVariables() {
        let i = this.indexVariableQuery || "";
        let nameMainType = this.nameMainType;
        let nameQuery = util.toPascalCase(this.graphFindOperation);
        let nameMainTypePascal = util.toPascalCase(nameMainType);
        let operationPascal = "CreateOrModify" + nameMainTypePascal;
        let operationUpdate = nameMainType + "Update";
        let operationCreate = nameMainType + "Create";
        let operationDelete = nameMainType + "Delete";
        let operationPascalDelete = util.toPascalCase(operationDelete);
        let inputType = nameMainTypePascal + "InputType";
        let inputVariable = "$" + nameMainType;
        return {
            nameMainType,
            nameMainTypePascal,
            operationPascal,
            operationUpdate,
            operationCreate,
            operationDelete,
            operationPascalDelete,
            inputType,
            nameQuery,
            inputVariable
        }
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLMutateBody() {
        let responseFieldsArr = this.getResponseFieldsFromMutation();
        let responseFieldsStr = responseFieldsArr.join(",");
        let {
            operationUpdate, nameMainType
        } = this.getVariablesMutation();
        let i = this.indexVariableQuery || 0;
        let result = `
              ${nameMainType}${i}: ${operationUpdate}(${nameMainType}:${'$' + nameMainType}${i},id: $id${i}) {
                  ${responseFieldsStr}
            }            
        `;
        return result;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLMutateVariables() {
        let i = this.indexVariableQuery || 0;
        let modelToSavePlain = this.toPlainObjectMutation();
        let modelToSavePrevious = this._fieldsOnLoad;
        for (let key in modelToSavePrevious) {
            if (modelToSavePrevious[key] === modelToSavePlain[key]) {
                delete modelToSavePlain[key];
            }
        }
        delete modelToSavePlain["id"];
        let variablesCurr = {['id' + i]: this.id || null};
        variablesCurr[this.nameMainType + i] = modelToSavePlain;
        return variablesCurr;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLMutateParameters() {
        let {
            inputType, nameMainType
        } = this.getVariablesMutation();
        let i = this.indexVariableQuery || 0;
        return `${'$' + nameMainType}${i}: ${inputType}!,$id${i}: ID`;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindQueryParameters() {
        let i = this.indexVariableQuery || "";
        return `$query${i}: QueryInputType, $sort${i}: SortInputType, $first${i}: Int, $page${i}: Int, $after${i}: String, $before${i}: String`;
    }

    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindQueryBody() {
        let graphOperation = this.graphFindOperation;
        let fields = this.getFieldsForGql();
        let i = this.indexVariableQuery || "";
        let result = `
                ${graphOperation}(query:$query${i}, sort: $sort${i}, first: $first${i}, page: $page${i}, after: $after${i}, before: $before${i}) {
                totalCount,
                pageInfo {
                    endCursor,
                    hasNextPage,
                    hasPreviousPage,
                    startCursor
                },
                items {
                    ${fields}
                }
            }            
        `;
        return result;
    }

    getGraphQLQueryFindVariables() {
        let filters = this.getFiltersForGraph();
        let q = this.refineWhere["q"];
        let first = this.first;
        let order = this.orderBy;
        let orderMode = this.orderMode;
        let after = null;
        let before = null;
        let page = 1;
        let i = this.indexVariableQuery || "";
        let result = {};
        result["query" + i] = {
            q,
            filters
        };
        result["sort" + i] = {
            field: order,
            orderMode: orderMode
        };
        result["page" + i] = page;
        result["first" + i] = first;
        result["after" + i] = after;
        result["before" + i] = before;

        return result;
    }

    getFieldsForSubscription() {
        let result = "";
        for (let [nameField, objParam] of Object.entries(this.getArrayFields())) {
            if (objParam.subscription) {
                if (!(this.disctinctQuery && nameField === "id")) {
                    result += "," + nameField;
                }
            }
        }
        if (result != "") {
            result = result.substring(1);
        }
        return result;
    }


    getFieldsForGql() {
        return this.getFieldsForGqlFromArray(this.getArrayFields());
    }

    getFieldsForGqlFromArray(fieldsImArray) {
        let result = "";
        // this.log({
        //     fieldsImArray
        // });
        for (let [nameField, objParam] of Object.entries(fieldsImArray)) {
            let arrayFields = objParam["arrayFields"];
            let Relation = objParam["childType"];
            //Si tengo arrayFields los añado. Si no los tengo los busco del objeto
            if (objParam.type === "Relation" || objParam.type === "RelationOne") {
                if (arrayFields != null) {
                    result += "," + nameField;
                    result += "{" + this.getFieldsForGqlFromArray(arrayFields) + "}";
                } else if (Relation != null) {
                    let relation = new Relation();
                    if (relation) {
                        result += "," + nameField;
                        let getArrayFields = relation.getArrayFields();
                        let onlyFields = this._relatedTables[nameField];
                        //Añado las tablas relacionadas que empiecen por este nombre de campo
                        //Aqui añade las tablas relacionadas de la relacion
                        this.addRelatedTablesToModel(nameField, relation);
                        if (onlyFields?.length === 0) {
                            for (let relationName of Object.keys(this._relatedTables)) {
                                if (relationName.startsWith(nameField + ".")) {
                                    let fieldNameRelation = relationName.substr(nameField.length + 1);
                                    fieldNameRelation = util.getDelim(fieldNameRelation, ".", 0);
                                    getArrayFields[fieldNameRelation] = relation.getArrayFields()[fieldNameRelation];
                                }
                            }
                            result += "{" + relation.getFieldsForGqlFromArray(getArrayFields) + "}";
                        } else {
                            let relatedTable = this._relatedTables[nameField];
                            if (relatedTable.onlyQueryFields.length > 0) {
                                getArrayFields = {};
                            }
                            for (let relationName of Object.keys(this._relatedTables)) {
                                if (relationName.startsWith(nameField + ".")) {
                                    let fieldNameRelation = relationName.substr(nameField.length + 1);
                                    fieldNameRelation = util.getDelim(fieldNameRelation, ".", 0);
                                    getArrayFields[fieldNameRelation] = relation.getArrayFields()[fieldNameRelation];
                                }
                            }
                            for (let field of relatedTable.onlyQueryFields) {
                                getArrayFields[field] = relation.getArrayFields()[field]
                            }
                            // this.log({
                            //     getArrayFields, nameField, relation, thisrelatedtable: this._relatedTables,relatedTable
                            // });
                            let params = "";
                            if (relatedTable.args != "") {
                                params = "(" + relatedTable.args + ")";
                            }
                            result += params + "{" + relation.getFieldsForGqlFromArray(getArrayFields) + "}";

                            // result += params + "{" + getArrayFields.join(",") + "}";
                        }
                    }
                }
            } else {
                if (!(this.disctinctQuery && nameField === "id") && !objParam.mutationOnly) {
                    result += "," + nameField;
                }
            }
        }
        if (result != "") {
            result = result.substring(1);
        }
        return result;
    }


    /**
     * DEvuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLFindByIdQuery() {
        let nameQuery = util.toPascalCase(this.graphFindByIdOperation);
        let graphOperation = this.graphFindByIdOperation;
        let fields = this.getFieldsForGql();
        let queryDebug = this.getQueryLogger();
        let result;
        if (this.hasQueryIdParams()) {
            result = `
            query ${nameQuery}($id: ID) {
                ${graphOperation}(id:$id) {
                    ${fields}
                }
                ${queryDebug}
            }
        `;
        } else {
            result = `
            query ${nameQuery} {
                ${graphOperation} {
                    ${fields}
                }
                ${queryDebug}
            }
        `;
        }
        return result;
    }

    getFiltersForGraph() {
        let fields = this.getArrayFields();
        let filters = [];
        if (this.filters != null) {
            filters = this.filters.slice(0)
        }
        for (let [key, value] of Object.entries(fields)) {
            if (util.hasValue(this[key]) && key !== "saveOptions") {
                let value = this[key];
                if (value) {
                    value = "" + value;
                }
                filters.push({fieldName: key, filterOperator: "EQ", fieldValue: value});
            }
        }
        return filters;
    }

    newModel() {
        return Object.create(this);
    }

    async findFirst() {
        let allResults = await this.findLimit(1, 1);
        let result = null;
        if (allResults.length > 0) {
            result = allResults[0];
        }
        return result;
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async find() {
        let variables = this.getGraphQLQueryFindVariables();
        return await this.findImpl(variables);
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findPlainObject() {
        let models = await this.find();
        return models.map(model => model.toPlainObject())
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async exportExcelOrCsv(format, q, fieldsName, fieldsLabel, page, pagination) {
        let variables = this.getGraphQLExportVariables(format, q, fieldsName, fieldsLabel, page, pagination);
        this.log(variables);
        let apolloProxy = new ApolloProxy(apolloClient);
        let resultQuery = await apolloProxy.graphQuery({
            query: this.getGraphQLExport(),
            variables,
        });
        return await resultQuery.data[this.graphExportOperation];
    }

    /**
     * Devuelve el GraphQL que se ejecuta en este listado
     */
    getGraphQLExport(q) {
        let nameQuery = util.toPascalCase(this.graphExportOperation);
        let graphOperation = this.graphExportOperation;
        let result = gql`
            query ${nameQuery}($query: QueryInputType, $sort: SortInputType, $fields:String,$format: FormatEnumType) {
                ${graphOperation}(query:$query, sort: $sort, fields:$fields,format: $format)
            }
        `;
        return result;
    }


    getGraphQLExportVariables(format, q, fieldsName, fieldsLabel) {
        let filters = this.getFiltersForGraph();
        let order = this.orderBy;
        let orderMode = this.orderMode;
        let objFields = {
            title: "test",
            fieldsLabel: fieldsLabel.join(","),
            fieldsName: fieldsName.join(",")
        };
        return {
            query: {
                q,
                filters
            },
            fields: JSON.stringify(objFields),
            sort: {
                field: order,
                orderMode: orderMode
            },
            format: format
        };
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findLimit(fromPage, pageSize) {
        let variables = this.getGraphQLQueryFindVariables();
        let i = this.indexVariableQuery || "";
        variables["first" + i] = pageSize;
        variables["page" + i] = fromPage;

        return await this.findImpl(variables);
    }


    subscription(onNextData) {
        return this.subscriptionImpl(onNextData);
    }

    subscriptionImpl(onNextData) {
        let apolloProxy = new ApolloProxy(apolloClient);
        let resultSubscription = apolloProxy.subscribe({
            query: this.getGraphQLSubsciptionQuery(),
        });
        // this.log({resultSubscription});
        let fetchResultObservable = resultSubscription.subscribe({
            next: ({data}) => {
                if (onNextData != null) {
                    onNextData(data);
                }
            },
            error: (err) => {
                this.log({msg: "Subscription Error", err});
            },
        });
        return fetchResultObservable;
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findImpl(variables) {
        let apolloProxy = new ApolloProxy(apolloClient);
        let gqlQueryRaw = this.getGraphQLFindQuery();
        let gqlQuery = gql`${gqlQueryRaw}`;
        let resultQuery = await apolloProxy.graphQuery({
            query: gqlQuery,
            queryRaw: gqlQueryRaw,
            variables,
        });
        let operationName = this.graphFindOperation;
        let result = [];
        if (resultQuery && resultQuery.data && resultQuery.data[operationName]) {
            result = this.getResultQueryToArray(resultQuery.data[operationName]);
        }
        return result;
    }

    getResultQueryToArray(dataFromOperationName) {
        let result = [];
        if (dataFromOperationName.items) {
            let rows = dataFromOperationName.items;
            result = [];
            for (let row of rows) {
                let model = this.newModel();
                model._relatedTables = this._relatedTables;
                model.hidrate(row);
                model._fieldsOnLoad = model.toPlainObjectMutation();
                result.push(model);
            }
            //Asigno a este objeto el valor del total de elementos para poder consultarlo posteriormente
            this.totalCount = dataFromOperationName.totalCount;
            this.pageInfo = dataFromOperationName.pageInfo;
        }
        return result;
    }

    /**
     * Devuelve un array con los elementos que cumplan la condición
     */
    async findById(id) {
        if (util.esVacio(id)) {
            throw new AppException("findById con valor nulo");
        }
        let apolloProxy = new ApolloProxy(apolloClient);
        let queryRaw = this.getGraphQLFindByIdQuery();
        let query = gql`${queryRaw}`;
        let resultQuery = await apolloProxy.graphQuery({
            query,
            queryRaw,
            variables: {id: id},
            //errorPolicy:'all' //Las queries no lanzan excepciones a pesar de tener este parametro. solo mutations
        });
        let operationName = this.graphFindByIdOperation;

        let result = null;
        if (resultQuery && resultQuery.data && resultQuery.data[operationName]) {
            let newData = resultQuery.data[operationName];
            if (newData != null) {
                let model = this.newModel();
                model._relatedTables = this._relatedTables;
                model.hidrate(newData);
                model._fieldsOnLoad = model.toPlainObjectMutation();
                result = model;
            }
        }
        return result;
    }

    async findByIdNotNull(id) {
        let result = await this.findById(id);
        if (result == null) {
            result = this.newModel();
        }
        return result;
    }


    async remove() {
        let apolloProxy = new ApolloProxy(apolloClient);
        let variables = {id: this.id};
        let mutation = this.getGraphQLMutationDelete();
        const resultQuery = await apolloProxy.mutate({mutation, variables});
        console.log({resultQuery});
    }

    addRelatedTableOnlyFields(tableName, relatedTableConfig) {
        this._relatedTables[tableName] = relatedTableConfig;
    }

    addRelatedTable(tableName) {
        this._relatedTables[tableName] = [];
    }

    getTableName() {
        return this.nameMainType;
    }

    getNameModelInDotNet() {
        let nameMainTypePascal = util.toPascalCase(this.nameMainType);
        let nombreModelo = nameMainTypePascal;
        return "dotnetwebapi.Modules.Models." + nombreModelo;
    }

    getNameModelInDotNetTables() {
        return util.toPascalCase(this.nameMainType);
    }

    findCreatablesReadables() {
        let userMe = appState.userState.userMe;
        let result = {creatable: {}, readable: {}};
        if (userMe?.access?.length > 0) {
            for (let obj of userMe.access) {
                result.creatable[util.firstLowerCase(obj.model)] = obj?.creatable;
                result.readable[util.firstLowerCase(obj.model)] = obj?.readable;
            }
        }
        return result;
    }

    getCodeNumeric(prefix: string, zeroes_Ammount: int) {
        return prefix.toUpperCase() + "-" + this.generateRandomCode(zeroes_Ammount).toUpperCase();
    }

    generateRandomCode(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
