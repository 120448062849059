import AbstractModel from "./AbstractModel";

export default class SaveOptionsModel extends AbstractModel {

    NOT_SAVE_WITH_WARNINGS = "NOT_SAVE_WITH_WARNINGS";
    SAVE_WITH_WARNINGS = "SAVE_WITH_WARNINGS";

    saveOptions = this.SAVE_WITH_WARNINGS;

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "saveOptions": { noImport: true, mutationOnly: true },
        };
        return result;
    }

}
