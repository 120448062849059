import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import { observable } from 'mobx'
import TextAreaComponent from '../../components/fields/TextAreaComponent'
import Select2Component from '../../components/fields/Select2Component'
import appState from '../../state/AppState'
import FormButtons from '../../components/FormButtons'
import PageTitle from '../../components/PageTitle'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import TabsComponent from '../../components/fields/TabsComponent'
import PropsUtil from '../../util/PropsUtil'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import CreateAndModify from '../../components/fields/CreateAndModify'
import InfoBlock from '../../components/InfoBlock'
import MoreButtons from '../../components/MoreButtons'
import util from '../../util/Util'
import AppLogger from '../../util/AppLogger'
import translate from '../../translator/translate'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import BaseWorkOrderForm from '../base/BaseWorkOrderForm'
import GqlErrors from '../../components/status/GqlErrors'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import SwitchComponent from '../../components/fields/SwitchComponent'
import WorkOrderModel from '../../models/WorkOrderModel'
import OrderModel from '../../models/OrderModel'
import WorkOrderActions from './WorkOrderActions'
import WorkLogModel from '../../models/WorkLogModel'
import OFFER from '../../config/OFFER'
import ClientModel from '../../models/ClientModel'
import VsfButtonNew from '../../components/VsfButtonNew'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import FetchProxy from '../../network/FetchProxy'
import GraphException from '../../network/GraphException'
import config from '../../config/config'
import CommentsDispatcherTable from '../../components/CommentsDispatcherTable'

@observer
class WorkOrderForm extends BaseWorkOrderForm {

    @observable workOrderSent = false;
    @observable textoEnviar = "Enviar Finalización a ERP";
    @observable textPopUp = "¿Seguro que desea enviar a Netsuite la orden de trabajo?";
    @observable textData = "";

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        let id = this.getFormId();
        let workOrder = this.graphDataMainType;
        let objDict = appState.workLogState.loadWorkLogsOfWorkOrderWarrantyPossible(workOrder, WorkLogModel.WARRANTY_POSSIBLE)
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (this.graphStatus.mutationError > 0) {
            this.state.errorFormulario = true;
        }
        let modalClasses = util.getModalClasses();
        if (this.props.fromOrderForm) {
            modalClasses = {};
        }
        let relatedInfo = "";
        if (util.hasValue(workOrder.asset?.manufacturer)) {
            relatedInfo = util.getString(workOrder.asset?.manufacturer);
        }
        if (util.hasValue(workOrder.asset?.model)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.model);
        }
        if (util.hasValue(workOrder.asset?.serialNumber)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.serialNumber);
        }
        if (util.hasValue(workOrder.asset?.posAddress)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.posAddress);
        }
        if (util.hasValue(workOrder.asset?.location)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.location);
        }
        if (util.hasValue(workOrder.asset?.status)) {
            relatedInfo += " - " + appState.typifiedState.getValue(workOrder.asset?.status);
        }
        let orderModel = new OrderModel();
        orderModel.addRelatedTable("originModel");
        orderModel.hidrate(workOrder.order);
        let periodOptions = [
            {label: t("Días"), value: WorkOrderModel.CODE_DAYS},
            {label: t("Meses"), value: WorkOrderModel.CODE_MONTHS},
            {label: t("Años"), value: WorkOrderModel.CODE_YEARS},
        ];
        let revisedOptions = [
            {label: "Pendiente de Revisión", value: WorkOrderModel.CODE_REVISED_PENDING},
            {label: t("Sí"), value: WorkOrderModel.CODE_REVISED},
            {label: t("No"), value: WorkOrderModel.CODE_NOT_REVISED},
        ];
        let optionsYorN = [
            {label: t("Sí"), value: WorkOrderModel.CODE_REVISED},
            {label: t("No"), value: WorkOrderModel.CODE_NOT_REVISED},
        ];
        let optionsType = orderModel.getValuesForSelect(TypifiedValueModel.CODE_WORK_ORDER_TYPE);
        let config = {};
        if (appState.typifiedState.getObject(workOrder.type).config) {
            config = JSON.parse(appState.typifiedState.getObject(workOrder.type).config);
        }
        let comentarioReturnWorkLog = "";
        if (util.hasValue(workOrder.reasonReturnWorkLog?.id)) {
            try {
                let workLogModel = new WorkLogModel();

                for (let obj of JSON.parse(workOrder.reasonReturnWorkLog.data)[workLogModel.nameForData(WorkLogModel.RETURN_WORK_ORDER)]) {
                    if (util.hasValue(obj.comment)) {
                        comentarioReturnWorkLog = obj.comment
                    }
                }
            } catch (e) {
                this.log({JsonParseErr: 1, e})

            }
        }

        let concatLabelAsset = "";
        if (workOrder?.asset?.serialNumber) {
            concatLabelAsset += " - " + workOrder?.asset?.serialNumber
        }
        if (workOrder?.asset?.status) {
            concatLabelAsset += " - " + appState.typifiedState.getValue(workOrder?.asset?.status)
        }
        let workLogModel = new WorkLogModel();
        let commentsDispatcher = workLogModel.getWorkLogsFromAction(workOrder, WorkLogModel.COMMENTS_DISPATCHER);
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmit(e)}>

                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                    title={this.getPageTitle()}
                                    subtitle={this.getPageSubtitle()}
                                    mode={"edit"}
                                    onCloseModal={() => this.onCloseModal()}>
                                    <MoreButtons>
                                        <WorkOrderActions fromRightModal={this.props.fromRightModal}
                                            setErrors={(errors) => this.setErrors(errors)}
                                            model={workOrder}
                                            getModelQuery={() => this.getModelQuery()}/>
                                    </MoreButtons>
                                </PageTitleRightModal>
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                            >
                                <MoreButtons onReloadData={() => this.reloadData()}>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                        getModelQuery={() => this.getModelQuery()}/>
                                </MoreButtons>
                            </PageTitle>
                        }


                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className={modalClasses.body}>
                                    {!this.props.fromRightModal &&
                                    <TabsComponent
                                        id={this.graphDataMainType.id || this.props.id}
                                        tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                        active={"Datos Generales"}
                                        fromRightModal={this.props.fromRightModal}
                                    />
                                    }
                                    <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                        error={this.graphStatus.mutationError}
                                        working={this.graphStatus.networkWorking}
                                    />

                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)}/>
                                    <GroupOfField
                                        title={t('Datos.')}
                                        subtitle={t('Orden de trabajo')}
                                        icon="fas fa-file-alt">
                                        <CreateAndModify
                                            updatedAt={workOrder.updatedAt}
                                            updatedBy={workOrder.updatedBy}
                                            createdBy={workOrder.createdBy}
                                            createdAt={workOrder.createdAt}
                                            status={workOrder.status}
                                            code={workOrder.order?.code || this.propsUtil.getRequest('orderCode')}
                                            client={workOrder.client?.name || this.propsUtil.getRequest('clientName')}
                                            getFormId={workOrder.id}
                                        />
                                        <Select2Component
                                            value={util.hasValue(workOrder.status) ? appState.typifiedState.getValue(workOrder.status) :
                                                appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, WorkLogModel.PENDING).id}
                                            title={t("Estado")}
                                            editable={this.editable}
                                            name={"status"}
                                            excludeValue={appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, WorkLogModel.PAUSE_WORK_ORDER).id}
                                            readOnly={!util.hasValue(workOrder.id)}
                                            isClearable={false}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            classGroup={"col-md-4"}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_WORKORDER_STATUS)}
                                            type={"text"}
                                        />
                                        {/*{(OFFER.netsuiteIntegration &&*/}
                                        {/*workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id &&*/}
                                        {/*workOrder.erpFinished != "Y") &&*/}
                                        {/*<>*/}
                                        {/*<VsfButtonNew className="btn btn--orange mt-3 col-2"*/}
                                        {/*label={"Enviar a ERP"}*/}
                                        {/*onClick={() => this.setState({ sendWorkOrder: true })} />*/}
                                        {/*<div className="col-5" />*/}
                                        {/*</>*/}
                                        {/*}*/}
                                        {/*{(OFFER.netsuiteIntegration &&*/}
                                        {/*workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id &&*/}
                                        {/*workOrder.erpFinished === "Y") &&*/}
                                        {/*<div className="text-success col-8">{"Orden de Trabajo enviada."}</div>*/}
                                        {/*}*/}

                                        {!appState.userState.isUserRoleClient() && (OFFER.netsuiteIntegration && workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id) &&
                                        <>
                                            <div className="links-row col-8 mt-3">
                                                <VsfButtonNew className="btn btn--orange"
                                                    disabled={workOrder.erpFinished === "Y"}
                                                    onClick={() => {
                                                        if (!appState.layoutState.formWithoutChanges) {
                                                            this.setState({modalChangeOpen: true})
                                                        } else {
                                                            this.workOrderSent = false;
                                                            this.setState({sendWorkOrder: true})
                                                        }
                                                    }}>
                                                    {t(this.textoEnviar)}
                                                </VsfButtonNew>
                                            </div>
                                            {this.state.modalChangeOpen &&
                                            <AlertModal isOpen={this.state.modalChangeOpen}
                                                onCloseModal={() => this.cancelChangeUrl()}>
                                                <PopUp
                                                    title={t('Estás saliendo sin guardar')}
                                                    icon="exclamationTriangleIcon"
                                                    text={t('Antes de enviar e ERP es necesario guaradar los cambios en el formualrio')}
                                                    label1={'Salir sin guardar'}
                                                    label2={'Guardar cambios'}
                                                    labelGuardar={true}
                                                    onClick1={() => this.setState({modalChangeOpen: false})
                                                    }
                                                    onClick2={() => {
                                                        this.setState({modalChangeOpen: false});
                                                        appState.layoutState.formWithoutChanges = true;
                                                        this.handleFormSubmit();
                                                    }}
                                                />
                                            </AlertModal>
                                            }
                                        </>
                                        }

                                        {(workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "RETURN_WORK_ORDER").id) &&
                                        <>
                                            <Select2Component
                                                value={appState.typifiedState.getValue(workOrder.reasonReturnId)}
                                                title={t("Motivo de Devolución")}
                                                readOnly={util.hasValue(workOrder.reasonReturnId)}
                                                relatedInfo={comentarioReturnWorkLog}
                                                name={"reasonReturnId"}
                                                classGroup={"col-md-4"}
                                                editable={this.editable}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_CAUSES_PAUSE)}
                                                type={"text"}
                                            />
                                            <Select2Component
                                                value={appState.typifiedState.getValue(workOrder.returnStatusId)}
                                                title={t("Estado - Seguimiento de Devolución")}
                                                classGroup={"col-md-4"}
                                                name={"returnStatusId"}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                editable={this.editable}
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_RETURN_STATUS)}
                                                type={"text"}
                                            />
                                        </>

                                        }
                                        {!(workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id) &&
                                        !(workOrder.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "RETURN_WORK_ORDER").id) &&
                                        <div className={"col-8"}/>
                                        }
                                        <Select2Component
                                            value={workOrder.type}
                                            onChange={(e) => this.updateInputEventWorkOrderType(e)}
                                            editable={this.editable}
                                            name={"type"}
                                            isClearable={false} required
                                            title={t("Tipo Orden de Trabajo")}
                                            classGroup={"col-md-4 col-lg-4"}
                                            options={optionsType}
                                            errors={errorsMapped.type}
                                        />
                                        <Select2Component
                                            value={workOrder.subType}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"subType"}
                                            editable={this.editable}
                                            readOnly={!util.hasValue(workOrder.type)}
                                            title={t("Subtipo Orden de Trabajo")}
                                            classGroup={"col-md-4 col-lg-4"}
                                            options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_WORK_ORDER_TYPE, workOrder.type)}
                                            errors={errorsMapped.subType}
                                        />
                                        <div className="col-4"/>
                                        <Select2Component
                                            value={util.hasValue(workOrder.assetId) ?
                                                workOrder.asset?.type
                                                : workOrder.assetType}
                                            onChange={(e) => this.updateInputEventAssetType(e)}
                                            name={"assetType"}
                                            isClearable={false}
                                            readOnly={util.hasValue(workOrder.assetId)}
                                            editable={this.editable}
                                            title={t("Tipo de Equipo")} required={config.asset === "1"}
                                            classGroup={"col-md-4 col-lg-4"}
                                            placeholder={t("Tipo de Equipo")}
                                            errors={errorsMapped.assetType}
                                            options={orderModel.getValuesForSelect(TypifiedValueModel.CODE_ASSET_TYPE)}
                                        />
                                        <Select2Component
                                            value={util.hasValue(workOrder.assetId) ?
                                                workOrder.asset?.subType
                                                : workOrder.assetSubType}
                                            onChange={(e) => this.changeAssetSubtype(e)}
                                            name={"assetSubType"}
                                            title={t("Subtipo de Equipo")}
                                            placeholder={t("Subtipo de Equipo")}
                                            readOnly={util.hasValue(workOrder.assetId) || !util.hasValue(workOrder.assetType)}
                                            editable={this.editable}
                                            classGroup={"col-md-4 col-lg-4"}
                                            errors={errorsMapped.assetSubType}
                                            options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_ASSET_SUBTYPE, workOrder.asset?.type || workOrder.assetType)}
                                            extraWidth={100}
                                        />
                                        <SelectSuggestComponent
                                            value={workOrder.asset?.plate}
                                            defaultTimeOut={2000}
                                            concatLabel={concatLabelAsset}
                                            onChange={(event) => {
                                                if (util.hasValue(event.target.value.id)) {
                                                    this.handleAutosuggestSelection("assetPlate", event.target.value.plate);
                                                    this.handleAutosuggestSelection("assetId", event.target.value.id);
                                                    workOrder.asset = event.target.value;
                                                } else {
                                                    workOrder.asset = {};
                                                    this.handleAutosuggestSelection("assetId", "");
                                                }
                                            }}
                                            loadSuggestions={(value, firstCall) => this.getAssetsSugestions(value, firstCall)}
                                            placeholder={t("Escribe...")}
                                            name={"assetPlate"}
                                            changeSelect={this.reloadSelect}
                                            readOnly={appState.userState.isUserRoleClient()}
                                            title={t("Número de Equipo")}
                                            editable={this.editable}
                                            classGroup={"col-md-4 col-lg-4"}
                                            errors={errorsMapped.assetPlate}
                                            info={t('Matrícula o Placa')}
                                            relatedInfo={relatedInfo}
                                        />
                                        <div className="col-12">
                                            <div className="mb-3">
                                                {util.hasValue(workOrder.assetId) ?
                                                    <span className="link-underline ico ico--edit"
                                                        onClick={() => this.onEditarAsset(workOrder)}
                                                    >{t("Editar Datos del Equipo")}</span>
                                                    :
                                                    <span
                                                        className="link-underline disabled prohibido ico ico--edit"
                                                    >{t("Editar Datos del Equipo")}</span>
                                                }
                                            </div>
                                        </div>
                                        <Select2Component
                                            value={workOrder.symptom}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"symptom"}
                                            editable={this.editable}
                                            title={t("Síntomas de la Avería")}
                                            classGroup={"col-md-6 col-lg-6 col-xl-6"}
                                            options={orderModel.getValuesForSelect(TypifiedValueModel.CODE_SYMPTHOM)}
                                            errors={errorsMapped.symptom}
                                        />
                                        <InputTypeComponent
                                            value={workOrder.symptomOther}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"symptomOther"}
                                            title={t("Otros Síntomas")}
                                            editable={this.editable}
                                            placeholder={t("Otros Síntomas")}
                                            classGroup={"col-md-6 col-lg-6 col-xl-6"}
                                            type={"text"}
                                            errors={errorsMapped.symptomOther}
                                        />
                                        <TextAreaComponent
                                            value={workOrder.generalDescription}
                                            title={t('Descripción de la Avería')}
                                            editable={this.editable}
                                            name={"generalDescription"}
                                            placeholder={t('Ej. No enfría lo suficiente')}
                                            classGroup={'col-12'}
                                            onChange={(e) => this.updateInputEvent(e)}
                                        />
                                    </GroupOfField>
                                    <CommentsDispatcherTable
                                        onRef={ref => (this.commentsDispatcherRef = ref)}
                                        onChange={(e) => this.updateInputEventYesOrNoEnum(e)}
                                        commentsDispatcher={commentsDispatcher}
                                        graphDataMainType={this.graphDataMainType}
                                    />
                                    {!appState.userState.isUserRoleClient() &&
                                    <>
                                        <GroupOfField
                                            title={t('Resolución')}
                                            subtitle={t('Posible Garantía')}
                                            icon="fas fa-search">

                                            <div className="row col-12 ml-1">
                                                <p>{t("Aviso De Posible Garantía Por Técnico")}</p>
                                                <SwitchComponent
                                                    checked={workOrder.warrantyPossible == "Y"}
                                                    onChange={(e) => this.updateInputEventYesOrNoEnum(e)}
                                                    value={workOrder.warrantyPossible}
                                                    className={"col-12"} withValue
                                                    editable={this.editable}
                                                    name={"warrantyPossible"}
                                                    classGroup={"content-switch__item col-3"}
                                                    flexChildren={"0"}
                                                    flexLabelInput="d-flex justify-content-flex-start"
                                                />
                                            </div>


                                            {workOrder.warrantyPossible == "Y" &&
                                            <>
                                                <InputTypeComponent
                                                    value={objDict.comment || "Sin comentarios"}
                                                    title={t("Comentarios del Técnico")}
                                                    placeholder={("Sin comentarios")}
                                                    readOnly={true}
                                                    classGroup={"col-md-12 col-lg-12 col-xl-12"}
                                                    type={"text"}
                                                />
                                                <Select2Component
                                                    value={workOrder.warrantyAccepted || WorkOrderModel.CODE_REVISED_PENDING}
                                                    onChange={(e) => {
                                                        this.updateInputEvent(e);
                                                        if (workOrder.warrantyPossible == "Y") {
                                                            if (workOrder.warrantyAccepted != WorkOrderModel.CODE_REVISED_PENDING) {
                                                                workOrder.warrantyRevised = WorkOrderModel.CODE_REVISED;
                                                            } else {
                                                                workOrder.warrantyRevised = WorkOrderModel.CODE_NOT_REVISED;
                                                            }
                                                        } else {
                                                            workOrder.warrantyRevised = WorkOrderModel.CODE_NOT_REVISED;
                                                            workOrder.warrantyAccepted = null;
                                                        }
                                                    }}
                                                    name={"warrantyAccepted"}
                                                    editable={this.editable}
                                                    placeholder={"Pendiente de Revisión"}
                                                    title={t('Garantía Aplicable')}
                                                    classGroup="col-md-4 col-lg-4"
                                                    ordenar={false}
                                                    options={revisedOptions}
                                                />
                                                <Select2Component
                                                    value={workOrder.warrantyRevised || WorkOrderModel.CODE_NOT_REVISED}
                                                    title={t("Garantía Revisada")}
                                                    classGroup={"col-md-4"}
                                                    name={"warrantyRevised"}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={true}
                                                    options={optionsYorN}
                                                    type={"text"}
                                                />

                                                {workOrder.warrantyAccepted === "Y" &&
                                                <>
                                                    <InputTypeComponent
                                                        value={workOrder.warrantyManufacturerCode}
                                                        onChange={(e) => this.updateInputEvent(e)}
                                                        name={"warrantyManufacturerCode"}
                                                        title={t("Código de Garantía de Fabricante")}
                                                        placeholder={"Código de Garantía de Fabricante"}
                                                        editable={this.editable}
                                                        classGroup={"col-md-4col-lg-4 col-xl-4"}
                                                        type={"text"}
                                                        errors={errorsMapped.warrantyManufacturerCode}
                                                    />
                                                    <SelectSuggestComponent
                                                        value={workOrder.warrantyManufacturerClientId}
                                                        onChange={(event) => {
                                                            let clientSelected = event.target.value;
                                                            if (util.hasValue(clientSelected.id)) {
                                                                this.handleAutosuggestSelection("warrantyManufacturerClientId", clientSelected.id);
                                                            } else {
                                                                this.handleAutosuggestSelection("warrantyManufacturerClientId", "");
                                                            }
                                                        }}
                                                        name={"warrantyManufacturerClientId"}
                                                        title={t("Cliente Fiscal")}
                                                        classGroup={"col-md-4 col-lg-4"}
                                                        editable={this.editable}
                                                        required={true}
                                                        errors={errorsMapped.warrantyManufacturerClientId}
                                                        loadSuggestions={(value, firstCall) => this.getPropietarysSuggestions(value, firstCall)}
                                                        placeholder={t("Escribe...")}
                                                    />
                                                </>
                                                }

                                                <InputTypeComponent
                                                    value={workOrder.warrantyComments}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"warrantyComments"}
                                                    title={t("Comentarios sobre la Resolución de la Garantía")}
                                                    classGroup={workOrder.warrantyAccepted === "Y" ? "col-md-8 col-lg-8" : "col-md-12 col-lg-12"}
                                                    type={"text"}
                                                    errors={errorsMapped.warrantyComments}
                                                />
                                                <InputTypeComponent
                                                    value={workOrder.warrantyInternalComments}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"warrantyInternalComments"}
                                                    title={t("Comentarios Internos")}
                                                    classGroup={"col-md-12 col-lg-12"}
                                                    type={"text"}
                                                    errors={errorsMapped.warrantyInternalComments}
                                                />
                                            </>
                                            }
                                        </GroupOfField>

                                        <GroupOfField
                                            title={t('Finalización')}
                                            subtitle={t('Orden de trabajo')}
                                            icon="fas fa-file-alt">
                                            <p className="o-parraf col-12 js-info-related admin ">{t("Ayuda Creación de Orden de Trabajo Automática")}</p>
                                            <SwitchComponent
                                                checked={workOrder.automaticNewWorkOrder === WorkOrderModel.CODE_AUTOMATIC_YES}
                                                onChange={(e) => this.updateInputEventSwitch(e)} withValue
                                                name={"automaticNewWorkOrder"}
                                                value={workOrder.automaticNewWorkOrder}
                                                className={"col-12"}
                                                editable={this.editable}
                                                title={t("Crear nueva orden de trabajo automática")}
                                                classGroup={"content-switch__item col-3"}
                                                flexChildren={"0"}
                                                flexLabelInput="d-flex justify-content-flex-start"
                                            />
                                            <div className="col-9"/>
                                            {workOrder.automaticNewWorkOrder === WorkOrderModel.CODE_AUTOMATIC_YES &&
                                            <>
                                                <Select2Component
                                                    value={workOrder.automaticWorkOrderTypeId}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"automaticWorkOrderTypeId"}
                                                    editable={this.editable}
                                                    title={t("Tipo de Orden de Trabajo")} ordenar={false}
                                                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                                                    options={appState.typifiedState.typifiedValuesForSelectPiramidal(TypifiedValueModel.CODE_WORK_ORDER_TYPE)}
                                                    errors={errorsMapped.automaticWorkOrderTypeId}
                                                />
                                                <Select2Component
                                                    value={workOrder.automaticPeriod}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"automaticPeriod"}
                                                    editable={this.editable}
                                                    title={t("Periodo")}
                                                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                                                    options={periodOptions}
                                                    errors={errorsMapped.automaticPeriod}
                                                />
                                                <InputTypeComponent
                                                    value={workOrder.automaticDuration}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"automaticDuration"}
                                                    title={t("Duración")}
                                                    editable={this.editable}
                                                    placeholder={t("Número de días, meses, años...")}
                                                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                                                    type={"numeric"}
                                                    errors={errorsMapped.automaticDuration}
                                                />
                                            </>
                                            }
                                        </GroupOfField>
                                    </>
                                    }

                                    <AlertModal isOpen={this.state.sendWorkOrder}
                                        onCloseModal={() => this.closeWorkOrderFinished()}>
                                        <PopUp
                                            title={t('Enviar una Orden de Trabajo')}
                                            icon="exclamationTriangleIcon"
                                            text={this.textPopUp} hideButton1={this.workOrderSent}
                                            label1={'Cerrar'} loading3={this.state.loadingSending}
                                            loadedError3={this.gqlErrors?.length !== 0}
                                            label3={'Aceptar'}
                                            onClick1={() => this.closeWorkOrderFinished()}
                                            onClick3={() => {
                                                this.sendWorkOrderFinished(workOrder)
                                            }}
                                        />
                                    </AlertModal>

                                    {this.props.fromRightModal ?
                                        <>
                                            <div className={"fixed-bottom-space-maker"}/>
                                            <div className="fixed-bottom mr-3 mt-3">
                                                <FormButtons id={id} formStatus={this.graphStatus}/>
                                            </div>
                                        </>
                                        :
                                        <FormButtons id={id} formStatus={this.graphStatus}/>
                                    }
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>

                    </form>
                </InfoBlock>
            </div>
        )
    }

    closeWorkOrderFinished() {
        this.setState({sendWorkOrder: false});
        this.gqlErrors = [];
    }

    // sendWorkOrderFinished() {
    //     this.updateInput("erpFinished", "Y");
    //     this.handleFormSubmit();
    //     this.salesOrderSent = true;
    //     this.setState({ sendWorkOrder: false });
    // }

    async getPropietarysSuggestions(value, firstCall) {
        let clientArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let clientQuery = new ClientModel();
            if (firstCall) {
                clientQuery.id = this.graphDataMainType.propietaryId;
            } else {
                // clientQuery.isPos = false;
                clientQuery.filters = [
                    {"fieldName": "companyName", "fieldValue": value, "filterOperator": "SUBSTR"},
                    {"fieldName": "isPos", "fieldValue": "false", "filterOperator": "EQ"},
                ];
            }
            let client = await clientQuery.findPlainObject();
            client.map((client) => {
                let clientObject = {};
                clientObject.label = client.companyName;
                clientObject.value = client;
                clientArray.push(clientObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return clientArray;
    }


    updateInputEventSwitch(event) {
        let value = event.target.value;
        let name = event.target.name;
        if (value === WorkOrderModel.CODE_AUTOMATIC_YES) {
            value = WorkOrderModel.CODE_AUTOMATIC_NO
        } else {
            value = WorkOrderModel.CODE_AUTOMATIC_YES
        }
        let eventObj = {
            target: {
                value,
                name
            }
        }
        this.log({updateInputEventSwitch: 1, eventObj})
        this.updateInputEvent(eventObj);
    }

    async sendWorkOrderFinished(workOrder) {
        this.setState({loadingSending: true});
        let workOrderId = workOrder.id;
        const t = translate;
        let httpApi = new FetchProxy();
        httpApi.withAuthorization = true;
        try {
            let url;
            url = config.apiRestHostBaseUrl + "/workOrderFinished";
            let response = await httpApi.fetchUrlPost(url, {"workOrderId": workOrderId,});
            let responseJson = await response.json();
            if (responseJson.status !== "OK") {
                let gqlErrors = new GraphException().getErrorsFromException(responseJson);
                this.log({gqlErrors, responseJson});
                this.setState({loadingError: true, loading: false});
                this.gqlErrors = gqlErrors;
                workOrder.erpFinished = "N";
                this.textPopUp = t("Ha habido un error con el envío") + ".";
                // workOrder.integrationErpStatus = responseJson.status;
                // workOrder.integrationErpMessage = responseJson.data?.message
            } else {
                workOrder.erpFinished = "Y";
                this.textPopUp = t("Gracias, todo ha ido correctamente. Ya se ha envíado al ERP") + ".";
                this.textoEnviar = t("Finalización Enviada");
            }
            this.log({respuestaJson: 1, responseJson});

        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
            this.log(e);
            // workOrder.integrationErpStatus = WorkOrderModel.CODE_INTEGRATION_FAIL;
        }
        this.workOrderSent = true;
        this.setState({loadingSending: false});
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(WorkOrderForm)

