import React from 'react';
import {observer} from 'mobx-react';
import WorkLogModel from "../../models/WorkLogModel";
import BaseEditableRow from "../base/BaseEditableRow";
import {computed, toJS} from "mobx";
import appState from "../../state/AppState";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import GqlErrors from "../../components/status/GqlErrors";
import util from '../../util/Util'

@observer
class OrderTaskRow extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });

    constructor(props) {
        super(props);
        this.foreingKeyfield = "orderId";
        this.state = {
            loadingSlot: false,
        }

    }

    @computed
    get mobxListado() {
        return appState.workLogState.workLogsTasksOfOrder;
    }

    setMobxListado(newValue) {
        appState.workLogState.workLogsTasksOfOrder = newValue;
    }

    async componentDidMount() {
        await super.componentDidMount();
    }

    getModelTable() {
        return new WorkLogModel();
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }


    render() {
        let workLog = this.props.row;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);


        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors)=>this.setErrors(errors)} />
                <tr aria-rowspan="2"
                    style={{ 'borderBottom': 0 }}>
                    <td>
                        {workLog.id}
                    </td>
                    <td>
                        {workLog.value}
                    </td>
                    <td>
                        <div className="form-group">
                            <div className="remove-add">
                                <div onClick={() => this.onDeleteRow()} title="Eliminar" className="remove">
                                    <span className="fas fa-trash-alt"> </span>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </InfoBlock>
        );
    }

    onDeleteRow() {
        delete appState.workLogState.tasksSelectedDict[this.props.row.id];
        appState.workLogState.saveWorkLogOrder = true;
        let arrCopy = toJS(this.mobxListado);
        arrCopy.splice(this.props.rowIndex, 1);
        this.log({ arrCopy });
        this.setMobxListado(arrCopy);
        if (util.hasValue(this.props.order.id)) {
            this.props.saveOrderTasks()
        }
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default OrderTaskRow
