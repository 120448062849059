import React, {Component} from 'react';
import {observer} from 'mobx-react';
import translate from "../translator/translate";
import AppLogger from "../util/AppLogger";
import version from '../static_data/version';
import ReactTooltip from "react-tooltip";
import LocalStorage from "../network/LocalStorage";
import PropsUtil from "../util/PropsUtil";
import DebuggerComponent from "../components/DebuggerComponent";
import VsfLoadingBar from "../network/VsfLoadingBar";
import InfoBlockSwitch from "../components/InfoBlockSwitch";

@observer
class VsfFooter extends Component {

    constructor() {
        super();
        this.state = { "sidebarOpened": false };
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let localStorage = new LocalStorage();
        const t = translate;

        return (<>
            <DebuggerComponent />
            <footer className="web-footer">
                <div className="footer-poweredby col-2">
                    Powered by:
                    <img src="/img/poweredby-logo.png" alt="Nuddo" />
                    <span data-tip data-for={"version"}
                          onMouseEnter={(e) => {
                              this.setState({ mouseEnter: true });
                          }}>  {" v."}{localStorage.getItemTenantId('nuddoDbVersion')}{" c(" + version.version + ")"}</span>
                    {this.state.mouseEnter &&
                    <ReactTooltip id={"version"} textColor="#6C757D"
                                  backgroundColor="#ffffff" arrowColor="#f7efef" place={'bottom'}
                                  className="customReactTooltip">
                        {version.lastUpdate}
                    </ReactTooltip>
                    }

                </div>
                <nav className="footer-nav ml-auto col-10">
                    <ul className="nav">
                        <li><a href="#">{t("Politica de Privacidad")}</a></li>
                        <li><a href="#">{t("Aviso legal")}</a></li>
                        <li><a href="#">{t("Terminos de uso")}</a></li>
                    </ul>
                </nav>
            </footer>
            <VsfLoadingBar />
            <InfoBlockSwitch />
        </>)
    }
}

export default VsfFooter
