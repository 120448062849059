import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import BaseForm from '../base/BaseForm'
import HistoryComponent from '../HistoryComponent'
import AssetModel from '../../models/AssetModel'
import translate from '../../translator/translate'
import MoreButtons from '../../components/MoreButtons'
import AssetActions from './AssetActions'
import appState from '../../state/AppState'

@observer
class AssetHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.nameMainType = "asset";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
    }

    getModelQuery() {
        return new AssetModel();
    }

    getModelQueryForView() {
        let result = new AssetModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("store");
        result.addRelatedTable("clientPropietary");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let asset = this.graphDataMainType;
        const t = translate;
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.type).config)
        }

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Histórico"}
                            />
                            }

                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />


                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(AssetHistory);
