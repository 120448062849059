import React from 'react';
import {observer} from 'mobx-react';
import GroupOfField from "../components/layout/GroupOfField";
import PageTitle from "../components/PageTitle"
import BaseForm from "./base/BaseForm";
import FormButtons from "../components/FormButtons";
import NetworkStatusInfo from "../components/status/NetworkStatusInfo";
import translate from "../translator/translate";
import PropsUtil from "../util/PropsUtil";
import TabsComponent from "../components/fields/TabsComponent";
import TabsData from "../layout/TabsData";
import WorkOrderModel from "../models/WorkOrderModel";
import AssetModel from "../models/AssetModel";
import util from "../util/Util";
import PageTitleRightModal from "../components/modals/PageTitleRightModal";
import InfoBlock from "../components/InfoBlock";
import DynamicFieldsComponent from "../components/DynamicFieldsComponent";
import MoreButtons from "../components/MoreButtons";
import OrderModel from "../models/OrderModel";
import ClientModel from "../models/ClientModel";
import GqlErrors from "../components/status/GqlErrors";
import appState from '../state/AppState';
import SpinnerClipLoaderInPage from "../network/SpinnerClipLoaderInPage";
import OrderActions from "./orders/OrderActions";
import WorkOrderActions from "./workOrders/WorkOrderActions";
import AssetActions from "./assets/AssetActions";
import ClientActions from "./clients/ClientActions";

@observer
class PageDynamic extends BaseForm {
    nameMainType = this.props.model;

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            loading: true
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }

    getMoreButtons() {
        let modelos = {
            "order": <OrderActions integracionId={this.graphDataMainType.id} model={this.graphDataMainType}
                                   getModelQuery={() => this.getModelQuery()}
                                   setErrors={(errors) => this.setErrors(errors)} />,
            "workOrder": <WorkOrderActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                           setErrors={(errors) => this.setErrors(errors)} />,
            "asset": <AssetActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                   setErrors={(errors) => this.setErrors(errors)} />,
            "client": <ClientActions model={this.graphDataMainType} getModelQuery={() => this.getModelQuery()}
                                     setErrors={(errors) => this.setErrors(errors)} />,
        };
        let result = modelos[this.nameMainType];
        return result;
    }

    getModelQuery() {
        let modelos = {
            "workOrder": new WorkOrderModel(),
            "asset": new AssetModel(),
            "order": new OrderModel(),
            "client": new ClientModel(),
        };
        return modelos[this.nameMainType];
    }

    getModelQueryForView() {
        let modelos = {
            "workOrder": new WorkOrderModel(),
            "asset": new AssetModel(),
            "order": new OrderModel(),
            "client": new ClientModel(),
        };
        let result = modelos[this.nameMainType];
        if (this.nameMainType === "order") {
            result.addRelatedTable("budget");
            result.addRelatedTable("documents");
            result.addRelatedTable("workOrder.workOrderCartArticle");
            result.addRelatedTable("workOrderCartArticle");
            result.addRelatedTable("workOrder");
            result.addRelatedTable("client");
            result.addRelatedTable("slot");
        } else if (this.nameMainType === "workOrder") {
            result.addRelatedTable("budget");
            result.addRelatedTable("workOrderCartArticle");
            result.addRelatedTable("documents");
            result.addRelatedTable("order");
            result.addRelatedTable("order.slot");
        } else if (this.nameMainType === "asset") {
            result.addRelatedTable("documents");
        }
        return result;
    }

    getTabs() {
        let tipos = {
            "workOrder": this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order)),
            "order": this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType)),
            "asset": this.tabsData.getDataAssets(),
            "client": this.tabsData.getDataPosClient(),
        };
        return tipos[this.nameMainType];
    }

    renderImpl() {
        const t = translate;
        let id = this.getFormId() || this.props.id;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let model = this.graphDataMainType;
        return (

            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons>
                                    {this.getMoreButtons()}
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={id}
                                tabs={this.getTabs()}
                                fromRightModal={this.props.fromRightModal}
                                active={"Campos dinamicos"}
                                classNameModal={" col-12"}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}>
                                {this.getMoreButtons()}
                            </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={id}
                                tabs={this.getTabs()}
                                active={"Campos dinamicos"}
                            />
                            }
                            <form
                                className="model-form" name="formulario" noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <div className={'card mb-3'}>
                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking} />
                                    <GqlErrors errors={this.gqlErrors}
                                               setErrors={(errors) => this.setErrors(errors)} />
                                    <GroupOfField title={t('Campos dinamicos')}
                                                  icon="fas fa-book" />
                                    <SpinnerClipLoaderInPage loading={this.state.loading}>
                                        <DynamicFieldsComponent
                                            name={'dynamicField'}
                                            editable={model.editable} nameMainType={this.nameMainType}
                                            dynamicFields={model.dynamicField && JSON.parse(model.dynamicField)}
                                            deleteInputEventDynamicFields={(groupId) => this.deleteInputEventDynamicFields(groupId)}
                                            updateInputEventDynamicFields={(name, value, groupId) => this.updateInputEventDynamicFields(name, value, groupId)}
                                        />
                                    </SpinnerClipLoaderInPage>
                                </div>
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>


        )
    }
}

export default PageDynamic
