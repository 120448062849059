import AbstractModel from "./AbstractModel";
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import appState from "../state/AppState";
import t from '../translator/translate'

export default class WorkLogModel extends AbstractModel {


    static START_WORK_ORDER = "START_WORK_ORDER";
    static PENDING = "PENDING";
    static RETURN_WORK_ORDER = "RETURN_WORK_ORDER";
    static PAUSE_WORK_ORDER = "PAUSE_WORK_ORDER";
    static END_WORK_ORDER = "END_WORK_ORDER";
    static WARRANTY_POSSIBLE = "WARRANTY_POSSIBLE";
    static START_DAY = "START_DAY";
    static END_DAY = "END_DAY";
    static DONT_SIGN = "DONT_SIGN";
    static START_VISIT = "START_VISIT";
    static COMMENTS_DISPATCHER = "COMMENTS_DISPATCHER";
    static GEOLOCATION = "GEOLOCATION";
    static CANCELATION_ORDER = "CANCELATION_ORDER";
    static NOT_APPROVED_ORDER = "NOT_APPROVED_ORDER";
    static END_VISIT = "END_VISIT";
    static DIAGNOSIS = "DIAGNOSIS";
    static ASSET_CHANGE = "ASSET_CHANGE";
    static TASKS = "TASKS";
    static TASKS_ORDER = "TASKS_ORDER";
    static COMMENTS = "COMMENTS";
    static ARTICLES = "ARTICLES";
    static WORK_ORDER_TEST_QUALITY = "WORK_ORDER_TEST_QUALITY";
    static FLAVOR_PLANOGRAM = "FLAVOR_PLANOGRAM";
    static CHANGE_REASONS = "CHANGE_REASONS";
    static ASSET_COMMENT = "ASSET_COMMENT";

    //Para poder editar las visitas
    static FIN_OT = "FIN_OT";


    id;
    userId;
    action;
    time;
    slotId;
    workOrderId;
    orderId;
    clientId;
    data;

    nameMainType = "workLog";
    graphFindByIdOperation = "workLog";
    graphFindOperation = "workLogsConnection";
    graphExportOperation = "workLogExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "userId": "",
            "action": "",
            "slotId": "",
            "workOrderId": "",
            "orderId": "",
            "clientId": "",
            "time": "",
            "data": "",
        };
        return result;
    }

    nameForData(action) {
        let tipos = {
            "WORK_ORDER_TEST_QUALITY": "testQuality",
            "WARRANTY_POSSIBLE": "warrantyPossible",
            "CHANGE_REASONS": "changeReasons",
            "DONT_SIGN": "dontSign",
            "CANCELATION_ORDER": "cancelOrder",
            "COMMENTS_DISPATCHER": "commentsDispatcher",
            "NOT_APPROVED_ORDER": "notApprovedOrder",
            "FLAVOR_PLANOGRAM": "flavorPlanogram",
            "RETURN_WORK_ORDER": "causesPause",
            "END_VISIT": "endVisitResume",
            "TASKS_ORDER": "orderTasks",
            "START_DAY": "startDayResume",
            "ASSET_COMMENT": "assetComment",
            "END_DAY": "endDayResume",
            "PAUSE_DAY": "pauseDay",
        };
        let result = WorkLogModel[action]?.toLowerCase();
        if (tipos[action] != null) {
            result = tipos[action];
        }
        return result;
    }

    getWorkLogsFromThisSlot(workOrder, action, slotId) {
        let result = [];
        if (workOrder.workLog?.length > 0) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.action === WorkLogModel[action]) {
                    if (workLog.slotId === slotId) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    getWorkLogsFromOtherSlot(workOrder, action, slotId) {
        let result = [];
        if (workOrder.workLog?.length > 0) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.action === WorkLogModel[action]) {
                    if (workLog.slotId !== slotId) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    getWorkLogsFromAction(workOrder, action) {
        let result = [];
        if (workOrder.workLog?.length > 0) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.action === WorkLogModel[action]) {
                        result.push(workLog);
                }
            }
        }
        return result;
    }

    getTitle(action) {
        let tipos = {
            "DIAGNOSIS": "Diagnóstico",
            "WARRANTY_POSSIBLE": "Posible Garantia",
            "TASKS": "Tarea",
            "COMMENTS": "Comentarios generales",
            "COMMENTS_DISPATCHER": "Comentarios internos",
            "ASSET_CHANGE": "Cambio de Equipo",
            "CHANGE_REASONS": "Cambio CC",
            "CANCELATION_ORDER": "Motivo de cancelación",
            "NOT_APPROVED_ORDER": "Motivo de No Aprobado",
            "TASKS_ORDER": "Tareas del pedido",
            "WORK_ORDER_TEST_QUALITY": "Formulario",
            "ASSET_COMMENT": "Comentarios del equipo",
            "FLAVOR_PLANOGRAM": "Planograma",
            "RETURN_WORK_ORDER": "Devuelta",
            "END_": "Comentarios del equipo",
            "START_WORK_ORDER": "Inicio de OT",
            "PAUSE_WORK_ORDER": "Pausa de OT",
            "END_WORK_ORDER": "Fin de OT",
            "START_DAY": "Inicio de Jornada",
            "END_DAY": "Fin de Jornada",
            "PAUSE_DAY": "Pausa de jornada",
            "RESTART_DAY": "Reiniciar jornada",
            "DONT_SIGN": "Razon de no firma",
            "START_VISIT": "Inicio de Visita",
            "END_VISIT": "Fin de Visita",
        };
        return tipos[action];
    }

    static ordenarWorkLogs(observableArray) {
        let log = (msg) => {
            AppLogger.get().debug(msg, this);
        };
        // log({ ordenarWorkLogs: 1, workLogs })
        let workLogs = observableArray.slice();
        workLogs.sort(function (a, b) {
            let key1 = util.getMoment(a.time).format("YYMMDDHHmmsss");
            let key2 = util.getMoment(b.time).format("YYMMDDHHmmsss");
            if (key1 < key2) {
                return -1;
            }
            if (key2 > key1) {
                return 1;
            }
            return 0;
        });
        // log({ ordenarWorkLogs: 1, workLogs })
        return workLogs;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return [...baseFields];
    }


    /**
     * Ejemplo de datos
     * {"flavorPlanogram":[
     *  {"0":{"assetId":"08da3fd1-112f-400d-8f07-6383ca7607fa","id":"08da7934-06cc-40f4-8603-673d58923fc6",...}},
     *  {"1":{"assetId":"08da3fd1-112f-400d-8f07-6383ca7607fa","canId":"00000000-0000-0000-0000-000000000000","dateExpire":"2022-08-26T22:00:00.000Z"}}
     *  ]
     * @returns [ { [string]: WorkLogModel } ]
     */
    getDataAsFlavourPlanogram() : Array<{ [string]: FlavourPlanogramItemType }>  {
        let jsonPlanogram = JSON.parse(this.data);
        let result = jsonPlanogram?.["flavorPlanogram"];
        if (result===undefined) {
            result=[];
        }
        return result;
    }

    /**
     * Util para poder presentar estos datos. Elimina los vacios
     * @returns Array Array con objetos con los datos de carriles como title, value
     */
    getFlavourAsTitleValue() {
        let jsonPlanogram = this.getDataAsFlavourPlanogram();
        let carriles=[];
        for (let carrilKey in jsonPlanogram) {
            let carrilData = jsonPlanogram[carrilKey][carrilKey];
            this.log({"renderFlavorData": "key", carrilKey,carrilData});
            let viewData = [];
            viewData.push({title:t("Carril"), value:carrilData["numberRail"]});
            viewData.push({title:t("Producto"), value: appState.typifiedState.getValueLang(carrilData["productId"])});
            viewData.push({title:t("Envase"), value: appState.typifiedState.getValueLang(carrilData["canId"])});
            viewData.push({title:t("Sabor"), value: appState.typifiedState.getValueLang(carrilData["qualityTasteId"])});
            viewData.push({title:t("Precio"), value:carrilData["price"]});
            viewData.push({title:t("Ratio"), value:carrilData["ratio"]});
            viewData.push({title:t("Temperatura"), value:carrilData["temperature"]});
            for(let key=viewData.length-1;key>=0;key--) {
                if (util.esVacio(viewData[key].value)) {
                    let valueToRemove=viewData[key].value;
                    let titleToRemove=viewData[key].title;
                    viewData.splice(key,key);
                    //this.log({"renderFlavorData": "removeKey", key,viewData,valueToRemove,titleToRemove});
                }
            }
            /*Pendientes:
                "dateExpire":string,
                "planogramInfo":string,
              */
            carriles.push(viewData);
        }
        return carriles;
    }

}
