import React, {Component} from 'react';
import {observer} from 'mobx-react';
import appState from "../../state/AppState";
import util from "../../util/Util"
import PropsUtil from "../../util/PropsUtil";
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";

@observer
class CreateAndModify extends Component {

    async componentDidMount() {
        await appState.userCacheState.loadUserCacheTechnicals();
    }

    render() {
        let props = this.props;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let user = appState.userCacheState.getUserById(props.createdBy);
        let creadoPor = "";
        if (util.hasValue(user.firstName)) {
            creadoPor = user.firstName;
        }
        if (util.hasValue(user.lastName)) {
            creadoPor += " " + user.lastName;
        }
        let classNameP = "last-modified";
        if(this.props.classNameP){
            classNameP = this.props.classNameP;
        }
        return (
            <>
                {!props.fromModal ?
                    <div className={'editable col-12'}>
                        <p className={classNameP}>
                            {props.createdAt &&
                            <>
                                <span>{t("Creado el")} {" "}</span>
                                <span>{util.localizeIsoDateHour(props.createdAt)}</span>
                                {props.createdBy && util.hasValue(creadoPor) &&
                                <>
                                    {" "}{t("por")}{" "}
                                    <span>{creadoPor}.</span>
                                </>
                                }
                            </>
                            }
                            {props.updatedAt &&
                            <>
                                {" "} <span>{t("Última actualización")}{" "} </span>
                                <span>{util.localizeIsoDateHour(props.updatedAt)}</span>
                                {props.updatedBy && util.hasValue(appState.userCacheState.getUserById(props.updatedBy).email) &&
                                <>
                                    {" "} {t("por")}{" "}
                                    <span>{appState.userCacheState.getUserById(props.updatedBy).email}.</span>
                                </>
                                }
                            </>
                            }
                            {props.client &&
                            <>
                                <span>{" " + t('Cliente') + ": "}</span>
                                <span>{props.client}.</span>
                            </>
                            }
                            {props.status &&
                            <>
                                <span>{" " + t('Estado del Técnico') + ": "}</span>
                                <span
                                    style={{ color: appState.typifiedState.getObject(this.props.status) && appState.typifiedState.getObject(this.props.status).color }}>
                                            {appState.typifiedState.getValue(this.props.status)}
                                        </span>
                            </>
                            }
                        </p>
                    </div>
                    :
                    <div style={{ padding: 16 }}>
                        <div style={{
                            color: '#6C757D',
                            fontSize: 12
                        }}>{t("Código Pedido") + ": " + this.props.code}</div>
                        <div style={{ color: '#6C757D', fontSize: 10 }}>
                            {t("Creado el") + ": " + util.localizeIsoDateHour(props.createdAt)}{" "} {t("por") + ": " + creadoPor + "."}
                        </div>
                    </div>
                }

            </>

        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default CreateAndModify;

