import AppLogger from "../util/AppLogger";
import appState from '../state/AppState';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
export default class CookieStorage {

    constructor() {
        this.debug = true;
    }

    getItemTenantId(name) {
        let tenantId = appState.loginState.user?.tenantId;
        return cookies.get(tenantId + "_" + name);
    }

    setItemTenantId(name, value) {
        let tenantId = appState.loginState.user?.tenantId;
        return cookies.set(tenantId + "_" + name, value);
    }

    getItem(name) {
        return cookies.get( name);
    }

    setItem(name, value) {
        return cookies.set(name, value);
    }

    // Logea en consola el mensaje. Sólo útil en depuración
    log(msg) {
        if (this.debug) {
            AppLogger.get().debug(msg, this);
        }
    }


}
