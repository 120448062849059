import BaseValidation from "./BaseValidation";
import util from '../../../util/Util';

export default class RequiredValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;

        if (!util.hasValue(inputValue)) {
            isCorrect = false;

        }

        return isCorrect;
    }
}
