import React, {Component} from 'react';
import {observer} from 'mobx-react';
import appState from '../../state/AppState';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";
import ReactTooltip from "react-tooltip";
import {observable} from "mobx";
import UserModel from "../../models/UserModel";
import MapRoutes from "../modals/MapRoutes";
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";

@observer
 class IconsSelectCapactitation extends Component {
    @observable clock = util.getMoment(Date.now()).add(-6, 'm');

    constructor(props) {
        super(props);
        this.state = {
            mouseEnter: false,
            mouseEnterWifi: false,
            technicalRoute: {}
        }
    }

    componentDidMount() {
        this.loadClock();
    }

    loadClock() {
        setTimeout(() => {
            this.clock = util.getMoment(Date.now()).add(-6, 'm');
            this.loadClock();
        }, 200000)
    }

    onClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    getColor() {
        let props = this.props;
        let result = "red";
        if (props.usuario?.userOnline && util.getMoment(props.usuario?.userOnline.time).format("YYYYMMDDHHmm") > this.clock.format("YYYYMMDDHHmm")) {
            if (props.usuario?.userOnline.connectionType === "wifi") {
                result = "green";
            } else {
                result = "blue";

            }
        }
        return result
    }

    getIcon() {
        let props = this.props;
        let result = "fas fa-user-slash";
        if (props.usuario?.userOnline && util.getMoment(props.usuario?.userOnline.time).format("YYYYMMDDHHmm") > this.clock.format("YYYYMMDDHHmm")) {
            if (props.usuario?.userOnline.connectionType === "wifi") {
                result = "fas fa-wifi";
            } else {
                result = "fas fa-signal";
            }
        }
        if (props.usuario.status === UserModel.STATUS_BLOCKED) {
            result = "fas fa-lock";
        }
        return result;
    }
    openClientModal(client) {
        this.propsUtil.changeUrlRequest({
            rightModal: "modalposclients",
            "clientId": client.id,
            "rightModalTab": "general"
        });
    }
    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        let t = translate;
        let classClick = '';
        if (this.props.onClick) {
            classClick = ' text-click';
        }
        let iconClass = this.getIcon();
        let color = this.getColor();
        let border = '2px solid ' + color;
        let icons = props.capacitationIds || [];

        if (props.select) {
            if (util.hasValue(props.postfix)) {
                for (let capacitation of props.postfix.split(',')) {
                    if (appState.typifiedState.getObject(capacitation)) {
                        icons.push(appState.typifiedState.getObject(capacitation));
                    }
                }
            }
        }
        let usuario = this.props.usuario

        return (
            <div className="w-100 d-flex align-items-center justify-content-between">
                <div className="input-group" style={{ display: 'block' }}>
                    <div data-tip data-for={"wifi" + usuario?.id} onMouseEnter={() => {
                        if (util.hasValue(usuario?.userOnline?.appVersion)) {
                            if (!props.select) {
                                this.setState({ mouseEnterWifi: true })
                            }
                        }
                    }} style={{ display: 'flex', position: 'relative', alignItems: 'baseline' }}>
                        <div className="icon-select-capacitation-box" style={{ border }}>
                            <i className={iconClass}
                               style={{ color: color, fontSize: "0.50rem" }} />
                        </div>
                        <p className={"th-bottom__item" + classClick} style={{
                            'marginBottom': 0, width: '160px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap'
                        }}
                           onClick={() => this.onClick()}>
                            {props.children}
                        </p>
                    </div>
                    <div style={{
                        display: 'flex', marginTop: 2,
                        marginBottom: 0,
                        width: '160px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}>
                        {icons?.map(icon => {
                            if (util.hasValue(icon.icon)) {
                                return (
                                    <div data-tip data-for={icon.value} className="icon-select-capacitation-box"
                                         onMouseEnter={() => {
                                             if (!props.select) {
                                                 this.setState({ mouseEnter: true })
                                             }
                                         }}
                                         style={{ backgroundColor: util.hasValue(icon.color) ? icon.color : "#6C757D", }}
                                         key={icon.id + usuario?.id + 2}>
                                        {util.getIconoStyled(icon.icon)}
                                        {this.state.mouseEnter &&
                                        <ReactTooltip id={icon.value} effect="float" textColor="#2D3B41"
                                                      wrapper={'div'}
                                                      backgroundColor="#F5F5F5" arrowColor="#CED4DA"
                                                      place="right">
                                                    <span style={{ fontSize: "0.7rem" }}>
                                                          {appState.typifiedState.getValue(icon.id)}
                                                    </span>
                                        </ReactTooltip>
                                        }
                                    </div>
                                )
                            }
                        })
                        }
                    </div>
                </div>
                {this.state.mouseEnterWifi &&
                <ReactTooltip id={"wifi" + usuario?.id} effect="float" textColor="#2D3B41"
                              wrapper={'div'} backgroundColor="#F5F5F5" arrowColor="#CED4DA"
                              place="right">
                <span style={{ fontSize: "0.7rem", color: "#6C757D" }}>
                    {"v." + usuario?.userOnline?.appVersion}
                </span>
                </ReactTooltip>
                }
                {usuario?.slots?.length > 0 &&
                <div className="icon-directions-background">
                    <i className="fas fa-directions" onClick={() => this.setState({technicalRoute: usuario})} />
                </div>
                }
                {this.state.technicalRoute.id != null &&
                <MapRoutes
                    openModal={this.state.technicalRoute.id != null}
                    closeModal={() => this.setState({technicalRoute: {}})}
                    slots={this.state.technicalRoute.slots}
                    title={t('Ruta de las visitas')}
                    openClientModal={(client) => {
                        this.setState({technicalRoute: {}});
                        this.openClientModal(client);
                    }}
                />
                }
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
export default withRouter(IconsSelectCapactitation);