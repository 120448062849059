import React, { Component } from 'react'
import { observer } from 'mobx-react'
import appState from '../state/AppState'
import VsfLink from '../components/VsfLink'

@observer
class SidebarItem extends Component {
  ifPathMatch (pathMatch) {
    let result = false
    if (this.props.location.pathname === pathMatch) {
      result = true
    }
    return result
  }

  render () {
    let props = this.props
    let title = props.title

    return (
      <li className="nav-item">
        {this.props.href !== '' ?
          <>
            <VsfLink className={this.props.active ?
              'nav-link active'
              : 'nav-link'} disabled={this.ifPathMatch(this.props.href)}
                     to={this.props.href} data-cy={this.props.dataCy}>
              <div data-cy={this.props.dataCy}>
                <i className={this.props.icon} />
                <span className="nav-item__name">{title} {this.props.active}</span>
                {this.props.badge != null &&
                <span className="badge badge-info">{this.props.badge}</span>
                }
                {this.props.arrow &&
                <i className="fas fa-angle-left" />
                }
              </div>
            </VsfLink>
            {/*El siguiente sólo sale cuando está plegado el sidebar   */}
            <VsfLink className="nav-link--slide" to={this.props.href}  disabled={this.ifPathMatch(this.props.href)} {...props} >
              <span className="nav-item__name">{title} {this.props.active}</span>
              {this.props.badge != null &&
              <span className="badge badge-info">{this.props.badge}</span>
              }
              {this.props.arrow &&
              <i className="fas fa-angle-left" />
              }
            </VsfLink>
            {this.props.children != null && this.props.children.length > 0 &&
            <ul className="nav-item--dropdown">
              {this.props.children}
            </ul>
            }
            {this.props.line &&
            <div className="nav-link--border" />
            }
          </>
          :
          <>
            <div className={this.props.active
              ? 'nav-link active' : 'nav-link'} onClick={(e) => this.props.click()}
                 data-cy={this.props.dataCy}>
              <i className={this.props.icon} />
              <span className="nav-item__name master">{title}</span>
              {this.props.badge != null && <span className="badge badge-info">{this.props.badge}</span>}
              {this.props.arrow &&
              <>
                {props.stateOpen === false ?
                  <i className="fas fa-angle-left" />
                  :
                  <span className="fas fa-angle-up" />
                }
              </>
              }
            </div>

            {props.stateOpen &&
            <div>
              {props.children != null &&
              <div className="nav-item--dropdown">
                {this.props.children}
              </div>
              }
            </div>
            }
            {appState.layoutState.sidebarMinimized &&
            <div className="dropdown--slide">
              {props.children != null &&
              <div className="nav-item--dropdown">
                {this.props.children}
              </div>
              }
            </div>
            }
            {this.props.line &&
            <div className="nav-link--border" />
            }
          </>
        }
      </li>
    )
  }
}

export default SidebarItem

