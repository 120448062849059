import React, { Component } from 'react'
import AppLogger from '../../../util/AppLogger'
import translate from '../../../translator/translate'
import Select2Component from '../../../components/fields/Select2Component'
import VsfButtonNew from '../../../components/VsfButtonNew'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import OrderModel from '../../../models/OrderModel'
import CheckboxUniqueComponent from '../../../components/fields/CheckboxUniqueComponent'
import appState from '../../../state/AppState'
import TypifiedValueModel from '../../../models/TypifiedValueModel'
import WorkOrderModel from '../../../models/WorkOrderModel'
import SlotModel from '../../../models/SlotModel'
import util from '../../../util/Util'
import GqlErrors from '../../../components/status/GqlErrors'
import GraphException from '../../../network/GraphException'
import PropsUtil from '../../../util/DateUtil'
import { withRouter } from 'react-router-dom'
import VsfModalResizable from '../../../components/modals/VsfModalResizable'
import MultiModel from '../../../models/MultiModel'

@observer
class ClientPreventModalMulti extends Component {
  @observable order = {}
  @observable gqlErrors = null

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      meses: {},
      change: false,
      seleccionadas: 0,
    }

  }

  async componentDidMount () {
    if (this.props.fromClient) {
      this.setState({
        change: true,
      })
    }
    this.order = this.props.order

  }

  getOrdersModels (arrOrders) {
    let tamanyo = 12 / this.state.seleccionadas
    let arrOrdersModels = []
    let status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'APPROVED')
    for (let order of arrOrders) {
      let scheduledYear = order.scheduledDate.substr(0, 4)
      let scheduledMonth = order.scheduledDate.substr(4)
      for (let i = 1; i <= tamanyo; i++) {
        let orderModel = new OrderModel()
        scheduledMonth = parseInt(scheduledMonth) + parseInt(this.state.seleccionadas)
        if (scheduledMonth > 12) {
          scheduledMonth -= 12
          scheduledYear++
        }
        orderModel.hourPreventive = order.hourPreventive
        orderModel.originId = order.originId
        orderModel.dayNumber = order.dayNumber
        orderModel.orderPreventiveId = order.id
        orderModel.weekNumber = order.weekNumber
        orderModel.origin = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_ORIGIN, 'NUDDO').id
        orderModel.scheduleVisitId = order.scheduleVisitId
        orderModel.status = status.id
        orderModel.clientId = order.clientId
        orderModel.propietaryId = order.propietaryId
        orderModel.scheduledLabel = order.scheduledLabel
        orderModel.comments = order.comments
        orderModel.scheduledDate = scheduledYear + '' + util.numDigitos(scheduledMonth, 2)
        arrOrdersModels.push(orderModel)
      }
    }
    return arrOrdersModels
  }

  getWorkOrderModel (orderCreada) {
    let type = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORK_ORDER_TYPE, 'PRE').id
    if (util.hasValue(appState.schedulePreventDropState.scheduleVisitsDict[orderCreada.scheduleVisitId]?.type)) {
      type = appState.schedulePreventDropState.scheduleVisitsDict[orderCreada.scheduleVisitId]?.type
    }
    let workOrder = new WorkOrderModel()
    workOrder.orderId = orderCreada.id
    workOrder.generalDescription = orderCreada.comments
    workOrder.type = type
    return workOrder
  }

  getSlotsModelsMains (orderCreada) {
    let arrayScheduleVisitSlots = appState.schedulePreventDropState.scheduleVisitsDict[orderCreada.scheduleVisitId]?.scheduleVisitSlots
    let resultModels = []
    if (arrayScheduleVisitSlots?.length > 0) {
      for (let scheduleVisitSlot of arrayScheduleVisitSlots) {
        let slot = new SlotModel()
        slot.workOrderIds = '-1'
        slot.createdByPreventive = 1
        slot.orderId = orderCreada.id
        slot.scheduleVisitId = orderCreada.scheduleVisitId
        slot.scheduledDate = orderCreada.scheduledDate
        slot.scheduledDuration = scheduleVisitSlot.scheduledDuration
        slot.slotUserRole = scheduleVisitSlot.slotUserRole
        if (scheduleVisitSlot.slotUserRole === SlotModel.MAIN) {
          resultModels.push(slot)
        }
      }
    }
    return resultModels
  }

  getSlotsModelsSupports (slotCreada) {
    let arrayScheduleVisitSlots = appState.schedulePreventDropState.scheduleVisitsDict[slotCreada.scheduleVisitId].scheduleVisitSlots
    let resultModels = []
    for (let scheduleVisitSlot of arrayScheduleVisitSlots) {
      let slot = new SlotModel()
      slot.workOrderIds = '-1'
      slot.createdByPreventive = 1
      slot.assignedSlotMainId = slotCreada.id
      slot.orderId = slotCreada.orderId
      slot.scheduleVisitId = slotCreada.scheduleVisitId
      slot.scheduledDate = slotCreada.scheduledDate
      slot.scheduledDuration = scheduleVisitSlot.scheduledDuration
      slot.slotUserRole = scheduleVisitSlot.slotUserRole
      if (scheduleVisitSlot.slotUserRole === SlotModel.SUPPORT) {
        resultModels.push(slot)
      }
    }
    return resultModels
  }

  async createModels (arrOrders) {
    let multiModel = new MultiModel()
    let arrOrdersModels = this.getOrdersModels(arrOrders)
    let ordersCreados = await multiModel.persist(arrOrdersModels)
    let arrWorkOrders = []
    let arrSlots = []
    for (let orderCreada of ordersCreados) {
      if (orderCreada.id) {
        await appState.schedulePreventDropState.scheduledOrders.push(orderCreada)
        //Creo una O.T
        arrWorkOrders.push(this.getWorkOrderModel(orderCreada))
        // Guardo los slots
        arrSlots = [...arrSlots, ...this.getSlotsModelsMains(orderCreada)]
      }
    }
    await multiModel.persist(arrWorkOrders)
    let slotsCreadas = await multiModel.persist(arrSlots)
    this.log({
      savePrevent: 1,
      ordersCreados,
      arrWorkOrders,
      slotsCreadas,
      arrSlots
    })
    arrSlots = []
    for (let slotCreado of slotsCreadas) {
      if (slotCreado.id) {
        arrSlots = [...arrSlots, ...this.getSlotsModelsSupports(slotCreado)]
      }
    }
    if (arrSlots.length > 0) {
      await multiModel.persist(arrSlots)

    }
  }

  async savePrevent () {
    this.setState({
      loading: true
    })
    //FromClient es la planificacion a 12 meses
    if (this.props.fromClient) {
      let arrOrders = []
      for (let mes of Object.keys(this.state.meses)) {
        for (let scheduledOrder of appState.schedulePreventDropState.scheduledOrders) {
          if (scheduledOrder.scheduledDate === mes) {
            arrOrders.push(scheduledOrder)
          }
        }
      }
      try {
        await this.createModels(arrOrders)
        await this.props.loadTable()
        await this.props.loadClients()
        setTimeout(() => {
          this.props.closeModal()
        }, 2000)
      } catch (e) {
        let gqlErrors = new GraphException().getErrorsFromException(e)
        this.log({ gqlErrors })
        this.gqlErrors = gqlErrors
      }
    } else {
      let order = new OrderModel()
      order.hidrate(this.order)
      try {
        await order.persist()
        await this.props.loadTable()
        await this.props.loadClients()
        setTimeout(() => {
          this.props.closeModal()
        }, 2000)
      } catch (e) {
        let gqlErrors = new GraphException().getErrorsFromException(e)
        this.log({ gqlErrors })
        this.gqlErrors = gqlErrors
      }
    }
    this.setState({
      loading: false
    })
  }

  getHours (dateIso) {
    let horasSegs = util.right(dateIso, 13)
    return util.left(horasSegs, 5)
  }

  setErrors (errors) {
    this.gqlErrors = errors
  }

  updateInputEvent (e) {
    let value = e.target.value
    let name = e.target.name
    this.state.seleccionadas = Object.keys(this.state.meses).length + 1
    if (this.props.fromClient) {
      this.state.meses[name] = value
    } else {
      this.setState({
        change: true
      })
      this.order[name] = value
    }
  }

  getOptionsHours () {
    return ([
      {
        label: '08:00',
        value: '08:00'
      },
      {
        label: '09:00',
        value: '09:00'
      },
      {
        label: '10:00',
        value: '10:00'
      },
      {
        label: '11:00',
        value: '11:00'
      },
      {
        label: '12:00',
        value: '12:00'
      },
      {
        label: '13:00',
        value: '13:00'
      },
      {
        label: '14:00',
        value: '14:00'
      },
      {
        label: '15:00',
        value: '15:00'
      },
      {
        label: '16:00',
        value: '16:00'
      },
      {
        label: '17:00',
        value: '17:00'
      },
      {
        label: '18:00',
        value: '18:00'
      },
      {
        label: '19:00',
        value: '19:00'
      },
      {
        label: '20:00',
        value: '20:00'
      },
    ])
  }

  optionsWeek () {
    const t = translate
    return [
      {
        label: t('Primera'),
        value: '1'
      },
      {
        label: t('Segunda'),
        value: '2'
      },
      {
        label: t('Tercera'),
        value: '3'
      },
      {
        label: t('Cuarta'),
        value: '4'
      },
    ]
  }

  optionsDay () {
    const t = translate
    return [
      {
        label: t('Lunes'),
        value: '1'
      },
      {
        label: t('Martes'),
        value: '2'
      },
      {
        label: t('Miércoles'),
        value: '3'
      },
      {
        label: t('Jueves'),
        value: '4'
      },
      {
        label: t('Viernes'),
        value: '5'
      },
      {
        label: t('Sábado'),
        value: '6'
      },
      {
        label: t('Domingo'),
        value: '7'
      },
    ]
  }

  render () {
    const t = translate
    let optionsWeek = this.optionsWeek()
    this.propsUtil = new PropsUtil(this.props)
    let optionsday = this.optionsDay()
    let hourPreventive = this.getOptionsHours()
    return (
      <>
        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
        <VsfModalResizable
          isOpen={this.props.openModal}
          initHeight={(!this.props.fromClient) ? 280 : 330}
          onCloseModal={this.props.closeModal}
          left={this.props.fromRightModal && '-45%'}>
          <div style={{ display: 'block' }}>
            <h5 className="card-title clientPreventModal"><i style={{ fontSize: 20 }}
                                                             className="fas fa-info-circle" />{t('Configuración de la visita preventiva')}
            </h5>
            <div className="modal-body-filters modal-body__no-overflow">
              {!this.props.fromClient ?
                <div>
                  <div>
                    <p>{t('Para poder configurar la visita del técnico selecciona el día de la semana y la posición en el mes (ej: primer miércoles del mes). Y la hora acordada con el cliente')}</p>
                  </div>
                  <div className="row">
                    <Select2Component
                      value={this.order.dayNumber}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={'dayNumber'}
                      title={t('Día de la semana')}
                      classGroup={'col-4'} ordenar={false}
                      editable={this.order.editable}
                      isClearable={false}
                      options={optionsday}
                    />
                    <Select2Component
                      value={this.order.weekNumber}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={'weekNumber'}
                      title={t('Posición en el mes')}
                      isClearable={false}
                      classGroup={'col-4'} ordenar={false}
                      options={optionsWeek}
                      editable={this.order.editable}
                    />
                    <Select2Component
                      value={this.order.hourPreventive}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={'hourPreventive'}
                      title={t('Hora')}
                      isClearable={false}
                      classGroup={'col-4'}
                      editable={this.order.editable}
                      options={hourPreventive}
                    />
                  </div>
                </div>
                :
                <div>
                  <div>
                    <p>{t(`Recuerda planificar las opciones de hora y semana en las visitas, antes de planificar los 12 meses de modo automático. Para ver estas opciones sólo tienes que pulsar en la caja azul de la visita.`)}</p>
                    <p>{t('Tu selección se repetirá de forma automática los 12 meses siguientes al último mes seleccionado')}</p>
                  </div>
                  <div className="row">
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '01']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '01'}
                      value={''}
                      className={'col-2'}
                      title={t('Enero')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '02']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '02'}
                      value={''}
                      className={'col-2'}
                      title={t('Febrero')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '03']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '03'}
                      value={''}
                      className={'col-2'}
                      title={t('Marzo')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '04']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '04'}
                      value={''}
                      className={'col-2'}
                      title={t('Abril')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '05']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '05'}
                      value={''}
                      className={'col-2'}
                      title={t('Mayo')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '06']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '06'}
                      value={''}
                      className={'col-2'}
                      title={t('Junio')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '07']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '07'}
                      value={''}
                      className={'col-2'}
                      title={t('Julio')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '08']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '08'}
                      value={''}
                      className={'col-2'}
                      title={t('Agosto')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '09']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '09'}
                      value={''}
                      className={'col-2'}
                      title={t('Septiembre')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '10']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '10'}
                      value={''}
                      className={'col-2'}
                      title={t('Octubre')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '11']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '11'}
                      value={''}
                      className={'col-2'}
                      title={t('Noviembre')}
                    />
                    <CheckboxUniqueComponent
                      checked={this.state.meses[this.props.year + '12']}
                      onChange={(e) => this.updateInputEvent(e)}
                      name={this.props.year + '12'}
                      value={''}
                      className={'col-2'}
                      title={t('Diciembre')}
                    />
                  </div>
                </div>
              }
            </div>
            <div className="modal-footer modal-footer__in-flexible-modal">
              <div className="d-flex justify-content-end">
                <VsfButtonNew
                  label={t('Cancelar')}
                  className="btn btn-secondary "
                  onClick={() => this.props.closeModal()}
                />
                <div style={{ width: 10 }} />
                <VsfButtonNew
                  label={t('Guardar')}
                  disabled={!this.state.change}
                  loading={this.state.loading}
                  className="btn btn--loader ml-3 "
                  onClick={() => this.savePrevent()}
                />
              </div>
            </div>
          </div>
        </VsfModalResizable>
      </>
    )
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default withRouter(ClientPreventModalMulti)
