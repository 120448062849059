import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import translate from "../../translator/translate";
import {observable} from "mobx";
import MoreButtons from "../../components/MoreButtons";
import appState from '../../state/AppState';
import OrderModel from "../../models/OrderModel";
import OrderActions from "./OrderActions";

@observer
class OrderHistoryTech extends BaseForm {
    @observable workLogs = {};
    @observable clients = [];
    @observable workOrdersDict = {};

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.workOrdersDict = this.getDictWorkOrders();
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("budget");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.asset");
        result.addRelatedTable("slot");
        result.addRelatedTable("slot.workLog");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    getDictWorkOrders() {
        let result = {};
        for (let workOrder of this.graphDataMainType.workOrder) {
            if (result[workOrder.id] == null) {
                result[workOrder.id] = workOrder;
            }
        }

        return result;
    }

    optionAction(type) {
        let tipos = {
            "END_WORK_ORDER": "Finalizada",
            "START_WORK_ORDER": "En progreso",
            "RETURN_WORK_ORDER": "Devolver",
            "COMMENTS": "Comentarios",
            "PAUSE_WORK_ORDER": "Pausada",
            "DIAGNOSIS": "Diagnosis",
            "TASKS": "Tarea nueva",
            "ASSET_CHANGE": "Cambio de equipo",
            "END_VISIT": "Fin de visita",
            "START_VISIT": "Inicio de visita",
        };
        return tipos[type];
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let order = this.graphDataMainType;
        const t = translate;
        this.log({ order: this.graphDataMainType, slots: this.graphDataMainType?.slot })
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={order} getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType))}
                                active={"Histórico técnico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            onReloadData={() => this.reloadData()}
                            title={this.getPageTitle()}
                            subtitle={t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}
                        >
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={order} getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }

                    <div className="card">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType))}
                                active={"Histórico técnico"}
                            />
                            }
                            <div className="history-container">
                                <div className="history-list-container">
                                    <div className="history-list-rows">
                                        {this.graphDataMainType?.slot?.workLog?.length == 0 &&
                                        <div
                                            className="col-md-9 history-list-item-updated">{t('No se ha trabajado en esta orden de Trabajo')}
                                        </div>
                                        }
                                        {this.graphDataMainType?.slot?.map((slot) => {
                                            if ((slot.workLog)?.length > 0) {
                                                return (
                                                    <div className="history-list-item">

                                                        <div className="history-change modified">
                                                        <span
                                                            className="history-change-title history-change-item modified">{t("Cambios del técnico")}</span>
                                                            {(slot.workLog)?.map(workLog => (
                                                                <>
                                                                    <div
                                                                        className="history-change-item">{(this.workOrdersDict[workLog.workOrderId]?.code || "") + " " + this.optionAction(workLog.action) + " a las " + util.getMoment(workLog.time).format("YYYY-MM-DD HH:mm")}
                                                                    </div>
                                                                </>

                                                            ))}
                                                        </div>

                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(OrderHistoryTech)
