import UserModel from "../UserModel";
import TypifiedValueModel from "../TypifiedValueModel";
import appState from "../../state/AppState";
import CachedData from "./CachedData";

export default class UsersCacheData extends CachedData {
    singleton = null;

    static get() {
        if (this.singleton == null) {
            this.singleton = new UsersCacheData();
        }
        return this.singleton;
    }

    async getDataImpl() {
        let userModel = new UserModel();
        userModel.status = UserModel.STATUS_ACTIVO;
        userModel.addRelatedTable("dateRangeUser");
        userModel.addRelatedTable("userOnline");
        userModel.orderBy = "firstName";
        userModel.filters.push({
            "fieldName": "role",
            "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "TECHNICIAN").id + ',' + appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "SUPERADMIN").id,
            "filterOperator": "INLIST"
        });
        let usersLoaded = await userModel.findPlainObject();
        let systemUser = {}
        systemUser.id = "00000000-0000-0000-0000-000000000000";
        systemUser.firstName = "System";
        systemUser.email = "system";
        usersLoaded.push(systemUser);

        this.data = usersLoaded;
    }
}



