import React from 'react';
import { observer } from 'mobx-react';
import appState from "../../state/AppState";
import util from '../../util/Util';
import './../../scss/components/form-show_history.scss';
import '../../scss/components/_form-file_uploader.scss';
import PropTypes from 'prop-types';
import BaseForm from "../base/BaseForm";
import TabsData from "../../layout/TabsData";
import WorkOrderModel from "../../models/WorkOrderModel";
import AssetModel from "../../models/AssetModel";
import { observable } from "mobx";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import OrderModel from "../../models/OrderModel";
import WorkLogModel from "../../models/WorkLogModel";

@observer
class BaseWorkOrderForm extends BaseForm {

    static defaultProps = {
        fromRightModal: false,
    };

    static propTypes = {
        fromRightModal: PropTypes.bool,
    };
    @observable reloadSelect = false;

    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();

        this.state = {
            changes: [],
            historyVisible: false,
            errorFormulario: false,
            modalFinalizada: false,
            task: false,
            loading: true,
            loadingSending: false,
        };
        // this.queryFieldWithId = "workOrderId";
    }

    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("workLog");
        result.addRelatedTable("reasonReturnWorkLog");
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrderCartArticle");
        result.addRelatedTable("client");
        result.addRelatedTable("asset");
        result.addRelatedTable("asset.assetModel");
        result.addRelatedTable("order");
        result.addRelatedTable("order.workOrder");
        result.addRelatedTable("order.slot");
        result.addRelatedTable("order.originModel");
        return result;
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        // Cargamos la workOrder correspondiente para cargar adecuadamente los selects jerarquicos
        let workOrder = this.graphDataMainType;
        let orderId = this.propsUtil.getRequest("orderId");
        if (orderId != null && !util.hasValue(this.graphDataMainType.id)) {
            let orderQuery = new OrderModel();
            orderQuery.id = orderId;
            orderQuery.addRelatedTable("originModel");
            let order = await orderQuery.findFirst();
            workOrder.orderId = orderId;
            workOrder.order = order;
        }
        if (orderId != null && orderId !== this.graphDataMainType.orderId) {
            this.editable = false;
        }
        this.setState({ loading: false ,change: 1})
    }

    actualizarEstado() {
        this.updateInput("status", appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, WorkLogModel.PENDING).id);
        this.updateInput("reasonReturnId", "");
        this.handleFormSubmit();
    }


    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        //Actualizo el array de workOrders que se visualizan
        if (this.ifPathStartsWith('/order')) {
            await this.actualizaListadWorkOrders(model, previousId);
        }
        if (this.props.fromRightModal) {
            this.propsUtil.changeUrlRequest({ "workOrderId": model.id });
        }
    }

    async actualizaListadWorkOrders(model, previousId) {
        if (this.props.orderId != null || this.props.order.id != null) {
            let workOrderQuery = new WorkOrderModel();
            workOrderQuery.addRelatedTable("documents");
            workOrderQuery.addRelatedTable("asset");
            workOrderQuery.addRelatedTable("order");
            workOrderQuery.addRelatedTable("order.workOrder");
            workOrderQuery.addRelatedTable("order.slot");
            let workOrder = await workOrderQuery.findById(model.id);
            let workOrderMobx = workOrder.toPlainObject();
            workOrderMobx['documents'] = workOrder.documents;
            workOrderMobx['asset'] = workOrder.asset;
            if (util.hasValue(previousId)) {
                for (let i = 0; i < appState.workOrderState.workOrders.length; i++) {
                    if (appState.workOrderState.workOrders[i].id == model.id) {
                        appState.workOrderState.workOrders[i] = workOrderMobx
                    }
                }
            } else {
                appState.workOrderState.workOrders.push(workOrderMobx);
            }
            appState.assetState.countWorkOrdersNotAssigned(workOrder.order);

        }
    }

    onEditarAsset(workorder) {
        return this.propsUtil.changeUrlRequest({
            rightModal: "modalassets",
            "assetId": workorder.assetId,
            "workOrderId": workorder.id,
            "rightModalTab": "assets"
        });
    }

    /**
     * Obtiene los Assets en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @param firstCall
     * @returns Array
     */
    async getAssetsSugestions(value, firstCall) {
        let assetsArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            if (util.hasValue(value) || util.hasValue(this.graphDataMainType.assetType) || firstCall) {
                // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
                this.initializeAbortController();
                let assetsQuery = new AssetModel();
                let assetsWarehousesQuery = new AssetModel();
                if (firstCall) {
                    assetsQuery.id = this.graphDataMainType.assetId;
                } else {
                    if (util.hasValue(this.graphDataMainType.client?.id || this.propsUtil.getRequest('clientId'))) {
                        assetsQuery.storeId = this.graphDataMainType.client?.id || this.propsUtil.getRequest('clientId');
                    } else if (util.hasValue(this.props.order?.client?.id)) {
                        assetsQuery.storeId = this.props.order.client?.id;
                    }
                    if (util.hasValue(value)) {
                        assetsQuery.filters.push({
                            "fieldName": "plate,serialNumber",
                            "fieldValue": value,
                            "filterOperator": "SUBSTR"
                        });
                        assetsWarehousesQuery.filters.push({
                            "fieldName": "plate,serialNumber",
                            "fieldValue": value,
                            "filterOperator": "SUBSTR"
                        });
                    }
                    if (util.hasValue(this.graphDataMainType.assetType)) {
                        assetsQuery.filters.push({
                            "fieldName": "type",
                            "fieldValue": this.graphDataMainType.assetType,
                            "filterOperator": "EQ"
                        });
                        assetsWarehousesQuery.filters.push({
                            "fieldName": "type",
                            "fieldValue": this.graphDataMainType.assetType,
                            "filterOperator": "EQ"
                        });
                    }
                    if (util.hasValue(this.graphDataMainType.assetSubType)) {
                        assetsQuery.filters.push({
                            "fieldName": "subType",
                            "fieldValue": this.graphDataMainType.assetSubType,
                            "filterOperator": "EQ"
                        });
                        assetsWarehousesQuery.filters.push({
                            "fieldName": "subType",
                            "fieldValue": this.graphDataMainType.assetSubType,
                            "filterOperator": "EQ"
                        });

                    }
                    if (appState.typifiedState.getObject(this.graphDataMainType.type).code === "INST") {
                        assetsQuery.filters.push({
                            "fieldName": "status",
                            "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ASSET_STATUS, "CLIENT").id,
                            "filterOperator": "NEQ"
                        });
                    }
                }
                assetsWarehousesQuery.status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ASSET_STATUS, "WAREHOUSE").id;
                let assetWarehouses = await assetsWarehousesQuery.findPlainObject();
                let assetClient = await assetsQuery.findPlainObject();
                let assets = [...assetClient, ...assetWarehouses];
                let assetCopy = {};
                for (let asset of assets) {
                    if (assetCopy[asset.id] == null) {
                        let assetObject = {};
                        let label = asset.plate;
                        if (asset?.serialNumber) {
                            label += " - " + asset?.serialNumber
                        }
                        if (asset?.status) {
                            label += " - " + appState.typifiedState.getValue(asset?.status)
                        }
                        assetObject.label = label;
                        assetObject.value = asset;
                        assetsArray.push(assetObject);
                        assetCopy[asset.id] = asset;
                    }
                }
            }
        } catch (e) {
            this.log("Error => " + e);
        }
        return assetsArray;
    }

    changePageOrder() {
        this.propsUtil.changeUrl("/order/form/" + this.graphDataMainType.order?.id)
    }

    changeAssetSubtype(e) {
        this.reloadSelect = !this.reloadSelect;
        this.updateInputEvent(e);
    }

    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un workOrderType, actualiza los selects adecuadamente
     *
     * @param e
     */
    updateInputEventWorkOrderType(e) {
        this.reloadSelect = !this.reloadSelect;
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput("subType", "");
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.workOrderTypeSelected = e.target.value;
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e);
    }

    async saveOrderBeforeWorkOrder() {
        if (this.props.changeFormOrder || !util.hasValue(this.props.order.id)) {
            await this.props.saveOrder();
        }
        this.graphDataMainType.orderId = this.props.order.id;
        this.graphDataMainType.id = '';

    }

    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un assetType, actualiza los selects adecuadamente
     * @param e
     */
    updateInputEventAssetType(e) {
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput("assetSubType", "");
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.assetTypeSelected = e.target.value;
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e);
    }


}

export default BaseWorkOrderForm;
