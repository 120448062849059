import { computed, observable } from 'mobx';
import ArticleModel from "../models/ArticleModel";
import BaseModelState from "./BaseModelState";


class ArticleState extends BaseModelState {

    @observable articles = [];
    @observable articlesDeslocalization = [];

    @computed get labelValuesForSelect() {
        let result = [];
        let copia = {};
        for (let item of this.articles) {
            if (copia[item.netsuiteInternalId] == null) {
                copia[item.netsuiteInternalId] = "";
                result.push({
                    value: item.netsuiteInternalId,
                    label: item.code + " " + item.description,
                    code: item.code,
                    description: item.description,
                    internalId: item.netsuiteInternalId
                });
            }
        }
        return result;
    }

    async getArticles() {
        let articleQuery = new ArticleModel();
        {
            let result = await articleQuery.find();
            if (result == null) {
                result = [];
            }
            this.articles = result;
        }
    }

    async loadDeslocalizedArticles() {
        let articleQuery = new ArticleModel();
        articleQuery.filters = [
            {"fieldName": "serviceType", "fieldValue": "DESLOCALIZATION", "filterOperator": "EQ"},
        ];
        let result = await articleQuery.findPlainObject();
        this.articlesDeslocalization = result;
    }

    @computed get labelValuesDeslocalizationForSelect() {
            let result = [];
            let copia = {};
            for (let item of this.articlesDeslocalization) {
                if (copia[item.id] == null) {
                    copia[item.id] = "";
                    result.push({
                        value: item.id,
                        label: item.code,
                    });
                }
            }
            return result;
        }



}

export default ArticleState;
