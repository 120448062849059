import React, { Component } from 'react';
import appState from '../state/AppState';
import ReactHtmlParser from 'react-html-parser';
import { observer } from "mobx-react";
import md5 from 'crypto-js/md5';

@observer
export default class DebbugerComponent extends Component {

    render() {
        return (
            <div>
                {appState.layoutState.infoDebuggerEnabled &&
                <div className={"logger-block"} style={{ padding: 10 }}>
                    LOG {appState.queryLoggers.length}
                    {appState.queryLoggers.map((loggerQuery, index) =>
                        <div className={"column"} key={index}>
                            <hr />
                            <div className="query">
                                <span>Query:</span>
                                <div>
                                    {ReactHtmlParser(this.formatLogLine(loggerQuery.query))}
                                </div>
                            </div>
                            <div className="variables">
                                <span>Var:</span>
                                <div>
                                    {ReactHtmlParser(this.formatLogLine(loggerQuery.variables))}
                                </div>
                            </div>
                            <div className="logger">
                                <span>logger:</span>
                                <div>
                                    {loggerQuery.loggers.map(log =>
                                        <div className={"logger-item logger-" + log.type} key={md5(log.message)}>
                                            <div className="time">{log.time}</div>
                                            <div className={"type " + log.type}>{log.type}</div>
                                            <div className="ellapsed-ms">{log.ellapsedMs}ms</div>
                                            <div
                                                className="message">{ReactHtmlParser(this.formatLogLine(log.message))}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                }
            </div>
        )
    }

    formatLogLine(line) {
        var lineReplaced = line.replace(/(FROM )(\`\w+\`)/mg, function (match, match1, match2) {
            return "FROM <span class='tablename'>" + match2 + "</span>";
        });
        lineReplaced = lineReplaced.replace(/SELECT |INSERT INTO |ORDER BY |FROM |WHERE |OR |AND |CASE /mg, function (match) {
            return "<span class='sql'>" + match + "</span>";
        });
        lineReplaced = lineReplaced.replace(/\w+ \{/g, function (match) {
            return "<span class='gqlParent'>" + match + "</span>";
        });
        lineReplaced = lineReplaced.replace(/\w+\{/g, function (match) {
            return "<span class='gqlParentChild'>" + match + "</span>";
        });
        lineReplaced = lineReplaced.replace(/\w+\:/mg, function (match) {
            return "<span class='variableDollar'>" + match + "</span>";
        });
        lineReplaced = lineReplaced.replace(/\"\w+\":/mg, function (match) {
            return "<span class='variableJs'>" + match + "</span>";
        });
        lineReplaced = lineReplaced.replace(/\w+InputType/mg, function (match) {
            return "<span class='variableInputType'>" + match + "</span>";
        });

        return lineReplaced;
    }
}
