import React from 'react'
import { observer } from 'mobx-react'
import FormInputWrapper from './FormInputWrapper'
import BaseFieldComponent from './BaseFieldComponent'
import AppLogger from '../../util/AppLogger'
import util from '../../util/Util'
import VsfButtonNew from '../VsfButtonNew'
import MezclarObj from '../../util/MezclarObj'

@observer
export default class InputTypeComponent extends BaseFieldComponent {
    static defaultProps = MezclarObj.assign(BaseFieldComponent.defaultProps, {
        type: 'text',
    });

    constructor(props) {
        super(props);
        this.state = { errorsClientValidation: [], value: this.props.value || '' }
    }

    componentWillReceiveProps(props) {
        this.setState({
            value: props.value || ''
        })
    }

    onBlurFunction(e) {
        let obj = {
            target: {
                value: e.target.value || this.state.value,
                name: this.props.name
            }
        };
        if (this.props.onBlur != null) {
            this.props.onBlur(obj)
        } else {
            this.validateOnBlurFunction(obj)
        }
    }

    onClickClearable(){
        if (this.props.onClickClearable != null) {
            this.props.onClickClearable();
        } else {
            let eventObj = {
                target: {
                    name: this.props.name,
                    value: ""
                }
            };
            this.validateOnChangeFunction(eventObj);
        }
    }

    //El autocomplete esta con new-password porque es lo unico que aceptan TODOS
    //los navegadores y la aplicacion no necesita que coja de cache nada
    render() {
        let props = this.props;
        let classNameInput = this.getClassNameInput();

        let readOnly = this.checkReadOnly();


        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                styleClassGroup={props.styleClassGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix} formGroup={this.props.formGroup}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title} onClickIcon={this.props.onClickIcon}
                required={this.props.required}
                info={this.props.info}
                relatedInfo={this.props.relatedInfo}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                labelClassName={this.props.labelClassName}
                flexLabelInput={this.props.flexLabelInput ? "d-flex justify-content-between" : ""}
            >
                {this.props.showView ?
                    <div>
                        {props.value}
                    </div>
                    :
                    <>
                    <input
                        value={this.state.value}
                        autoComplete="new-password" autoFocus={this.props.autoFocus}
                        className={classNameInput} onKeyDown={(e) => this.onKeyDownPrevent(e)}
                        name={props.name}
                        inputMode={this.props.inputMode}
                        placeholder={props.placeholder}
                        onChange={e => this.validateOnChangeFunction(e)}
                        readOnly={readOnly}
                        type={this.props.type}
                        data-cy={props.dataCy}
                        onBlur={(e) => this.onBlurFunction(e)}
                    />
                        {(this.props.isClearable && util.hasValue(this.state.value)) &&
                        <div className="input-group-remove">
                            <VsfButtonNew onClick={() => this.onClickClearable()} className="btn close" aria-label="Cerrar">
                                <span className="fas fa-times" />
                            </VsfButtonNew>
                        </div>
                        }

                    </>
                }
                {props.children}
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


