import React from 'react'
import { observer } from 'mobx-react'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import BaseEditableRow from '../base/BaseEditableRow'
import { computed, observable } from 'mobx'
import appState from '../../state/AppState'
import InfoBlock from '../../components/InfoBlock'
import translate from '../../translator/translate'
import util from '../../util/Util'
import FormQuestionModel from '../../models/FormQuestionModel'
import AppLogger from '../../util/AppLogger'
import Select2Component from '../../components/fields/Select2Component'
import SwitchComponent from '../../components/fields/SwitchComponent'
import VsfButtonNew from '../../components/VsfButtonNew'

@observer
class ConfigFormQuestionRow extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });
    @observable optionsValues = [""];

    constructor(props) {
        super(props);
        this.modalBaseRow = true;
        this.foreingKeyfield = "formId";
        this.state = {
            fixedTableWidth: 0
        };
        this.myRef = React.createRef()
    }

    @computed get mobxListado() {
        return this.props.form.formQuestion;
    }


    setMobxListado(newValue) {
        this.props.form.formQuestion = newValue;
    }

    async componentDidMount() {
        await super.componentDidMount();
        let formQuestion = this.props.row;
        if (util.hasValue(formQuestion.optionValues)) {
            this.optionsValues = formQuestion.optionValues.split(",");
        }
        this.calcWidthTable()
    }


    calcWidthTable() {
        let fixedTableWidth = this.myRef.clientWidth;
        let trChilds = this.myRef.childNodes;
        let newState = {};
        let i = 0;
        for (let th of trChilds) {
            newState["fixedTableWidth_" + i++] = th.clientWidth;
        }
        this.setState({ ...newState, fixedTableWidth });
        // this.log({ newState, fixedTableWidth, ref: this.myRef })
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    getModelTable() {
        return new FormQuestionModel();
    }

    updateInput(e) {
        appState.layoutState.formWithoutChanges = false;
        this.handleInputChange(e)
    }


    updateInputType(e) {
        appState.layoutState.formWithoutChanges = false;
        let eventObj = {
            target: {
                value: this.getModelQuery().getCodeNumeric(e.target.value, 5),
                name: "code"
            }
        };
        if (e.target.value === FormQuestionModel.CODE_SWITCH) {
            this.optionsValues = this.optionsValues.slice(0, 2);
        } else if (e.target.value === FormQuestionModel.CODE_RADIO_BUTTON) {
            this.optionsValues = [];
        }
        this.handleInputChange(eventObj);
        this.handleInputChange(e)
    }

    async rowDidSave() {
        if (this.props.reload != null) {
            await this.props.reload()
        }
    }

    getModelQuery() {
        return new FormQuestionModel();
    }

    render() {
        return (
            <>
                {this.renderImpl()}
                {this.renderModals()}
            </>
        )
    }

    renderImpl() {
        const t = translate;
        let formQuestion = this.props.row;
        let style = { "width": this.state.fixedTableWidth, 'borderBottom': 0 };
        let options = {
            [FormQuestionModel.CODE_TEXT]: t("Texto"),
            [FormQuestionModel.CODE_NUMBER]: t("Numero"),
            [FormQuestionModel.CODE_SELECT]: t("Select"),
            [FormQuestionModel.CODE_DATE]: t("Fecha"),
            [FormQuestionModel.CODE_SWITCH]: t("Dos opciones"),
            [FormQuestionModel.CODE_RADIO_BUTTON]: t("Radio Button"),
        };

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr style={style} ref={myRef => {
                    this.myRef = myRef
                }}>
                    <td scope="row" style={{ width: this.state["fixedTableWidth_" + 0] }}>
                        {formQuestion.title}
                    </td>

                    <td style={{ width: this.state["fixedTableWidth_" + 1] }}>
                        {options[formQuestion.inputType]}
                    </td>
                    <td style={{ width: this.state["fixedTableWidth_" + 3] }}>
                        {formQuestion.required ? t("Si") : t("No")}
                    </td>
                    <td style={{ width: this.state["fixedTableWidth_" + 4] }}>
                        {formQuestion.questionOrder}
                    </td>
                    <td style={{ width: this.state["fixedTableWidth_" + 5] }}>
                        <div className="row">
                            {this.renderEditBlock()}
                            <div className="remove-add text-click">
                                <div
                                    id={"duplicar"} onClick={() => this.newQuestionForm(formQuestion)}
                                    title={t("Nuevo")}>
                                    <span>{t("Duplicar")} </span>
                                </div>
                            </div>
                        </div>
                    </td>

                </tr>
            </InfoBlock>
        );
    }

    async newQuestionForm(formQuestion) {
        let formQuestionModel = new FormQuestionModel();
        formQuestionModel.hidrate(formQuestion);
        formQuestionModel.id = "";
        formQuestionModel.code = this.getModelQuery().getCodeNumeric(formQuestion.inputType, 5);
        await formQuestionModel.persist();
        await this.rowDidSave(formQuestionModel);

    }

    renderCamposModal() {
        const t = translate;
        let formQuestion = this.props.row;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let options = [
            { label: "Texto", value: FormQuestionModel.CODE_TEXT },
            { label: "Numero", value: FormQuestionModel.CODE_NUMBER },
            { label: "Select", value: FormQuestionModel.CODE_SELECT },
            { label: "Fecha", value: FormQuestionModel.CODE_DATE },
            { label: "Dos opciones", value: FormQuestionModel.CODE_SWITCH },
            { label: "Radio Button", value: FormQuestionModel.CODE_RADIO_BUTTON },
        ];
        return (
            <div className={"row"}>
                <InputTypeComponent
                    value={formQuestion.title || ""}
                    onChange={(e) => this.updateInput(e)}
                    classGroup={'col-6'}
                    name={"title"}
                    title={t("Título")}
                    type={"text"}
                    className="form-control"
                    errors={errorsMapped.title}
                />
                <Select2Component
                    value={formQuestion.inputType}
                    onChange={(e) => this.updateInputType(e)}
                    classGroup={'col-6 '}
                    title={t("Tipo")}
                    extraWidth={100} name={"inputType"}
                    isClearable={false} required
                    type={"text"}
                    errors={errorsMapped.inputType}
                    options={options}
                />

                <div className="col-6 row">
                    <SwitchComponent
                        checked={formQuestion.required}
                        title={t("Obligatorio")}
                        value={formQuestion.required}
                        classGroup={"col-6"}
                        onChange={(e) => this.updateInput(e)}
                        name={"required"}
                    />

                </div>
                <div className={"col-12"}>
                    <span>{t('Valores Selector')}</span>
                    {this.optionsValues.map((obj, index) => (
                        <div className={" row"}>
                            <InputTypeComponent
                                value={this.optionsValues[index]}
                                onChange={(e) => {
                                    this.optionsValues[index] = e.target.value;
                                    this.handleAutosuggestSelection("optionValues", this.optionsValues.join(","))
                                }}
                                name={"optionValues"}
                                classGroup={"col-6"}
                                className="form-control"
                                readOnly={(formQuestion.inputType !== FormQuestionModel.CODE_SELECT && formQuestion.inputType !== FormQuestionModel.CODE_SWITCH)}
                                errors={errorsMapped.optionValues}
                            />
                            {(formQuestion.inputType === FormQuestionModel.CODE_SELECT || formQuestion.inputType === FormQuestionModel.CODE_SWITCH) &&
                            <>

                                {(index === (this.optionsValues?.length - 1) && (formQuestion.inputType !== FormQuestionModel.CODE_SWITCH || index < 1)) &&
                                <div className="remove-add">
                                    <div
                                        id={"newQuestionForm"} onClick={() => this.optionsValues.push("")}
                                        title="Nuevo" className="edit">
                                        <span className="fas fa-plus-circle"> </span>
                                        <input type="radio" onFocus={() => this.optionsValues.push("")} style={{
                                            width: '20px',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }} />
                                    </div>
                                </div>
                                }
                                {index !== 0 &&
                                <div className="remove-add">
                                    <div
                                        id={"deleteQuestionForm"}
                                        onClick={() => this.optionsValues.splice(index, 1)}
                                        title="Nuevo" className="edit">
                                        <span className="fas fa-minus-circle"> </span>
                                    </div>
                                </div>
                                }
                            </>
                            }
                        </div>

                    ))}
                </div>
                <div className="col-8">
                    <VsfButtonNew
                        label={t("Guardar")}
                        onClick={() => this.onSaveRow()}
                        className="btn btn--loader"
                    />
                </div>
            </div>
        )
    }

}

export default ConfigFormQuestionRow;
