import AbstractModel from './AbstractModel'

export default class PlanogramDetailModel extends AbstractModel {

    dateExpire
    numberRail
    ratio
    price
    temperature
    planogramInfo
    canId
    qualityTasteId
    assetId
    productId

    nameMainType = 'planogramDetail'
    graphFindByIdOperation = 'planogramDetail'
    graphFindOperation = 'planogramDetailsConnection'

    getArrayFields () {
        let resultBase = super.getArrayFields()
        let result = {
            ...resultBase,
            "dateExpire": '',
            "numberRail": '',
            "ratio": '',
            "price": '',
            "temperature": '',
            "planogramInfo": '',
            "canId": '',
            "qualityTasteId": '',
            "assetId": '',
            "productId": '',

        }
        return result
    }

}
