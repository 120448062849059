import React from 'react';
import { observer } from 'mobx-react';
import BaseFieldComponent from "./BaseFieldComponent";
import FormInputWrapper from "./FormInputWrapper";
import AppLogger from "../../util/AppLogger";
import RadioButtonComponent from "./RadioButtonComponent";


@observer
export default class CheckboxUniqueComponent extends BaseFieldComponent {

    constructor(props) {
        super(props);
        this.state = { checked: this.props.checked };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== this.props.checked) {
            this.setState({
                checked: nextProps.checked
            })
        }
    }

    onChange(event) {
        let eventObj = {
            target: {
                name: this.props.name,
                value: !event.target.value
            }
        };
        eventObj.target.value = !this.props.checked;
        if (this.props.withValue) {
            eventObj = {
                target: {
                    name: this.props.name,
                    value: this.props.value
                }
            };
        }
        this.props.onChange(eventObj);
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        let props = this.props;
        let readOnly = this.checkReadOnly();
        return (
            <div
                className={"form-checkform-check-inline " + this.props.className}>
                <FormInputWrapper
                    classGroup={props.classGroup}
                    classInputType={"Select2Component"}
                    postfix={this.props.postfix}
                    prefix={this.props.prefix}
                    errors={this.props.errors}
                    required={this.props.required}
                    isClearable={this.props.isClearable} {...this.props}
                    info={this.props.info}
                    name={props.name}
                    errorsClientValidation={this.state.errorsClientValidation}
                    title={this.props.title}
                    relatedInfo={this.props.relatedInfo}
                    hasNoErrors={this.props.hasNoErrors}
                    forceMedia={this.props.forceMedia}
                    labelClassName={this.props.labelClassName}>
                    {readOnly ?
                        <RadioButtonComponent
                            value={props.checked || false}
                            editable={!readOnly}
                            checked={"0"}
                            name={"warranty"}
                        />
                        :
                        <>
                            <input
                                autoComplete="off"
                                type="checkbox"
                                style={{ display: "block" }}
                                name={props.name}
                                checked={this.state.checked}
                                id={props.name}
                                aria-label={props.name}
                                placeholder={props.placeholder}
                                value={props.value || ''}
                                onChange={e => this.onChange(e)}
                                readOnly={readOnly}
                            />
                            <label htmlFor={props.name} />
                        </>
                    }
                </FormInputWrapper>
            </div>


        );
    }
}

