import DocumentModel from './DocumentModel'
import ClientModel from './ClientModel'
import AssetModelModel from './AssetModelModel'
import SaveOptionsModel from './SaveOptionsModel'
import TypifiedValueModel from './TypifiedValueModel'
import WorkLogModel from './WorkLogModel'
import PlanogramDetailModel from './PlanogramDetailModel'

export default class AssetModel extends SaveOptionsModel {

    static CODE_KILOMETERS = "km";
    static CODE_METERS = "m";
    static CODE_CENTIMETERS = "cm";
    static CODE_MILIMETERS = "mm";
    static CODE_INCHES = "in";
    static CODE_FEET = "ft";
    static CODE_KILOGRAMS = "kg";
    static CODE_GRAMS = "g";
    // category;
    createdAt;
    createdBy;
    planogramDetailCount;
    description;
    userPlate;
    endDateWarranty;
    endDateWarrantyNotify;
    startDateWarranty;
    codeErp;
    exploitation;
    id;
    location;
    maintenance;
    manufacturer;
    manufacturerRef;
    model;
    subType;
    plate;
    pos;
    posAddress;
    movementError;
    propietary;
    zone;
    serialNumber;
    status;
    storeId;
    type;
    geoLongitude;
    geoLatitude;
    updatedAt;
    updatedBy;
    dynamicField;
    warehouse;
    warranty;
    labourWarranty;
    articleWarranty;
    dimensionsUnit;
    weightUnit;
    supplier;
    purchaseDate;
    clientOrderNumber;
    orderNumber;
    purchasePrice;
    salePrice;
    weeePrice;
    productionDate;
    deliveryDate;
    installationDate;
    retirementDate;
    brand;
    tagId;
    barCodeId;
    qrCodeId;
    parentAssetId;
    contractNumber;
    contractPropietary;
    propertyRegime;
    pcd;
    comments;
    managerMaintenance;
    deslocalizationWarranty;
    labourWarrantyStartDate;
    labourWarrantyEndDate;
    articleWarrantyStartDate;
    articleWarrantyEndDate;
    deslocalizationWarrantyStartDate;
    deslocalizationWarrantyEndDate;

    connectivyTrigger;
    connectivyTriggerId;
    connectivySerialNumber;
    connectivyModel;
    connectivyFirmware;
    connectivy;
    connectivySimCard;
    connectivyPlatform;
    connectivyAlerts;
    connectivitySN;
    nameMainType = "asset";
    graphFindByIdOperation = "asset";
    graphFindOperation = "assetsConnection";
    graphExportOperation = "assetsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "endDateWarranty": {label: 'Fecha fin de garantia'},
            "startDateWarranty": {label: 'Fecha inicio de garantia'},
            //"endDateWarrantyNotify": { label: 'Notificación de fecha fin de garantía'},
            "codeErp": {label: "Código ERP"},
            "dynamicField": {label: "Campos Dinámicos"},
            "userPlate": {label: "Referencia interna"},
            "exploitation": {label: "Explotacion"},
            "location": {label: 'Ubicacion'},
            "maintenance": {label: "Mantenimiento"},
            "manufacturer": {label: 'Fabricante'},
            "planogramDetailCount": {label: 'Nº de carriles'},
            "pcd": {label: 'PCD'},
            "manufacturerRef": {label: "Referencia de Fabricante"},
            "warehouse": {label: "Almacén"},
            "model": {label: 'Modelo'},
            "plate": {label: 'Numero de Equipo'},
            "subType": {label: 'Subtipo de Equipo'},
            "pos": {label: "Posición"},
            "movementError": {label: "Error de Movimiento"},
            "posAddress": {label: "Dirección"},
            "propietary": {label: "Propietario", relationName: "clientPropietary", relationValue: "companyName"},
            "serialNumber": {label: 'Numero de Serie'},
            "status": {label: 'Estado'},
            "storeId": {label: 'Emplazamiento', relationName: "store", relationValue: "name"},
            "zone": {label: 'Zona'},
            "type": {label: 'Tipo de Equipo'},
            "geoLongitude": {label: "Longitud"},
            "geoLatitude": {label: "Latitud"},
            "warranty": {label: "Garantía"},
            "labourWarranty": {type: "BoolField", label: "Mano de Obra"},
            "articleWarranty": {type: "BoolField", label: "Piezas"},
            "supplier": {label: "Proveedor"},
            "purchaseDate": {label: "Fecha de Compra"},
            "clientOrderNumber": {label: "Número del Pedido del Cliente"},
            "orderNumber": {label: "Número del Pedido"},
            "purchasePrice": {label: "Precio de Compra"},
            "salePrice": {label: "Precio de Venta"},
            "weeePrice": {label: "Precio WEEE"},
            "productionDate": {label: "Fecha de Producción"},
            "deliveryDate": {label: "Fecha de Entrega"},
            "installationDate": {label: "Fecha de Instalación"},
            "retirementDate": {label: "Fecha de Retiro"},
            "brand": {label: "Marca"},
            "tagId": {label: "Matrícula propiedad del cliente"},
            "barCodeId": {label: "ID del Código de Barras"},
            "qrCodeId": {label: "ID del Código QR"},
            "parentAssetId": {label: "ID del Equipo Padre"},
            "contractNumber": {label: "Número del Contrato"},
            "contractPropietary": {label: "Propietario del Contrato"},
            "propertyRegime": {label: "Régimen de Propiedad"},
            "comments": {label: "Comentarios"},
            "managerMaintenance": {label: "Mantenimiento del Gerente"},
            "serialNotVisible": {label: "Nº de Serie no visible"},
            "deslocalizationWarranty": {label: "Garantía de Localización"},
            "labourWarrantyStartDate": {label: "Fecha Inicio de la Garantía de Trabajo"},
            "labourWarrantyEndDate": {label: "Fecha Fin de la Garantía de Trabajo"},
            "articleWarrantyStartDate": {label: "Fecha Inicio de la Garantía de Artículo"},
            "articleWarrantyEndDate": {label: "Fecha Fin de la Garantía de Artículo"},
            "deslocalizationWarrantyStartDate": {label: "Fecha Inicio de la Garantía de Localización"},
            "deslocalizationWarrantyEndDate": {label: "Fecha Fin de la Garantía de Localización"},
            "connectivyTrigger": "",
            "connectivyTriggerId": "",
            "connectivySerialNumber": "",
            "connectivyModel": "",
            "connectivyFirmware": "",
            "connectivy": "",
            "connectivySimCard": "",
            "connectivyPlatform": "",
            "connectivitySN": "",
            "connectivyAlerts": "",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "assetModel", "RelationOne", AssetModelModel);
        this.addRelatedTableGeneric(result, "store", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "parentAsset", "RelationOne", AssetModel);
        this.addRelatedTableGeneric(result, "clientPropietary", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "typeAsset", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "subTypeAsset", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "statusAsset", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "workLog", "Relation", WorkLogModel);
        this.addRelatedTableGeneric(result, "replaceDocuments", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "planogramDetail", "Relation", PlanogramDetailModel);

        return result;
    }

    getArrayForExcelImports() {
        let result = {
            "Datos generales": [
                "plate",
                "tagId",
                "manufacturer",
                "model",
                "serialNumber",
                "status",
            ],
            "Datos de ubicación": [
                "posAddress",
            ],
            "Datos de compra": [
                "weeePrice",
                "pcd",
                "supplier",
                "installationDate",
                "retirementDate",
            ]
        };
        return result;
    }


};




















