import DocumentModel from './DocumentModel'
import VehicleModel from './VehicleModel'
import DateRangesModel from './DateRangesModel'
import CheckWorkDaysModel from './CheckWorkDaysModel'
import UserOnlinesModel from './UserOnlinesModel'
import SaveOptionsModel from './SaveOptionsModel'
import TypifiedValueModel from './TypifiedValueModel'
import SubcontractModel from './SubcontractModel'
import t from '../translator/translate'

export default class UserModel extends SaveOptionsModel {
    static ACTIONS_END_DAY = 'END_TECHNICAL_DAY'

    static STATUS_ACTIVO = 'ACTIVO'
    static STATUS_INACTIVO = 'INACTIVO'
    static STATUS_BAJA_TEMPORAL = 'BAJA_TEMPORAL'
    static STATUS_BAJA_DEFINITIVA = 'BAJA_DEFINITIVA'
    static STATUS_BLOCKED = 'BLOQUEADO'

    static getLabelStatus (status) {
        const result = {
            [UserModel.STATUS_ACTIVO]: t('ACTIVO'),
            [UserModel.STATUS_INACTIVO]: t('INACTIVO'),
            [UserModel.STATUS_BAJA_TEMPORAL]: t('BAJA_TEMPORAL'),
            [UserModel.STATUS_BAJA_DEFINITIVA]: t('BAJA_DEFINITIVA'),
            [UserModel.STATUS_BLOCKED]: t('BLOQUEADO'),
        }
        return result[status]
    }

    address
    birthDate
    city
    company
    contactPerson
    contactPhoneNumber
    contactRelationship
    clientId
    clientPosIds
    country
    email
    employeeNumber
    firstName
    status
    id
    idNumber
    companyPhoneNumber
    lastName
    lastStartDay
    lastEndDay
    locale
    mobileNumber
    password
    phoneNumber
    tenantId
    employment
    postalCode
    province
    insuranceNumber
    capacitationIds
    subcontractId
    role
    stockControl
    erpSalesAutomatic
    citizenNumber
    permission
    actions
    subInventory
    userVehicle
    timeZone
    permissionZoneId
    zones
    codeErp
    completeName //Este campo solo se pide en las relaciones con UserModel
    zoneAsignedId
    nameMainType = 'user'
    graphFindByIdOperation = 'user'
    graphFindOperation = 'usersConnection'
    graphExportOperation = 'usersExport'

    getArrayFields () {
        let resultBase = super.getArrayFields()
        let result = {
            ...resultBase,
            'address': { label: 'Direccion' },
            'birthDate': { label: 'Fecha de Nacimiento' },
            'city': { label: 'Ciudad' },
            'company': { label: 'Empresa' },
            'permissionZoneId': { label: 'Permiso de zona' },
            'contactPerson': { label: 'Persona de contacto' },
            'contactPhoneNumber': { label: 'Telefono de Contacto' },
            'contactRelationship': { label: 'Relacion' },
            'companyEmail': { label: 'Email de empresa' },
            'country': { label: 'Pais' },
            'clientId': { label: 'Cliente' },
            'clientPosIds': { label: 'Establecimiento' },
            'completeName': {
                label: 'Nombre completo',
                readonly: true
            },
            'email': { label: 'E-mail' },
            'lastStartDay': { label: 'Último Inicio de Jornada' },
            'lastEndDay': { label: 'Último Fin de Jornada' },
            'subcontractId': { label: 'ID de ' },
            'employeeNumber': { label: 'Número de empleado' },
            'employment': { label: 'Puesto de trabajo' },
            'firstName': { label: 'Usuario' },
            'idNumber': { label: 'DNI' },
            'lastName': { label: 'Apellido' },
            'citizenNumber': { label: 'Número de ciudadano' },
            'insuranceNumber': { label: 'Número de la SS' },
            'locale': { label: 'Local' },
            'mobileNumber': { label: 'Número de móvil' },
            'password': {
                label: 'Contraseña',
                mutationOnly: true
            },
            'phoneNumber': { label: 'Numero de Telefono' },
            'tenantId': { label: 'ID del tenant' },
            'postalCode': { label: 'Código Postal' },
            'province': { label: 'Provincia' },
            'role': { label: 'Rol' },
            'stockControl': {
                label: 'Control de stock',
                type: 'BoolField'
            },
            'erpSalesAutomatic': {
                label: 'Envío ERP automatico',
                type: 'BoolField'
            },
            'subInventory': { label: 'Almacén' },
            'companyPhoneNumber': { label: 'Número de empresa' },
            'capacitationIds': { label: 'IDs de las capacitaciones' },
            'timeZone': { label: 'Zona horaria' },
            'permission': { label: 'Permiso' },
            'actions': { label: 'Acciones' },
            'userVehicle': { label: 'Vehículo' },
            'status': { label: 'Estado' },
            'zoneAsignedId': { label: 'Zona de trabajo' },
            'codeErp': { label: 'Código ERP' },
        }
        this.addRelatedTableGeneric(result, 'documents', 'Relation', DocumentModel)
        this.addRelatedTableGeneric(result, 'photoProfile', 'RelationOne', DocumentModel)
        this.addRelatedTableGeneric(result, 'vehicle', 'RelationOne', VehicleModel)
        this.addRelatedTableGeneric(result, 'dateRangeUser', 'Relation', DateRangesModel)
        this.addRelatedTableGeneric(result, 'subcontract', 'RelationOne', SubcontractModel)
        this.addRelatedTableGeneric(result, 'checkWorkDays', 'Relation', CheckWorkDaysModel)
        this.addRelatedTableGeneric(result, 'userOnline', 'RelationOne', UserOnlinesModel)
        this.addRelatedTableGeneric(result, 'employmentUser', 'RelationOne', TypifiedValueModel)
        this.addRelatedTableGeneric(result, 'zones', 'Relation', TypifiedValueModel)
        this.addRelatedTableGeneric(result, 'roles', 'Relation', TypifiedValueModel)
        return result
    }

};
