import React, {Component} from 'react';
import PropTypes from 'prop-types';
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import AppLogger from "../../util/AppLogger";
import ReactTooltip from "react-tooltip";
import VsfButtonNew from "../VsfButtonNew";
import util from "../../util/Util";
import VsfLink from "../VsfLink";
import appState from "../../state/AppState";

class PageTitleRightModal extends Component {
    static propTypes = {
        title: PropTypes.string,
        subtitle: PropTypes.string,
        showClose: PropTypes.bool,
        hasEditOrView: PropTypes.bool,
        onCloseModal: PropTypes.func,
        onClickEdit: PropTypes.func,
        onClickView: PropTypes.func,
    };
    static defaultProps = {
        title: "",
        subtitle: "",
        showClose: true,
        hasEditOrView: false,
        onCloseModal: () => {
        },
    };

    constructor(props) {
        super(props);
        this.state = {
            mouseEnter: false,
        }
    }

    onBackModal() {
        this.props.history.goBack()
    }

    urlModalInPage() {
        // this.log({ props: this.props })
        let modelId = this.props.componentObject.modelPlainFirstLoaded?.id;
        let path = this.props.componentObject.props.path;
        let result = path;
        if (util.hasValue(path)) {
            result = path.replace(":id?", modelId);
        }
        return result;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        return (
            <div className="row">
                <div className="col-12 col-md-6 text-left">
                    <div className="modal-title">
                        <p>{this.props.title} <span>{this.props.subtitle}</span></p>
                    </div>
                </div>
                <div className="col-12 col-md-6 d-flex justify-content-end align-items-end">
                    <>
                        {this.props.children}
                        {util.hasValue(this.urlModalInPage()) &&
                        <VsfLink to={this.urlModalInPage()} className="btn close btn-span"
                                 onClick={() => {
                                     appState.scheduleByTechDropState.slotClicked = {};
                                 }}>
                               <span className="fas fa-expand-arrows-alt" data-tip data-for={"spanModal"}
                                     onMouseEnter={(e) => {
                                         this.setState({ mouseEnter: true });
                                     }} />
                        </VsfLink>
                        }
                        {this.state.mouseEnter &&
                        <ReactTooltip id={"spanModal"} key={"spanModal"} textColor="#6C757D"
                                      backgroundColor="#ffffff" arrowColor="#f7efef" place={'bottom'}
                                      className="customReactTooltip">
                            {t("Abrir en pantalla completa")}
                        </ReactTooltip>
                        }

                        <VsfButtonNew className="close" aria-label="Cerrar"
                                      onClick={() => this.onBackModal()}>
                                <span className="fas fa-long-arrow-alt-left" data-tip data-for={"spanBack"}
                                      onMouseEnter={(e) => {
                                          this.setState({ mouseEnter: true });
                                      }} />
                        </VsfButtonNew>
                        {this.state.mouseEnter &&
                        <ReactTooltip id={"spanBack"} key={"spanBack"} textColor="#6C757D"
                                      backgroundColor="#ffffff" arrowColor="#f7efef" place={'bottom'}
                                      className="customReactTooltip">
                            {t("Volver")}
                        </ReactTooltip>
                        }
                        <VsfButtonNew className="close" aria-label="Cerrar"
                                      onClick={() => this.props.onCloseModal()}>
                                <span className="fas fa-times" data-tip data-for={"spanClose"}
                                      onMouseEnter={(e) => {
                                          this.setState({ mouseEnter: true });
                                      }} />
                        </VsfButtonNew>
                        {this.state.mouseEnter &&
                        <ReactTooltip id={"spanClose"} key={"spanClose"} textColor="#6C757D"
                                      backgroundColor="#ffffff" arrowColor="#f7efef" place={'bottom'}
                                      className="customReactTooltip">
                            {t("Cerrar modal")}
                        </ReactTooltip>
                        }
                    </>
                </div>

            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(PageTitleRightModal)
