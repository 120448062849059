import React, {Component} from 'react';
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";
import WarningModal from "../../subpages/WarningModal";
import VsfButtonNew from "../VsfButtonNew";

class GqlWarnings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            warnings: this.props.warnings?.slice(0, 4),
            coGqlWarnings: this.props.warnings || [],
            loading: false
        }
    }


    render() {
        let props = this.props;
        const t = translate;

        if (this.state.warnings == null) {
            return null;
        }
        if (this.state.warnings?.length === 0) {
            return null;
        }
        return (
            <WarningModal isOpen={this.state.warnings.length > 0}
                          onCloseModal={() => this.resetWarnings()}>
                <div className="modal-head">
                    <div className="warning-img">
                        <img src="/img/warning.png" alt="Alerta" className="header-brand-full"/>
                    </div>
                    <div className="modal-title-warning">
                        <p>{t("Atencion")}</p>
                    </div>
                </div>
                <div className="modal-body-warning">
                    {this.props.spinner &&
                    <div className=' text-center'>
                        <i className="fas fa-spinner fa-pulse"/>
                    </div>
                    }
                    <div className={"column"}>
                        {this.state.warnings.map((error, index) => (
                                <div key={"warning" + index}>
                                    {error.extensions?.data?.canBeTranslated === "1" ?
                                        <div>
                                            {t(error.message)}
                                        </div>
                                        :
                                        <div>
                                            {error.message}
                                        </div>
                                    }
                                </div>
                            )
                        )
                        }
                    </div>
                </div>
                <div className="modal-footer-warning">
                    <div className='d-md-flex text-right'>
                        <VsfButtonNew
                            showButton
                            className="btn btn-secondary"
                            onClick={() => this.resetWarnings()}
                            label={t("Cancelar")}
                            classGroup={'button-form-group mr-md-3'}
                        />
                        <VsfButtonNew
                            label={t("Guardar de todos modos")}
                            loading={this.state.loading}
                            className="btn btn--loader" showButton
                            onClick={async () => {
                                await this.setState({loading: true});
                                await this.props.onSaveWarning();
                                await this.setState({loading: false});
                            }}
                            classGroup={'button-form-group'}
                        />
                    </div>
                </div>
            </WarningModal>
        )

    }


    resetWarnings() {
        this.props.setWarnings([]);
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

};


export default GqlWarnings
