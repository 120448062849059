import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import HistoryComponent from "../HistoryComponent";
import Article from "../../models/ArticleModel";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class ArticleHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.nameMainType = "article";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            loading: true,
        }
    }

    getModelQuery() {
        return new Article();
    }

    async componentDidMountImpl() {
        this.setState({ loading: false })
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        const t = translate;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <SpinnerClipLoaderInPage loading={this.state.loading}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()} mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataArticles()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            />
                        </PageTitle>

                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataArticles()}
                                active="Histórico"
                            />
                            }

                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />


                        </div>

                    </div>
                    </SpinnerClipLoaderInPage>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(ArticleHistory)
