import React, {Component} from 'react';
import {observer} from "mobx-react/index";
import translate from "../translator/translate";

@observer
class PageNoData extends Component {


    render() {
        const t = translate;
        return (
            <section style={{overflowX: 'hidden'}}>
                <div className="login d-flex justify-content-center" style={{ height: '100vh'}}>
                    <div>
                        <h1 className="m-5">{t("Esta página no tiene datos")}</h1>
                        <h3 className="m-5 d-flex justify-content-center" style={{backgroundColor: '#BC212C', borderRadius: 4, padding: 10}}>
                            <a style={{display: 'contents', color: '#fff'}} className={"text-click"} onClick={this.props.history.goBack}>{t("Volver")}</a>
                        </h3>
                    </div>
                </div>
            </section>
        );
    }
}

export default PageNoData

