class MezclarObj {


    static assign(claseObj1, claseObj2) {
        let result = {};
        if (claseObj1) {
            for (let [key, value] of Object.entries(claseObj1)) {
                if (result[key] == null) {
                    result[key] = value;
                }
            }
        }
        if (claseObj2) {
            for (let [key, value] of Object.entries(claseObj2)) {
                if (result[key] == null) {
                    result[key] = value;
                }
            }
        }
        return result;
    }


}


export default MezclarObj;
