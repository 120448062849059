import React from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import translate from '../../translator/translate'
import withRouter from 'react-router-dom/es/withRouter'
import PropsUtil from '../../util/PropsUtil'
import AppLogger from '../../util/AppLogger'
import FiltersPage from '../FiltersPage'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import ClientModel from '../../models/ClientModel'
import Select2Component from '../../components/fields/Select2Component'

@observer
class WorkOrderArticleListFilters extends FiltersPage {

    nameVariableStateFilter = 'workOrderArticleListFilter'
    async componentDidMount () {
        await appState.userCacheState.loadUserCacheTechnicals()

    }
    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @returns Array
     */
    async getClientsSuggestions (value) {
        let clientArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            let clientQuery = new ClientModel()
            clientQuery.isPos = true
            clientQuery.filters = [
                {
                    'fieldName': 'name,code',
                    'fieldValue': value,
                    'filterOperator': 'SUBSTR'
                },
            ]
            let client = await clientQuery.find()
            client.map((client) => {
                let clientObject = {}
                clientObject.label = client.code + ' - ' + client.name
                clientObject.value = client.id
                clientArray.push(clientObject)
            })
        } catch (e) {
            this.log('Error => ' + e)
        }
        return clientArray
    }

    render () {
        this.propsUtil = new PropsUtil(this.props)
        const t = translate
        return (
            <React.Fragment>
                <SelectSuggestComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.clientId}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name={'clientId'}
                    title={t('Establecimiento')}
                    classGroup={'col-md-8 col-xl-8'}
                    loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                    placeholder={t('Escribe...')}
                />
                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.userId}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name={'userId'}
                    title={t('Técnico')}
                    classGroup={'col-4'}
                    options={appState.userCacheState.userCacheForSelectList}
                />
            </React.Fragment>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default withRouter(WorkOrderArticleListFilters)
