import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Links from "../../util/Links";
import VsfLink from "../VsfLink";
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";
import LinksForModal from "../../util/LinksForModal";

@observer
class SortTableHeadsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productAvailability: [],
            showFixedTableHeader: false,
            memoryTableHeaderLeft: 0,
            left: 0
        };
        this.props.onRef(this);
        this.myRef = React.createRef()
    }

    getUrlLinkOrder(newFieldOrder) {
        let links = new Links(this.props.location);
        if (this.props.fromRightModal) {
            links = new LinksForModal(this.props.location);
        }
        let result=links.getUrlLinkOrder(newFieldOrder);
        //this.log({getUrlLinkOrder:1,result, fromRightModal: this.props.fromRightModal})
        return result;
    }

    handleScroll(e) {
        let element = e.target;
        if (this.scrollHandler != null) {
            clearTimeout(this.scrollHandler);
        }
        this.scrollHandler = setTimeout(() => {
            this.setState({
                scrollLeft: element.scrollLeft,
                scrollWidth: element.scrollWidth,
                offsetLeft: element.offsetLeft,
            });
            this.handleScrollCallback();
        }, 100);
    };

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (!this.props.status && prevProps.status) {
            this.calcHeaderWidth()
        }
    }

    calcHeaderWidth() {
        let fixedTableWidth = this.myRef.clientWidth;
        let trChilds = this.myRef.childNodes;
        let newState = {};
        let i = 0;
        for (let th of trChilds) {
            newState["fixedTableWidth_" + i++] = th.clientWidth;
        }
        this.setState({ ...newState, fixedTableWidth });
        // this.log({ newState, fixedTableWidth, ref: this.myRef })
    }

    /**
     * Controlamos el cambio entre thead normal y fixed con sus consecuencias
     *
     */
    handleScrollCallback() {
        let finalLeft = this.state.memoryTableHeaderLeft;
        if (this.state.scrollLeft < this.state.scrollWidth - 10) {
            let initialLeft = 65 + this.state.offsetLeft;
            // Añadimos el left inicial dependiendo si el panel esta abierto o cerrado
            if (!appState.layoutState.sidebarMinimized) {
                initialLeft = 245;
            }
            finalLeft = initialLeft - this.state.scrollLeft;
            finalLeft += 16;
        }
        this.setState({ left: finalLeft });

    }

    setVisibleHeader(showFixedTableHeader) {
        this.setState({ showFixedTableHeader })
    }

    /**
     * Obtiene la clave de la columna del thead para pintar su anchura correctamente
     * @param key
     * @param showColumnActions
     * @returns {string}
     */
    getThIndex(key, showColumnActions) {
        let index = "fixedTableWidth_";
        // Dependiendo de si mostramos el boton de accion rapida, sumamos o restamos al indice
        if (showColumnActions) {
            index += (key + 0);
        } else {
            index += (key - 1);
        }
        return index;
    }

    getParamOrder(params) {
        let result = params.get('order');
        if (this.props.fromRightModal) {
            result = params.get('modalOrder');
        }
        return result;
    }

    getParamOrderMode(params) {
        let result = params.get('orderMode');
        if (this.props.fromRightModal) {
            result = params.get('modalOrderMode');
        }
        return result;
    }


    render() {
        const params = new URLSearchParams(this.props.location.search);
        let props = this.props;
        let columnsSelect = this.props.fields;
        const fieldOrder = this.getParamOrder(params);
        const orderMode = this.getParamOrderMode(params);
        let showColumnActions = false;
        if (props.quickEdit || this.props.quickView) {
            showColumnActions = true;
        }
        let style = { "width": this.state.fixedTableWidth };
        if (this.state.left !== 0) {
            style["left"] = this.state.left;
        }
        showColumnActions = true;
        let orderName = "";
        return (
            <>
                <thead id={"sortTableHead"}>
                <tr ref={myRef => {
                    this.myRef = myRef
                }}>
                    <th style={{ width: '40px' }} />
                    {columnsSelect.map(((column, key) => {
                            orderName = (column.prefixName || "") + column.name;
                            if(column.orderName != null){
                                orderName = column.orderName
                            }
                            if (column.visible || props.withoutCache) {
                                return (
                                    <th
                                        key={column.name}
                                        scope="col"
                                        data-col={column.name}
                                        className={fieldOrder === orderName ? "active" : ""}>
                                        {column.withOrder === false ?
                                            <span>{column.title}</span>
                                            :
                                            <VsfLink
                                                title="ID Siniestro: Ordenar por esta columna Ascendentemente (A->Z)"
                                                to={this.getUrlLinkOrder(orderName)}>
                                                <span>{column.title}</span>
                                                {fieldOrder === orderName &&
                                                <>
                                                    {orderMode === "DESC" ?
                                                        <i className="fas fa-long-arrow-up" />
                                                        :
                                                        <i className="fas fa-long-arrow-down" />
                                                    }
                                                </>
                                                }
                                            </VsfLink>
                                        }
                                    </th>
                                )
                            }
                            else return null;
                        }
                    ))}
                </tr>
                </thead>
                {this.state.showFixedTableHeader &&
                <>
                    <thead className={"thead-fixed"}
                           style={style}>
                    <tr>
                        <th style={{ width: '60px' }} />
                        {columnsSelect.map(((column, key) =>
                                (column.visible || props.withoutCache) &&
                                <th
                                    key={column.name}
                                    scope="col"
                                    data-col={column.name}
                                    className={fieldOrder == column.name ? "active" : ""}
                                    style={{ width: this.state[this.getThIndex(key + 1, showColumnActions)] }}
                                >
                                    {column.withOrder === false ?
                                        <span>{column.title}</span>
                                        :
                                        <VsfLink title="ID Siniestro: Ordenar por esta columna Ascendentemente (A->Z)"
                                                 to={this.getUrlLinkOrder((column.prefixName || "") + column.name)}>
                                            <span>{column.title}</span>
                                            {fieldOrder == column.name &&
                                            <>
                                                {orderMode === "DESC" ?
                                                    <i className="fas fa-long-arrow-up" />
                                                    :
                                                    <i className="fas fa-long-arrow-down" />
                                                }
                                            </>
                                            }
                                        </VsfLink>
                                    }
                                </th>
                        ))}
                    </tr>
                    </thead>
                </>
                }
            </>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default SortTableHeadsComponent;
