import React from 'react'
import {Circle, Map, Marker, Popup, TileLayer} from 'react-leaflet';
import VsfModal from "./VsfModal";
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";
import * as Leaflet from "leaflet";
import {observer} from "mobx-react";
import {observable} from "mobx";
import util from "../../util/Util";

@observer
class MapRoutes extends React.Component {
    @observable positionUpdate = [0, 0];

    loadLocation() {
        let result = [];
        let i = 1;
        let objCopia = {};
        for (let slot of this.props.slots) {
            let clientModel = slot.client;
            if (clientModel) {
                if (objCopia[clientModel.id] == null) {
                    objCopia[clientModel.id] = 0;
                }
                let lat = clientModel.geoLatitude ? (parseFloat(clientModel.geoLatitude) + objCopia[clientModel.id]) : 0;
                let lng = clientModel.geoLongitude ? (parseFloat(clientModel.geoLongitude) + objCopia[clientModel.id]) : 0;
                let icon = L.icon({
                    iconUrl: '/img/MAP-MARKET' + i + '.png',
                    iconSize: [38, 95], // size of the icon
                    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
                    popupAnchor: [i, i]
                });
                objCopia[clientModel.id] += 0.001;
                if (i > 9) {
                    i = '_plus';
                } else {
                    i++;
                }
                let obj = { position: [lat, lng], ...clientModel, icon };
                if (lng !== 0 && lat !== 0) {
                    result.push(obj);
                }
            }
        }
        if (result.length === 0) {
            result.push({ position: [0, 0] });
        }
        return result;
    }

    loadClientsWithoutLocations() {
        let result = [];
        for (let slot of this.props.slots) {
            let clientModel = slot.client;
            if (!util.hasValue(clientModel.geoLongitude) || !util.hasValue(clientModel.geoLatitude)) {
                result.push(slot);
            }
        }
        return result;
    }

    handleLocationFound(e) {
        this.setState({
            hasLocation: true,
            latlng: e.latlng,
        })
    }


    render() {
        const positionClient = this.loadLocation();
        const slotsClientsWithoutLocation = this.loadClientsWithoutLocations();
        this.log({ slotsClientsWithoutLocation: slotsClientsWithoutLocation });
        const t = translate;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">
                        {slotsClientsWithoutLocation.length > 0 &&
                        <div>
                            <p className="mb-1">{t("Clientes sin localización") + ":"}</p>
                            {slotsClientsWithoutLocation.map((slot) =>
                                <p className="visits__text text-click"
                                   onClick={() => this.props.openClientModal(slot.client)}
                                >{slot.client.posAddress || slot.client.name}</p>
                            )}
                        </div>
                        }
                        {positionClient[0].position[0] != 0 ? this.renderWithLocation() : this.noLocationsSlots()}
                    </div>
                </div>

            </VsfModal>

        );
    }

    noLocationsSlots() {
        const positionClients = this.loadLocation();
        this.log({ slots: this.props.slots, positionClients });
        const t = translate;
        return (
            <Map center={positionClients[0].position} zoom={2} scrollWheelZoom={true}
                 onLocationfound={(e) => this.handleLocationFound(e)}
                 style={{ width: '100%', height: '450px' }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
            </Map>
        )
    }


    renderWithLocation() {
        let positionClients = this.loadLocation();
        const t = translate;
        let centers = [];
        for (let position of positionClients) {
            centers.push(position.position)
        }
        let center = Leaflet.latLngBounds([centers]);
        this.log({ slots: this.props.slots, positionClients, centers, center });
        return (
            <Map bounds={center} center={centers[0]} zoom={1} scrollWheelZoom={true} maxZoom={16}
                 onLocationfound={(e) => this.handleLocationFound(e)}
                 style={{ width: '100%', height: '450px', marginBottom: 15 }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {positionClients.map(client => (
                    <>
                        <Circle center={client.position} fillColor="red" radius={200} />
                        <Marker position={client.position} icon={client.icon}>
                            <Popup>
                                <span>{client?.posAddress || ""}.</span>
                            </Popup>
                        </Marker>
                    </>
                ))
                }
            </Map>)
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default MapRoutes
