import util from "../../util/Util";

export default class CachedData {
    data = [];
    timeLastLaunched = 0;

    constructor() {
        this.timeLastLaunched = 0;
    }

    setTimeLastLaunched(currentUnixTime) {
        this.timeLastLaunched = currentUnixTime;
    }

    compareUnixTime(currentUnixTime) {
        return this.timeLastLaunched + util.getMinutsForMs(15) < currentUnixTime
    }

    async getData() {
        let currentUnixTime = util.getNumSeg1970()
        if (this.compareUnixTime(currentUnixTime) || this.data.length === 0) {
            await this.getDataImpl();
            this.setTimeLastLaunched(currentUnixTime);
        }
        // compruegba fechas y si no llaa a
        // getDataKIml()
        return this.data;
    }

    async getDataImpl() {

    }

}

