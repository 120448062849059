import React, { Component } from 'react';
import AppLogger from "../util/AppLogger";
import util from "../util/Util";
import appState from '../state/AppState';

export default class BaseWorkLogs extends Component {

    params;

    constructor(props) {
        super(props);
        this.params = {};
        if (props.navigation != null) {
            const { navigation: { state: { params } } } = props;
            if (params != null) {
                this.params = params;
            }
        }

    }


    componentDidMount() {
    }

    getDictFlavor(array) {
        let result = {};
        for (let flavorObj of array) {
            for (let flavor of Object.keys(flavorObj))
                if (result[flavor] == null) {
                    result[flavor] = flavorObj[flavor]
                }
        }
        return result;
    }

    checkOrderCocaCola() {
        let slot = this.props.slot;
        let result = false;
        let notifType = "";
        if (slot.order?.dynamicField) {
            let camposDinamicos = JSON.parse(slot.order?.dynamicField);
            notifType = camposDinamicos.sapNotifType?.toUpperCase();
        }
        if (appState.configState.checkDataSapNotifTypeExist(notifType)) {
            result = true;
        }
        //TODO
        return false;
    }

    onChangeComment(e) {
        this.setState({ text: e.target.value })
        let item = { id: "0", comment: e.target.value };
        this.props.objDict[item.id] = item;
        this.props.onChangeObjDict(this.props.objDict)
    }

    onPressCancelComment() {
        let args = {
            title: '¿Seguro que desea borrar comentario?',
            icon: "exclamation",
            ok: {
                text: 'Sí, borrar comentario',
                callback: async() => {
                    await this.setState({ text: "" });
                    this.saveResults();
                    if (util.esVacio(this.state.text)) {
                        this.setState({ alert_text: "Comentario borrado correctamente" });
                    } else {
                        this.setState({ alert_text: "Comentario guardado correctamente" });
                    }
                }
            },
        };
        this.dialogbox.tip(args);
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
