import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class CheckWorkDaysState extends BaseModelState {

    @observable checkWorkDays = [];
    @observable checkWorkDaysOrderBy = {};

}

export default CheckWorkDaysState;
