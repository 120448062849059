import React from 'react'
import { observer } from 'mobx-react'
import BaseForm from '../base/BaseForm'
import FormButtons from '../../components/FormButtons'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import ScheduleVisitModel from '../../models/ScheduleVisitModel'
import ScheduleVisitSlot from './ScheduleVisitSlot'
import MoreButtons from '../../components/MoreButtons'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import { withRouter } from 'react-router-dom'
import PropsUtil from '../../util/PropsUtil'
import GqlErrors from '../../components/status/GqlErrors'
import ScheduleVisitSlotModel from '../../models/ScheduleVisitSlotModel'
import util from '../../util/Util'
import InfoBlock from '../../components/InfoBlock'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import OriginModel from '../../models/OriginModel'
import Select2Component from '../../components/fields/Select2Component'
import OrderModel from '../../models/OrderModel'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import translate from '../../translator/translate'

@observer
class ScheduleVisitForm extends BaseForm {


    constructor(props) {
        super(props);
        this.nameMainType = "scheduleVisit";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false
        }
    }

    async deleteScheduleType(schedule) {
        let modelo = this.getModelQuery();
        modelo.hidrate(schedule);
        modelo.id = this.getFormId();
        try {
            await modelo.remove();
        } catch (e) {
            this.log(e);
        }
        return this.propsUtil.changeUrl("/schedule/visit/type/ls/")
    }

    getModelQuery() {
        return new ScheduleVisitModel();
    }

    getModelQueryForView() {
        let result = new ScheduleVisitModel();
        result.addRelatedTable("scheduleVisitSlots");
        result.addRelatedTable("originModel");
        return result;
    }

    async formDidSave(model, previousId) {
        if (!util.hasValue(previousId)) {
            this.createChildren(model, previousId);
        }
    }

    async getOriginsSuggestions(value, firstCall) {
        let originArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let originQuery = new OriginModel();
            if (firstCall) {
                originQuery.id = this.graphDataMainType.originId;
            } else {
                originQuery.filters = [
                    {"fieldName": "name", "fieldValue": value, "filterOperator": "SUBSTR"},
                ];
            }
            let origins = await originQuery.findPlainObject();
            for (let origin of  origins) {
                let originObject = {};
                originObject.label = origin.name;
                originObject.value = origin;
                originArray.push(originObject);
            }
        } catch (e) {
            this.log("Error => " + e);
        }
        return originArray;
    }


    async createChildren(model, previousId) {
        let scheduleVisitSlot = new ScheduleVisitSlotModel();
        scheduleVisitSlot.scheduledDuration = 1;
        scheduleVisitSlot.scheduleVisitId = model.id;
        scheduleVisitSlot.slotUserRole = ScheduleVisitSlotModel.MAIN;
        try {
            await scheduleVisitSlot.persist();
            this.graphDataMainType.scheduleVisitSlots = [];
            this.graphDataMainType.scheduleVisitSlots.push(scheduleVisitSlot)
        } catch (e) {
            this.log("EXCEPCION=>");
            this.log(e);
        }
    }

    renderImpl() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let scheduledVisit = this.graphDataMainType;
        if (scheduledVisit == null) {
            scheduledVisit = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let modalClasses = util.getModalClasses();
        let orderModel = new OrderModel();
        orderModel.addRelatedTable("originModel");
        orderModel.originModel = scheduledVisit.originModel;
        orderModel.originId = scheduledVisit.originId;
        let optionsType = orderModel.getValuesForSelect(TypifiedValueModel.CODE_WORK_ORDER_TYPE);
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <form
                    className="model-form"
                    name="formulario"
                    noValidate
                    onSubmit={(e) => this.handleFormSubmit(e)}>
                    <InfoBlock componentObject={this}>
                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                                     title={this.getPageTitle()}
                                                     subtitle={this.getPageSubtitle()}
                                                     mode={"edit"}
                                                     onCloseModal={() => this.onCloseModal()}>
                                    <MoreButtons
                                        readOnly={!this.readOnly}
                                        actions={
                                            [
                                                {
                                                    title: "Eliminar Tipo de Visita",
                                                    onClick: () => this.setState({
                                                        rightModal: true,
                                                        popUp: "eliminar"
                                                    })
                                                    , dontShow: !this.deletable
                                                }
                                            ]
                                        }
                                    />
                                </PageTitleRightModal>
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataScheduleVisit()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}>
                                {(this.graphDataMainType.id) &&
                                <MoreButtons
                                    onReloadData={() => this.reloadData()} readOnly={!this.readOnly}
                                    actions={
                                        [
                                            {
                                                title: "Eliminar Tipo de Visita",
                                                onClick: () => this.setState({rightModal: true, popUp: "eliminar"})
                                                , dontShow: !this.deletable
                                            }
                                        ]
                                    }
                                />
                                }
                            </PageTitle>
                        }
                        <div className={'card mb-3'}>
                            <div className={modalClasses.body}>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataScheduleVisit()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                                }
                                <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                   error={this.graphStatus.mutationError}
                                                   working={this.graphStatus.networkWorking}
                                />

                                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)}/>
                                <GroupOfField title={t("Datos")}
                                              subtitle={t("Visita Preventiva")}
                                              icon="fas fa-calendar-check">
                                    <InputTypeComponent
                                        value={scheduledVisit.name}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"name"}
                                        title={t("Nombre para el nuevo Tipo de Visita Preventiva")}
                                        editable={this.editable}
                                        classGroup={"col-4"}
                                        type={"text"}
                                        errors={errorsMapped.name}
                                        labelClassName={"control-label--grey"}
                                    />
                                    <SelectSuggestComponent
                                        value={scheduledVisit.originId}
                                        onChange={(event) => {
                                            let originSelected = event.target.value;
                                            if (util.hasValue(originSelected.id)) {
                                                this.handleAutosuggestSelection("originId", originSelected.id);
                                                scheduledVisit.originModel = originSelected;
                                            } else {
                                                scheduledVisit.originId = "";
                                                scheduledVisit.originModel = {};
                                            }
                                        }}
                                        name={"originId"}
                                        title={t("Origen")} defaultTimeOut={2000}
                                        classGroup={"col-md-4 col-lg-4"}
                                        editable={this.editable}
                                        errors={errorsMapped.originId}
                                        loadSuggestions={(value, firstCall) => this.getOriginsSuggestions(value, firstCall)}
                                        placeholder={t("Escribe...")}

                                    />
                                    <Select2Component
                                        value={scheduledVisit.type}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        editable={this.editable}
                                        name={"type"}
                                        isClearable={false} required
                                        title={t("Tipo Orden de Trabajo")}
                                        classGroup={"col-md-3 col-lg-3"}
                                        options={optionsType}
                                        errors={errorsMapped.type}
                                    />
                                    {this.graphDataMainType.scheduleVisitSlots ?.length > 0 &&
                                        <ScheduleVisitSlot
                                        id={this.graphDataMainType.id}
                                        scheduledVisit={this.graphDataMainType}
                                        {...this.props} />
                                    }
                                </GroupOfField>
                                <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus}/>
                            </div>
                        </div>
                        <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                                    onCloseModal={() => this.setState({
                                        rightModal: false,
                                        popUp: ""
                                    })}>
                            {this.state.popUp == "eliminar" &&
                            <PopUp
                                title={t('Eliminar')}
                                subtitle={t('Visita preventiva ' + scheduledVisit.name)}
                                icon="exclamationTriangleIcon"
                                text={t('¿Estas seguro que quieres eliminar este tipo de visita? Los cambios no se aplicarán ' +
                                    'a las visitas que ya hayan sido creadas en la planificación de los establecimientos.')}
                                label1={t('No eliminar')}
                                label3={t('Eliminar')}
                                onClick1={() => this.setState({rightModal: false, popUp: ""})}
                                onClick3={(scheduledVisit) => this.deleteScheduleType(scheduledVisit)}>
                            </PopUp>
                            }
                        </AlertModal>
                    </InfoBlock>
                </form>
            </div>


        )
    }
}

export default withRouter(ScheduleVisitForm)
