import React from 'react'
import { observer } from 'mobx-react'
import BaseEditableRow from '../base/BaseEditableRow'
import { computed, toJS } from 'mobx'
import appState from '../../state/AppState'
import WorkOrderCartArticleModel from '../../models/WorkOrderCartArticleModel'
import WorkOrderModel from '../../models/WorkOrderModel'
import ArticleModel from '../../models/ArticleModel'
import InfoBlock from '../../components/InfoBlock'
import WorkOrdersSelectInModal from '../../components/modals/WorkOrdersSelectInModal'
import translate from '../../translator/translate'
import AutoSuggestComponent from '../../components/fields/AutoSuggestComponent'
import util from '../../util/Util'
import GqlErrors from '../../components/status/GqlErrors'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import PropsUtil from '../../util/PropsUtil'
import SlotsSelectInModal from '../../components/modals/SlotsSelectInModal'
import SlotModel from '../../models/SlotModel'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'

@observer
class OrderFormArticle extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });

    constructor(props) {
        super(props);
        this.foreingKeyfield = this.props.foreingKeyfield;
        this.state = {
            change: 0
        }
    }


    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        await appState.articleState.getArticles();
    }

    getArticlefromArticleId(articleId) {
        let result = {};
        if (util.hasValue(articleId)) {
            for (let article of appState.articleState.articles) {
                if (util.hasValue(articleId)) {
                    if (article.id === articleId) {
                        result = article;
                        break;
                    }
                }
            }
        }
        return result;
    }


    async getArticlesConection(value) {
        let articlesArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            // Obtenemos los Assets
            let articlesQuery = new ArticleModel();
            articlesQuery.filters = [
                { "fieldName": "code", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let articles = await articlesQuery.find();
            // Transformamos a formato Autosuggest
            articles.map((article) => {
                let articleObject = {};
                articleObject.label = article.code;
                articleObject.value = article;
                articlesArray.push(articleObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return articlesArray;
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    showSelectsWorksOrder() {
        appState.layoutState.favouritesDropMenuOpen = true;
    }

    updateInputEventWorkOrder(event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        this.updateInput("workOrderId", event.target.value)
    }

    updateInput(key, value) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row);
        }
        let workOrderArticle = this.props.row;
        workOrderArticle[key] = value;
    }


    getOptionsForStatusSelect() {
        let result = [];
        result.push({ value: WorkOrderCartArticleModel.CODE_RESERVADA, label: "Reservada" });
        result.push({ value: WorkOrderCartArticleModel.CODE_UTILIZADA, label: "Utilizada" });
        return result;
    }

    getLabelOfStatusSelect(value) {
        let result = "";
        if (value === WorkOrderCartArticleModel.CODE_DISPONIBLE) {
            result = "Disponible";
        } else if (value === WorkOrderCartArticleModel.CODE_SIN_STOCK) {
            result = "Sin stock";
        } else if (value === WorkOrderCartArticleModel.CODE_RESERVADA) {
            result = "Reservada";
        } else if (value === WorkOrderCartArticleModel.CODE_UTILIZADA) {
            result = "Utilizada";
        } else if (value === WorkOrderCartArticleModel.CODE_PPRESUPUESTADA) {
            result = "Presupuestada";
        }
        return result;
    }


    async rowDidSave(model, previousId) {
        await this.props.reload();

    }

    render() {
        const t = translate;
        let workOrderArticle = this.props.row;
        this.propsUtil = new PropsUtil(this.props);
        let workOrderCode = this.props.workOrders.map((workor) => {
            let workOrderModel = new WorkOrderModel();
            let workOrderFounded = new WorkOrderModel();
            workOrderModel.hidrate(workor);
            if (workOrderModel.id == workOrderArticle.workOrderId) {
                workOrderFounded = workOrderModel;
            }
            return workOrderFounded.code;
        });
        let slotCode = this.props.slots?.map((workor) => {
            let slotModel = new SlotModel();
            let slotFounded = new SlotModel();
            slotModel.hidrate(workor);
            if (slotModel.id == workOrderArticle.slotId) {
                slotFounded = slotModel;
            }
            return slotFounded.code;
        });
        if (workOrderArticle.workOrderId == null && this.props.workOrderId) {
            workOrderArticle.workOrderId = this.props.workOrderId;
        }
        if (workOrderArticle.orderId == null && this.props.orderId) {
            workOrderArticle.orderId = this.props.orderId;
        }
        if (workOrderArticle.slotId == null && this.props.slotId) {
            workOrderArticle.slotId = this.props.slotId;
        }
        if (workOrderArticle.status == null) {
            workOrderArticle.status = WorkOrderCartArticleModel.CODE_UTILIZADA;
        }
        let errorsMapped = this.getGraphErrorsFromStatus();

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                <tr>
                    <td scope="row">
                        {workOrderArticle.isEditing ?
                            <AutoSuggestComponent
                                value={this.getArticlefromArticleId(workOrderArticle.articleId).code || workOrderArticle.articleCode}
                                onChange={(articleCodeSelected) => {
                                    if (articleCodeSelected.id !== '') {
                                        this.handleAutosuggestSelection("articleCode", articleCodeSelected.code);
                                        this.handleAutosuggestSelection("description", articleCodeSelected.description);
                                        this.handleAutosuggestSelection("itemType", articleCodeSelected.itemType);
                                        this.handleAutosuggestSelection("saleUnit", articleCodeSelected.saleUnit);
                                        this.handleAutosuggestSelection("articleId", articleCodeSelected.id);
                                        this.handleAutosuggestSelection("articleName", articleCodeSelected.name);
                                        this.handleAutosuggestSelection("used", true);
                                    } else {
                                        this.handleAutosuggestSelection("description", '');
                                        this.handleAutosuggestSelection("articleId", '');
                                        this.handleAutosuggestSelection("articleName", '');
                                        this.handleAutosuggestSelection("itemType", '');
                                        this.handleAutosuggestSelection("saleUnit", '');
                                        this.handleAutosuggestSelection("used", false);
                                    }
                                }}
                                name={"articleCode"}
                                errors={errorsMapped.articleCode}
                                loadSuggestions={(value) => this.getArticlesConection(value)}
                                placeholder={t("Escribe...")}
                            />
                            :
                            <span className={!appState.userState.isUserRoleClient() ? 'text-click' : ''}
                                  onClick={() => {
                                      if (!appState.userState.isUserRoleClient()) {
                                          this.openModalArticle(workOrderArticle.articleId)
                                      }
                                  }}>   {workOrderArticle.articleCode}
                                            </span>

                        }

                    </td>
                    <td scope="row">
                        {workOrderArticle.description}
                    </td>
                    <td>
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.quantity}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"quantity"}
                                type={"text"}
                                relatedInfo={t(workOrderArticle.saleUnit)}
                                validate={"decimal"}
                                className="form-control"
                                errors={errorsMapped.quantity}
                            />
                            :
                            <>
                                {workOrderArticle.quantity + (' ') + (t(workOrderArticle.saleUnit) || "")}
                            </>
                        }
                    </td>
                    <td>
                        {workOrderArticle.isEditing ?
                            <InputTypeComponent
                                value={workOrderArticle.stockLote}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"stockLote"}
                                type={"text"}
                                className="form-control"
                                errors={errorsMapped.stockLote}
                            />
                            :
                            <>
                                {workOrderArticle.stockLote}
                            </>
                        }
                    </td>


                    <td>
                        {workOrderArticle.isEditing ?
                            <>
                                <SlotsSelectInModal
                                    values={workOrderArticle.slotId}
                                    name={"slotId"}
                                    title={slotCode}
                                    onChange={(e) => this.handleInputChange(e)}
                                    {...this.props}
                                />
                            </>
                            :
                            <div>{slotCode}</div>
                        }
                    </td>
                    {!this.props.fromWorkOrder &&
                    <td>
                        {workOrderArticle.isEditing ?
                            <>
                                <WorkOrdersSelectInModal
                                    fromArticles={false}
                                    values={workOrderArticle.workOrderId}
                                    onChangeAssetId={(assetId) => this.updateInput("assetId", assetId)}
                                    name={"workOrderId"}
                                    onChange={(e) => this.updateInputEventWorkOrder(e)}
                                    {...this.props}
                                />
                                {errorsMapped.workOrderId && errorsMapped.workOrderId.map(error =>
                                    <small className="js-error help-block text-danger">{error.message}</small>)}
                            </>
                            :
                            <div>{workOrderCode}</div>
                        }
                    </td>
                    }

                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>

                {this.state.popUp === "eliminar" &&
                <AlertModal isOpen={this.state.popUp === "eliminar"}
                            onCloseModal={() => this.setState({ popUp: "" })}>
                    {this.state.popUp === "eliminar" &&
                    <PopUp
                        title={t('Eliminar')}
                        icon="exclamationTriangleIcon"
                        text={t('¿Estas Seguro de Eliminar esta pieza?')}
                        label1={t('No eliminar')}
                        label3={t('Eliminar')}
                        onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                        onClick3={() => {
                            super.onDeleteRow();
                            this.setState({
                                rightModal: false,
                                popUp: ""
                            });
                        }}
                    >
                    </PopUp>
                    }
                </AlertModal>
                }
            </InfoBlock>
        );
    }

    onDeleteRow() {
        this.setState({
            rightModal: true,
            popUp: "eliminar"
        });
    }

    openModalArticle(articleId) {
        this.propsUtil.changeUrlRequest({
            "articleId": articleId,
            "rightModal": "modalarticles",
            "rightModalTab": "articles",
            "fromRightModal": true
        });
    }
}

export default OrderFormArticle
