import React, {Component} from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import apolloClient from "../storage/ApolloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import {gql} from "apollo-boost/lib/index";
import {observer} from "mobx-react";
import appState from "../state/AppState";
import util from "../util/Util";
import {now} from "moment";
import DocumentModel from "../models/DocumentModel";
import RadioButtonComponent from "./fields/RadioButtonComponent";
import translate from "../translator/translate";
import AppLogger from "../util/AppLogger";
import SortableList from "./SortableList";
import arrayMove from "array-move";
import ChangesWithValidationsModel from "../models/ChangesWithValidationsModel";
import VsfButtonNew from "./VsfButtonNew";

let baseurl = "http://localhost:5000/api/document";

let documentAddQuery = async(fileKey) => {
    let query = gql`
        query DocumentAdd($fileKey:String){
            documentAdd(fileKey:$fileKey) {
                presignedURL,
                url,
                urlSource
            }
        }
    `;
    let variables = {
        fileKey: fileKey
    };

    let apolloProxy = new ApolloProxy(apolloClient);
    return await apolloProxy.graphQuery({ query, variables });
};

const images = require.context('../../public/img/fileicons', true);

const imagePath = (name) => images(name, true);


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

@observer
class UploadImageComponentSortable extends Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();

        this.state = {
            files: [],
            select: "",
        };
    };

    handleInit() {
        this.pond.current._pond.on("addfile", (error, file) => this.onAddedFile(error, file));
    }

    onAddedFile(error, file) {
        if (error) {
            return;
        }
        // this.log({ addfile: 1, file })
        // Set file metadata here in order to retrieve it in the custom process function
        // file attributes like fileExtension and filenameWithoutExtension
        // are not availabe in the file object in the custom process function
        file.setMetadata('fileInfo', {
            filenameWithoutExtension: file.filenameWithoutExtension,
            fileExtension: file.fileExtension
        });
    }

    getDocuments() {
        let result = [];
        if (this.props.documents != null) {
            if (this.props.documents?.length > 0) {
                for (let obj of this.props.documents) {
                    let document = new DocumentModel();
                    document.hidrate(obj);
                    if (document.isFieldImage()) {
                        result.push(obj);
                    }
                }
            }
        }
        result = result.sort((a, b) => {
            let result = a.orderView - b.orderView;
            if (result == 0) {
                result = a.createdAt - b.createdAt;
            }
            return result;
        });
        return result;
    }

    addDocument({ name, url, urlSource, size, field }) {
        window.setTimeout(() => {
            let documentToAdd = { name, url, urlSource, size, field };
            if (this.props.documents && this.props.onChange == null) {
                this.props.documents.push(documentToAdd);
            }
            this.onChange(documentToAdd);
        }, 2000);
    }

    onChangeMainPhoto(documentChange) {
        if (this.props.documents?.length > 0) {
            for (let obj of this.props.documents) {
                let document = new DocumentModel();
                document.hidrate(obj);
                if (document.isFieldImage() && obj.field !== "mainPhotoValidate") {
                    //Fix si es recien subida que no tiene id
                    if ((util.hasValue(documentChange.id) && obj.id === documentChange.id)
                        || (util.esVacio(documentChange.id) && obj.name === documentChange.name)) {
                        obj.field = "mainPhoto";
                    } else {
                        obj.field = "photo";
                    }
                }
            }
            this.onChange(documentChange)
        }
        // this.log({ documents: this.props.documents })
    }

    onChange(documentToAdd) {
        if (this.props.onChange != null) {
            this.props.onChange(documentToAdd);
        }
        if (this.props.onChangePrevious != null) {
            this.props.onChangePrevious();
        }
        appState.layoutState.formWithoutChanges = false;
    }

    checkReadOnly() {
        let readOnly = false;
        if (this.props.model && !this.props.model?.editable) {
            readOnly = true;
        }
        return readOnly;
    }

    deleteDocument(document) {
        this.log({ delete: (document) })
        appState.layoutState.formWithoutChanges = false;
        let documents = this.props.documents;

        //Buscamos el indice a borrar
        let index = -1;
        for (let i = 0; i < documents.length; i++) {
            if ((util.hasValue(document.id) && documents[i].id === document.id)
                || (util.esVacio(document.id) && documents[i].name === document.name)) {
                index = i;
            }
        }

        //Borramos la imagen
        if (index !== -1) {
            documents.splice(index, 1);
        }

        this.props.setDocuments(documents);
        this.onChange();
    };

    async rejectedPhoto(documentChange, index) {
        try {
            for (let validate of appState.changesWithValidationsState.validatesForm) {
                let changeJson = JSON.parse(validate.changes)
                if (Object.values(changeJson)[0] === documentChange.id) {
                    validate.approved = ChangesWithValidationsModel.REJECTED;
                    appState.changesWithValidationsState.validateChanges[documentChange.id] = validate;
                }
            }
        } catch (e) {
            this.log({ validateMainPhoto: e, })
        }
        this.deleteDocument(documentChange)
    }

    async validateMainPhoto(documentChange) {
        try {
            for (let validate of appState.changesWithValidationsState.validatesForm) {
                let changeJson = JSON.parse(validate.changes)
                if (Object.values(changeJson)[0] === documentChange.id) {
                    validate.approved = ChangesWithValidationsModel.APPROVED;
                    appState.changesWithValidationsState.validateChanges[documentChange.id] = validate;
                }
            }
        } catch (e) {
            this.log({ validateMainPhoto: e, })

        }
        this.log({ validatePhoto: appState.changesWithValidationsState.validateChanges })
        documentChange.orderView = -1;
        documentChange.field = "mainPhoto";
        this.onChangeMainPhoto(documentChange)
    }

    async validatePhoto(documentChange) {
        try {
            for (let validate of appState.changesWithValidationsState.validatesForm) {
                let changeJson = JSON.parse(validate.changes)
                if (Object.values(changeJson)[0] === documentChange.id) {
                    validate.approved = ChangesWithValidationsModel.APPROVED;
                    appState.changesWithValidationsState.validateChanges[documentChange.id] = validate;
                }
            }
        } catch (e) {
            this.log({ validateMainPhoto: e, })
        }
        // this.log({ validatePhoto: appState.changesWithValidationsState.validateChanges })
        documentChange.orderView = this.props.documents?.length;
        documentChange.field = "photo";
        this.onChange(documentChange)
    }

    getImageThumbnail(document) {
        let result = "";
        if (util.hasValue(document.url)) {
            let extensionesValidas = [];
            extensionesValidas.push(".png");
            extensionesValidas.push(".gif");
            extensionesValidas.push(".pdf");
            extensionesValidas.push(".mp4");
            extensionesValidas.push(".jpeg");
            extensionesValidas.push(".jpg");
            let hasThumbnail = false;
            for (let extension of extensionesValidas) {
                if (document.url.toLowerCase().includes(extension.toLowerCase())) {
                    hasThumbnail = true;
                }
                if ((util.getDocumentPhoto(document).isPDF() || util.getDocumentPhoto(document).isVideo()) && !util.hasValue(util.getDocumentPhoto(document).thumbnailx4)) {
                    hasThumbnail = false;
                }
            }
            if (hasThumbnail) {
                result = util.getDocumentPhoto(document).getThumbNailx4();
            } else {
                let iPath;
                try {
                    iPath = imagePath('./' + document.url.split('.').pop() + '.png');
                } catch (err) {
                    /*No encuentra un icono específico con el formato del archivo subido*/
                    iPath = imagePath('./undefined.png');
                }
                result = iPath;
            }
        }
        return result;
    };

    async onSortEnd(event) {
        appState.layoutState.formWithoutChanges = false;
        let { oldIndex, newIndex } = event;

        //Obtenemos los documentos que son solo imagenes
        let images = this.getDocuments();
        images = images.filter(a => a.field !== "mainPhotoValidate");

        //Reordenamos el array
        images = arrayMove(images, oldIndex, newIndex);

        //Establecemos el nuevo orden
        let newDocuments = [];
        for (let i = 0; i < images.length; i++) {
            let document = new DocumentModel();
            document.hidrate(images[i]);
            document.orderView = i;
            newDocuments.push(document);
        }

        //Añadimos el resto de documentos
        for (let obj of this.props.documents) {
            let document = new DocumentModel();
            document.hidrate(obj);
            if (!document.isFieldImage() || document.field === "mainPhotoValidate") {
                newDocuments.push(obj);
            }
        }

        //this.log({ oldIndex, newIndex, images, newDocuments });

        this.props.setDocuments(newDocuments);
        this.onChange();
    };

    renderSortableItem(document, index) {
        const t = translate;
        let readOnly = this.checkReadOnly();

        return (
            <div key={index} className="image-sortable-item">
                <div>
                    <RadioButtonComponent
                        value={document.field}
                        styleLabel={'m-5'}
                        editable={true}
                        name={"isPos"}
                        options={[{ label: t("Es la foto principal"), value: "mainPhoto", id: "img-" + document.id }]}
                        checked={document.field === 'mainPhoto'}
                        classGroup={""}
                        onChange={(e) => this.onChangeMainPhoto(document)}
                    />
                </div>
                <a className="image" target="_blank" href={document.url}>
                    <img alt={document.name} src={this.getImageThumbnail(document)} />
                </a>
                <div className="content">
                    <div className="title">
                        {util.hasValue(document.title) ? document.title : '--'}
                    </div>
                    <div className="data">
                        <div>
                            {document.name}
                        </div>
                        <div>
                            {document.createdAt ?
                                <>{util.getMoment(document.createdAt).format("DD/MM/YYYY HH:mm")}</>
                                :
                                <>{util.getMoment(now()).format("DD/MM/YYYY HH:mm")}</>
                            }
                        </div>
                        <div>
                            {util.formatBytes(document.size)}
                        </div>
                    </div>
                </div>
                {!readOnly &&
                <VsfButtonNew className="btn btn-danger btn-padding-small"
                              onClick={() => this.deleteDocument(document)}>
                    <i className="fa fa-trash-alt"></i>{' '}{t('Eliminar imagen')}
                </VsfButtonNew>
                }
            </div>
        )
    }

    renderDocumentValidate(document, index) {
        const t = translate;
        let readOnly = this.checkReadOnly();

        return (
            <div key={index} className="uploaded-image validate">
                <div className="dragHandle-fake">
                    {(index + 1)}.
                </div>
                <div key={index} className="image-sortable-item">
                    <a className="image" target="_blank" href={document.url}>
                        <img alt={document.name} src={this.getImageThumbnail(document)} />
                    </a>
                    <div className="content">
                        <div className="title">
                            {util.hasValue(document.title) ? document.title : '--'}
                        </div>
                        <div className="data">
                            <div>
                                {document.name}
                            </div>
                            <div>
                                {document.createdAt ?
                                    <>{util.getMoment(document.createdAt).format("DD/MM/YYYY HH:mm")}</>
                                    :
                                    <>{util.getMoment(now()).format("DD/MM/YYYY HH:mm")}</>
                                }
                            </div>
                            <div>
                                {util.formatBytes(document.size)}
                            </div>
                        </div>
                    </div>
                    {!readOnly &&
                    <div className="d-flex flex-column">
                        <VsfButtonNew className="btn btn-danger btn-padding-small"
                                      onClick={() => this.rejectedPhoto(document)}>
                            <i className="fa fa-times" />{' '}{t('Eliminar imagen')}
                        </VsfButtonNew>
                        <VsfButtonNew className="btn btn-secondary btn-padding-small"
                                      onClick={() => this.validateMainPhoto(document)}>
                            <i className="fa fa-check" />{' '}{t('Validar como foto principal')}
                        </VsfButtonNew>
                        <VsfButtonNew className="btn btn-secondary btn-padding-small"
                                      onClick={() => this.validatePhoto(document)}>
                            <i className="fa fa-check" />{' '}{t('Validar como foto ')}
                        </VsfButtonNew>
                    </div>
                    }
                </div>
            </div>
        )
    }

    render() {
        const t = translate;
        let props = this.props;

        let documents = this.getDocuments();
        let documentsWithFileNames = documents.filter(a => a.url != null);
        let documentsWithoutValidates = documentsWithFileNames.filter(a => a.field !== "mainPhotoValidate");
        let documentsWithValidates = documentsWithFileNames.filter(a => a.field === "mainPhotoValidate");
        let classGroup = "";
        if (props.classGroup) {
            classGroup = props.classGroup;
        }
        classGroup += " col-12";

        let label = t('Arrastre las imagenes aquí') + '</br>o</br><span class="filepond--label-action">' + t('Añadir imagenes') + '</span>';
        let readOnly = this.checkReadOnly();
        return (
            <div className={classGroup}>
                <div className="row">
                    <div className={props.configForm ? "col-6" : "col-12"}>
                        {!readOnly &&
            <>
                <FilePond
                    ref={this.pond} onprocessfiles={() => this.onprocessfiles()}
                    acceptedFileTypes={['image/*']}
                    server={this.getServer()} labelTapToUndo={""}
                    labelFileProcessingComplete={t("Archivo subido")}
                    labelFileProcessing={t("Subiendo archivo")} labelTapToCancel={""}
                    oninit={() => this.handleInit()}
                    labelIdle={label}
                    allowMultiple={true}
                    credits={false}
                />

                <label htmlFor={props.name}
                       className="control-label">
                    {props.title}
                </label>
            </>
                        }

                    </div>
                    <div
                        className={props.configForm ? "col-6 uploaded-elements-container" : "col-12 uploaded-elements-container"}>
                        {documentsWithValidates?.map((document, index) => (
                            this.renderDocumentValidate(document, index)
                        ))}
                        <SortableList
                            items={documentsWithoutValidates}
                            onSortEnd={e => this.onSortEnd(e)}
                            useDragHandle={true}
                            showDragHandle={true}
                            classGroup="uploaded-image"
                            pressDelay={50}
                            renderItem={(obj, index) => this.renderSortableItem(obj, index)}
                        />
                    </div>
                </div>
            </div>
        )

    }

    isImage(documentJS) {
        let document = new DocumentModel();
        document.hidrate(documentJS);
        return document.isImage();
    }


    onChangeFileType(obj, document) {
        document.field = obj.value;
        this.onChange(document);
        this.setState({ select: "" })
    }

    onprocessfiles() {
        setTimeout(() => {
            this.pond.current._pond.removeFiles();
        }, 1500);
    }

    getServer() {
        let me = this;
        let baseFolder = this.props.baseFolder;
        // let log = (msg) => this.log(msg);
        let log = () => {
        };
        let server = {
            url: baseurl,
            process: function (fieldName, file, metadata, load, error, progress, abort) {
                var filepondRequest = new XMLHttpRequest({
                    contentType: 'binary/octet-stream',
                    processData: false,
                });
                log({ fieldName, file, metadata, load, error, progress, abort });
                let fileKey = baseFolder + "/" + file.name;
                let signedResult = documentAddQuery(fileKey);
                signedResult.then((response) => {
                    log({ signedResult: 1, response });
                    try {
                        let presignedUrl = response.data.documentAdd.presignedURL;
                        let urlSource = response.data.documentAdd.urlSource;
                        let url = response.data.documentAdd.url;
                        var filepondFormData = new FormData();
                        filepondFormData.append("file", file);
                        filepondRequest.upload.onprogress = function (e) {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
                        filepondRequest.open("PUT", presignedUrl);
                        filepondRequest.onload = function () {
                            load(`${"file.name"}`);
                            log({ Loaded: 1, file });
                            me.addDocument({
                                name: file.name,
                                size: file.size,
                                url, urlSource,
                                field: "photo",
                                orderView: me.getDocuments().length + 1
                            });
                        };
                        let binaryFile = filepondFormData.get("file");
                        filepondRequest.send(binaryFile);
                    } catch (e) {
                        log("Error uploading file=>");
                        log({ e });
                    }
                })

            }
        };
        return server;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

/*
Property Default Description
allowFileSizeValidation true Enable or disable file size validation
minFileSize null The minimum size of a file, for instance 5MB or 750KB
maxFileSize null The maximum size of a file, for instance 5MB or 750KB
maxTotalFileSize null Maximum size of all files in list, same format as maxFileSize
labelMaxFileSizeExceeded 'File is too large' Status message shown when large file is dropped.
labelMaxFileSize 'Maximum file size is {filesize}' Detail message shown when max file size was
exceeded. {filesize} is replaced with the value of the maxFileSize property.
labelMaxTotalFileSizeExceeded 'Maximum total size exceeded' Status message shown when total file
size exceeds maximum.
labelMaxTotalFileSize 'Maximum total file size is {filesize}' Detail message shown then total
file size exceeds maximum.
*/

export default UploadImageComponentSortable
