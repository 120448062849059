import React from 'react';
import {observer} from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed} from 'mobx';
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import GroupOfField from "../../components/layout/GroupOfField";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import PropsUtil from "../../util/PropsUtil";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import SlotModel from "../../models/SlotModel";
import translate from "../../translator/translate";
import {withRouter} from "react-router-dom";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import MoreButtons from "../../components/MoreButtons";
import VsfButtonNew from "../../components/VsfButtonNew";
import OrderFormArticle from "../orders/OrderFormArticle";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import SlotActions from "./SlotActions";

@observer
class SlotFormArticles extends BaseEditableTable {


    constructor(props) {
        super(props);
        this.nameMainType = "slot";
        this.tabsData = new TabsData();
        this.foreingKeyfield = "slotId";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable();
        this.setState({ loading: false })
    }

    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    getModelQuery() {
        return new SlotModel();
    }

    getModelQueryForView() {
        let result = new SlotModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.asset");
        result.addRelatedTable("client");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }


    async reloadTable() {
        appState.workOrderState.workOrders = this.graphDataMainType.workOrder;
        appState.workOrderCartArticlesState.workOrderArticles = this.graphDataMainType.workOrderCartArticle;
        appState.assetState.orderArticles = this.calculaCantidadStr();
    }

    workOrderArticlesUsed() {
        let result = [];
        if (this.graphDataMainType?.workOrderCartArticle?.length != 0) {
            for (let article of this.graphDataMainType.workOrderCartArticle) {
                result.push(article);
            }
        }
        return result;
    }

    calculaCantidadStr() {
        let suma = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles || [];
        articulos.map((articulo, index) => {
            if (articulo.quantity != null) {
                if (suma == 0)
                    suma = (util.formatDecimal(articulo.quantity));
                else if (articulo.quantity != '') {
                    suma += (util.formatDecimal(articulo.quantity));
                }
            }
        });
        return parseFloat(suma).toFixed(2);
    }

    renderImpl() {
        const t = translate;

        let slot = this.graphDataMainType;
        let cantidadStr = this.calculaCantidadStr();
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataSlot()}
                                active="Piezas"
                                numAlarm={3}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            >
                                <SlotActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}
                                             model={this.graphDataMainType}
                                             getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card mb-3">

                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataSlot()}
                                active={"Piezas"}
                            />
                            }
                            <GroupOfField
                                title={t('Pedido')}
                                subtitle={t('Piezas')}
                                icon='fas fa-calendar'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div
                                    style={{
                                        margin: 10,
                                        fontWeight: 'bold'
                                    }}>{t("Info para añadir piezas desde BO")}</div>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna pieza creada')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&

                                        <tr>
                                            <th scope="col">{t("Codigo")}</th>
                                            <th scope="duration">{t("Nombre")}</th>
                                            <th scope="duration">{t("Cantidad")}</th>
                                            <th scope="duration">{t("Lote")}</th>
                                            {/*<th scope="wo-width-date">{t("Estado")}</th>*/}
                                            <th scope="wo-matched">{t('Orden de Trabajo')}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">
                                        {this.mobxListado.map((article, index) => (
                                            <OrderFormArticle
                                                key={article && article.id}
                                                orderId={slot.orderId}
                                                reload={(e) => this.reloadData(e)}
                                                rowIndex={index} fromSlots foreingKeyfield={this.foreingKeyfield}
                                                row={article} slotId={slot.id}
                                                workOrders={appState.workOrderState.workOrders}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            />
                                        ))}

                                        </tbody>
                                        <tfoot>
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <td colSpan="2">{t('TOTAL')}</td>
                                            <td colSpan="7">
                                                {isNaN(cantidadStr) ?
                                                    0
                                                    :
                                                    cantidadStr
                                                }
                                            </td>
                                        </tr>
                                        }
                                        </tfoot>
                                    </table>

                                    <div className="col-12 text-right mb-3">
                                        <VsfButtonNew
                                            disabled={this.state.hasSomeRowEditing}
                                            showButton={this.creatable["article"] || false}
                                            label={t("Añadir Piezas")}
                                            isEditing={this.state.hasSomeRowEditing}
                                            className="btn btn--loader"
                                            onClick={_ => this.onOpenRowNewEmpty()}
                                        />
                                    </div>
                                    <AlertModal isOpen={this.state.orderCanceled}
                                                onCloseModal={() => this.setState({ "orderCanceled": false })}>
                                        <PopUp
                                            title={t('Operación no permitida')}
                                            icon={"exclamationTriangleIcon"}
                                            text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                                            label1={'Cerrar'}
                                            onClick1={() => this.setState({ orderCanceled: false })}
                                        />
                                    </AlertModal>
                                    <AlertModal isOpen={this.state.orderFinished}
                                                onCloseModal={() => this.setState({ "orderFinished": false })}>
                                        <PopUp
                                            title={t('Operación no permitida')}
                                            icon={"exclamationTriangleIcon"}
                                            text={t(`Este Pedido está finalizado, para poder añadir una pieza
                                                 tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                                            label1={'Cerrar'}
                                            onClick1={() => this.setState({ orderFinished: false })}
                                        />
                                    </AlertModal>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }

}

export default withRouter(SlotFormArticles)
