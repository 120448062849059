import { observable } from 'mobx'
import appState from '../state/AppState'
import Model from '../models/Model'
import AppLogger from '../util/AppLogger'
import LocalStorage from '../network/LocalStorage'
import config from '../config/config'

const localStorage = new LocalStorage();
let planogram = true;
let SOFinishedMoveOT = true;
let _new_models = true;
let ordenarTecnicos = true;
let xml_integration = true;
if (config.isLive) {
    _new_models = false;
    planogram = false;
    xml_integration = false;
    SOFinishedMoveOT = true;
}

export default class OFFER {
    static calendar_subscriptions = true;
    @observable static switch_cocacola = false;
    @observable static SOFinishedMoveOT = SOFinishedMoveOT;
    @observable static planogram = planogram;
    @observable static xml_integration = xml_integration;
    @observable static asset_list_cocacola = false;
    @observable static vehicle_gps_list = false;
    @observable static mail_sales = true;
    @observable static conectividad = true;
    @observable static destinatario_parte = false;
    @observable static code_partes_trabajo = false;
    @observable static sales_order = true;
    @observable static creatable = JSON.parse(localStorage.getItem("lastPermission"))?.creatable || {};
    @observable static readable = JSON.parse(localStorage.getItem("lastPermission"))?.readable || {};
    @observable static new_models = _new_models;
    @observable static netsuiteIntegration = true;
    @observable static bin = false;
    @observable static showSidebarContract = config.isLive?0:1;
    @observable static showInventoryScanBin = (config.isLive||config.isTest2())?0:1;

    static ordenarTecnicos = ordenarTecnicos;
    async checkOffers() {
        this.userLogged();
    }

    /**
     * Si el usuario tiene el tenant de coca cola, ofrecemos el switch, el listado de equipos
     */
    userLogged() {
        for (let tenant of appState.tenantState.tenants) {
            //Se ha cambiado varias veces el code España_CC, en cada entorno esta un code, al final comparo por el title porque es lo unico que coincide
            if (tenant.title === "Coca Cola" && tenant.tenantId === appState.loginState.user?.tenantId) {
                // OFFER.asset_list_cocacola = true;
                // OFFER.switch_cocacola = true;
            }
        }
    }

    loadCreatables() {
        let model = new Model();
        let result =  model.findCreatablesReadables();
        if (Object.keys(result.creatable).length > 0 || Object.keys(result.readable).length > 0) {
            OFFER.creatable = result.creatable;
            OFFER.readable = result.readable;
        }
        this.log({loadCreatables: 1,result})
        localStorage.setItem('lastPermission', JSON.stringify(result));
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
