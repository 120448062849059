import React, { Component } from 'react'
import { observer } from 'mobx-react'
import VsfLink from '../components/VsfLink'

@observer
class SidebarSubItem extends Component {
  ifPathMatch (pathMatch) {
    let result = false
    if (this.props.location.pathname === pathMatch) {
      result = true
    }
    return result
  }

  render () {
    let props = this.props
    let title = props.title
    return (
      <div className="nav-item--dropdown__item">
        <VsfLink className={this.props.active ? ' active' : ''} to={this.props.href}
                 disabled={this.ifPathMatch(this.props.href)} dataCy={props.dataCy} {...props}>
          {title}
        </VsfLink>
      </div>
    )
  }
}

export default SidebarSubItem

