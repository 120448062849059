import React from 'react'
import { observer } from 'mobx-react'
import PageTitle from '../../components/PageTitle'
import SearchComponent from '../../components/list/SearchComponent'
import ListComponent from '../../components/list/ListComponent'
import PaginationComponent from '../../components/list/PaginationComponent'
import appState from '../../state/AppState'
import BaseList from '../base/BaseList'
import ListItemSelect from '../../components/listaddons/ListItemsSelect'
import TextListItem from '../../components/list/TextListItem'
import MoreButtons from '../../components/MoreButtons'
import PropsUtil from '../../util/PropsUtil'
import translate from '../../translator/translate'
import t from '../../translator/translate'
import AppLogger from '../../util/AppLogger'
import GqlErrors from '../../components/status/GqlErrors'
import ListPageCounts from '../../components/list/ListPageCounts'
import InfoBlock from '../../components/InfoBlock'
import WorkOrderCartArticleModel from '../../models/WorkOrderCartArticleModel'
import IsoDateListItem from '../../components/list/IsoDateListItem'
import RelatedTableConfig from '../../models/RelatedTableConfig'
import util from '../../util/Util'
import CreatedByListItem from '../../components/list/CreatedByListItem'
import WorkOrderArticleListFilters from './WorkOrderArticleListFilters'
import ModalListFilter from '../../subpages/ModalListFilter'

@observer
class WorkOrderArticleList extends BaseList {

  constructor (props) {
    super(props)
    this.quickView = false
    this.quickEdit = false
    if (util.hasValue(this.props.articleId)) {
      this.filterQueryList.push({
        fieldName: 'articleId',
        fieldValue: this.props.articleId
      })
    }
    if (util.hasValue(this.props.assetId)) {
      this.filterQueryList.push({
        fieldName: 'assetId',
        fieldValue: this.props.assetId
      })
    }
    this.filterQueryList.push({
      fieldName: 'quantity',
      fieldValue: '0',
      filterOperator: 'NEQ'
    })
    this.includeDownload = false
    this.includeFilters = true;
    this.includeSearch = true
    this.deleteFilter = this.deleteFilter.bind(this)
    this.nameVariableStateFilter = 'workOrderArticleListFilter'
  }

  getListFields () {
    return [
      {
        title: t('Código'),
        name: 'articleCode',
        link: false,
        component: (props) => <TextListItem link={true} onClick={(e) => this.watchInArticle(e)} {...props} />,
        visible: true
      },
      {
        title: t('Lote'),
        name: 'stockLote',
        link: false,
        component: (props) => <TextListItem link={true} {...props} />,
        visible: true
      },
      {
        title: t('F. Creación'),
        name: 'createdAt',
        link: false,
        component: (props) => <IsoDateListItem link={true} {...props} />,
        visible: true
      },
      {
        title: t('Cantidad'),
        name: 'quantity',
        link: false,
        component: (props) => <TextListItem link={true} textAlign {...props} />,
        visible: true
      },

      {
        title: t('Orden de Trabajo'),
        name: 'workOrder.code',
        withOrder: false,
        component: (props) => <TextListItem link={false}
                                            onClick={(e) => this.watchInWorkOrderOrder(e)} {...props} />,
        visible: true
      },
      {
        title: t('Equipo'),
        name: 'workOrder.asset.plate',
        withOrder: false,
        component: (props) => <TextListItem link={false} onClick={(e) => this.watchInAsset(e)} {...props} />,
        visible: true
      },
      {
        title: t('Técnico'),
        name: 'user.firstName',
        withOrder: false,
        component: (props) => <CreatedByListItem concatLastName {...props} />,
        visible: true
      },
      {
        title: t('Establecimiento'),
        name: 'client.name',
        withOrder: false,
        component: (props) => <TextListItem link={false} onClick={(e) => this.watchInClient(e)} {...props} />,
        visible: true
      },
    ]
  }

  getDefaultOrder () {
    return 'articleCode'
  }

  getDefaultOrderMode () {
    return 'ASC'
  }

  watchInArticle (article) {
    this.propsUtil.changeUrlRequest({
      'articleId': article.articleId,
      'rightModal': 'modalarticles',
      'rightModalTab': 'articles',
      'fromRightModal': true
    })
  }

  watchInAsset (article) {
    this.propsUtil.changeUrlRequest({
      'assetId': article.workOrder?.asset?.id,
      'rightModal': 'modalassets',
      'rightModalTab': 'assets',
      'fromRightModal': true
    })
  }

  watchInClient (article) {
    this.propsUtil.changeUrlRequest({
      'clientId': article.client?.id,
      'rightModal': 'modalposclients',
      'rightModalTab': 'general',
      'fromRightModal': true
    })
  }

  watchInWorkOrderOrder (article) {
    this.propsUtil.changeUrlRequest({
      'workOrderId': article.workOrderId,
      'rightModal': 'modalworkorderls',
      'rightModalTab': 'workorder',
      'fromRightModal': true
    })
  }

  getListState () {
    if (util.hasValue(this.props.articleId)) {
      return appState.articleWorkOrderArticleState
    } else if (util.hasValue(this.props.assetId)) {
      return appState.articleState
    } else {
      return appState.workOrderCartArticlesState
    }
  }

  getModelQuery () {
    let result = new WorkOrderCartArticleModel()
    result.addRelatedTableOnlyFields('user', new RelatedTableConfig(['firstName', 'lastName']))
    result.addRelatedTableOnlyFields('workOrder', new RelatedTableConfig(['code']))
    result.addRelatedTableOnlyFields('workOrder.asset', new RelatedTableConfig(['plate', 'id']))
    result.addRelatedTableOnlyFields('client', new RelatedTableConfig(['name', 'id']))
    return result
  }

  render () {
    this.propsUtil = new PropsUtil(this.props)
    let props = this.props
    const t = translate
    let fields = this.stateListColumnsSelect.listFields

    return (
      <InfoBlock componentObject={this}>
        {!util.hasValue(this.props.articleId) && !util.hasValue(this.props.assetId) &&
        <>
          <ListItemSelect
            stateListColumnsSelect={this.stateListColumnsSelect}
            name={this.graphOperation}
            subtitle={t('Consumos')}
          />
          <ModalListFilter listComponent={this} stateFilter={this.stateListFilter}>
            {this.renderFilters()}
          </ModalListFilter>
          <PageTitle
            title={t('Consumos')}
            subtitle={t('Listado')}
          >
            <MoreButtons
              onReloadData={() => this.reloadData()}
              showButton={this.creatable[this.getModelQuery().nameMainType] || false}
            />
          </PageTitle>
        </>
        }

        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

        <div className="main-content">
          {!util.hasValue(this.props.articleId) && !util.hasValue(this.props.assetId) &&
          <>
            <SearchComponent deleteFilter={this.deleteFilter}
                             listState={this.getListState()}
                             listData={this.getListData()}
                             numEntriesPerPage={this.numEntriesPerPage}
                             fields={fields}
                             onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                             renderActionsList={() => this.renderActionsList()}
                             model={this.getModelQuery()}
                             onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                             includeSavedFavourites={false}
                             includeChangeColumns={this.includeChangeColumns}
                             includeFilters={this.includeFilters}
                             includeDownload={this.includeDownload}
                             stateListColumnsSelect={this.stateListColumnsSelect}
                             includeSearch={this.includeSearch}
                             {...props}
                             updateFilters={(e) => this.updateInputFilterEvent(e)}
                             listComponent={this}
                             favouriteModalOpen={appState.layoutState.favouritesSaveMenuOpen}
                             toggleFavouriteModal={() => appState.layoutState.clickFavouritesSaveMenuOpen()}
            />
          </>
          }
          <ListComponent quickView={this.quickView}
                         listState={this.getListState()}
                         withActions={this.withActions}
                         listData={this.getListData()}
                         status={this.getListStatus()}
                         fields={fields}
                         urlForm={'/article/form'}
                         openQuickEdit={(row) => this.openQuickEdit(row)}
                         {...props}>
          </ListComponent>
          <div className="o-flex-between mt-3">
            <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                            listState={this.getListState()}
                            listData={this.getListData()} {...props} />

            <PaginationComponent listData={this.getListData()}
                                 numEntriesPerPage={this.numEntriesPerPage} {...props} />
          </div>
        </div>

      </InfoBlock>
    )
  }

  renderFilters () {
    return <WorkOrderArticleListFilters key={'workOrderCartArticleListFilters'} />
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default WorkOrderArticleList
