import Links from "./Links";

export default class LinksForModal extends Links {

    constructor(location) {
        super(location);
        this.paramOrder="modalOrder";
        this.paramOrderMode="modalOrderMode";
        this.paramPage="modalPage";
        this.paramPagination="modalPagination";
        this.paramAfter="modalAfter";
        this.paramBefore="modalBefore";
        this.paramFilters="modalFilters";
    }




}
