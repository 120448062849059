import React from 'react';
import {observer} from 'mobx-react';
import appState from "../../state/AppState";
import translate from "../../translator/translate";
import withRouter from "react-router-dom/es/withRouter";
import Select2Component from "../../components/fields/Select2Component"
import PropsUtil from "../../util/PropsUtil";
import AppLogger from "../../util/AppLogger";
import FiltersPage from "../FiltersPage";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import DynamicGroupModel from '../../models/DynamicGroupModel'
import util from '../../util/Util'
import ClientModel from "../../models/ClientModel";
import SelectSuggestComponent from "../../components/fields/SelectSuggestComponent";
import DateRangeMobiscroll from "../../components/fields/DateRangeMobiscroll";
import InputTypeComponent from "../../components/fields/InputTypeComponent";

@observer
class WorkOrderListFilters extends FiltersPage {

    nameVariableStateFilter = "workOrderListFilters";

    initialize() {
        super.initialize();
        appState[this.nameVariableStateFilter].filterType["createdAt"] = "DateTime";
    }

    async componentDidMount() {
        if (appState.clientCacheState.clients.length == 0) {
            await appState.clientCacheState.loadClientCache()
        }
    }

    async getClientsSuggestions(value) {
        let clientArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let clientQuery = new ClientModel();
            clientQuery.isPos = true;
            clientQuery.filters = [
                { "fieldName": "name,code", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let client = await clientQuery.find();
            client.map((client) => {
                let clientObject = {};
                clientObject.label = client.code + " - " + client.name;
                clientObject.value = client.id;
                clientArray.push(clientObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return clientArray;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let options = [...appState.userCacheState.userCacheForSelectList, {
            label: "No asignado",
            value: util.getZeroIdIdentifierGUID()
        }];
        let optionsYorN = [
            { label: "Si", value: "Y" },
            { label: "No", value: "N" }
        ]
        const t = translate;

        return (
            <React.Fragment>
                <DateRangeMobiscroll
                    value={appState[this.nameVariableStateFilter].currentFilter.createdAt_From}
                    valueTo={appState[this.nameVariableStateFilter].currentFilter.createdAt_To}
                    onChange={(e) => this.updateInputFilterEvent(e, "GTEQ")}
                    onChangeTo={(e) => this.updateInputFilterEvent(e, "LTEQ")}
                    classGroup={"col-md-6 col-xl-6"}
                    name={'createdAt_From'}
                    nameTo={'createdAt_To'}
                    editable={this.editable}
                    allowEmptyDate={true}
                    prefix={"fas fa-calendar-alt"}
                    title={t("F. Modificación")}
                />
                <DateRangeMobiscroll
                    value={appState[this.nameVariableStateFilter].currentFilter.nextScheduledDate_From}
                    valueTo={appState[this.nameVariableStateFilter].currentFilter.nextScheduledDate_To}
                    onChange={(e) => this.updateInputFilterEvent(e, "GTEQ")}
                    onChangeTo={(e) => this.updateInputFilterEvent(e, "LTEQ")}
                    classGroup={"col-md-6 col-xl-6"}
                    name={'nextScheduledDate_From'}
                    nameTo={'nextScheduledDate_To'}
                    allowEmptyDate={true}
                    prefix={"fas fa-calendar-alt"}
                    title={t("Próxima planificación")}
                />
                <DateRangeMobiscroll
                    value={appState[this.nameVariableStateFilter].currentFilter.lastVisitDate_From}
                    valueTo={appState[this.nameVariableStateFilter].currentFilter.lastVisitDate_To}
                    onChange={(e) => this.updateInputFilterEvent(e, "GTEQ")}
                    onChangeTo={(e) => this.updateInputFilterEvent(e, "LTEQ")}
                    classGroup={"col-md-6 col-xl-6"}
                    name={'lastVisitDate_From'}
                    nameTo={'lastVisitDate_To'}
                    allowEmptyDate={true}
                    prefix={"fas fa-calendar-alt"}
                    title={t("Última fecha de ejecución")}
                />
                <SelectSuggestComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.clientId}
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    name={"clientId"} multi={true}
                    title={t("Establecimiento")}
                    classGroup={"col-md-12 col-xl-8"}
                    loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                    placeholder={t("Escribe...")}
                />
                <div className="col-4" />
                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.assignedToMainId}
                    name="assignedToMainId"
                    title={t("Técnico principal")}
                    operatorFilter={"STRIN"}
                    classGroup={"col-lg-4"}
                    options={options}
                    multi={true}
                />
                <Select2Component
                    onChange={(e) => this.updateInputFilterEvent(e, "STRIN")}
                    value={appState[this.nameVariableStateFilter].currentFilter.status}
                    name="status"
                    title={t("Estado")}
                    classGroup={"col-lg-4"}
                    operatorFilter={"STRIN"}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_WORKORDER_STATUS)}
                    multi={true}
                />
                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.symptom}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"symptom"}
                    title={t("Síntomas de la Avería")}
                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_SYMPTHOM)}
                />

                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.type}
                    name="type"
                    operatorFilter={"STRIN"}
                    title={t("Tipo Orden de Trabajo")}
                    classGroup={"col-4"}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_WORK_ORDER_TYPE)}
                    multi={true}
                />
                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e, op)}
                    value={appState[this.nameVariableStateFilter].currentFilter.subType}
                    name="subType"
                    operatorFilter={"STRIN"}
                    title={t("Subtipo Orden de Trabajo")}
                    classGroup={"col-4"}
                    options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_WORK_ORDER_TYPE, appState[this.nameVariableStateFilter].currentFilter.type)}
                    multi={true}
                />
                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.assetType}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"assetType"}
                    title={t("Tipo de Equipo")}
                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ASSET_TYPE)}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.assetSubType}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"assetSubType"}
                    title={t("Subtipo de Equipo")}
                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                    options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_ASSET_TYPE, appState[this.nameVariableStateFilter].currentFilter.assetType)}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.warrantyPossible}
                    title={t("Posible Garantía")}
                    classGroup={"col-md-4"}
                    name={"warrantyPossible"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={optionsYorN}
                    type={"text"}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.warrantyAccepted}
                    title={t("Garantía Aplicable")}
                    classGroup={"col-md-4"}
                    name={"warrantyAccepted"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={optionsYorN}
                    type={"text"}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.warrantyRevised}
                    title={t("Garantía Revisada")}
                    classGroup={"col-md-4"}
                    name={"warrantyRevised"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={optionsYorN}
                    type={"text"}
                />

                <InputTypeComponent
                    value={appState[this.nameVariableStateFilter].currentFilter.warrantyManufacturerCode}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    name={"warrantyManufacturerCode"}
                    title={t("Código de Garantía de Fabricante")}
                    placeholder={"Código de Garantía de Fabricante"}
                    classGroup={"col-md-4 col-lg-4 col-xl-4"}
                    type={"text"}
                />
                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.reasonReturnId}
                    title={t("Motivo de Devolución")}
                    name={"reasonReturnId"}
                    classGroup={"col-md-4"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_CAUSES_PAUSE)}
                    type={"text"}
                />
                <Select2Component
                    onChange={(e, op) => this.updateInputFilterEvent(e)}
                    value={appState[this.nameVariableStateFilter].currentFilter.lastTechnicalReturnId}
                    name="lastTechnicalReturnId"
                    title={t("Devuelta por")}
                    classGroup={"col-lg-4"}
                    options={options}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.hasReturned}
                    title={t("Ha sido devuelta")}
                    classGroup={"col-md-4"}
                    name={"hasReturned"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={optionsYorN}
                    type={"text"}
                />

                <Select2Component
                    value={appState[this.nameVariableStateFilter].currentFilter.returnStatusId}
                    title={t("Estado - Seguimiento de Devolución")}
                    classGroup={"col-md-4"}
                    name={"returnStatusId"}
                    onChange={(e) => this.updateInputFilterEvent(e)}
                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_RETURN_STATUS)}
                    type={"text"}
                />
                {this.getFiltersDynamics(DynamicGroupModel.WORK_ORDER).map(item => (
                    item
                ))}
            </React.Fragment>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}


export default withRouter(WorkOrderListFilters);
