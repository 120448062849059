import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import VsfButtonNew from "./VsfButtonNew";
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import Overlay from "./modals/Overlay";
import translate from "../translator/translate";
import util from '../util/Util'
import AppLogger from "../util/AppLogger";
import SwitchComponent from "./fields/SwitchComponent";
import PropsUtil from "../util/PropsUtil";
import ReactTooltip from "react-tooltip";


@observer
class MoreButtons extends Component {

    static propTypes = {
        actions: PropTypes.array,
        showButton: PropTypes.bool,
    };
    static defaultProps = {
        actions: [],
        showButton: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            lastModif: util.getMoment(Date.now()).format("YYYY-DD-MM HH:mm")
        }
        if (this.props.onRef != null) {
            this.props.onRef(this);
        }
    }

    async onReloadData() {
        this.setState({ loading: true, lastModif: util.getMoment(Date.now()).format("YYYY-DD-MM HH:mm") });
        await this.props.onReloadData();
        setTimeout(() => {
            this.setState({ loading: false });
        }, 500)

    }

    onClick(evento) {
        appState.layoutState.menu = false;
        evento();
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;

        let buttons = [];
        this.props.actions.map((accion) => {
            if (accion.button) {
                buttons.push(accion)
            }
        });

        let actions = [];
        this.props.actions.map((accion) => {
            if (!accion.button) {
                actions.push(accion)
            }
        });

        return (
            <div className="d-flex align-items-center justify-content-end">
                <div
                    className="wrapper-buttons-right d-flex align-items-center justify-content-end">
                    {buttons.map((accion) => {
                        if (!accion.dontShow) {
                            return (
                                <button className="btn btn-primary btn-padding-small ml-3"
                                        key={accion.title}
                                        onClick={() => this.onClick(accion.onClick)}
                                        data-cy={this.props.dataCy}>
                                    {accion.title}
                                    <span>{accion.icon && <i className={accion.icon} />}</span>
                                </button>
                            )
                        }
                    })}
                    {this.props.switchList &&
                    <SwitchComponent
                        value={this.props.valueSwitch} checked={this.props.valueSwitch}
                        title={this.props.titleSwitch}
                        onChange={(e) => this.props.onChangeList(e)}
                        classGroup="c-switch"
                    />
                    }
                    {this.props.onReloadData &&
                    <div className="last-update-div d-flex ml-3" data-tip data-for="last-update">
                        <button type="button"
                                onClick={() => this.onReloadData()}
                        >
                            <i className={this.state.loading ? "far fa-sync-alt fa-spin" : "far fa-sync-alt"} />
                        </button>
                        <ReactTooltip data-for="last-update" id="last-update" place="bottom"
                                      textColor="#ffffff"
                                      backgroundColor="#1D3145" arrowColor="#1D3145"
                                      multiline={false}>
                            {t("Última actualización") + ": " + this.state.lastModif}
                        </ReactTooltip>
                    </div>
                    }
                    {(actions.length > 0 || this.props.children != null) &&
                    <VsfButtonNew
                        onClick={() => appState.layoutState.clickMenu()}
                        className={"btn btn--points "}
                        icon={"fas fa-ellipsis-v"} showButton={this.props.showButton}
                        disabled={false}
                        dataCy={"moreButtonsCy"}
                    />
                    }
                    {appState.layoutState.menu &&
                    <>
                        <Overlay show={appState.layoutState.menu} onClick={_ => appState.layoutState.menu = false} />
                        <div className="dropdown-sublist" style={{ zIndex: 100 }}>
                            {actions.map((accion) => {
                                if (!accion.dontShow) {
                                    return (
                                        <div className="dropdown-sublist__item " key={accion.title}>
                                            <a onClick={() => this.onClick(accion.onClick)}
                                               data-cy={this.props.dataCy}>
                                                {accion.title}
                                                <span>{accion.icon && <i className={accion.icon} />}</span>
                                            </a>
                                        </div>
                                    )
                                }
                            })}
                            {this.props.children}
                        </div>
                    </>
                    }
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(MoreButtons)

