import React from 'react'
import AbstractModel from './AbstractModel'
import DocumentModel from './DocumentModel'

export default class ArticleModel extends AbstractModel {

    static CODE_INMATERIAL = "INMATERIAL";
    static CODE_MATERIAL = "MATERIAL";
    static CODE_LOT = "LOT";
    static CODE_SERIAL = "SERIAL";
    static CODE_ARTICLE = "ARTICLE";
    static CODE_DESLOCALIZATION = "DESLOCALIZATION";
    static CODE_WORKFORCE = "WORKFORCE";

    id;
    code;
    enable;
    family;
    subfamily;
    description;
    manufacturer;
    model;
    manufacturerRef;
    warranty;
    endDateWarranty;
    documentation;
    type;
    codeErp;
    itemType;
    saleUnit;
    purchaseUnit;
    stockUnit;
    serialType;
    tax;
    priceWithoutTax;
    priceWithTax;
    serviceType;
    documentationCodes;
    createdBy;
    createdAt;
    updatedBy;
    updatedAt;

    nameMainType = "article";
    graphFindByIdOperation = "article";
    graphFindOperation = "articlesConnection";
    graphExportOperation = "articlesExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "code": { label: "Código" },
            "enable": { label: "Disponibilidad" },
            "family": { label: "Familia" },
            "subfamily": { label: "Subfamilia" },
            "description": { label: "Descripción" },
            "manufacturer": { label: "Fabricante" },
            "model": { label: "Modelo" },
            "manufacturerRef": { label: "Referencia Fabricante"},
            "endDateWarranty": { label: "Fecha fin de garantía" },
            "warranty": { label: "Garantía", type: "BoolField" },
            "type": { label: "Tipo" },
            "stockUnit": { label: "Unidad de stock" },
            "codeErp": { label: "Código ERP", readonly: true },
            "itemType": { label: "Tipo de Objeto" },
            "saleUnit": { label: "Unidad de Venta"},
            "purchaseUnit": { label: "Unidad de Compra" },
            "serialType": { label: "Serial"},
            "serviceType": { label: "Tipo de Servicio"},
            "documentationCodes": { label: "Códigos de referencia de manual"},
            "createdBy": { label: "Creado por" },
            "createdAt": { label: "Creado el" },
            "updatedBy": { label: "Actualizado por" },
            "updatedAt": { label: "Actualizado el" },

        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        return result;
    }

    getArrayForExcelImports() {
        let result = {
            "Datos generales": [
                "code",
                "documentationCodes",
            ]
        };
        return result;
    }

}
