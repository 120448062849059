import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import VehicleModel from "../models/VehicleModel";

class VehicleState extends BaseModelState {


    @observable vehicles = [];

    async getVehicles() {
        let vehicleQuery = new VehicleModel();{
            let result = await vehicleQuery.find();
            if (result == null) {
                result = [];
            }
            this.vehicles = result;
        }
    }

}

export default VehicleState;
