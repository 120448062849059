import React, { Component } from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import util from '../../util/Util'
import { observable, toJS } from 'mobx'
import WorkOrderModel from '../../models/WorkOrderModel'
import WorkOrderCartArticleModel from '../../models/WorkOrderCartArticleModel'
import PropsUtil from '../../util/PropsUtil'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import InfoBlock from '../../components/InfoBlock'
import PropTypes from 'prop-types'
import AppLogger from '../../util/AppLogger'
import GqlErrors from '../../components/status/GqlErrors'
import GraphException from '../../network/GraphException'
import translate from '../../translator/translate'

@observer
class OrderFormWorkOrder extends Component {

    @observable gqlErrors = [];

    static defaultProps = {
        orderId: null

    };

    static propTypes = {
        //OrderId from this WorkOrders
        orderId: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = { removeModalActive: false }
    }

    getArticles() {
        let workOrder = this.props.row;
        let result = 0;
        if (workOrder && workOrder.workOrderCartArticle?.length > 0) {
            for (let article of workOrder.workOrderCartArticle) {
                if (article.status === WorkOrderCartArticleModel.CODE_UTILIZADA) {
                    result += article.quantity;
                }
            }
        }

        return parseFloat(result).toFixed(2);
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    openAsset(assetId) {
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalassets",
            "assetId": assetId,
            "rightModalTab": "assets",
        });
    };

    openWorkOrder(workOrder) {
        let orderId = this.props.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalworkorderls",
            "orderId": orderId,
            "workOrderId": workOrder.id,
            "rightModalTab": "workorder"
        });
    };

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async onDeleteRow() {
        let workOrderModel = new WorkOrderModel();
        workOrderModel.hidrate(this.props.row);
        appState.loadingBarState.start();
        try {
            if (util.hasValue(workOrderModel.id)) {
                await workOrderModel.remove();
            }
            let arrCopy = toJS(appState.workOrderState.workOrders);
            arrCopy.splice(this.props.rowIndex, 1);
            appState.workOrderState.workOrders = arrCopy;
            this.props.setHasSomeRowEditing(false);
        } catch (e) {
            let graphExceptionModel = new GraphException();
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e);
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors;
            if (errors.length > 0) {
                this.gqlErrors = errors;
            }
        }
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let workOrder = this.props.row;
        const t = translate;
        let equipo = workOrder.asset ? workOrder.asset : {};
        this.log({ workorderId: workOrder.orderId, orderId: this.props.orderId })
        return (
            <InfoBlock componentObject={this} wrapper={"tbody"}>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <tr style={{ 'borderBottom': 0 }}>
                    <td className="td-underline">
                        {/* numero de orden de trabajo*/}
                        <div className={"text-click"} onClick={() => this.openWorkOrder(workOrder)}>
                            {workOrder.code}   </div>
                    </td>
                    <td>
                        <div>
                            {workOrder.assetId ? <span className={"text-click"}
                                                       onClick={() => this.openAsset(equipo.id)}>{equipo.plate}</span> : (workOrder.assetPlate || t("No asignado"))}
                        </div>
                    </td>
                    <td>
                        <span className="status"
                              style={{ color: appState.typifiedState.getObject(workOrder.status)?.color }}>{appState.typifiedState.getValue(workOrder.status)}</span>
                    </td>
                    <td>
                        <div>
                            {workOrder.assetId ? appState.typifiedState.getValue(equipo.type) :
                                appState.typifiedState.getValue(workOrder.assetType)} </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <div>
                                {workOrder.assetId ? appState.typifiedState.getValue(equipo.subType) :
                                    (appState.typifiedState.getValue(workOrder.assetSubType) || t("No asignado"))} </div>
                        </div>
                    </td>
                    <td>
                        <div className="form-group">
                            <div>
                                {appState.typifiedState.getValue(workOrder.type)}</div>
                        </div>
                    </td>
                    {/*<td>*/}
                    {/*    <div className="form-group">*/}
                    {/*        <div>*/}
                    {/*            {appState.typifiedState.getValue(workOrder.subType) || t("No asignado")}</div>*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*    <div className="form-group">*/}
                    {/*        {workOrder.documents && workOrder.documents.length === 0 ?*/}
                    {/*            <span className="no-attached">No</span>*/}
                    {/*            :*/}
                    {/*            <span className="yes-attached">{workOrder.documents?.length}</span>*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</td>*/}
                    <td>
                        <div className="form-group">
                            {this.getArticles()}
                        </div>
                    </td>

                    <td className="">
                        {appState.assetState.workOrdersNotAsigned[workOrder.id] != null ?
                            <span className="not-schedule">{t("No planificada")}</span>
                            : <span className="schedule">{t("Planificada")}</span>
                        }
                    </td>
                    <td>
                        {workOrder.orderId === this.props.orderId &&
                        <div className="form-group">
                            <div className="remove-add">
                                <div onClick={() => this.openWorkOrder(workOrder)} title="Editar Contacto"
                                     className="edit">
                                    <span className="fas fa-edit"> </span>
                                </div>
                                {(workOrder.deletable && !this.props.lastRow) &&
                                <div onClick={() => this.setState({ removeModalActive: 1 })} title="Eliminar"
                                     className="remove">
                                    <span className="fas fa-trash-alt"> </span>
                                </div>
                                }
                            </div>
                        </div>
                        }
                    </td>

                    <AlertModal isOpen={this.state.removeModalActive}
                                onCloseModal={() => this.setState({ "removeModalActive": false })}>
                        <PopUp
                            title={t('cancelar')}
                            subtitle={t('OT')}
                            icon="exclamationTriangleIcon"
                            text={t("Va a eliminar la Orden de Trabajo CodeOT. ¿Quiere continuar?").replace("CodeOT", workOrder.code)}
                            label1={t('No eliminar')}
                            label2={'No Eliminar'}
                            onClick1={() => this.onDeleteRow()}
                            onClick2={() => this.setState({ "removeModalActive": false })}
                        />
                    </AlertModal>
                </tr>
                {/* añadido tr con comentarios*/}
                {workOrder.orderId !== this.props.orderId &&
                <tr style={{ borderBottom: '0' }}>
                    <td colSpan="11" className="notes-cell pt-0">
                        <span>    {t("Movido al pedido") + ": " + workOrder.order?.code}</span>
                    </td>
                </tr>
                }
                <tr>
                    <td colSpan="11" className="notes-cell pt-0">
                        {workOrder.generalDescription ? t("Notas") + ": " + workOrder.generalDescription
                            : t("Notas") + ": " + t("No hay notas")
                        }
                    </td>
                </tr>

            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default OrderFormWorkOrder;
