import React from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import BaseForm from '../base/BaseForm'
import translate from '../../translator/translate'
import FormButtons from '../../components/FormButtons'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import Select2Component from '../../components/fields/Select2Component'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import UserModel from '../../models/UserModel'
import MoreButtons from '../../components/MoreButtons'
import PropsUtil from '../../util/PropsUtil'
import { observable } from 'mobx'
import AppLogger from '../../util/AppLogger'
import InfoBlock from '../../components/InfoBlock'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'
import TabsData from '../../layout/TabsData'
import TabsComponent from '../../components/fields/TabsComponent'
import util from '../../util/Util'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import LoadTranslations from '../../translator/LoadTranslations'
import LocalStorage from '../../network/LocalStorage'
import GqlErrors from '../../components/status/GqlErrors'
import GqlWarnings from '../../components/status/GqlWarnings'
import ClientModel from '../../models/ClientModel'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import PermissionsModal from '../../components/PermissionsModal'
import SubcontractModel from '../../models/SubcontractModel'
import UserActions from './UserActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'

@observer
class UserForm extends BaseForm {
    nameMainType = "user";
    @observable hasNoErrors = false;
    @observable passwordErrors = [];
    @observable isPasswordValidationNeeded = false;

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.tabsData.isClient = appState.userState.isUserRoleClient();
        this.state = {
            errorFormulario: false,
            permissionsModal: false,
            userBlocked: false,
            loading: true,
            change: 0
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);
        appState.layoutState.badPassword = true;
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        if (!util.hasValue(this.graphDataMainType.id)) {
            this.isPasswordValidationNeeded = true;
        }
        this.setState({ change: 1 });
        this.setState({ loading: false });
    }

    getModelQueryForView() {
        return new UserModel();
    }

    getModelQuery() {
        return new UserModel();
    }

    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @param firstCall
     * @returns Array
     */
    async getClientPosSugestions(value, firstCall) {
        let clientArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let clientQuery = new ClientModel();
            if (firstCall) {
                clientQuery.filters = [
                    { "fieldName": "id", "fieldValue": this.graphDataMainType.clientPosIds, "filterOperator": "STRIN" },
                ];
            } else {
                clientQuery.filters = [
                    { "fieldName": "name,code", "fieldValue": value, "filterOperator": "SUBSTR" },
                ];
            }
            clientQuery.isPos = 'true';
            let clients = await clientQuery.findPlainObject();
            for (let client of clients) {
                let clientObject = {};
                clientObject.label = (client.code || "") + " - " + client.name;
                clientObject.value = client.id;
                clientArray.push(clientObject);
            }
        } catch (e) {
            this.log("Error => " + e);
        }
        return clientArray;
    }

    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @param firstCall
     * @returns Array
     */
    async getClientsSuggestions(value, firstCall) {
        let clientArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let clientQuery = new ClientModel();
            if (firstCall) {
                clientQuery.id = this.graphDataMainType.clientId;
            } else {
                clientQuery.filters = [
                    { "fieldName": "companyName,code", "fieldValue": value, "filterOperator": "SUBSTR" },
                ];
            }
            clientQuery.isPos = 'false';
            let clients = await clientQuery.findPlainObject();
            for (let client of clients) {
                let clientObject = {};
                clientObject.label = (client.code || "") + " - " + client.companyName;
                clientObject.value = client.id;
                clientArray.push(clientObject);
            }
        } catch (e) {
            this.log("Error => " + e);
        }
        return clientArray;
    }

    async getSubcontractsSuggestions(value, firstCall) {
        let subcontractArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let subcontractQuery = new SubcontractModel();
            if (firstCall) {
                subcontractQuery.id = this.graphDataMainType.subcontractId;
            } else {
                subcontractQuery.filters = [
                    { "fieldName": "name", "fieldValue": value, "filterOperator": "SUBSTR" },
                    { "fieldName": "status", "fieldValue": "ACTIVE", "filterOperator": "EQ" },
                ];
            }
            let subcontracts = await subcontractQuery.findPlainObject();
            for (let subcontract of subcontracts) {
                let subcontractObject = {};
                subcontractObject.label = subcontract.name;
                subcontractObject.value = subcontract.id;
                subcontractArray.push(subcontractObject);
            }
        } catch (e) {
            this.log("Error => " + e);
        }
        return subcontractArray;
    }


    isPasswordValid(event) {
        this.updateInputEvent(event);
        let encontrado = true;
        let contrasenya = event.target.value;
        if (!/^(?=.*\d)(?=.*[\W])(?=.*[A-Z])(?=.*[a-z])\S{8,}$/.test(contrasenya)) {
            appState.layoutState.badPassword = true;
            this.hasNoErrors = false;
        } else {
            this.hasNoErrors = true;
            appState.layoutState.badPassword = false;
        }
        if (this.hasNoErrors) {
            this.passwordErrors = [];
            encontrado = false;
        }
        if (encontrado && this.passwordErrors.length === 0) {
            this.passwordErrors.push({ "message": "" })
        }
    }

    async formDidSave(model, previousId) {
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        if (appState.loginState.user.id === model.id) {
            let localStorage = new LocalStorage();
            if (model.timeZone != null) {
                localStorage.setItem('currentTimeZone', appState.typifiedState.getObject(model.timeZone).code);
            }
            if (model.locale != null) {
                if (localStorage.getItem('currentLanguage') !== appState.typifiedState.getObject(model.locale).code) {
                    localStorage.setItem('currentLanguage', appState.typifiedState.getObject(model.locale).code);
                    let loadTranslations = new LoadTranslations();
                    await loadTranslations.loadMessages();
                }
            }
        }
    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    updateInputRoleEvent(e) {
        this.updateInputEvent(e);
        if (util.perteneceListaComa(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CUSTOMIZED").id, this.graphDataMainType.role)) {
            if(!util.hasValue(this.graphDataMainType.permissions)){
                this.updateInput("permission",'{"TypifiedValues":"R","Users":"R","Tenants":"R"}')
            }
            this.setState({ permissionsModal: true })
        }
    }

    updateInputEventStatus(e) {
        this.updateInputEvent(e);
        if (this.graphDataMainType.status === UserModel.STATUS_BLOCKED) {
            this.setState({ userBlocked: true });
        }
    }

    renderImpl() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let user = this.graphDataMainType;
        if (user == null) {
            user = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let optionsActions = [
            { label: t("Cierre de jornada de técnicos"), value: UserModel.ACTIONS_END_DAY },
        ];
        let userStatus = [
            { label: t("Activo"), value: UserModel.STATUS_ACTIVO },
            { label: t("Inactivo"), value: UserModel.STATUS_INACTIVO },
            { label: t("Baja"), value: UserModel.STATUS_BAJA_DEFINITIVA },
            { label: t("Bloqueado"), value: UserModel.STATUS_BLOCKED },
        ];
        let modalClasses = util.getModalClasses();
        let textoErrores = "";
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""] && errorsMapped[""].map(error => error.message);
            if (util.hasValue(textoErrores)) {
                this.state.errorFormulario = true;
            }
        }

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                {this.getFormId() != null &&
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)} model={user}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                                }
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataUser()}
                                active="Datos Generales"
                                alarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.getFormId() != null &&
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            >
                                <UserActions fromRightModal={this.props.fromRightModal}
                                    setErrors={(errors) => this.setErrors(errors)} model={user}
                                    getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <SpinnerClipLoaderInPage loading={this.state.loading}>
                            <div className={modalClasses.body}>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataUser()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                />
                                }
                                <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                    error={this.graphStatus.mutationError}
                                    working={this.graphStatus.networkWorking}
                                />

                                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                <GqlWarnings warnings={this.gqlWarnings} onSaveWarning={() => this.saveWithWarnings()}
                                    key={this.gqlWarnings}
                                    setWarnings={(warnings) => this.setWarnings(warnings)} />

                                <form
                                    className="model-form" autoComplete={"off"}
                                    name="formulario"
                                    onSubmit={(e) => this.handleFormSubmit(e)}>
                                    <div className={'card mb-3'}>
                                        <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                            error={this.graphStatus.mutationError}
                                            working={this.graphStatus.networkWorking}
                                        />


                                        <AlertModal isOpen={this.state.errorFormulario}
                                            onCloseModal={() => this.clickErroresFormulario()}>
                                            <PopUp
                                                title={t('Error')}
                                                icon="exclamationTriangleIcon"
                                                text={textoErrores}
                                                label1={'Aceptar'}
                                                onClick1={() => this.clickErroresFormulario()}
                                            >
                                            </PopUp>
                                        </AlertModal>
                                        <GroupOfField title={t("Identificacion del Usuario")} icon="fas fa-id-badge">
                                            <InputTypeComponent
                                                dataCy={"inputEmail"}
                                                value={user.email}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"email"}
                                                title={t("Email")}
                                                placeholder="Email"
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                readOnly={util.hasValue(user.id)}
                                                required={true}
                                                validate={"email"}
                                                errors={errorsMapped.email}
                                            />
                                            {this.getFormId() == null &&
                                            <InputTypeComponent
                                                dataCy={"inputPassword"}
                                                value={user.password}
                                                onChange={(e) => this.isPasswordValid(e)}
                                                name={"password"}
                                                title={t("Password")}
                                                placeholder="Password"
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"password"}
                                                hasNoErrors={this.hasNoErrors}
                                                required={true}
                                                errors={this.passwordErrors}
                                            />
                                            }
                                            <Select2Component
                                                dataCy={"selectRol"}
                                                value={user.role}
                                                onChange={(e) => this.updateInputRoleEvent(e)}
                                                name={"role"}
                                                editable={this.editable}
                                                title={t("Rol")}
                                                classGroup="col-md-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_USER_ROLE)}
                                                multi={true}
                                                errors={errorsMapped.role}
                                            />
                                            {util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "DISPATCHER").id, user.role) &&
                                            <Select2Component
                                                dataCy={"actions"}
                                                value={user.actions}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"actions"}
                                                editable={this.editable}
                                                title={t("Acciones permitidas")}
                                                classGroup="col-md-4"
                                                options={optionsActions}
                                                multi={true}
                                                errors={errorsMapped.role}
                                            />
                                            }
                                            {util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CUSTOMIZED").id, user.role) &&
                                            <div
                                                className={this.props.fromRightModal ? "pr-0 pl-0 col-md-2" : "col-md-2 pl-0"}>
                                                <div className={"button-form-group mb-0  width-text-edit-client"}>
                                                    <span className="link-underline ico ico--edit"
                                                        onClick={() => this.setState({ permissionsModal: true })}
                                                    >{t("Editar Permisos")}</span>
                                                </div>
                                            </div>
                                            }

                                            {(!util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, user.role) &&
                                                !util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CUSTOMIZED").id, user.role) &&
                                                !util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "DISPATCHER").id, user.role)) &&
                                            this.getFormId() != null && <div className={"col-3"} />
                                            }
                                            <Select2Component
                                                value={user.timeZone}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"timeZone"}
                                                editable={this.editable}
                                                isClearable={false}
                                                title={t('Zona Horaria')}
                                                classGroup="col-md-4 col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_TIME_ZONES)}
                                                dataCy={"selectTimeZone"}
                                            />
                                            <Select2Component
                                                value={user.permissionZoneId }
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"permissionZoneId"}
                                                editable={this.editable}
                                                isClearable={false}
                                                multi
                                                title={t('Permisos de zona')}
                                                classGroup="col-md-4 col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ZONE)}
                                            />
                                            <Select2Component
                                                value={user.locale}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                isClearable={false}
                                                name={"locale"}
                                                title={t("Lenguaje")}
                                                editable={this.editable}
                                                classGroup="col-md-4 col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_LOCALES)}
                                                dataCy={"slectLocale"}
                                            />

                                            <Select2Component
                                                value={user.status}
                                                onChange={(e) => this.updateInputEventStatus(e)}
                                                name={"status"}
                                                isClearable={false}
                                                title={t("Estado")}
                                                editable={this.editable}
                                                classGroup={this.props.fromRightModal ? 'col-md-4 col-lg-4' : 'col-md-4 col-lg-4'}
                                                options={userStatus}
                                                errors={errorsMapped.status}
                                                dataCy={"selecStatus"}
                                            />
                                            <AlertModal isOpen={this.state.userBlocked}
                                                onCloseModal={() => {
                                                    this.handleAutosuggestSelection("status", this.previousRowForDiscard.status);
                                                    this.setState({ userBlocked: false })
                                                }}>
                                                <PopUp
                                                    title={t('Bloqueo')}
                                                    subtitle={t('Usuario') + this.graphDataMainType.email}
                                                    icon="exclamationTriangleIcon"
                                                    text={t('El usuario va a tener bloqueado el acceso al sistema. Si tiene acceso a otras instalaciones, no podrá acceder a ellas.')}
                                                    label1={'Volver a Editar'}
                                                    label3={'Guardar cambios'}
                                                    onClick3={() => this.setState({ userBlocked: false })}
                                                    onClick1={() => {
                                                        this.handleAutosuggestSelection("status", this.previousRowForDiscard.status);
                                                        this.setState({ userBlocked: false })
                                                    }}
                                                />
                                            </AlertModal>
                                        </GroupOfField>
                                        {util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, user.role) &&
                                        <GroupOfField title={t("Datos del Cliente")} icon="fas fa-id-badge">
                                            <SelectSuggestComponent
                                                value={user.clientId}
                                                onChange={(event) => {
                                                    let id = event.target.value;
                                                    if (util.hasValue(id)) {
                                                        this.handleAutosuggestSelection("clientId", id);
                                                    } else {
                                                        this.handleAutosuggestSelection("clientId", "");
                                                    }
                                                }}
                                                name={"clientId"}
                                                readOnly={util.hasValue(user.clientPosIds)}
                                                editable={this.editable}
                                                title={t("Cliente")}
                                                classGroup={"col-md-4"}
                                                loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                                                placeholder={t("Escribe...")}
                                            />
                                            <SelectSuggestComponent
                                                value={user.clientPosIds}
                                                onChange={(event) => {
                                                    let id = event.target.value;
                                                    if (util.hasValue(id)) {
                                                        this.handleAutosuggestSelection("clientPosIds", id);
                                                    } else {
                                                        this.handleAutosuggestSelection("clientPosIds", "");
                                                    }
                                                }}
                                                multi={true}
                                                readOnly={util.hasValue(user.clientId)}
                                                name={"clientPosIds"}
                                                editable={this.editable}
                                                title={t("Establecimiento")}
                                                classGroup={"col-md-12"}
                                                loadSuggestions={(value, firstCall) => this.getClientPosSugestions(value, firstCall)}
                                                placeholder={t("Escribe...")}
                                            />
                                        </GroupOfField>
                                        }
                                        <GroupOfField title={t("Datos Generales")}
                                            subtitle={t("Técnico")}
                                            icon="fas fa-id-card-alt"
                                        >
                                            <InputTypeComponent
                                                value={user.firstName}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"firstName"}
                                                title={t("Nombre")}
                                                placeholder="Nombre"
                                                editable={this.editable}
                                                classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-4' : 'col-md-12 col-lg-6 col-xl-4'}
                                                type={"text"}
                                                errors={errorsMapped.firstName}
                                                dataCy={"inputFirstName"}
                                            />

                                            <InputTypeComponent
                                                value={user.lastName}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"lastName"}
                                                title={t("Apellidos")}
                                                editable={this.editable}
                                                placeholder="Apellidos"
                                                classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-4' : 'col-md-12 col-lg-6 col-xl-4'}
                                                type={"text"}
                                                errors={errorsMapped.lastName}
                                                dataCy={"inputLastName"} />

                                            <DateInputFieldMobiscroll
                                                birthDate={true}
                                                prefix={"fas fa-calendar-alt"}
                                                editable={this.editable}
                                                value={user.birthDate}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"birthDate"}
                                                title={t("Fecha de Nacimiento")}
                                                classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-4' : 'col-md-7 col-lg-6 col-xl-4 pr-md-8 pl-md-8'}
                                                type={"text"}
                                                errors={errorsMapped.birthDate}
                                                styleCustomProps={true}
                                                allowEmptyDate={true}
                                                dataCy={"inputBirtDay"}
                                            />

                                            <InputTypeComponent
                                                value={user.idNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"idNumber"}
                                                title={t("NIF")}
                                                editable={this.editable}
                                                placeholder="NIF"
                                                classGroup={this.props.fromRightModal ? 'col-md-6 col-lg-4' : 'col-md-5 col-lg-6 col-xl-4'}
                                                type={"text"}
                                                errors={errorsMapped.idNumber}
                                                dataCy={"inputNIF"}
                                            />

                                            <InputTypeComponent
                                                value={user.insuranceNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"insuranceNumber"}
                                                title={t("Número SS")}
                                                placeholder="Introduzca su número de la Seguridad Social..."
                                                classGroup={this.props.fromRightModal ? 'col-md-4 col-lg-4' : 'col-md-4 col-lg-4'}
                                                editable={this.editable}
                                                type={"text"}
                                                errors={errorsMapped.insuranceNumber}
                                                dataCy={"inputInsuranceNumber"}
                                            />
                                            <InputTypeComponent
                                                value={user.citizenNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"citizenNumber"}
                                                title={t("Número Ciudadano")}
                                                placeholder="Número Ciudadano"
                                                editable={this.editable}
                                                classGroup={this.props.fromRightModal ? 'col-md-4 col-lg-4' : 'col-md-4 col-lg-4'}
                                                type={"text"}
                                                errors={errorsMapped.citizenNumber}
                                                dataCy={"inputCitizenNumber"}
                                            />
                                            <InputTypeComponent
                                                value={user.address}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"address"}
                                                title={t("Direccion")}
                                                placeholder="Direccion"
                                                editable={this.editable}
                                                classGroup={"col-md-12 col-lg-8"}
                                                type={"text"}
                                                errors={errorsMapped.address}
                                                dataCy={"inputAddress"}
                                            />
                                            <InputTypeComponent
                                                value={user.postalCode}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"postalCode"}
                                                title={t("Código Postal")}
                                                editable={this.editable}
                                                placeholder="Código Postal"
                                                classGroup={"col-md-4 col-lg-4"}
                                                classInput={" col-12"}
                                                type={"text"}
                                                validate={"postalCode"}
                                                errors={errorsMapped.postalCode}
                                                dataCy={"inputPostalCode"}
                                            />
                                            <InputTypeComponent
                                                value={user.country}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"country"}
                                                title={t("País")}
                                                placeholder="Pais"
                                                editable={this.editable}
                                                classGroup={"col-md-4 col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.country}
                                                dataCy={"inputCountry"}
                                            />
                                            <InputTypeComponent
                                                value={user.city}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"city"}
                                                title={t("Ciudad")}
                                                placeholder="Ciudad"
                                                classGroup={"col-md-4 col-lg-4"}
                                                editable={this.editable}
                                                type={"text"}
                                                errors={errorsMapped.city}
                                                dataCy={"inputCity"}
                                            />
                                            <InputTypeComponent
                                                value={user.province}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"province"}
                                                title={t("Provincia")}
                                                editable={this.editable}
                                                placeholder="Provincia"
                                                classGroup={"col-md-4 col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.province}
                                                dataCy={"inputProvince"}
                                            />
                                            <InputTypeComponent
                                                value={user.phoneNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"phoneNumber"}
                                                title={t("Número de Télefono")}
                                                placeholder={t("Número de Télefono")}
                                                validate={"phone"}
                                                classGroup={"col-md-6 col-lg-4"}
                                                editable={this.editable}
                                                type={"text"}
                                                errors={errorsMapped.phoneNumber}
                                                dataCy={"inputPhoneNumber"}
                                            />
                                            <InputTypeComponent
                                                value={user.mobileNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"mobileNumber"}
                                                validate={"phone"}
                                                title={t("Número de movil")}
                                                editable={this.editable}
                                                placeholder={t("Número de movil")}
                                                classGroup={"col-md-6 col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.mobileNumber}
                                                dataCy={"inputMobileNumber"}
                                            />
                                            <div className="col-4" />
                                            <Select2Component
                                                value={user.contactRelationship}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"contactRelationship"}
                                                title={t("Relación")}
                                                editable={this.editable}
                                                classGroup={"col-md-4 col-lg-4"}
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_RELATIONSHIP)}
                                                errors={errorsMapped.contactRelationship}
                                                dataCy={"selectContactRelationship"}
                                            />
                                            <InputTypeComponent
                                                value={user.contactPerson}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"contactPerson"}
                                                title={t("Contacto")}
                                                editable={this.editable}
                                                placeholder="Contacto"
                                                classGroup={"col-md-4 col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.contactPerson}
                                                dataCy={"inputContactPerson"}
                                            />
                                            <InputTypeComponent
                                                value={user.contactPhoneNumber}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"contactPhoneNumber"}
                                                title={t("Teléfono (contacto)")}
                                                editable={this.editable}
                                                validate={"phone"}
                                                placeholder="Teléfono"
                                                classGroup={"col-md-4 col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.contactPhoneNumber}
                                                dataCy={"inputContactPhoneNumber"}
                                            />
                                            {!appState.userState.isUserRoleSubcontract() &&
                                            <SelectSuggestComponent
                                                value={user.subcontractId}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"subcontractId"}
                                                title={t("Subcontrata")}
                                                classGroup={"col-md-4 col-lg-4"}
                                                editable={this.editable}
                                                errors={errorsMapped.subcontractId}
                                                loadSuggestions={(value, firstCall) => this.getSubcontractsSuggestions(value, firstCall)}
                                                placeholder={t("Escribe...")}

                                            />
                                            }
                                        </GroupOfField>

                                        {this.state.permissionsModal &&
                                        <PermissionsModal
                                            title={t('Permisos de usuario')}
                                            userModel={user}
                                            updateInputEvent={(value) => this.handleAutosuggestSelection("permission", value)}
                                            openModal={this.state.permissionsModal}
                                            closeModal={() => {
                                                this.setState({ permissionsModal: false })
                                                this.handleFormSubmit()
                                            }}
                                        />
                                        }

                                        {this.props.fromRightModal ?
                                            <>
                                                <div className={"fixed-bottom-space-maker"} />
                                                <div className="fixed-bottom mr-3 mt-3">
                                                    <FormButtons id={this.graphDataMainType.id}
                                                        formStatus={this.graphStatus}
                                                        isPasswordValidationNeeded={this.isPasswordValidationNeeded} />
                                                </div>
                                            </>
                                            :
                                            <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus}
                                                isPasswordValidationNeeded={this.isPasswordValidationNeeded} />
                                        }
                                    </div>

                                </form>
                            </div>
                        </SpinnerClipLoaderInPage>
                    </div>
                </InfoBlock>
            </div>

        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default UserForm
