import React from 'react'
import { observer } from 'mobx-react'
import translate from '../../translator/translate'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'
import PropsUtil from '../../util/PropsUtil'
import { withRouter } from 'react-router-dom'
import BaseActions from '../base/BaseActions'

@observer
class VehicleActions extends BaseActions {

    constructor(props) {
        super(props);
        this.state = {
            rightModal: false,
            popUp: "",
        }
    }

    urlUserFromVehicle(vehicle) {
        let url = '/user/ls/?filters=';
        url += '[{\"fieldKey\":\"userVehicle\",\"fieldName\":\"userVehicle\",\"fieldValue\":\"' + vehicle.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + vehicle.plate + '\"}]';
        return url;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let model = this.props.model;
        return (
            <>
            <div className="dropdown-sublist__item ">
                <a onClick={() => {this.propsUtil.changeUrl(this.urlUserFromVehicle(model)); this.closeModal()}}>
                    {"Ver su Usuario"}
                </a>
                {model.deletable &&
                <a onClick={() => this.setState({rightModal: true, popUp: "eliminar"})}>
                    {t("Eliminar Vehículo")}
                </a>
                }
                <a onClick={() => {
                     if (this.props.fromRightModal) {
                            this.propsUtil.changeUrlRequest({ "rightModalTab": "history" });
                        } else {
                            this.propsUtil.changeUrl(this.urlForHistoric());
                        }
                    this.closeModal()
                }}>
                    {t("Control de cambios")}
                </a>
            </div>

            <AlertModal isOpen={this.state.rightModal && this.state.popUp !== ""}
                        onCloseModal={() => this.setState({
                            rightModal: false,
                            popUp: ""
                        })}>
                {this.state.popUp === "eliminar" &&
                <PopUp
                    title={t('Eliminar')}
                    subtitle={t('Vehiculo') + model.plate}
                    icon="exclamationTriangleIcon"
                    text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
                    label1={t('No eliminar')}
                    label3={t('Eliminar')}
                    onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                    onClick3={() => this.deleteModel('vehicle')}
                >
                </PopUp>
                }
            </AlertModal>
            </>
        )
    }
}

export default withRouter(VehicleActions)
