import moment from "moment/moment";
import AppLogger from "./AppLogger";

class DateUtil {

    constructor() {

    }

    /**
     * A partir de una fecha en formato "AAAAMMDD" devuelve todas las semanas que cubre ese mes
     * @param from
     * @returns {any[]}
     */
    getWeeksFromDate(from) {
        let mDateFrom = moment(from.replace('-', ''));
        let mDateTo = moment(mDateFrom).add(1, 'month');
        let mDateCurrent = moment(mDateFrom);
        //quitar esto si falla moment
        moment.suppressDeprecationWarnings = true;
        mDateCurrent.locale('es');


        let weeks = {};
        while (mDateCurrent.unix() < mDateTo.unix()) {
            let isoWeek = mDateCurrent.isoWeek();
            if (weeks[isoWeek] == null) {
                let monthName = mDateCurrent.format("MMMM");
                let colspan = 0;
                let firstDay = mDateCurrent.format("D");
                weeks[isoWeek] = { monthName, colspan, firstDay };
            }
            weeks[isoWeek].colspan++;
            mDateCurrent = mDateCurrent.add(1, "days");
        }
        let result = Object.values(weeks);
        return result;
    }


    getDiasMesFromDate(from) {
        let dias = [];
        let mDateFrom = moment(from.replace('-', ''));
        let mDateTo = moment(mDateFrom).add(1, 'month');
        let mDateCurrent = moment(mDateFrom);
        //quitar esto si falla moment
        moment.suppressDeprecationWarnings = true;
        while (mDateCurrent.unix() < mDateTo.unix()) {
            //console.log(mDateCurrent.toISOString());
            let labelWeekDay = mDateCurrent.format("ddd").substr(0, 1).toUpperCase();
            let dayOfMonth = mDateCurrent.format("D");
            let dayAndMonth = mDateCurrent.format("MMDD");
            let weekDay = mDateCurrent.isoWeekday(); //1 es Lunes
            let isoDate = mDateCurrent.format("YYYY-MM-DD");
            dias.push({ isoDate, dayOfMonth, labelWeekDay, weekDay, dayAndMonth });
            mDateCurrent = mDateCurrent.add(1, "days");
        }
        return dias;
    }


    getDiasMesAMes(from) {
        let dias = [];
        let mDateFrom = moment(from.replace('-', ''));
        let startOfMonth = moment(mDateFrom).startOf('month');
        let endOfMonth   = moment(mDateFrom).endOf('month');
        //quitar esto si falla moment
        moment.suppressDeprecationWarnings = true;
        while (startOfMonth.unix() < endOfMonth.unix()) {
            //console.log(mDateCurrent.toISOString());
            let labelWeekDay = startOfMonth.format("ddd").substr(0, 1).toUpperCase();
            let dayOfMonth = startOfMonth.format("D");
            let dayAndMonth = startOfMonth.format("MMDD");
            let weekDay = startOfMonth.isoWeekday(); //1 es Lunes
            let isoDate = startOfMonth.format("YYYY-MM-DD");
            dias.push({ isoDate, dayOfMonth, labelWeekDay, weekDay, dayAndMonth });
            startOfMonth = startOfMonth.add(1, "days");
        }
        return dias;
    }

    /**
     * A partir de una fecha en formato "AAAAMMDD" devuelve todas las semanas que cubre ese mes
     * @param from
     * @returns {any[]}
     */
    getWeeksDayOne(from) {
        let mDateFrom = moment(from.replace('-', ''));
        let startOfMonth = moment(mDateFrom).startOf('month');
        let endOfMonth   = moment(mDateFrom).endOf('month');
        //quitar esto si falla moment
        moment.suppressDeprecationWarnings = true;
        startOfMonth.locale('es');


        let weeks = {};
        while (startOfMonth.unix() < endOfMonth.unix()) {
            let isoWeek = startOfMonth.isoWeek();
            if (weeks[isoWeek] == null) {
                let monthName = startOfMonth.format("MMMM");
                let colspan = 0;
                let firstDay = startOfMonth.format("D");
                weeks[isoWeek] = { monthName, colspan, firstDay };
            }
            weeks[isoWeek].colspan++;
            startOfMonth = startOfMonth.add(1, "days");
        }
        let result = Object.values(weeks);
        return result;
    }
    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default DateUtil;
