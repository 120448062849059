import React, {Component} from 'react';
import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import apolloClient from "../storage/ApolloClientInstance";
import ApolloProxy from "../network/ApolloProxy";
import {gql} from "apollo-boost/lib/index";
import {observer} from "mobx-react/index";
import appState from "../state/AppState";
import util from "../util/Util";
import translate from "../translator/translate";
import AppLogger from "../util/AppLogger";
import {now} from "moment";
import DocumentModel from "../models/DocumentModel";
import SortableList from "./SortableList";
import arrayMove from "array-move";

let baseurl = "http://localhost:5000/api/document";

let documentAddQuery = async(fileKey) => {
    let query = gql`
        query DocumentAdd($fileKey:String){
            documentAdd(fileKey:$fileKey) {
                presignedURL,
                url,
                urlSource
            }
        }
    `;
    let variables = {
        fileKey: fileKey
    };

    let apolloProxy = new ApolloProxy(apolloClient);
    let resultQuery = await apolloProxy.graphQuery({ query, variables });
    let newData = resultQuery;
    return newData;
};

const images = require.context('../../public/img/fileicons', true);

const imagePath = (name) => images(name, true);


registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

@observer
class UploadFileComponentSortable extends Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();

        this.state = {
            files: [],
            select: "",
        };
    };

    handleInit() {
        this.pond?.current._pond.on("addfile", (error, file) => this.onAddedFile(error, file));
    }

    onAddedFile(error, file) {
        if (error) {
            return;
        }
        // this.log({ addfile: 1, file })
        // Set file metadata here in order to retrieve it in the custom process function
        // file attributes like fileExtension and filenameWithoutExtension
        // are not availabe in the file object in the custom process function
        file.setMetadata('fileInfo', {
            filenameWithoutExtension: file.filenameWithoutExtension,
            fileExtension: file.fileExtension
        });
    }


    getDocuments() {
        let result = [];
        if (this.props.documents != null) {
            if (this.props.documents?.length > 0) {
                for (let obj of this.props.documents) {
                    let document = new DocumentModel();
                    document.hidrate(obj);
                    if (!document.isFieldImage()) {
                        result.push(obj);
                    }
                }
            }
        }
        result = result.sort((a, b) => {
            let result = a.orderView - b.orderView;
            if (result == 0) {
                result = a.createdAt - b.createdAt;
            }
            return result;
        });
        return result;
    }

    addDocument({ name, url, urlSource, size }) {

        window.setTimeout(() => {
            let documentToAdd = { name, url, urlSource, size };
            if (this.props.documents && this.props.onChange == null) {
                this.props.documents.push(documentToAdd);
            }
            this.onChange(documentToAdd);
        }, 2000);
    }

    onChange(documentToAdd) {
        if (this.props.onChange != null) {
            this.props.onChange(documentToAdd);
        }
        if (this.props.onChangePrevious != null) {
            this.props.onChangePrevious();
        }
        appState.layoutState.formWithoutChanges = false;
    }

    async onSortEnd(event) {
        appState.layoutState.formWithoutChanges = false;
        let { oldIndex, newIndex } = event;

        //Obtenemos los documentos que son solo imagenes
        let images = this.getDocuments();
        //Reordenamos el array
        images = arrayMove(images, oldIndex, newIndex);

        //Establecemos el nuevo orden
        let newDocuments = [];
        for (let i = 0; i < images.length; i++) {
            let document = new DocumentModel();
            document.hidrate(images[i]);
            document.orderView = i;
            newDocuments.push(document);
        }

        //Añadimos el resto de documentos
        for (let obj of this.props.documents) {
            let document = new DocumentModel();
            document.hidrate(obj);
            if (document.isFieldImage() || document.field === "mainPhotoValidate") {
                newDocuments.push(obj);
            }
        }

        //this.log({ oldIndex, newIndex, images, newDocuments });

        this.props.setDocuments(newDocuments);
        this.onChange();
    };

    checkReadOnly() {
        let readOnly = false;
        if (this.props.model && !this.props.model?.editable) {
            readOnly = true;
        }
        return readOnly;
    }


    deleteDocument(document) {
        appState.layoutState.formWithoutChanges = false;
        let documents = this.props.documents;

        //Buscamos el indice a borrar
        let index = -1;
        for (let i = 0; i < documents.length; i++) {
            if ((util.hasValue(document.id) && documents[i].id === document.id)
                || (util.esVacio(document.id) && documents[i].name === document.name)) {
                index = i;
            }
        }

        //Borramos la imagen
        if (index !== -1) {
            documents.splice(index, 1);
        }

        this.props.setDocuments(documents);
        this.onChange();
    };


    getImageThumbnail(document) {
        let result = "";
        if (util.hasValue(document.url)) {
            let extensionesValidas = [];
            extensionesValidas.push(".png");
            extensionesValidas.push(".gif");
            extensionesValidas.push(".pdf");
            extensionesValidas.push(".mp4");
            extensionesValidas.push(".jpeg");
            extensionesValidas.push(".jpg");
            let hasThumbnail = false;
            for (let extension of extensionesValidas) {
                if (document.url.toLowerCase().includes(extension.toLowerCase())) {
                    hasThumbnail = true;
                }
                if ((util.getDocumentPhoto(document).isPDF() || util.getDocumentPhoto(document).isVideo()) && !util.hasValue(util.getDocumentPhoto(document).thumbnailx4)) {
                    hasThumbnail = false;
                }
            }
            if (hasThumbnail) {
                result = util.getDocumentPhoto(document).getThumbNailx4();
            } else {
                let iPath;
                try {
                    iPath = imagePath('./' + document.url.split('.').pop() + '.png');
                } catch (err) {
                    /*No encuentra un icono específico con el formato del archivo subido*/
                    iPath = imagePath('./undefined.png');
                }
                result = iPath;
            }
        }
        return result;
    };


    render() {
        let documents = this.getDocuments();
        let documentsWithFileNames = documents.filter(a => a.url != null);
        const t = translate;
        let props = this.props;
        let optionsSelectFile = [
            { label: "Back-End", value: "back-End", },
            { label: "Technical", value: "technical", },
            { label: "Video", value: "video", }
        ];
        let optionsSelectPhoto = this.props.optionsSelect || [
            { label: "Back-End", value: "back-End" },
            { label: "Technical", value: "technical", },
            { label: "Photo", value: "photo", },
            { label: "Video", value: "video", }
        ];
        let classGroup = props.classGroup;
        let label = t('Arrastre los ficheros aquí') + '</br>o</br><span class="filepond--label-action">' + t('Añadir ficheros') + '</span>';
        if (props.configForm) {
            classGroup = 'row';
            label = t('Arrastre el fichero aquí') + '</br>o</br><span class="filepond--label-action">' + t('Añadir fichero') + '</span>';
        }
        if (props.warrantyForm) {
            classGroup = 'row';
            label = t('Arrastre su fichero PDF aquí') + '</br>o</br><span class="filepond--label-action">' + t('Añadir fichero PDF') + '</span>';
        }
        let readOnly = this.checkReadOnly();
        return (
            <div className={classGroup} style={{ flex: 1 }}>
                <div className={props.configForm ? "col-6" : "col-12"}>
                    {!readOnly &&
                    <>
                        <FilePond
                            ref={this.pond} onprocessfiles={() => this.onprocessfiles()}
                            acceptedFileTypes={['video/*', 'image/*', 'application/pdf', 'application/doc', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                            server={this.getServer()} labelTapToUndo={""}
                            labelFileProcessingComplete={t("Archivo subido")}
                            labelFileProcessing={t("Subiendo archivo")} labelTapToCancel={""}
                            oninit={() => this.handleInit()}
                            labelIdle={label}
                            allowMultiple={true}
                            credits={false}
                        />

                        <label htmlFor={props.name}
                               className="control-label">
                            {props.title}
                        </label>
                    </>
                    }

                </div>
                <div
                    className={props.configForm ? "col-6 uploaded-elements-container d-flex" : "col-12 uploaded-elements-container d-flex"}>

                    <SortableList
                        items={documentsWithFileNames}
                        axis={"xy"}
                        axisClass={true}
                        onSortEnd={e => this.onSortEnd(e)}
                        useDragHandle={true}
                        showDragHandle={true}
                        classGroup="uploaded-image"
                        pressDelay={50}
                        renderItem={(obj, index) => this.renderSortableItem(obj, index)}
                    />
                </div>
            </div>
        )
            ;
    }

    renderSortableItem(document, index) {
        const t = translate;
        let readOnly = this.checkReadOnly();

        return (
            <div key={index} className="uploaded-elements-container__file mr-3 mb-3">
                <div className="fileItem">
                    {!readOnly &&
                    <div className="d-flex justify-content-between align-content-center">
                        <div className="fileItem-header" title={document.name}>
                            <a className="download-file" target="_blank" href={document.url}>
                                <span>{document.name}</span>
                            </a>
                        </div>
                        <span onClick={() => this.deleteDocument(document)} className="text-click delete-file"><i
                            className="fa fa-trash-alt"> </i></span>
                    </div>
                    }
                    <div className="fileItem-content">
                        <div>
                            <a className="download-file" target="_blank" href={document.url}>
                                {util.getDocumentPhoto(document).isPDF() &&
                                <div className="fileItem-content__tag">
                                    <img src="/img/pdftag.png" alt="pdf" />
                                </div>
                                }
                                {util.getDocumentPhoto(document).isVideo() &&
                                <div className="fileItem-content__tag">
                                    <span className="fas fa-play" />
                                </div>
                                }
                                <img alt={document.name} src={this.getImageThumbnail(document)} />
                            </a>
                        </div>
                    </div>
                    <div className="fileItem-footer">
                        {document.createdAt ?
                            <span>{util.getDateWithoutHours(document.createdAt)}</span>
                            :
                            <span>{util.getMoment(now()).format("DD/MM/YYYY")}</span>
                        }
                        <div className="fileItem-footer__right">
                            <span>{/*document.field || "document"*/}</span>
                            <span className="size">{util.formatBytes(document.size)}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    isImage(documentJS) {
        let document = new DocumentModel();
        document.hidrate(documentJS);
        return document.isImage();
    }


    onChangeFileType(obj, document) {
        document.field = obj.value;
        this.onChange(document);
        this.setState({ select: "" })
    }

    onprocessfiles() {
        setTimeout(() => {
            this.pond.current._pond.removeFiles();
        }, 1500);
    }

    getServer() {
        let me = this;
        let baseFolder = this.props.baseFolder;
        // let log = (msg) => this.log(msg);
        let log = () => {
        };
        let server = {
            url: baseurl,
            process: function (fieldName, file, metadata, load, error, progress, abort) {
                var filepondRequest = new XMLHttpRequest({
                    contentType: 'binary/octet-stream',
                    processData: false,
                });
                log({ fieldName, file, metadata, load, error, progress, abort });
                let fileKey = baseFolder + "/" + file.name;
                let signedResult = documentAddQuery(fileKey);
                signedResult.then((response) => {
                    log({ signedResult: 1, response });
                    try {
                        let presignedUrl = response.data.documentAdd.presignedURL;
                        let urlSource = response.data.documentAdd.urlSource;
                        let url = response.data.documentAdd.url;
                        var filepondFormData = new FormData();
                        filepondFormData.append("file", file);
                        filepondRequest.upload.onprogress = function (e) {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
                        filepondRequest.open("PUT", presignedUrl);
                        filepondRequest.onload = function () {
                            load(`${"file.name"}`);
                            log({ Loaded: 1, file });
                            me.addDocument({ name: file.name, url, urlSource, size: file.size, field: "document" });
                        };
                        let binaryFile = filepondFormData.get("file");
                        filepondRequest.send(binaryFile);
                    } catch (e) {
                        log("Error uploading file=>");
                        log({ e });
                    }
                })

            }
        };
        return server;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

/*
Property	Default	Description
allowFileSizeValidation	true	Enable or disable file size validation
minFileSize	null	The minimum size of a file, for instance 5MB or 750KB
maxFileSize	null	The maximum size of a file, for instance 5MB or 750KB
maxTotalFileSize	null	Maximum size of all files in list, same format as maxFileSize
labelMaxFileSizeExceeded	'File is too large'	Status message shown when large file is dropped.
labelMaxFileSize	'Maximum file size is {filesize}'	Detail message shown when max file size was exceeded. {filesize} is replaced with the value of the maxFileSize property.
labelMaxTotalFileSizeExceeded	'Maximum total size exceeded'	Status message shown when total file size exceeds maximum.
labelMaxTotalFileSize	'Maximum total file size is {filesize}'	Detail message shown then total file size exceeds maximum.
*/

export default UploadFileComponentSortable
