import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import translate from "../../translator/translate";
import util from '../../util/Util';
import appState from "../../state/AppState";

@observer
class OrderFormSlotClientsPending extends Component {
    constructor(props) {
        super(props);
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
    }

    render() {
        let slot = this.props.row;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let usuario = appState.userCacheState.getUserById(slot.userId);
        let icons = [];
        if (util.hasValue(usuario.capacitationIds)) {
            for (let capacitation of usuario.capacitationIds.split(',')) {
                if (appState.typifiedState.getObject(capacitation)) {
                    icons.push(appState.typifiedState.getObject(capacitation));
                }
            }
        }
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <div className="col-6 d-flex">
                    <div className="medium blue">
                        {slot.userId != null ?
                            ((usuario.firstName ? usuario.firstName : "") + " " +
                                (usuario.lastName ? usuario.lastName : ""))
                            :
                            t("Sin técnico asignado")
                        }
                    </div>
                    <span className="blue"> {": "}</span>
                    <div className="blue">
                        &nbsp;
                        {util.hasValue(slot.scheduledTime) ? util.localizeIsoDateMinutes(slot.scheduledTime) : " "}
                    </div>
                </div>
            </InfoBlock>
        );
    }

}

export default withRouter(OrderFormSlotClientsPending)
