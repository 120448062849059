import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import DynamicGroupModel from "../models/DynamicGroupModel";


class DynamicsState extends BaseModelState {

    @observable dynamics = [];
    @observable dynamicsFieldsDict = {};

    async loadDynamics() {
        let dynamicQuery = new DynamicGroupModel();
        dynamicQuery.addRelatedTable("dynamicField");
        this.dynamics = await dynamicQuery.find();
        this.getDictDynamicFields();
    }

    getDictDynamicFields() {
        let dynamicsFieldsDict = {};
        for (let dynamicGroup of this.dynamics) {
            for (let dynamicField of dynamicGroup.dynamicField) {
                dynamicsFieldsDict[dynamicField.code] = { ...dynamicField, codeGroup: dynamicGroup.code };
            }
        }
        this.dynamicsFieldsDict = dynamicsFieldsDict;
        // this.log({ dynamicsFieldsDict })
    }


    inputsAssetsOrder(notifType) {
        let result = [];
        let tabla = {
            "FA": ['claddingModel1', 'valveModel1',
                'sapInstallDate', 'sapCodeProblem'],
            "HC": ['sapEquipmentToRemove ',
                'subColdEquipment ', 'sapStatus']
        };
        if (tabla[notifType]) {
            result = tabla[notifType]
        }
        return result
    }


}

export default DynamicsState;
