import React, {Component} from 'react';
import {observer} from 'mobx-react';
import util from "../../util/Util";
import PropTypes from "prop-types";
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";

@observer
class CreatedByListItem extends Component {

    static defaultProps = { noAsignadoTexto: " - " };

    static propTypes = {
        noAsignadoTexto: PropTypes.string,
    };


    render() {
        const t = translate;
        let props = this.props;
        let value = props.value || "";
        if (props.concatLastName) {
            value += " " + (props.row[props.name?.split('.')[0]]?.lastName || "");
        }
        if (!util.hasValue(value)) {
            value = t(this.props.noAsignadoTexto);
        }

        return (
            <div
                className={util.hasValue(this.props.onClick) && util.hasValue(props.value) ? "text-click text-underline" : ""}
                onClick={() => {
                    if (util.hasValue(this.props.onClick) && util.hasValue(props.value)) {
                        this.props.onClick(props.row)
                    }
                }}>{value}</div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default CreatedByListItem
