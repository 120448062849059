import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import ClientModel from "../models/ClientModel";
import util from "../util/Util";

class ClientState extends BaseModelState {

    @observable isPos;
    @observable isClient;
    @observable clients=[];
    @observable client={};

    async reloadClientFromClientId(clientId) {
        let clientQuery = new ClientModel();
        clientQuery.addRelatedTable('parentClient');
        clientQuery.id = clientId;
        if (util.hasValue(clientQuery.id)) {
            let result = await clientQuery.findFirst();
            if (result == null) {
                result = [];
            }
            this.client = result;
        }
    }

}

export default ClientState;
