import AppLogger from "../../../util/AppLogger";

export default class BaseValidation {

    event;

    validate(name, inputValue, args) {
        return true;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
