import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import appState from "../state/AppState";
import Overlay from "../components/modals/Overlay";
import VsfLink from "../components/VsfLink";
import AppLogger from "../util/AppLogger";
import util from '../util/Util'
import {Animated} from "react-animated-css";
import VsfButtonNew from "../components/VsfButtonNew";
import translate from "../translator/translate";

@observer
class ProfileButton extends Component {

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        const t = translate;
        let mail = localStorage.getItem('mail');
        let user = appState.loginState.user;
        return (
            <div style={{marginLeft: '12px'}}>
                {/*Botón Usuario*/}
                <VsfButtonNew type="button" className="user-dropdown btn btn--user"
                        onClick={() => appState.layoutState.clickUser()}>
                <span className="user-avatar">
                    {util.hasValue(user?.photoProfile?.id) ?
                        <img src={util.getDocumentPhoto(user?.photoProfile)?.getThumbNailx2()} alt="UserImg"
                             className={'user-avatar'}/> : <i className="fa fa-user"/>
                    }
                 </span>
                </VsfButtonNew>

                {appState.layoutState.user &&
                <Animated style={{zIndex: 100, minWidth: 25, position: 'absolute', top: 10}}
                          animationInDelay={150} animationIn="zoomIn" animationInDuration={250}
                          isVisible={true}>
                    <Overlay show={appState.layoutState.user}
                             style={{backgroundColor: 'transparent'}}
                             onClick={_ => appState.layoutState.user = false}/>
                    <dl className="dropdown-sublist" style={{ top: 10, width: 220 }}>
                        <div className="d-flex justify-content-center mr-0">
                    <span className="user-avatar">
                    {util.hasValue(user?.photoProfile?.id) ?
                        <img src={util.getDocumentPhoto(user?.photoProfile)?.getThumbNailx2()} alt="UserImg"
                             className={'user-avatar'}/>
                        :
                        <i className="fa fa-user"/>
                    }
                    </span>
                        </div>
                        <dt className="dropdown-sublist__head" style={{
                            color: '#576267', display: 'flex', justifyContent: 'center'
                        }}>
                            {mail}
                        </dt>
                        <dd className="dropdown-sublist__item"><VsfLink
                            onClick={_ => appState.layoutState.user = false}
                            to={"/profile/form/"}>{t('Perfil')}</VsfLink>
                        </dd>
                        <dd className="dropdown-sublist__item"><VsfLink
                            onClick={_ => appState.layoutState.user = false}
                            to="/change/password">{t('Cambiar Contraseña')}</VsfLink>
                        </dd>
                        <dd className="dropdown-sublist__item"><a onClick={(e) => {
                            appState.loginState.doLogout()
                        }}>{t("Cerrar Sesion")}</a></dd>
                    </dl>
                </Animated>

                }
            </div>
        );
    }

}

export default withRouter(ProfileButton)

