import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import WorkOrderModel from "../../models/WorkOrderModel";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import WorkOrderActions from "./WorkOrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import WorkOrderSummaryWorkLogs from "./WorkOrderSummaryWorkLogs";
import {computed, observable} from "mobx";
import appState from '../../state/AppState';

@observer
class WorkOrderSummary extends BaseForm {
    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true,
        }
    }

    @observable slots = [];

    @computed get mobxListado() {
        return this.slots;
    }

    setMobxListado(newValue) {
        this.slots = newValue;
    }

    setHasSomeRowEditing(newHasSomeRowEditing) {
        this.setState({
            hasSomeRowEditing: newHasSomeRowEditing
        });
        //Si es false quito el isEditing De todos los contactos
        if (!newHasSomeRowEditing) {
            for (let row of this.mobxListado) {
                row.isEditing = false;
            }
        }
    };


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.formAssignmentState.getFormsAssignments();
        await appState.userCacheState.loadUserCacheTechnicals();
        this.loadTable()
        this.setState({ loading: false })
    }

    loadTable() {
        let miSlot = [];
        if (this.graphDataMainType?.order?.slot?.length > 0) {
            for (let slot of this.graphDataMainType.order?.slot) {
                if (util.hasValue(slot?.userId)) {
                    slot.isEditing = false;
                    if (util.perteneceA(this.graphDataMainType.id, slot.workOrderIds)) {
                        miSlot.push(slot);
                    } else if (util.perteneceA("-1", slot.workOrderIds)) {
                        miSlot.push(slot);
                    }
                }
            }
        }
        this.setMobxListado(miSlot)
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("documents");
        result.addRelatedTable("budget");
        result.addRelatedTable("order");
        result.addRelatedTable("order.slot");
        result.addRelatedTable("order.slot.workLog");
        result.addRelatedTable("workLog");
        result.addRelatedTable("client");
        result.addRelatedTable("asset");
        result.addRelatedTable("asset.assetModel");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let workOrder = this.graphDataMainType;
        const t = translate;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                      getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Visitas"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                  getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">

                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Visitas"}
                            />
                            }

                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                {this.mobxListado?.map((slot, index) => (
                                    <div className={"row"} key={index}>
                                        <WorkOrderSummaryWorkLogs
                                            workLogs={workOrder.workLog}
                                            workOrder={workOrder}
                                            slot={slot}
                                            workOrderCartArticles={workOrder.workOrderCartArticle}
                                            hasSomeRowEditing={this.state.hasSomeRowEditing}
                                            setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                            reloadData={async() => {
                                                await this.reloadData();
                                                this.loadTable();
                                            }}
                                        />
                                    </div>
                                ))}
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(WorkOrderSummary);
