import React, { Component } from 'react'
import MultiModel from '../../models/MultiModel'
import ReportModel from '../../models/ReportModel'
import appState from '../../state/AppState'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import util from '../../util/Util'
import SlotModel from '../../models/SlotModel'
import UserModel from '../../models/UserModel'
import WorkOrderModel from '../../models/WorkOrderModel'
import BudgetModel from '../../models/BudgetModel'
import ChangesWithValidationsModel from '../../models/ChangesWithValidationsModel'
import YesOrNoEnum from '../../models/enum/YesOrNoEnum'
import OrderModel from '../../models/OrderModel'

let timeoutLoadData

export default class BaseAlertsButtons extends Component {

  async componentDidMount () {
    if (util.hasValue(this.props.tenantId)) {
      if (appState.userState.isUserRoleAdminOrSuperAdmin()) {
        await this.loadData()
      }
    }
  }

  MINUTES_BETWEEN_UPDATES = 5

   getQuerys () {
    let arrayQuerys = []
    arrayQuerys.push(this.loadSalesOrderFailed())
    arrayQuerys.push(this.loadSlotsWithoutStartDay())
    arrayQuerys.push(this.loadWorkOrdersReturned())
    arrayQuerys.push(this.loadWorkOrdersReturnedFollowed())
    arrayQuerys.push(this.loadWorkOrdersPossibleWarranty())
    arrayQuerys.push(this.loadBudgetsAccepted())
    arrayQuerys.push(this.loadBudgetsPending())
    arrayQuerys.push(this.loadBudgetsExpired())
    arrayQuerys.push(this.loadBudgetsDenied())
    arrayQuerys.push(this.loadValidations())
    arrayQuerys.push(this.loadWorkOrderCommentsNotValidated())
    arrayQuerys.push(this.loadPdtApproval())
    arrayQuerys.push(this.loadBudgetBorrador())
    arrayQuerys.push(this.loadBudgetPendingBudget())
    arrayQuerys.push(this.loadBudgetNotApproved())
    arrayQuerys.push(this.loadNotApproved())
    if (appState.userState.hasUserActionsEndDay) {
      arrayQuerys.push(this.techniciansWithoutEndDayQuery())
    }
    return arrayQuerys
  }

  async loadData () {
    let multiModel = new MultiModel()
    let arrayQuerys = this.getQuerys()
    let [salesOrderResult, slotsWithoutStarResult, workOrdersReturned,
      workOrdersReturnedFollowed, workOrdersPossibleWarranty, budgetsAccepted,
      budgetsPending, budgetsExpired, budgetsDenied, validations, commentsValidated, pdtApproval, budgetPending, orderBudgetPending, orderBudgetNotApproved,notApproved,
      techsWithoutEndDay] = await multiModel.find(arrayQuerys)
    let reportQuery = new ReportModel()
    let reportResult = await reportQuery.findByIdNotNull('-')
    appState.alertState.techniciansWithoutScheduledToday = reportResult.techniciansWithoutScheduledTodayCount
    let techniciansWithoutScheduledToday = reportResult.techniciansWithoutScheduledToday.split(',')
    let techniciansWithoutScheduledTodayDict = {}
    for (let tech of techniciansWithoutScheduledToday) {
      techniciansWithoutScheduledTodayDict[tech] = tech
    }
    appState.alertState.techniciansWithoutScheduledTodayDict = techniciansWithoutScheduledTodayDict
    appState.alertState.salesOrderError = salesOrderResult.length
    appState.alertState.slotsWithoutStartDay = slotsWithoutStarResult.length
    appState.alertState.workOrdersReturned = workOrdersReturned.length
    appState.alertState.workOrdersReturnedFollowed = workOrdersReturnedFollowed.length
    appState.alertState.workOrdersPossibleWarranty = workOrdersPossibleWarranty.length
    appState.alertState.budgetsAccepted = budgetsAccepted.length
    appState.alertState.budgetsPending = budgetsPending.length
    appState.alertState.budgetsExpired = budgetsExpired.length
    appState.alertState.budgetsDenied = budgetsDenied.length
    appState.alertState.validations = validations.length
    appState.alertState.commentsValidated = commentsValidated.length
    appState.alertState.pdtApproval = pdtApproval.length
    appState.alertState.budgetPending = budgetPending.length
    let budgets = orderBudgetPending.length
    let count = 0
    if (budgets.length > 0) {
      for (let budget of budgets) {
        if (budget.order?.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'BUDGET_PENDING').id) {
          count++
        }
      }
    }
    appState.alertState.orderBudgetPending = count
    let orderBudgets = orderBudgetNotApproved.length
    let countOrderBudgetNotApproved = 0
    if (orderBudgets.length > 0) {
      for (let budget of orderBudgets) {
        if (budget.order?.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'BUDGET_PENDING').id
        ) {
          countOrderBudgetNotApproved++
        }
      }
    }
    appState.alertState.orderBudgetNotApproved = countOrderBudgetNotApproved
    appState.alertState.notApproved = notApproved.length
    if (appState.userState.hasUserActionsEndDay) {
      this.techniciansWithoutEndDay(techsWithoutEndDay)
    }
    if (timeoutLoadData != null) {
      clearTimeout(timeoutLoadData)
    }
    timeoutLoadData = setTimeout(() => (
      this.loadData()
    ), this.MINUTES_BETWEEN_UPDATES * (1000 * 60))
  }

  techniciansWithoutEndDay (usersTechs) {
    let result = 0
    for (let user: UserModel of usersTechs) {
      if (util.hasValue(user.lastStartDay) || util.hasValue(user.lastEndDay)) {
        let lastStartDay = util.getMoment(user.lastStartDay)
        if (!util.hasValue(user.lastStartDay)) {
          lastStartDay = util.getMoment()
        }
        let lastEndDay = util.getMoment(user.lastEndDay)
        if (!util.hasValue(user.lastEndDay) || (lastStartDay.format('YYYYMMDDHHmm') > lastEndDay.format('YYYYMMDDHHmm'))) {
          user.lastEndDay = ''
          lastEndDay = util.getMoment()
          let total = lastEndDay.diff(lastStartDay, 'hours')
          if (total > 16) {
            result++
          }
        }
      }
    }
    this.techsWithoutEndDay = result
  }

  techniciansWithoutEndDayQuery () {
    let usersTechQuery = new UserModel()
    usersTechQuery.filters.push({
      'fieldName': 'role',
      'fieldValue': appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, 'TECHNICIAN').id,
      'filterOperator': 'INLIST'
    })
    return usersTechQuery
  }

  loadSlotsWithoutStartDay () {
    let slotsQuery = new SlotModel()
    slotsQuery.filters.push({
        'fieldName': 'scheduledTime',
        'fieldValue': util.getMoment(),
        'filterOperator': 'LTEQ'
      },
      {
        'fieldName': 'scheduledTime',
        'fieldValue': util.getMoment().add(-7, 'day'),
        'filterOperator': 'GTEQ'
      },
      {
        'fieldName': 'isStarted',
        'fieldValue': 0,
        'filterOperator': 'EQ'
      },
    )
    return slotsQuery
  }

  loadWorkOrdersReturned () {
    let workOrderQuery = new WorkOrderModel()
    workOrderQuery.filters.push({
        'fieldName': 'status',
        'fieldValue': appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, 'RETURN_WORK_ORDER').id,
        'filterOperator': 'EQ'
      },
    )
    return workOrderQuery
  }

  loadWorkOrdersReturnedFollowed () {
    let workOrderQuery = new WorkOrderModel()
    workOrderQuery.filters.push({
        'fieldName': 'hasReturned',
        'fieldValue': 'Y',
        'filterOperator': 'EQ'
      },
      {
        'fieldName': 'status',
        'fieldValue': appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, 'END_WORK_ORDER').id,
        'filterOperator': 'NEQ'
      },
    )
    return workOrderQuery
  }

  loadWorkOrdersPossibleWarranty () {
    let workOrderQuery = new WorkOrderModel()
    workOrderQuery.filters.push({
        'fieldName': 'warrantyPossible',
        'fieldValue': 'Y',
        'filterOperator': 'EQ'
      },
      {
        'fieldName': 'warrantyRevised',
        'fieldValue': 'N',
        'filterOperator': 'EQ'
      },
    )
    return workOrderQuery
  }

  loadWorkOrderCommentsNotValidated () {
    let query = new WorkOrderModel()
    query.filters.push({
        'fieldName': 'commentsValidated',
        'fieldValue': YesOrNoEnum.NO,
        'filterOperator': 'EQ'
      },
    )
    return query
  }

  loadBudgetsAccepted () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
        'fieldName': 'status',
        'fieldValue': BudgetModel.CODE_APPROVED,
        'filterOperator': 'EQ'
      },
    )
    return budgetQuery
  }

  loadBudgetsPending () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
        'fieldName': 'status',
        'fieldValue': BudgetModel.CODE_PENDING_ERP,
        'filterOperator': 'EQ'
      },
    )
    return budgetQuery
  }

  loadBudgetsExpired () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
        'fieldName': 'status',
        'fieldValue': BudgetModel.CODE_PENDING_ERP,
        'filterOperator': 'EQ'
      },
      {
        'fieldName': 'endDateBudget',
        'fieldValue': util.getMoment(),
        'filterOperator': 'GTEQ'
      },
      {
        'fieldName': 'endDateBudget',
        'fieldValue': util.getMoment().add(5, 'day'),
        'filterOperator': 'LTEQ'
      },
    )
    return budgetQuery
  }

  loadValidations () {
    let validatesQuery = new ChangesWithValidationsModel()
    validatesQuery.approved = ChangesWithValidationsModel.PENDING
    validatesQuery.orderBy = 'createdAt'
    validatesQuery.orderMode = 'DESC'

    return validatesQuery
  }

  loadBudgetsDenied () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
        'fieldName': 'status',
        'fieldValue': BudgetModel.CODE_NOT_APPROVED,
        'filterOperator': 'EQ'
      },
    )
    return budgetQuery
  }

  /**
   * Aqui cargan los dispatcher
   * @returns {Promise<void>}
   */
  loadSalesOrderFailed () {
    let twoDays = util.getMoment().add(-7, 'day')
    let date = util.getMomentFromDateWithoutTimezone(twoDays.format('YYYY-MM-DDTHH:mm:ss')).toISOString()
    let slotQuery = new SlotModel()
    slotQuery.filters.push({
        'fieldName': 'integrationErpStatus',
        'fieldValue': SlotModel.CODE_INTEGRATION_FAIL,
        'filterOperator': 'EQ'
      },
      {
        'fieldName': 'integrationErpDate',
        'fieldValue': date,
        'filterOperator': 'GTEQ'
      })
    return slotQuery
  }

  /**
   * Aqui cargan los dispatcher
   * @returns {Promise<void>}
   */
  loadPdtApproval () {
    let orderQuery = new OrderModel()
    orderQuery.filters.push({
      'fieldName': 'status',
      'fieldValue': appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'PDT_APPROVED').id,
      'filterOperator': 'STRIN'
    })
    return orderQuery
  }

  /**
   * Aqui cargan los clientes
   * @returns {Promise<void>}
   */
  loadBudgetBorrador () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
      'fieldName': 'status',
      'fieldValue': BudgetModel.CODE_BORRADOR,
      'filterOperator': 'STRIN'
    })
    return budgetQuery
  }

  /**
   * Aqui cargan los dispatcher
   * @returns {Promise<void>}
   */
  loadBudgetPendingBudget () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
      'fieldName': 'status',
      'fieldValue': BudgetModel.CODE_APPROVED,
      'filterOperator': 'STRIN'
    })
    budgetQuery.addRelatedTable('order')
    return budgetQuery
  }

  /**
   * Aqui cargan los dispatcher
   * @returns {Promise<void>}
   */
  loadBudgetNotApproved () {
    let budgetQuery = new BudgetModel()
    budgetQuery.filters.push({
      'fieldName': 'status',
      'fieldValue': BudgetModel.CODE_NOT_APPROVED,
      'filterOperator': 'STRIN'
    })
    budgetQuery.addRelatedTable('order')
    return budgetQuery
  }

  /**
   * Aqui cargan los clientes
   * @returns {Promise<void>}
   */
  loadNotApproved () {
    let orderQuery = new OrderModel()
    orderQuery.filters.push({
      'fieldName': 'status',
      'fieldValue': appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'NOT_APPROVED').id,
      'filterOperator': 'STRIN'
    })
    return orderQuery
  }

}