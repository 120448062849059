import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Toast from 'react-bootstrap/Toast'
import ToastHeader from 'react-bootstrap/ToastHeader'
import ToastBody from 'react-bootstrap/ToastBody'

// import 'bootstrap/dist/css/bootstrap.min.css';

@observer
class ToastBootstrap extends Component {


    onCloseModal() {
        this.props.onCloseModal();
    }

    render() {
        let isOpen = this.props.isOpen;
        if (isOpen) {
            return (
                <div className="toast-fixed">
                    {isOpen &&
                    <div className={"toast-style"}>
                        <Toast bsPrefix={this.props.success ? "toast bg-success" : "toast bg-primary"}
                               onClose={() => this.onCloseModal()} show={isOpen} delay={3000}
                               autohide={this.props.autohide}>
                            <ToastHeader style={{ border: 0 }}>
                                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                                <div className={"toast-div"}>
                                    <strong className="mr-auto">{this.props.title}</strong>
                                    <small>{this.props.time}</small>
                                </div>
                            </ToastHeader>
                            {this.props.description &&
                            <ToastBody>{this.props.description}</ToastBody>
                            }
                        </Toast>

                    </div>
                    }
                </div>
            );
        } else return null;

    }
}

export default ToastBootstrap;







