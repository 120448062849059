import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import BaseForm from '../base/BaseForm'
import translate from '../../translator/translate'
import { observable } from 'mobx'
import MoreButtons from '../../components/MoreButtons'
import appState from '../../state/AppState'
import AssetModel from '../../models/AssetModel'
import WorkLogModel from '../../models/WorkLogModel'
import AssetActions from './AssetActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'

@observer
class AssetHistoryTech extends BaseForm {
    @observable workLogs = {};

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "asset";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }

    getModelQuery() {
        return new AssetModel();
    }

    getModelQueryForView() {
        let result = new AssetModel();
        result.addRelatedTable("workLog");
        result.addRelatedTable("documents");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let asset = this.graphDataMainType;
        const t = translate;
        let workLogModel = new WorkLogModel();
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.type).config)
        }

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}
                                onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Histórico comentarios"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            onReloadData={() => this.reloadData()}
                            title={this.getPageTitle()}
                            subtitle={t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Histórico comentarios"}
                            />
                            }
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className=" history-container">
                                    <div className="history-list-container">
                                        <div className="history-list-rows">
                                            {workLogModel.getWorkLogsFromThisSlot(this.graphDataMainType, WorkLogModel.ASSET_COMMENT, "")?.length == 0 &&
                                            <div
                                                className="history-list-item-updated">{t('No se ha comentado en el equipo')}
                                            </div>
                                            }
                                            {workLogModel.getWorkLogsFromThisSlot(this.graphDataMainType, WorkLogModel.ASSET_COMMENT, "")?.map((workLog) => {
                                                return (
                                                    <div className="history-list-item">
                                                        <div className="history-change modified">
                                                            <span
                                                                className="history-change-title history-change-item modified">{t("Comentario del técnico")}</span>
                                                            <div className="history-change-item">
                                                                {t("Comentario del equipo")}
                                                                {this.renderData(workLog, "ASSET_COMMENT")}
                                                            </div>

                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </SpinnerClipLoaderInPage>

                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

    renderData(workLog, action) {
        let workLogModel = new WorkLogModel();
        return (
            <div className={"row"}>
                {(util.hasValue(workLog.data) && JSON.parse(workLog.data)[workLogModel.nameForData(action)]) &&
                <>
                    <p key={workLog.id} style={{
                        color: '#6C757D',
                        fontSize: 12, textTransform: 'uppercase'
                    }}>{util.getMoment(workLog.time).format("YYYY-MM-DD HH:mm")}</p>
                    {JSON.parse(workLog.data)[workLogModel.nameForData(action)].reverse().map(obj => (
                        <p style={{ marginBottom: 6, color: '#6C757D', fontSize: 12, paddingLeft: 5 }}
                            key={obj.id}>
                            {util.hasValue(obj.title) ? obj.title + " - " : ""}
                            {util.hasValue(obj.code) && obj.code + " - "}
                            {obj.value}
                            {util.hasValue(obj.comment) && " - " + obj.comment}
                        </p>
                    ))}
                </>
                }
            </div>
        )
    }


}

export default withRouter(AssetHistoryTech);
