import React from 'react';
import {observable, toJS} from "mobx";
import {observer} from "mobx-react";
import appState from "../state/AppState";

import './../scss/debug.css';
import debug from "../util/debug";

@observer
class DebugObjects extends React.Component {

    @observable pathsExpanded=[];

    constructor(props) {
        super(props);
        //Cada vez que montemos debug reiniciamos el log
        appState.debugObjects={};
        //makeObservable(this);
    }

    async componentDidMount() {
    }

    /*
    componentWillReceiveProps(nextProps, nextContext) {
        debug.d({nextProps});
    }
    */

    async loadView(tab:String) {
    }

    isExpanded(arrPathAcu) {
        let result=false;
        let key = arrPathAcu.join("||");
        if (this.pathsExpanded.includes(key) || key==="") {
            result=true;
        }
        return result;
    }

    removeItemWithSlice(items,index) {
        return [...items.slice(0, index), ...items.slice(index + 1)]
    }

    onToggle(event, arrPathAcu) {
        event.stopPropagation();
        if (event.ctrlKey) {

        } else {
            this.onToggleSingle(arrPathAcu);
        }
    }

    onToggleSingle(arrPathAcu) {
        let key = arrPathAcu.join("||");
        if (key!=="") {
            if (this.pathsExpanded.includes(key)) {
                let index = this.pathsExpanded.indexOf(key);
                if (index > -1) {
                    this.pathsExpanded = this.removeItemWithSlice(this.pathsExpanded, index);
                }
            } else {
                this.pathsExpanded.push(key);
            }
        }
        //console.log({"onToggle onToggle:":arrPathAcu, pathsExpandedResult:toJS(this.pathsExpanded)});
    }

    render() {
        let debugObjectsPlain = toJS(appState.debugObjects);
        //console.log({debugObjectsPlain});
        if (Object.keys(appState.debugObjects)==0) {
            return null;
        }
        return (
            <pre className="sf-dump">
                {this.renderRecursive({debugObjects: debugObjectsPlain}, [])}
            </pre>
        );
    }

    getTextObjectHeader(object) {
        let txtIncluido="";
        if (object===undefined) {
            txtIncluido="undefined";
        }
        if (object===null) {
            txtIncluido="null";
        }
        if (typeof object==="function") {
            txtIncluido="function";
        }
        if (Array.isArray(object) || typeof object==="object") {
            let nameClassObject = object.constructor.name;
            if (nameClassObject==null) {
                nameClassObject="Object";
            }
            txtIncluido = nameClassObject+":" + Object.keys(object).length;

            if (Array.isArray(object)) {
                txtIncluido = "Array:" + object.length + "";
            }
        }
        return txtIncluido;
    }

    regOnlyDigits = /^\d+$/;

    getKeysForOrder(a) {

        let trozos=a.split(".");
        let binNumberWithZeoroes="";
        for(let trozo of trozos) {
            if (this.regOnlyDigits.test(trozo)) {
                binNumberWithZeoroes += this.numDigitos(trozo, 6) + ".";
            } else {
                binNumberWithZeoroes += trozo+".";
            }
        }
        //console.log({a,binNumberWithZeoroes});
        return binNumberWithZeoroes;
    }

    getKeysOrdered(object) {
        let keys = Object.keys(object);
        keys.sort((a,b)=> this.getKeysForOrder(a)<this.getKeysForOrder(b) );
        //console.log({keys});
        return keys;
    }

    numDigitos(numero, size) {
        let i;
        let res = numero;
        for (i = 0; i < size; i++) res = "0" + res;
        return this.right(res, size);
    }

    right(cadena, size) {
        return cadena.substring(cadena.length - size);
    }

    renderRecursive(object, pathAcu) {
        //console.log("renderRecursive");
        if (object===undefined) {
            return object;
        }
        if (object===null) {
            return object;
        }
        if (typeof object==="string") {
            return object;
        }
        if (typeof object==="number") {
            return object;
        }
        if (typeof object==="boolean") {
            return object;
        }
        if (typeof object==="function") {
            return object;
        }
        if (Array.isArray(object) || typeof object==="object") {
            let keys;
            if (pathAcu.length==1) {
                //Raiz. no hijos
                keys = this.getKeysOrdered(object);
            } else {
                keys = Object.keys(object);
            }
            if (!this.isExpanded(pathAcu)) {
                return (
                    <></>
                );
            } else {
                return (
                    <ul className="sf-dump-list">
                        {keys.map(key => {
                            let recursiveCall=this.renderRecursive(object[key], [...pathAcu, key]);
                            let recursiveCallInitial=recursiveCall;
                            let isFinal=false;
                            if (recursiveCall===undefined) {
                                isFinal = true;
                                recursiveCall = <span className="sf-dump-num">undefined</span>
                            } else if (recursiveCall===null) {
                                    isFinal=true;
                                    recursiveCall=<span className="sf-dump-num">null</span>
                            } else if (typeof recursiveCall==="string") {
                                isFinal=true;
                                recursiveCall=<>
                                    "
                                    <span className="sf-dump-str">{recursiveCall}</span>
                                    "
                                </>
                            } else if (typeof recursiveCall==="number") {
                                isFinal=true;
                                recursiveCall=<span className="sf-dump-num">{recursiveCall}</span>
                            } else if (typeof recursiveCall==="function") {
                                isFinal=true;
                                recursiveCall=<span className="sf-dump-function">function</span>
                            } else if (typeof recursiveCall==="boolean") {
                                isFinal=true;
                                recursiveCall=<span className="sf-dump-boolean">{recursiveCall?"true":"false"}</span>
                            }
                            return (
                                <li key={pathAcu.join("|") + key}
                                    id={"debug-" + pathAcu.join("|") + key}
                                >
                                    {!isFinal &&
                                        <span className="hand" onClick={(event) => this.onToggle(event,[...pathAcu, key])}>
                                            <span className="sf-dump-key">{key}</span>:&nbsp;
                                            {this.isExpanded([...pathAcu, key]) &&
                                                <span id={"debug-"+[...pathAcu, key].join("|")}
                                                      className="hand"
                                                >
                                                    <span className="sf-dump-note">{this.getTextObjectHeader(object[key])}&nbsp;</span>
                                                    <span data-depth="1" className="sf-dump-expanded">
                                                        [<a
                                                        className="sf-dump-ref sf-dump-toggle"
                                                        title="[Ctrl+click] Expand all children"><span>▼</span></a>]</span>
                                                </span>
                                            }
                                            {!this.isExpanded([...pathAcu, key]) &&
                                                <span id={"debug-"+[...pathAcu, key].join("|")}
                                                      className="hand"
                                                >
                                                    <span className="sf-dump-note">{this.getTextObjectHeader(object[key])}&nbsp;</span>
                                                    <span data-depth="1" className="sf-dump-expanded">
                                                        [<a
                                                        className="sf-dump-ref sf-dump-toggle"
                                                        title="[Ctrl+click] Expand all children"><span>▶</span></a>]</span>
                                                </span>
                                            }
                                        </span>
                                    }
                                    {isFinal &&
                                        <>
                                            <span className="sf-dump-key">{key}</span>:&nbsp;
                                        </>
                                    }
                                    {recursiveCall}
                                </li>
                            )
                            }
                        )}
                    </ul>
                );
            }
        }
    }
}
export default DebugObjects;
