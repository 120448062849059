/**
 * Muestra las pestañas que nos haya solicitado a traves de las distintaws URLs de la aplicación
 */
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import OrderForm from '../../pages/orders/OrderForm'
import translate from '../../translator/translate'
import withRouter from 'react-router-dom/es/withRouter'
import RightModalInList from '../../subpages/RightModalInList'
import PropsUtil from '../../util/PropsUtil'
import OrderFormSlots from '../../pages/orders/OrderFormSlots'
import OrderFormArticles from '../../pages/orders/OrderFormArticles'
import OrderFormWorkOrders from '../../pages/orders/OrderFormWorkOrders'
import ClientContacts from '../../pages/clients/ClientContacts'
import ClientForm from '../../pages/clients/ClientForm'
import ScheduleByClientPrevent from '../../pages/clients/clientSchedule/ScheduleByClientPrevent'
import AppLogger from '../../util/AppLogger'
import WorkOrderForm from '../../pages/workOrders/WorkOrderForm'
import WorkOrderFormSlots from '../../pages/workOrders/WorkOrderFormSlots'
import OrderHistory from '../../pages/orders/OrderHistory'
import PageDocument from '../../pages/PageDocument'
import WorkOrderFormItems from '../../pages/workOrders/WorkOrderFormArticles'
import WorkOrderHistory from '../../pages/workOrders/WorkOrderHistory'
import WorkOrderSummary from '../../pages/workOrders/WorkOrderSummary'
import ClientHistory from '../../pages/clients/ClientHistory'
import AssetForm from '../../pages/assets/AssetForm'
import AssetHistory from '../../pages/assets/AssetHistory'
import appState from '../../state/AppState'
import AlertModal from '../../subpages/AlertModal'
import PopUp from './PopUp'
import ArticleHistory from '../../pages/articles/ArticleHistory'
import ArticleFormLocation from '../../pages/articles/ArticleFormLocationTable'
import ArticleForm from '../../pages/articles/ArticleForm'
import UserCapacitations from '../../pages/users/UserCapacitations'
import UserHistory from '../../pages/users/UserHistory'
import UserAvailabilityTable from '../../pages/users/UserAvailabilityTable'
import UserForm from '../../pages/users/UserForm'
import OrderSummaryPage from '../../pages/orders/OrderSummaryPage'
import DynamicGroups from '../../pages/PageDynamic'
import OrderFormBudgetTable from '../../pages/orders/OrderFormBudgetTable'
import WorkOrderFormBudgetTable from '../../pages/workOrders/WorkOrderFormBudgetTable'
import util from '../../util/Util'
import UserCheckWorkDaysTable from '../../pages/users/UserCheckInTable'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import BudgetForm from '../../pages/budgets/BudgetForm'
import OrderHistoryTech from '../../pages/orders/OrderHistoryTech'
import AssetHistoryTech from '../../pages/assets/AssetHistoryTech'
import ScheduleVisitForm from '../../pages/scheduledVisits/ScheduleVisitForm'
import AssetWarranty from '../../pages/assets/AssetWarranty'
import UserFieldWorks from '../../pages/users/UserFieldWorks'
import ArticleWorkOrderList from '../../pages/articles/ArticleWorkOrderList'
import SlotDetailView2 from '../SlotDetailView'
import StockForm from '../../pages/stocks/StockForm'
import AssetWorkOrderArticleList from '../../pages/assets/AssetWorkOrderArticleList'
import SlotForm from '../../pages/slots/SlotForm'
import SlotHistory from '../../pages/slots/SlotHistory'
import SlotFormArticles from '../../pages/slots/SlotFormArticles'
import AssetWorkOrderList from '../../pages/assets/AssetWorkOrderList'
import BudgetHistory from '../../pages/budgets/BudgetHistory'
import PageDocumentSortable from '../../pages/PageDocumentSortable'
import ClientPosContacts from '../../pages/clients/ClientPosContacts'
import ClientPosForm from '../../pages/clients/ClientPosForm'
import ClientPosHistory from '../../pages/clients/ClientPosHistory'
import ConfigFormForm from '../../pages/config/ConfigFormForm'
import AssetPlanogram from '../../pages/assets/AssetPlanogram'

@observer
class RightModals extends Component {

    @observable    gqlErrors = null;

    constructor(props) {
        super(props);
        this.state = {
            openToast: true
        }

    }

    onCloseModal() {
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;
        } else {
            this.propsUtil.changeUrlRequest({
                fromRightModal: null,
                rightModal: null,
                articleId: null,
                rightModalTab: null,
                workOrderId: null,
                orderId: null,
                clientId: null,
                scheduleVisitId: null,
                assetId: null,
                userId: null,
                slotId: null,
                budgetId: null,
                warehouseId: null
            });
            appState.layoutState.modalChangeOpen = false;
        }
    }

    volver() {
        appState.layoutState.modalChangeOpen = false;
    }


    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;

        return (
            <>
                {util.hasValue(this.propsUtil.getRequest("updateVersion")) &&
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={this.state.openToast}
                    onClose={() => setTimeout(() => {
                        this.setState({ openToast: false })
                    }, 6000)}
                    message="Se ha actualizado NUDDO"
                    severity={"success"}
                    TransitionComponent={Slide}
                />
                }
                {appState.layoutState.modalChangeOpen &&
                <AlertModal isOpen={appState.layoutState.modalChangeOpen}
                    onCloseModal={() => {
                        appState.layoutState.formWithoutChanges = false;
                        this.onCloseModal()
                    }}
                >
                    <PopUp
                        title={t('Has salido sin guardar')}
                        icon="exclamationTriangleIcon"
                        text={t('¿Quieres guardar los cambios? Los cambios se perderán si no lo guardas')}
                        label1={'Salir sin guardar'}
                        label2={'Volver'}
                        labelGuardar={true}
                        onClick1={() => {
                            appState.layoutState.formWithoutChanges = true;
                            this.onCloseModal()
                        }}
                        onClick2={() => {
                            this.volver()
                        }}
                    />
                </AlertModal>
                }
                {util.hasValue(appState.scheduleByTechDropState.slotClicked.id) &&
                <SlotDetailView2
                    key={appState.scheduleByTechDropState.slotClicked.id}
                    slot={appState.scheduleByTechDropState.slotClicked} />
                }
                {this.renderRightModalsOrder()}
                {this.renderRightModalsSchedules()}
                {this.renderRightModalsStocks()}
                {this.renderRightModalsForms()}
                {this.renderRightModalsArticles()}
                {this.renderRightModalsWorkOrder()}
                {this.renderRightModalsBudget()}
                {this.renderRightModalsClient()}
                {this.renderRightModalsPosClient()}
                {this.renderRightModalsUser()}
                {this.renderRightModalsAssets()}
                {this.renderRightModalsSlots()}
                {this.renderRightModalsWarehouses()}
            </>
        );
    }

    renderRightModalsForms() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalformls"}
                onCloseModal={() => this.onCloseModal()}
            >

                {this.propsUtil.getRequest("rightModalTab") === "form" &&
                <ConfigFormForm path="/config/form/form/:id?"
                    key={this.propsUtil.getRequest("formId")}
                    id={this.propsUtil.getRequest("formId")}
                    formId={this.propsUtil.getRequest("formId")}
                    fromRightModal={true}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsOrder() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalorderls"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "workorders" &&
                <OrderFormWorkOrders path="/order/form/:id?/workOrder"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true} {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "order" &&
                <OrderForm path="/order/form/:id?"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "slots" &&
                <OrderFormSlots path="/order/form/:id?/slots"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                <OrderFormArticles path="/order/form/:id?/articles"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "summary" &&
                <OrderSummaryPage path="/order/form/:id?/summary"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <OrderHistory path="/order/form/:id?/history"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocument path="/order/form/:id?/document"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    pestanya={"pedidos"}
                    model="order"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "dynamicGroups" &&
                <DynamicGroups path="/order/form/:id?/dynamicGroups"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    pestanya={"pedidos"}
                    model="order"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "historytech" &&
                <OrderHistoryTech path="/order/form/:id?/historytech"
                    key={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "budget" &&
                <OrderFormBudgetTable path="/order/form/:id?/budget"
                    key={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("orderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    model="order"
                    {...props}
                />
                }

            </RightModalInList>

        )
    }

    renderRightModalsSchedules() {
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalschedulevisitls"}
                onCloseModal={() => this.onCloseModal()}>
                {this.propsUtil.getRequest("rightModalTab") === "schedulevisit" &&
                <ScheduleVisitForm path="/schedule/visit/type/form/:id?"
                    key={this.propsUtil.getRequest("scheduleVisitId")}
                    id={this.propsUtil.getRequest("scheduleVisitId")}
                    fromRightModal={true}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsSlots() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalslots"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "general" &&
                <SlotForm path="/slot/form/:id?"
                    key={this.propsUtil.getRequest("slotId")}
                    id={this.propsUtil.getRequest("slotId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                <SlotFormArticles path="/slot/form/:id?"
                    key={this.propsUtil.getRequest("slotId")}
                    id={this.propsUtil.getRequest("slotId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <SlotHistory
                    path="/slot/form/:id?/history"
                    id={this.propsUtil.getRequest("slotId")}
                    key={this.propsUtil.getRequest("slotId")}
                    fromRightModal={true}
                    {...props}
                />
                }
            </RightModalInList>
        )
    }

    renderRightModalsWorkOrder() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalworkorderls"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "workorder" &&
                <WorkOrderForm
                    path="/workOrder/form/:id?"
                    key={this.propsUtil.getRequest("workOrderId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                />
                }

                {this.propsUtil.getRequest("rightModalTab") === "schedule" &&
                <WorkOrderFormSlots
                    path="/workOrder/form/:id?/assigment"
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    key={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                <WorkOrderFormItems
                    path="/workOrder/form/:id?/items"
                    id={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    key={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <WorkOrderHistory
                    path="/workOrder/form/:id?/history"
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    key={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "workLogSummary" &&
                <WorkOrderSummary
                    path="/workOrder/form/:id?/workLogSummary"
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    key={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "budget" &&
                <WorkOrderFormBudgetTable
                    path="/workOrder/form/:id?/budget"
                    key={this.propsUtil.getRequest("workOrderId")}
                    id={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    pestanya={"pedidos"}
                    model="order"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocument
                    path="/workOrder/form/:id?/document"
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    key={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    orderId={this.propsUtil.getRequest("orderId")}
                    model="workOrder"
                    pestanya={"ordenes de trabajo"}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "dynamicGroups" &&
                <DynamicGroups
                    path="/workOrder/form/:id?/dynamicGroups"
                    id={this.propsUtil.getRequest("workOrderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    key={this.propsUtil.getRequest("workOrderId")}
                    fromRightModal={true}
                    pestanya={"ordenes de trabajo"}
                    model="workOrder"
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsClient() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalclients"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "contacts" &&
                <ClientContacts
                    path="/client/form/:id?/contacts"
                    orderId={this.propsUtil.getRequest("orderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    key={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "general" &&
                <ClientForm
                    path="/client/form/:id?"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "prevent" &&
                <ScheduleByClientPrevent
                    path="/client/form/:id?/prevent"
                    key={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <ClientHistory
                    path="/client/form/:id?/history"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocumentSortable
                    path="/client/form/:id?/document"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    pestanya={"clientes"}
                    model="client"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "dynamicGroups" &&
                <DynamicGroups
                    path="/client/form/:id?/dynamicGroups"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    pestanya={"clientes"}
                    model="client"
                    {...props}
                />
                }
            </RightModalInList>

        )
    }


    renderRightModalsPosClient() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalposclients"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "contacts" &&
                <ClientPosContacts
                    path="/clientPos/form/:id?/contacts"
                    orderId={this.propsUtil.getRequest("orderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    key={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "general" &&
                <ClientPosForm
                    path="/clientPos/form/:id?"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "prevent" &&
                <ScheduleByClientPrevent
                    path="/clientPos/form/:id?/prevent"
                    key={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <ClientPosHistory
                    path="/clientPos/form/:id?/history"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocumentSortable
                    path="/clientPos/form/:id?/document"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    pestanya={"clientes"}
                    model="clientPos"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "dynamicGroups" &&
                <DynamicGroups
                    path="/clientPos/form/:id?/dynamicGroups"
                    key={this.propsUtil.getRequest("clientId")}
                    id={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    pestanya={"clientes"}
                    model="client"
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsUser() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modaluserls"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "general" &&
                <UserForm
                    path="/user/form/:id?"
                    key={this.propsUtil.getRequest("userId")}
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "capacitation" &&
                <UserCapacitations
                    path="/user/form/:id?/userCapacitation"
                    key={this.propsUtil.getRequest("userId")}
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "availability" &&
                <UserAvailabilityTable
                    path="/user/form/:id?/userAvailability"
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    key={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "fieldwork" &&
                <UserFieldWorks
                    path="/user/form/:id?/UserFieldWorks"
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    key={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <UserHistory
                    path="/user/form/:id?/history"
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    key={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocument
                    path="/user/form/:id?/document"
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    key={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                    model="user"
                    pestanya={"usuarios"}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "clockingInTable" &&
                <UserCheckWorkDaysTable
                    path="/user/form/:id?/userClockingInTable"
                    id={this.propsUtil.getRequest("userId")}
                    userId={this.propsUtil.getRequest("userId")}
                    key={this.propsUtil.getRequest("userId")}
                    fromRightModal={true}
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsBudget() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalbudgetls"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "budgetForm" &&
                <BudgetForm
                    path="/budget/form/:id?"
                    key={this.propsUtil.getRequest("budgetId")}
                    id={this.propsUtil.getRequest("budgetId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <BudgetHistory
                    path="/budget/form/:id?/history"
                    key={this.propsUtil.getRequest("budgetId")}
                    id={this.propsUtil.getRequest("budgetId")}
                    orderId={this.propsUtil.getRequest("orderId")}
                    workOrderId={this.propsUtil.getRequest("workOrderId")}
                    clientId={this.propsUtil.getRequest("clientId")}
                    fromRightModal={true}
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsStocks() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalstocks"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "stocks" &&
                <StockForm
                    path="/stock/form/:id?"
                    key={this.propsUtil.getRequest("subInventory")}
                    id={this.propsUtil.getRequest("subInventory")}
                    fromRightModal={true}
                    {...props}
                />}
            </RightModalInList>
        )
    };

    renderRightModalsArticles() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalarticles"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "articles" &&
                <ArticleForm
                    path="/article/form/:id?"
                    key={this.propsUtil.getRequest("articleId")}
                    id={this.propsUtil.getRequest("articleId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "location" &&
                <ArticleFormLocation
                    path="/article/form/:id?/location"
                    key={this.propsUtil.getRequest("articleId")}
                    id={this.propsUtil.getRequest("articleId")}
                    articleId={this.propsUtil.getRequest("articleId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "workOrderCartList" &&
                <ArticleWorkOrderList
                    path="/article/form/:id?/location"
                    key={this.propsUtil.getRequest("articleId")}
                    id={this.propsUtil.getRequest("articleId")}
                    articleId={this.propsUtil.getRequest("articleId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <ArticleHistory
                    path="/article/form/:id?/history"
                    key={this.propsUtil.getRequest("articleId")}
                    id={this.propsUtil.getRequest("articleId")}
                    articleId={this.propsUtil.getRequest("articleId")}
                    fromRightModal={true}
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocument
                    path="/article/form/:id?/document"
                    key={this.propsUtil.getRequest("articleId")}
                    id={this.propsUtil.getRequest("articleId")}
                    articleId={this.propsUtil.getRequest("articleId")}
                    fromRightModal={true}
                    model="article"
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsAssets() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalassets"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "assets" &&
                <AssetForm
                    path="/asset/form/:id?"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true} {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "historyComment" &&
                <AssetHistoryTech
                    path="/asset/form/:id?/historyComment"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "workOrderCartList" &&
                <AssetWorkOrderArticleList
                    path="/asset/form/:id?/workOrderArticleList"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    assetId={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "workOrderList" &&
                <AssetWorkOrderList
                    path="/asset/form/:id?/workOrderList"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    assetId={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "warranty" &&
                <AssetWarranty
                    path="/asset/form/:id?/warranty"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "general" &&
                <AssetForm
                    path="/asset/form/:id?"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true} {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "planogram" &&
                <AssetPlanogram
                    path="/asset/form/:id?/planogram"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true} {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "history" &&
                <AssetHistory
                    path="/asset/form/:id?/history"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true} {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "document" &&
                <PageDocumentSortable
                    path="/asset/form/:id?/document"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                    pestanya={"equipos"}
                    model="asset"
                    {...props}
                />
                }
                {this.propsUtil.getRequest("rightModalTab") === "dynamicGroups" &&
                <DynamicGroups
                    path="/asset/form/:id?/dynamicGroups"
                    key={this.propsUtil.getRequest("assetId")}
                    id={this.propsUtil.getRequest("assetId")}
                    fromRightModal={true}
                    pestanya={"equipos"}
                    model="asset"
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    renderRightModalsWarehouses() {
        let props = this.props;
        return (
            <RightModalInList
                isOpen={this.propsUtil.getRequest("rightModal") === "modalwarehouses"}
                onCloseModal={() => this.onCloseModal()}
            >
                {this.propsUtil.getRequest("rightModalTab") === "warehouses" &&
                <ArticleForm
                    path="/warehouse/form/:id?"
                    key={this.propsUtil.getRequest("warehouseId")}
                    id={this.propsUtil.getRequest("warehouseId")}
                    fromRightModal={true}
                    {...props}
                />
                }
            </RightModalInList>

        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default withRouter(RightModals, "RightModals")
