import AbstractModel from "./AbstractModel";
import util from '../util/Util'
import * as mimeTypes from "react-native-mime-types";

export default class DocumentModel extends AbstractModel {


    id;
    size;
    url;
    urlSource;
    createdAt;
    name;
    field;
    orderView;
    modelName;
    modelId;
    thumbnailx1;
    thumbnailx2;
    thumbnailx4;
    thumbnailx8;
    thumbnailx16;

    nameMainType = "document";
    graphFindByIdOperation = "document";
    graphFindOperation = "documentsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        return {
            ...resultBase,
            "size": "",
            "modelName": "",
            "modelId": "",
            "field": "",
            "urlSource": "",
            "orderView": "",
            "url": { readonly: true },
            "name": { readonly: true },
            "thumbnailx1": { readonly: true },
            "thumbnailx2": { readonly: true },
            "thumbnailx4": { readonly: true },
            "thumbnailx8": { readonly: true },
            "thumbnailx16": { readonly: true }
        };
    }

    /*
    { "Thumbnailx1", @"Thumbnail of the image. 60px" },
    { "Thumbnailx2", @"Thumbnail of the image. 120px" },
    { "Thumbnailx4", @"Thumbnail of the image. 240px" },
    { "Thumbnailx8", @"Thumbnail of the image. 480px" },
    { "Thumbnailx16", @"Thumbnail of the image. 960px" }
    */

    getThumbNailx1() {
        let result;
        if (this.thumbnailx1 != null) {
            result = this.thumbnailx1;
        } else {
            result = this.url;
        }
        return result;
    }

    getThumbNailx2() {
        let result;
        if (this.thumbnailx2 != null) {
            result = this.thumbnailx2;
        } else {
            result = this.url;
        }
        return result;
    }

    getThumbNailx4() {
        let result;
        if (this.thumbnailx4 != null) {
            result = this.thumbnailx4;
        } else {
            result = this.url;
        }
        return result;
    }

    getThumbNailx8() {
        let result;
        if (this.thumbnailx8 != null) {
            result = this.thumbnailx8;
        } else {
            result = this.url;
        }
        return result;
    }

    getThumbNailx16() {
        let result;
        if (this.thumbnailx16 != null) {
            result = this.thumbnailx16;
        } else {
            result = this.url;
        }
        return result;
    }


    getFileName() {
        let result = this.name;
        if (util.esVacio(result)) {
            result = util.getFileName(this.getUrl());
        }
        return result;
    }

    isVideo() {
        let result = false;
        let mime = this.getMimeType();
        // this.log({ mime });
        if (mime.startsWith("video")) {
            result = true;
        }
        return result;
    }

    isPDF() {
        let result = false;
        let mime = this.getMimeType();
        if (mime.startsWith("application/pdf")) {
            result = true;
        }
        return result;
    }


    getUrl() {
        return util.getString(this.url);
    }

    isImage() {
        let result = false;
        let mime = this.getMimeType();
        // this.log({ isImage: 1, mime });
        if (mime.startsWith("image")) {
            result = true;
        }
        return result;
    }

    isFieldImage() {
        let result = false;
        if (this.field === "photo" || this.field === "mainPhoto" || this.field === "mainPhotoValidate") {
            result = true;
        }
        return result;
    }

    getMimeType() {
        let ext = util.getFileExtension(this.getUrl());
        let mimeType = mimeTypes.lookup(ext);
        let mimeTypeStr = util.getString(mimeType);
        // this.log({ url: this.getUrl(), ext, mimeTypeStr, mimeType });
        return mimeTypeStr;
    }


}
