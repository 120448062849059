import React, { Component } from 'react';
import { observer } from 'mobx-react';
import ReactModal from 'react-modal-resizable-draggable';
import PropTypes from "prop-types";


const body = document.getElementsByTagName('body')[0];

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.5)",
    }
};

@observer
export default class VsfModalResizable extends Component {

    constructor(props) {
        super(props);
    }
    static defaultProps = {
        disableResize: true,

    }
    static propTypes = {
        disableResize: PropTypes.bool,
    }

    openModal() {
        if (this.props.stateFilter) {
            this.props.stateFilter.modalOpen = true;
        }
    };

    closeModal() {
        body.classList.remove("ReactModal__Body--open");
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        } else {
            if (this.props.stateFilter) {
                this.props.stateFilter.modalOpen = false;
            }
        }
    }

    render() {
        let isOpen = this.props.isOpen;
        if (isOpen == null) {
            if (this.props.stateFilter) {
                isOpen = this.props.stateFilter.modalOpen;
            }
        }
        if(isOpen){
            body.classList.add("ReactModal__Body--open");
        }
        return (
            <ReactModal
                isOpen={isOpen}
                onRequestClose={() => this.closeModal()}
                className={this.props.className} minWidth={400}
                initHeight={this.props.initHeight} left={this.props.left}
                closeTimeoutMS={200} disableResize={this.props.disableResize}
                ariaHideApp={false}
                style={[customStyles]}
            >
                {this.props.children}
            </ReactModal>
        );
    }
}
