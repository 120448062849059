import { observable } from 'mobx'
import ClientState from './ClientState'
import UserState from './UserState'
import WorkOrderState from './WorkOrderState'
import AssetState from './AssetState'
import LoginState from './LoginState'
import TypifiedState from './TypifiedState'
import LayoutState from './LayoutState'
import UserCacheState from './UserCacheState'
import OrderState from './OrderState'
import ClientCacheState from './ClientCacheState'
import ScheduleVisitState from './ScheduleVisitState'
import SchedulePreventDropState from './SchedulePreventDropState'
import ContactState from './ContactState'
import LoadingBarState from './LoadingBarState'
import SlotState from './SlotState'
import ArticleState from './ArticleState'
import WorkOrderCartArticlesState from './WorkOrderCartArticlesState'
import ArticleCodesState from './ArticleCodesState'
import ScheduleSlotState from './ScheduleSlotState'
import AssetCacheState from './AssetCacheState'
import DateRangesState from './DateRangesState'
import CheckWorkDaysState from './CheckWorkDaysState'
import VehicleState from './VehicleState'
import TranslationsState from './TranslationsState'
import AssetModelState from './AssetModelState'
import ChangesWithValidationsState from './ChangesWithValidationsState'
import DynamicsState from './DynamicsState'
import DynamicFieldsState from './DynamicFieldsState'
import ConfigState from './ConfigState'
import WorkLogState from './WorkLogState'
import BudgetArticleState from './BudgetArticleState'
import BudgetState from './BudgetState'
import StockState from './StockState'
import WarehouseState from './WarehouseState'
import TenantState from './TenantState'
import WarningsState from './WarningsState'
import FormState from './FormState'
import BaseModelState from './BaseModelState'
import FormAssignmentState from './FormAssignmentState'
import VehicleGpsState from './VehicleGpsState'
import ScheduleByTechDropState from './ScheduleByTechDropState'
import SubcontractState from './SubcontractState'
import SlaState from './SlaState'
import OriginState from './OriginState'
import BinState from './BinState'
import SubcontractContactState from './SubcontractContactState'
import ScheduleClientDropState from './ScheduleClientDropState'
import AlertState from './AlertState'
import StockMovementState from './StockMovementState'
import ClassFactory from '../factory/ClassFactory'
import ContractState from './ContractState'

class AppState {

  constructor () {
    this.clientState = new ClientState()
    this.clientPosState = new ClientState()
    this.userState = new UserState()
    this.userWithoutState = new UserState()
    this.workOrderState = new WorkOrderState()
    this.changesWithValidationsState = new ChangesWithValidationsState()
    this.assetState = new AssetState()
    this.assetModelState = new AssetModelState()
    this.translationState = new TranslationsState()
    this.configState = new ConfigState()
    this.assetCacheState = new AssetCacheState()
    this.loginState = new LoginState()
    this.typifiedState = new TypifiedState()
    this.userCacheState = new UserCacheState()
    this.stockState = new StockState()
    this.stockMovementState = new StockMovementState()
    this.warehouseState = new WarehouseState()
    this.layoutState = new LayoutState()
    this.orderState = new OrderState()
    this.clientCacheState = new ClientCacheState()
    this.workLogState = new WorkLogState()
    this.slotsState = new SlotState()
    this.scheduleVisitState = new ScheduleVisitState()
    this.schedulePreventDropState = new SchedulePreventDropState()
    this.scheduleClientDropState = new ScheduleClientDropState()
    this.scheduleByTechDropState = new ScheduleByTechDropState()
    this.dateRangesState = new DateRangesState()
    this.checkWorkDaysState = new CheckWorkDaysState()
    this.contactState = new ContactState()
    this.subcontractContactState = new SubcontractContactState()
    this.slotState = new SlotState()
    this.summaryState = new SlotState()
    this.scheduleSlotState = new ScheduleSlotState()
    this.articleCodesState = new ArticleCodesState()
    this.loadingBarState = new LoadingBarState()
    this.dynamicGroupState = new DynamicsState()
    this.tenantState = new TenantState()
    this.dynamicFieldState = new DynamicFieldsState()
    this.articleState = new ArticleState()
    this.articleWorkOrderArticleState = new ArticleState()
    this.vehicleState = new VehicleState()
    this.budgetArticleState = new BudgetArticleState()
    this.formState = new FormState()
    this.formAssignmentState = new FormAssignmentState()
    this.budgetState = new BudgetState()
    this.workOrderCartArticlesState = new WorkOrderCartArticlesState()
    this.warningsState = new WarningsState()
    this.purchaseOrderState = new BaseModelState()
    this.transferOrderState = new BaseModelState()
    this.wmsInventoryState = new BaseModelState()
    this.wmsTransferBinState = new BaseModelState()
    this.vehicleGpsState = new VehicleGpsState()
    this.subcontractState = new SubcontractState()
    this.slaState = new SlaState()
    this.contractState = new ContractState()
    this.originState = new OriginState()
    this.binState = new BinState()
    this.alertState = new AlertState()
    this.classFactory = ClassFactory
  }

  /**
   * @type ClientState
   */
  client = null
  /**
   *
   * @type OrderState
   */
  order = null
  /**
   * @type UserState
   */
  user = null
  /**
   * @type AssetState
   */
  AssetModel = null
  /**
   * Idioma en el que se verá el contenido
   * @type {string}
   */
  @observable nuddoDbVersion
  @observable lang = 'ES'
  @observable maintenance = false
  @observable bookingData = {
    lang: 'es'
  }
  @observable workOrderStatus = {
    mutationGraphQlResponse: {
      errors: []
    },
    mutationLoading: false,
    mutationError: false,
  }
  @observable queryLoggers = []
  @observable pageNoData = false
  @observable preventOrders = []
  // Guarda los filtros del listado de usuarios
  @observable orderFormSlotState = {
    mutationGraphQlResponse: { errors: [] },
    mutationError: 0
  }
  @observable articleFormSlotState = {
    mutationGraphQlResponse: { errors: [] },
    mutationError: 0
  }
  @observable genericFormRowState = {
    mutationGraphQlResponse: { errors: [] },
    mutationError: 0
  }
  @observable userListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable clientListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable orderListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable workOrderListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable assetListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable vehicleListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable assetModelListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable articleListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable scheduledTechFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable stockListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable workOrderArticleListFilter = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable contractListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable budgetListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable slotListFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable purchaseOrderFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable transferOrderFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable wmsInventoryFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable wmsTransferBinFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable userZoneFilters = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }
  @observable reportResume = {
    currentFilter: {},
    currentFilterOps: {},
    currentFilterLabels: {},
    actionList: {}
  }

  @observable debugObjects = {};

}

export default new AppState()
