import React, {Component} from 'react';
import {observer} from "mobx-react";
import Modal from "react-modal";

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.0)",
        pointerEvents: "none",
    },
};

@observer
class SidebarModal extends Component {

    body;

    constructor(props) {
        super(props);
        this.body = document.getElementsByTagName('body')[0];
    }

    onCloseModal() {
        this.body.classList.remove("ReactModal__Body--open");
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        }
    }

    render() {
        let isOpen = this.props.isOpen;
        if (isOpen) {
            this.body.classList.add("ReactModal__Body--open");
        }
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={() => this.onCloseModal()}
                className={"right-to-left sidebar " + this.props.className}
                ariaHideApp={false}
                style={customStyles}>
                <div className={"modal-push"}>
                    <div className={"modal-head"}>
                        <div className="row">
                            <div className="col-8 text-left">
                            </div>
                            <div className="col-4 text-right">
                                <button type="button" className="close" aria-label="Close"
                                        onClick={() => this.onCloseModal()}>
                                    <span className="fas fa-times"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"modal-body"}>
                        <div className={"form-group"}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default SidebarModal;