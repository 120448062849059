import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import {computed} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import translate from "../../translator/translate";
import VsfButtonNew from "../../components/VsfButtonNew";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import WorkLogModel from "../../models/WorkLogModel";
import OrderTaskRow from "./OrderTaskRow";

@observer
class OrderTaskTable extends BaseEditableTable {

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.foreingKeyfield = "orderId";
        this.state = {
            hasSomeRowEditing: false
        };

    }

    @computed get mobxListado() {
        return appState.workLogState.workLogsTasksOfOrder;
    }
    setMobxListado(newValue) {
        appState.workLogState.workLogsTasksOfOrder = newValue;
    }
    getModelTable() {
        return new WorkLogModel();
    }

    reloadForm () {

    }
    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <div className={"col-12"}>
                <InfoBlock componentObject={this}>
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            <div className="card-body card-body--nopadd">
                                <div className="row mt-4">
                                    <div className="col-4">
                                        <div className="table-edit">
                                            <table className="table visible-text">
                                                <thead>
                                                {this.mobxListado?.length != 0 &&
                                                <tr>
                                                    <th scope="col">{t('Codigo')}</th>
                                                    <th scope="col">{t('Descripcion')}</th>
                                                    <th scope="col"></th>
                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">
                                                {this.mobxListado?.map((workLog, index) => (
                                                    <OrderTaskRow
                                                        key={workLog && workLog.id}
                                                        order={this.props.order}
                                                        rowIndex={index}
                                                        row={workLog}
                                                        reload={(e) => this.reloadData(e)}
                                                        fromWorkOrder={false}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    />
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className={ "text-right"}>
                                            <VsfButtonNew
                                                disabled={this.state.hasSomeRowEditing}
                                                label={t("Añadir Tareas")}
                                                onClick={() => this.props.openModalTask()}
                                                isEditing={this.state.hasSomeRowEditing}
                                                className="btn btn--loader"
                                            />
                                        </div>
                                        <AlertModal isOpen={this.state.orderFinished}
                                                    onCloseModal={() => this.setState({ "orderFinished": false })}
                                        >
                                            <PopUp
                                                title={t('Operación no permitida')}
                                                icon={"exclamationTriangleIcon"}
                                                text={t(`Este Pedido está finalizado, para poder añadir una visita
                                                 tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                                                label1={'Cerrar'}
                                                onClick1={() => this.setState({ orderFinished: false })}
                                            />
                                        </AlertModal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(OrderTaskTable)
