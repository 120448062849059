import AbstractModel from "./AbstractModel";

export default class ReportModel extends AbstractModel {

    techniciansWithoutScheduledToday;

    nameMainType = "reports";
    graphFindByIdOperation = "reports";
    graphFindOperation = "reportsConnection";

    getArrayFields() {
        let result = {
            "techniciansWithoutScheduledToday": "",
            "techniciansWithoutScheduledTodayCount": "",
        };
        return result;
    }

    hasQueryIdParams() {
        return false;
    }

    hasMutationIdParams() {
        return false;
    }
}
