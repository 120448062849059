import React from 'react';
import {observer} from 'mobx-react';
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";
import util from "../util/Util";
import InputTypeComponent from "../components/fields/InputTypeComponent";
import translate from "../translator/translate";

@observer
class FiltersPage extends React.Component {

    nameVariableStateFilter = "userListFilters-Personalizar-en-subclase";

    constructor(props) {
        super(props);
        this.initialize();
    }

    initialize() {
        if (appState[this.nameVariableStateFilter] == null) {
            appState[this.nameVariableStateFilter] = {
                currentFilter: {},
                currentFilterOps: {},
                currentFilterLabels: {},
            }
        }
        appState[this.nameVariableStateFilter].filterType = {};
        appState["currentFilterName"] = this.nameVariableStateFilter;
    }
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }
    /**
     * Actualiza el valor de un filtro.
     * @param event
     * @param op operator filter
     */
    updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = { ...appState[this.nameVariableStateFilter].currentFilter, [name]: value };
        let currentFilterOps = { ...appState[this.nameVariableStateFilter].currentFilterOps, [name]: op };
        let currentFilterLabels = { ...appState[this.nameVariableStateFilter].currentFilterLabels, [name]: label };
        appState[this.nameVariableStateFilter] = { currentFilter, currentFilterOps, currentFilterLabels };
    }

    getFiltersDynamics(relatedModels) {
        const t = translate;
        let result = [];
        for (let dynamicGroup of appState.dynamicGroupState.dynamics) {
            for (let dynamicField of dynamicGroup.dynamicField) {
                if (dynamicField.listing && util.perteneceA(relatedModels, dynamicGroup.relatedModels)) {
                    let item = <InputTypeComponent
                        value={appState[this.nameVariableStateFilter].currentFilter['dynamicField.' + [dynamicField.code]]}
                        onChange={(e) => this.updateInputFilterEvent(e, 'EQ')}
                        name={'dynamicField.' + [dynamicField.code]}
                        title={t(dynamicField.label)}
                        placeholder={t(dynamicField.label)}
                        classGroup={"col-4"}
                        type={"text"}
                    />;
                    result.push(item);
                }
            }
        }
        return result;
    }

    render() {
        return null;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }


}

export default FiltersPage;
