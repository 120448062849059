import ApolloProxy from "../network/ApolloProxy";
import { gql } from "apollo-boost/lib/index";
import apolloClient from "../storage/ApolloClientInstance";
import AppLogger from "../util/AppLogger";
import appState from "../state/AppState";

export default class MultiModel {

    async persist(models) {
        let variables = {};
        let queryBody = "";
        let i = 0;
        let parametersArr = [];
        for (/** @type Model */ let model of models) {
            model.indexVariableQuery = i;
            let variablesCurr = model.getGraphQLMutateVariables();
            variables = { ...variables, ...variablesCurr };
            parametersArr.push(model.getGraphQLMutateParameters());
            queryBody += model.getGraphQLMutateBody();
            i++;
        }
        let queryDebug=this.getQueryLogger();
        let apolloProxy = new ApolloProxy(apolloClient);
        let nameQuery = "CreateOrModifyMultiple";
        let parameters = parametersArr.join(',');

        let mutationRaw = `
            mutation ${nameQuery}(${parameters}) {
                ${queryBody}
                ${queryDebug}
            }
        `;
        let mutation = gql`${mutationRaw}`;
        const resultQuery = await apolloProxy.mutate({ mutation, mutationRaw, variables, errorPolicy: 'all' });
        let result = [];
        i = 0;
        for (/** @type Model */ let model of models) {
            let resultParcial = model.toPlainObject();
            if (resultQuery && resultQuery.data) {
                let { nameMainType } = model.getVariablesMutation();
                if (resultQuery.data[nameMainType + i]) {
                    for (let fieldName of model.getResponseFieldsFromMutation()) {
                        resultParcial[fieldName] = resultQuery.data[nameMainType + i][fieldName];
                    }
                }
            }
            i++;
            result.push(resultParcial);
        }
        return result;
    }

    getQueryLogger() {
        let queryDebug="";
        if (appState.layoutState.infoDebuggerEnabled) {
            queryDebug = "loggers { type time ellapsedMs message }";
        }
        return queryDebug;
    }

    async find(models) {
        let variables = {};
        let queryBody = "";
        let i = 0;
        let parametersArr = [];
        for (/** @type Model */ let model of models) {
            model.indexVariableQuery = i;
            let variablesCurr = model.getGraphQLQueryFindVariables();
            variables = { ...variables, ...variablesCurr };
            queryBody += "query" + i + ": " + model.getGraphQLFindQueryBody();
            parametersArr.push(model.getGraphQLFindQueryParameters());
            i++;
        }
        let apolloProxy = new ApolloProxy(apolloClient);
        let nameQuery = "QueryMultiple";
        let parameters = parametersArr.join(',');
        let queryDebug=this.getQueryLogger();
        let queryRaw = `
            query ${nameQuery}(${parameters}) {
                ${queryBody}
                ${queryDebug}
            }            
        `;
        let query = gql`${queryRaw}`;
        let resultQuery = await apolloProxy.graphQuery({ query, queryRaw, variables });
        let result = [];
        i = 0;
        for (/** @type Model */ let model of models) {
            let resultParcial = [];
            if (resultQuery && resultQuery.data) {
                let operationName = "query" + i;
                if (resultQuery.data[operationName]) {
                    resultParcial = model.getResultQueryToArray(resultQuery.data[operationName]);
                }
            }
            i++;
            result.push(resultParcial);
        }
        return result;
    }

    async remove(models) {
        let variables = {};
        let queryBody = "";
        let i = 0;
        let parametersArr = [];
        for (/** @type Model */ let model of models) {
            model.indexVariableQuery = i;
            let variablesCurr = model.getGraphQLDeleteVariables();
            variables = { ...variables, ...variablesCurr };
            queryBody += "mutation" + i + ": " + model.getGraphQLMutationDeleteBody();
            parametersArr.push(model.getGraphQLDeleteParameters());
            i++;
        }
        let queryDebug=this.getQueryLogger();
        let apolloProxy = new ApolloProxy(apolloClient);
        let parameters = parametersArr.join(',');
        let nameQuery = "DeleteMultiple";
        let mutationRaw = `
            mutation ${nameQuery}(${parameters}) {
                ${queryBody}
                ${queryDebug}
            }
        `;
        let mutation=gql`${mutationRaw}`;
        return await apolloProxy.mutate({ mutation, mutationRaw, variables });
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
