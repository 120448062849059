import { observable } from 'mobx'
import BaseModelState from './BaseModelState'
import util from '../util/Util'
import AppLogger from '../util/AppLogger'
import AssetModel from '../models/AssetModel'

class AssetState extends BaseModelState {

    @observable orderArticles;
    @observable assetPlanograms = [];
    @observable orderArticlesLength = [];
    @observable orderBudgets;
    @observable orderDocuments;
    @observable workOrderArticles;
    @observable workOrderArticlesLength = [];
    @observable orderSlots;
    @observable workOrderSlots;
    @observable workOrderBudgets;
    @observable workOrderDocuments;
    @observable assetDocuments;
    @observable assets = [];
    @observable workOrdersNotAsigned = {};
    @observable orderWorkOrders = [];
    @observable asset = {};

    async reloadAssetFromAssetId(assetId) {
        let assetQuery = new AssetModel();
        assetQuery.id = assetId;
        if (util.hasValue(assetQuery.id)) {
            let result = await assetQuery.findFirst();
            if (result == null) {
                result = [];
            }
            this.asset = result;
        }
    }

    countWorkOrdersNotAssigned(order) {
        let result = {};
        if (order != null) {
            if (order.workOrder && order.workOrder.length > 0) {
                for (let workOrder of order.workOrder) {
                    let encontrada = false;
                    for (let slot of order.slot) {
                        if (util.perteneceA(workOrder.id, slot.workOrderIds) ||
                            slot.workOrderIds=="-1") {
                            if (util.hasValue(slot.userId)) {
                                encontrada = true;
                            }
                        }
                    }
                    if (!encontrada) {
                        result[workOrder.id] = "";
                    }
                }
            }
            this.orderWorkOrders = order.workOrder?.length > 0 ? order.workOrder.length : "0";
            this.workOrdersNotAsigned = Object.keys(result).length > 0 ? result : {};
        }
    }

    getCountSlotsFromOrder(order) {
        let result = [];
        let vueltas = 0;
        if (order != null) {
            order.slot?.map(() => {
                vueltas++;
            });
        }
        this.slots = result;
        this.orderSlots = vueltas === 0 ? "0" : vueltas;

    }

    getCountSlotsFromWorkOrder(workOrder) {
        let vueltas = 0;
        if (workOrder != null) {
            workOrder.order?.slot?.map((slot) => {
                if (util.perteneceA(workOrder.id, slot.workOrderIds) || slot.workOrderIds=="-1")
                    vueltas++;
            });
        }
        this.workOrderSlots = vueltas === 0 ? "0" : vueltas;
    }

    async getCountArticlesFromWorkOrder(workOrder) {
        let vueltas = 0;
        if (workOrder != null) {
            workOrder.workOrderCartArticle?.map((pieza) => {
                vueltas += pieza.quantity;
            });
        }
        this.workOrderArticles = vueltas === 0 ? "0" : parseFloat(vueltas).toFixed(2);
        this.workOrderArticlesLength = workOrder.workOrderCartArticle?.length > 0 ? workOrder.workOrderCartArticle.length : "0";
    }

    async getCountArticlesFromOrder(order) {
        let vueltas = 0;
        if (order != null) {
            if (order.workOrderCartArticle?.map((pieza) => {
                vueltas += pieza.quantity;
            }));

        }
        this.orderArticles = vueltas === 0 ? "0" : parseFloat(vueltas).toFixed(2) ;
        this.orderArticlesLength = order.workOrderCartArticle?.length > 0 ? order.workOrderCartArticle.length : "0";
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default AssetState;
