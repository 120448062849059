import { observable, runInAction } from 'mobx';
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import ScheduleVisitModel from "../models/ScheduleVisitModel";
import SlotModel from "../models/SlotModel";

class SchedulePreventDropState {

    @observable scheduledOrders = [];
    @observable order = {};
    @observable horas = 0;
    @observable tecnicosApoyo = 0;
    @observable tecnicosSinAsignar = 0;
    @observable scheduleVisits = [];
    @observable workOrdersNotSlotGrouped = [];
    @observable workOrdersSlotGrouped = [];
    @observable workOrdersSlotGroupedCopia = [];
    @observable workOrdersNotSlotGroupedCopia = [];
    @observable workOrderIds = "";
    @observable flagSlots = false;
    @observable zona;
    @observable clientId;
    @observable slotSchedule = {};
    /**
     * Almaceno un diccionario con la key el id de ScheduleVisit y el value el objeto
     * @type {{}}
     */
    @observable scheduleVisitsDict = {};
    @observable workOrdersOnlyWithMonth = [];
    @observable workOrdersOnlyWithMonthGrouped = {};
    @observable rowsWithoutTechnicals = [];
    //Slots para la parte de planificación
    @observable allSlotsDict = {};
    @observable slotsFromTech = {};
    //Slots agrupados para poder saber el número de elementos en base a técnico - dia - etc
    @observable allSlotsGrouped = {};
    @observable slotClicked = {};
    @observable slotClickedPosition = { top: 0, left: 0 };
    @observable clients = [];
    @observable copiaClients = [];
    @observable horasGrafico = {};
    @observable horasGraficoMayor = 0;
    @observable slotsFromClientDict = {};
    @observable copiaTecnicosCalendario = [];
    @observable tecnicosCalendario = [];
    @observable tecnicosCalendarioDict = {};

    /**
     * Contendrá un array con las horas forzadas. Por ejemplo: {"2019-01-01":{min:5,max:23}}
     * A la hora de pintar tendrá en cuenta este array para que sea el que se utilice
     * @type {{}}
     */
    @observable dateRangeForced = {};
    @observable scheduledDict = [];


    loadClients(zona, cliente) {
        let slotsFromClientDict = {};
        let clientesDict = {};
        let clientesArray = [];
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            if (util.hasValue(slot.client?.id)) {
                if (!util.hasValue(zona) && !util.hasValue(cliente)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                } else if (util.perteneceA(slot.client.posZoneId, zona)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                } else if (util.perteneceA(slot.clientId, cliente)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                }
                if (!slot.isFinished) {
                    if (slotsFromClientDict[slot.client.id] == null) {
                        slotsFromClientDict[slot.client.id] = [];
                    }
                    slotsFromClientDict[slot.client.id].push(slot);
                }
            }
        }
        runInAction(() => {
            let clientsOrderView = util.arrayModelToPlainObjects(clientesArray);
            this.clients = clientsOrderView;
            this.copiaClients = clientsOrderView;
            this.slotsFromClientDict = slotsFromClientDict;
            localStorage.setItem('loadClients', "Todas");
        });
    }


    orderClientsByOrderView(clientesObj) {
        let orderViewArr = [];
        let clientsWithoutZone = [];
        Object.keys(clientesObj).map((zona) => {
            let result = clientesObj[zona].sort(function (a, b) {
                let key1 = a.orderView;
                let key2 = b.orderView;
                if (key1 < key2 || !util.hasValue(key2)) {
                    return -1;
                }
                if (key1 > key2 || !util.hasValue(key1)) {
                    return 1;
                }
                return 0;
            });
            if (util.hasValue(zona)) {
                orderViewArr = [...orderViewArr, ...result];
            } else {
                clientsWithoutZone = [...result];
            }
        });
        orderViewArr = [...orderViewArr, ...clientsWithoutZone];
        return orderViewArr;
    }

    async loadClientsFromScheduledByClient(dateFrom) {
        let slotsFromClientDict = {};
        let allSlotsDict = {};
        let clientesArray = {};
        let slotQuery = new SlotModel();
        slotQuery.filters.push({ "fieldName": "scheduledDate", "fieldValue": dateFrom, "filterOperator": "EQ" });
        slotQuery.addRelatedTable("client");
        slotQuery.addRelatedTable("order");
        slotQuery.addRelatedTable("order.slot");
        slotQuery.addRelatedTable("order.workOrder");
        slotQuery.addRelatedTable("order.workOrder.asset");
        let slots = await slotQuery.findPlainObject();
        let clients = {};
        for (let slot of slots) {
            if (util.hasValue(slot.client?.id)) {
                if (clients[slot.client?.id] == null) {
                    clients[slot.client.id] = "";
                    if (clientesArray[slot.client.posZoneId || ""] == null) {
                        clientesArray[slot.client.posZoneId || ""] = []
                    }
                    if (!util.hasValue(this.zona) && !util.hasValue(this.clientId)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    } else if (util.hasValue(this.zona) && util.perteneceA(slot.client.posZoneId, this.zona)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    } else if (util.hasValue(this.clientId) && util.perteneceListaComa(slot.client.id, this.clientId)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    }
                }
                if (slotsFromClientDict[slot.client.id] == null) {
                    slotsFromClientDict[slot.client.id] = [];
                }
                if (slot.slotUserRole === SlotModel.MAIN && !util.hasValue(slot.scheduledTime)) {
                    slotsFromClientDict[slot.client.id].push(slot.id);
                }
                if (clientesArray[slot.client.posZoneId || ""].length > 0) {
                    allSlotsDict[slot.id] = slot;
                }
            }
        }
        let clientsOrderView = this.orderClientsByOrderView(clientesArray);
        this.clients = clientsOrderView;
        this.copiaClients = clientsOrderView;
        this.slotsFromClientDict = slotsFromClientDict;
        this.allSlotsDict = allSlotsDict;
        this.allSlotsGrouped = this.getCacheWorkUnitsByClient();
    }


    getCacheWorkUnitsByClient() {
        let cacheWorkUnits = {};
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            let hour = util.getHourFromDatetime(slot.scheduledTime) < 14 ? 8 : 14;
            let order = slot.order;
            let clientId = order && util.getString(order.clientId);
            if (slot.scheduledTime != null && slot.slotUserRole === SlotModel.MAIN) {
                let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
                let key = hour + ";" + util.getString(clientId) + ";" + isoDate;
                if (cacheWorkUnits[key] == null) {
                    cacheWorkUnits[key] = [];
                }
                cacheWorkUnits[key].push(slotId);
            }
        }
        return cacheWorkUnits;
    }


    /**
     * Carga los tipos de visitas que se pueden planificar
     * */
    async loadScheduledVisits() {
        let scheduleVisit = new ScheduleVisitModel();
        scheduleVisit.addRelatedTable("scheduleVisitSlots");
        let result = await scheduleVisit.find();
        let resultDict = {};
        for (let visit of result) {
            resultDict[visit.id] = visit;
        }
        this.scheduledDict = util.getDictSingleFromArray(result);
        this.scheduleVisits = result;
        this.scheduleVisitsDict = resultDict;
    }


    ordenar(array) {
        let workOrdersGrouped = {};
        for (let workOrder of array) {
            let key = (util.getString(workOrder.type) || ".") + " " + (util.getString(workOrder.subType) || ".");
            if (workOrdersGrouped[key] == null) {
                workOrdersGrouped[key] = [];
            }
            workOrdersGrouped[key].push(workOrder);
        }
        let result = [];
        for (let types of Object.keys(workOrdersGrouped)) {
            result = [...result, ...workOrdersGrouped[types]]
        }
        return result;
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default SchedulePreventDropState;
