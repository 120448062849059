import AbstractModel from "./AbstractModel";


export default class CheckWorkDaysModel extends AbstractModel {

    static MAX_TIME_JOURNEY = 16;


    static START_DAY = "START_DAY";
    static PAUSE_DAY = "PAUSE_DAY";
    static RESTART_DAY = "RESTART_DAY";
    static END_DAY = "END_DAY";

    id;
    userId;
    action;
    // createdByDaemon;
    time;
    extra;

    nameMainType = "checkWorkDay";
    graphFindByIdOperation = "checkWorkDay";
    graphFindOperation = "checkWorkDaysConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "userId": "",
            "action": "",
            "time": "",
            "extra": "",
        };
        return result;
    }

};





















