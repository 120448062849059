import AbstractModel from "./AbstractModel";

export default class SubcontractContactModel extends AbstractModel {

    id;
    email;
    firstName;
    subcontractId;
    phoneNumber;
    position;

    nameMainType = "subcontractContact";
    graphFindByIdOperation = "subcontractContact";
    graphFindOperation = "subcontractContactsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "email": "",
            "firstName": { label: "Nombre" },
            "subcontractId": "",
            "phoneNumber": { label: "Télefono" },
            "position": "",

        };
        return result;
    }

}
