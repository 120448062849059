import { observable } from 'mobx';
import util from "../util/Util";
import ScheduleVisitSlotModel from "../models/ScheduleVisitSlotModel";


class ScheduleSlotState {

    @observable scheduleSlots = [];

    async reloadScheduleSlotsFromScheduleVisitId(scheduleVisitId) {
        console.log("reloadScheduleSlotsFromOrderId scheduleVisitId:" + scheduleVisitId);
        let scheduleSlotsQuery = new ScheduleVisitSlotModel();
        scheduleSlotsQuery.scheduleVisitId = scheduleVisitId;
        if (util.hasValue(scheduleSlotsQuery.scheduleVisitId)) {
            let result = await scheduleSlotsQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.scheduleSlots = result;
        }
    }


}

export default ScheduleSlotState;
