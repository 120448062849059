import React from 'react';
import {observer} from 'mobx-react';
import BaseForm from "../base/BaseForm";
import translate from "../../translator/translate";
import FormButtons from "../../components/FormButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import StockModel from "../../models/StockModel";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import MoreButtons from "../../components/MoreButtons";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import InfoBlock from "../../components/InfoBlock";
import util from "../../util/Util";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import GqlErrors from "../../components/status/GqlErrors";
import PropsUtil from "../../util/PropsUtil";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class StockForm extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "stock";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            loading: true,
            copyArticle: {}
        }
    }


    getModelQuery() {
        return new StockModel();
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }

    duplicar(stock) {
        this.setState(stock.id = null)
        this.handleFormSubmit()
    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    renderImpl() {
        const t = translate;
        let id = this.getFormId() || this.props.id;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let stock = this.graphDataMainType;
        if (stock == null) {
            stock = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""].map(error => error.message)
            this.state.errorFormulario = true;
        }

        return (

            <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                <InfoBlock componentObject={this}>
                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                                     title={this.getPageTitle()}
                                                     subtitle={this.getPageSubtitle()}
                                                     mode={"edit"}
                                                     onCloseModal={() => this.onCloseModal()}
                                />

                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataStocks()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                            > <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}
                            />
                            </PageTitle>
                        }

                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>

                                <div className={modalClasses.body}>
                                    <form
                                        className="model-form"
                                        name="formulario"
                                        noValidate
                                        onSubmit={(e) => this.handleFormSubmit(e)}
                                    >

                                        {!this.props.fromRightModal &&
                                        <TabsComponent
                                            id={this.graphDataMainType.id}
                                            tabs={this.tabsData.getDataStocks()}
                                            active={"Datos Generales"}
                                        />
                                        }

                                        <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                           error={this.graphStatus.mutationError}
                                                           working={this.graphStatus.networkWorking}
                                        />

                                        <GqlErrors errors={this.gqlErrors}
                                                   setErrors={(errors) => this.setErrors(errors)} />
                                        <GroupOfField title={t("Datos")}
                                                      subtitle={t("Stock")}
                                                      icon="fas fa-toolbox"
                                        >
                                            <InputTypeComponent
                                                value={stock.subInventory}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"subInventory"}
                                                title={t("Almacén")}
                                                required
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.subInventory}
                                            />
                                            <InputTypeComponent
                                                value={stock.articleCode}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"articleCode"}
                                                title={t("Referencia Artículo")}
                                                placeholder=""
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.articleCode}
                                            />
                                            <InputTypeComponent
                                                value={stock.quantity}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"quantity"}
                                                title={t("Cantidad")}
                                                placeholder=""
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.quantity}
                                                readOnly={false}
                                            />
                                            <InputTypeComponent
                                                value={stock.lote}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"lote"}
                                                title={t("Lote")}
                                                placeholder=""
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.lote}
                                                readOnly={false}
                                            />

                                        </GroupOfField>
                                    </form>

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                        <AlertModal isOpen={this.state.errorFormulario}
                                    onCloseModal={() => this.clickErroresFormulario()}
                        >
                            <PopUp
                                title={t('Error')}
                                icon="exclamationTriangleIcon"
                                text={textoErrores}
                                label1='Aceptar'
                                onClick1={() => this.clickErroresFormulario()}
                            >
                            </PopUp>
                        </AlertModal>
                        {this.props.fromRightModal ?
                            <div className=" fixed-bottom">
                                <FormButtons id={id} formStatus={this.graphStatus} />
                            </div>
                            :
                            <FormButtons id={id} formStatus={this.graphStatus} />
                        }


                </InfoBlock>

            </div>
        )
    }
}


export default StockForm