import AbstractModel from "./AbstractModel";

export default class ScheduleVisitSlotModel extends AbstractModel {
    static MAIN = "MAIN";
    static SUPPORT = "SUPPORT";
    id;
    scheduleVisitId;
    slotUserRole;
    scheduledDuration;

    nameMainType = "scheduleVisitSlot";
    graphFindByIdOperation = "scheduleVisitSlot";
    graphFindOperation = "scheduleVisitSlotsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "scheduleVisitId": "",
            "slotUserRole": "",
            "scheduledDuration": "",
        };
        return result;
    }

}
