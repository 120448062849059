import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";
import translate from "../../translator/translate";
import Overlay from "./Overlay";
import InputTypeComponent from "../fields/InputTypeComponent";
import SlotModel from "../../models/SlotModel";

@observer
class SlotsSelectInModal extends Component {

    static defaultProps = {
        values: "",
        slots: [],
        fromArticles: true,
    };

    static propTypes = {
        values: PropTypes.string, //Listado separado por comas con los WorkOrderId
        slots: PropTypes.array, //Listado de todas las WorkOrders de este pedido
        fromArticles: PropTypes.bool //Si viene de Articulos no me muestra seleccionar todas
    };

    constructor(props) {
        super(props)
    }

    onChange(e, slot) {
        e.target.name = this.props.name;
        this.props.onChange(e);
    }

    getClassNameInput() {
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let hasErrors = errors.length > 0;
        let classNameInput = "form-control " + this.props.classInput;
        if (hasErrors) {
            classNameInput += " is-invalid text-danger";
        }
        if (this.props.hasNoErrors) {
            classNameInput += " is-valid text-success";
        }
        return classNameInput;
    }

    render() {
        const t = translate;

        let values;
        let classNameInput = this.getClassNameInput();
        if (typeof this.props.values === 'number') {
            values = this.props.values.toString();
        } else values = this.props.values;
        let slotCode = appState.slotState.slots.map((workor) => {
            let slotModel = new SlotModel();
            let slotFounded = new SlotModel();
            slotModel.hidrate(workor);
            if (util.perteneceA(slotModel.id, this.props.values)) {
                slotFounded = slotModel;
            }
            return slotFounded.code;
        });
        let labelClassName;
        if (util.hasValue(this.props.labelClassName)) {
            labelClassName = "control-label " + this.props.labelClassName;
        } else {
            labelClassName = "control-label ";
        }
        return (
            <>
                {this.props.readOnly ?

                    <InputTypeComponent value={slotCode}
                                        autoComplete="new-password"
                                        className={classNameInput}
                                        title={this.props.title}
                                        classGroup={this.props.classGroup}
                                        type={"text"}
                                        readOnly={true}
                    />
                    :
                    <>
                        <div className={this.props.classGroup}>
                            {util.hasValue(this.props.title) &&
                            <div className={labelClassName}>
                                <label style={{
                                    overflowX: 'visible',
                                    color: '#650200',
                                    marginBottom: 0
                                }}>   {this.props.title}</label>
                            </div>
                            }
                            <div className={"row "} style={{ paddingLeft: 15 }}>
                                <button onClick={() => {
                                    appState.layoutState.slotsMenuOpen = true
                                }} id="btnGroupColumns" type="button"
                                        className="btn btn-outline-secondary dropdown-toggle "
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    {this.props.title}
                                </button>
                                {this.props.values != null &&
                                <div
                                    className={"col-6 mt-2 ml-2"}>{(slotCode)}
                                </div>
                                }
                            </div>
                        </div>
                        <Overlay show={appState.layoutState.slotsMenuOpen}
                                 onClick={_ => appState.layoutState.slotsMenuOpen = false} />
                        {appState.layoutState.slotsMenuOpen &&
                        <div className="table-responsive dropdown-sublist-workorder"
                             style={{ zIndex: 100, 'backgroundColor': '#fff' }}>
                            <table className=" table">
                                <tbody>
                                <tr>
                                    <th />
                                    <th>{t("Código")}</th>
                                    <th>{t("F. Planificación")}</th>
                                    <th>{t("Técnico")}</th>
                                    <th>{t("Establecimiento")}</th>
                                </tr>
                                {this.props.slots.map((slot) => {
                                    let slotModel = new SlotModel();
                                    slotModel.addRelatedTable("client");
                                    slotModel.hidrate(slot);
                                    return (
                                        <tr key={slotModel.id} className='dropdown-sublist-workorder__head'>
                                            <td scope="row">
                                                <input
                                                    type="checkbox"
                                                    style={{ display: "block" }}
                                                    name={"slot-" + slotModel.id}
                                                    checked={util.perteneceA(slotModel.id, values)}
                                                    id={"slot-" + slotModel.id}
                                                    value={slotModel.id}
                                                    onChange={(e) => this.onChange(e, slotModel)}
                                                />
                                            </td>
                                            <td>
                                                {slotModel.code}
                                            </td>
                                            <td>
                                                {util.localizeIsoDateMinutes(slotModel.scheduledTime)}
                                            </td>
                                            <td>
                                                {appState.userCacheState.getUserName(slotModel.userId) || "No asignado"}
                                            </td>
                                            <td>
                                                {slotModel?.client?.name || "No asignado"}
                                            </td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <div className="text-right mr-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={_ => appState.layoutState.slotsMenuOpen = false}
                                >{t("Cerrar")}</button>
                            </div>
                        </div>
                        }
                    </>
                }
            </>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default SlotsSelectInModal
