import React, {Component} from 'react'
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";
import {observer} from "mobx-react";
import VsfModalBootstrap from "./VsfModalBootstrap";

@observer
class ModalBaseRow extends Component {

    constructor() {
        super();
    }


    render() {
        const t = translate;
        let hijos = this.props.renderCamposModal;
        if (typeof this.props.renderCamposModal=="function") {
            hijos=this.props.renderCamposModal();
        } else {
            hijos=this.props.renderCamposModal;
        }
        return (
            <VsfModalBootstrap
                isOpen={this.props.openModal}
                title={this.props.title}
                dialogClassName={this.props.dialogClassName}
                onCloseModal={this.props.closeModal}
                titleBlock={this.props.titleBlock}
                animation={this.props.animation}
                size={"lg"}>
                {hijos}
            </VsfModalBootstrap>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ModalBaseRow
