import React from 'react';
import { ClipLoader } from 'react-spinners';
import './../scss/components/SpinnerClipLoader.scss';

class SpinnerClipLoaderComponent extends React.Component {

    DOHERTY_LIMIT = 650;


    constructor(props) {
        super(props);
        if (props.dohertyLimit!=null) {
            this.DOHERTY_LIMIT = props.dohertyLimit;
        }
        let loading=false;
        if (this.DOHERTY_LIMIT==0) {
            loading=true;
        }
        this.state = {
            loading,
            isMounted: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let callback = ()=> {
            if (this._isMounted) {
                this.setState({ ...this.state, loading: true })
            }
        }
        if (this.DOHERTY_LIMIT==0) {
            callback();
        } else {
            setTimeout(_ => {
                callback();
            }, this.DOHERTY_LIMIT);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        let classesCss = "SpinnerClipLoader text-center";
        if (this.state.loading) {
            classesCss += " loading";
        }

        return (
            <div className={classesCss}>
                <ClipLoader
                    color={'#fff'}
                    loading={this.state.loading}
                />
            </div>
        )
    }
}

export default SpinnerClipLoaderComponent
