import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import BaseForm from "./../base/BaseForm";
import translate from "../../translator/translate";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import OrderModel from "../../models/OrderModel";
import OrderFormWorkOrder from "./OrderFormWorkOrder";
import PropTypes from 'prop-types';
import VsfButtonNew from '../../components/VsfButtonNew';
import PropsUtil from "../../util/PropsUtil";
import {computed, observable} from "mobx";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import MoreButtons from "../../components/MoreButtons";
import OrderActions from "./OrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class OrderFormWorkOrders extends BaseForm {
    static defaultProps = {
        workOrderId: null,
    };
    static propTypes = {
        //Acción de descarga de Excel en el listado
        workOrderId: PropTypes.string,
    };

    @observable currentModalState = { modalOpen: false };

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            hasSomeRowEditing: false, // Muestra este formulario en modo edición en algono de los elementos
            orderFinished: false,
            removeModalActive: false,
            orderCanceled: false,
            loading: true,
        }
    }

    @computed get mobxListado() {
        return appState.workOrderState.workOrders;
    }

    setMobxListado(newValue) {
        appState.workOrderState.workOrders = newValue;
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.setMobxListado([])
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        appState.workOrderState.workOrders = this.graphDataMainType.workOrder || [];
        this.setState({ loading: false })
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.order");
        result.addRelatedTable("workOrder.documents");
        result.addRelatedTable("workOrder.asset");
        result.addRelatedTable("workOrder.workOrderCartArticle")
        result.addRelatedTable("workOrderCartArticle");
        result.addRelatedTable("client");
        result.addRelatedTable("slot");
        return result;
    }

    getModelQuery() {
        return new OrderModel();
    }


    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        let order = this.graphDataMainType;
        if (order == null) {
            order = {}
        }
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                {this.graphDataMainType.id &&
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal}
                                                  setErrors={(errors) => this.setErrors(errors)} model={order}
                                                  getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                                }
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active="Órdenes de Trabajo"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal}
                                              setErrors={(errors) => this.setErrors(errors)} model={order}
                                              getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }


                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active={"Órdenes de Trabajo"}
                            />
                            }
                            <GroupOfField
                                title={(this.mobxListado?.length > 0) ? t('Creadas') : t('Todavia no has añadido ninguna orden de trabajo')}
                                subtitle={(this.mobxListado?.length > 0) ? this.mobxListado.length + " " + t('Órdenes de trabajo') : ''}
                                icon='fas fa-file-import' />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="table-edit second">
                                    <div className="">
                                        <table className="table">
                                            <thead>

                                            {this.mobxListado?.length > 0 &&
                                            <tr>
                                                <th scope="col">{t('Numero de Orden de trabajo')}</th>
                                                <th scope="col">{t('Numero de equipo')}</th>
                                                <th scope="col">{t('Estado Técnico')}</th>
                                                <th scope="col">{t('Tipo de Equipo')}</th>
                                                <th scope="col">{t('Subtipo de Equipo')}</th>
                                                <th scope="col">{t('Tipo de OT')}</th>
                                                {/*<th scope="col">{t('Subtipo de OT')}</th>*/}
                                                {/*<th scope="col">{t('Adjuntos')}</th>*/}
                                                <th scope="col">{t('Piezas Usadas')}</th>
                                                <th colSpan="3">&nbsp;</th>

                                            </tr>
                                            }
                                            </thead>
                                            <tbody>
                                            {this.mobxListado?.map((workOrder, index) => (
                                                    <OrderFormWorkOrder
                                                        key={workOrder.id || 0}
                                                        row={workOrder}
                                                        orderId={this.getFormId()}
                                                        lastRow={this.mobxListado.length == 1}
                                                        rowIndex={index}
                                                        {...this.props}
                                                        remove={this.onRemoveWO}
                                                    />
                                                )
                                            )}
                                            </tbody>
                                        </table>

                                    </div>
                                    {!appState.configState.checkOrderCocaCola(order) &&
                                    <div className="mb-2">
                                        <div className="text-right">
                                            <VsfButtonNew
                                                label={t("Crear Nueva")}
                                                showButton={(this.creatable["workOrder"] || false) && this.editable}
                                                onClick={() => this.createdWO()}
                                                classGroup={'button-form-group rext-right col-12'}
                                                className="btn btn--loader btn--max"
                                            />
                                        </div>
                                    </div>
                                    }
                                    <AlertModal isOpen={this.state.orderCanceled}
                                                onCloseModal={() => this.setState({ "orderCanceled": false })}>
                                        <PopUp
                                            title={t('Operación no permitida')}
                                            icon={"exclamationTriangleIcon"}
                                            text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                                            label1={'Cerrar'}
                                            onClick1={() => this.setState({ orderCanceled: false })}
                                        />
                                    </AlertModal>
                                    <AlertModal isOpen={this.state.orderFinished}
                                                onCloseModal={() => this.setState({ "orderFinished": false })}>
                                        <PopUp
                                            title={t('Operación no permitida')}
                                            icon={"exclamationTriangleIcon"}
                                            text={t(`Este Pedido está finalizado, para poder añadir una Orden de Trabajo tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                                            label1={'Cerrar'}
                                            onClick1={() => this.setState({ orderFinished: false })}
                                        />
                                    </AlertModal>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>

                </InfoBlock>
            </div>
        )
    }

    onRemoveWO() {
        return this.propsUtil.changeUrlRequest({ rightModal: "3" });
    }

    createdWO() {
        let order = this.graphDataMainType;
        if (appState.typifiedState.getObject(order.status)?.code === "CLOSED") {
            this.log("El pedido " + order.id + " está finalizado. No puedes crear mas WO.");
            this.setState({ orderFinished: true });
        } else if (appState.typifiedState.getObject(order.status)?.code === "CANCELED") {
            this.setState({ orderCanceled: true });
        } else {
            return this.propsUtil.changeUrlRequest({
                "rightModalTab": "workorder",
                "rightModal": "modalworkorderls",
                "orderId": order.id,
                'clientId': order.client.id
            });
        }
    }

}

export default withRouter(OrderFormWorkOrders)


