import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import WarehouseModel from "./WarehouseModel";

export default class ContractModel extends AbstractModel {

    name;
    categoryId;
    type;
    paymentMethod;
    paymentType;
    bill;

    nameMainType = "contract";
    graphFindByIdOperation = "contract";
    graphFindOperation = "contractsConnection";
    graphExportOperation = "contractsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name" : { label: "Nombre"},
            "categoryId" : { label: "Categoría"},
            "paymentMethod" : { label: "Forma de pago"},
            "paymentType" : { label: "Tipo de pago"},
            "type" : { label: "Tipo"},
            "bill" : { label: "Factura"},
        };
        //this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        return result;
    }

};
