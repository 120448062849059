import { computed, observable } from 'mobx';
import UserModel from "../models/UserModel";
import TypifiedValueModel from "../models/TypifiedValueModel";
import util from "../util/Util";
import appState from './AppState';
import React from 'react';
import IconsSelectCapactitation from "../components/fields/IconsSelectCapactitation";
import AppLogger from "../util/AppLogger";
import CacheDataFactory from "../models/cacheData/CacheDataFactory";

class UserCacheState {

    //bloque user
    @observable users = [];
    @observable usersTechnicals = [];

    @computed get userCacheForSelectList() {
        let result = [];
        for (let user of this.usersTechnicals) {
            result.push({
                value: user.id,
                label: ((user.firstName != null ? user.firstName : "") + ' ' + (user.lastName != null ? user.lastName : ""))
            })
        }
        return result;
    }

    @computed get userCacheForSelect() {
        let result = [];
        for (let user of this.usersTechnicals) {
            if (util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "TECHNICIAN").id, user.role)) {
                let zonas = "";
                let valores = user.zoneAsignedId?.split(",");
                if (valores?.length > 0) {
                    for (let zona of valores) {
                        if (appState.typifiedState.getValue(zona)) {
                            zonas += appState.typifiedState.getValue(zona) + " ";
                        }
                    }
                }
                let key = ((user.firstName != null ? user.firstName : "") + ' ' + (user.lastName != null ? user.lastName : "") + " " +
                    (util.hasValue(zonas) ? (" - " + zonas) : ""));
                result.push({
                    value: user.id,
                    label: <IconsSelectCapactitation key={user.firstName} color={"#3D708D"} iconClass={"col-3"}
                                                     search={user.firstName + " " + user.lastName}
                                                     select={true} usuario={user}
                                                     postfix={user.capacitationIds}>{key}</IconsSelectCapactitation>
                })
            }
        }
        return result;
    }

    async loadUserCache() {
        if (this.users.length === 0) {
            let userModel = new UserModel();
            userModel.status = UserModel.STATUS_ACTIVO;
            userModel.addRelatedTable("dateRangeUser");
            userModel.addRelatedTable("userOnline");
            userModel.orderBy = "firstName";
            let usersLoaded = await userModel.find();
            let systemUser = new UserModel();
            systemUser.id = "00000000-0000-0000-0000-000000000000";
            systemUser.firstName = "System";
            systemUser.email = "system";
            usersLoaded.push(systemUser);
            this.users = usersLoaded;
        }
    }

    async loadUserCacheTechnicals() {
        this.usersTechnicals = await CacheDataFactory.getUsersTech();

    }

    /**
     * Obtiene el objeto cliente desde el array de clientes (cargados en memoria)
     * @param userId
     */
    getUserById(userId) {
        let plainUserFounded = {};
        for (let user of [...this.users, ...this.usersTechnicals]) {
            if (user.id === userId) {
                plainUserFounded = user;
            }
        }
        return plainUserFounded;
    }


    getUserName(userId) {
        let completeName = this.getUserById(userId)?.firstName;
        if (util.hasValue(this.getUserById(userId)?.lastName)) {
            completeName += " " + this.getUserById(userId)?.lastName;
        }
        return completeName
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default UserCacheState;
