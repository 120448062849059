import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class BudgetState extends BaseModelState {
    @observable budget = [];


}

export default BudgetState;
