import AppLogger from "../util/AppLogger";
import appState from '../state/AppState';

export default class LocalStorage {

    constructor() {
        this.debug = false;
        this.withAuthorization = false;
    }

    getItemTenantId(name) {
        let tenantId = appState.loginState.user?.tenantId;
        return window.localStorage.getItem(tenantId + "_" + name);
    }

    setItemTenantId(name, value) {
        let tenantId = appState.loginState.user?.tenantId;
        return window.localStorage.setItem(tenantId + "_" + name, value);
    }

    getItem(name) {
        return window.localStorage.getItem( name);
    }

    setItem(name, value) {
        return window.localStorage.setItem(name, value);
    }

    // Logea en consola el mensaje. Sólo útil en depuración
    log(msg) {
        if (this.debug) {
            AppLogger.get().debug(msg, this);
        }
    }


}
