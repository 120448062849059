import React from 'react';
import {observer} from 'mobx-react';
import BaseEditableRow from "../base/BaseEditableRow";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import CheckWorkDaysModel from "../../models/CheckWorkDaysModel";
import DateInputHoursMobiscroll from "../../components/fields/DateInputHoursMobiscroll";
import appState from '../../state/AppState';
import {computed, toJS} from "mobx";
import GraphException from "../../network/GraphException";
import GqlErrors from "../../components/status/GqlErrors";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import UserEndJourney from "./UserEndJourney";

@observer
class UserCheckInRow extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.state = {
            mouseEnter: false
        };
        this.eliminar = false;

    }

    @computed get mobxListado() {
        return appState.checkWorkDaysState.checkWorkDays;
    }
    setMobxListado(newValue) {
        appState.checkWorkDaysState.checkWorkDays = newValue;
    }

    /**
     * Cambiar por el modelo que se quiera conseguir
     * return  new ContactModel();
     */
    getModelTable() {
        return new CheckWorkDaysModel();
    }

    /**
     * Guarda e en base de datos
     * @returns {Promise<void>}
     */
    async onSaveRow() {
        let modelo = this.getModelTable();
        appState.loadingBarState.start();
        appState[this.appStateKeyAfterSave].mutationError = 0;
        appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = [];
        modelo.hidrate(this.props.row[CheckWorkDaysModel.END_DAY]);
        try {
            await modelo.persist();
            this.props.row[CheckWorkDaysModel.END_DAY].isEditing = false;
            this.previousRowForDiscard = null;
            this.props.setHasSomeRowEditing(false);
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.log({ errors });
            if (errors[0].extensions && errors[0].extensions.data && errors[0].extensions.data.field === "WarningException") {
                this.gqlWarnings = errors;
            } else {
                this.gqlErrors = errors;
                appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = errors;
                appState[this.appStateKeyAfterSave].mutationError = 1;
            }
        }
        appState.loadingBarState.finalize();
    };

    editButtonsDisabled() {
        return (this.state.conflict || this.state.totalTime > CheckWorkDaysModel.MAX_TIME_JOURNEY)
    }


    handleInputChange(event) {
        let jornada = this.props.row;
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(jornada);
        }
        if (jornada[CheckWorkDaysModel.END_DAY] == null) {
            jornada[CheckWorkDaysModel.END_DAY] = {};
            jornada[CheckWorkDaysModel.END_DAY].userId = this.props.graphDataMainType.id;
            jornada[CheckWorkDaysModel.END_DAY].action = CheckWorkDaysModel.END_DAY;
        }
        jornada[CheckWorkDaysModel.END_DAY][event.target.name] = event.target.value;
        let totalTime = util.calcularDuracionEntreHoras(jornada[CheckWorkDaysModel.START_DAY].time, jornada[CheckWorkDaysModel.END_DAY].time);
        let jornadaSiguiente = this.mobxListado[this.props.rowIndex - 1] || {};
        let conflict = util.hasValue(jornadaSiguiente[CheckWorkDaysModel.START_DAY]?.time) &&
            (util.getMoment(jornada[CheckWorkDaysModel.END_DAY].time).format("MMDDHHmm") > util.getMoment(jornadaSiguiente[CheckWorkDaysModel.START_DAY].time).format("MMDDHHmm"));
        this.setState({ totalTime, conflict })
        if (this.props.onChangeRow != null) {
            this.props.onChangeRow(this.props.rowIndex, jornada[CheckWorkDaysModel.END_DAY])
        }

    };


    render() {
        const t = translate;
        let jornada = this.props.row;
        let dateFilter = this.props.dateFilter;
        if (util.hasValue(jornada[CheckWorkDaysModel.START_DAY]?.time) && dateFilter === util.getMoment(jornada[CheckWorkDaysModel.START_DAY]?.time).format("YYYYMM")) {
            return (
                <>
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                    <tr key={jornada[CheckWorkDaysModel.START_DAY]?.id}>
                        <td>{util.getMoment(jornada[CheckWorkDaysModel.START_DAY]?.time).format("DD-MM-YY")}</td>
                        <td>{util.getMoment(jornada[CheckWorkDaysModel.START_DAY]?.time).format("DD-MM-YY HH:mm")}</td>
                        <td>
                            {jornada.isEditing ?
                                <DateInputHoursMobiscroll
                                    prefix={"fas fa-calendar-alt"}
                                    value={jornada[CheckWorkDaysModel.END_DAY]?.time}
                                    onChange={(e) => this.handleInputChange(e)}
                                    minDate={jornada[CheckWorkDaysModel.START_DAY]?.time}
                                    name={'time'}
                                    allowEmptyDate={true}
                                    errors={jornada.time} />
                                :
                                <>
                                    {jornada[CheckWorkDaysModel.END_DAY] ? util.getMoment(jornada[CheckWorkDaysModel.END_DAY].time).format("DD-MM-YY HH:mm") : "--"}
                                </>
                            }
                        </td>
                        <td>
                            {(util.hasValue(jornada[CheckWorkDaysModel.START_DAY]?.time) && util.hasValue(jornada[CheckWorkDaysModel.END_DAY]?.time)) &&
                            util.cancularDuracionEntreHorasEnMinutos(jornada[CheckWorkDaysModel.START_DAY]?.time, jornada[CheckWorkDaysModel.END_DAY]?.time)}
                        </td>
                        <td>
                            {jornada.isEditing ?
                                <InputTypeComponent
                                    value={jornada[CheckWorkDaysModel.END_DAY]?.extra}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={"extra"}
                                />
                                :
                                <>
                                    {jornada[CheckWorkDaysModel.END_DAY]?.extra}
                                </>
                            }
                        </td>
                        <td>
                            {!util.hasValue(jornada[CheckWorkDaysModel.END_DAY]?.time) ?
                                <UserEndJourney userId={this.props.graphDataMainType.id}
                                                reload={() => this.props.reload()}
                                                row={jornada}
                                />
                                :
                                this.renderEditBlock()
                            }
                        </td>
                    </tr>
                    {(this.state.totalTime > CheckWorkDaysModel.MAX_TIME_JOURNEY || this.state.conflict) &&
                    <tr aria-rowspan="2" className={jornada.isEditing && "editing"}>
                        <td colSpan={5}>
                            {this.state.totalTime > CheckWorkDaysModel.MAX_TIME_JOURNEY &&
                            <span className={"text-danger"}>
                            <i className="fas exclamationTriangleIcon" />
                                {t('La jornada no puede ser superior a 16 horas')}
                                   </span>
                            }
                            {this.state.conflict &&
                            <span className={"text-danger"}>
                            <i className="fas exclamationTriangleIcon" />
                                {t('La jornada no puede ser superior al inicio de jornada siguiente')}
                                   </span>
                            }
                        </td>
                    </tr>
                    }

                </>

            );
        } else {
            return null;
        }
    }

    onDiscardRow() {
        let jornada = this.props.row;
        if (this.previousRowForDiscard != null) {
            for (let i = 0; i < this.mobxListado.length; i++) {
                let row = this.mobxListado[i];
                if (row?.[CheckWorkDaysModel.START_DAY]?.id === jornada[CheckWorkDaysModel.START_DAY]?.id) {
                    this.mobxListado[i] = this.previousRowForDiscard;
                }
            }
        }
        this.props.row.isEditing = false;
        this.props.setHasSomeRowEditing(false);
        this.previousRowForDiscard = null;
        this.setState({ conflict: false, totalTime: 0 })
    }

    onSaveRowImpl() {
        let jornada = this.props.row;
        try {
            if (util.calcularDuracionEntreHoras(jornada[CheckWorkDaysModel.START_DAY]?.time, jornada[CheckWorkDaysModel.END_DAY]?.time) < 0 ||
                util.calcularDuracionEntreHoras(jornada[CheckWorkDaysModel.START_DAY]?.time, jornada[CheckWorkDaysModel.END_DAY]?.time) > CheckWorkDaysModel.MAX_TIME_JOURNEY) {
                let errorMessages = [{ message: "La jornada debe ser mayor de 0 horas y menor que 16 horas" }];
                throw new GraphException({ message: errorMessages[0].message, errors: errorMessages });
            } else {
                this.onSaveRow();
                this.setState({ conflict: false, totalTime: 0 })
            }
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.log({ errors });
            if (errors[0].extensions && errors[0].extensions.data && errors[0].extensions.data.field === "WarningException") {
                this.gqlWarnings = errors;
            } else {
                this.gqlErrors = errors;
                appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = errors;
                appState[this.appStateKeyAfterSave].mutationError = 1;
            }
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default UserCheckInRow
