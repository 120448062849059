import AbstractModel from "./AbstractModel";

export default class FormQuestionModel extends AbstractModel {

    static CODE_TEXT = "CODE_TEXT";
    static CODE_NUMBER = "CODE_NUMBER";
    static CODE_SELECT = "CODE_SELECT";
    static CODE_DATE = "CODE_DATE";
    static CODE_RADIO_BUTTON = "CODE_RADIO_BUTTON";
    static CODE_SWITCH = "CODE_SWITCH";

    title;
    code;
    formId ;
    questionOrder ;
    questionType ;
    inputType ;
    length ;
    required;
    optionValues;
    displayOnlyIf;

    nameMainType = "formQuestion";
    graphFindByIdOperation = "formQuestion";
    graphFindOperation = "formQuestionsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "title": "",
            "code": "",
            "formId": "",
            "questionOrder": "",
            "questionType": "",
            "inputType": "",
            "length": "",
            "required": "",
            "optionValues": "",
            "displayOnlyIf": "",

        };
        return result;
    }

}
