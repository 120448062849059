import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import appState from '../../state/AppState'
import BaseForm from '../base/BaseForm'
import translate from '../../translator/translate'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import Select2Component from '../../components/fields/Select2Component'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import BudgetModel from '../../models/BudgetModel'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import util from '../../util/Util'
import InfoBlock from '../../components/InfoBlock'
import VsfButtonNew from '../../components/VsfButtonNew'
import GqlErrors from '../../components/status/GqlErrors'
import WorkOrderModel from '../../models/WorkOrderModel'
import WorkOrdersSelectInModal from '../../components/modals/WorkOrdersSelectInModal'
import OrderFormBudgetArticlesRow from '../orders/OrderFormBudgetArticlesRow'
import BudgetArticleModel from '../../models/BudgetArticleModel'
import { observable, toJS } from 'mobx'
import TextAreaComponent from '../../components/fields/TextAreaComponent'
import ClientModel from '../../models/ClientModel'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import OrderModel from '../../models/OrderModel'
import CreateAndModify from '../../components/fields/CreateAndModify'
import MoreButtons from '../../components/MoreButtons'
import AutoSuggestComponent from '../../components/fields/AutoSuggestComponent'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import config from '../../config/config'
import FetchProxy from '../../network/FetchProxy'
import BudgetActions from './BudgetActions'
import AssetModel from '../../models/AssetModel'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'

@observer
class BudgetForm extends BaseForm {

    nameMainType = 'budget'
    @observable clientModel = {}
    @observable textPopUp = '¿Seguro que desea enviar a Netsuite el presupuesto?'
    @observable budgetSent = false

    constructor (props) {
        super(props)
        this.graphData.data[this.nameMainType] = {}
        this.tabsData = new TabsData()
        this.queryFieldWithId = 'budgetId'
        this.state = {
            errorFormulario: false,
            loading: true,
        }
    }

    async componentDidMountImpl () {
        await super.componentDidMountImpl()
        await this.getPedido()
        await this.getWorkOrder()
        await this.getClientPedido()
        if (!util.hasValue(this.graphDataMainType.id)) {
            this.graphDataMainType.status = BudgetModel.CODE_BORRADOR
            this.graphDataMainType.budgetArticles = [{}]
            this.graphDataMainType.orderId = this.props.orderId
            this.graphDataMainType.clientId = this.props.clientId
            if (this.props.workOrderId) {
                this.graphDataMainType.workOrderId = this.props.workOrderId
            }
        } else {
            appState.workOrderState.workOrders = this.graphDataMainType?.order?.workOrder || []
        }
        if (!util.hasValue(this.graphDataMainType.id) || this.graphDataMainType.budgetArticles.length === 0) {
            if (this.previousRowForDiscard == null) {
                this.previousRowForDiscard = toJS(this.graphDataMainType)
            }
            this.graphDataMainType.budgetArticles = [{}]
        }
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType)
        }
        if (this.graphDataMainType.createdFrom === 'Netsuite') {
            this.editable = false
        }
        this.setState({ loading: false })
        // this.log({ budget: this.graphDataMainType })
    }

    getModelQueryForView () {
        let result = new BudgetModel()
        result.addRelatedTable('budgetArticles')
        result.addRelatedTable('order')
        result.addRelatedTable('order.workOrder')
        result.addRelatedTable('propietary')
        result.addRelatedTable('budget')
        result.addRelatedTable('documents')
        result.addRelatedTable('workOrder')
        result.addRelatedTable('workOrder.asset')
        result.addRelatedTable('client')
        result.addRelatedTable('client.parentClient')
        return result
    }

    getModelQuery () {
        return new BudgetModel()
    }

    async getPedido () {
        if (!util.hasValue(this.graphDataMainType.id) && util.hasValue(this.propsUtil.getRequest('orderId'))) {
            let orderQuery = new OrderModel()
            orderQuery.id = this.propsUtil.getRequest('orderId')
            this.graphDataMainType.order = await orderQuery.findFirst()
        }
    }

    async getWorkOrder () {
        if (!util.hasValue(this.graphDataMainType.id) && util.hasValue(this.propsUtil.getRequest('workOrderId'))) {
            let query = new WorkOrderModel()
            query.id = this.propsUtil.getRequest('workOrderId')
            query.addRelatedTable('asset')
            this.graphDataMainType.workOrder = await query.findFirst()
            if (this.graphDataMainType.workOrder?.asset?.id) {
                this.graphDataMainType.assetId = this.graphDataMainType.workOrder?.asset?.id
            }
        }
    }

    async getClientPedido () {
        if (!util.hasValue(this.graphDataMainType.id) && util.hasValue(this.propsUtil.getRequest('clientId'))) {
            let clientQuery = new ClientModel()
            clientQuery.id = this.propsUtil.getRequest('clientId')
            clientQuery.addRelatedTable('parentClient')
            this.graphDataMainType.client = await clientQuery.findFirst()
        }
    }

    async formDidSave (model, previousId) {
        if (model.status !== BudgetModel.CODE_PENDING_ERP) {
            for (let budgetArticles of this.graphDataMainType.budgetArticles) {
                if (util.hasValue(budgetArticles.articleCode)) {
                    let budgetArticlesModel = new BudgetArticleModel()
                    budgetArticlesModel.hidrate(budgetArticles)
                    budgetArticlesModel.budgetId = this.graphDataMainType.id
                    budgetArticlesModel.workOrderId = this.graphDataMainType.workOrderId
                    await budgetArticlesModel.persist()
                }
            }
        }
        if (this.ifPathStartsWith('/order')) {
            await this.actualizaListadBudgets(model, previousId)
        }
        if (this.props.fromRightModal) {
            this.propsUtil.changeUrlRequest({ 'budgetId': model.id })
            await this.reloadData()
        }
    }

    async actualizaListadBudgets (model, previousId) {
        if (util.hasValue(previousId)) {
            for (let i = 0; i < appState.budgetState.budget.length; i++) {
                if (appState.budgetState.budget[i].id == model.id) {
                    appState.budgetState.budget[i] = model
                }
            }
        } else {
            appState.budgetState.budget.push(model)
        }
    }

    catchErrImpl () {
        setTimeout(() => {
            let arrayFields = new BudgetModel().getArrayFields()
            for (let [nameField, value] of Object.entries(arrayFields)) {
                if (value.type !== 'Relation' && value.type !== 'RelationOne' && nameField !== 'id') {
                    if (this.previousRowForDiscard?.[nameField]) {
                        this.graphDataMainType[nameField] = this.previousRowForDiscard[nameField]
                    }
                }
            }
        }, 2000)

    }

    /**
     * Obtiene los Assets en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @param firstCall
     * @returns Array
     */
    async getAssetsSugestions (value, firstCall) {
        let assetsArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            if (util.hasValue(value) || util.hasValue(this.graphDataMainType.assetType) || firstCall) {
                // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
                this.initializeAbortController()
                let assetsQuery = new AssetModel()
                if (firstCall) {
                    assetsQuery.id = this.graphDataMainType.assetId
                }
                let assetClient = await assetsQuery.findPlainObject()
                let assets = assetClient
                let assetCopy = {}
                for (let asset of assets) {
                    if (assetCopy[asset.id] == null) {
                        let assetObject = {}
                        let label = asset.plate
                        if (asset?.serialNumber) {
                            label += ' - ' + asset?.serialNumber
                        }
                        if (asset?.status) {
                            label += ' - ' + appState.typifiedState.getValue(asset?.status)
                        }
                        assetObject.label = label
                        assetObject.value = asset
                        assetsArray.push(assetObject)
                        assetCopy[asset.id] = asset
                    }
                }
            }
        } catch (e) {
            this.log('Error => ' + e)
        }
        return assetsArray
    }

    async getClientsSuggestions (value) {
        let clientArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            let clientQuery = new ClientModel()
            clientQuery.isPos = true
            clientQuery.filters = [
                {
                    'fieldName': 'name',
                    'fieldValue': value,
                    'filterOperator': 'SUBSTR'
                },
            ]
            let clients = await clientQuery.find()
            for (let client of clients) {
                let clientObject = {}
                clientObject.label = client.name
                clientObject.value = client
                clientArray.push(clientObject)
            }
        } catch (e) {
            this.log('Error => ' + e)
        }
        return clientArray
    }

    async onChangeClientId (clientId) {
        let order = this.graphDataMainType
        await appState.contactState.reloadContactsFromClientId(order.clientId, util.hasValue(order.scheduledDate))
        if (util.hasValue(clientId)) {
            this.handleAutosuggestSelection('clientId', clientId)
            await appState.clientState.reloadClientFromClientId(clientId)
            this.graphDataMainType.client = appState.clientState.client
        }
    }

    calculaCantidad () {
        let result = 0
        let articulos = this.graphDataMainType.budgetArticles || []
        for (let articulo of articulos) {
            if (articulo.quantity != null) {
                if (result === 0)
                    result = (util.formatDecimal(articulo.quantity))
                else if (articulo.quantity !== '') {
                    result += (util.formatDecimal(articulo.quantity))
                }
            }
        }

        return result
    }

    async loadPrizeArticle (articleSelected) {
        let httpApi = new FetchProxy()
        httpApi.withAuthorization = true
        let result = ''
        try {
            this.loading = true
            let response = await httpApi.fetchUrlPost(config.apiHostInt + '/priceRequestNetsuite/PriceRequest',
                {
                    'article': {
                        'code': articleSelected.articleCode,
                        'codeErp': articleSelected.codeErp
                    },
                    'client': {
                        'code': this.graphDataMainType.client?.parentClient?.code,
                        'codeErp': this.graphDataMainType.client?.parentClient?.codeErp
                    }
                }
            )
            let responseJson = await response.json()
            this.log(responseJson)
            result = responseJson.data?.price

        } catch (e) {
            this.log(e)
        }
        return result
    }

    async updatePrices () {
        this.loadingPrices = true
        if (this.graphDataMainType.budgetArticles.length > 0) {
            for (let article of this.graphDataMainType.budgetArticles) {
                article.prizeWithoutTax = await this.loadPrizeArticle(article)
                appState.layoutState.formWithoutChanges = false
            }
        }
        this.loadingPrices = false
    }

    @observable loadingPrices = false

    calculaPrecio (withTax) {
        let result = parseFloat(0)
        let articulos = this.graphDataMainType.budgetArticles || []
        for (let articulo of articulos) {
            if (util.hasValue(articulo.quantity)) {
                if (withTax) {
                    if (util.hasValue(articulo.prizeWithTax)) {
                        result += (util.formatDecimal(articulo.prizeWithTax)) * (util.formatDecimal(articulo.quantity))
                    } else {
                        result += (util.formatDecimal(0))
                    }
                } else {
                    if (util.hasValue(articulo.prizeWithoutTax)) {
                        result += (util.formatDecimal(articulo.prizeWithoutTax)) * (util.formatDecimal(articulo.quantity))
                    } else {
                        result += (util.formatDecimal(0))
                    }
                }

            }
        }
        return result.toFixed(2)
    }

    renderImpl () {
        const t = translate
        let modalClasses = util.getModalClasses()
        this.propsUtil = new PropsUtil(this.props)

        let budget = this.graphDataMainType
        if (budget == null) {
            budget = {}
        }
        let workOrderCode = appState.workOrderState.workOrders.map((workor) => {
            let workOrderModel = new WorkOrderModel()
            let workOrderFounded = new WorkOrderModel()
            workOrderModel.hidrate(workor)
            if (workOrderModel.id === this.state.workOrderId) {
                workOrderFounded = workOrderModel
            }
            return workOrderFounded.code
        })
        let options = [
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_BORRADOR, this.props),
                value: BudgetModel.CODE_BORRADOR
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_APPROVED, this.props),
                value: BudgetModel.CODE_APPROVED
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_APPROVED_SCHEDULED, this.props),
                value: BudgetModel.CODE_APPROVED_SCHEDULED
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_NOT_APPROVED, this.props),
                value: BudgetModel.CODE_NOT_APPROVED
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_NOT_APPROVED_CLOSED, this.props),
                value: BudgetModel.CODE_NOT_APPROVED_CLOSED
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_CANCEL, this.props),
                value: BudgetModel.CODE_CANCEL
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_PENDING_ERP, this.props),
                value: BudgetModel.CODE_PENDING_ERP
            },
            {
                label: BudgetModel.getTextFromCode(BudgetModel.CODE_EXPIRED, this.props),
                value: BudgetModel.CODE_EXPIRED
            },
        ]
        let errorsMapped = this.getGraphErrorsFromStatus()
        let cantidad = this.calculaCantidad()
        let totalWithTax = this.calculaPrecio(true)
        let totalWithoutTax = this.calculaPrecio(false)
        let relatedInfo = ''
        if (util.hasValue(this.graphDataMainType.dateApproved)) {
            relatedInfo = t('Presupuesto aprobado el') + ': ' + util.getMoment(this.graphDataMainType.dateApproved)
                .format('DD-MM-YYYY')
        }
        if (util.hasValue(this.graphDataMainType.dateRejected)) {
            relatedInfo = t('Presupuesto cancelado el') + ': ' + util.getMoment(this.graphDataMainType.dateRejected)
                .format('DD-MM-YYYY')
        }
        let assetLabel = budget.workOrder?.asset?.plate
        if (budget.workOrder?.asset?.serialNumber) {
            assetLabel += ' - ' + budget.workOrder?.asset?.serialNumber
        }
        if (budget.workOrder?.asset?.status) {
            assetLabel += ' - ' + appState.typifiedState.getValue(budget.workOrder?.asset?.status)
        }
        let relatedInfoAsset = ''
        if (util.hasValue(budget.workOrder?.asset?.manufacturer)) {
            relatedInfoAsset = util.getString(budget.workOrder?.asset?.manufacturer)
        }
        if (util.hasValue(budget.workOrder?.asset?.model)) {
            relatedInfoAsset += ' - ' + util.getString(budget.workOrder?.asset?.model)
        }
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ''}>
                <InfoBlock componentObject={this}>
                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmit(e)}>

                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                    title={t('Presupuesto')}
                                    subtitle={budget.code || 'Nuevo'}
                                    mode={'edit'}
                                    onCloseModal={() => this.onCloseModal()}
                                />

                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataBudget()}
                                    active={'Datos Generales'}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={' '}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                            >
                                <MoreButtons
                                    onReloadData={() => this.reloadData()}
                                >
                                    <BudgetActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)}
                                        model={this.graphDataMainType}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitle>

                        }

                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className={modalClasses.body}>
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                    {!this.props.fromRightModal &&
                                    <TabsComponent
                                        id={this.graphDataMainType.id}
                                        tabs={this.tabsData.getDataBudget()}
                                        active="Datos Generales"
                                        fromRightModal={this.props.fromRightModal}

                                    />
                                    }
                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                        error={this.graphStatus.mutationError}
                                        working={this.graphStatus.networkWorking}
                                    /><GroupOfField title={t('Presupuesto')}
                                        subtitle={this.graphDataMainType.articleCode}
                                        icon="fas fa-calendar"
                                    />

                                    <CreateAndModify
                                        updatedAt={budget.updatedAt}
                                        updatedBy={budget.updatedBy}
                                        createdBy={budget.createdBy}
                                        createdAt={budget.createdAt}
                                        fromOrder={true}
                                        getFormId={budget.id}
                                    />
                                    <div className="row">
                                        <p className="o-parraf col-12 js-info-related admin ">{t('budget-form-description')}</p>
                                        <InputTypeComponent value={budget.order?.code}
                                            autoComplete="new-password"
                                            title={t('Pedido ')}
                                            classGroup={'col-3'}
                                            type={'text'}
                                            readOnly={true}
                                        />
                                        {!util.hasValue(this.props.workOrderId) && this.editable && !appState.userState.isUserRoleClient() &&
                                        <WorkOrdersSelectInModal
                                            classGroup={'col-md-3'}
                                            fromArticles={true}
                                            workOrders={appState.workOrderState.workOrders}
                                            readOnly={this.graphDataMainType.id} //{this.modelPlainFirstLoaded.workOrderId}//
                                            values={this.graphDataMainType.workOrderId}
                                            title={t('Orden de trabajo ')}
                                            onChange={(e) => {
                                                this.updateInputEvent({
                                                    target: {
                                                        name: 'workOrderId',
                                                        value: e.target.value
                                                    }
                                                })
                                                appState.layoutState.favouritesDropMenuOpen = false
                                            }}
                                        />
                                        }
                                        <AutoSuggestComponent
                                            value={budget.client?.name}
                                            onChange={(clientSelected) => {
                                                if (util.hasValue(clientSelected.id)) {
                                                    this.handleAutosuggestSelection('clientId', clientSelected.id)
                                                    this.onChangeClientId()
                                                    budget.client = clientSelected
                                                } else {
                                                    budget.clientId = ''
                                                    budget.client = {}

                                                }
                                            }}
                                            name="clientId"
                                            readOnly={util.hasValue(this.graphDataMainType.workOrderId)}
                                            title={t('Establecimiento')}
                                            classGroup={'col-md-3 col-xl-3'}
                                            errors={errorsMapped.clientName}
                                            loadSuggestions={(value) => this.getClientsSuggestions(value)}
                                            placeholder={t('Escribe...')}
                                            relatedInfo={relatedInfo}
                                        />
                                        <InputTypeComponent
                                            value={budget.propietary?.companyName}
                                            name="propietaryId"
                                            readOnly={true}
                                            title={t('Cliente Fiscal')}
                                            classGroup={'col-md-3 col-xl-3'}
                                            errors={errorsMapped.propietaryId}
                                        />
                                        <AutoSuggestComponent
                                            value={assetLabel}
                                            loadSuggestions={(value, firstCall) => this.getAssetsSugestions(value, firstCall)}
                                            onChange={(event) => {
                                                if (util.hasValue(event.target.value.id)) {
                                                    budget.workOrder.asset = event.target.value
                                                } else {
                                                    budget.workOrder.asset = {}
                                                }
                                            }}
                                            placeholder={t('Escribe...')}
                                            readOnly={true}
                                            name={'assetPlate'}
                                            title={t('Número de Equipo')}
                                            editable={this.editable}
                                            classGroup={'col-md-3 col-lg-3'}
                                            errors={errorsMapped.assetPlate}
                                            info={t('Matrícula o Placa')}
                                            relatedInfo={relatedInfoAsset}
                                        />
                                        <Select2Component
                                            value={this.graphDataMainType.status}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'status'}
                                            title={t('Estado')}
                                            readOnly={appState.configState.validateStatusBudget(this.graphDataMainType.status)}
                                            editable={this.editable}
                                            classGroup="col-md-3 inputheight-s"
                                            options={options}
                                            isClearable={false}
                                            errors={errorsMapped.status}
                                        />
                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            withMinMaxDate={true}
                                            readOnly={true}
                                            classGroup={'col-3'}
                                            value={this.graphDataMainType.endDateBudget}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            title={t('Caducidad del presupuesto')}
                                            name={'endDateBudget'}
                                            type={'text'}
                                            relatedInfo={relatedInfo}
                                            minDate={util.getMoment()}
                                            allowEmptyDate={true}
                                            errors={errorsMapped.endDateBudget}
                                        />

                                        <InputTypeComponent
                                            value={budget.codeErpVisible}
                                            editable={this.editable}
                                            readOnly={true}
                                            name={'codeErpVisible'}
                                            title={t('Código Erp')}
                                            classGroup="col-md-3 inputheight-s"
                                            errors={errorsMapped.codeErpVisible}
                                        />
                                        <Select2Component
                                            options={[{
                                                label: t('Nuddo'),
                                                value: 'Nuddo'
                                            }, {
                                                label: t('Netsuite'),
                                                value: 'Netsuite'
                                            }]}
                                            value={budget.createdFrom}
                                            editable={this.editable}
                                            readOnly={true}
                                            name={'createdFrom'}
                                            title={t('Referencia Erp')}
                                            classGroup="col-md-3 inputheight-s"
                                            errors={errorsMapped.createdFrom}
                                        />
                                        <InputTypeComponent
                                            value={budget.title}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={'title'}
                                            title={t('Título')}
                                            classGroup={'col-md-9 col-lg-9 col-xl-9'}
                                            errors={errorsMapped.title}
                                        />
                                        <TextAreaComponent
                                            classGroup={'col-md-12 mt-2'}
                                            value={this.graphDataMainType.comments}
                                            onChange={(event) => this.updateInputEvent(event)}
                                            name={'comments'} autoFocus
                                            title={t('Comentarios para el presupuesto')}
                                            editable={this.editable}
                                            className="form-control"
                                            readOnly={this.graphDataMainType.status !== BudgetModel.CODE_BORRADOR}
                                            row={'1'}
                                        />
                                    </div>


                                    <GroupOfField
                                        title={t('Artículos')}
                                        icon="fas fa-calendar"
                                    />
                                    <div className="table-edit">
                                        <table className="table visible-text">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="td-code">{t('Codigo')}</th>
                                                    <th scope="col">{t('Descripción')}</th>
                                                    <th scope="col" className="td-quantity">{t('Cantidad')}</th>
                                                    <th scope="col">{t('Precio')}</th>
                                                    <th scope="col">{t('Precio/sin iva')}</th>
                                                    <th scope="col">{t('Precio/con iva')}</th>
                                                    <th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody className="border-end">
                                                {this.graphDataMainType.budgetArticles?.map((budgetArticle, index) => (
                                                    <OrderFormBudgetArticlesRow
                                                        key={index}
                                                        clientModel={this.graphDataMainType.client}
                                                        rowIndex={index}
                                                        editable={this.editable}
                                                        budget={this.graphDataMainType}
                                                        row={budgetArticle}
                                                        budgetArticles={this.graphDataMainType.budgetArticles}
                                                        newBudget={() => this.graphDataMainType.budgetArticles.push({})}
                                                        deleteBudget={(pos) => this.graphDataMainType.budgetArticles.splice(pos, 1)}
                                                        workOrders={appState.workOrderState.workOrders}
                                                        {...this.props}

                                                    />
                                                ))}

                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan="2">{t('TOTAL')}</td>
                                                    <td colSpan="2">{cantidad}</td>
                                                    <td>{isNaN(totalWithoutTax) ? ' - '
                                                        : parseInt(totalWithoutTax) === 0 ? ' - ' :
                                                            Math.round(totalWithoutTax * 100) / 100 + ' €'}</td>
                                                    <td colSpan="2">{isNaN(totalWithTax) ? ' - '
                                                        : parseInt(totalWithTax) === 0 ? ' - ' :
                                                            Math.round(totalWithTax * 100) / 100 + ' €'}</td>
                                                </tr>
                                            </tfoot>
                                        </table>

                                        <div className="row">
                                            {!appState.userState.isUserRoleClient() &&
                                            <>
                                                <div className="col-4">
                                                    {this.editable &&
                                                    <>
                                                        <VsfButtonNew
                                                            isEditing={(!util.hasValue(this.graphDataMainType.id) || this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                            disabled={(!util.hasValue(this.graphDataMainType.id) || this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                            label={t('Actualizar peticion a ERP')}
                                                            showButton={this.creatable['budget'] || false}
                                                            onClick={() => {
                                                                this.updateInput('status', BudgetModel.CODE_PENDING_ERP)
                                                                this.handleFormSubmit()
                                                            }}
                                                            className="btn btn--loader"
                                                            loadedError={this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationError}
                                                            loading={this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationLoading}
                                                        />
                                                        {/*{!this.budgetSent &&*/}
                                                        {/*<VsfButtonNew className="btn btn--orange"*/}
                                                        {/*label={"Enviar a ERP"}*/}
                                                        {/*loadedError={this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationError}*/}
                                                        {/*loading={this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationLoading}*/}
                                                        {/*isEditing={(!util.hasValue(this.graphDataMainType.id) || this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}*/}
                                                        {/*disabled={(!util.hasValue(this.graphDataMainType.id) || this.graphDataMainType.status === BudgetModel.CODE_PENDING_ERP || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}*/}
                                                        {/*onClick={() => this.setState({sendBudget: true})}>*/}
                                                        {/*</VsfButtonNew>*/}
                                                        {/*}*/}
                                                        {/*{this.budgetSent &&*/}
                                                        {/*<div className="text-success col-8">{"Presupuesto enviado."}</div>*/}
                                                        {/*}*/}
                                                    </>
                                                    }
                                                </div>

                                            </>
                                            }
                                            {appState.userState.isUserRoleClient() &&
                                            <>
                                                <div className="col-4">
                                                    {this.editable &&
                                                    <VsfButtonNew
                                                        isEditing={(!util.hasValue(this.graphDataMainType.id) || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                        disabled={(!util.hasValue(this.graphDataMainType.id) || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                        label={t('No aprobar')}
                                                        onClick={() => {
                                                            this.updateInput('status', BudgetModel.CODE_NOT_APPROVED)
                                                            this.handleFormSubmit()
                                                        }}
                                                        className="btn btn--loader"
                                                        loadedError={this.graphDataMainType.status === BudgetModel.CODE_NOT_APPROVED && this.graphStatus.mutationError}
                                                        loading={this.graphDataMainType.status === BudgetModel.CODE_NOT_APPROVED && this.graphStatus.mutationLoading}
                                                    />
                                                    }
                                                </div>
                                                <div className="col-4">
                                                    {this.editable &&
                                                    <VsfButtonNew
                                                        isEditing={(!util.hasValue(this.graphDataMainType.id) || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                        disabled={(!util.hasValue(this.graphDataMainType.id) || !appState.layoutState.formWithoutChanges || this.graphDataMainType.status === BudgetModel.CODE_APPROVED)}
                                                        label={t('Aprobar presupuesto')}
                                                        onClick={() => {
                                                            this.updateInput('status', BudgetModel.CODE_APPROVED)
                                                            this.handleFormSubmit()
                                                        }}
                                                        className="btn btn--loader"
                                                        loadedError={this.graphDataMainType.status === BudgetModel.CODE_APPROVED && this.graphStatus.mutationError}
                                                        loading={this.graphDataMainType.status === BudgetModel.CODE_APPROVED && this.graphStatus.mutationLoading}
                                                    />
                                                    }
                                                </div>
                                            </>
                                            }

                                            <div className="col-4">
                                                <VsfButtonNew
                                                    label={t('Guardar')}
                                                    loading={this.graphDataMainType.status !== BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationLoading}
                                                    loadedError={this.graphDataMainType.status !== BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationError}
                                                    className="mr-2 btn--disabled"
                                                    formButton={true}
                                                    disabled={appState.layoutState.formWithoutChanges}
                                                    showButton={this.creatable['budget'] || false}
                                                    onClick={_ => this.handleFormSubmit()}
                                                />
                                            </div>

                                            <div className="col-4">
                                                <VsfButtonNew
                                                    label={t('Actualizar precios')}
                                                    loading={this.loadingPrices}
                                                    loadedError={this.graphDataMainType.status !== BudgetModel.CODE_PENDING_ERP && this.graphStatus.mutationError}
                                                    className="btn btn--loader"
                                                    disabled={!this.editable}
                                                    messageOnLoading={'Cargando'}
                                                    messageOnSuccess={'Actualizado'}
                                                    showButton={this.creatable['budget'] || false}
                                                    onClick={_ => this.updatePrices()}
                                                />
                                            </div>
                                        </div>
                                        {/*<AlertModal isOpen={this.state.sendBudget}*/}
                                        {/*onCloseModal={() => this.closeBudgetFinished()}>*/}
                                        {/*<PopUp*/}
                                        {/*title={t('Enviar un presupuesto')}*/}
                                        {/*icon="exclamationTriangleIcon"*/}
                                        {/*text={this.textPopUp} hideButton1={this.budgetSent}*/}
                                        {/*label1={'Cerrar'} loading3={this.state.loading}*/}
                                        {/*loadedError3={this.gqlErrors?.length !== 0}*/}
                                        {/*label3={'Aceptar'}*/}
                                        {/*onClick1={() => this.closeBudgetFinished()}*/}
                                        {/*onClick3={() => this.sendBudgetFinished(budget)}*/}
                                        {/*></PopUp>*/}
                                        {/*</AlertModal>*/}
                                    </div>

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>

                    </form>
                </InfoBlock>
            </div>

        )
    }

    async sendBudgetFinished () {
        this.updateInput('status', BudgetModel.CODE_PENDING_ERP)
        this.setState({ sendBudget: false })
        await this.handleFormSubmit()
        this.budgetSent = true
    }

    closeBudgetFinished () {
        this.setState({ sendBudget: false })
        this.textPopUp = '¿Seguro que desea enviar a Netsuite el presupuesto?'
        this.gqlErrors = []
    }

}

export default withRouter(BudgetForm)
