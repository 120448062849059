import AbstractModel from "./AbstractModel";

export default class WarehouseModel extends AbstractModel {

    code;
    name;
    latitude;
    longitude;
    address;
    useBin;

    nameMainType = "warehouse";
    graphFindByIdOperation = "warehouse";
    graphFindOperation = "warehousesConnection";
    graphExportOperation = "warehousesExport";


    newModel() {
        return new WarehouseModel();
    }


    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "code": "",
            "name": "",
            "address": "",
            "latitude": "",
            "longitude": "",
            "useBin": "",
        };
        return result;
    }

    getArrayForExcelImports() {
        let result = {
            "Datos generales": [
                "code",
                "name",
                "address",
                "latitude",
                "longitude",
                "useBin"
            ]
        };
        return result;
    }

};
