import React, {Component} from 'react';
import {observer} from 'mobx-react';
import GroupOfField from "../layout/GroupOfField";
import VsfButtonNew from "../VsfButtonNew";
import util from "../../util/Util";

@observer
class PopUp extends Component {


    render() {
        let {
            title, subtitle, icon, onClick1, onClick2,
            loading3, messageOnLoading3, loadedError3,
            messageOnSuccess3, onClick3, loading1,
            loading2, messageOnLoading, messageOnSuccess,
            disabled3, text, textData, label1, label2, label3,
        } = this.props;

        // let [textStatus, textData] = text?.split('.')
        let className3 = this.props.className3 || "btn btn-secondary btn--loader";

        return (
            <div className={this.props.classGroup}>
                <div className="modal-body">
                    <div>
                        <GroupOfField
                            title={title}
                            subtitle={subtitle}
                            icon={icon}
                        />
                    </div>
                </div>
                <div className="modal-body">
                    {this.props.spinner &&
                    <div className='text-center'>
                        <i className="fas fa-spinner fa-pulse"/>
                    </div>
                    }
                    <div className='text-center'>
                        <div className="column">
                            <p className={"modal-center-text " + this.props.classNameText}
                               data-cy={this.props.dataCy}>{text}</p>
                            {util.hasValue(textData) &&
                            <p className={"modal-center-text " + this.props.classNameText}
                               data-cy={this.props.dataCy}>{textData}</p>
                            }
                        </div>

                    </div>
                    <div className='modal-footer-warning'>
                        <div className='d-flex justify-content-center justify-content-sm-end w-100'>
                            {label1 &&
                            <VsfButtonNew
                                label={label1}
                                loading={loading1} showButton
                                onClick={() => onClick1()}
                                className="btn btn-secondary"
                                classGroup={'button-form-group mr-3'}
                            />
                            }
                            {!this.props.hideButton1 && label3 &&
                            <VsfButtonNew
                                label={label3} showButton
                                onClick={() => onClick3()} loadedError={loadedError3}
                                loading={loading3} disabled={disabled3}
                                messageOnLoading={messageOnLoading3}
                                messageOnSuccess={messageOnSuccess3}
                                classGroup={'button-form-group mr-3'}
                                className={className3}
                            />
                            }
                            {label2 &&
                            <VsfButtonNew
                                className="btn btn--loader" showButton
                                label={label2}
                                messageOnLoading={messageOnLoading}
                                messageOnSuccess={messageOnSuccess}
                                loading={loading2}
                                onClick={() => onClick2()}
                                classGroup={'button-form-group'}
                            />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PopUp







