import React, { Component } from 'react';
import { observer } from 'mobx-react';
import VsfButtonNew from "./VsfButtonNew";
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";
import t from "../translator/translate";

@observer
export default class FormButtons extends Component {
    constructor() {
        super();
        this.state = {
            badPassword: appState.layoutState.badPassword
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            badPassword: appState.layoutState.badPassword
        })
    }

    render() {
        let desactivado = true;
        let formButton;
        let passwordValid;//esto solo existe cuando se necesita validacion. Si es true es que ha pasado la validacion
        if (this.props.isPasswordValidationNeeded) {
            //si esta prop es falsa, o ha pasado la validacion de contraseña o no es necesaria
            if (!this.state.badPassword) {
                passwordValid = true;
                desactivado = false;
            }
        } else {
            desactivado = appState.layoutState.formWithoutChanges;
            formButton = true;
        }

        return (
            <div className="form_container form_actions mt-3 mb-3">
                <div className="text-right">
                    <VsfButtonNew
                        label={t("Guardar")}
                        loading={this.props.formStatus.mutationLoading}
                        loadedError={this.props.formStatus.mutationError}
                        icon="fa fa-save"
                        showButton={this.props.showButton}
                        type="submit"
                        loadedWarning={this.props.formStatus.mutationWarning}
                        passwordValid={passwordValid}
                        disabled={desactivado}
                        formButton={formButton}
                        className="btn btn--disabled"
                        dataCy={this.props.dataCy}
                    />
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

