import Model from "./Model";
import appState from '../state/AppState'
import util from '../util/Util'

export default class AbstractModel extends Model {

    updatedAt;
    updatedBy;

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "updatedAt": { readonly: true, label: "Actualizado" },
            "updatedBy": { readonly: true, label: "Actualizado por", subscription: true },
            "createdAt": { readonly: true, label: "Creado" },
            "createdBy": { readonly: true, label: "Creado por" },
            "editable": { readonly: true, subscription: true },
            "deletable": { readonly: true, subscription: true },
            "creatable": { readonly: true, subscription: true },
            "logicalDelete": { type: "BoolField", readonly: true, subscription: true },
        };
        this.addRelatedTableGeneric(result, "userCreatedBy", "RelationOne", appState.classFactory.UserModel);
        return result;
    }


    getLabelFromFieldName(name) {
        let arrayFields = this.getArrayFields();
        let result = null;
        if (arrayFields[name] != null) {
            result = arrayFields[name].label;
        }
        if (name === 'posZoneId') {
            result = 'Zona'
        }
        if (name === 'typeWorkOrder') {
            result = 'Tipo de OT'
        }
        if (name === 'clientId') {
            result = 'Cliente'
        }
        if (name === 'capacitationIds') {
            result = 'Capacitaciones'
        }
        if (name.startsWith('dynamic')) {
            result = this.getDynamicFieldLabel(name)?.label;
        }
        if (result == null) {
            result = name;
        }
        return result;
    }

    hidrateSimple(previousModel, setId = true) {
        if (previousModel !== null && util.hasValue(previousModel?.id)) {
            if (setId) {
                this.id = previousModel?.id;
            }
            this.createdAt = previousModel?.createdAt;
            this.createdBy = previousModel?.createdBy;
            this.updatedAt = previousModel?.updatedAt;
            this.updatedBy = previousModel?.updatedBy;
        }
    }

    getDynamicFieldLabel(name) {
        return appState.dynamicGroupState.dynamicsFieldsDict[name.split('.')[1]];
    }

    isThisNameFieldRelation(namefield) {
        let result = false;
        if (this.getArrayFields()[namefield]?.relationName) {
            result = true;
        }
        return result;
    }

    isThisValueFromTypified(name) {
        let result = false;
        if (this.isThisTypeExistInTypified(name)) {
            result = true
        }
        return result;
    }

    isThisTypeExistInTypified(type) {
        let listTypes = {
            "orderStatus": "orderStatus",
            "diagnosis": "diagnosis",
            "assetType": "assetType",
            "assetSubType": "assetType",
            "assetCodes": "assetCodes",
            "symptom": "symptom",
            "status": "status",
            "quality": "quality",
            "workOrderType": "workOrderType",
            "subType": "subType",
            "interventionCategory": "interventionCategory",
            "zone": "zone",
            "origin": "origin",
            "reasonReturnId": "reasonReturnId",
            "type": "type",
            "relationship": "relationship",
            "chainPos": "chainPos",
            "capacitationIds": "capacitationIds",
            "role": "rolUser",
            "articleFamily": "articleFamily",
            "articleType": "articleType",
            "storeId": "storeId",
            "statusArticles": "statusArticles",
            "articleSubfamily": "articleSubfamily",
            "appTask": "appTask",
            "subfamily": "subfamily",
            "family": "family",
            "causesPause": "causesPause",
            "emploiments": "emploiments",
            "employeeCapacitation": "employeeCapacitation",
            "groups": "groups",
            "locales": "locales",
            "statusAssets": "statusAssets",
            "timeZones": "timeZones",
            "chainId": "chainId",
            "typeIntervention": "typeIntervention",
            "vehicles": "vehicles",
            "returnStatusId": "returnStatusId",
        };
        let result = false;
        if (listTypes[type] != null) {
            result = true;
        }
        return result;
    }

    getResponseFieldsFromMutation() {
        return ["id", "createdAt", "createdBy", "updatedAt", "updatedBy", "editable", "deletable"];
    }

    addRelatedTableGeneric(result, name, type, childType) {
        if (this._relatedTables[name]) {
            result[name] = {
                type: type, onlyRead: true, childType: childType
            }
        }
    }


}
