import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class FormState extends BaseModelState {
    @observable forms = [];


}

export default FormState;
