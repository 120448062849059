import BaseValidation from "./BaseValidation";
import util from '../../../util/Util';

export default class PostalCodeValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect=true;

        if(util.hasValue(inputValue)){
            if (this.event==="blur") {
                // let regValidatePostalCode =/[1-9][0-9]{3}-[0-9]{3}$/;
                // let regValidatePostalCode =/[0-9-]{8}$/;
                let regValidatePostalCode =/[0-9-]{1,}$/;


                if (!regValidatePostalCode.test(inputValue)) {
                    isCorrect = false;
                }
            }
        }

        return isCorrect;
    }
}
