import React, { Component } from 'react'
import PropTypes from 'prop-types'
import appState from '../state/AppState'
import translate from '../translator/translate'
import AppLogger from '../util/AppLogger'
import util from '../util/Util'

class VsfButtonNew extends Component {


    static propTypes = {
        onClick: PropTypes.func, //Función que se llama cuando se clicka el elemento
        href: PropTypes.string, //usar a en vez de button con esta url
        label: PropTypes.string, //Valor del botón
        icon: PropTypes.string, //Valor del botón
        loading: PropTypes.bool, //Valor del botón
        disabled: PropTypes.bool, //Valor del botón
        classGroup: PropTypes.string, //Clase del div que envuelve al elemento
        className: PropTypes.string, //Clase del boton bootstrap
        type: PropTypes.string, //Tipo de botón. Si es submit, mandará el formulario con un enter.
        formButton: PropTypes.bool, //Si viene de un formulario está desactivado hasta que haya un cambio. En ese caso esta propiedad sea true
        isEditing: PropTypes.bool, //Si se esta editando un row de una tabla por lo que el campo isEditing=true hace que el botón este deshabilitado.
        messageOnLoading: PropTypes.string,//Mensaje que sale en la transiccion cuando esta loading
        messageOnSuccess: PropTypes.string,//Mensaje que sale en la transiccion cuando es success
        messageOnFailure: PropTypes.string,//Mensaje que sale en la transiccion cuando falla
        messageOnWarning: PropTypes.string,//Mensaje que sale en la transiccion cuando falla
        buttomFromLogin: PropTypes.bool, //indica si la validación procede del Login
        passwordValid: PropTypes.bool, //validacion de passwordValid desde el usuario
        showButton: PropTypes.bool,
        classNameOnEnabled: PropTypes.string,//Estilos que salen cuando se habilita
    };
    static defaultProps = {
        onClick: () => {
        },
        label: "",
        icon: null,
        loading: null,
        passwordValid: false,
        disabled: false,
        classGroup: "",
        className: "",
        type: "button",
        formButton: false,
        loadedWarning: false,
        isEditing: false,
        messageOnLoading: "Guardando",
        messageOnSuccess: "Guardado",
        messageOnFailure: "Error al guardar",
        messageOnWarning: "Atención",
        showButton: true,
    };

    constructor(props) {
        super(props);
        this.state = { loading: false, success: false, loadedError: false }
    }

    componentDidMount(): void {
        this._isMounted = true;

    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState) {
        // this.log({ componentDidUpdate: this.props, prevProps, prevState, state: this.state })
        if (prevProps.loadedError !== this.props.loadedError) {
            this.setState({ loadedError: this.props.loadedError });
        }
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading });
        }
        if (!this.props.loading && prevProps.loading) {
            this.setState({ success: true });
            setTimeout(() => {
                this.setState({ loading: false, success: false, loadedError: false });
                if (!this.props.withoutFormChanges) {
                    appState.layoutState.formWithoutChanges = true;
                    appState.layoutState.loadingForm = false;
                }
            }, 3000);
        }
    }


    checkReadOnly() {
        let readOnly = false;
        if (!this.props.showButton) {
            readOnly = true;
        }
        return readOnly;
    }


    isClickActive() {
        let result = true;
        if (this.isStyleDisabled(this.props)) {
            result = false;
        }
        return result;
    }

    isStyleDisabled() {
        let disabled;
        if (this.props.formButton) {
            disabled = appState.layoutState.formWithoutChanges;
        } else if (this.props.passwordValid) {
            //si estoy aqui es que ha pasado la validacion de contraseña y activo el boton
            disabled = false;
        } else {
            disabled = this.props.disabled;
        }

        return disabled;
    }

    async onClick() {
        if (this.isClickActive() && !this.state.loading) {
            await this.props.onClick();
        }
        // if (this._isMounted) {
        //     setTimeout(() => {
        //         this.setState({ loading: false, success: false, loadedError: false });
        //         if (!this.props.withoutFormChanges) {
        //             appState.layoutState.formWithoutChanges = true;
        //             appState.layoutState.loadingForm = false;
        //         }
        //     }, 3000);
        // }
    }

    MyButton = ({children, ...props}) => {
        //this.log({"Button()":"", props, children });
        if (util.hasValue(props.href)) {
            return <a {...props}>{children}</a>;
        } else {
            return <button {...props}>{children}</button>;
        }
    }

    render() {
        const { backgroundColor, borderColor } = this.props;
        const t = translate;
        let props = this.props;
        let disabled = this.isStyleDisabled();
        let readOnly = this.checkReadOnly();
        let label = this.props.label;
        let className = this.props.className;
        let icon = this.props.icon;
        let postIcon = this.props.postIcon;
        if (props.isEditing) {
            //aqui entra en tablas o paginas que no tienen formulario y cuando se
            //esta editando una row
            className = 'btn btn--disabled';
        }
        if (!this.isStyleDisabled()) {
            if (this.props.formButton) {
                //aqui solo entra cuando viene del formButtons
                className = 'btn btn--loader';
            } else if (this.props.wmsButton) {
                className = 'btn btn--red';
            }
        } else {
            className = 'btn btn--disabled';
        }
        if (props.passwordValid) {
            //Si estoy aqui es que ha pasado la validacion de la contraseña y activo el boton
            className = 'btn btn--loader';
        }

        if (this.state.loading) {
            className = 'btn btn--loading';
            label = t(this.props.messageOnLoading);
            icon = "fas fa-spinner fa-pulse";
            //Si el botón es el del Login
        }
        if (this.state.success) {
            if (props.buttomFromLogin) {
                icon = null;
            } else {
                //cuando ya se ha cargado y es success
                icon = 'far fa-check-circle';
                className = 'btn btn--loaded';
                label = t(this.props.messageOnSuccess);
            }
        }
        if (this.state.loadedError) {
            //por aqui entra cuando hay un fallo, si no hay un fallo es que es succes y entra por el else
            className = 'btn btn--loading';
            label = t(this.props.messageOnFailure);
            icon = "fas fa-exclamation-triangle";
        }
        if (this.props.loadedWarning) {
            //por aqui entra cuando hay un fallo, si no hay un fallo es que es succes y entra por el else
            className = 'btn btn--disabled';
            label = t(this.props.messageOnWarning);
            icon = "fas fa-exclamation-triangle";
        }
        if (this.props.classNameExtra) {
            className += ' '+ this.props.classNameExtra;
        }
        let Container = React.Fragment;
        if (util.hasValue(this.props.classGroup)) {
            Container = (props) => {
                return <div className={this.props.classGroup}>{props.children}</div>
            }
        }
        if (this.props.withDiv) {
            Container = (props) => {
                return <div className={this.props.classGroup}>{props.children}</div>
            }
        }


        return (
            <Container>
                {/*{prueba}*/}
                {!readOnly &&
                <this.MyButton type={this.props.type}
                        style={{ borderColor: borderColor, backgroundColor: backgroundColor, position: 'relative' }}
                        className={className} 
                        onClick={() => this.onClick()} 
                        disabled={disabled}
                        data-cy={this.props.dataCy}
                        href={this.props.href}
                >
                    {icon &&
                    <i className={icon} />

                    }
                    {label &&
                    <span>{label}</span>
                    }
                    {this.props.children &&
                    this.props.children
                    }
                    {postIcon &&
                    <i className={postIcon} />
                    }
                </this.MyButton>
                }
            </Container>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}


export default VsfButtonNew;
