import React from 'react'
import AppLogger from '../../util/AppLogger'
import util from '../../util/Util'
import InfoBlock from '../../components/InfoBlock'
import { computed, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import translate from '../../translator/translate'
import GroupOfField from '../../components/layout/GroupOfField'
import PropsUtil from '../../util/PropsUtil'
import PageTitle from '../../components/PageTitle'
import CreateAndModify from '../../components/fields/CreateAndModify'
import FormModel from '../../models/FormModel'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import GqlErrors from '../../components/status/GqlErrors'
import ConfigFormQuestionRow from './ConfigFormQuestionRow'
import VsfButtonNew from '../../components/VsfButtonNew'
import BaseEditableTable from '../base/BaseEditableTable'
import MoreButtons from '../../components/MoreButtons'
import SortableList from '../../components/SortableList'
import { arrayMove } from 'react-sortable-hoc'
import FormQuestionModel from '../../models/FormQuestionModel'
import FormButtons from '../../components/FormButtons'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'

@observer
class ConfigFormForm extends BaseEditableTable {
    nameMainType = "form";

    @observable dynamicGroup = {};

    constructor(props) {
        super(props);
        this.state = {
            hasSomeRowEditing: false, // Muestra este formulario en modo edición en algono de los elementos
            change: "1",
        };
        this.nameMainType = "form";
        this.initializeGraphData(this.nameMainType);
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.ordenarForms();
    }

    ordenarForms() {
        let result = this.graphDataMainType.formQuestion?.sort(function (a, b) {
            let key1 = a.questionOrder;
            let key2 = b.questionOrder;
            if (key1 < key2 || !util.hasValue(key2)) {
                return -1;
            }
            if (key1 > key2 || !util.hasValue(key1)) {
                return 1;
            }
            return 0;
        });
        this.setMobxListado(result);

    }

    @computed
    get mobxListado() {
        return this.graphDataMainType.formQuestion;
    }

    setMobxListado(newValue) {
        this.graphDataMainType.formQuestion = newValue;
    }

    getModelTable() {
        return new FormModel();
    }

    getModelQuery() {
        return new FormModel();
    }

    getModelQueryForView() {
        let result = new FormModel();
        result.addRelatedTable("formQuestion");
        return result;
    }

    /**
     * Se ejecuta tras mover columnas de posicion, obteniendo como parametro el antiguo y nuevo indice del elemento movido
     */
    async onSortEnd(event) {
        let { oldIndex, newIndex } = event;
        // Guardamos en el state directamente el nuevo array con el orden de las columnas para que la siguiente funcion
        // tenga la informacion actualizada, ya que setState es asincrono y es mas lento que la funcion en ejecutarse
        let sortableFields = arrayMove(this.mobxListado, oldIndex, newIndex);
        this.setMobxListado(sortableFields);
        let formOld = new FormQuestionModel();
        formOld.hidrate(this.mobxListado[oldIndex]);
        formOld.questionOrder = oldIndex;
        await formOld.persist();
        let form = new FormQuestionModel();
        form.hidrate(this.mobxListado[newIndex]);
        form.questionOrder = newIndex;
        await form.persist();
        this.mobxListado[newIndex] = { ...form };
        this.mobxListado[oldIndex] = { ...formOld };
        // this.formsDict[form.id] = form;
        this.log({ forms: toJS(this.mobxListado), sortableFields, });
        // Actualizamos el render modificando el state (hace que haya que dar doble click para salir)
        this.setState({ sortableFields });
    };


    render() {
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;

        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    <PageTitle
                        title={this.getPageTitle()}
                        subtitle={this.getPageSubtitle()}
                    > {this.graphDataMainType.id &&
                    <MoreButtons
                        onReloadData={() => this.reloadData()}
                        actions={
                            [
                                {
                                    title: "Eliminar Formulario",
                                    onClick: () => this.setState({ rightModal: true, popUp: "eliminar" }),
                                    dontShow: !this.deletable
                                }
                            ]
                        }
                    />
                    }
                    </PageTitle>
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking} />

                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <div className={'card'}>
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                    <GroupOfField title={t("Formulario")}>
                                        <CreateAndModify
                                            updatedAt={this.graphDataMainType.updatedAt}
                                            updatedBy={this.graphDataMainType.updatedBy}
                                            createdBy={this.graphDataMainType.createdBy}
                                            createdAt={this.graphDataMainType.createdAt}
                                            getFormId={this.graphDataMainType.id}
                                        />
                                        <InputTypeComponent
                                            value={this.graphDataMainType.title}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"title"}
                                            title={t("Titulo")}
                                            classGroup={"col-md-6"}
                                        />
                                        <InputTypeComponent
                                            value={this.graphDataMainType.description}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"description"}
                                            title={t("Descripcion")}
                                            classGroup={"col-md-6"}
                                        />
                                        {this.graphDataMainType.id &&

                                        <div className="col-12 ">
                                            <div className="table-edit">
                                                <table className="table visible-text">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" className="td-code">{t("Titulo")}</th>
                                                        <th scope="col"
                                                            className="wo-technical-name">{t("Tipo")}</th>
                                                        <th scope="col">{t("Obligatorio")}</th>
                                                        <th scope="col">{t("Orden")}</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                    </thead>
                                                    <SortableList items={this.mobxListado}
                                                                  onSortEnd={e => this.onSortEnd(e)} tableList
                                                                  renderItem={(objParams) => this.renderFlatItem(objParams)}
                                                                  pressDelay={200} />
                                                </table>
                                            </div>
                                            <div className="text-right">
                                                <VsfButtonNew
                                                    disabled={this.state.hasSomeRowEditing}
                                                    label={t("Crear nueva")}
                                                    isEditing={this.graphDataMainType.id == null ? true : this.state.hasSomeRowEditing}
                                                    onClick={_ => this.onOpenRowNewEmpty()}
                                                    className="btn btn--loader"
                                                />
                                            </div>
                                        </div>
                                        }

                                    </GroupOfField>
                                </div>

                                <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />

                            </form>
                        </div>

                    </div>
                    <AlertModal isOpen={this.state.rightModal && this.state.popUp !== ""}
                                onCloseModal={() => this.setState({
                                    rightModal: false,
                                    popUp: ""
                                })}>
                        {this.state.popUp === "eliminar" &&
                        <PopUp
                            title={t('Eliminar')}
                            icon="exclamationTriangleIcon"
                            text={t('¿Estas seguro que quieres eliminar este tipo de formulario?')}
                            label1={t('No eliminar')}
                            label3={t('Eliminar')}
                            onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                            onClick3={() => this.deleteModel('config/form')}>
                        </PopUp>
                        }
                    </AlertModal>
                                        </div>
            </InfoBlock>
        )
    }

    renderFlatItem(formQuestion, index) {
        return (
            <ConfigFormQuestionRow key={index}
                                   rowIndex={index} editable={this.editable}
                                   form={this.graphDataMainType}
                                   row={formQuestion}
                                   reload={(e) => this.reloadData(e)}
                                   formQuestion={this.graphDataMainType.formQuestion}
                                   newQuestionForm={(e) => this.graphDataMainType.formQuestion.push(e)}
                                   hasSomeRowEditing={this.state.hasSomeRowEditing}
                                   setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                   {...this.props}
            />
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ConfigFormForm;
