import React from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import { computed, toJS } from 'mobx'
import BaseEditableRow from '../base/BaseEditableRow'
import InfoBlock from '../../components/InfoBlock'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import DateRangesModel from '../../models/DateRangesModel'
import util from '../../util/Util'
import Select2Component from '../../components/fields/Select2Component'
import DateInputHoursMobiscroll from '../../components/fields/DateInputHoursMobiscroll'
import translate from '../../translator/translate'
import GqlErrors from '../../components/status/GqlErrors'

@observer
class UserAvailabilityRow extends BaseEditableRow {

    constructor (props) {
        super(props)
        this.state = {}
        this.foreingKeyfield = 'userId'
    }

    @computed
    get mobxListado () {
        return appState.dateRangesState.dateRanges
    }

    setMobxListado (newValue) {
        appState.dateRangesState.dateRanges = newValue
    }

    getModelTable () {
        return new DateRangesModel()
    }

    async rowDidDelete (model) {
        this.log({
            rowDidDelete: 1,
            model,
        })
        let arrCopy = toJS(this.mobxListado)
        arrCopy.splice(this.props.rowIndex, 1)
        this.log({ arrCopy })
        this.setMobxListado(arrCopy)
    }

    async componentDidMount () {
        await super.componentDidMount()
    }

    getValueDaterangesType (type) {
        let tipos = {
            'HOLIDAYS': 'Vacaciones',
            'TIMEOFF': 'Baja',
            'FESTIVE': 'Festivo',
            'LOCAL_FESTIVE': 'Festivo local',
            'ONGUARD': 'Guardia',
        }
        return tipos[type]
    }

    render () {
        const t = translate
        let dateRange = this.props.row
        let dateFilter = this.props.dateFilter
        let statusFilter = this.props.statusFilter
        let errorsMapped = this.getGraphErrorsFromStatus()
        let dateRangeStatus = [
            {
                label: t('Baja'),
                value: DateRangesModel.CODE_TIMEOFF
            },
            {
                label: t('Vacaciones'),
                value: DateRangesModel.CODE_HOLIDAYS
            },
            {
                label: ('Guardia'),
                value: DateRangesModel.CODE_ONGUARD
            },
        ]

        let filterMatching = false
        if (!util.hasValue(dateFilter) && !util.hasValue(statusFilter)) {
            filterMatching = true
        } else if (util.hasValue(dateFilter)) {
            if (util.getMoment(dateFilter).format('YYYYMM') === util.getMoment(dateRange.startDate).format('YYYYMM')) {
                if (!util.hasValue(statusFilter)) {
                    filterMatching = true
                } else if (util.hasValue(statusFilter) && statusFilter === dateRange.type) {
                    filterMatching = true
                }
            }
        } else if (util.hasValue(statusFilter) && statusFilter === dateRange.type) {
            filterMatching = true
        }

        if (!util.hasValue(dateRange.id)) {
            filterMatching = true
        }

        this.log({
            filterMatching,
            statusFilter,
            dateFilter,
            daterang: dateRange
        })
        if (filterMatching) {
            return (
                <InfoBlock componentObject={this} wrapper="tbody">
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                    <tr style={{ borderBottom: 'none' }}>
                        <td>
                            {dateRange.isEditing ?
                                <Select2Component
                                    value={dateRange.type}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={'type'}
                                    classGroup={'col-10 pr-1 pl-0'}
                                    type={'text'}
                                    errors={errorsMapped.type}
                                    isClearable={false}
                                    options={dateRangeStatus}
                                    extraWidth={100}
                                    className="form-control"
                                />
                                :
                                <div>
                                    {util.hasValue(dateRange.type) ? t(this.getValueDaterangesType(dateRange.type))
                                        : ' '}
                                </div>
                            }
                        </td>
                        <td>
                            {dateRange.isEditing ?
                                <>
                                    {dateRange.type === DateRangesModel.CODE_ONGUARD ?
                                        <DateInputHoursMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            value={dateRange.startDate}
                                            onChange={(e) => this.handleInputChange(e)}
                                            name={'startDate'}
                                            type={'text'}
                                            hours={true}
                                            allowEmptyDate={true}
                                            errors={errorsMapped.startDate} />
                                        :
                                        <DateInputFieldMobiscroll
                                            value={dateRange.startDate}
                                            onChange={(e) => this.handleInputChange(e)}
                                            allowEmptyDate={true}
                                            name={'startDate'}
                                            validate={'required'} dateWithoutTimeZone
                                            prefix={'fas fa-calendar-alt'}
                                            errors={errorsMapped.startDate}
                                            type={'text'}
                                        />
                                    }
                                </>
                                :
                                <div>
                                    {util.hasValue(dateRange.startDate) ? dateRange.type == DateRangesModel.CODE_ONGUARD
                                        ?
                                        util.getMoment(dateRange.startDate).format('DD-MM-YYYY HH:mm') :
                                        util.getMoment(dateRange.startDate).format('DD-MM-YYYY')
                                        : ' '}       </div>
                            }
                        </td>

                        <td>
                            {dateRange.isEditing ?
                                <>
                                    {dateRange.type === DateRangesModel.CODE_ONGUARD ?
                                        <DateInputHoursMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            value={dateRange.endDate}
                                            onChange={(e) => this.handleInputChange(e)}
                                            name={'endDate'}
                                            minDate={dateRange.startDate}
                                            type={'text'}
                                            hours={true}
                                            allowEmptyDate={true}
                                            errors={errorsMapped.endDate} />
                                        :
                                        <DateInputFieldMobiscroll
                                            value={dateRange.endDate}
                                            withMinMaxDate={true}
                                            prefix={'fas fa-calendar-alt'}
                                            minDate={dateRange.startDate}
                                            onChange={(e) => this.handleInputChange(e)}
                                            validate={'required'} dateWithoutTimeZone
                                            allowEmptyDate={true}
                                            name={'endDate'}
                                            errors={errorsMapped.endDate}
                                            type={'text'}
                                        />
                                    }
                                </>
                                :
                                <div>
                                    {util.hasValue(dateRange.endDate) ? dateRange.type == DateRangesModel.CODE_ONGUARD ?
                                        util.getMoment(dateRange.endDate).format('DD-MM-YYYY HH:mm') :
                                        util.getMoment(dateRange.endDate).format('DD-MM-YYYY')
                                        : ' '}
                                </div>
                            }

                        </td>
                        <td>
                            {this.renderEditBlock()}
                        </td>
                    </tr>
                    <tr style={{ borderBottom: 'none' }} aria-rowspan="2">
                        <th colSpan={5} style={{ paddingBottom: 0 }}>{t('Comentarios')}{' '}</th>
                    </tr>
                    <tr aria-rowspan="2">
                        <td colSpan={5}>
                            {dateRange.isEditing ?
                                <InputTypeComponent
                                    value={dateRange.comments}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={'comments'}
                                    className="form-control "
                                    errors={errorsMapped.comments}
                                />
                                :
                                <>
                                    {util.hasValue(dateRange.comments) ? dateRange.comments : ''}
                                </>
                            }
                        </td>

                    </tr>
                </InfoBlock>
            )
        } else return null
    }
}

export default UserAvailabilityRow
