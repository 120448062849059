import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class DateRangesState extends BaseModelState {

    @observable dateRanges = [];

}

export default DateRangesState;
