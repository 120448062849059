import { computed, observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import WarehouseModel from "../models/WarehouseModel";

class StockState extends BaseModelState {

    @observable stocks = [];
    @observable stocksMovements = [];

    /**
     * Para que funcione el disctint hay que poner el disctintQuery a true
     * @returns {Promise<void>}
     */
    async getDistintStocks() {
        if (this.stocks.length == 0) {
            let stockQuery = new WarehouseModel();
            stockQuery.filters = [
                { "fieldName": "code", "fieldValue": '1', "filterOperator": "DISTINCT" },
            ];
            stockQuery.disctinctQuery = true;
            stockQuery.orderBy = 'code';
            this.stocks = await stockQuery.find();
        }
    }

    @computed get labelValuesForSelect() {
        let result = [];
        let copia = {};
        for (let typified of this.stocks) {
            if (copia[typified.code] == null) {
                copia[typified.code] = "";
                result.push({ value: typified.code, label: typified.code });
            }
        }
        return result;
    }


}

export default StockState;
