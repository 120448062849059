import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import HistoryComponent from "../HistoryComponent";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import BudgetModel from "../../models/BudgetModel";

@observer
class BudgetHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "budget";
        this.initializeGraphData(this.nameMainType);
    }

    getModelQuery() {
        return new BudgetModel();
    }

    getModelQueryForView() {
        let result = new BudgetModel();
        result.addRelatedTable("client");
        result.addRelatedTable("order");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("slot");
        return result;
    }
    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let budget = this.graphDataMainType;
        const t = translate;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={t("Presupuesto")}
                                                 subtitle={budget.code || "Nuevo"}
                                                 mode={"edit"}
                                                 onCloseModal={() => this.onCloseModal()}
                            />

                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataBudget()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" "}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                            />
                        </PageTitle>


                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataBudget()}
                                active={"Histórico"}
                            />
                            }
                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />
                        </div>


                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(BudgetHistory)
