import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import appState from "../../../state/AppState";
import AppLogger from "../../../util/AppLogger";

@observer
export default class WorkOrderStateListItem extends Component {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        id: PropTypes.string,
        // Objeto con todos los campos que estamos mostrando
        row: PropTypes.object,
        // Nombre del campo a mostrar
        name: PropTypes.string,
        // Valor del campo a mostrar
        value: PropTypes.string,
    };


    render() {
        let props = this.props;
        let value = this.props.value;
        let color =  appState.typifiedState.getObject(props.row.status)?.color;

        return (
            <div style={{ color }}>
                {value}
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
