import React, {Component} from 'react';
import {observer} from 'mobx-react';
import WorkOrderModel from "../../models/WorkOrderModel";
import PropTypes from 'prop-types';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";
import translate from "../../translator/translate";
import Overlay from "./Overlay";
import InputTypeComponent from "../fields/InputTypeComponent";

@observer
class WorkOrdersSelectInModal extends Component {

    static defaultProps = {
        values: "",
        workOrders: [],
        fromArticles: true,
    };

    static propTypes = {
        values: PropTypes.string, //Listado separado por comas con los WorkOrderId
        workOrders: PropTypes.array, //Listado de todas las WorkOrders de este pedido
        fromArticles: PropTypes.bool //Si viene de Articulos no me muestra seleccionar todas
    };

    constructor(props) {
        super(props)
    }

    onChange(e, workOrder) {
        e.target.name = this.props.name;
        this.props.onChange(e);
        if (this.props.onChangeAssetId != null) {
            // this.log({ onChange: 1, e, value: e.target.value, workOrder, asset: workOrder.asset, props: this.props });
            this.props.onChangeAssetId(workOrder.asset?.id)
        }
    }

    getClassNameInput() {
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let hasErrors = errors.length > 0;
        let classNameInput = "form-control " + this.props.classInput;
        if (hasErrors) {
            classNameInput += " is-invalid text-danger";
        }
        if (this.props.hasNoErrors) {
            classNameInput += " is-valid text-success";
        }
        return classNameInput;
    }

    render() {
        const t = translate;

        let values;
        let classNameInput = this.getClassNameInput();
        if (typeof this.props.values === 'number') {
            values = this.props.values.toString();
        } else values = this.props.values;
        let workOrderCode = "";
        for (let workOrder of appState.workOrderState.workOrders) {
            if (util.perteneceA(workOrder.id, this.props.values)) {
                workOrderCode += workOrder.code + ',';
            }
        }
        if (util.hasValue(workOrderCode)) {
            workOrderCode = workOrderCode.substring(0, workOrderCode.length - 1);
        }
        let labelClassName;
        if (util.hasValue(this.props.labelClassName)) {
            labelClassName = "control-label " + this.props.labelClassName;
        } else {
            labelClassName = "control-label ";
        }
        return (
            <>
                {this.props.readOnly ?

                    <InputTypeComponent value={workOrderCode}
                                        autoComplete="new-password"
                                        className={classNameInput}
                                        title={this.props.title}
                                        classGroup={this.props.classGroup}
                                        type={"text"}
                                        readOnly={true}
                    />
                    :
                    <>
                        <div className={this.props.classGroup}>
                            {util.hasValue(this.props.title) &&
                            <div className={labelClassName}>
                                <label style={{
                                    overflowX: 'visible',
                                    color: '#650200',
                                    marginBottom: 0
                                }}>   {this.props.title}</label>
                            </div>
                            }
                            <div className={"row "} style={{ paddingLeft: 15 }}>
                                <button onClick={() => {
                                    appState.layoutState.favouritesDropMenuOpen = true
                                }} id="btnGroupColumns" type="button"
                                        className="btn btn-outline-secondary dropdown-toggle "
                                        data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false"
                                >
                                    {this.props.title}
                                </button>
                                {this.props.values != null &&
                                <div
                                    className={"col-6 mt-2 ml-2"}>{(this.props.values === "-1" ? "Todas " : workOrderCode)}
                                </div>
                                }
                            </div>
                        </div>
                        <Overlay show={appState.layoutState.favouritesDropMenuOpen}
                                 onClick={_ => appState.layoutState.favouritesDropMenuOpen = false} />
                        {appState.layoutState.favouritesDropMenuOpen &&
                        <div className="table-responsive dropdown-sublist-workorder"
                             style={{ zIndex: 100, 'backgroundColor': '#fff' }}>
                            <table className=" table">
                                <tbody>
                                <tr>
                                    <th>
                                        {this.props.fromArticles &&
                                        <input
                                            autoComplete="off"
                                            type="checkbox"
                                            style={{ display: "block" }}
                                            checked={values === '-1'}
                                            name={"allWO"}
                                            id={"all"}
                                            value={'-1'}
                                            onChange={(e) => this.props.onChange(e)}
                                        />
                                        }
                                    </th>
                                    <th>{t("Numero de Orden de trabajo")}</th>
                                    <th>{t("Código de Equipo")}</th>
                                    <th>{t("Estado según técnico")}</th>
                                    <th>{t("Tipo de orden de trabajo")}</th>
                                    <th>{t("Subtipo de orden de trabajo")}</th>
                                    <th>{t("Tipo de equipo")}</th>
                                    <th>{t("Subtipo de equipo")}</th>
                                </tr>
                                {this.props.workOrders.map((woJs) => {
                                    let workOrderModel = new WorkOrderModel();
                                    workOrderModel.addRelatedTable("asset");
                                    workOrderModel.hidrate(woJs);
                                    return (
                                        <tr key={workOrderModel.id} className='dropdown-sublist-workorder__head'>
                                            <td scope="row">
                                                <input
                                                    type="checkbox"
                                                    style={{ display: "block" }}
                                                    name={"workorder-" + workOrderModel.id}
                                                    checked={util.perteneceA(workOrderModel.id, values)}
                                                    id={"workorder-" + workOrderModel.id}
                                                    value={workOrderModel.id}
                                                    onChange={(e) => this.onChange(e, workOrderModel)}
                                                />
                                            </td>
                                            <td>
                                                {workOrderModel.code}
                                            </td>
                                            <td>
                                                {util.hasValue(workOrderModel.asset?.plate) ? workOrderModel.asset?.plate : "No asignado"}
                                            </td>
                                            <td>
                                                   <span className="status"
                                                         style={{ color: appState.typifiedState.getObject(workOrderModel.status)?.color }}>{appState.typifiedState.getValue(workOrderModel.status)}</span>
                                            </td>
                                            <td>
                                                {appState.typifiedState.getValue(workOrderModel.type) || "No asignado"}
                                            </td>
                                            <td>
                                                {appState.typifiedState.getValue(workOrderModel.subType) || "No asignado"}
                                            </td>
                                            <td>
                                                {util.hasValue(workOrderModel.asset?.type) ? appState.typifiedState.getValue(workOrderModel?.asset?.type) :
                                                    (appState.typifiedState.getValue(workOrderModel.assetType) || "No asignado")}
                                            </td>
                                            <td>
                                                {util.hasValue(workOrderModel.asset?.subType) ? appState.typifiedState.getValue(workOrderModel?.asset?.subType) :
                                                    (appState.typifiedState.getValue(workOrderModel.assetSubType) || "No asignado")}
                                            </td>

                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            <div className="text-right mr-3">
                                <button type="button" className="btn btn-secondary"
                                        onClick={_ => appState.layoutState.favouritesDropMenuOpen = false}
                                >{t("Cerrar")}</button>
                            </div>
                        </div>
                        }
                    </>
                }
            </>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default WorkOrdersSelectInModal