import React from 'react'
import { observer } from 'mobx-react'
import translate from '../../translator/translate'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'
import PropsUtil from '../../util/PropsUtil'
import { withRouter } from 'react-router-dom'
import BaseActions from '../base/BaseActions'

@observer
class SlotActions extends BaseActions {

    constructor(props) {
        super(props);
        this.state = {
            rightModal: false,
            popUp: "",
        }
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let model = this.props.model;
        return (
            <>
                <div className="dropdown-sublist__item ">
                    <a onClick={() => {
                         if (this.props.fromRightModal) {
                            this.propsUtil.changeUrlRequest({ "rightModalTab": "history" });
                        } else {
                            this.propsUtil.changeUrl(this.urlForHistoric());
                        }
                        this.closeModal()
                    }}>
                        {t("Control de cambios")}
                    </a>
                </div>

                <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                            onCloseModal={() => this.setState({
                                rightModal: false,
                                popUp: ""
                            })}>
                    {this.state.popUp == "eliminar" &&
                    <PopUp
                        title={t('Eliminar')}
                        icon="exclamationTriangleIcon"
                        text={t('¿Estas Seguro de Eliminar este usuario?')}
                        label1={t('No eliminar')}
                        label3={t('Eliminar')}
                        onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                        onClick3={() => this.deleteModel('user')}
                    >
                    </PopUp>
                    }
                </AlertModal>
            </>
        )
    }
}

export default withRouter(SlotActions)
