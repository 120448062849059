import { observable } from 'mobx';
import WorkLogModel from "../models/WorkLogModel";
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import appState from "../state/AppState";
import SlotModel from "../models/SlotModel";


class WorkLogState {

    @observable saveWorkLogOrder = false;
    @observable workLogsOfUser = [];
    @observable workLogsTasksOfWorkOrder = [];
    @observable commentsSelectedDict = {};
    @observable workLogsTasksOfOrder = [];
    @observable tasksSelectedDict = {};
    @observable lastEndDayWorklog = {};
    @observable workLogStart = {};
    @observable workLogEnd = {};
    @observable retrySendLocation = 0;
    @observable workOrderTestQualityMandatory = false;

    /**
     * Carga los workLogs del usuario. Esta ordenado de ultimo a primero. Son los workLog de la jornada actual
     * @param userId
     * @returns {Promise<void>}
     */
    async loadWorkLogsOfUser(userId) {
        let lastEndDayWorklog = await this.getLastEndDayWorklog(userId);
        let workLogsWhithoutStartDay = [];
        let workLogQueryUser = new WorkLogModel();
        workLogQueryUser.userId = userId;
        if (util.hasValue(lastEndDayWorklog?.id)) {
            workLogQueryUser.filters.push({
                "fieldName": "time",
                "fieldValue": lastEndDayWorklog.time,
                "filterOperator": "GTEQ"
            });
        } else {
            workLogQueryUser.filters = this.getFiltersForTime();
        }
        workLogQueryUser.orderBy = "time";
        workLogQueryUser.orderMode = "DESC";
        let workLogOfUser = await workLogQueryUser.findPlainObject();
        for (let workLog of workLogOfUser) {
            workLogsWhithoutStartDay.push(workLog);
            if (workLog.action === WorkLogModel.START_DAY) {
                break;
            }
        }
        // this.log({ loadWorkLogsInicioDia: 1, workLogsWhithoutStartDay, lastEndDayWorklog });

        this.workLogsOfUser = workLogsWhithoutStartDay;
    }

    async getLastEndDayWorklog(userId) {
        let workLogQueryUser = new WorkLogModel();
        workLogQueryUser.action = WorkLogModel.END_DAY;
        workLogQueryUser.userId = userId;
        workLogQueryUser.orderBy = "time";
        workLogQueryUser.orderMode = "DESC";
        return await workLogQueryUser.findFirst();
    }


    /**
     * Devuelve el ultimo workOrder que haya finalizado, pausado, o pausado en progreso
     * @returns {boolean}
     */
    lastWorkOrderSlotIdFinished() {
        let result = "";
        for (let workLog of this.workLogsOfUser) {
            if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                result = workLog.workOrderId + '-' + workLog.slotId;
                break;
            }
        }
        return result;
    }

    /**
     * Devuelve el id del ultimo workOrder que ha empezado
     * @returns {string}
     */
    lastWorkOrderIdStarted() {
        let result = "";
        for (let workLog of this.workLogsOfUser) {
            if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                result = workLog.workOrderId;
                break;
            }
        }
        return result;
    }

    /**
     * Devuelve el id del ultimo workOrder y slot que ha empezado
     * @returns {string}
     */
    lastWorkOrderSlotIdStarted() {
        let result = "";
        for (let workLog of this.workLogsOfUser) {
            if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                result = workLog.workOrderId + '-' + workLog.slotId;
                break;
            }
        }
        return result;
    }

    isFinishedPausedOrReturned(workOrder, slot) {
        let result = false;
        let lastWorkLog = this.getLastWorkLogOfSlotAndWorkOrder(workOrder, slot.id);
        if (lastWorkLog != null) {
            if (lastWorkLog.action === WorkLogModel.END_WORK_ORDER ||
                lastWorkLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                lastWorkLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                result = true;
            }
        }
        return result;
    }

    isInProgress(workOrder, slot) {
        let result = false;
        let workLogModel = new WorkLogModel();
        let workLogsUser = workLogModel.getWorkLogOfSlotAndWorkOrder(workOrder, slot?.id);
        //this.log({ isInProgress: 1, workLogsUser: toJS(workLogsUser) });
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                workLog.action === WorkLogModel.PAUSE_WORK_ORDER ||
                workLog.action === WorkLogModel.END_WORK_ORDER) {
                result = false;
            }
            if (!result) {
                if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                    result = true;
                }
            }
        }

        return result;
    }

    isPaused(workOrder, slot) {
        let result = false;
        let workLogModel = new WorkLogModel();
        let workLogsUser = workLogModel.getWorkLogOfSlotAndWorkOrder(workOrder, slot?.id);
        //this.log({ isInProgress: 1, workLogsUser: toJS(workLogsUser) });
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                result = false;
            }
            if (!result) {
                if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                    result = true;
                }
            }
        }

        return result;
    }

    loadWorkLogsOfWorkOrderWithoutSlot(workOrder, action) {
        let objDict = {};
        let workLogModel = new WorkLogModel();
        if (workOrder?.workLog?.length > 0) {
            for (let workLog of workOrder?.workLog) {
                if (workLog.action === action) {
                    if (action === WorkLogModel.WORK_ORDER_TEST_QUALITY) {
                        let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(action)];
                        objDict = util.getDictSingleFromArrayFieldName(arrDict, 'code');
                    } else if (action === WorkLogModel.FLAVOR_PLANOGRAM) {
                        let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(action)];
                        objDict = this.gedDictFlavor(arrDict);
                    } else {
                        let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(action)];
                        objDict = util.getDictSingleFromArray(arrDict);
                    }
                }
            }
        }
        return objDict;
    }

    loadWorkLogsOfWorkOrderWarrantyPossible(workOrder, action) {
        let objDict = {};
        let workLogModel = new WorkLogModel();
        if (workOrder?.workLog?.length > 0) {
            for (let workLog of workOrder?.workLog) {
                if (workLog.action === action) {
                    let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(action)];
                    objDict = arrDict[0];
                }
            }
        }
        return objDict;
    }

    getWorkLogOfSlotAndWorkOrder(workOrder, slotId) {
        let result = [];
        if (workOrder.workLog != null) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.slotId === slotId && workLog.workOrderId === workOrder.id) {
                    if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                        workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                        workLog.action === WorkLogModel.START_WORK_ORDER ||
                        workLog.action === WorkLogModel.PENDING ||
                        workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    isReturned(workOrder, slot) {
        let result = false;
        let workLogModel = new WorkLogModel();
        let workLogsUser = workLogModel.getWorkLogOfSlotAndWorkOrder(workOrder, slot?.id);
        //this.log({ isInProgress: 1, workLogsUser: toJS(workLogsUser) });
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.RETURN_WORK_ORDER) {
                result = false;
            }
            if (!result) {
                if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                    result = true;
                }
            }
        }

        return result;
    }

    isFinished(workOrder, slot) {
        let result = false;
        let workLogModel = new WorkLogModel();
        let workLogsUser = workLogModel.getWorkLogOfSlotAndWorkOrder(workOrder, slot?.id);
        //this.log({ isInProgress: 1, workLogsUser: toJS(workLogsUser) });
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.END_WORK_ORDER) {
                result = false;
            }
            if (!result) {
                if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                    result = true;
                }
            }
        }

        return result;
    }

    getWorkLogOfSlot(slot) {
        let result = [];
        if (slot.workLog != null) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(slot.workLog)) {
                if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                    workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                    workLog.action === WorkLogModel.START_WORK_ORDER ||
                    workLog.action === WorkLogModel.START_VISIT ||
                    workLog.action === WorkLogModel.END_VISIT ||
                    workLog.action === WorkLogModel.PENDING ||
                    workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                    result.push(workLog);
                }
            }
        }
        return result;
    }

    getLastWorkLogOfSlotAndWorkOrder(workOrder, slotId) {
        let result = null;
        let workLogModel = new WorkLogModel();
        let workLogs = workLogModel.getWorkLogOfSlotAndWorkOrder(workOrder, slotId);
        if (workLogs.length > 0) {
            result = workLogs[workLogs.length - 1];
        }
        return result;
    }
    /**
     * Si la ultima workOrder que ha terminado es distinta a la ultima workOrder que ha empezado, es que sigue trabajando en la ultima workOrder empezada
     * @returns {boolean}
     */
    thereIsSomeWorkOrderInProgress() {
        let result = false;
        let workOrderCurrent = appState.slotsState.getCurrentWorkOrder();
        let slotCurrent = appState.slotsState.getCurrentSlot();
        let workOrderInProgress = this.isInProgress(workOrderCurrent, slotCurrent);
        if ((workOrderInProgress && util.hasValue(workOrderCurrent?.id))) {
            result = true;
        }
        return result;
    }

    /**
     * Si la ultima workOrder que ha terminado es distinta a la ultima workOrder que ha empezado, es que sigue trabajando en la ultima workOrder empezada
     * @returns {boolean}
     */
    canSlotStartWorkOrder(slot) {
        let result = false;
        let lastSlotIdFinished = this.lastSlotIdFinished();
        let lastSlotIdStarted = this.lastSlotIdStarted();
        if (!util.hasValue(lastSlotIdStarted) || slot.id === lastSlotIdStarted) {
            result = true;
        } else if (lastSlotIdStarted === lastSlotIdFinished) {
            result = true;
        }
        return result;
    }

    /**
     * Indica si el usuario ha empezado a trabajar
     * @param slot
     * @returns {boolean}
     */
    hasUserSlotStartVisit(slot) {
        let result = false;
        let endVisit = true;
        let workLogsUserCopy = this.workLogsOfUser.slice(0);
        let workLogsSlot = workLogsUserCopy.filter(workLog => workLog.slotId === slot?.id).reverse();
        for (let workLog of workLogsSlot) {
            if (workLog.slotId === slot.id) {
                if (workLog.action === WorkLogModel.END_VISIT) {
                    endVisit = true;
                    result = false;
                }
                if (endVisit) {
                    if (workLog.action === WorkLogModel.START_VISIT) {
                        result = true;
                        endVisit = false;
                    }
                }
            }
        }
        return result;
    }

    /**
     * Devuelve el ultimo workOrder que haya finalizado, pausado, o pausado en progreso
     * @returns {boolean}
     */
    lastSlotIdFinished() {
        let result = "";
        for (let workLog of this.workLogsOfUser) {
            if (workLog.action === WorkLogModel.END_VISIT) {
                result = workLog.slotId;
                break;
            }
        }
        return result;
    }

    /**
     * Devuelve el id del ultimo workOrder que ha empezado
     * @returns {string}
     */
    lastSlotIdStarted() {
        let result = "";
        for (let workLog of this.workLogsOfUser) {
            if (workLog.action === WorkLogModel.START_VISIT) {
                result = workLog.slotId;
                break;
            }
        }
        return result;
    }


    /**
     * Si la ultima workOrder que ha terminado es distinta a la ultima workOrder que ha empezado, es que sigue trabajando en la ultima workOrder empezada
     * @returns {boolean}
     */
    isThereSomeSlotInProgress() {
        let result = false;
        let endVisit = true;
        let workLogsUserCopy = this.workLogsOfUser.slice(0);
        let workLogsUser = workLogsUserCopy.reverse();
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.END_VISIT) {
                endVisit = true;
                result = false;
            }
            if (endVisit) {
                if (workLog.action === WorkLogModel.START_VISIT) {
                    result = true;
                    endVisit = false;
                }
            }
        }
        return result;
    }

    getClockingIn() {
        let result = [];
        let workLogsUserCopy = this.workLogsOfUser.slice(0);
        let workLogsUser = workLogsUserCopy.reverse();
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.RESTART_DAY) {
                result.push(workLog);
            }
            if (workLog.action === WorkLogModel.PAUSE_DAY) {
                result.push(workLog);
            }
            if (workLog.action === WorkLogModel.START_DAY) {
                result.push(workLog);
            }
            if (workLog.action === WorkLogModel.END_DAY) {
                result.push(workLog);
            }
        }
        return result;
    }

    /**
     * Indica que el usuario ha empezado el día. Si es false, tiene que volver a empezar el día
     * @returns {boolean}
     */
    hasUserStartDay() {
        let result = false;
        let endDay = true;
        let workLogsUserCopy = this.workLogsOfUser.slice(0);
        let workLogsUser = workLogsUserCopy.reverse();
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.END_DAY) {
                endDay = true;
                result = false;
            }
            if (endDay) {
                if (workLog.action === WorkLogModel.START_DAY) {
                    result = true;
                    endDay = false;
                }
            }
        }

        return result;
    }

    /**
     * Indica que el usuario ha pausado el día. Si es true, tiene que reanudar el día
     * @returns {boolean}
     */
    isUserPauseDay() {
        let result = false;
        let pauseDay = true;
        let workLogsUserCopy = this.workLogsOfUser.slice(0);
        let workLogsUser = workLogsUserCopy.reverse();
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.RESTART_DAY) {
                pauseDay = true;
                result = false;
            }
            if (pauseDay) {
                if (workLog.action === WorkLogModel.PAUSE_DAY) {
                    result = true;
                    pauseDay = false;
                }
            }
        }
        return result;
    }


    /**
     * Indica que el usuario ha empezado el día. Si es false, tiene que volver a empezar el día
     * @param userId
     * @returns {boolean}
     */
    setUserStartDayAndKm(userId) {
        let endDay = true;
        let workLogStart = {};
        let workLogsUser = this.workLogsOfUser.slice(0).reverse();
        for (let workLog of workLogsUser) {
            if (workLog.action === WorkLogModel.END_DAY) {
                endDay = true;
                workLogStart = {};
            }
            if (endDay) {
                if (workLog.action === WorkLogModel.START_DAY) {
                    workLogStart = {
                        id: workLog.id,
                        km: JSON.parse(workLog.data).startDayResume?.km,
                        momentStart: workLog.time,
                        reason: JSON.parse(workLog.data).startDayResume?.reason,
                    };
                    endDay = false;
                }
            }
        }
        this.workLogStart = workLogStart;
    }


    /**
     * Si la ultima workOrder que ha terminado es distinta a la ultima workOrder que ha empezado, es que esta trabajando
     * Si la workOrder de param es igual a la ultima workOrder que ha empezado, es que esta trabajando en ella, sino esta trabajando en otra workOrder
     * @param workOrderId
     * @returns {boolean}
     */
    isWorkOrderInProgress(workOrderId) {
        let result = false;
        if (workOrderId === this.lastWorkOrderIdStarted()) {
            result = true;
        }
        return result;
    }


    /**
     * Si se esta trabajando en una workOrder, devuelve el id de la workOrder que esta trabajando, sino devuelve NULL
     * @returns {*}
     */
    getCurrentWorkingWorkOrderId() {
        return this.lastWorkOrderIdStarted();
    }

    /**
     * Si se esta trabajando en un slot, devuelve el id del slot que esta trabajando, sino devuelve NULL
     * @returns {*}
     */
    getCurrentWorkingSlotId() {
        return this.lastSlotIdStarted();
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    getDate() {
        return util.getMoment(new Date());
    }

    getDateToForGql() {
        /*Fecha NOW en formato ISO*/
        return util.formatToISOString(util.getMomentFromDateWithoutTimezone(this.getDate().format("YYYY-MM-DDTHH:mm:ss")));
    }

    getDateFromForGql() {
        /*Fecha NOW + 12h  en formato ISO*/
        let fecha = this.getDate().add(-7, 'days').format("YYYY-MM-DDTHH:mm:ss");
        return util.formatToISOString(util.getMomentFromDateWithoutTimezone(fecha));
    }


    getFiltersForTime() {
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToForGql();
        return [
            { "fieldName": "time", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "time", "fieldValue": dateTo, "filterOperator": "LTEQ" }];
    }

    async startDay(userId, km, reason) {
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.START_DAY;
        workLog.userId = userId;
        workLog.time = util.getDateIsoNow();
        let data = { km, reason, sign: "sign" };
        workLog.data = JSON.stringify({ [workLog.nameForData(WorkLogModel.START_DAY)]: data });
        await workLog.persist();
        this.workLogsOfUser = [];
        this.setUserStartDayAndKm(userId);
        return workLog;
    }

    async endDay(userId, time, data) {
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.END_DAY;
        workLog.userId = userId;
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        workLog.time = time;
        workLog.data = JSON.stringify({ [workLog.nameForData(WorkLogModel.END_DAY)]: data });
        await workLog.persist();
        this.workLogsOfUser = [];
        this.workLogStart = {};
        return workLog;
    }

    async pauseDay(userId, causePause) {
        //Si estoy trabajando en una OT, la pauso
        let currentSlot = appState.slotsState.getCurrentSlot();
        if (util.hasValue(currentSlot?.id)) {
            let currentWorkOrder = appState.slotsState.getCurrentWorkOrder();
            if (util.hasValue(currentWorkOrder?.id) && this.isInProgress(currentWorkOrder, currentSlot)) {
                await this.pauseProcessWorkOrder(userId, currentSlot?.clientId, currentSlot?.orderId, currentSlot, currentWorkOrder);
                if (currentSlot?.slotUserRole === SlotModel.SUPPORT) {
                    await this.endClient(userId, currentSlot?.clientId, currentSlot?.orderId, currentSlot);
                }
            }
        }

        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.PAUSE_DAY;
        workLog.userId = userId;
        workLog.time = util.getDateIsoNow();
        workLog.data = this.dataFromAction([causePause], WorkLogModel.PAUSE_DAY);
        await workLog.persist();
        return workLog;
    }

    async restartDay(userId) {
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.RESTART_DAY;
        workLog.userId = userId;
        workLog.time = util.getDateIsoNow();
        await workLog.persist();
        return workLog;
    }

    async startClient(userId, clientId, orderId, slotId, workLogUpdate) {
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.START_VISIT;
        workLog.clientId = clientId;
        workLog.data = this.dataFromAction([], WorkLogModel.START_VISIT);
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.slotId = slotId;
        await workLog.persist();
        return workLog;
    }

    async addWorkLogToState(workLog, workOrder, slot) {
        let previousWorkLogsOfUser = this.workLogsOfUser.slice(0);
        previousWorkLogsOfUser.unshift(workLog);
        this.workLogsOfUser = previousWorkLogsOfUser;
        if (workOrder != null) {
            let workLogYaAniadido = false;
            for (let workLogWO of workOrder.workLog) {
                if (util.hasValue(workLog.id) && workLogWO.id === workLog.id ||
                    util.hasValue(workLog.idOffline) && workLogWO.idOffline === workLog.idOffline
                ) {
                    workLogWO.data = workLog.data;
                    workLogWO.action = workLog.action;
                    workLogYaAniadido = true;
                }
            }
            if (!workLogYaAniadido) {
                workOrder["workLog"].push(workLog)
            }
            appState.slotsState.workOrderCurrent = workOrder;
        }
        if (slot != null) {
            if (util.hasValue(workOrder.id)) {
                for (let workOrderSlot of slot.workOrder) {
                    if (workOrderSlot.id === workOrder.id) {
                        workOrderSlot["workLog"] = workOrder["workLog"];
                    }
                }
            }
            appState.slotsState.updateSlotState(slot);
            appState.slotsState.slotCurrent = slot;
        }
    }

    dataFromAction(arrData, action) {
        let workLog = new WorkLogModel();
        let dataToSave = {};
        dataToSave["timezone"] = util.getTimezone();
        dataToSave["backEndModifiedBy"] = appState.loginState.getUserId();
        dataToSave["backEndModifiedByEmail"] = appState.loginState.user?.email;
        dataToSave[workLog.nameForData(action)] = [];
        for (let item of arrData) {
            let itemToSave = {};
            if (util.hasValue(item.id)) {
                itemToSave.id = item.id
            }
            if (util.hasValue(item.code)) {
                itemToSave.code = item.code
            }
            if (util.hasValue(item.value)) {
                itemToSave.value = item.value
            }
            if (util.hasValue(item.title)) {
                itemToSave.title = item.title
            }
            if (util.hasValue(item.comment)) {
                itemToSave.comment = item.comment
            }
            if (util.hasValue(item.parentId)) {
                itemToSave.parentId = item.parentId
            }
            if (util.hasValue(item.warrantyPossibleValue)) {
                itemToSave.warrantyPossibleValue = item.warrantyPossibleValue
            }
            if (util.hasValue(item.createdBy)) {
                itemToSave.createdBy = item.createdBy
            }
            dataToSave[workLog.nameForData(action)].push(itemToSave);
        }
        return JSON.stringify(dataToSave);
    }

    async insertOrderTasks(clientId, orderId, tasksWorkOrder) {
        let workLog = new WorkLogModel();
        workLog.id = this.tasksSelectedDict.workLogId;
        workLog.action = WorkLogModel.TASKS_ORDER;
        workLog.orderId = orderId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.data = this.dataFromAction(tasksWorkOrder, WorkLogModel.TASKS_ORDER);
        await workLog.persist();
        this.tasksSelectedDict.workLogId = workLog.id;

    }
    dataFlavor(arrData, action) {
        let dataToSave = {};
        let workLog = new WorkLogModel();
        dataToSave[workLog.nameForData(action)] = [];
        let i = 0;
        for (let item of arrData) {
            let itemToSave = {};
            for (let flavor of Object.keys(item)) {
                if (itemToSave[i] === null) {
                    itemToSave[i] = {};
                }
                itemToSave[i][flavor] = {
                    code: item[flavor].code,
                    value: item[flavor].value,
                    title: item[flavor].title
                };
            }
            i++;
            dataToSave[workLog.nameForData(action)].push(itemToSave);
        }
        return JSON.stringify(dataToSave);
    }


    async insertWorkOrderAssetChange(userId, clientId, orderId, workOrder, slotId, data) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.ASSET_CHANGE;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.orderId = orderId;
        workLog.slotId = slotId;
        workLog.workOrderId = workOrderId;
        workLog.data = data;
        await workLog.persist();

        return workLog;
    }

    async insertTasksWorkOrder(userId, clientId, orderId, slotId, workOrder, tasksWorkOrder, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.TASKS;
        workLog.slotId = slotId;
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        workLog.data = this.dataFromAction(tasksWorkOrder, WorkLogModel.TASKS);
        await workLog.persist();
        this.log({ insertWorkOrder: 1, workLog, workLogUpdate });
        return workLog;
    }

    async insertDiagnosisWorkOrder(userId, clientId, orderId, slotId, workOrder, diagnosisWorkOrder, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.DIAGNOSIS;
        workLog.data = this.dataFromAction(diagnosisWorkOrder, WorkLogModel.DIAGNOSIS);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        this.log({ insertWorkOrder: 1, workLog, workLogUpdate });

        return workLog;
    }

    async insertCancelOrder(userId, clientId, orderId, diagnosisOrder) {
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.CANCELATION_ORDER;
        workLog.data = this.dataFromAction(diagnosisOrder, WorkLogModel.CANCELATION_ORDER);
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        await workLog.persist();
        this.workLogsOrderCancelOfOrder = diagnosisOrder;
        this.log({ insertWorkOrder: 1, workLog });
    }


    async insertNotApprovedOrder(userId, clientId, orderId, diagnosisOrder) {
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.NOT_APPROVED_ORDER;
        workLog.data = this.dataFromAction(diagnosisOrder, WorkLogModel.NOT_APPROVED_ORDER);
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        await workLog.persist();
        this.workLogsOrderNotApprovedOrder = diagnosisOrder;
        this.log({ insertWorkOrder: 1, workLog });
    }

    async insertWarrantyPossibleWorkOrder(userId, clientId, orderId, slotId, workOrder, data, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.WARRANTY_POSSIBLE;
        workLog.data = this.dataFromAction(data, WorkLogModel.WARRANTY_POSSIBLE);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertChangeReasonsWorkOrder(userId, clientId, orderId, slotId, workOrder, diagnosisWorkOrder) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.CHANGE_REASONS;
        workLog.data = this.dataFromAction(diagnosisWorkOrder, WorkLogModel.CHANGE_REASONS);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertCommentsWorkOrder(userId, clientId, orderId, slotId, workOrder, data, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.COMMENTS;
        workLog.data = this.dataFromAction(data, WorkLogModel.COMMENTS);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertCommentsDispatcherWorkOrder(userId, clientId, orderId, slotId, workOrder, data, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.COMMENTS_DISPATCHER;
        workLog.data = this.dataFromAction(data, WorkLogModel.COMMENTS_DISPATCHER);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertCommentsAsset(userId, clientId, orderId, slotId, workOrder, assetId, data) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.ASSET_COMMENT;
        workLog.data = this.dataFromAction(data, WorkLogModel.ASSET_COMMENT);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.assetId = assetId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }


    async insertQualityWorkOrder(userId, clientId, orderId, slotId, workOrder, data, workLogUpdate) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.WORK_ORDER_TEST_QUALITY;
        workLog.data = this.dataFromAction(data, WorkLogModel.WORK_ORDER_TEST_QUALITY);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertFlavorsWorkOrder(userId, clientId, orderId, slotId, workOrder, data) {
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.FLAVOR_PLANOGRAM;
        workLog.data = this.dataFlavor(data, WorkLogModel.FLAVOR_PLANOGRAM);
        workLog.slotId = slotId;
        workLog.orderId = orderId;
        workLog.userId = userId;
        workLog.clientId = clientId;
        workLog.time = util.getDateIsoNow();
        workLog.workOrderId = workOrderId;
        await workLog.persist();
        return workLog;
    }

    async insertDontSignVisit(userId, clientId, orderId, slot, dataDontSign, idWorkLog, time) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let workLog = new WorkLogModel();
        workLog.slotId = slot.id;
        workLog.id = idWorkLog;
        workLog.action = WorkLogModel.DONT_SIGN;
        workLog.orderId = orderId;
        workLog.clientId = clientId;
        workLog.userId = userId;
        workLog.time = time;
        workLog.data = this.dataFromAction(dataDontSign, WorkLogModel.DONT_SIGN);
        await workLog.persist();
        let workLogYaAniadido = false;
        for (let workLogWO of slot.workLog) {
            if (util.hasValue(workLog.id) && workLogWO.id === workLog.id) {
                workLogWO.data = workLog.data;
                workLogYaAniadido = true;
            }
        }
        if (!workLogYaAniadido) {
            if (slot["workLog"] != null) {
                slot["workLog"].push(workLog.toPlainObject());
            }
        }
        return workLog;
    }

    async endWorkOrder(userId, clientId, orderId, slot, workOrder, workLogUpdate) {
        let slotId = slot.id;
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = util.getDateIsoNow();
        workLog.hidrate(workLogUpdate)
        workLog.action = WorkLogModel.END_WORK_ORDER;
        workLog.data = this.dataFromAction([], WorkLogModel.END_WORK_ORDER);
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.assetId = workOrder.assetId;
        workLog.slotId = slotId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
    }

    async pauseProcessWorkOrder(userId, clientId, orderId, slot, workOrder, workLogUpdate) {
        let time = util.getDateIsoNow();
        let slotId = slot.id;
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = time;
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.PAUSE_WORK_ORDER;
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.assetId = workOrder.assetId;
        workLog.slotId = slotId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
    }

    async returnWorkOrder(userId, clientId, orderId, slot, workOrder, causeOfPause, time, workLogUpdate) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let slotId = slot.id;
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = time;
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.RETURN_WORK_ORDER;
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.assetId = workOrder.assetId;
        workLog.slotId = slotId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        workLog.data = this.dataFromAction(causeOfPause, WorkLogModel.RETURN_WORK_ORDER);
        await workLog.persist();
    }

    async startWorkOrder(userId, clientId, orderId, slot, workOrder, time, workLogUpdate) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let slotId = slot.id;
        let workOrderId = workOrder.id;
        let workLog = new WorkLogModel();
        workLog.time = time;
        workLog.hidrate(workLogUpdate);
        workLog.action = WorkLogModel.START_WORK_ORDER;
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.assetId = workOrder.assetId;
        workLog.slotId = slotId;
        workLog.clientId = clientId;
        workLog.workOrderId = workOrderId;
        await workLog.persist();
    }

    async endClientWorkOrder(userId, clientId, orderId, slot, data, time, workLogUpdate) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let workLog = new WorkLogModel();
        workLog.data = JSON.stringify({ [workLog.nameForData(WorkLogModel.END_VISIT)]: data });
        workLog.time = time;
        workLog.hidrate(workLogUpdate)
        workLog.action = WorkLogModel.END_VISIT;
        workLog.clientId = clientId;
        workLog.userId = userId;
        workLog.orderId = orderId;
        workLog.slotId = slot.id;
        await workLog.persist();
        return workLog;
    }

    async endClient(userId, clientId, orderId, slot, data, time,t) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let workLog = new WorkLogModel();
        workLog.action = WorkLogModel.END_VISIT;
        workLog.clientId = clientId;
        workLog.userId = userId;
        workLog.time = time;
        workLog.orderId = orderId;
        workLog.slotId = slot.id;
        workLog.data = JSON.stringify({ [workLog.nameForData(WorkLogModel.END_VISIT)]: data });
        await workLog.persist();
        await appState.slotsState.generateNewSlotPausesReturned(slot, time, t);
        return workLog;
    }

    //se inicie la ot se añada tareas y diagnosis y se pause desde el pedido
    async pauseWorkOrdersDaemon(userId, clientId, orderId, slot, time) {
        let timeIso = "";
        let causePause = { code: "0", id: "0", type: "causesPause", value: "Finalizado manualmente desde BO" };
        for (let workOrder of slot.workOrder) {
            let workLogs = workOrder.workLog;
            if (util.hasValue(time)) {
                time.add(1, 'minutes');
                timeIso = util.formatToISOString(time)
            }
            if (workLogs?.length > 0) {
                //Primero compruebo que los workLogs que tenga la OT son de la visita que estamos cerrando
                if (workLogs[workLogs.length - 1].slotId === slot.id) {
                    if (this.isInProgress(workOrder, slot)) {
                        await this.returnWorkOrder(userId, clientId, orderId, slot, workOrder, causePause, timeIso);
                    } else if (!this.isFinishedPausedOrReturned(workOrder, slot)) {
                        await this.startWorkOrder(userId, clientId, orderId, slot, workOrder, timeIso);
                        if (util.hasValue(time)) {
                            time.add(1, 'minutes');
                            timeIso = util.formatToISOString(time)
                        }
                        await this.returnWorkOrder(userId, clientId, orderId, slot, workOrder, causePause, timeIso);
                    }
                } else if (workLogs[workLogs.length - 1].slotId !== slot.id) {
                    let otherSlot = { id: workLogs[workLogs.length - 1].slotId }
                    if (!this.isFinishedPausedOrReturned(workOrder, otherSlot)) {
                        //Aqui tiene workLogs la OT pero no son de esta visita, si no estan finalizadas esas OTs las inicia y finaliza en esta
                        await this.startWorkOrder(userId, clientId, orderId, slot, workOrder, timeIso);
                        if (util.hasValue(time)) {
                            time.add(1, 'minutes');
                            timeIso = util.formatToISOString(time)
                        }
                        await this.returnWorkOrder(userId, clientId, orderId, slot, workOrder, causePause, timeIso);
                    }
                }
            } else {
                //Aqui entra si no se ha trabajado nada en la OT
                await this.startWorkOrder(userId, clientId, orderId, slot, workOrder, timeIso);
                if (util.hasValue(time)) {
                    time.add(1, 'minutes');
                    timeIso = util.formatToISOString(time)
                }
                await this.returnWorkOrder(userId, clientId, orderId, slot, workOrder, causePause, timeIso);
            }
        }

    }

}

export default WorkLogState;
