import React from "react";
import AbstractModel from "./AbstractModel";

export default class BudgetArticleModel extends AbstractModel {


    static CODE_ARTICLE = "CODE_ARTICLE";
    static CODE_TIME = "CODE_TIME";

    id;
    articleCode;
    workOrderId;
    description;
    prizeWithTax;
    prizeWithoutTax;
    totalPrizeWithTax;
    totalPrizeWithoutTax;
    quantity;
    budgetId;
    type;

    nameMainType = "budgetArticle";
    graphFindByIdOperation = "budgetArticle";
    graphFindOperation = "budgetArticlesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "id": "",
            "articleCode": "",
            "workOrderId": "",
            "description": "",
            "prizeWithTax": "",
            "prizeWithoutTax": "",
            "totalPrizeWithTax": "",
            "totalPrizeWithoutTax": "",
            "quantity": "",
            "budgetId": "",
            "type": "",
        };
        return result;
    }


}
