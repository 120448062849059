import React from 'react'
import { observer } from 'mobx-react'
import translate from '../../translator/translate'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'
import PropsUtil from '../../util/PropsUtil'
import { withRouter } from 'react-router-dom'
import BaseActions from '../base/BaseActions'
import appState from '../../state/AppState'
import util from '../../util/Util'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import DuplicateAssetModal from '../../components/DuplicateAssetModal'

@observer
class AssetActions extends BaseActions {

    constructor(props) {
        super(props);
        this.state = {
            duplicateAsset: false,
            rightModal: false,
            popUp: "",
        }
    }

    urlForAssetFromClient(asset) {
        let url = '/workOrder/ls/?filters=';
        url += '[{\"fieldKey\":\"assetId\",\"fieldName\":\"assetId\",\"fieldValue\":\"' + asset.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + asset.plate + '\"}]';
        return url;
    }

    urlForFormOrderCCFromAsset(asset) {
        let url = 'assetId=' + asset.id;
        if (util.hasValue(asset.storeId)) {
            url += '&storeId=' + asset.storeId;
        }
        url += '&origin=' + appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_ORIGIN, "CCEP").id;
        return '/order/form/?' + url;
    }

    urlForFormOrderFromAsset(asset) {
        let url = 'assetId=' + asset.id;
        if (util.hasValue(asset.storeId)) {
            url += '&storeId=' + asset.storeId;
        }
        return '/order/form/?' + url;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let model = this.props.model;
        return (
            <>
                <div className="dropdown-sublist__item ">
                    <a onClick={() => {
                        this.propsUtil.changeUrl(this.urlForAssetFromClient(model));
                        this.closeModal()
                    }}>
                        {"Ver sus órdenes de trabajo"}
                    </a>
                    <a onClick={() => this.setState({ duplicateAsset: true })}>
                        {"Duplicar Equipo"}
                    </a>
                    <a onClick={() => {
                        this.propsUtil.changeUrl(this.urlForFormOrderFromAsset(model));
                        this.closeModal()
                    }}>
                        {"Crear Pedido"}
                    </a>
                    {model.deletable &&
                    <a onClick={() => this.setState({ rightModal: true, popUp: "eliminar" })}>
                        {t("Eliminar Equipo")}
                    </a>
                    }
                    {!appState.userState.isUserRoleClient() &&
                    <a onClick={() => {
                        if (this.props.fromRightModal) {
                            this.propsUtil.changeUrlRequest({ "rightModalTab": "history" });
                        } else {
                            this.propsUtil.changeUrl(this.urlForHistoric());
                        }
                        this.closeModal()
                    }}>
                        {t("Control de cambios")}
                    </a>
                    }
                </div>

                <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                            onCloseModal={() => this.setState({
                                rightModal: false,
                                popUp: ""
                            })}>
                    {this.state.popUp == "eliminar" &&
                    <PopUp
                        title={t('Eliminar')}
                        subtitle={t('Equipo') + model.plate}
                        icon="exclamationTriangleIcon"
                        text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
                        label1={t('No eliminar')}
                        label3={t('Eliminar')}
                        onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                        onClick3={() => this.deleteModel('asset')}
                    >
                    </PopUp>
                    }

                </AlertModal>
                {this.state.duplicateAsset &&
                <DuplicateAssetModal
                    title={t('Matricula nueva')}
                    assetModel={model}
                    openModal={this.state.duplicateAsset}
                    closeModal={() => {
                        this.setState({ duplicateAsset: false })
                    }}
                />
                }
            </>
        )
    }
}

export default withRouter(AssetActions)
