import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {observable, runInAction} from 'mobx';
import InfoBlock from "./InfoBlock";
import SlotModel from "../models/SlotModel";
import AppLogger from "../util/AppLogger";
import Overlay from "./modals/Overlay";
import appState from "../state/AppState";
import PropsUtil from "../util/PropsUtil";
import {withRouter} from "react-router-dom";
import util from "../util/Util";
import translate from "../translator/translate";
import GraphException from "../network/GraphException";
import GqlErrors from "./status/GqlErrors";
import MapView from "./modals/MapView";
import WorkOrderDetailRow from "./WorkOrderDetailRow";
import RelatedTableConfig from "../models/RelatedTableConfig";
import CreateAndModify from "./fields/CreateAndModify";
import SlotShortModel from "../models/SlotShortModel";


@observer
class SlotDetailView extends Component {

    @observable slotId = "";
    @observable gqlErrors = null;

    constructor(props) {
        super(props);
        this.state = {
            modalMap: false, slot: {},
            workOrdersSlotGroupedCount: 0,
            workOrdersNotSlotGroupedCount: 0
        }
    }

    @observable workOrdersSlotGrouped = [];
    @observable workOrdersNotSlotGrouped = [];
    @observable slotview = true;

    async componentDidMount() {
        try {
            await runInAction(async() => {
                await this.loadDataSlot();
                this.calcWorkOrdersAndSupports();
                this.workOrdersSlotGrouped = appState.scheduleByTechDropState.getPaginationWorkOrdersGrouped(appState.scheduleByTechDropState.workOrdersSlotGroupedCopia, this.state.workOrdersSlotGroupedCount);
                this.workOrdersNotSlotGrouped = appState.scheduleByTechDropState.getPaginationWorkOrdersGrouped(appState.scheduleByTechDropState.workOrdersNotSlotGroupedCopia, this.state.workOrdersNotSlotGroupedCount);
            });
            this.loadSlotsFromTech();
        } catch (e) {
            let gqlErrors = new GraphException().getErrorsFromException(e);
            this.log({ gqlErrors });
            this.gqlErrors = gqlErrors;
        }
    }

    loadSlotsFromTech() {
        if (util.hasValue(this.props.slot?.userId)) {
            let result = [];
            let i = 0;
            for (let slotId of Object.keys(appState.scheduleByTechDropState.allSlotsDict)) {
                let slot = appState.scheduleByTechDropState.allSlotsDict[slotId];
                if (slot.userId == this.props.slot?.userId) {
                    result.push(slot);
                }
            }
            result = util.ordenarFechasFormato(result, "scheduledTime", "DDHHmm");
            for (let slot of result) {
                if (slot.id === this.props.slot.id) {
                    this.setState({ indexSlot: i })
                }
                slot.indexOf = i++;
            }
            appState.scheduleByTechDropState.slotsFromTech[this.props.slot?.userId] = result;
        }
    }

    async loadDataSlot() {
        let slotId = this.props.slot.id;
        let slotQuery = new SlotShortModel();
        slotQuery.addRelatedTableOnlyFields("workLog", new RelatedTableConfig(["id", "logicalDelete", "userId", "action", "slotId", "workOrderId", "orderId", "clientId", "time", "data"]));
        slotQuery.addRelatedTableOnlyFields("order", new RelatedTableConfig(["code", "comments", "origin", "createdBy", "createdAt"]));
        slotQuery.addRelatedTableOnlyFields("order.slot", new RelatedTableConfig(["id", "userId", "orderId", "slotUserRole"]));
        slotQuery.addRelatedTableOnlyFields("order.workOrder", new RelatedTableConfig(["id", "type", "code", "status", "subType", "assetType", "assetSubType"]));
        slotQuery.addRelatedTableOnlyFields("order.workOrder.workLog", new RelatedTableConfig(["id", "logicalDelete", "userId", "action", "slotId", "workOrderId", "orderId", "clientId", "time", "data"]));
        slotQuery.addRelatedTableOnlyFields("order.workOrder.asset", new RelatedTableConfig(["id", "plate", "type", "subType","manufacturer","model","serialNumber"]));
        let slotModel = await slotQuery.findById(slotId);
        let slotCopia = slotModel.toPlainObject();
        slotCopia.order = slotModel.order;
        slotCopia.workLog = slotModel.workLog;
        let slots1ToCopy = appState.scheduleByTechDropState.allSlotsDict[slotModel.id] || [];
        let slots1 = util.deepCloneOBJ(slots1ToCopy);
        let slots2 = util.deepCloneOBJ(slotCopia);
        this.state.slot = { ...slots1, ...slots2 };
    }


    changePageSlot(movimiento, nombreArray) {
        if (movimiento === "restar") {
            this.state [nombreArray + "Count"] = this.state[nombreArray + "Count"] - 10;
            this.setState({ ...this.state });
        } else {
            this.state [nombreArray + "Count"] = this.state[nombreArray + "Count"] + 10;
            this.setState({ ...this.state });
        }
        this[nombreArray] = appState.scheduleByTechDropState.getPaginationWorkOrdersGrouped(appState.scheduleByTechDropState[nombreArray + "Copia"], this.state[nombreArray + "Count"])
    }

    calcWorkOrdersAndSupports() {
        appState.scheduleByTechDropState.getWorkOrders(this.state.slot.order, this.state.slot.workOrderIds);
    }

    onCloseOverlay() {
        this.gqlErrors = [];
        appState.layoutState.backgroundScheduled = 0;
        appState.layoutState.backgroundRowScheduled = 0;
        this.slotview = false;
        appState.scheduleByTechDropState.slotClicked = {};
        appState.scheduleByTechDropState.workOrdersSlotGroupedCopia = [];
        appState.scheduleByTechDropState.workOrdersNotSlotGroupedCopia = [];
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    hasSlotStarted() {
        let slot = this.state.slot;
        return slot?.isStarted;
    }

    goChangeDetailSlot(tipo) {
        let arraySlots = appState.scheduleByTechDropState.slotsFromTech[this.props.slot?.userId];
        this.log({ goChangeDetailSlot: 1, arraySlots })
        let index = 0;
        for (let i = 0; i < arraySlots.length; i++) {
            if (arraySlots[i].id === this.props.slot.id) {
                index = i;
            }
        }
        if (tipo === "siguiente") {
            index++;
            if (index < arraySlots.length) {
                appState.scheduleByTechDropState.slotClicked = arraySlots[index]
            }
        } else if (tipo === "anterior") {
            index--;
            if (index >= 0) {
                appState.scheduleByTechDropState.slotClicked = arraySlots[index];
            }
        }
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        const t = translate;
        let slot = {};
        if (props.slot === null) {
            return null;
        } else {
            slot = props.slot;
        }
        let pantallaEmpiezaEn = window.pageYOffset;
        let boundingRectSlotBox = document.getElementById(slot.id)?.getBoundingClientRect();
        let elementoEmpiezaEn = pantallaEmpiezaEn + boundingRectSlotBox?.top;
        let difY = boundingRectSlotBox?.y - elementoEmpiezaEn;
        let pantallaXEmpizaEn = window.pageXOffset;
        let elementoXEmpiezaEn = pantallaXEmpizaEn + boundingRectSlotBox?.left;
        let difX = boundingRectSlotBox?.x - elementoXEmpiezaEn;
        let leftSlot = boundingRectSlotBox?.x - difX;
        let topSlot = boundingRectSlotBox?.y - difY;
        // this.log({ slot })

        let altoPantalla = window.innerHeight;
        let styleAbsolute = {};
        let classFlecha = "flecha-arriba";
        let bottom = altoPantalla - topSlot;
        let posicionLimite = altoPantalla / 2 + pantallaEmpiezaEn;
        if (topSlot > posicionLimite) {
            //Arriba
            styleAbsolute.bottom = bottom + 20;
            classFlecha = "flecha-debajo";
        } else {
            //Abajo
            styleAbsolute.top = topSlot + 40;
            classFlecha = "flecha-arriba";
        }
        //Si lo muestro arriba y se me sale de la pantalla por arriba lo muestro debajo, independinetemente de donde hay más hueco
        if (topSlot > posicionLimite) {
            let boundingRectSlotDetail = document.getElementById("slotDetail-"+slot?.id)?.getBoundingClientRect();
            if (boundingRectSlotDetail!=null) {
                let altoCajaMasBottom = styleAbsolute.bottom + boundingRectSlotDetail.height;
                if (altoCajaMasBottom > altoPantalla) {
                    styleAbsolute.bottom = null;
                    styleAbsolute.top = topSlot + 40;
                    classFlecha = "flecha-arriba";
                }
                /*
                this.log({
                    styleAbsolute,
                    boundingRectSlotDetail: boundingRectSlotDetail,
                    altoCajaMasBottom,
                    altoPantalla,
                    topSlot,
                    posicionLimite,
                    bottom: bottom + 20
                })
                 */
            }
        }
        //Calculo del estilo para eje X
        let pantallaYEmpiezaEn = window.pageXOffset;
        let anchoPantalla = window.innerWidth;
        let posicionYLimite = anchoPantalla / 2 + pantallaYEmpiezaEn;
        if (leftSlot > posicionYLimite) {
            //Izquierda
            styleAbsolute.right = anchoPantalla - leftSlot - 40;
            classFlecha += " flecha-derecha";
        } else {
            //Derecha
            styleAbsolute.left = leftSlot - 15;
            classFlecha += " flecha-izquierda";
        }
        let className = "c-modal-info-cell " + classFlecha;
        return (
            <>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <Overlay show={this.slotview} onClick={() => this.onCloseOverlay()} />
                <InfoBlock componentObject={this}>
                    <div className={className} style={styleAbsolute} id={"slotDetail-"+slot.id}>
                        {this.renderHeaderOrderSlot()}
                        <div className="c-modal-info__cell__body__table">
                            <table className="w-100">
                                <thead>
                                <tr>
                                    <td height="10" />
                                </tr>
                                <tr>
                                    {/*<th>{t("Tipo")}</th>*/}
                                    <th>{t("O.trabajo")}</th>
                                    <th>{t("Estado")}</th>
                                    <th>{t("Equipo matrícula")}</th>
                                    <th>{t("Fabricante")}</th>
                                    <th>{t("Modelo")}</th>
                                    <th>{t("N Serie")}</th>
                                    <th>{t("Tipo de equipo")}</th>
                                    <th>{t("Subtipo de equipo")}</th>
                                </tr>
                                </thead>
                                {this.renderWorkOrders(this.workOrdersSlotGrouped, "slot")}
                                {appState.scheduleByTechDropState.workOrdersSlotGroupedCopia.length > 10 &&

                                <tr>
                                    <td colSpan={5}>
                                        <div className="pagination-div">
                                            <ul className="pagination">
                                                <li>
                                                    <span
                                                        className={this.state.workOrdersSlotGroupedCount > 0 ? "page-link" : "page-link linkNotAllowed"}
                                                        tabIndex="-1" style={{ border: 0 }}
                                                        onClick={() => {
                                                            if (this.state.workOrdersSlotGroupedCount > 0) {
                                                                this.changePageSlot("restar", "workOrdersSlotGrouped")
                                                            }
                                                        }}>{"Anterior"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="pagination-div">
                                            <ul className="pagination">
                                                <li>
                                                    <span
                                                        className={this.state.workOrdersSlotGroupedCount < (appState.scheduleByTechDropState.workOrdersSlotGroupedCopia.length - 10) ? "page-link" : "page-link linkNotAllowed"}
                                                        tabIndex="-1" style={{ border: 0 }}
                                                        onClick={() => {
                                                            if (this.state.workOrdersSlotGroupedCount < (appState.scheduleByTechDropState.workOrdersSlotGroupedCopia.length - 10)) {
                                                                this.changePageSlot("sumar", "workOrdersSlotGrouped")
                                                            }
                                                        }}> {"Siguiente"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                }
                                {this.renderWorkOrders(this.workOrdersNotSlotGrouped, "notSlot")}

                                {appState.scheduleByTechDropState.workOrdersNotSlotGroupedCopia.length > 10 &&
                                <tr>
                                    <td colSpan={5}>
                                        <div className="pagination-div">
                                            <ul className="pagination">
                                                <li>
                                                    <span className="page-link" tabIndex="-1" style={{ border: 0 }}
                                                          onClick={() => this.changePageSlot("restar", "workOrdersNotSlotGrouped")}>{"Anterior"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="pagination-div">
                                            <ul className="pagination">
                                                <li>
                                                    <span className="page-link" tabIndex="-1" style={{ border: 0 }}
                                                          onClick={() => {
                                                              if (this.state.workOrdersSlotGroupedCount < (appState.scheduleByTechDropState.workOrdersSlotGroupedCopia.length - 10)) {
                                                                  this.changePageSlot("sumar", "workOrdersNotSlotGrouped")
                                                              }
                                                          }}> {"Siguiente"}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </td>
                                </tr>
                                }
                            </table>
                        </div>
                        {this.state.modalMap &&
                        <MapView
                            slot={this.state.slot}
                            title={t('Visita') + " " + t('Inicio de Jornada')}
                            clientModel={this.state.slot.client}
                            openModal={this.state.modalMap}
                            closeModal={() => this.setState({ modalMap: false })}
                        />
                        }
                    </div>
                </InfoBlock>
            </>
        );
    }

    calcularTecnicosApoyo(order) {
        let resultApoyo = 0;
        if (order?.slot != null) {
            for (let slot of order.slot) {
                if (slot.userId != null) {
                    if (slot.slotUserRole === SlotModel.SUPPORT) {
                        resultApoyo++;
                    }
                }
            }
        }
        return resultApoyo;
    }

    renderHeaderOrderSlot() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        const t = translate;
        let slot = {};

        if (props.slot === null) {
            return null;
        } else {
            slot = props.slot;
        }
        let usuario = appState.userCacheState.getUserById(props.slot?.userId);
        let tecnicosApoyo = this.calcularTecnicosApoyo(props.slot?.order);

        let order = this.state.slot?.order;
        let codes = "";
        if (order) {
            codes = util.getString(order.code) + " " + util.getString(order.externalCode);
        }
        return (
            <div className="c-modal-info-cell__header">
                <div className="c-modal-info-cell__header__table">
                    <table className="w-100">
                        <thead>
                        <tr>
                            <th width="100">{t("Pedido")}</th>
                            <th>{t("Establecimiento")}</th>
                            <th style={{ width: '50px' }} />
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td valign="top">
                                <span onClick={() => this.openOrderModal()}
                                      className="text-click uppercase underline">{codes}</span>
                            </td>
                            <td>
                            <span className="text-click visits__text underline"
                                  onClick={() => this.openClientModal(slot?.client)}>{(slot?.client?.name) + " - "}
                                <span>{(slot?.client?.posAddress || "") + " - "}{appState.typifiedState.getValue(slot?.client?.posZoneId) || ""}</span>                           </span>
                            </td>
                            <td>
                                {appState.scheduleByTechDropState.slotsFromTech[this.props.slot?.userId]?.[this.state.indexSlot]?.indexOf > 0 &&
                                <>
                            <span className="text-click"
                                  onClick={() => this.goChangeDetailSlot("anterior")}>
                                <span className={"fas fa-angle-left"} />
                             </span>
                                </>
                                }
                                {appState.scheduleByTechDropState.slotsFromTech[this.props.slot?.userId]?.[this.state.indexSlot]?.indexOf <
                                (appState.scheduleByTechDropState.slotsFromTech[this.props.slot?.userId]?.length - 1) &&
                                <>
                                <span className="text-click" onClick={() => this.goChangeDetailSlot("siguiente")}>
                                <span className={"fas fa-angle-right"} /></span>
                                </>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row">
                    <CreateAndModify
                        classNameP={'last-modified-slot'}
                        createdBy={order?.createdBy}
                        createdAt={order?.createdAt}
                    />
                </div>
                {util.hasValue(slot.scheduledTime) &&
                <div className="c-modal-info-cell__header__table">
                    <table className="w-100">
                        <thead>
                        <tr>
                            <th width="100">{t("Fecha")}</th>
                            <th>{t("Inicio de la visita")}</th>
                            <th>{t("Fin de la visita")}{!slot.isFinished && <i>{" (Previsto)"}</i>}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td valign="top">
                                <span className="text-uppercase">
                                    {slot.scheduledTime ? util.getMoment(slot.scheduledTime).format("YYYY/MM/DD") : ""}
                                </span>
                            </td>
                            <td><span
                                className="medium">{slot.scheduledTime ? util.getMoment(slot.scheduledTime).format("HH:mm") : ""}</span>
                                {this.hasSlotStarted() &&
                                <span className="link-underline pl-2" onClick={() => this.setState({ modalMap: true })}>
                                    <span className="fa fa-map-marked-alt pr-1" /> {t("Localización")}
                                </span>
                                }
                            </td>
                            <td>
                                <span
                                    className="medium">{slot.scheduledEndTime ? util.getMoment(slot.scheduledEndTime).format("HH:mm") : ""}</span>
                                {slot.isFinished &&
                                <span className="link-underline pl-2"
                                      onClick={() => this.goSummary(slot)}>
                                    <span className="fa fa-eye pr-1" />
                                    {t("Ver parte de trabajo")}
                                </span>
                                }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                }
                {(usuario.firstName && usuario.lastName) && //Algunos técnicos salen como undefined undefined
                <div
                    className="c-modal-info-cell__text blueMed--italic">{t("Técnico principal") + ": " + usuario.firstName + " " + usuario.lastName}</div>
                }

                <div className="c-modal-info-cell__header__info">

                    {/*comentarios*/}
                    {util.hasValue(order?.comments) &&
                    <p className="c-modal-info-cell__text blueMed--italic comment">{order.comments}</p>
                    }
                    {util.hasValue(slot.comments) &&
                    <p className="c-modal-info-cell__text blueMed--italic comment">{slot.comments} </p>
                    }
                    {/*tecnicos*/}
                    {tecnicosApoyo != 0 &&
                    <p className="c-modal-info-cell__text regular technicals">

                        <span className="attention"><i className="far fa-user mr-2" />{t("Técnicos de Apoyo")}{": "}
                            <span>{tecnicosApoyo}</span>
                    </span>

                    </p>
                    }
                    <p className="c-modal-info-cell__text regular">
                        <span onClick={() => this.openSlotModal()}
                              className="text-click uppercase underline">{slot.code}</span>
                    </p>
                    <p className="c-modal-info-cell__text regular">{(slot.viewedByTechnical && util.hasValue(slot.viewedByTechnicalDate)) &&
                    <i className="far fa-circle mr-2"
                       style={{ color: slot.viewedByTechnical === SlotModel.CODE_VIEWED_NO ? "red" : (slot.viewedByTechnical === SlotModel.CODE_VIEWED_PENDIG ? "orange" : "green") }} />
                    }
                        {(util.hasValue(slot.viewedByTechnical) && slot.viewedByTechnical !== SlotModel.CODE_VIEWED_NO) &&
                        <>
                            {t("Visto el")}{": "}
                            <span>{util.getMoment(slot.viewedByTechnicalDate).format("YYYY-MM-DD HH:mm")}</span>
                        </>
                        }
                    </p>
                </div>
            </div>
        )
    }

    renderWorkOrders(array, text) {
        const t = translate;
        return (
            array.length > 0 &&
            <>
                <tbody>
                {text !== "slot" &&
                <>
                    <tr>
                        <td height="10" />
                    </tr>
                    <tr>
                        <th colSpan="8">
                            {t("Otras Ordenes de Trabajo del Pedido")}
                        </th>
                    </tr>
                </>
                }
                {array.map(workOrder => (
                    <WorkOrderDetailRow workOrder={workOrder} key={workOrder.id} slot={this.state.slot} />
                ))}
                </tbody>
            </>

        )
    }

    goSummary(slot) {
        this.propsUtil.changeUrlRequest({
            orderId: slot.orderId,
            rightModalTab: "summary",
            rightModal: "modalorderls",
            fromRightModal: true
        })
    }

    openOrderModal() {
        let orderId = this.state.slot.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalorderls",
            "orderId": orderId,
            "rightModalTab": "order"
        });
    }

    openSlotModal() {
        let slotId = this.state.slot.id;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalslots",
            "slotId": slotId,
            "rightModalTab": "general"
        });
    }

    openClientModal(client) {
        this.propsUtil.changeUrlRequest({
            rightModal: "modalposclients",
            "clientId": client.id,
            "rightModalTab": "general"
        });
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(SlotDetailView);
