import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import TabsData from "../../layout/TabsData";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import translate from "../../translator/translate";
import TabsComponent from "../../components/fields/TabsComponent";
import PageTitle from "../../components/PageTitle";
import UserModel from "../../models/UserModel";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import BaseForm from "../base/BaseForm";
import FormButtons from "../../components/FormButtons";
import util from '../../util/Util';
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import MoreButtons from "../../components/MoreButtons";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import Select2Component from "../../components/fields/Select2Component";
import GroupOfField from "../../components/layout/GroupOfField";
import AutoSuggestComponent from "../../components/fields/AutoSuggestComponent";
import SwitchComponent from "../../components/fields/SwitchComponent";
import VehicleModel from "../../models/VehicleModel";
import GqlErrors from "../../components/status/GqlErrors";
import GqlWarnings from "../../components/status/GqlWarnings";
import UserActions from "./UserActions";
import ModalEnviarContrasenya from "../../components/modals/ModalEnviarContrasenya";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class UserFieldWorks extends BaseForm {

    nameMainType = "user";

    constructor(props) {
        super(props);
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);
        await appState.vehicleState.getVehicles();
        await appState.stockState.getDistintStocks();
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        this.setState({ loading: false });
    }

    getModelQueryForView() {
        let result = new UserModel();
        result.addRelatedTable("vehicle");
        return result;
    }

    getModelQuery() {
        return new UserModel();
    }

    getVehicleFromVehicleId(vehiclesId) {
        let result = {};
        if (util.hasValue(vehiclesId)) {
            for (let vehicle of appState.vehicleState.vehicles) {
                if (vehicle.id === vehiclesId) {
                    result = vehicle;
                    break;
                }
            }
        }
        return result;
    }

    async getVehiclesSugestions(value) {
        let vehiclesArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            // let signal = this.signal;
            // Obtenemos los Assets
            let vehiclesQuery = new VehicleModel();
            vehiclesQuery.filters = [
                { "fieldName": "plate", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let vehicles = await vehiclesQuery.find();
            // Transformamos a formato Autosuggest
            vehicles.map((vehicle) => {
                let vehicleObject = {};
                vehicleObject.label = vehicle.plate;
                vehicleObject.value = vehicle;
                vehiclesArray.push(vehicleObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return vehiclesArray;
    }

    async formDidSave(model, previousId) {
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
    }

    renderImpl() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let user = this.graphDataMainType;
        if (user == null) {
            user = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                {this.getFormId() != null &&
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                                 getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                                }
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataUser()}
                                active="Trabajos de campo"
                                alarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            onReloadData={() => this.reloadData()}
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.getFormId() != null &&
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                             getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <ModalEnviarContrasenya user={user} />
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataUser()}
                                active={"Trabajos de campo"}
                                fromRightModal={this.props.fromRightModal}
                            />
                            }
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking}
                            />

                            <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                            <GqlWarnings warnings={this.gqlWarnings} onSaveWarning={() => this.saveWithWarnings()}
                                         key={this.gqlWarnings}
                                         setWarnings={(warnings) => this.setWarnings(warnings)} />
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>

                                <GroupOfField title={t("Relación Laboral")} icon="fas fa-clipboard"/>
                                <SpinnerClipLoaderInPage loading={this.state.loading}>
                                      <div className="row">

                                     <InputTypeComponent
                                            value={user.company}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"company"}
                                            editable={this.editable}
                                            title={t("Empresa")}
                                            placeholder="Empresa"
                                            classGroup={"col-md-4 col-lg-4"}
                                            type={"text"}
                                            errors={errorsMapped.employeeNumber}
                                            dataCy={"inputCompany"}
                                        />
                                        <Select2Component
                                            value={user.employment}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"employment"}
                                            title={t("Puesto de Trabajo")}
                                            classGroup="col-md-6 col-lg-4"
                                            inputClass="col-2"
                                            editable={this.editable}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_EMPLOIMENTS)}
                                            errors={errorsMapped.employment}
                                            dataCy={"selectEmployment"}
                                        />
                                        <Select2Component
                                            value={user.zoneAsignedId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"zoneAsignedId"}
                                            isClearable={false}
                                            editable={this.editable}
                                            multi={true}

                                            title={t("Zona de trabajo")}
                                            classGroup="col-md-6 col-lg-4"
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ZONE)}
                                            errors={errorsMapped.zoneAsignedId}
                                            dataCy={"selectZoneAsigned"}
                                        />

                                        <InputTypeComponent
                                            value={user.employeeNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"employeeNumber"}
                                            title={t("Número de Empleado")}
                                            editable={this.editable}
                                            placeholder="Número de Empleado"
                                            classGroup={"col-md-4 col-lg-4"}
                                            type={"text"}
                                            info={"Sólo para empleados de Serlusa"}
                                            errors={errorsMapped.employeeNumber}
                                            dataCy={"inputEmployeeNumber"}
                                        />
                                        <InputTypeComponent
                                            value={user.companyPhoneNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"companyPhoneNumber"}
                                            title={t("Teléfono Empresa")}
                                            validate={"phone"}
                                            editable={this.editable}
                                            placeholder="Teléfono Empresa"
                                            classGroup={"col-md-6 col-lg-4"}
                                            type={"text"}
                                            errors={errorsMapped.companyPhoneNumber}
                                            dataCy={"inputCompanyNumber"}
                                        />
                                        <InputTypeComponent
                                            value={user.companyEmail}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"companyEmail"}
                                            title={t("Email Empresa")}
                                            editable={this.editable}
                                            placeholder="Email Empresa"
                                            classGroup={"col-md-6 col-lg-4"}
                                            type={"text"}
                                            validate={"email"}
                                            errors={errorsMapped.companyEmail}
                                            dataCy={"inputCompanyEmail"}
                                        />

                                        <InputTypeComponent
                                            value={user.codeErp}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="codeErp"
                                            title={t("Código ERP")}
                                            placeholder={t("Código ERP")}
                                            classGroup={"col-md-4 col-xl-4"}
                                            editable={this.editable}
                                            errors={errorsMapped.codeErp}
                                        />

                                        <AutoSuggestComponent
                                            value={this.getVehicleFromVehicleId(user.userVehicle).plate}
                                            onChange={(vehicleSelected) => {
                                                if (util.hasValue(vehicleSelected.id)) {
                                                    this.handleAutosuggestSelection("userVehicle", vehicleSelected.id);
                                                    user.vehicle = vehicleSelected
                                                } else {
                                                    user.userVehicle = '';
                                                    this.handleAutosuggestSelection("userVehicle", '');
                                                    user.vehicle = {};
                                                }
                                            }}
                                            name={"userVehicle"}
                                            title={t("Vehiculo")}
                                            classGroup={"col-md-6 col-lg-4"}
                                            errors={errorsMapped.userVehicle}
                                            editable={this.editable}
                                            info={t('Matrícula')}
                                            loadSuggestions={(value) => this.getVehiclesSugestions(value)}
                                            placeholder={t("Escribe...")}
                                        />
                                        <InputTypeComponent
                                            value={this.getVehicleFromVehicleId(user.userVehicle).brand}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"userPlate"}
                                            readOnly={true}
                                            title={t("Marca")}
                                            classGroup={"col-md-6 col-lg-4"}
                                            placeholder={t("Marca")}
                                            dataCy={"inputBrandVehicle"}
                                        />
                                        <Select2Component
                                            value={user.subInventory}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"subInventory"}
                                            title={t("Almacen")}
                                            classGroup={"col-md-6 col-lg-4"}
                                            editable={this.editable}
                                            placeholder={t("Almacen")}
                                            options={appState.stockState.labelValuesForSelect}
                                            info={t("Rellenar solo si no hay vehiculo")}
                                            dataCy={"selectsubImvetory"}
                                            errors={errorsMapped.subInventory}
                                        />
                                          <SwitchComponent
                                              checked={user.stockControl}
                                              onChange={(e) => this.updateInputEvent(e)}
                                              name={"stockControl"}
                                              value={user.stockControl}
                                              className={"col-12"}
                                              editable={this.editable}
                                              relatedInfo={t("El técnico solo podrá utilizar articulos desde el stock de su almacén")}
                                              title={t("Con control de Stock")}
                                              classGroup={"col-4"}
                                              errors={errorsMapped.stockControl}
                                              flexChildren={"0"}
                                              flexLabelInput="d-flex justify-content-between"
                                          />
                                          <SwitchComponent
                                              checked={user.erpSalesAutomatic}
                                              onChange={(e) => this.updateInputEvent(e)}
                                              name={"erpSalesAutomatic"}
                                              value={user.erpSalesAutomatic}
                                              className={"col-12"}
                                              editable={this.editable}
                                              relatedInfo={t("Envio automatico a ERP de los partes de trabajo")}
                                              title={t("Envio ERP Automatico")}
                                              classGroup={"col-4"}
                                              errors={errorsMapped.erpSalesAutomatic}
                                              flexChildren={"0"}
                                              flexLabelInput="d-flex justify-content-between"
                                          />
                                      </div>
                                    {this.props.fromRightModal ?
                                        <div className="fixed-bottom mr-3 mt-3">
                                            <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />
                                        </div>
                                        :
                                        <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />
                                    }

                                    </SpinnerClipLoaderInPage>
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>

        )
    }
}

export default withRouter(UserFieldWorks)
