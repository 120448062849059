import React from 'react';
import {observer} from 'mobx-react';
import BaseActions from "../base/BaseActions";
import translate from "../../translator/translate";
import {withRouter} from "react-router-dom";
import PropsUtil from "../../util/PropsUtil";
import appState from '../../state/AppState';

@observer
class ClientActions extends BaseActions {

    constructor(props) {
        super(props);
    }

    urlForClientFromClient(client) {
        let url = "";
        if (client.isPos == 1) {
            url = '/client/form/' + client.parentId;
        } else {
            url = '/clientPos/ls/?filters=';
            url += '[{\"fieldKey\":\"parentId\",\"fieldName\":\"parentId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + (client.companyName || client.code) + '\"}]';
        }
        return url;
    }

    urlForOrderFromClient(client) {
        let url = '/order/ls/?filters=';
        url += '[{\"fieldKey\":\"clientId\",\"fieldName\":\"clientId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + client.name + '\"}]';
        return url;
    }

    urlForAssetFromClient(client) {
        let url = '/asset/ls/?filters=';
        url += '[{\"fieldKey\":\"storeId\",\"fieldName\":\"storeId\",\"fieldValue\":\"' + client.id + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + client.name + '\"}]';
        return url;
    }

    urlForFormAssetFromClient(client) {
        return '/asset/form/?propietary=' + client.parentId + '&storeId=' + client.id;
    }

    urlForFormOrderFromClient(client) {
        return '/order/form/?storeId=' + client.id;
    }

    urlForHistoric() {
        let url = super.urlForHistoric();
        let model = this.props.model;
        if (model.isPos) {
            url = '/clientPos/form/' + model.id + '/history'
        }
        return url;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let model = this.props.model;
        return (
            <div className="dropdown-sublist__item ">
                {model.isPos ?
                    //Establecimientos
                    <>
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForOrderFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Ver en Pedidos")}
                        </a>
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForAssetFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Ver sus equipos")}
                        </a>
                        {!appState.userState.isUserRoleClient() &&
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForFormAssetFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Crear equipo")}
                        </a>
                        }
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForFormOrderFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Crear Pedido")}
                        </a>
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForClientFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Ver cliente")}
                        </a>
                        {!appState.userState.isUserRoleClient() &&
                        <a onClick={() => {
                            if (this.props.fromRightModal) {
                                this.propsUtil.changeUrlRequest({"rightModalTab": "history"});
                            } else {
                                this.propsUtil.changeUrl(this.urlForHistoric());
                            }
                            this.closeModal()
                        }}>
                            {t("Control de cambios")}
                        </a>
                        }
                    </>
                    :
                    //Clientes
                    <>
                        <a onClick={() => {
                            this.propsUtil.changeUrl(this.urlForClientFromClient(model));
                            this.closeModal()
                        }}>
                            {t("Ver su Establecimiento")}
                        </a>
                        {!appState.userState.isUserRoleClient() &&
                        <a onClick={() => {
                            if (this.props.fromRightModal) {
                                this.propsUtil.changeUrlRequest({"rightModalTab": "history"});
                            } else {
                                this.propsUtil.changeUrl(this.urlForHistoric());
                            }
                            this.closeModal()
                        }}>
                            {t("Control de cambios")}
                        </a>
                        }
                    </>
                }
            </div>
        )
    }
}

export default withRouter(ClientActions)
