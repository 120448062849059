import { observable, runInAction } from 'mobx';
import util from "../util/Util";
import AppLogger from "../util/AppLogger";
import ScheduleVisitModel from "../models/ScheduleVisitModel";
import SlotModel from "../models/SlotModel";
import moment from "moment";
import RelatedTableConfig from "../models/RelatedTableConfig";

class ScheduleClientDropState {

    @observable scheduleVisits = [];
    @observable zona;
    @observable clientId;
    @observable scheduleVisitsDict = {};
    //Slots para la parte de planificación
    @observable allSlotsDict = {};
    @observable slotsFromTech = {};
    //Slots agrupados para poder saber el número de elementos en base a técnico - dia - etc
    @observable allSlotsGrouped = {};
    @observable slotClicked = {};
    @observable clients = [];
    @observable copiaClients = [];
    @observable horasGrafico = {};
    @observable horasGraficoMayor = 0;
    @observable slotsFromClientDict = {};

    /**
     * Contendrá un array con las horas forzadas. Por ejemplo: {"2019-01-01":{min:5,max:23}}
     * A la hora de pintar tendrá en cuenta este array para que sea el que se utilice
     * @type {{}}
     */
    @observable dateRangeForced = {};
    @observable scheduledDict = [];

    loadClients(zona, cliente) {
        let slotsFromClientDict = {};
        let clientesDict = {};
        let clientesArray = [];
        for (let slotId in this.allSlotsDict) {
            let slot = this.allSlotsDict[slotId];
            if (util.hasValue(slot.client?.id)) {
                if (!util.hasValue(zona) && !util.hasValue(cliente)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                } else if (util.perteneceA(slot.client.posZoneId, zona)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                } else if (util.perteneceA(slot.clientId, cliente)) {
                    if (clientesDict[slot.client.id] == null) {
                        clientesDict[slot.client.id] = slot.client;
                        clientesArray.push(slot.client)
                    }
                }
                if (!slot.isFinished) {
                    if (slotsFromClientDict[slot.client.id] == null) {
                        slotsFromClientDict[slot.client.id] = [];
                    }
                    slotsFromClientDict[slot.client.id].push(slot);
                }
            }
        }
        runInAction(() => {
            let clientsOrderView = util.arrayModelToPlainObjects(clientesArray);
            this.clients = clientsOrderView;
            this.copiaClients = clientsOrderView;
            this.slotsFromClientDict = slotsFromClientDict;
            localStorage.setItem('loadClients', "Todas");
        });
    }

    loadClientsQ(q) {
        if (!util.hasValue(q)) {
            this.clients = this.copiaClients;
            this.getCacheWorkUnitsByClient();
            this.getHoursWorkUnitsByClient();
        } else {
            let clients = [];
            this.copiaClients.map(client => {
                let regExp = new RegExp(q, 'gi');
                if (regExp.test(client.name)) {
                    clients.push(client)
                }
            });
            this.getCacheWorkUnitsByClient(q);
            this.getHoursWorkUnitsByClient(q);
            this.clients = clients;
        }
    }

    orderClientsByOrderView(clientesObj) {
        let orderViewArr = [];
        let clientsWithoutZone = [];
        Object.keys(clientesObj).map((zona) => {
            let result = clientesObj[zona].sort(function (a, b) {
                let key1 = a.orderView;
                let key2 = b.orderView;
                if (key1 < key2 || !util.hasValue(key2)) {
                    return -1;
                }
                if (key1 > key2 || !util.hasValue(key1)) {
                    return 1;
                }
                return 0;
            });
            if (util.hasValue(zona)) {
                orderViewArr = [...orderViewArr, ...result];
            } else {
                clientsWithoutZone = [...result];
            }
        });
        orderViewArr = [...orderViewArr, ...clientsWithoutZone];
        return orderViewArr;
    }

    async loadClientsFromScheduledByClient(dateFrom) {
        let slotsFromClientDict = {};
        let allSlotsDict = {};
        let clientesArray = {};
        let slotQuery = new SlotModel();
        slotQuery.filters.push({ "fieldName": "scheduledDate", "fieldValue": dateFrom, "filterOperator": "EQ" });
        slotQuery.addRelatedTableOnlyFields("client", new RelatedTableConfig(["id", "name", "posAddress", "posZoneId", "geoLongitude", "geoLatitude","orderView"]));
        slotQuery.addRelatedTableOnlyFields("order", new RelatedTableConfig(["origin","scheduleVisitId","clientId"]));
        slotQuery.addRelatedTableOnlyFields("workOrder", new RelatedTableConfig(["id", "type", "code", "status", "subType", "assetId", "assetType", "assetSubType"]));
        slotQuery.addRelatedTableOnlyFields("workOrder.asset", new RelatedTableConfig(["id", "plate", "type", "subType"]));
        let slots = await slotQuery.findPlainObject();
        let clients = {};
        for (let slot of slots) {
            if (util.hasValue(slot.client?.id)) {
                if (clients[slot.client?.id] == null) {
                    clients[slot.client.id] = "";
                    if (clientesArray[slot.client.posZoneId || ""] == null) {
                        clientesArray[slot.client.posZoneId || ""] = []
                    }
                    if (!util.hasValue(this.zona) && !util.hasValue(this.clientId)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    } else if (util.hasValue(this.zona) && util.perteneceA(slot.client.posZoneId, this.zona)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    } else if (util.hasValue(this.clientId) && util.perteneceListaComa(slot.client.id, this.clientId)) {
                        clientesArray[slot.client.posZoneId || ""].push(slot.client);
                    }
                }
                if (slotsFromClientDict[slot.client.id] == null) {
                    slotsFromClientDict[slot.client.id] = [];
                }
                if (slot.slotUserRole === SlotModel.MAIN && !util.hasValue(slot.scheduledTime)) {
                    slotsFromClientDict[slot.client.id].push(slot.id);
                }
                if (clientesArray[slot.client.posZoneId || ""].length > 0) {
                    allSlotsDict[slot.id] = slot;
                }
            }
        }
        let clientsOrderView = this.orderClientsByOrderView(clientesArray);
        this.clients = clientsOrderView;
        this.copiaClients = clientsOrderView;
        this.slotsFromClientDict = slotsFromClientDict;
        this.allSlotsDict = allSlotsDict;
        this.allSlotsGrouped = this.getCacheWorkUnitsByClient();
    }

    getDayAndMonth(date) {
        return moment(date).format("MMDD");
    }

    getCacheWorkUnitsByClient() {
        let cacheWorkUnits = {};
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            let hour = util.getHourFromDatetime(slot.scheduledTime) < 14 ? 8 : 14;
            let order = slot.order;
            let clientId = order && util.getString(order.clientId);
            if (slot.scheduledTime != null && slot.slotUserRole === SlotModel.MAIN) {
                let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime));
                let key = hour + ";" + util.getString(clientId) + ";" + isoDate;
                if (cacheWorkUnits[key] == null) {
                    cacheWorkUnits[key] = [];
                }
                cacheWorkUnits[key].push(slotId);
            }
        }
        return cacheWorkUnits;
    }

    getHoursWorkUnitsByClient(q) {
        let regExp = new RegExp(q, 'gi');
        let horasGraficoMayor = 0;
        let horasGrafico = { "manyana": {}, "tarde": {} };
        for (let slotId of Object.keys(this.allSlotsDict)) {
            let slot = this.allSlotsDict[slotId];
            if (util.hasValue(slot.scheduledTime)) {
                let coincidence = false;
                if (horasGrafico.manyana[this.getDayAndMonth(slot.scheduledTime)] == null) {
                    horasGrafico.manyana[this.getDayAndMonth(slot.scheduledTime)] = 0;
                }
                if (horasGrafico.tarde[this.getDayAndMonth(slot.scheduledTime)] == null) {
                    horasGrafico.tarde[this.getDayAndMonth(slot.scheduledTime)] = 0;
                }
                let horas = slot.scheduledDuration;
                if (this.scheduledDict[slot.order?.scheduleVisitId]?.estimatedtotalTime > slot.scheduledDuration) {
                    horas = this.scheduledDict[slot.order.scheduleVisitId].estimatedtotalTime;
                }
                if (!util.hasValue(this.zona) && !util.hasValue(this.clientId) && !util.hasValue(q)) {
                    coincidence = true;
                } else if (util.hasValue(this.zona) && util.perteneceA(slot.client.posZoneId, this.zona) && !util.hasValue(q)) {
                    coincidence = true;
                } else if (util.hasValue(this.clientId) && util.perteneceListaComa(slot.client.id, this.clientId) && regExp.test(slot.client?.name)) {
                    coincidence = true;
                } else if (util.perteneceA(slot.client?.posZoneId, this.zona) && regExp.test(slot.client.name)) {
                    coincidence = true;
                }
                if (coincidence) {
                    if (util.getMoment(slot.scheduledTime).format("H") < 14) {
                        horasGrafico.manyana[this.getDayAndMonth(slot.scheduledTime)] += horas;
                    } else {
                        horasGrafico.tarde[this.getDayAndMonth(slot.scheduledTime)] += horas;
                    }
                    horasGraficoMayor = Math.max(horasGraficoMayor, horasGrafico.manyana[this.getDayAndMonth(slot.scheduledTime)]);
                    horasGraficoMayor = Math.max(horasGraficoMayor, horasGrafico.tarde[this.getDayAndMonth(slot.scheduledTime)]);
                }
            }
        }
        this.horasGraficoMayor = horasGraficoMayor;
        this.horasGrafico = horasGrafico;
    }


    /**
     * Carga los tipos de visitas que se pueden planificar
     * */
    async loadScheduledVisits() {
        let scheduleVisit = new ScheduleVisitModel();
        scheduleVisit.addRelatedTable("scheduleVisitSlots");
        let result = await scheduleVisit.find();
        let resultDict = {};
        for (let visit of result) {
            resultDict[visit.id] = visit;
        }
        this.scheduledDict = util.getDictSingleFromArray(result);
        this.scheduleVisits = result;
        this.scheduleVisitsDict = resultDict;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ScheduleClientDropState;
