import React from 'react'
import appState from '../../state/AppState'
import { observer } from 'mobx-react'
import TabsComponent from '../../components/fields/TabsComponent'
import PageTitle from '../../components/PageTitle'
import translate from '../../translator/translate'
import GroupOfField from '../../components/layout/GroupOfField'
import { withRouter } from 'react-router-dom'
import { computed } from 'mobx'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import VsfButtonNew from '../../components/VsfButtonNew'
import BaseEditableTable from '../base/BaseEditableTable'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import UserAvailabilityRow from './UserAvailabilityRow'
import DateRangesModel from '../../models/DateRangesModel'
import TabsData from '../../layout/TabsData'
import UserModel from '../../models/UserModel'
import MoreButtons from '../../components/MoreButtons'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import DateInputMonthMobiscroll from '../../components/fields/DateInputMonthMobiscroll'
import UserActions from './UserActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import Select2Component from '../../components/fields/Select2Component'

@observer
class UserAvailabilityTable extends BaseEditableTable {
    nameMainType = 'user'

    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield

    constructor (props) {
        super(props)
        this.graphData.data[this.nameMainType] = {}
        this.tabsData = new TabsData()
        this.tabsData.isClient = appState.userState.isUserRoleClient()
        this.foreingKeyfield = 'userId'
        this.state = {
            date: '',
            status: '',
            loading: true
        }
    }

    @computed get mobxListado () {
        return appState.dateRangesState.dateRanges
    }

    setMobxListado (newValue) {
        appState.dateRangesState.dateRanges = newValue
    }

    async componentDidMountImpl () {
        await super.componentDidMountImpl()
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, 'CLIENT').id, this.graphDataMainType.role)
        await this.loadTable()
        this.setState({ loading: false })
    }

    getModelQuery () {
        return new UserModel()
    }

    getModelQueryForView () {
        let result = new UserModel()
        result.addRelatedTable('dateRangeUser')
        return result
    }

    getModelTable () {
        return new DateRangesModel()
    }

    changeState (e) {
        if (e.target.name === 'date') {
            this.setState({ [e.target.name]: util.getMoment(e.target.value) })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    async loadTable () {
        appState.dateRangesState.dateRanges = util.ordenarFechasDesc(this.graphDataMainType.dateRangeUser, 'startDate')
    }

    renderImpl () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let user = this.graphDataMainType
        let modalClasses = util.getModalClasses()
        let dateRangeStatus = [
            {
                label: t('Baja'),
                value: DateRangesModel.CODE_TIMEOFF
            },
            {
                label: t('Vacaciones'),
                value: DateRangesModel.CODE_HOLIDAYS
            },
            {
                label: ('Guardia'),
                value: DateRangesModel.CODE_ONGUARD
            },
        ]
        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ''}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={'edit'}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)} model={user}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId() || this.props.userId}
                                tabs={this.tabsData.getDataUser()}
                                active="Disponibilidad"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.getFormId() != null &&
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <UserActions fromRightModal={this.props.fromRightModal}
                                    setErrors={(errors) => this.setErrors(errors)} model={user}
                                    getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className={'card mb-3'}>
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId() || this.props.userId}
                                tabs={this.tabsData.getDataUser()}
                                active="Disponibilidad"
                                fromRightModal={this.props.fromRightModal}
                            />
                            }
                            <GroupOfField
                                title={t('Disponibilidad Usuario')}
                                icon="fas fa-id-card"
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="row">
                                    <div className={this.props.fromRightModal ? 'col-9 row' : 'col-10 row'}>
                                        <Select2Component
                                            value={this.state.status}
                                            onChange={(e) => this.changeState(e)}
                                            name={'status'}
                                            title={t('Estado')}
                                            options={dateRangeStatus}
                                            editable={this.editable}
                                            classGroup={'col-lg-4'}
                                        />
                                        <DateInputMonthMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            value={this.state.date} allowEmptyDate dateWithoutMoment
                                            classGroup="input-group input-group--calendar"
                                            title={t('Fecha de inicio')}
                                            initialDay={15}
                                            onChange={(e) => this.changeState(e)}
                                            name={'date'}
                                            type={'text'}
                                        />
                                    </div>
                                    <div className={'text-right'}>
                                        <VsfButtonNew
                                            disabled={this.state.hasSomeRowEditing}
                                            showButton={this.creatable['dateRange'] || false}
                                            label={t('Crear nueva')}
                                            onClick={_ => this.onOpenRowNewEmpty()}
                                            className={' mt-4 ml-3 btn btn--loader'}
                                        />
                                    </div>
                                </div>

                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                            {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ningun estado creado')}
                                            </td>
                                        </tr>
                                            }
                                            {this.mobxListado.length != 0 &&

                                        <tr>
                                            <th className="wo-technical-name" scope="col">{t('Estado Temporal')}</th>
                                            <th scope="col">{t('Fecha Inicio')}</th>
                                            <th scope="col">{t('Fecha Fin')}</th>
                                            <th scope="col">&nbsp;</th>
                                        </tr>
                                            }
                                        </thead>
                                        <tbody className="border-end">
                                            {this.mobxListado.map((usuario, index) => (
                                                <UserAvailabilityRow
                                                    key={usuario.id}
                                                    userId={this.props.userId}
                                                    rowIndex={index}
                                                    row={usuario}
                                                    hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                    dateFilter={this.state.date}
                                                    statusFilter={this.state.status}
                                                    setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                    {...this.props}
                                                />
                                            ))}

                                        </tbody>
                                    </table>
                                </div>
                                <div className="text-right pr-md-4 pt-3">
                                    <VsfButtonNew
                                        disabled={this.state.hasSomeRowEditing}
                                        showButton={this.creatable['dateRange'] || false}
                                        label={t('Crear nueva')}
                                        onClick={_ => this.onOpenRowNewEmpty()}
                                        className={'btn btn--loader'}
                                    />

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
    }

}

export default withRouter(UserAvailabilityTable)

