import React from 'react'
import PropTypes from 'prop-types'
import FormInputWrapper from './FormInputWrapper'
import util from '../../util/Util'
import BaseFieldComponent from './BaseFieldComponent'
import AsyncSelect from 'react-select/async'
import MezclarObj from '../../util/MezclarObj'
import debounce from '../status/debounce-decorator'

export default class SelectSuggestComponent extends BaseFieldComponent {
    static defaultProps = MezclarObj.assign(BaseFieldComponent.defaultProps, {
        onChange: _ => 0, //Evento al cambiar el elemento
        value: '', // Value of the input
        name: '', // Name of the input
        title: '', // Title of the element. Showed in the label
        classGroup: '', // CSS class
        options: [], // List of elements to show in the select [{"label":"Label", value:"1"}]
        isClearable: true,
        multi: false,
        forceMedia: null, // Fuerza a que se pinte con un media concreto. Por ejemlo md.
        concatLabel: '',
        defaultTimeOut: 0,
    })

    static propTypes = MezclarObj.assign(BaseFieldComponent.propTypes, {
        onChange: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
        title: PropTypes.string,
        classGroup: PropTypes.string,
        options: PropTypes.array,
        isClearable: PropTypes.bool,
        multi: PropTypes.bool,
        forceMedia: PropTypes.string,
        concatLabel: PropTypes.string,
    })

    constructor (props) {
        super(props)
        this.state = {
            value: null,
            options: [],
            changeSelect: false,
            defaultOptions: true,
            resetKey: false,
            defaultTimeOut: this.props.defaultTimeOut,
        }
    };

    async componentDidMount () {
        this.setState({
            value: this.props.value,
        })
        if (this.checkReadOnly()) {
            let defaultOptions = await this.props.loadSuggestions('')
            this.setState({
                changeSelect: !this.state.changeSelect,
                defaultOptions,
                options: defaultOptions,
                resetKey: !this.state.resetKey
            })
        }

    }

    async componentWillReceiveProps (nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            })
        }
        if ((util.hasValue(nextProps.changeSelect) && nextProps.changeSelect !== this.props.changeSelect)) {
            let defaultOptions = await this.props.loadSuggestions('')
            this.setState({
                changeSelect: nextProps.changeSelect,
                defaultOptions,
                options: defaultOptions,
                resetKey: !this.state.resetKey
            })
        }
    }

    getOptionsKeyed () {
        let result = {}
        if (this.state.options.length > 0) {
            for (let item of this.state.options) {
                result[item?.value] = item
                result[item?.value?.id] = item
                result[item?.label] = item
            }
        }
        return result
    }

    onBlurFunction () {
        let obj = {
            target: {
                value: this.props.value,
                name: this.props.name
            }
        }
        if (this.props.onBlur != null) {
            this.props.onBlur(obj)
        } else {
            this.validateOnBlurFunction(obj)
        }
    }

    onInputChange (value) {
        this.setState({ text: value })
    }

    handleChange (value) {
        if (util.hasValue(this.props.value) && !util.hasValue(this.state.text) || util.hasValue(this.state.text) || util.hasValue(value)) {
            let eventObj = {
                target: {
                    name: this.props.name,
                    value: '',
                    label: ''
                }
            }
            if (this.props.multi) {
                let options = [...this.state.options, ...value || []]
                this.setState({ options })
                if (util.hasValue(value)) {
                    for (let option of value) {
                        if (util.hasValue(eventObj.target.value)) {
                            eventObj.target.value += ',' + option.value + ''
                            eventObj.target.label += ',/\'' + option.label + '/\''
                        } else {
                            eventObj.target.value = '' + option.value + ''
                            eventObj.target.label = '/\'' + option.label + '/\''
                        }
                    }
                }
                this.setState({ value })
            } else {
                this.setState({ options: [value] })
                if (util.esVacio(value)) {
                    this.setState({
                        value: null
                    })
                } else {
                    this.setState({
                        value: value.value
                    })
                    eventObj.target.value = value.value
                    eventObj.target.label = value.label
                    eventObj.target.item = { ...value }
                }
            }
            if (this.props.onChange != null) {
                if (this.props.operatorFilter == null) {
                    this.props.onChange(eventObj, 'STRIN')
                } else {
                    this.props.onChange(eventObj, this.props.operatorFilter)
                }
            }
        }
    }

    getValueOptionMulti (values, dict) {
        let response = []
        if (values == null) {
            values = ''
        }
        let valuesArray = values.split(',')
        for (let option of valuesArray) {
            response.push(dict[option])
        }
        return response

    };

    @debounce(350)
    loadOptions (inputValue, callback) {
        if (inputValue.length > 2) {
            setTimeout(async () => {
                let res = await this.props.loadSuggestions(inputValue)
                let options = [...this.state.options, ...res || []]
                let defaultOptions = [...res || []]
                this.setState({
                    options,
                    defaultOptions
                })
                // this.log({ loadOptions: 1, options, defaultOptions, callback });
                callback(res)
            }, this.state.defaultTimeOut)
        } else {
            setTimeout(async () => {
                if (util.hasValue(this.props.value)) {
                    let res = await this.props.loadSuggestions(this.props.value, true)
                    let options = [...this.state.options, ...res || []]
                    let defaultOptions = [...res || []]
                    // this.log({ loadOptions: 2, options, defaultOptions, callback });
                    this.setState({
                        options,
                        defaultOptions,
                    })
                    callback(res)
                } else {
                    callback([])
                }
            }, this.state.defaultTimeOut)
        }
        this.setState({
            defaultTimeOut: 0,
        })
    };

    render () {
        let props = this.props
        let optionsKeyed = this.getOptionsKeyed()
        let valueOption = optionsKeyed[this.props.concatLabel + this.state.value]
        if (!util.hasValue(valueOption)) {
            valueOption = optionsKeyed[this.state.value + this.props.concatLabel]
        }
        if (this.props.multi) {
            valueOption = this.getValueOptionMulti(this.state.value, optionsKeyed)
        }
        let valueText = valueOption?.label
        if (this.props.multi) {
            valueText = valueOption?.map(option => option?.label)
            valueText = valueText.join(', ')
        }
        let readOnly = this.checkReadOnly()
        // this.log({
        //     render: 1,
        //     valueText,
        //     valueOption,
        //     optionsKeyed,
        //     state: this.state.value,
        //     options: this.state.options
        // })
        let classNameInput = this.getClassNameInput()
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                classInputType={'Select2Component'}
                name={props.name}
                ariaLabelledby={this.props.ariaLabelledby}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                title={this.props.title}
                errorsClientValidation={this.state.errorsClientValidation}
                required={this.props.required}
                isClearable={this.props.isClearable}
                relatedInfo={this.props.relatedInfo}
                info={this.props.info}
                forceMedia={this.props.forceMedia}
                dataCy={this.props.dataCy}
            >
                {readOnly ?
                    <input
                        value={valueText || props.value}
                        autoComplete="new-password"
                        className={classNameInput}
                        name={props.name}
                        placeholder={props.placeholder}
                        readOnly={readOnly}
                        type={props.type} />
                    :
                    <>
                        {this.props.showView ?
                            <div>
                                {valueText || props.value}
                            </div>
                            :
                            <AsyncSelect
                                placeholder={props.placeholder || 'Seleccionar'}
                                loadOptions={(e, f) => this.loadOptions(e, f)}
                                value={valueOption}
                                styles={this.customStyles}
                                key={this.state.resetKey}
                                cacheOptions
                                onChange={(e) => this.handleChange(e)}
                                onInputChange={(e) => this.onInputChange(e)}
                                isClearable={props.isClearable}
                                defaultOptions={this.state.defaultOptions}
                                onBlur={e => this.onBlurFunction(e)}
                                isMulti={this.props.multi}
                                loadingMessage={() => ('Cargando')}
                                noOptionsMessage={() => (this.state.text?.length < 3
                                    ? 'Debe escribir al menos 3 carácteres'
                                    : 'No hay resultados')}
                                data-cy={'SELCTTESTCY'}
                                filterOption={(candidate, input) => this.customFilter(candidate, input)}
                            />
                        }
                    </>
                }
            </FormInputWrapper>
        )
    }
}

