import { observable } from 'mobx'
import AppLogger from '../util/AppLogger'
import TypifiedValueModel from '../models/TypifiedValueModel'
import util from '../util/Util'
import BaseModelState from './BaseModelState'
import React from 'react'

class TypifiedState extends BaseModelState {

    /**
     * Indexado por el id del maestro contiene todos los objetos que tengo cargados.
     * @type {{}}
     */
    @observable allObjectsById = {}

    /**
     * Id del elemento que esta siendo editado en el formulario
     * @type {number}
     */
    @observable formIdTypifiedValue = 0
    /**
     * Indexado por el tipo de campo contiene un array de los maestros
     * @type {{}}
     */
    @observable arrWithType = {}

    /**
     * Obtiene el array de typifed para select jerarquico piramidal creando el arbol e iterando en preorden posteriormente
     * @returns {Array}
     */
    typifiedValuesForSelectPiramidal (type) {
        let typifiedValuesHierarchyArray = []
        if (this.arrWithType[type] != null) {
            // Primero, obtiene el arbol ordenado de los typifed
            let typifiedValuesHierarchy = this.getTypifiedValuesHierarchy(this.arrWithType[type])
            // Segundo, obtenemos el array en orden
            typifiedValuesHierarchyArray = this.getTypifiedValuesHierarchyArray(typifiedValuesHierarchy)
        }
        return typifiedValuesHierarchyArray
    }

    /**
     * Metodo que recorre los maestros padres para llamar recursivamente al metodo que creara los hijos
     * @param maestros
     * @returns {Array}
     */
    getTypifiedValuesHierarchy (maestros) {
        let result = []
        let maestrosLibres = maestros.slice(0)
        // this.log({getTypifiedValuesHierarchy:1,maestros})
        for (let typified of maestros) {
            // Si es un typified padre, construimos el arbol hijo
            if (this.isParent(typified, maestros) && !typified.logicalDelete) {
                // Quitamos el typified padre de la lista de maestros libres
                maestrosLibres = maestrosLibres.filter(function (obj) {
                    return obj.id !== typified.id
                })
                result.push({
                    ...typified,
                    value: typified.id,
                    childs: this.getTypifiedValuesHierarchyChilds(typified.id, maestrosLibres)
                })

            }
        }
        return result
    }

    /**
     * Metodo que a partir de un typified padre, busca maestros hijos y los coloca recursivamente
     * @param parentId
     * @param maestros
     * @returns {Array}
     */
    getTypifiedValuesHierarchyChilds (parentId, maestros, altura) {
        if (!altura) {
            altura = 1
        }
        let childs = []
        // Estos seran los maestros libres
        let maestrosLibres = maestros.filter(function (obj) {
            return obj.parentId != parentId
        })
        for (let typified of maestros) {
            // Si es un typified hijo, lo agregamos al array de hijos
            if (typified.parentId === parentId && !typified.logicalDelete) {
                childs.push({
                    ...typified,
                    value: typified.id,
                    altura,
                    childs: this.getTypifiedValuesHierarchyChilds(typified.id, maestrosLibres, altura)
                })
            }
        }
        return childs
    }

    /**
     * A partir del arbol ordenado de typified, obtiene el array en preorden recursivamente para ser mostrado en el select
     * @param maestros
     * @param piramidal
     * @param list
     * @param altura
     * @param disabled
     * @param label
     *
     * @returns {Array}
     */
    getTypifiedValuesHierarchyArray (maestros, list, altura = 0, disabled = false) {
        let result = []
        // this.log({getTypifiedValuesHierarchy:1,maestros})
        for (let typified of maestros) {
            // Si es el mismo typified del formulario, el y sus hijos no podran ser seleccionados como padres
            if (typified.id == this.formIdTypifiedValue) {
                disabled = true
            }
            // Añadimos el typified padre al array de retorno dependiendo si es para una lista o un select
            if (list) {
                result.push({
                    ...typified,
                    value: typified.id,
                    label: this.getTypifiedValueHierarchyLabel(this.getValue(typified.id), altura, true),
                    altura,
                    disabled
                })
            } else {
                // Distinguimos entre select piramidal o lineal
                let label = ''
                if (util.hasValue(typified.parentId) && typified.parentId != util.getZeroIdIdentifierGUID()) {
                    label = this.getValue(typified.parentId)
                }
                label += this.getTypifiedValueHierarchyLabel(this.getValue(typified.id), altura)
                if (util.hasValue(typified.originModel?.name)) {
                    label = (
                        <span>
                            {label}<span style={{ color: 'blue' }}>{' [' + typified.originModel?.name + ']'}</span>
                        </span>
                    )
                }
                result.push({
                    ...typified,
                    value: typified.id,
                    label,
                    altura,
                    disabled
                })

            }
            // Si el typified tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
            if (typified.childs.length != 0) {
                let childs = this.getTypifiedValuesHierarchyArray(typified.childs, list, altura + 1, disabled)
                result = result.concat(childs)
            }
            // Desactivamos el disabled para los hermanos
            if (typified.id == this.formIdTypifiedValue) {
                disabled = false
            }
        }

        return result

    }

    /**
     * Obtiene la etiqueta con guiones para una opcion de un selector jerarquico o una lista jerarquica
     * @param label
     * @param altura
     * @param list
     * @returns {string}
     */
    getTypifiedValueHierarchyLabel (label, altura, list) {
        let separacion = ''
        let separador = ' > '
        if (list) {
            separacion = ''
        } else {
            for (let i = 0; i < altura; ++i) {
                separacion += separador
            }
            separacion += ' '
        }
        return separacion + label
    }

    /**
     * Obtiene si el tipo de typifed value se puede ordenar jerarquicamente
     * @returns {boolean}
     */
    isTypifiedValueHierarchy (type) {
        let result = false
        if (type === TypifiedValueModel.CODE_WORK_ORDER_TYPE || type === TypifiedValueModel.CODE_ASSET_SUBTYPE) {
            result = true
        }
        return result
    }

    async loadMasters () {
        let listTypes = [
            TypifiedValueModel.CODE_DIAGNOSIS,
            TypifiedValueModel.CODE_APP_TASK,
            TypifiedValueModel.CODE_WORK_ORDER_TYPE,
            TypifiedValueModel.CODE_ASSET_TYPE,
            TypifiedValueModel.CODE_WORKORDER_STATUS,
            TypifiedValueModel.CODE_CHAINS_POS,
            TypifiedValueModel.CODE_USER_ROLE,
            TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION,
            TypifiedValueModel.CODE_ASSET_SUBTYPE,
            TypifiedValueModel.CODE_SYMPTHOM,
            TypifiedValueModel.CODE_SUBTYPE,
            TypifiedValueModel.CODE_RELATIONSHIP,
            TypifiedValueModel.CODE_INTERVENTION_CATEGORY,
            TypifiedValueModel.CODE_MODEL,
            TypifiedValueModel.CODE_ORDER_FROM,
            TypifiedValueModel.CODE_ORDER_STATUS,
            TypifiedValueModel.CODE_QUALITY,
            TypifiedValueModel.CODE_ARTICLE_FAMILY,
            TypifiedValueModel.CODE_CAUSES_PAUSE_JOURNEY,
            TypifiedValueModel.CODE_ARTICLE_SUBFAMILY,
            TypifiedValueModel.CODE_ARTICLE_TYPE,
            TypifiedValueModel.CODE_ZONE,
            TypifiedValueModel.CODE_ARTICLE_STATUS,
            TypifiedValueModel.CODE_CAUSES_PAUSE,
            TypifiedValueModel.CODE_ORDER_ORIGIN,
            TypifiedValueModel.CODE_PLANOGRAM_PRODUCT,
            TypifiedValueModel.CODE_PLANOGRAM_TASTE,
            TypifiedValueModel.CODE_PLANOGRAM_CAN,
            TypifiedValueModel.CODE_EMPLOIMENTS,
            TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION,
            TypifiedValueModel.CODE_DONT_SIGN,
            TypifiedValueModel.CODE_LOCALES,
            TypifiedValueModel.CODE_ASSET_STATUS,
            TypifiedValueModel.CODE_TIME_ZONES,
            TypifiedValueModel.CODE_VEHICLES,
            TypifiedValueModel.CODE_RETURN_STATUS,
            TypifiedValueModel.CODE_CONTRACT_CATEGORY,
            TypifiedValueModel.CODE_CONTRACT_TYPE,
            TypifiedValueModel.CODE_CONTRACT_PAYMENT_TYPE,
            TypifiedValueModel.CODE_CONTRACT_PAYMENT_METHOD,
            TypifiedValueModel.CODE_CONTRACT_BILL,
        ]

        let typeQuery = new TypifiedValueModel()
        typeQuery.filters = [
            {
                'fieldName': 'type',
                'fieldValue': listTypes.join(','),
                'filterOperator': 'STRIN'
            },
            {
                'fieldName': 'includeDeleteRecords',
                'fieldValue': '1',
                'filterOperator': 'EQ'
            },
        ]
        typeQuery.orderMode = 'DESC'
        typeQuery.addRelatedTable('originModel')
        let rows = await typeQuery.findPlainObject()
        let newDict = {}
        for (let row of rows) {
            if (newDict[row.type] == null) {
                newDict[row.type] = []
            }
            newDict[row.type].push(row)
            this.allObjectsById[row.id] = row
        }
        for (let type of listTypes) {
            this.arrWithType[type] = newDict[type]
        }
        // this.log({ loadMasters: 1, rows, arrWithType: this.arrWithType })
    }

    getObject (id) {
        let result = this.allObjectsById[id]
        if (result == null) {
            result = new TypifiedValueModel()
        }
        return result
    }

    getArrWithType (masterType) {
        return this.arrWithType[masterType].filter(obj => !obj.logicalDelete)
    }

    getObjectFromCode (masterType, code) {
        let result = this.arrWithType[masterType] ? this.arrWithType[masterType].find(e => e.code == code) : null
        if (result == null) {
            result = new TypifiedValueModel()
        }
        // this.log({ getObjectFromCode: 1, result, assWithType: this.arrWithType[masterType] });
        return result
    }

    getArrayFromMaster (masterType) {
        let result = []
        if (this.arrWithType[masterType] && this.arrWithType[masterType]?.length != 0) {
            result = this.arrWithType[masterType]
        }
        // this.log({ getArrayFromMaster: 1, result, assWithType: this.arrWithType[masterType] });
        return result
    }

    getValue (id) {
        return this.getValueLang(id)
    }

    getValueMultiple (ids) {
        let result = ''
        for (let id of ids.split(',')) {
            if (util.hasValue(this.getValue(id))) {
                result += this.getValue(id) + ', '
            }
        }
        if (util.hasValue(result)) {
            const regex = /(,\s)$/gm
            result = result.replace(regex, '')
        }
        return result
    }

    getValueLang (id) {
        let result = ''
        try {
            result = JSON.parse(this.getObject(id).value)[util.getLang()]
            if (!util.hasValue(result)) {
                for (let lang of this.getArrayFromMaster(TypifiedValueModel.CODE_LOCALES)) {
                    if (util.hasValue(JSON.parse(this.getObject(id).value)[lang.code])) {
                        result = JSON.parse(this.getObject(id).value)[lang.code]
                    }
                }
            }
        } catch (e) {
            // this.log({ ERRORPARSE: 1, e })
        }
        if (!util.hasValue(result)) {
            result = this.getObject(id).value
        }
        if (this.getObject(id).logicalDelete) {
            result += ' (Obsoleto)'
        }
        return result

    }

    getCode (id) {
        return this.getObject(id).code
    }

    isParent (typified, arrNodos) {
        let result
        if (util.esVacio(typified.parentId) || typified.parentId === util.getZeroIdIdentifierGUID()) {
            result = true
        }
        return result
    }

    getLabelValuesForSelect (masterType) {
        let result = []
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (this.isParent(typified, this.arrWithType[masterType])) {
                    result.push({
                        ...typified,
                        value: typified.id,
                        label: this.getValueLang(typified.id),
                        logicalDelete: typified.logicalDelete,
                    })
                }
            }
        }
        return result
    }

    getLabelValuesForSelectByCode (masterType) {
        let result = []
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (this.isParent(typified, this.arrWithType[masterType])) {
                    result.push({
                        ...typified,
                        value: typified.code,
                        label: this.getValueLang(typified.id),
                        logicalDelete: typified.logicalDelete,
                    })
                }
            }
        }
        return result
    }

    getLabelValuesForSelectExcludeCode (masterType, codeExclude) {
        let result = []
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (this.isParent(typified, this.arrWithType[masterType])) {
                    if (typified.code != codeExclude) {
                        result.push({
                            ...typified,
                            value: typified.id,
                            label: this.getValueLang(typified.id),
                            logicalDelete: typified.logicalDelete,
                        })
                    }
                }
            }
        }
        return result
    }

    getLabelValuesForSelectChilds (masterType, parentId) {
        let result = []
        if (this.arrWithType[masterType]) {
            for (let typified of this.arrWithType[masterType]) {
                if (!util.hasValue(parentId)) {
                    if (util.getZeroIdIdentifierGUID() !== typified.parentId) {
                        result.push({
                            value: typified.id,
                            label: this.getValueLang(typified.id)
                        })
                    }
                } else if (typified.parentId == parentId) {
                    result.push({
                        value: typified.id,
                        label: this.getValueLang(typified.id)
                    })
                }
            }
        }
        return result
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default TypifiedState
