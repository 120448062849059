import React, { Component } from 'react';
import { observer } from 'mobx-react';
import appState from "../state/AppState";
import '../scss/components/info-block.scss';
import LocalStorage from "../network/LocalStorage";
import util from "../util/Util";
import CookieStorage from "../network/CookieStorage";
import AppLogger from "../util/AppLogger";

@observer
class InfoBlockSwitch extends Component {
    setEnabled(enabled) {
        if (enabled) {
            appState.layoutState.infoBlockEnabled = true;
        } else {
            appState.layoutState.infoBlockEnabled = false;
        }
        //DEBUG_MODE
    }

    setDebugger(enabled) {
        let localStorage = new LocalStorage();
        let cookieStorage = new CookieStorage();
        cookieStorage.getItem("debug");
        // this.log({ cookieStorage, debug: cookieStorage.getItem("debug") });
        if (enabled) {
            appState.layoutState.infoDebuggerEnabled = true;
            localStorage.setItem('debug', "true");
            cookieStorage.setItem('debug', "true");
        } else {
            localStorage.setItem('debug', "false");
            cookieStorage.setItem('debug', "false");
            appState.layoutState.infoDebuggerEnabled = false;
            appState.queryLoggers = [];
        }
    }

    render() {
        return (
            <div>
                {(window.location.hostname === 'localhost' || util.getCookie('debug') === "true") &&
                <div className="block-info-buttons row">
                    {window.location.hostname === 'localhost' &&
                    <div>
                        Mostrar archivos:
                        <button type="button" onClick={() => this.setEnabled(true)}>ON</button>
                        <button type="button" onClick={() => this.setEnabled(false)}>OFF</button>
                    </div>
                    }
                    <div>
                        Mostrar debugger:
                        <button type="button" onClick={() => this.setDebugger(true)}>ON</button>
                        <button type="button" onClick={() => this.setDebugger(false)}>OFF</button>
                    </div>
                </div>
                }
            </div>
        )

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default InfoBlockSwitch;

