import { observable } from 'mobx';
import AppLogger from "../util/AppLogger";
import TenantModel from "../models/TenantModel";
import OFFER from "../config/OFFER";
import BaseModelState from "./BaseModelState";


class TenantState extends BaseModelState {

    @observable tenants = [];
    @observable tenantsOptions = [];

    async loadTenantsApi() {
        try {
            let tenantModel = new TenantModel();
            let result = await tenantModel.find();
            let tenantsOptions = [];
            for (let obj of result) {
                tenantsOptions.push({ value: obj.tenantId, label: obj.title })
            }
            this.tenantsOptions = tenantsOptions;
            this.tenants = result;
            let offer = new OFFER();
            offer.checkOffers();
        } catch (e) {
            this.log({ loadTenantsApiException: 1, e });
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default TenantState;
