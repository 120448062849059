import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import translate from '../translator/translate'
import { Animated } from 'react-animated-css'
import AppLogger from '../util/AppLogger'
import VsfButtonNew from '../components/VsfButtonNew'
import appState from '../state/AppState'
import Overlay from '../components/modals/Overlay'
import VsfLink from '../components/VsfLink'
import TypifiedValueModel from '../models/TypifiedValueModel'
import util from '../util/Util'
import BudgetModel from '../models/BudgetModel'
import BaseAlertsButtons from './Base/BaseAlertsButtons'

@observer
class InfoButton extends BaseAlertsButtons {
    constructor (props) {
        super(props)
        this.state = { alert: false }
    }

    loadData () {

    }

    getUrlPdtApproved () {
        let idTypified = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'PDT_APPROVED').id
        let url = '/order/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + idTypified + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + appState.typifiedState.getValue(idTypified) + '\"}]'
        return url
    }

    getUrlTechniciansWithoutScheduledToday () {
        let date = util.getMoment().format('YYYYMMDD')
        return '/schedule/bytechnical?withoutTech=' + date + '&date=' + date
    }

    getUrlNotApproved () {
        let idTypified = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'NOT_APPROVED').id
        let url = '/order/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + idTypified + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"' + appState.typifiedState.getValue(idTypified) + '\"}]'
        return url
    }

    getUrlBudgetPending () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_BORRADOR + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Pendiente de aprobar\"}]'
        return url
    }

    getUrlBudgetPendingOrder () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_APPROVED + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Presupuestos aprobados\"}]'
        return url
    }

    getUrlBudgetNotApprovedOrder () {
        let url = '/budget/ls/?filters='
        url += '[{\"fieldKey\":\"status\",\"fieldName\":\"status\",\"fieldValue\":\"' + BudgetModel.CODE_NOT_APPROVED + '\",\"filterOperator\":\"EQ\",\"fieldLabel\":\"Presupuestos rechazados\"}]'
        return url
    }

    getUrlValidations () {
        return '/config/validates'
    }

    getUrlTechsWithoutEndDay () {
        return '/userWithoutEndDay/ls/'
    }

    getWorkOrdersReturnedFollowed () {
        let url = '/workOrder/ls?filters='
        let statusId = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, 'END_WORK_ORDER').id
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${statusId}","filterOperator":"NEQ","fieldLabel":"Sin Finalizar"}, {"fieldKey":"hasReturned","fieldName":"hasReturned","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Devuelta"}]`
        return url
    }

    getWorkOrdersWithPossibleWarranty () {
        let url = '/workOrder/ls?filters='
        url += `[{"fieldKey":"warrantyPossible","fieldName":"warrantyPossible","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Sí"}, {"fieldKey":"warrantyRevised","fieldName":"warrantyRevised","fieldValue":"N","filterOperator":"EQ","fieldLabel":"No"}]`
        return url
    }

    getBudgetsAccepted () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_APPROVED
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Aceptado"}]`
        return url
    }

    getBudgetsPending () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_PENDING_ERP
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Pendiente ERP"}]`
        return url
    }

    getBudgetsDenied () {
        let url = '/budget/ls?filters='
        let status = BudgetModel.CODE_NOT_APPROVED
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Denegado"}]`
        return url
    }

    render () {
        let totalAlarms = appState.alertState.budgetsDenied
        totalAlarms += appState.alertState.budgetsPending
        totalAlarms += appState.alertState.budgetsAccepted
        totalAlarms += appState.alertState.notApproved
        totalAlarms += appState.alertState.budgetPending
        totalAlarms += appState.alertState.orderBudgetPending
        totalAlarms += appState.alertState.orderBudgetNotApproved
        totalAlarms += appState.alertState.validations
        totalAlarms += appState.alertState.techniciansWithoutScheduledToday
        totalAlarms += appState.alertState.techsWithoutEndDay
        const t = translate
        return (
            <div className="header-alerts dropdown"
                style={{
                    marginRight: '0',
                    padding: '12px 7px 12px 7px'
                }}>

                <VsfButtonNew type="button" className="alerts-dropdown btn info--alert btn--alert text-info"
                    icon={'fa fa-info-circle'}
                    onClick={() => this.setState({ alert: true })}>
                    {/*<i className="badge badge-info">{totalAlarms}</i>*/}
                </VsfButtonNew>

                {this.state.alert &&
                <Animated style={{
                    zIndex: 100,
                    position: 'absolute',
                    top: 10,
                    left: 0,
                    right: 0
                }}
                animationInDelay={150} animationIn="zoomIn" animationInDuration={250}
                isVisible={true}>
                    <Overlay show={this.state.alert} style={{ backgroundColor: 'transparent' }}
                        onClick={_ => this.setState({ alert: false })} />
                    <dl className="dropdown-sublist" style={{
                        top: 10,
                        right: 22,
                        width: 420
                    }}>

                        {totalAlarms === 0 ?
                            <dt className="dropdown-sublist__head">
                                {t('No tienes notificaciones pendientes')}
                            </dt>
                            :
                            <>
                                <dt className="dropdown-sublist__head">
                                    {totalAlarms === 1 ?
                                        t('Tienes #total# notificacion').replace('#total#', totalAlarms)
                                        : t('Tienes #total# notificaciones').replace('#total#', totalAlarms)}
                                </dt>
                                {appState.alertState.budgetsDenied !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getBudgetsDenied()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.budgetsDenied}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Presupuestos Denegados')}</div>

                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.budgetsPending !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getBudgetsPending()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.budgetsPending}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Presupuestos Pendientes')}</div>

                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.budgetsAccepted !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getBudgetsAccepted()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.budgetsAccepted}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Presupuestos Aceptados')}</div>

                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.notApproved !== 0 &&
                                <dd className="dropdown-sublist__item" style={{
                                    marginTop: 10,
                                    width: 220
                                }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlNotApproved()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.notApproved}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Pedidos no aprobados')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.budgetPending !== 0 &&
                                <dd className="dropdown-sublist__item" style={{
                                    marginTop: 10,
                                    width: 220
                                }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlBudgetPending()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.budgetPending}</i>
                                        <div
                                            style={{ marginLeft: 25 }}>{t('Presupuestos pendientes')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.orderBudgetPending !== 0 &&
                                <dd className="dropdown-sublist__item" style={{
                                    marginTop: 10,
                                    width: 220
                                }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlBudgetPendingOrder()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.orderBudgetPending}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Presupuestos aprobados')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.orderBudgetNotApproved !== 0 &&
                                <dd className="dropdown-sublist__item" style={{
                                    marginTop: 10,
                                    width: 220
                                }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlBudgetNotApprovedOrder()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.orderBudgetNotApproved}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Presupuestos rechazados')}</div>
                                    </VsfLink>
                                </dd>
                                }


                                {appState.alertState.validations !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlValidations()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.validations}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Validaciones pendientes')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.techniciansWithoutScheduledToday !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlTechniciansWithoutScheduledToday()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.techniciansWithoutScheduledToday}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Técnicos sin planificación')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.techsWithoutEndDay !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlTechsWithoutEndDay()}>
                                        <i className="badge badge-info"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.techsWithoutEndDay}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Técnicos sin finalizar jornada')}</div>
                                    </VsfLink>
                                </dd>
                                }
                            </>
                        }
                    </dl>
                </Animated>

                }
            </div>
        )

    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default withRouter(InfoButton)
