import React from 'react';
import {observer} from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed} from 'mobx';
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import VsfButtonNew from "../../components/VsfButtonNew";
import GroupOfField from "../../components/layout/GroupOfField";
import PropsUtil from "../../util/PropsUtil";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import OrderModel from "../../models/OrderModel";
import translate from "../../translator/translate";
import {withRouter} from "react-router-dom";
import MoreButtons from "../../components/MoreButtons";
import BudgetModel from "../../models/BudgetModel";
import OrderFormBudgetRow from "./OrderFormBudgetRow";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import OrderActions from "./OrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class OrderFormBudgetTable extends BaseEditableTable {


    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            budget: null,
            hasSomeRowEditing: false,
            editable: false, loading: true,
            noWorkOrders: false,
            orderFinished: false,
            orderCanceled: false,
        }
    }

    @computed get mobxListado() {
        return appState.budgetState.budget;
    }

    setMobxListado(newValue) {
        appState.budgetState.budget = newValue;
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.workOrderCartArticle");
        result.addRelatedTable("client");
        result.addRelatedTable("slot");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    getModelTable() {
        return new BudgetModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.configState.configGetDict();
        await this.reloadTable();
        this.setState({ loading: false })
    }

    async reloadTable() {
        if (util.hasValue(this.graphDataMainType.id)) {
            appState.budgetState.budget = this.graphDataMainType.budget;
            appState.assetState.totalSlots = this.graphDataMainType.slot.length;
            appState.workOrderState.workOrders = this.graphDataMainType.workOrder;
            // this.log({ graph: this.graphDataMainType, appState: appState.budgetState.budget, mobx: this.mobxListado })
        }
    }

    render() {
        return this.renderImpl();
    }


    renderImpl() {
        const t = translate;

        let order = this.graphDataMainType;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                                  getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active="Presupuestos"
                                numAlarm={3}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                              getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">

                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active={"Presupuestos"}
                            />
                            }
                            <GroupOfField
                                title={t('Otros')}
                                subtitle={t('Presupuestos')}
                                icon='fas fa-calendar'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ningun presupuesto creado')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <th scope="col">{t("Num. Presupuesto")}</th>
                                            <th scope="col">{t("Orden de trabajo")}</th>
                                            <th scope="col">{t("Estado")}</th>
                                            <th scope="col">{t("Comentarios")}</th>
                                            {/*<th scope="col">{t('T.Presupuestado Total')}</th>*/}
                                            <th scope="col">{t("Cantidad")}</th>
                                            <th scope="col">{t("Precio Total con Impuestos")}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">
                                        {this.mobxListado.map((budget, index) => {
                                                if (util.hasValue(budget.id)) {
                                                    return (
                                                        <OrderFormBudgetRow
                                                            key={budget && budget.id}
                                                            budgetId={budget.id}
                                                            disabled={this.state.editable}
                                                            rowIndex={index}
                                                            row={budget} fromOrder={true}
                                                            workOrders={appState.workOrderState.workOrders}
                                                            hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                            setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                            setEditable={(budget) => this.setEditable(budget)}
                                                            {...this.props}
                                                        />)
                                                }
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                    <div className="text-right mb-3">
                                        <VsfButtonNew
                                            disabled={this.state.editable}
                                            label={t("Nuevo Presupuesto")}
                                            showButton={this.creatable["budget"] || false}
                                            isEditing={this.state.editable}
                                            className="btn btn--loader"
                                            onClick={() => this.onClickNewBudget(order)}
                                        />
                                    </div>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
                <AlertModal isOpen={this.state.orderFinished}
                            onCloseModal={() => this.setState({ "orderFinished": false })}>
                    <PopUp
                        title={t('Operación no permitida')}
                        icon={"exclamationTriangleIcon"}
                        text={t(`Este Pedido está finalizado, para poder añadir una visita
                                                 tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                        label1={'Cerrar'}
                        onClick1={() => this.setState({ orderFinished: false })}
                    />
                </AlertModal>
                <AlertModal isOpen={this.state.orderCanceled}
                            onCloseModal={() => this.setState({ "orderCanceled": false })}>
                    <PopUp
                        title={t('Operación no permitida')}
                        icon={"exclamationTriangleIcon"}
                        text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                        label1={'Cerrar'}
                        onClick1={() => this.setState({ orderCanceled: false })}
                    />
                </AlertModal>
                <AlertModal isOpen={this.state.noWorkOrders}
                            onCloseModal={() => this.setState({ "noWorkOrders": false })}>
                    <PopUp
                        title={t('Operación no permitida')}
                        icon={"exclamationTriangleIcon"}
                        text={t(`Necesitas al menos una Orden de Trabajo para crear un presupuesto.`)}
                        label1={'Cerrar'}
                        onClick1={() => this.setState({ noWorkOrders: false })}
                    />
                </AlertModal>
            </InfoBlock>
        )
            ;
    }

    onClickNewBudget(order) {
        if (this.graphDataMainType.workOrder?.length === 0) {
            this.setState({
                noWorkOrders: true
            })
        } else if (appState.typifiedState.getObject(order.status)?.code === "CLOSED") {
            this.setState({ orderFinished: true });
        } else if (appState.typifiedState.getObject(order.status)?.code === "CANCELED") {
            this.setState({ orderCanceled: true });
        } else {
            this.propsUtil.changeUrlRequest({
                "rightModal": "modalbudgetls",
                orderId: this.graphDataMainType.id,
                clientId: this.graphDataMainType.clientId,
                "rightModalTab": "budgetForm",
            });
        }
    }


    setEditable(budget) {
        this.propsUtil.changeUrlRequest({
            "rightModal": "modalbudgetls",
            "budgetId": budget.id,
            clientId: this.graphDataMainType.clientId,
            "orderId": this.graphDataMainType.id,
            "rightModalTab": "budgetForm"
        });

    }


}

export default withRouter(OrderFormBudgetTable)
