import React from 'react'
import { observer } from 'mobx-react'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import appState from '../../state/AppState'
import BaseForm from '../base/BaseForm'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import Select2Component from '../../components/fields/Select2Component'
import MoreButtons from '../../components/MoreButtons'
import PropsUtil from '../../util/PropsUtil'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import AssetModel from '../../models/AssetModel'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import AutoSuggestComponent from '../../components/fields/AutoSuggestComponent'
import AssetModelModel from '../../models/AssetModelModel'
import FormButtons from '../../components/FormButtons'
import { withRouter } from 'react-router-dom'
import { observable, toJS } from 'mobx'
import VsfButtonNew from '../../components/VsfButtonNew'
import ClientModel from '../../models/ClientModel'
import GqlErrors from '../../components/status/GqlErrors'
import GraphException from '../../network/GraphException'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import CreateAndModify from '../../components/fields/CreateAndModify'
import GqlWarnings from '../../components/status/GqlWarnings'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import UploadFileComponent from '../../components/UploadFileComponent'
import DocumentModel from '../../models/DocumentModel'
import TextAreaComponent from '../../components/fields/TextAreaComponent'
import MapView from '../../components/modals/MapView'
import AssetActions from './AssetActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import ValidatesFormTable from '../../components/ValidatesFormTable'
import YesOrNoEnum from '../../models/enum/YesOrNoEnum'
import SwitchComponent from '../../components/fields/SwitchComponent'
import translate from '../../translator/translate'
import OFFER from '../../config/OFFER'

@observer
class AssetForm extends BaseForm {

    @observable client = {}
    @observable lastPlateSuggest = {}

    constructor (props) {
        super(props)
        this.nameMainType = 'asset'
        this.initializeGraphData(this.nameMainType)
        this.tabsData = new TabsData()
        appState.changesWithValidationsState.formValidations = false
        appState.changesWithValidationsState.imageValidations = false
        appState.changesWithValidationsState.validatesForm = []
        this.state = {
            errorFormulario: false,
            copyAsset: {},
            duplicateAsset: false,
            modalDynamics: false,
            assetModelFound: true,
            assetModelLoading: false,
            loading: true
        }
    }

    async componentDidMountImpl () {
        await super.componentDidMountImpl()
        await appState.clientCacheState.loadClientCacheClients()
        await appState.stockState.getDistintStocks()
        await appState.assetModelState.getAssetModels()

        await this.loadContractDocument()
        await this.loadFinalArtDocument()
        if (!util.hasValue(this.graphDataMainType.id)) {
            await appState.configState.configGetDict()
        }
        this.setState({ loading: false })
    }

    async loadContractDocument () {
        if (util.hasValue(this.graphDataMainType.id)) {
            let documentQuery = new DocumentModel()
            documentQuery.modelId = this.graphDataMainType.id
            documentQuery.modelName = this.getModelQuery().getNameModelInDotNetTables()
            documentQuery.field = 'contract'
            let document = await documentQuery.findFirst()
            if (document != null) {
                this.graphDataMainType.contractDocuments = [document?.toPlainObject()]
            } else {
                this.graphDataMainType.contractDocuments = []
            }
        }
    }

    async loadFinalArtDocument () {
        if (util.hasValue(this.graphDataMainType.id)) {
            let documentQuery = new DocumentModel()
            documentQuery.modelId = this.graphDataMainType.id
            documentQuery.modelName = this.getModelQuery().getNameModelInDotNetTables()
            documentQuery.field = 'finalArt'
            let document = await documentQuery.findFirst()
            if (document != null) {
                this.graphDataMainType.finalArtDocuments = [document?.toPlainObject()]
            } else {
                this.graphDataMainType.finalArtDocuments = []
            }
        }
    }

    async componentWillUpdate () {
        // Cargamos el asset correspondiente para cargar adecuadamente los selects jerarquicos
        let asset = this.graphDataMainType
        appState.typifiedState.assetTypeSelected = asset.type
        if (asset.storeId == null && !this.props.fromRightModal && this.getFormId() == null) {
            asset.storeId = util.hasValue(this.propsUtil.getRequest('storeId')) ? this.propsUtil.getRequest('storeId')
                : asset.storeId
            await appState.clientState.reloadClientFromClientId(asset.storeId)
            this.graphDataMainType.store = appState.clientState.client
            asset.propietary = util.hasValue(this.propsUtil.getRequest('propietary'))
                ? this.propsUtil.getRequest('propietary') : asset.propietary
        }
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave (model, previousId) {
        //Actualizo el array de workOrders que se visualizan
        await this.actualizaCamposEquipo(model, previousId)
        this.validatesFormRef.loadTable()
    }

    async actualizaCamposEquipo (model, previousId) {
        if (util.hasValue(previousId)) {
            for (let i = 0; i < appState.assetState.assets.length; i++) {
                if (appState.assetState.assets[i].id == model.id) {
                    appState.assetState.assets[i] = model.toPlainObject()
                }
            }
        }
    }

    getModelQuery () {
        let result = new AssetModel()
        result.addRelatedTable('documents')
        return result
    }

    getModelQueryForView () {
        let result = new AssetModel()
        result.addRelatedTable('assetModel')
        result.addRelatedTable('store')
        result.addRelatedTable('documents')
        return result
    }

    urlForFormOrderCCFromAsset (asset) {
        let url = 'assetId=' + asset.id
        if (util.hasValue(asset.storeId)) {
            url += '&storeId=' + asset.storeId
        }
        url += '&origin=' + appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_ORIGIN, 'CCEP').id
        return '/order/form/?' + url
    }

    async getAssetSugestions (value) {
        let assetModelsArray = []
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            // Obtenemos los Assets
            if (util.hasValue(value)) {
                let assetQuery = new AssetModel()
                assetQuery.filters = [
                    {
                        'fieldName': 'plate',
                        'fieldValue': value,
                        'filterOperator': 'SUBSTR'
                    },
                ]
                let assetModels = await assetQuery.find()
                // Transformamos a formato Autosuggest
                assetModels.map((assetModel) => {
                    let assetModelObject = {
                        label: assetModel.value,
                        value: assetModel.id
                    }
                    assetModelsArray.push(assetModelObject)
                }
                )
            }
        } catch
        (e) {
            this.log('Error => ' + e)
        }
        return assetModelsArray
    }

    async getAssetModelSugestionsFromModel (manufacturer, value) {
        let assetModelsArray = []
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            // Obtenemos los Assets
            if (util.hasValue(manufacturer) && util.hasValue(value)) {
                let assetModelQuery = new AssetModelModel()
                assetModelQuery.manufacturer = manufacturer
                assetModelQuery.filters = [
                    {
                        'fieldName': 'model',
                        'fieldValue': value,
                        'filterOperator': 'SUBSTR'
                    },
                ]
                let assetModels = await assetModelQuery.find()
                appState.assetModelState.assetModels = assetModels
                // Transformamos a formato Autosuggest
                assetModels.map((assetModel) => {
                    let assetModelObject = {
                        label: assetModel.model,
                        value: assetModel.model
                    }
                    assetModelsArray.push(assetModelObject)
                }
                )
            }
        } catch
        (e) {
            this.log('Error => ' + e)
        }
        return assetModelsArray
    }

    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @returns Array
     */
    async getClientsSugestions (value) {
        let clientArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            let clientQuery = new ClientModel()
            clientQuery.isPos = true
            clientQuery.filters = [
                {
                    'fieldName': 'name',
                    'fieldValue': value,
                    'filterOperator': 'SUBSTR'
                },
            ]
            let client = await clientQuery.find()
            client.map((client) => {
                let clientObject = {}
                clientObject.label = client.name
                clientObject.value = client
                clientArray.push(clientObject)
            })
        } catch (e) {
            this.log('Error => ' + e)
        }
        return clientArray
    }

    async getAssetModelSugestions (value) {
        let assetModelsArray = []
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            // Obtenemos los Assefts
            let assetModelQuery = new AssetModelModel()
            assetModelQuery.filters = [
                {
                    'fieldName': 'manufacturer',
                    'fieldValue': value,
                    'filterOperator': 'SUBSTR'
                },
            ]
            let assetModels = await assetModelQuery.find()
            // Transformamos a formato Autosuggest
            let assetModelObject = {}
            assetModels.map((assetModel) => {
                if (assetModelObject[assetModel.manufacturer] == null) {
                    assetModelObject[assetModel.manufacturer] = {
                        label: assetModel.manufacturer,
                        value: assetModel.manufacturer
                    }
                    assetModelsArray.push(assetModelObject[assetModel.manufacturer])
                }
            })
        } catch (e) {
            this.log('Error => ' + e)
        }
        return assetModelsArray
    }

    checkButtonCreated (manufacturer, model) {
        let result = false
        if (util.hasValue(manufacturer) && util.hasValue(model)) {
            for (let assetModel of appState.assetModelState.assetModels) {
                if (assetModel.manufacturer === manufacturer && assetModel.model === model) {
                    result = true
                }
            }
        }
        if (!util.hasValue(manufacturer) || !util.hasValue(model)) {
            result = true
        }
        this.setState({
            assetModelFound: result
        })
    }

    getZoneFromAppState (storeId) {
        let result = ''
        if (util.hasValue(storeId)) {
            let client = this.graphDataMainType.store
            result = client && appState.typifiedState.getObject(client.posZoneId) && appState.typifiedState.getObject(client.posZoneId).value
        }
        return result

    }

    async saveAssetModel (manufacturer, model) {
        try {
            this.setState({
                assetModelLoading: true,
            })
            let assetModelQuery = new AssetModelModel()
            assetModelQuery.model = model
            assetModelQuery.manufacturer = manufacturer
            await assetModelQuery.persist()
            this.setState({
                assetModelLoading: false,
                assetModelFound: true,
            })
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e)
            this.gqlErrors = errors
            this.setState({
                assetModelLoading: false,
                assetModelFound: false,
            })
        }
    }

    /**
     * Metodo que antes de llamar a updateInputEvent al seleccionar un assetType, actualiza los selects adecuadamente
     *
     * @param e
     */
    updateInputEventAssetType (e) {
        // Borramos la workOrderType2, ya que al cambiar de workOrderType, las workOrderType2 se recargan
        this.updateInput('subType', '')
        // Actualizamos en el typifiedState la variable asociada a la workOrderType por la cual se filtran las workOrderType2
        appState.typifiedState.assetTypeSelected = e.target.value
        // Ahora si, actualizamos la workOrderType
        this.updateInputEvent(e)
    }

    onChangeInputYesOrNo (event) {
        let isOn = event.target.value
        let name = event.target.name
        let value = YesOrNoEnum.NO
        if (isOn) {
            value = YesOrNoEnum.YES
        }
        this.updateInput(name, value)
    }

    renderImpl () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let modalClasses = util.getModalClasses()
        let id = this.getFormId() || this.props.id
        let asset = this.graphDataMainType
        if (asset == null) {
            asset = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus()
        if (this.graphStatus.mutationError > 0) {
            this.state.errorFormulario = true
        }
        let ubicacion = asset.store?.posAddress || ''
        let zona = this.getZoneFromAppState(asset.storeId) || ''
        let relatedInfo = ''
        if (util.hasValue(ubicacion)) {
            relatedInfo = ubicacion
        }
        if (zona) {
            relatedInfo += ' - ' + ubicacion
        }
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject( this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject( this.graphDataMainType.subType).config);
        } else if (util.hasValue(appState.typifiedState.getObject( this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject( this.graphDataMainType.type).config)
        }

        let user = appState.loginState.user

        return (
            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ''}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={'edit'}
                                onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={'Datos Generales'}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={' '}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.graphDataMainType.id &&
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal}
                                    setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card">
                        <div className={modalClasses.body + ' mb-3'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={'Datos Generales'}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={' '}
                            />
                            }
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking} />
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <SpinnerClipLoaderInPage loading={this.state.loading}>
                                    <ValidatesFormTable
                                        onRef={ref => (this.validatesFormRef = ref)}
                                        model={this.getModelQuery()}
                                        onChangeInput={(name, value, relation) => this.onChangeInput(name, value, relation)}
                                        graphDataMainType={this.graphDataMainType}
                                        previousRowForDiscard={this.previousRowForDiscard} />
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                    <GqlWarnings warnings={this.gqlWarnings}
                                        onSaveWarning={() => this.saveSlotWarning()}
                                        key={this.gqlWarnings}
                                        setWarnings={(warnings) => this.cancelSlotWarning(warnings)} />

                                    <div className={'row'}>
                                        <Select2Component
                                            value={asset.status}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'status'}
                                            title={t('Estado')}
                                            editable={this.editable}
                                            classGroup={'col-lg-3'}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ASSET_STATUS)}
                                            errors={errorsMapped.status}
                                        />
                                        <Select2Component
                                            value={asset.warehouse}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'warehouse'}
                                            title={t('Almacén')}
                                            classGroup={'col-lg-3'}
                                            editable={this.editable}
                                            placeholder={t('Almacén')}
                                            options={appState.stockState.labelValuesForSelect}
                                            errors={errorsMapped.warehouse}
                                        />
                                        <Select2Component
                                            value={asset.propietary}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={'propietary'}
                                            title={t('Propietario')}
                                            classGroup={'col-lg-3'}
                                            options={appState.clientCacheState.clientPosCacheForSelect}
                                            errors={errorsMapped.propietary}
                                        />

                                    </div>

                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('Equipo')}
                                        icon="fas fa-file-import">
                                        <CreateAndModify
                                            updatedAt={asset.updatedAt}
                                            updatedBy={asset.updatedBy}
                                            createdBy={asset.createdBy}
                                            createdAt={asset.createdAt}
                                        />
                                        <div className={'col-12'}>
                                            <div className={'row'}>
                                                <InputTypeComponent
                                                    value={asset.plate}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    editable={false}
                                                    readOnly={!util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, 'ADMIN').id, user.role) && util.hasValue(asset.id)}
                                                    name={'plate'}
                                                    title={t('Numero de Matrícula')}
                                                    validate={'alphanumeric|maxLength:20'}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.plate}
                                                />
                                                <InputTypeComponent
                                                    value={asset.tagId}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'tagId'}
                                                    title={t('Matrícula propiedad del cliente')}
                                                    placeholder={t('Matrícula propiedad del cliente')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.tagId}
                                                />
                                                <InputTypeComponent
                                                    value={asset.barCodeId}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'barCodeId'}
                                                    title={t('Código barras')}
                                                    info={t('del ERP')}
                                                    placeholder={t('Código barras')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.barCodeId}
                                                />
                                                <InputTypeComponent
                                                    value={asset.qrCodeId}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'qrCodeId'}
                                                    title={t('QR')}
                                                    placeholder={t('QR')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.qrCodeId}
                                                />
                                                <Select2Component
                                                    value={asset.manufacturer}
                                                    onChange={(e) => {
                                                        this.handleAutosuggestSelection('model', '')
                                                        this.updateInputEvent(e)
                                                    }}
                                                    name={'manufacturer'}
                                                    title={t('Fabricante')}
                                                    classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                                    editable={this.editable}
                                                    options={appState.assetModelState.getOnlyManufacturerForSelect}
                                                    errors={errorsMapped.manufacturer}
                                                />
                                                <AutoSuggestComponent
                                                    value={asset.model}
                                                    onChange={(modelSelected) => {
                                                        this.handleAutosuggestSelection('model', modelSelected)
                                                        this.checkButtonCreated(asset.manufacturer, asset.model)
                                                    }}
                                                    name={'model'}
                                                    key={asset.manufacturer}
                                                    title={t('Modelo')}
                                                    lenghtAutosuggest={1}
                                                    classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                                    editable={this.editable}
                                                    errors={errorsMapped.model}
                                                    loadSuggestions={(value) => this.getAssetModelSugestionsFromModel(asset.manufacturer, value)}
                                                    placeholder={t('Escribe...')}

                                                />
                                                <VsfButtonNew
                                                    label={t('Crear nuevo modelo')}
                                                    onClick={() => this.saveAssetModel(asset.manufacturer, asset.model)}
                                                    className={'btn btn--disabled'}
                                                    withoutFormChanges={true}
                                                    passwordValid={!this.state.assetModelFound}
                                                    loading={this.state.assetModelLoading}
                                                    classGroup={'col-xl-3 d-flex align-items-center '}
                                                    messageOnSuccess={'Modelo Guardado'}
                                                />
                                                <InputTypeComponent
                                                    value={asset.serialNumber}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'serialNumber'}
                                                    title={t('Número de Serie')}
                                                    editable={this.editable}
                                                    placeholder="Número de Serie"
                                                    classGroup={'col-md-5 col-md-5 col-xl-5'}
                                                    type={'text'}
                                                    validate={'maxLength:20'}
                                                    info={t('Fabricante')}
                                                    errors={errorsMapped.serialNumber}
                                                />

                                                <SwitchComponent
                                                    checked={asset.serialNotVisible === YesOrNoEnum.YES}
                                                    value={asset.serialNotVisible}
                                                    title={t('Nº de Serie no visible')}
                                                    onChange={(e) => this.onChangeInputYesOrNo(e)}
                                                    name={'serialNotVisible'}
                                                    classGroup="col-3 c-switch"
                                                />
                                                <div className="col-4" />
                                                <Select2Component
                                                    value={asset.type}
                                                    onChange={(e) => this.updateInputEventAssetType(e)}
                                                    name={'type'}
                                                    title={t('Tipo de equipo')} isClearable={false}
                                                    classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                                    editable={this.editable}
                                                    options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ASSET_TYPE)}
                                                    errors={errorsMapped.type}
                                                />
                                                <Select2Component
                                                    value={asset.subType}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    editable={this.editable}
                                                    name={'subType'}
                                                    title={t('Subtipo de equipo')}
                                                    classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                                    options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_ASSET_SUBTYPE, asset.type)}
                                                    errors={errorsMapped.subType}
                                                />
                                                <AutoSuggestComponent
                                                    value={asset.parentAssetId}
                                                    onChange={(subTypeSelected) => {
                                                        this.handleAutosuggestSelection('parentAssetId', subTypeSelected)
                                                    }}
                                                    name={'parentAssetId'}
                                                    title={t('Sub-activo del activo principal')}
                                                    classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                                    editable={this.editable}
                                                    errors={errorsMapped.parentAssetId}
                                                    loadSuggestions={(value) => this.getAssetSugestions(value)}
                                                    placeholder={t('Escribe...')}

                                                />
                                                <InputTypeComponent
                                                    value={asset.userPlate}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    editable={this.editable}
                                                    name={'userPlate'}
                                                    title={t('Código de artículo')}
                                                    placeholder={t('Código de artículo')}
                                                    info={t('del sub-activo')}
                                                    validate={'alphanumeric|maxLength:20'}
                                                    classGroup={'col-md-6 col-lg-6 col-xl-6'}
                                                    errors={errorsMapped.userPlate}
                                                />
                                                <InputTypeComponent
                                                    value={asset.connectivitySN}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    editable={this.editable}
                                                    name={'connectivitySN'}
                                                    title={t('FDE Connectivity SN')}
                                                    placeholder={t('FDE Connectivity SN')}
                                                    info={t('del sub-activo')}
                                                    validate={'alphanumeric|maxLength:20'}
                                                    classGroup={'col-md-6 col-lg-6 col-xl-6'}
                                                    errors={errorsMapped.connectivitySN}
                                                />

                                            </div>
                                        </div>
                                    </GroupOfField>

                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('Ubicación')}
                                        icon="fas fa-map-marker-alt">
                                        <div className={'col-12'}>
                                            <div className={'row'}>
                                                <SelectSuggestComponent
                                                    value={asset.store?.name}
                                                    onChange={(event) => {
                                                        let clientSelected = event.target.value
                                                        if (util.hasValue(clientSelected.id)) {
                                                            this.handleAutosuggestSelection('storeId', clientSelected.id)
                                                            asset.store = clientSelected
                                                        } else {
                                                            this.handleAutosuggestSelection('storeId', '')
                                                            asset.store = {}
                                                        }
                                                    }}
                                                    name={'clientId'}
                                                    key={this.changeKey}
                                                    title={t('Establecimiento')}
                                                    classGroup={'col-md-9 col-lg-9 col-xl-9'}
                                                    changeSelect={this.reloadSelect}
                                                    editable={this.editable}
                                                    loadSuggestions={(value, firstCall) => this.getClientsSugestions(value, firstCall)}
                                                    placeholder={t('Escribe...')}
                                                    relatedInfo={relatedInfo}
                                                />
                                                <div className="button-form-group">
                                                    {util.hasValue(asset.storeId) ?
                                                        <span className="link-underline ico ico--edit"
                                                            onClick={() => this.onEditarCliente(asset)}
                                                        >{t('Editar Datos de Cliente')}</span>
                                                        :
                                                        <span className="link-underline prohibido ico ico--edit"
                                                        >{t('Editar Datos de Cliente')}</span>
                                                    }
                                                </div>
                                                <InputTypeComponent
                                                    value={asset.posAddress}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'posAddress'}
                                                    title={t('Dirección del equipo')}
                                                    editable={this.editable}
                                                    classGroup={'col-6'}
                                                    type={'text'}
                                                    info={t('Rellenar sólo si el equipo tiene una dirección diferente a la dirección del establecimiento')}
                                                    errors={errorsMapped.posAddress}
                                                />
                                                <InputTypeComponent
                                                    value={asset.location}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'location'}
                                                    title={t('Emplazamiento')}
                                                    editable={this.editable}
                                                    classGroup={'col-6'}
                                                    type={'text'}
                                                    info={t('Lugar donde se encuentra el equipo dentro de las instalaciones del Cliente')}
                                                    errors={errorsMapped.comments}
                                                />
                                                <InputTypeComponent
                                                    value={asset.geoLatitude}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'geoLatitude'}
                                                    editable={this.editable} required
                                                    title={t('Latitud')}
                                                    placeholder={t('Latitud')}
                                                    dataCy={'latitudEstablecimiento'}
                                                    classGroup={'col-md-4'}
                                                    type={'text'}
                                                    instanceOf={'form-control'}
                                                />
                                                <InputTypeComponent
                                                    value={asset.geoLongitude}
                                                    onChange={(e) => this.updateInputEvent(e)} required
                                                    name={'geoLongitude'}
                                                    title={t('Longitud')}
                                                    dataCy={'longitudEstablecimiento'}
                                                    editable={this.editable}
                                                    placeholder={t('Longitud')}
                                                    classGroup={'col-md-4'}
                                                    type={'text'}
                                                    instanceOf={'form-control'}
                                                />
                                                <VsfButtonNew
                                                    onClick={() => this.setState({
                                                        modalMap: true,
                                                        asset
                                                    })}
                                                    label={t('Ver en mapa')}
                                                    icon={'fas fa-map-marked-alt'}
                                                    className={'btn btn--loader btn--auto'}
                                                    classGroup={'col-md-3 d-flex align-items-center'}
                                                />
                                                <div className="col-12 d-flex align-items-center">
                                                    <img height={25} width={25}
                                                        src="/img/fileicons/not_listed_location-24px.svg"
                                                        alt="ayuda_ubicación" />
                                                    <span onClick={() => this.setState({ modalGps: true })} style={{
                                                        color: '#B3B3B3',
                                                        cursor: 'pointer'
                                                    }}>{t('¿Cómo añadir la geolocalización?')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </GroupOfField>

                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('Compras')}
                                        icon="fas fa-file-import">
                                        <InputTypeComponent
                                            value={asset.weeePrice}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'weeePrice'}
                                            title={t('WEEE')}
                                            placeholder={t('Coste')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.weeePrice}
                                        />
                                        <InputTypeComponent
                                            value={asset.pcd}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'pcd'}
                                            title={t('PCD')}
                                            placeholder={t('PCD')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.pcd}
                                        />
                                        <InputTypeComponent
                                            value={asset.supplier}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'supplier'}
                                            title={t('Proveedor')}
                                            placeholder={t('Proveedor')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.supplier}
                                        />
                                        <InputTypeComponent
                                            value={asset.supplierRef}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'supplierRef'}
                                            title={t('Referencia del proveedor')}
                                            placeholder={t('Referencia del proveedor')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.supplierRef}
                                        />
                                        <InputTypeComponent
                                            value={asset.clientOrderNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'clientOrderNumber'}
                                            title={t('Número de pedido cliente')}
                                            placeholder={t('Número de pedido cliente')}
                                            classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                            errors={errorsMapped.clientOrderNumber}
                                        />
                                        <InputTypeComponent
                                            value={asset.orderNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'orderNumber'}
                                            title={t('Número de pedido interno')}
                                            placeholder={t('Número de pedido interno')}
                                            classGroup={'col-md-4 col-lg-4 col-xl-4'}
                                            errors={errorsMapped.orderNumber}
                                        />
                                        <InputTypeComponent
                                            value={asset.salePrice}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'salePrice'}
                                            title={t('Precio de venta')}
                                            placeholder={t('Precio de venta')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.salePrice}
                                        />
                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            editable={this.editable}
                                            value={asset.purchaseDate}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'purchaseDate'}
                                            title={t('Fecha de compra')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            type={'text'}
                                            errors={errorsMapped.purchaseDate}
                                            styleCustomProps={true}
                                            allowEmptyDate={true}
                                        />
                                        <InputTypeComponent
                                            value={asset.purchasePrice}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'purchasePrice'}
                                            title={t('Coste de compra')}
                                            placeholder={t('Coste de compra')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.purchasePrice}
                                        />


                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            editable={this.editable}
                                            value={asset.productionDate}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'productionDate'}
                                            title={t('Fecha de fabricación')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            type={'text'}
                                            errors={errorsMapped.productionDate}
                                            styleCustomProps={true}
                                            allowEmptyDate={true}
                                        />

                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            editable={this.editable}
                                            value={asset.deliveryDate}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'deliveryDate'}
                                            title={t('Fecha de entrega')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            type={'text'}
                                            errors={errorsMapped.deliveryDate}
                                            styleCustomProps={true}
                                            allowEmptyDate={true}
                                        />

                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            editable={this.editable}
                                            value={asset.installationDate}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'installationDate'}
                                            title={t('Fecha de instalación')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            type={'text'}
                                            errors={errorsMapped.installationDate}
                                            styleCustomProps={true}
                                            allowEmptyDate={true}
                                        />

                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            editable={this.editable}
                                            value={asset.retirementDate}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'retirementDate'}
                                            title={t('Fecha de baja')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            type={'text'}
                                            errors={errorsMapped.retirementDate}
                                            styleCustomProps={true}
                                            allowEmptyDate={true}
                                        />

                                    </GroupOfField>

                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('Contrato')}
                                        icon="fas fa-file-import">
                                        <div className={'col-12'}>
                                            <div className={'row'}>
                                                <InputTypeComponent
                                                    value={asset.contractNumber}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'contractNumber'}
                                                    title={t('Número de contrato')}
                                                    placeholder={t('Número de contrato')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.contractNumber}
                                                />
                                                <InputTypeComponent
                                                    value={asset.contractPropietary}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'contractPropietary'}
                                                    title={t('Propietario contrato')}
                                                    placeholder={t('Propietario contrato')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.contractPropietary}
                                                />
                                                <InputTypeComponent
                                                    value={asset.propertyRegime}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'propertyRegime'}
                                                    title={t('Régimen de propiedad')}
                                                    placeholder={t('Régimen de propiedad')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.propertyRegime}
                                                />
                                                <InputTypeComponent
                                                    value={asset.managerMaintenance}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'managerMaintenance'}
                                                    title={t('Gestor Mantenimiento')}
                                                    placeholder={t('Gestor Mantenimiento')}
                                                    classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                                    errors={errorsMapped.managerMaintenance}
                                                />


                                                <TextAreaComponent
                                                    value={asset.comments}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'comments'}
                                                    title={t('Notas')}
                                                    classGroup={'col-md-6col-lg-6 col-xl-6'}
                                                    errors={errorsMapped.comments}
                                                />

                                            </div>
                                            <UploadFileComponent
                                                required={true}
                                                name={'uploadFiles'}
                                                configForm={true}
                                                optionsSelect={[{
                                                    label: 'Contract',
                                                    value: 'contractDocument'
                                                }]}
                                                onChangePrevious={() => this.onChangePrevious()}
                                                onChange={(e) => this.onChangeContract(e)}
                                                documents={asset?.contractDocuments}
                                                baseFolder={this.nameMainType + '/' + asset.id + '/documents'}
                                            />

                                        </div>
                                    </GroupOfField>
                                    {OFFER.conectividad &&
                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('De conectividad')}
                                        icon="fas fa-file-import">
                                        <InputTypeComponent
                                            value={asset.connectivyTrigger}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyTrigger'}
                                            title={t('Activador')}
                                            placeholder={t('Activador')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivyTrigger}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivyTriggerId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyTriggerId'}
                                            title={t('ID Activador')}
                                            placeholder={t('ID Activador')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivyTriggerId}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivySerialNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivySerialNumber'}
                                            title={t('Serial Number')}
                                            placeholder={t('Serial Number')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivySerialNumber}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivyModel}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyModel'}
                                            title={t('Modelo')}
                                            placeholder={t('Modelo')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivyModel}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivyFirmware}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyFirmware'}
                                            title={t('Firmware')}
                                            placeholder={t('Firmware')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivyFirmware}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivy}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivy'}
                                            title={t('Conectividad')}
                                            placeholder={t('Conectividad')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivy}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivySimCard}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivySimCard'}
                                            title={t('SIM Card')}
                                            placeholder={t('SIM Card')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivySimCard}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivyPlatform}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyPlatform'}
                                            title={t('Plataforma')}
                                            placeholder={t('Plataforma')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.connectivyPlatform}
                                        />
                                        <InputTypeComponent
                                            value={asset.connectivyAlerts}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'connectivyAlerts'}
                                            title={t('Alertas')}
                                            placeholder={t('Alertas')}
                                            classGroup={'col-12'}
                                            errors={errorsMapped.connectivyAlerts}
                                        />
                                    </GroupOfField>
                                    }
                                    <GroupOfField title={t('Datos')}
                                        subtitle={t('Decoraciones')}
                                        icon="fas fa-file-import">
                                        <div className={'col-12'}>
                                            <div className={'row'}>
                                                <InputTypeComponent
                                                    value={asset.brand}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={'brand'}
                                                    title={t('Marca')}
                                                    placeholder={t('Marca')}
                                                    classGroup={'col-md-6 col-lg-6 col-xl-6'}
                                                    errors={errorsMapped.brand}
                                                />
                                            </div>

                                            <UploadFileComponent
                                                required={true}
                                                name={'uploadFiles'}
                                                configForm={true}
                                                optionsSelect={[{
                                                    label: 'Final Art',
                                                    value: 'FinalArt'
                                                }]}
                                                onChangePrevious={() => this.onChangePrevious()}
                                                onChange={(e) => this.onChangeDecoration(e)}
                                                documents={asset?.finalArtDocuments}
                                                baseFolder={this.nameMainType + '/' + asset.id + '/decorations'}
                                            />
                                        </div>
                                    </GroupOfField>
                                    {this.props.fromRightModal ?
                                        <>
                                            <div className="fixed-bottom-space-maker" />
                                            <div className="fixed-bottom">
                                                <FormButtons id={id} formStatus={this.graphStatus} />
                                            </div>
                                        </>
                                        :
                                        <FormButtons id={id} formStatus={this.graphStatus} />
                                    }
                                </SpinnerClipLoaderInPage>
                            </form>
                        </div>
                    </div>

                    {this.state.modalMap &&
                    <MapView
                        title={t('Localización del equipo')}
                        clientModel={this.state.asset} editable={this.editable}
                        updateInputEvent={(e) => this.updateInputEvent(e)}
                        openModal={this.state.modalMap}
                        updateClient={true}
                        {...this.props}
                        closeModal={() => this.setState({ modalMap: false })}
                    />
                    }
                </div>
            </InfoBlock>

        )
    }

    onChangePrevious () {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType)
        }
    }

    @observable changeKey = ''

    onChangeInput (name, value, relation) {
        if (name === 'storeId') {
            if (util.hasValue(relation?.id)) {
                this.changeKey = value
                this.graphDataMainType.store = relation
            }
        }
        this.updateInput(name, value)
    }

    async onChangeDecoration (documentAdd) {
        let documentModel = new DocumentModel()
        documentModel.hidrate(documentAdd)
        documentModel.field = 'finalArt'
        documentModel.modelName = this.getModelQuery().getNameModelInDotNetTables()
        documentModel.modelId = this.graphDataMainType.id
        this.graphDataMainType.finalArtDocuments = [documentModel]
        this.graphDataMainType.documents.push(documentModel)
    }

    async onChangeContract (documentAdd) {
        let documentModel = new DocumentModel()
        documentModel.hidrate(documentAdd)
        documentModel.field = 'contract'
        documentModel.modelName = this.getModelQuery().getNameModelInDotNetTables()
        documentModel.modelId = this.graphDataMainType.id
        this.graphDataMainType.contractDocuments = [documentModel]
        this.graphDataMainType.documents.push(documentModel)
    }

    onEditarCliente (asset) {
        return this.propsUtil.changeUrlRequest({
            'rightModal': 'modalposclients',
            'clientId': asset.storeId,
            'assetId': asset.id,
            'rightModalTab': 'general'
        })
    }

    wait () {
    }
}

export default withRouter(AssetForm)
