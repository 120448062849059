import React, {Component} from 'react';
import VsfModal from "./modals/VsfModal";
import InputTypeComponent from "./fields/InputTypeComponent";
import AppLogger from "../util/AppLogger";
import SavedFilterModel from "../models/SavedFilterModel";
import PropTypes from 'prop-types';
import GraphException from "../network/GraphException";
import translate from "../translator/translate";
import Links from "../util/Links";
import appState from "../state/AppState";
import {observable} from "mobx";
import GqlErrors from "./status/GqlErrors";
import {observer} from "mobx-react";
import util from "../util/Util";

@observer
class FavouriteModal extends Component {

    static defaultProps = {
        listComponent: null
    }
    static propTypes = {
        listComponent: PropTypes.object
    }
    listComponent;
    @observable gqlErrors = [];

    constructor(props) {
        super(props);
        this.state = {
            favouriteTitle: "",
            shared: false
        };
    }

    getTitle(nameField) {
        let model = this.props.model;
        let result = nameField;
        if (model != null) {
            result = model.getLabelFromFieldName(nameField);
        }
        return result;
    }

    getNameFilter(valueObj) {
        let result = this.getTitle(valueObj.name);
        if (valueObj.op == "GT" || valueObj.op == "GTEQ") {
            result += " (Desde)";
        }
        if (valueObj.op == "LT" || valueObj.op == "LTEQ") {
            result += " (Hasta)";
        }
        return result;
    }

    getInFilterValues = (filter) => {
        let filterKeysArray = filter.value.split(",");
        let filterLabelsArray = [];
        if (filter.label != null) {
            filterLabelsArray = filter.label.split("/'").filter((value, key) => (key % 2 != 0));
        } else {
            filterLabelsArray = filter.value.split(",");
        }
        let filterArrayWithoutCommas = [];
        filterLabelsArray.map((label) => {
            filterArrayWithoutCommas.push({ label: label });
        });
        filterKeysArray.map((value, key) => {
            filterArrayWithoutCommas[key].value = value;
        });
        return filterArrayWithoutCommas;

    };

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async saveFavourite() {
        let configuration = {};
        configuration.columns = this.props.columns;
        configuration.currentFilter = this.props.currentFilter;
        let savedFilterModel = new SavedFilterModel();
        savedFilterModel.configuration = JSON.stringify(configuration);
        savedFilterModel.name = this.state.favouriteTitle;
        savedFilterModel.isShared = this.state.shared;
        if(util.hasValue(this.props.nameFilters)){
            savedFilterModel.modelName = this.props.nameFilters;
        }else {
            savedFilterModel.modelName = this.props.model.nameMainType;
        }
        savedFilterModel.userId = appState.loginState.userId;
        try {
            this.props.listComponent.getListStatus().formError = false;
            await savedFilterModel.persist();
            this.setState({ favouriteTitle: "", shared: false });
            this.props.reloadFilters();
            this.props.closeModal();
        } catch (e) {
            this.props.listComponent.getListStatus().queryGraphQlResponse.errors = new GraphException().getErrorsFromException(e);
            this.props.listComponent.getListStatus().formError = true;
            this.gqlErrors = new GraphException().getErrorsFromException(e);
        }

    }

    sharedFilter() {
        this.setState({
            shared: 1
        })
    }

    render() {
        const t = translate;
        let columns = this.props.columns;
        let links = new Links(this.props.location);
        const currentFilter = links.getCurrentFilterFromUrl();

        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom"
            >
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <div className="modal-push">
                    <div className="modal-head">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{t('Guardar')}<span>{t('Filtro como Favorito')}</span></p>
                                </div>
                            </div>
                            <div className="col-2 text-right">
                                <a href="#" className="close-modal" onClick={() => this.props.closeModal()}>
                                    <span className="fas fa-times" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">
                        <div className="mt-2 mb-4">
                            <div className="col-12">
                                {Object.entries(currentFilter).length !== 0
                                    ?
                                    <p className="modal-center-text">{t('Has seleccionado los siguientes filtros') + ": "}</p>
                                    :
                                    <p className="modal-center-text">{t('No hay filtros escogidos') + "."}</p>
                                }
                            </div>
                            <div className="col-12">
                                <div className="filter-tags">
                                    <div className="filter-tags__left">
                                        <div className="filter-tags__block">
                                            {Object.entries(currentFilter).map(([index, valueObj]) => (
                                                    (valueObj.op !== "IN" || valueObj.op !== "STRIN") ?
                                                        <ul key={index + valueObj} className="filter-tags__block__tags"
                                                            aria-labelledby="type-order">
                                                            <label
                                                                className='m-2 filter-tags__block__label2'>{this.getNameFilter(valueObj)}:</label>
                                                            <li>
                                                                <span
                                                                    className="tag-item tag-item--text">{valueObj.label}</span>
                                                            </li>
                                                        </ul>
                                                        :
                                                        <React.Fragment>
                                                            <label
                                                                className='m-2 filter-tags__block__label2'>{this.getNameFilter(valueObj)}:</label>
                                                            {this.getInFilterValues(valueObj).map((filter, index) => {
                                                                return (
                                                                    <ul key={index} className="filter-tags__block__tags"
                                                                        aria-labelledby="type-order">
                                                                        <li>
                                                                            <span
                                                                                className="tag-item tag-item--text">{filter.label}</span>
                                                                        </li>
                                                                    </ul>
                                                                )
                                                            })
                                                            }
                                                        </React.Fragment>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <p className="modal-center-text">{t('Y está activa siguiente visualizacion')+ ":"}</p>
                                <ul className="checkbox-list">
                                    {columns.map((value, index) =>
                                        (value.visible &&
                                            <li className="drag-li" key={index}>
                                                <div className="modal-center-label">{value.title}</div>
                                            </li>)
                                    )}
                                </ul>
                                <InputTypeComponent
                                    value={this.state.favouriteTitle}
                                    onChange={(e) => this.setState({ favouriteTitle: e.target.value })}
                                    name="favouriteTitle"
                                    title={t("Escribe un nombre para tu selección:")}
                                    placeholder={t("Escribe aqui...")}
                                    classGroup="modal-center-text"
                                    type="text"
                                    info={t("Elige un nombre que describa la selección") + ": Ej: Portugalia Norte Averías."}
                                />

                            </div>
                            <div className="col-12 mt-2">
                                <div className="checkbox text-right">
                                    <input type="checkbox" id="save-favorites" onClick={() => this.sharedFilter()} />
                                    <label onClick={() => this.sharedFilter()} htmlFor="save-favorites"
                                           className="pl-less">{t('Guardar para Todos')}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end mb-2">
                                <button onClick={() => this.props.closeModal()} type="button"
                                        className="btn btn-secondary">{t('Cancelar')}</button>
                                <button onClick={() => this.saveFavourite()} type="button"
                                        className="btn btn-primary">{t('Guardar')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default FavouriteModal
