import React from 'react';
import {observer} from 'mobx-react';
import AppLogger from "../util/AppLogger";
import BaseForm from "./base/BaseForm";
import ChangeModel from "../models/ChangeModel";
import appState from '../state/AppState';
import translate from "../translator/translate";
import util from "../util/Util";
import SpinnerClipLoaderInPage from "../network/SpinnerClipLoaderInPage";
import {observable} from "mobx";

@observer
class HistoryComponent extends BaseForm {

    constructor(props) {
        super(props);
        this.state = {
            changes: [],
            historyVisible: true,
            loading: true,
            errorFormulario: false
        };
    }

    async reloadForm() {
    }

    @observable dictRelations = {};

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.userCacheState.loadUserCache();
        await this.loadHistory();
        await this.loadRelations();
        this.setState({ loading: false })
    }

    async loadRelations() {
        for (let change of this.state.changes) {
            for (let value of Object.entries(JSON.parse(change.jsonInitial))) {
                let model = this.props.modelo;
                if (model.isThisNameFieldRelation(util.firstLowerCase(value[0])) && util.hasValue(value[1]) && util.getZeroIdIdentifierGUID() !== value[1]) {
                    let objArrayField = model.getArrayFields()[util.firstLowerCase(value[0])];
                    let Relation = model.getArrayFields()[objArrayField.relationName].childType;
                    let modelQuery = new Relation();
                    modelQuery.id = value[1];
                    this.dictRelations[value[1]] = await modelQuery.findFirst();
                    this.log({
                        loadRelations: 1,
                        modelQuery,
                        getArrayFields: model.getArrayFields(),
                        relacion: this.dictRelations[value[1]],
                        value, objArrayField,
                    })
                }
            }
            for (let value of Object.entries(JSON.parse(change.jsonChange))) {
                let model = this.props.modelo;
                if (model.isThisNameFieldRelation(util.firstLowerCase(value[0])) && util.hasValue(value[1]) && util.getZeroIdIdentifierGUID() !== value[1]) {
                    let objArrayField = model.getArrayFields()[util.firstLowerCase(value[0])];
                    let Relation = model.getArrayFields()[objArrayField.relationName].childType;
                    let modelQuery = new Relation();
                    modelQuery.id = value[1];
                    this.dictRelations[value[1]] = await modelQuery.findFirst();
                    this.log({
                        loadRelations: 2,
                        modelQuery,
                        getArrayFields: model.getArrayFields(),
                        relacion: this.dictRelations[value[1]],
                        value, objArrayField,
                    })
                }
            }
        }
    }

    showItemHistory(index) {
        this.log({ index, state: this.state.changes })
        let changes = this.state.changes;
        changes[index].visible = !changes[index].visible;
        this.setState({
            changes
        });
        this.log({ changes, state: this.state.changes })
    };

    async loadHistory() {
        let modelo = this.props.modelo;
        let changeQuery = new ChangeModel();
        changeQuery.modelName = modelo.getNameModelInDotNetTables();
        changeQuery.modelId = this.props.id;
        changeQuery.orderBy = "id";
        changeQuery.orderMode = "DESC";
        let historicos = await changeQuery.find();
        // this.log({ loadHistory: 1, modelo: modelo.getNameModelInDotNetTables(), historicos, changeQuery });
        let changes = [];
        let tamanyo = 10;
        for (let i = 0; i < tamanyo; i++) {
            if (historicos[i] != null) {
                if (historicos[i].fieldsChanged !== "")
                    changes.push(historicos[i]);
                else
                    tamanyo++;
            }
        }
        // LO AÑADIMOS AL ESTADO
        this.setState({
            changes,
            historyVisible: true
        });
    }

    getTitle(nameField) {
        let model = this.props.modelo;
        let result = nameField.replace(/\b\w/g, l => l.toLowerCase());
        if (model != null) {
            result = model.getLabelFromFieldName(nameField.replace(/\b\w/g, l => l.toLowerCase()));
        }
        return result;
    }

    getValue(nameField, value) {
        let model = this.props.modelo;
        let result = "";
        if (util.hasValue(value)) {
            // this.log({ etValue: 1, nameField, value, typeof: typeof value })
            if (value === "False") {
                result = "No";
            } else if (value === "True") {
                result = "Sí";
            } else if (model.isThisNameFieldRelation(util.firstLowerCase(nameField)) && util.getZeroIdIdentifierGUID() !== value) {
                let objArrayField = model.getArrayFields()[util.firstLowerCase(nameField)];
                result = this.dictRelations[value]?.[objArrayField.relationValue];
            } else if (model.isThisValueFromTypified(util.firstLowerCase(nameField))) {
                result = appState.typifiedState.getValueMultiple(value);
            } else {
                result = value;
                if (result === util.getZeroIdIdentifierGUID()) {
                    result = "";
                    value = "";
                }
            }
            if (nameField === 'DynamicField') {
                let texto = '';
                if (util.hasValue(value)) {
                    let json = JSON.parse(value);
                    for (let campo of Object.keys(json)) {
                        if (campo !== 'groups' && json[campo] !== 'SIN DATOS' && util.hasValue(json[campo])) {
                            texto += campo + ': ' + json[campo] + '  '
                        }
                    }
                    result = texto;
                }
            }
        }
        if (!util.hasValue(result)) {
            result = value;
        }
        return result;
    }

    render() {
        const t = translate;

        return (
            <SpinnerClipLoaderInPage loading={this.state.loading}>
                <div className="history-container">
                    <div className="history-list-container">
                        <div className="history-list-rows">
                            {this.state.changes.map((change, index) => (
                                <div className="history-list-item" key={index}>
                                    <span className="row history-list-item-title"
                                          onClick={() => {
                                              let changes = this.state.changes;
                                              changes[index].visible = !changes[index].visible;
                                              this.setState({
                                                  changes
                                              })
                                          }}>
                                              <div
                                                  className="col-md-9 history-list-item-updated">{change.action === "U" ? t('Actualizado por') + " " : t('Creado por') + " "}
                                                  <strong>{appState.userCacheState.getUserById(change.createdBy).email || ""}</strong> el <strong>{util.getMoment(change.createdAt).format("YYYY-MM-DD HH:mm")}</strong>
                                        </div>
                                        {change.action === "U" &&
                                        <div
                                            className="col-md-3 history-list-item-changes"> {Object.keys(JSON.parse(change.jsonInitial)).length} {t("Cambios")}
                                            <i className="fa fa-angle-right" />
                                        </div>
                                        }
                                    </span>
                                    {change.visible &&
                                    <div className="history-change-container">
                                        {change.action === "U" &&
                                        <div className="history-change previous">
                                        <span
                                            className="history-change-title history-change-item previous">{t("Anterior")}</span>
                                            {Object.entries(JSON.parse(change.jsonInitial)).map((value) => (
                                                <>
                                                    {util.perteneceLista(value[0], change.fieldsChanged) &&
                                                    <div className="history-change-item" key={value[0]}>
                                                    <span
                                                        className="history-change-item-text">{this.getTitle(value[0])}</span>
                                                        <span className="history-change-item-text value">
                                                    <i className="fa fa-arrow-right"> </i> {this.getValue(value[0], value[1])}</span>
                                                    </div>
                                                    }
                                                </>

                                            ))}
                                        </div>
                                        }
                                        <div className="history-change modified">
                                        <span
                                            className="history-change-title history-change-item modified">{change.action === "U" ? t("Modificado") : t("Creado")}</span>
                                            {Object.entries(JSON.parse(change.jsonChange)).map((value) => (
                                                <div className="history-change-item" key={value[1]}>
                                                <span
                                                    className="history-change-item-text">{this.getTitle(value[0])}</span>
                                                    <span className="history-change-item-text value"><i
                                                        className="fa fa-arrow-right"> </i> {this.getValue(value[0], value[1])}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </SpinnerClipLoaderInPage>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default HistoryComponent
