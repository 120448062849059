import React from 'react';
import Select, {components} from 'react-select';
import PropTypes from 'prop-types'
import FormInputWrapper from "./FormInputWrapper";
import util from "../../util/Util";
import BaseFieldComponent from "./BaseFieldComponent";
import {Animated} from "react-animated-css";
import MezclarObj from "../../util/MezclarObj";
import translate from "../../translator/translate";

class Select2Component extends BaseFieldComponent {

    static defaultProps = MezclarObj.assign(BaseFieldComponent.defaultProps, {
        onChange: () => 0, //Evento al cambiar el elemento
        value: "", // Value of the input
        name: "", // Name of the input
        title: "", // Title of the element. Showed in the label
        classGroup: "", // CSS class
        options: [], // List of elements to show in the select [{"label":"Label", value:"1"}]
        isClearable: true,
        multi: false,
        ordenar: true,
        forceMedia: null, // Fuerza a que se pinte con un media concreto. Por ejemlo md.
    });

    static propTypes = MezclarObj.assign(BaseFieldComponent.propTypes, {
        onChange: PropTypes.func,
        value: PropTypes.string,
        name: PropTypes.string,
        readOnly: PropTypes.bool,
        ordenar: PropTypes.bool,
        title: PropTypes.string,
        classGroup: PropTypes.string,
        options: PropTypes.array,
        isClearable: PropTypes.bool,
        multi: PropTypes.bool,
        forceMedia: PropTypes.string,
    });

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
        };
    };

    componentDidMount() {
        if (this._isMounted) {
            this.setState({
                selectedOption: this.props.value,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            if (this._isMounted) {
                this.setState({
                    selectedOption: nextProps.value
                })
            }
        }
    }

    getOptionsKeyed() {
        let result = {};
        for (let item of this.props.options) {
            result[item.value] = item;
            result[item.label] = item;
        }
        return result;
    }

    onBlurFunction() {
        let obj = {
            target: {
                value: this.props.value,
                name: this.props.name
            }
        };
        if (this.props.onBlur != null) {
            this.props.onBlur(obj)
        } else {
            this.validateOnBlurFunction(obj)
        }
    }


    handleChange(selectedOption) {
        let eventObj = {
            target: {
                name: this.props.name,
                value: "",
                label: ""
            }
        };
        if (this.props.multi) {
            selectedOption?.map((option) => {
                if (util.hasValue(eventObj.target.value)) {
                    eventObj.target.value += "," + option.value + "";
                    eventObj.target.label += ",/'" + option.label + "/'";
                } else {
                    eventObj.target.value = "" + option.value + "";
                    eventObj.target.label = "/'" + option.label + "/'";
                }
            });
            this.setState({ selectedOption });
        } else {
            if (util.esVacio(selectedOption)) {
                this.setState({
                    selectedOption: null
                });
            } else {
                this.setState({
                    selectedOption: selectedOption.value
                });
                eventObj.target.value = selectedOption.value;
                eventObj.target.label = selectedOption.label;
                eventObj.target.selectedOption = selectedOption;
            }
        }
        if (this.props.onChange != null) {
            if (this.props.operatorFilter == null) {
                if (this.props.multi) {
                    this.props.onChange(eventObj, "STRIN");
                } else {
                    this.props.onChange(eventObj, "EQ");
                }
            } else {
                this.props.onChange(eventObj, this.props.operatorFilter);
            }
        }
    };

    getValueOptionMulti(values, dict) {
        let response = [];
        if (values == null) {
            values = "";
        }
        let valuesArray = values.split(",");
        valuesArray.map((option) => {
            response.push(dict[option]);
        });
        return response;
    };


    getOptions() {
        let result = [];
        let observableArray = this.props.options;
        let options = observableArray.slice();
        if (this.props.ordenar) {
            options.sort(function (a, b) {
                return a.label?.localeCompare(b.label);
            });
        }
        for (let option of options) {
            //Si no ha sido eliminado y si no está excluido, se muestra
            if (!option.logicalDelete && option.value !== this.props.excludeValue) {
                result.push(option)
            }
        }
        return result;
    }

    getComponentMenu() {
        if (this.props.tenantSelector) {
            return (
                props => (
                    <Animated style={{
                        backgroundColor: '#fff',
                        zIndex: 99,
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        borderRadius: 5
                    }} animationIn="zoomIn" animationInDuration={250} isVisible={true}>
                        <components.Menu {...props} />
                    </Animated>
                )
            )
        } else {
            return null;
        }
    }

    getComponentValueContainer() {
        const t = translate;

        if (this.props.valueContainerMore) {
            return (
                ({ children, getValue, ...props }) => {
                    let maxToShow = 3;
                    var length = getValue().length;
                    let displayChips = React.Children.toArray(children).slice(0, maxToShow);
                    let shouldBadgeShow = length > maxToShow;
                    let displayLength = length - maxToShow;
                    var selectInput = React.Children.toArray(children).find((input) => input.type.name === "Input" || input.type.name === "DummyInput");
                    return (
                        <components.ValueContainer {...props}>
                            {!props.selectProps.inputValue && displayChips}
                            <div className="root">
                                {shouldBadgeShow &&
                                `+ ${displayLength} ${t("más")}`}
                            </div>
                            {selectInput}
                        </components.ValueContainer>

                    );
                }
            )
        } else {
            return null;
        }
    }

    render() {
        const t = translate;

        let props = this.props;
        let valueOption = "";
        let readOnly = this.checkReadOnly();
        if (this.props.multi) {
            valueOption = this.getValueOptionMulti(this.props.value, this.getOptionsKeyed());
        } else {
            valueOption = this.getOptionsKeyed()[this.props.value];
        }
        let options = this.getOptions();
        let classNameInput = this.getClassNameInput();
        if (readOnly) {
            if (this.props.multi) {
                valueOption = valueOption.map(option => option?.label + " ")
            } else {
                valueOption = this.getOptionsKeyed()[this.props.value]?.label;
            }
        }
        const ValueContainer = this.getComponentValueContainer();
        const Menu = this.getComponentMenu();
        let components = {};
        if (Menu != null) {
            components["Menu"] = Menu;
        }
        if (ValueContainer != null) {
            components["ValueContainer"] = ValueContainer;
        }

        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                classInputType={"Select2Component "}
                name={props.name}
                ariaLabelledby={this.props.ariaLabelledby}
                postfixClass={props.postfixClass}
                prefixClass={props.prefixClass}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors} formGroup={this.props.formGroup}
                title={this.props.title}
                errorsClientValidation={this.state.errorsClientValidation}
                required={this.props.required}
                isClearable={this.props.isClearable}
                relatedInfo={this.props.relatedInfo}
                info={this.props.info}
                forceMedia={this.props.forceMedia}
                dataCy={this.props.dataCy}
                flexLabelInput={this.props.flexLabelInput ? "d-flex justify-content-between" : ""}>
                {this.props.showView ?
                    <div>
                        {valueOption?.label || props.value}
                    </div>
                    :
                    <>
                        {readOnly ?
                            <input
                                value={valueOption || ""}
                                autoComplete="new-password"
                                className={this.props.tenantSelector ? classNameInput + " customClass-input" : classNameInput}
                                name={props.name}
                                placeholder={props.placeholder}
                                readOnly={readOnly}
                                type={props.type}
                            />
                            :
                            <Select
                                placeholder={props.placeholder || t("Seleccionar")}
                                value={valueOption}
                                onChange={(e) => this.handleChange(e)}
                                isClearable={props.isClearable}
                                noOptionsMessage={() => (t('No hay resultados'))}
                                options={options}
                                onBlur={e => this.onBlurFunction(e)} isHidden
                                isMulti={this.props.multi}
                                data-cy={"SELCTTESTCY"}
                                data-name={props.name}
                                filterOption={(candidate, input) => this.customFilter(candidate, input)}
                                className={this.props.tenantSelector ? "customClass" : ""}
                                classNamePrefix={"react-select"} //usar esta prop para dar estilos a cualquier elemento del select
                                styles={this.customStyles}
                                components={components}
                                width={util.hasValue(this.props.extraWidth) ? `calc(100% + ${this.props.extraWidth}px)` : '100%'}
                            />
                        }
                    </>
                }
            </FormInputWrapper>
        );
    }


}

export default Select2Component
