import React, { Component } from 'react';
import { observer } from 'mobx-react';
import util from '../../util/Util';
import AppLogger from "../../util/AppLogger";

@observer
export default class DynamicGroupSelectInModal extends Component {

    constructor(props) {
        super(props)
    }



    render() {
        let values;
        if (typeof this.props.values === 'number') {
            values = this.props.values.toString();
        } else values = this.props.values;

        let dynamicsGroups = this.props.dynamicsGroups.map((dynamicGroup) => {
            return (
                <tr className='dropdown-sublist__head' key={dynamicGroup.id}>
                    <td scope="row">
                        <input
                            type="checkbox"
                            style={{ display: "block" }}
                            name={"workorder-" + dynamicGroup.id}
                            checked={util.perteneceA(dynamicGroup.code, values)}
                            id={"workorder-" + dynamicGroup.id}
                            value={dynamicGroup.id}
                            onChange={() => this.props.onChange(dynamicGroup)}
                        />
                    </td>
                    <>
                        <td scope={"row"}>
                            {dynamicGroup.name}
                        </td>
                    </>
                </tr>
            )
        });
        return (
            <>
                {dynamicsGroups}
            </>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
