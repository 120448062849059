import React from 'react';
import LoadTranslations from "./LoadTranslations";
import TranslationsModel from "../models/TranslationsModel";
import util from "../util/Util";
import LocalStorage from "../network/LocalStorage";
import appState from "../state/AppState";
import OFFER from "../config/OFFER";

let loadTranslations = new LoadTranslations();
const localStorage = new LocalStorage();
const creatable = OFFER.creatable;

function limpiaString(archivo) {
    let result = "";
    if (util.hasValue(archivo)) {
        archivo = archivo.replace(/[áàâãªä]/gi, "a");
        archivo = archivo.replace(/[ÁÀÂÃÄ]/gi, "a");
        archivo = archivo.replace(/[éèêë]/gi, "e");
        archivo = archivo.replace(/[ÉÈÊË]/gi, "e");
        archivo = archivo.replace(/[íìîï]/gi, "i");
        archivo = archivo.replace(/[ÍÌÎÏ]/gi, "i");
        archivo = archivo.replace(/[óòôõºö]/gi, "o");
        archivo = archivo.replace(/[ÓÒÔÕÖ]/gi, "o");
        archivo = archivo.replace(/[úùûü]/gi, "u");
        archivo = archivo.replace(/[ÚÙÛÜ]/gi, "u");
        archivo = archivo.replace(/[ñÑ]/gi, "n");
        archivo = archivo.replace(/ /gi, "");
        archivo = archivo.toLowerCase();
        archivo = archivo.trim();
        // log({ limpiaString: 2, archivo });
        result = archivo.toLowerCase();
    }
    return result
}

function translate(message) {
    let messages = loadTranslations.getMessages();
    let result = message;
    let userLocale = localStorage.getItem('currentLanguage');
    let currentLanguage;
    if (util.hasValue(userLocale)) {
        currentLanguage = userLocale;
    } else {
        currentLanguage = "es"
    }
    // log({ translates: 1, messages, message, trad: messages[limpiaString(message)] });
    //Tenemos mensaje traducido
    if (message != null && messages[limpiaString(message)] != null) {
        //messages[message] cuando haya traducciones
        result = messages[limpiaString(message)];
        if (util.esVacio(messages[limpiaString(message)])) {
            result = message;
        }
    } else {
        if (message != null) {
            let translation = new TranslationsModel();
            translation.groupTranslation = "";
            translation.label = message.trim();
            translation.message = "";
            translation.lang = currentLanguage;
            translation.device = TranslationsModel.WEB;
            loadTranslations.setMessages(message, message);
            if (appState.loginState.isUserLogged && util.hasValue(translation.label)) {
                if (creatable[translation.nameMainType]) {
                    translation.persist();
                }
            }
        }
        result = message;
    }
    return result;
};


export default translate;
