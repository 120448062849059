import React, {Component} from 'react';
import {observer} from "mobx-react";
import PropsUtil from "../util/PropsUtil";
import InfoBlock from "../components/InfoBlock";
import AppLogger from "../util/AppLogger";
import appState from '../state/AppState';
import ChangesWithValidationsModel from "../models/ChangesWithValidationsModel";
import GroupOfField from "./layout/GroupOfField";
import SwitchComponent from "./fields/SwitchComponent";
import util from "../util/Util";
import WorkLogModel from "../models/WorkLogModel";
import YesOrNoEnum from "../models/enum/YesOrNoEnum";
import translate from "../translator/translate";

@observer
class CommentsDispatcherTable extends Component {

    constructor(props) {
        super(props);
        this.props.onRef(this);
    }


    updateInputEventConfig(e, validate, name) {
        appState.layoutState.formWithoutChanges = false;
        let value = JSON.parse(validate.changes)[name]
        let relation = {};
        if (this.startWith(e.target.name, "approved")) {
            if (validate.approved === ChangesWithValidationsModel.PENDING || validate.approved === ChangesWithValidationsModel.REJECTED) {
                validate.approved = ChangesWithValidationsModel.APPROVED;
                if (name === "storeId") {
                    relation = this.clients[value];
                }
                this.props.onChangeInput(name, value, relation);
                for (let validateMbx of this.mobxListado) {
                    let nameMbx = Object.keys(JSON.parse(validateMbx.changes))[0];
                    if (name === nameMbx) {
                        if (validateMbx.id !== validate.id) {
                            validateMbx.approved = ChangesWithValidationsModel.REJECTED;
                            validateMbx.hasChange = true;
                        }
                    }
                }
            } else {
                if (name === "storeId") {
                    relation = this.clients[this.props.previousRowForDiscard?.[name]];
                }
                validate.approved = ChangesWithValidationsModel.PENDING;
                this.props.onChangeInput(name, this.props.previousRowForDiscard?.[name], relation);
            }
        } else {
            if (name === "storeId") {
                relation = this.clients[this.props.previousRowForDiscard?.[name]];
            }
            if (validate.approved === ChangesWithValidationsModel.REJECTED) {
                validate.approved = ChangesWithValidationsModel.PENDING
            } else {
                validate.approved = ChangesWithValidationsModel.REJECTED;
                if (this.props.graphDataMainType?.[name] !== this.props.previousRowForDiscard?.[name]) {
                    this.props.onChangeInput(name, this.props.previousRowForDiscard?.[name], relation);
                }
            }
        }
        appState.workLogState.commentsDispatcher = validate;
    }


    render() {
        //let {t} = translate;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let workLogModel = new WorkLogModel();
        return (
            <InfoBlock componentObject={this}>
                {this.props.commentsDispatcher?.length > 0 &&
                <>
                    <GroupOfField title={t('Comentarios Internos')}>
                        <div className="col-12">
                            <SwitchComponent
                                checked={this.props.graphDataMainType?.commentsValidated === YesOrNoEnum.YES}
                                onChange={(e) => this.props.onChange(e)}
                                name={"commentsValidated"}
                                value={this.props.graphDataMainType?.commentsValidated}
                                className={"col-12"}
                                withValue={true}
                                editable={this.editable}
                                title={t("Comentarios Vistos")}
                                classGroup={"col-4"}
                                flexChildren={"0"}
                                flexLabelInput="d-flex justify-content-between"
                            />
                            <div className="validatesFormTable">
                                {this.props.commentsDispatcher.map((result) => (
                                    (util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(WorkLogModel.COMMENTS_DISPATCHER)]) &&
                                    JSON.parse(result.data)[workLogModel.nameForData(WorkLogModel.COMMENTS_DISPATCHER)].reverse().map(obj => (
                                        <div className="validatesFormTable-item">
                                            <div
                                                className="validatesFormTable-title-comment"> <span>{obj.comment}</span></div>
                                            <div
                                                className="validatesFormTable-user">{appState.userCacheState.getUserName(result.createdBy)}</div>
                                            <div
                                                className="validatesFormTable-date">{util.hasValue(result.createdAt) ? util.localizeIsoDateMinutes(result.createdAt) : " "}</div>
                                        </div>
                                    ))
                                ))}
                            </div>
                        </div>
                    </GroupOfField>
                </>
                }
            </InfoBlock>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default CommentsDispatcherTable;
