import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import BaseForm from '../base/BaseForm'
import AssetModel from '../../models/AssetModel'
import translate from '../../translator/translate'
import GroupOfField from '../../components/layout/GroupOfField'
import MoreButtons from '../../components/MoreButtons'
import CreateAndModify from '../../components/fields/CreateAndModify'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import UploadFileComponent from '../../components/UploadFileComponent'
import SwitchComponent from '../../components/fields/SwitchComponent'
import FormButtons from '../../components/FormButtons'
import GqlErrors from '../../components/status/GqlErrors'
import GqlWarnings from '../../components/status/GqlWarnings'
import { toJS } from 'mobx'
import DocumentModel from '../../models/DocumentModel'
import AssetActions from './AssetActions'
import TextAreaComponent from '../../components/fields/TextAreaComponent'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import ToastBootstrap from '../../components/modals/ToastBootstrap'
import appState from '../../state/AppState'

@observer
class AssetWarranty extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "asset";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            showToaster: false,
        }
    }

    async reloadForm() {
        await super.reloadForm();
        await this.loadWarranty();
    }

    async loadWarranty() {
        let documentQuery = new DocumentModel();
        documentQuery.modelId = this.graphDataMainType.id;
        documentQuery.field = 'warranty';
        let document = await documentQuery.findFirst();
        if (document != null) {
            this.graphDataMainType.replaceDocuments = [document?.toPlainObject()];
        } else {
            this.graphDataMainType.replaceDocuments = [];
        }
    }

    async onChangeWarranty(documentAdd) {
        this.setState({
            showToaster: true
        })
        if (documentAdd != null) {
            let documentModel = new DocumentModel();
            documentModel.hidrate(documentAdd);
            documentModel.field = 'warranty';
            documentModel.modelName = this.getModelQuery().getNameModelInDotNetTables();
            documentModel.modelId = this.graphDataMainType.id;
            this.graphDataMainType.replaceDocuments = [documentModel];
        } else {
            this.graphDataMainType.replaceDocuments = [{ field: 'warranty' }];
        }
    }

    getModelQuery() {
        let result = new AssetModel();
        result.addRelatedTable("replaceDocuments");
        return result;
    }

    getModelQueryForView() {
        let result = new AssetModel();
        //result.addRelatedTable("documents");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let asset = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let id = this.getFormId() || this.props.id;
        const t = translate;
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.type).config)
        }

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Gestión de Garantías"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle title={this.getPageTitle()} subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={"Gestión de Garantías"}
                            />
                            }
                            <SpinnerClipLoaderInPage loading={this.state.loading}>

                                <form
                                    className="model-form"
                                    name="formulario" noValidate
                                    onSubmit={(e) => this.handleFormSubmit(e)}>
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                    <GqlWarnings warnings={this.gqlWarnings}
                                        onSaveWarning={() => this.saveSlotWarning()}
                                        key={this.gqlWarnings}
                                        setWarnings={(warnings) => this.cancelSlotWarning(warnings)} />
                                    <GroupOfField title={t('Gestión de Garantías')}
                                        icon="fas fa-search-plus" />
                                    <div className="row">
                                        <CreateAndModify
                                            updatedAt={asset.updatedAt}
                                            updatedBy={asset.updatedBy}
                                            createdBy={asset.createdBy}
                                            createdAt={asset.createdAt}
                                        />
                                    </div>
                                    <div className="row">
                                        <div className={"col-7"}>
                                            <div className="row">
                                                <SwitchComponent
                                                    checked={asset.articleWarranty}
                                                    name={"articleWarranty"}
                                                    value={asset.articleWarranty} title={t("Piezas")}
                                                    editable={this.editable}
                                                    onChange={(e) => {
                                                        this.updateInputEvent(e);
                                                    }}
                                                    classGroup={"col-xs-12 col-lg-4"}
                                                    errors={errorsMapped.articleWarranty}
                                                />
                                                <DateInputFieldMobiscroll
                                                    value={asset.articleWarrantyStartDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Inicio de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={!asset.articleWarranty}
                                                    name={"articleWarrantyStartDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />

                                                <DateInputFieldMobiscroll
                                                    value={asset.articleWarrantyEndDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Fin de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={!asset.articleWarranty}
                                                    name={"articleWarrantyEndDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />
                                                <SwitchComponent
                                                    checked={asset.labourWarranty}
                                                    name={"labourWarranty"}
                                                    value={asset.labourWarranty} title={t("Mano de Obra")}
                                                    editable={this.editable}
                                                    onChange={(e) => {
                                                        this.updateInputEvent(e);
                                                    }}
                                                    classGroup={"col-xs-12 col-lg-4"}
                                                    errors={errorsMapped.labourWarranty}
                                                />
                                                <DateInputFieldMobiscroll
                                                    value={asset.labourWarrantyStartDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Inicio de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={!asset.labourWarranty}
                                                    name={"labourWarrantyStartDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />
                                                <DateInputFieldMobiscroll
                                                    value={asset.labourWarrantyEndDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Fin de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => {
                                                        this.updateInputEvent(e);
                                                    }}
                                                    readOnly={!asset.labourWarranty}
                                                    name={"labourWarrantyEndDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />
                                                <SwitchComponent
                                                    checked={asset.deslocalizationWarranty}
                                                    name={"deslocalizationWarranty"}
                                                    value={asset.deslocalizationWarranty} title={t("Desplazamientos")}
                                                    editable={this.editable}
                                                    onChange={(e) => {
                                                        this.updateInputEvent(e);
                                                    }}
                                                    classGroup={"col-xs-12 col-lg-4"}
                                                    errors={errorsMapped.deslocalizationWarranty}
                                                />
                                                <DateInputFieldMobiscroll
                                                    value={asset.deslocalizationWarrantyStartDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Inicio de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={!asset.deslocalizationWarranty}
                                                    name={"deslocalizationWarrantyStartDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />
                                                <DateInputFieldMobiscroll
                                                    value={asset.deslocalizationWarrantyEndDate}
                                                    prefix={"fas fa-calendar-alt"}
                                                    title={t("Fecha Fin de Garantía")} allowEmptyDate={true}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    readOnly={!asset.deslocalizationWarranty}
                                                    name={"deslocalizationWarrantyEndDate"}
                                                    classGroup={"col-xs-3 col-lg-4"}
                                                />
                                                {/*<DateInputFieldMobiscroll*/}
                                                {/*value={asset.endDateWarrantyNotify}*/}
                                                {/*prefix={"fas fa-calendar-alt"}*/}
                                                {/*title={t("Notificación de Fin de Garantía")} allowEmptyDate={true}*/}
                                                {/*editable={this.editable}*/}
                                                {/*onChange={(e) => this.updateInputEvent(e)}*/}
                                                {/*readOnly={(asset.articleWarranty || asset.labourWarranty) != 1}*/}
                                                {/*name={"endDateWarrantyNotify"}*/}
                                                {/*classGroup={"col-md-6 col-lg-3 col-xl-3 ml-4"}*/}
                                                {/*/>*/}
                                                <TextAreaComponent
                                                    classGroup={"col-12 mt-3"}
                                                    value={this.graphDataMainType.comments}
                                                    onChange={(event) => this.updateInputEvent(event)}
                                                    name={"comments"} autoFocus
                                                    title={t('Comentarios')}
                                                    editable={this.editable}
                                                    className="form-control"
                                                    readOnly={(!asset.deslocalizationWarranty && !asset.articleWarranty && !asset.labourWarranty)}
                                                />
                                            </div>
                                            <ToastBootstrap onCloseModal={() => this.setState({ showToaster: false })}
                                                isOpen={this.state.showToaster}
                                                title={t("Al guardar la nueva imagen la anterior queda guardada en la pestaña documentos")}
                                                autohide={true}
                                                success={true}
                                            />
                                            <UploadFileComponent
                                                required={true}
                                                name={'uploadFiles'}
                                                warrantyForm={true}
                                                optionsSelect={[{ label: "Warranty", value: "warranty" }]}
                                                onChangePrevious={() => this.onChangePrevious()}
                                                onChange={(e) => this.onChangeWarranty(e)}
                                                documents={asset?.replaceDocuments}
                                                baseFolder={this.nameMainType + "/" + asset.id + "/documents"}
                                            />
                                        </div>
                                        <div className="col-5">
                                            {asset?.replaceDocuments?.length > 0 &&
                                            util.hasValue(util.getDocumentPhoto(asset?.replaceDocuments?.[0])?.thumbnailx16) &&
                                            <a href={util.getDocumentPhoto(asset?.replaceDocuments?.[0])?.getThumbNailx16()}
                                                target={'_blank'} rel="noreferrer">
                                                <img alt={asset?.replaceDocuments?.[0]?.name} style={{ width: '100%' }}
                                                    src={util.getDocumentPhoto(asset?.replaceDocuments?.[0])?.getThumbNailx16()} />
                                            </a>
                                            }
                                        </div>
                                    </div>
                                    <FormButtons id={id} formStatus={this.graphStatus} />
                                </form>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

    onChangePrevious() {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
    }

}

export default withRouter(AssetWarranty);
