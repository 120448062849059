import BaseModelState from "./BaseModelState";
import OriginModel from "../models/OriginModel";
import { computed, observable } from "mobx";

class OriginState extends BaseModelState {

    @observable origins = []

    async getOrigins() {
        if (Object.keys(this.origins).length === 0) {
            let originModel = new OriginModel();
            this.origins = await originModel.find();
        }
    }

    @computed get labelValuesForSelect() {
        let result = [];
        let copia = {};
        for (let item of this.origins) {
            if (copia[item.name] == null) {
                copia[item.name] = "";
                result.push({
                    value: item.id,
                    label: item.name,
                    obj: item,
                });
            }
        }
        return result;
    }


}

export default OriginState;
