import React, { Component } from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import util from '../../util/Util'
import AppLogger from '../../util/AppLogger'
import PropTypes from 'prop-types'
import translate from '../../translator/translate'
import BudgetModel from '../../models/BudgetModel'
import UserModel from '../../models/UserModel'

@observer
class TextListItem extends Component {

  static defaultProps = { noAsignadoTexto: ' - ' }

  static propTypes = {
    row: PropTypes.object,
    urlForm: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    noAsignadoTexto: PropTypes.string,
  }

  render () {
    let props = this.props
    const t = translate
    let value = props.value
    let valueInitial = value
    if (props.disponible || props.garantia || props.warrantyAsset) {
      value = !value ? 'No' : 'Si'
    }
    if (props.isPrice) {
      value = value === 0 ? '-' : value + ' €'
    }
    if (props.fromWarranty) {
      value = value === 'Y' ? 'Si' : 'No'
    }
    if (props.fromWarrantyAccepted) {
      value = value === 'Y' ? 'Si' : value === 'N' ? 'No' : '-'
    }
    if (props.fromTypified) {
      if (util.hasValue(value)) {
        value = appState.typifiedState.getValue(value)
      }
    }
    if (props.fromDynamic) {
      if (props.row.dynamicField && JSON.parse(props.row.dynamicField)) {
        value = typeof JSON.parse(props.row.dynamicField) === 'object' ? JSON.parse(props.row.dynamicField)[props.name]
          : ''
      }
    }
    if (props.statusUser && props.value) {
      value = UserModel.getLabelStatus(props.value)
    }
    if (props.budgetStatus) {
      value = BudgetModel.getTextFromCode(value, props)
    }
    if (!util.hasValue(value)) {
      value = t(this.props.noAsignadoTexto)
    }


    return (
      <div className={this.props.textTruncate ? 'ancho-responsive text-truncate' : ''}
           style={this.props.textAlign && { textAlign: 'right' }}>
        {props.icon &&
        <div className="col-6"><span className={props.icon} /></div>}
        <span onClick={() => {
          if (util.hasValue(this.props.onClick) && util.hasValue(props.value)) {
            this.props.onClick(props.row)
          }
        }} title={util.hasValue(this.props.onClick) ? t('Ver detalle') : ''}
              className={util.hasValue(this.props.onClick) && util.hasValue(props.value) ? 'text-click text-underline'
                : ''}>{value}</span>
      </div>
    )
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default TextListItem
