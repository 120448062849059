import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import UserModel from "../../models/UserModel";
import HistoryComponent from "../HistoryComponent";
import MoreButtons from "../../components/MoreButtons";
import appState from "../../state/AppState";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import UserActions from "./UserActions";


@observer
class UserHistory extends BaseForm {
    nameMainType = "user";

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.tabsData.isClient = appState.userState.isUserRoleClient();
        this.nameMainType = "user";
        this.initializeGraphData(this.nameMainType);
    }

    async componentDidMount(){
        await super.componentDidMount();
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);

    }

    getModelQuery() {
        return new UserModel();
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let user = this.graphDataMainType;
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                {this.getFormId() != null &&
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={user} getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                                }
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataUser()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.getFormId() != null &&
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={user} getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataUser()}
                                active={"Histórico"}
                            />
                            }
                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />
                        </div>


                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(UserHistory);
