import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../../state/AppState";
import { observer } from 'mobx-react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import OrderModel from "../../../models/OrderModel";
import SlotModel from "../../../models/SlotModel";
import WorkOrderModel from "../../../models/WorkOrderModel";
import AppLogger from "../../../util/AppLogger";
import TypifiedValueModel from "../../../models/TypifiedValueModel";
import { now } from "moment";
import util from "../../../util/Util";

const Types = {
    ITEM: 'workInMonthUnit'
};

const itemTarget = {
    hover(props, monitor, component) {
        if (!component) {
            return null
        }
        let item = monitor.getItem();
        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // Determine rectangle on screen
        const hoverBoundingRect = (findDOMNode(
            component,
        )).getBoundingClientRect()

    },

    //Vuelco un elemento dentro de la zona "Dropable"
    async drop(props, monitor, component) {
        let codeMonth = props.monthItem.code;
        const propsFromVisitDragged = monitor.getItem();
        // La visita puede ser nula. Esto significa que estoy moviendo un elemento.
        if (propsFromVisitDragged.order == null) {

            let status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'APPROVED');
            let scheduleVisitId = propsFromVisitDragged.visitType.id;
            let originId = propsFromVisitDragged.visitType.originId;
            let type = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORK_ORDER_TYPE, 'PRE').id;
            if(util.hasValue(propsFromVisitDragged.visitType?.type)){
                type = propsFromVisitDragged.visitType?.type;
            }
            //Creo el OrderModel
            let order = new OrderModel();
            order.scheduleVisitId = scheduleVisitId;
            order.status = status.id;
            order.originId = originId;
            order.origin = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_ORIGIN, "NUDDO").id;
            order.clientId = props.storeId;
            order.propietaryId = props.propietaryId;
            order.comments = propsFromVisitDragged.visitType.name;
            order.scheduledDate = codeMonth;
            let orderPlain = order.toPlainObject();
            orderPlain.loading = true;
            await appState.schedulePreventDropState.scheduledOrders.push(orderPlain);
            let length = appState.schedulePreventDropState.scheduledOrders.length;
            await order.persist();
            //Creo una O.T
            let workOrder = new WorkOrderModel();
            workOrder.originId = originId;
            workOrder.orderId = order.id;
            workOrder.generalDescription = propsFromVisitDragged.visitType.name;
            workOrder.type = type;
            await workOrder.persist();
            //Guardo los slots
            let scheduledLabel = '';
            let assignedSlotMainId = "";
            for (let scheduleVisitSlot of propsFromVisitDragged.visitType.scheduleVisitSlots) {
                let slot = new SlotModel();
                slot.orderId = order.id;
                slot.createdByPreventive = 1;
                slot.originId = originId;
                slot.scheduledDuration = scheduleVisitSlot.scheduledDuration;
                slot.slotUserRole = scheduleVisitSlot.slotUserRole;
                slot.workOrderIds = "-1";
                slot.scheduledDate= order.scheduledDate;
                scheduledLabel += scheduleVisitSlot.scheduledDuration + 'h/';
                //     slot.slotUserRole = scheduleVisitSlot.slotUserRole;
                if (scheduleVisitSlot.slotUserRole === SlotModel.MAIN) {
                    await slot.persist();
                    assignedSlotMainId = slot.id;
                    slot.order = order;
                    if(appState.schedulePreventDropState.slotSchedule[order.scheduledDate] == null){
                        appState.schedulePreventDropState.slotSchedule[order.scheduledDate] = [];
                    }
                    appState.schedulePreventDropState.slotSchedule[order.scheduledDate].push(slot)
                }
                if (scheduleVisitSlot.slotUserRole === SlotModel.SUPPORT) {
                    slot.assignedSlotMainId = assignedSlotMainId;
                    await slot.persist();
                    slot.order = order;
                    appState.schedulePreventDropState.slotSchedule[order.scheduledDate].push(slot)
                }
            }
            order.scheduledLabel = scheduledLabel.slice(0, -1);
            await order.persist();
            appState.schedulePreventDropState.scheduledOrders[length - 1] = order.toPlainObject();
            props.loadClients();
            props.getTotals();
        } else {
            //Muevo un elemento.
            if (propsFromVisitDragged.order.scheduledDate != props.monthItem.code) {
                let orderObservable = propsFromVisitDragged.order;
                let order = new OrderModel();
                order.hidrate(orderObservable);
                await order.persist();
                orderObservable.scheduledDate = codeMonth;
            }
        }
    }

};

function log(msg) {
    AppLogger.get().debug(msg, this);
}

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class MonthDrop extends Component {
    static defaultProps = {};
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        monthItem: PropTypes.object,
        //Identificador de la tienda para que al generar el OrderModel asigne este valor
        storeId: PropTypes.string
    };

    render() {
        let dateMonth = this.props.monthItem.code;
        let dateMonthNow = util.getMoment(Date(now())).format("YYYYMM");
        let clasName = "";
        if (dateMonth < dateMonthNow) {
            clasName = " inactive ";
        }
        //let ordersWithDate = appState.preventOrders.filter(order=>order.date!=null);
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <td key={this.props.monthItem.code} className={"drop-hours " + clasName}
                style={{ width: '60px', border: "1px solid #D3D3D3", padding: '0.3rem' }}>
                {this.props.children}
            </td>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(MonthDrop)
