import React, { Component } from 'react'
import { observer } from 'mobx-react'
import IconsInputsComponent from './IconsInputsComponent'
import PropTypes from 'prop-types'
import util from '../../util/Util'
import AppLogger from '../../util/AppLogger'
import PropsUtil from '../../util/PropsUtil'
import translate from '../../translator/translate'

@observer
class FormInputWrapper extends Component {

    static defaultProps = {
        classInputType: "",
        formGroup: "form-group"
    };
    static propTypes = {
        classInputType: PropTypes.string,
        formGroup: PropTypes.string,
    };

    constructor(props) {
        super(props);
    }

    getClassGroupWithForceMedia(forceMedia, classGroup) {
        //si classGroup tiene un col-xl-4 y un col-sm-6 reemplaza los superiores (no mostrandolos)
        let valores = classGroup.split(" ");
        let newClassGroup = [];

        let arrSizes = ["sm", "md", "lg", "xl"];
        let arrSizesEliminar = [];
        let pos = arrSizes.indexOf(forceMedia);
        for (let i = pos + 1; i < arrSizes.length; i++) {
            arrSizesEliminar.push(arrSizes[i]);
        }
        let strSizesEliminar = arrSizesEliminar.join(";");
        for (let valor of valores) {
            let claveTamanio = util.getDelim(valor, "-", 1);
            //this.log("claveTamanio:"+claveTamanio+" strSizesEliminar: "+strSizesEliminar);
            if (!util.perteneceLista(claveTamanio, strSizesEliminar)) {
                newClassGroup.push(valor);
            }
        }
        classGroup = newClassGroup.join(" ");
        return classGroup;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        const t = translate;
        let errorsProps = this.props.errors;
        if (errorsProps == null) {
            errorsProps = [];
        }
        let errorsClientValidation = this.props.errorsClientValidation;
        if (errorsClientValidation == null) {
            errorsClientValidation = [];
        }
        let errors = [...errorsProps, ...errorsClientValidation];
        let hasNoErrors = this.props.hasNoErrors;
        let hasErrors = errors.length > 0;
        let labelClassName;
        if (util.hasValue(this.props.labelClassName)) {
            labelClassName = "control-label " + this.props.labelClassName;
        } else {
            labelClassName = "control-label ";
        }
        if (hasErrors) {
            labelClassName += " text-danger";
        }
        if (hasNoErrors) {
            labelClassName += " text-success";
        }
        let classGroup = props.classGroup;
        if (util.hasValue(this.props.forceMedia)) {
            classGroup = this.getClassGroupWithForceMedia(this.props.forceMedia, props.classGroup);
        }

        return (
            <div className={classGroup} style={props.styleClassGroup} data-cy={this.props.dataCy}>
                <div className={this.props.formGroup}>
                    <div style={this.props.style} aria-labelledby={props.ariaLabelledby || ''}
                        className={props.classInputType + this.props.flexLabelInput}>
                        <div className={labelClassName}
                            style={(util.hasValue(props.title) || !util.hasValue(props.flexLabelInput)) ? { flex: 1 } : { flex: 0 }}>
                            {util.hasValue(props.title) &&
                            <label style={{ overflowX: 'visible', color: '#650200', marginBottom: 0 }}
                                htmlFor={props.name}>
                                {props.title}
                                {props.required && "*"}
                                {util.hasValue(props.iconTitle) &&
                                <span className={"text-click " + props.iconTitle}
                                    onClick={() => props.onClickIconTitle()} />
                                }
                            </label>
                            }
                            {props.info && <span className="js-info ">{"  " + props.info}</span>}
                        </div>
                        {util.hasValue(this.props.prefix) || util.hasValue(this.props.postfix) ?
                            <IconsInputsComponent
                                onClickIcon={this.props.onClickIcon}
                                postfix={this.props.postfix}
                                postfixClass={this.props.postfixClass}
                                prefix={this.props.prefix}
                                prefixClass={this.props.prefixClass}
                                {...this.props}>
                                {this.renderInput()}
                            </IconsInputsComponent> :
                            this.renderInput()
                        }

                    </div>
                    {util.hasValue(props.relatedInfo) &&
                    <span className="js-info-related ">{props.relatedInfo}</span>
                    }
                </div>
            </div>
        );
    }

    renderInput() {
        let props = this.props;
        const t = translate;
        let errorsProps = this.props.errors;
        if (errorsProps == null) {
            errorsProps = [];
        }
        let errorsClientValidation = this.props.errorsClientValidation;
        if (errorsClientValidation == null) {
            errorsClientValidation = [];
        }
        let errors = [...errorsProps, ...errorsClientValidation];
        let hasErrors = errors.length > 0;
        return (
            <div style={{ flex: this.props.flexChildren || 5 }}>
                {props.children}
                {hasErrors &&
                <>
                    <i className="js-error form-control-feedback glyphicon glyphicon-remove" />
                    {errors.map(error =>
                        <small data-cy="errorsForm"
                            className="js-error help-block text-danger">{t(error.message)}</small>)
                    }
                </>
                }
            </div>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default FormInputWrapper;
