import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import WorkOrderModel from "../../models/WorkOrderModel";
import HistoryComponent from "../HistoryComponent";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import appState from "../../state/AppState";
import WorkOrderActions from "./WorkOrderActions";

@observer
class WorkOrderHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
    }

    getModelQuery() {
        return new WorkOrderModel();
    }


    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("order");
        result.addRelatedTable("order.slot");
        result.addRelatedTable("asset");
        result.addRelatedTable("documents");
        result.addRelatedTable("budget");
        result.addRelatedTable("workLog");
        result.addRelatedTable("client");
        result.addRelatedTable("userAssignedMain");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let workOrder = this.graphDataMainType;
        const t = translate;

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.graphDataMainType.code + ' - ' + t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}

                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={workOrder} getModelQuery={() => this.getModelQuery()}/>
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.graphDataMainType.code + ' - ' + t('Time zone in') + " " + (appState.typifiedState.getValue(appState.loginState.user?.timeZone) || "")}
                        >
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={workOrder} getModelQuery={() => this.getModelQuery()}/>
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder()}
                                active={"Histórico"}
                            />
                            }
                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()} />
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(WorkOrderHistory)
