import AbstractModel from "./AbstractModel";


export default class TenantModel extends AbstractModel {

    code;
    title;
    tenantId;

    nameMainType = "tenant";
    graphFindByIdOperation = "tenant";
    graphFindOperation = "tenantsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "code": "",
            "title": "",
            "tenantId": ""
        };
        return result;
    }
};




















