import AbstractModel from "./AbstractModel";
import DynamicFieldsModel from "./DynamicFieldsModel";

export default class DynamicGroupModel extends AbstractModel {

    static ORDER = "ORDER";
    static WORK_ORDER = "WORK_ORDER";
    static ASSET = "ASSET";
    static CLIENT = "CLIENT";

    name;
    code;
    relatedModels;

    nameMainType = "dynamicGroup";
    graphFindByIdOperation = "dynamicGroup";
    graphFindOperation = "dynamicGroupsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "code": "",
            "name": "",
            "relatedModels": "",
        };
        this.addRelatedTableGeneric(result, "dynamicField", "Relation", DynamicFieldsModel);
        return result;
    }

}
