import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';

@observer
export default class VsfModalBootstrap extends Component {

    constructor(props) {
        super(props);
    }

    // sizes : 'sm' | 'lg','xl'
    closeModal() {
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        }
    }

    render() {
        return (
            <Modal
                show={this.props.isOpen}
                onHide={() => this.closeModal()}
                size={this.props.size}
                dialogClassName={this.props.dialogClassName}
                animation={this.props.animation}
            >
                <ModalHeader closeButton>
                    <ModalTitle>
                        {this.props.title}
                        {this.props.titleBlock && this.props.titleBlock()}
                    </ModalTitle>
                </ModalHeader>
                <ModalBody className="show-grid">
                    {this.props.children}
                </ModalBody>
            </Modal>
        );
    }
}
