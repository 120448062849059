import AbstractModel from './AbstractModel'
import OriginModel from './OriginModel'

export default class TypifiedValueModel extends AbstractModel {
    static CODE_ORDER_STATUS = "orderStatus";
    static CODE_DIAGNOSIS = "diagnosis";
    static CODE_ASSET_TYPE = "assetType";
    static CODE_ASSET_SUBTYPE = "assetType";
    static CODE_ORDER_ORIGIN = "orderOrigin";
    static CODE_PLANOGRAM_TASTE = "planogramQualityTaste";
    static CODE_PLANOGRAM_PRODUCT = "planogramProduct";
    static CODE_PLANOGRAM_CAN = "planogramCan";
    static CODE_SYMPTHOM = "symptom";
    static CODE_WORKORDER_STATUS = "workOrderStatus";
    static CODE_QUALITY = "quality";
    static CODE_WORK_ORDER_TYPE = "workOrderType";
    static CODE_SUBTYPE = "subType";
    static CODE_INTERVENTION_CATEGORY = "interventionCategory";
    static CODE_CAUSES_PAUSE_JOURNEY = "causesPauseJourney";
    static CODE_ZONE = "zone";
    static CODE_MODEL = "model";
    static CODE_RELATIONSHIP = "relationship";
    static CODE_CHAINS_POS = "chainPos";
    static CODE_USER_ROLE = "rolUser";
    static CODE_ARTICLE_FAMILY = "articleFamily";
    static CODE_ARTICLE_TYPE = "articleType";
    static CODE_ARTICLE_STATUS = "statusArticles";
    static CODE_ARTICLE_SUBFAMILY = "articleSubfamily";
    static CODE_APP_TASK = "appTask";
    static CODE_CAUSES_PAUSE = "causesPause";
    static CODE_EMPLOIMENTS = "emploiments";
    static CODE_EMPLOYEE_CAPACITATION = "employeeCapacitation";
    static CODE_LOCALES = "locales";
    static CODE_CONTRACT_CATEGORY = "contractCategory";
    static CODE_CONTRACT_TYPE = "contractType";
    static CODE_CONTRACT_PAYMENT_TYPE = "contractPaymentType";
    static CODE_CONTRACT_PAYMENT_METHOD = "contractPaymentMethod";
    static CODE_CONTRACT_BILL = "contractBill";
    static CODE_ASSET_STATUS = "statusAssets";
    static CODE_TIME_ZONES = "timeZones";
    static CODE_VEHICLES = "vehicles";
    static CODE_DONT_SIGN = "dontSignReasons";
    static CODE_ORDER_FROM = "orderFrom";
    static CODE_RETURN_STATUS = "returnStatus"


    id;
    type;
    code;
    value;
    essential;
    originId;
    icon;
    image;
    color;
    config;
    description;
    codeErp;

    nameMainType = "typifiedValue";
    graphFindByIdOperation = "typifiedValue";
    graphFindOperation = "typifiedValuesConnection";

    newModel() {
        return new TypifiedValueModel();
    }

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "type": "",
            "code": "",
            "color": "",
            "originId": "",
            "image": "",
            "value": "",
            "description": "",
            "config": "",
            "essential": { type: "BoolField", onlyRead: true },
            "parentId": "",
            "icon": { label: "Icono" },
            "codeErp": "",
        };
        this.addRelatedTableGeneric(result, "originModel", "RelationOne", OriginModel);
        return result;
    }

    /**
     * Dado un tipo de maestro obtiene el valor a mostrar
     * @param type
     * @returns {*}
     */
    getTextFromType(type, t) {
        let tipos = {
            [TypifiedValueModel.CODE_WORK_ORDER_TYPE]: t("Tipos de Orden de Trabajo "),
            [TypifiedValueModel.CODE_ASSET_TYPE]: t("Tipos de Equipos "),
            [TypifiedValueModel.CODE_SYMPTHOM]: t("Síntomas de Averias"),
            [TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION]: t("Capacitaciones "),
            [TypifiedValueModel.CODE_ZONE]: t("Zonas"),
            [TypifiedValueModel.CODE_CHAINS_POS]: t("Grupo"),
            [TypifiedValueModel.CODE_RELATIONSHIP]: t("Relaciones de contactos"),
            [TypifiedValueModel.CODE_DONT_SIGN]: t("Motivos de no firma"),
            [TypifiedValueModel.CODE_DIAGNOSIS]: t("Diagnosis No_CC"),
            [TypifiedValueModel.CODE_APP_TASK]: t("Tareas No_CC"),
            [TypifiedValueModel.CODE_ORDER_ORIGIN]: t("Integracion"),
            [TypifiedValueModel.CODE_LOCALES]: t("Idioma"),
            [TypifiedValueModel.CODE_CAUSES_PAUSE]: t("Motivos de devolución de la OT"),
            [TypifiedValueModel.CODE_CAUSES_PAUSE_JOURNEY]: t("Causas de pausar jornada"),
            [TypifiedValueModel.CODE_USER_ROLE]: t("Roles"),
            [TypifiedValueModel.CODE_TIME_ZONES]: t("Zona horaria"),
            [TypifiedValueModel.CODE_WORKORDER_STATUS]: t("Estados de la OT"),
            [TypifiedValueModel.CODE_ASSET_STATUS]: t("Estados del equipo"),
            [TypifiedValueModel.CODE_ORDER_STATUS]: t("Estados del pedido"),
            [TypifiedValueModel.CODE_ORDER_FROM]: t("Origen del pedido"),
            [TypifiedValueModel.CODE_RETURN_STATUS]: t("Estado de la devolución"),
            [TypifiedValueModel.CODE_EMPLOIMENTS]: t("Puesto de trabajo"),
            [TypifiedValueModel.CODE_CONTRACT_CATEGORY]: t("Categoría de contrato"),
            [TypifiedValueModel.CODE_CONTRACT_TYPE]: t("Tipo de contrato"),
            [TypifiedValueModel.CODE_CONTRACT_PAYMENT_TYPE]: t("Tipo de pago de contrato"),
            [TypifiedValueModel.CODE_CONTRACT_PAYMENT_METHOD]: t("Forma de pago de contrato"),
            [TypifiedValueModel.CODE_CONTRACT_BILL]: t("Factura de contrato"),
            [TypifiedValueModel.CODE_PLANOGRAM_PRODUCT]: t("Planograma productos"),
            [TypifiedValueModel.CODE_PLANOGRAM_TASTE]: t("Planograma Sabores"),
            [TypifiedValueModel.CODE_PLANOGRAM_CAN]: t("Planograma Envase"),
        };
        let result = tipos[type];
        return result;
    }

    renderListaMaestros(t) {
        return (
            [
                { title: t("Tipos de Orden de Trabajo "), url: TypifiedValueModel.CODE_WORK_ORDER_TYPE },
                { title: t("Tipos de Equipos "), url: TypifiedValueModel.CODE_ASSET_TYPE },
                { title: t("Síntomas de Averias "), url: TypifiedValueModel.CODE_SYMPTHOM },
                { title: t("Grupo"), url: TypifiedValueModel.CODE_CHAINS_POS },
                { title: t("Capacitaciones "), url: TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION },
                { title: t("Puesto de trabajo "), url: TypifiedValueModel.CODE_EMPLOIMENTS },
                { title: t("Zonas"), url: TypifiedValueModel.CODE_ZONE },
                { title: t("Relaciones de contactos"), url: TypifiedValueModel.CODE_RELATIONSHIP },
                { title: t("Motivos de no firma"), url: TypifiedValueModel.CODE_DONT_SIGN },
                { title: t("Causas de pausar jornada"), url: TypifiedValueModel.CODE_CAUSES_PAUSE_JOURNEY },
                { title: t("Diagnosis No_CC"), url: TypifiedValueModel.CODE_DIAGNOSIS },
                { title: t("Tareas No_CC"), url: TypifiedValueModel.CODE_APP_TASK },
                { title: t("Motivos de devolución de la OT"), url: TypifiedValueModel.CODE_CAUSES_PAUSE },
                { title: t("Idioma"), url: TypifiedValueModel.CODE_LOCALES },
                { title: t("Roles"), url: TypifiedValueModel.CODE_USER_ROLE },
                { title: t("Zona horaria"), url: TypifiedValueModel.CODE_TIME_ZONES },
                { title: t("Estados de la OT"), url: TypifiedValueModel.CODE_WORKORDER_STATUS },
                { title: t("Estados del equipo"), url: TypifiedValueModel.CODE_ASSET_STATUS },
                { title: t("Estados del pedido"), url: TypifiedValueModel.CODE_ORDER_STATUS },
                { title: t("Origen del pedido"), url: TypifiedValueModel.CODE_ORDER_FROM },
                { title: t("Estado de la devolución"), url: TypifiedValueModel.CODE_RETURN_STATUS },
                { title: t("Categoría de contrato"), url: TypifiedValueModel.CODE_CONTRACT_CATEGORY },
                { title: t("Tipo de contrato"), url: TypifiedValueModel.CODE_CONTRACT_TYPE },
                { title: t("Tipo de pago de contrato"), url: TypifiedValueModel.CODE_CONTRACT_PAYMENT_TYPE },
                { title: t("Forma de pago de contrato"), url: TypifiedValueModel.CODE_CONTRACT_PAYMENT_METHOD },
                { title: t("Factura de contrato"), url: TypifiedValueModel.CODE_CONTRACT_BILL },
                { title: t("Planograma productos"), url: TypifiedValueModel.CODE_PLANOGRAM_PRODUCT },
                { title: t("Planograma Sabores"), url: TypifiedValueModel.CODE_PLANOGRAM_TASTE },
                { title: t("Planograma Envase"), url: TypifiedValueModel.CODE_PLANOGRAM_CAN },
            ])
    }

    renderListaMaestrosOrigin(t) {
        return (
            [
                { title: t("Tipos de Orden de Trabajo "), url: TypifiedValueModel.CODE_WORK_ORDER_TYPE },
                { title: t("Síntomas de Averias "), url: TypifiedValueModel.CODE_SYMPTHOM },
                { title: t("Motivos de no firma"), url: TypifiedValueModel.CODE_DONT_SIGN },
                { title: t("Diagnosis No_CC"), url: TypifiedValueModel.CODE_DIAGNOSIS },
                { title: t("Tareas No_CC"), url: TypifiedValueModel.CODE_APP_TASK },
            ])
    }

    checkValueType(type, campo) {
        let result = false;
        let tipos = {
            [TypifiedValueModel.CODE_WORK_ORDER_TYPE]: { color: true, checkList: true },
            [TypifiedValueModel.CODE_ORDER_STATUS]: { color: true },
            [TypifiedValueModel.CODE_ZONE]: { color: true },
            [TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION]: { icon: true, color: true },
            [TypifiedValueModel.CODE_LOCALES]: { noCreatable: true },
            [TypifiedValueModel.CODE_USER_ROLE]: { noCreatable: true },
            [TypifiedValueModel.CODE_TIME_ZONES]: { noCreatable: true },
            [TypifiedValueModel.CODE_WORKORDER_STATUS]: { noCreatable: true },
            [TypifiedValueModel.CODE_ORDER_STATUS]: { noCreatable: true },
            [TypifiedValueModel.CODE_ASSET_TYPE]: { planogram: true },
        };
        if (tipos[type]?.[campo] != null) {
            result = tipos[type]?.[campo];
        }
        return result;
    }


}

