import { computed, observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import AssetModelModel from "../models/AssetModelModel";
import util from "../util/Util";

class AssetModelState extends BaseModelState {

    @observable assetModels = [];

    async getAssetModels() {
        let assetModelQuery = new AssetModelModel();
        this.assetModels = await assetModelQuery.find();
    }

    @computed get getManufacturerForSelect() {
        let result = [];
        let copia = {};
        for (let assetModel of this.assetModels) {
            if (copia[assetModel.id] == null) {
                copia[assetModel.id] = "";
                result.push({
                    value: assetModel.manufacturer,
                    label: assetModel.manufacturer + " " + (assetModel.model || "")
                });
            }
        }
        return result;
    }

    @computed get getOnlyManufacturerForSelect() {
        let result = [];
        let copia = {};
        for (let assetModel of this.assetModels) {
            if (copia[assetModel.manufacturer] == null) {
                copia[assetModel.manufacturer] = "";
                result.push({
                    value: (assetModel.manufacturer || " "),
                    label: (assetModel.manufacturer || " ") });
            }
        }
        return result;
    }

    @computed get getModelForSelect() {
        let result = [];
        let copia = {};
        for (let assetModel of this.assetModels) {
            if (util.hasValue(assetModel.model) && copia[assetModel.model] == null) {
                copia[assetModel.model] = "";
                result.push({
                    value: assetModel.model,
                    label: assetModel.model
                });
            }
        }
        return result;
    }

}

export default AssetModelState;
