import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import { computed, observable, toJS } from 'mobx'
import Links from '../../util/Links'
import appState from '../../state/AppState'
import util from '../../util/Util'
import AppLogger from '../../util/AppLogger'
import GraphException from '../../network/GraphException'
import SlotModel from '../../models/SlotModel'
import ModalBaseRow from '../../components/modals/ModalBaseRow'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'

@observer
class BaseEditableRow extends Component {

    static propTypes = {
        setHasSomeRowEditing: PropTypes.func, //Función que se llama cuando el elemento editable actual pasa a ser no editable
        hasSomeRowEditing: PropTypes.bool, //Algún elemento se está editando actualmente. No tiene por qué ser este elemento. Hace que se "nublen" los botones de "editar"
    }

    static defaultProps = {
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    }
    previousRowForDiscard = null
    appStateKeyAfterSave = 'genericFormRowState' //cambiar-en-subclase si pueden convivir 2 listados
    @observable gqlErrors = []
    @observable gqlWarnings = []

    /**
     * crear el elemento foreingKeyField
     * this.foreingKeyfield = "clientId";
     * @param props
     */
    constructor (props) {
        super(props)
        this.state = {
            warningError: [],
        }
        this.eliminar = true
    }

    /**
     * Get del estado para poder usarlo en eliminarTabla
     * Cambiar por el estado que se quiera conseguir
     * return appState.contactState.contacts;
     * @returns {Array}
     */
    @computed
    get mobxListado () {
        return null
    }

    ifPathStartsWith (pathMatch) {
        let result = false
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true
        }
        return result
    }

    setErrors (errors) {
        this.gqlErrors = errors
    }

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController () {
        this.controller = new AbortController()
        this.signal = this.controller.signal
    }

    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch () {
        if (this.controller) {
            this.controller.abort()
        }
    }

    setMobxListado (newValue) {
    }

    /**
     * Cambiar por el modelo que se quiera conseguir
     * return  new ContactModel();
     */
    getModelTable () {

    }

    handleAutosuggestSelection (fieldName, suggestSelected) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row)
        }
        let eventObj = {
            target: {
                name: fieldName,
                value: suggestSelected
            }
        }
        this.handleInputChange(eventObj)
    };

    onEditRow () {
        this.props.row.isEditing = true
        this.props.setHasSomeRowEditing(true)
    };

    async onDeleteRow () {
        let modelo = this.getModelTable()
        modelo.hidrate(this.props.row)
        appState.loadingBarState.start()
        try {
            if (util.hasValue(modelo.id)) {
                await modelo.remove()
            }
            await this.rowDidDelete(this.props.row)
            this.props.setHasSomeRowEditing(false)
        } catch (e) {
            let graphExceptionModel = new GraphException()
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e)
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors
            if (errors.length > 0) {
                this.gqlErrors = errors
                appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = errors
                appState[this.appStateKeyAfterSave].mutationError = 1
            }
        }
        appState.loadingBarState.finalize()
    }

    onDiscardRow () {
        appState[this.appStateKeyAfterSave].mutationError = 0
        appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = []
        if (this.props.row.id == null) {
            this.rowDidDelete(this.props.row)
        } else {
            if (this.previousRowForDiscard != null) {
                for (let i = 0; i < this.mobxListado.length; i++) {
                    let row = this.mobxListado[i]
                    if (row.id === this.props.row.id) {
                        this.mobxListado[i] = this.previousRowForDiscard
                    }
                }
            }
        }
        this.props.row.isEditing = false
        this.props.setHasSomeRowEditing(false)
        this.previousRowForDiscard = null
    };

    handleInputChange (event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row)
        }
        this.props.row[event.target.name] = event.target.value
        if (this.props.onChangeRow != null) {
            this.props.onChangeRow(this.props.rowIndex, this.props.row)
        }

    };

    getFormId () {
        let result = this.props[this.foreingKeyfield]
        if (result == null) {
            let links = new Links()
            result = links.getFormId(this.props)
        }
        return result
    }

    getGraphErrorsFromStatus () {
        return new GraphException().getGraphErrorsFromStatus(appState[this.appStateKeyAfterSave].mutationGraphQlResponse)
    }

    renderDefaults () {
        if (this.props.row.scheduledDuration == null || this.props.row.scheduledDuration == '') {
            this.props.row.scheduledDuration = 1
        }
        if (this.props.row.quantity == null || this.props.row.quantity == '') {
            this.props.row.quantity = 1
        }
        if (this.props.row.workOrderIds == null || this.props.row.workOrderIds == '') {
            this.props.row.workOrderIds = '-1'
        }
        if (this.props.row.price == null || this.props.row.price == '') {
            this.props.row.price = '1'
        }
    }

    async rowDidDelete (modelo) {
        let arrCopy = toJS(this.mobxListado)
        arrCopy.splice(this.props.rowIndex, 1)
        this.log({ arrCopy })
        this.setMobxListado(arrCopy)
    }

    async rowDidSave (modelo) {
    }

    /**
     * Guarda e en base de datos
     * @returns {Promise<void>}
     */
    async onSaveRow () {
        this.renderDefaults()
        let modelo = this.getModelTable()
        modelo.hidrate(this.props.row)
        if (this.props.fromWorkOrder && this.foreingKeyfield === 'orderId') {
            modelo[this.foreingKeyfield] = this.props.orderId
        } else if (this.props.fromSlots && this.foreingKeyfield === 'orderId') {
            modelo[this.foreingKeyfield] = this.props.orderId
        } else {
            modelo[this.foreingKeyfield] = this.getFormId()
        }
        appState.loadingBarState.start()
        appState[this.appStateKeyAfterSave].mutationError = 0
        appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = []
        try {
            this.log({
                onSaveRow: 1,
                modelo,
                props: this.props,
                foreingKeyfield: this.foreingKeyfield
            })
            let previousId = this.props.row.id
            await modelo.persist()
            this.setState({ loadingSave: false })
            this.props.row.id = modelo.id
            this.props.row.isEditing = false
            this.props.row.editable = modelo.editable
            this.props.row.deletable = modelo.deletable
            this.previousRowForDiscard = null
            this.props.setHasSomeRowEditing(false)
            await this.rowDidSave(modelo, previousId)
        } catch (e) {
            this.setState({ loadingSave: false })
            let graphExceptionModel = new GraphException()
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e)
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors
            let warnings = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).warnings
            if (errors.length > 0) {
                this.gqlErrors = errors
                appState[this.appStateKeyAfterSave].mutationGraphQlResponse.errors = errors
                appState[this.appStateKeyAfterSave].mutationError = 1
            } else {
                this.gqlWarnings = warnings
            }
            this.log({
                warnings,
                errors
            })
        }
        appState.loadingBarState.finalize()
        this.setState({ loadingSave: false })
    };

    renderSupport (readOnly) {

    }

    onOpenSupport (slot) {
        let newModel = this.getModelTable().toPlainObject()
        newModel.orderId = slot.orderId
        newModel.slotUserRole = SlotModel.SUPPORT
        newModel.scheduledDuration = slot.scheduledDuration
        newModel.scheduledTime = slot.scheduledTime
        newModel.scheduledDate = slot.scheduledDate
        newModel.workOrderIds = slot.workOrderIds
        newModel.assignedSlotMainId = slot.id
        newModel.isEditing = true
        this.mobxListado.splice((this.props.rowIndex + 1), 0, newModel)
        this.props.setHasSomeRowEditing(true)
    }

    renderEditBlock (isDisabled) {
        let rowModel = this.props.row
        let editable = rowModel.editable
        if (rowModel.isEditing) {
            editable = true
        }
        return (
            <div className="form-group">
                <div className="remove-add">
                    <SpinnerClipLoaderInPage loading={this.state.loadingSave} size={25}>

                        {editable &&
                        this.renderSupport()
                        }

                        {editable &&
                        <div onClick={() => {
                            if ((rowModel.isEditing || !this.props.hasSomeRowEditing) && !isDisabled)
                                if (rowModel.isEditing) {
                                    if (!this.editButtonsDisabled()) {
                                        this.setState({ loadingSave: true })
                                        this.onSaveRow()
                                    }
                                } else {
                                    this.onEditRow()
                                }
                        }} title="Guardar"
                        className={((!rowModel.isEditing && this.props.hasSomeRowEditing) || isDisabled)
                            ? 'edit inactive' : 'edit'}>
                            <span className={rowModel.isEditing ? 'fas fa-save' : 'fas fa-edit'} />

                        </div>
                        }
                        {rowModel.deletable &&

                        <>
                            {(!this.props.lastRow || (rowModel.isEditing && this.props.hasSomeRowEditing)) &&
                            <div onClick={() => {
                                if ((rowModel.isEditing || !this.props.hasSomeRowEditing) && !isDisabled) {
                                    if (rowModel.isEditing) {
                                        this.onDiscardRow()
                                    } else {
                                        this.onDeleteRow()
                                    }
                                }
                            }} title="Eliminar"
                            className={((!rowModel.isEditing && this.props.hasSomeRowEditing) || isDisabled)
                                ? 'remove inactive' : 'remove'}>
                                <span className={rowModel.isEditing ? 'fas fa-times' : 'fas fa-trash-alt'} />
                            </div>
                            }</>
                        }
                    </SpinnerClipLoaderInPage>
                </div>
            </div>
        )
    }

    editButtonsDisabled () {
        return false
    }

    /**
     * Para implementar el modo edicion en un modal, hay que cambiar los renders, crear la funcion renderCamposModal como en DynamicsFieldsRow y
     * cambiar la variable this.modalBaseRow=true en el constructor
     * @returns {*}
     */
    renderModals () {
        return (
            <ModalBaseRow
                row={this.props.row} closeModal={() => this.onDiscardRow()} openModal={this.props.row.isEditing}
                title={this.props.title}
                renderCamposModal={() => this.renderCamposModal()}
            />
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default BaseEditableRow
