import React from 'react';
import Autosuggest from 'react-autosuggest';
import FormInputWrapper from "./FormInputWrapper";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import appState from '../../state/AppState'
import debounce from '../status/debounce-decorator';
import BaseFieldComponent from "./BaseFieldComponent";
import ReactHtmlParser from 'react-html-parser';
import MezclarObj from "../../util/MezclarObj";

/* Estilos que se le puede pasar para cambiar la clase
{
  container:                'react-autosuggest__container',
  containerOpen:            'react-autosuggest__container--open',
  input:                    'react-autosuggest__input',
  inputOpen:                'react-autosuggest__input--open',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
}
 */

class AutoSuggestComponent extends BaseFieldComponent {
    static defaultProps = MezclarObj.assign(BaseFieldComponent.defaultProps, {
        minWidthAutosuggest: '100%',
        lenghtAutosuggest: 2,
    });

    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || "",
            isLoading: false,
            suggestions: []
        };
    };



    componentWillReceiveProps(nextProps) {
        if (util.hasValue(nextProps.value)) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    /**
     * Lanza el proceso de cargar suggestions
     */
    @debounce(350)
    async loadSuggestions(value) {

        this.setState({
            isLoading: true
        });

        await this.props.loadSuggestions(value).then((newSuggestions) => {
            this.setState({
                isLoading: false,
                suggestions: newSuggestions
            });
            return true;
        });

    }

    /**
     * Cambia el valor del autosuggest
     * @param event
     * @param newValue
     */
    onChange = (event, { newValue }) => {
        this.props.onChange(newValue);
        this.setState({
            value: newValue
        });
    };

    /**
     * Se lanza cuando seleccionamos una suggestion
     * @param event
     * @param suggestion
     * @param suggestionValue
     * @param suggestionIndex
     * @param sectionIndex
     * @param method
     */
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.props.onChange(suggestion.value);
    };

    /**
     * Recibe un valor, y a partir de ahi recarga las suggestions
     *
     * @param value
     */
    onSuggestionsFetchRequested = ({ value }) => {
        if (value.length > this.props.lenghtAutosuggest) {
            this.loadSuggestions(value);
        } else {
            this.setState({
                suggestions: []
            });
        }
    };

    /**
     * Resetea el array de suggestions
     */
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    /**
     * Obtiene el nombre de la suggestion para mostrar
     */
    getSuggestionValue(suggestion) {
        return suggestion.label;
    }

    /**
     * Renderiza la suggestion en la lista del autosuggest
     */
    renderSuggestion(suggestion) {
        let coincidencia = '<strong>' + this.state.value?.toUpperCase() + '</strong>';
        let result = suggestion.label.replace(this.state.value?.toUpperCase(), coincidencia);
        return (
            <span>{ReactHtmlParser(result)}</span>
        );
    }

    /**
     * Limpia el value del autosuggest
     */
    clearAutosuggest() {
        this.setState({
            value: ""
        });
        appState.layoutState.formWithoutChanges = false;
        this.props.onChange("");
    }

    renderSuggestionsContainer({ containerProps, children, query }) {
        // this.log({containerProps,children,query})
        return (
            <div {...containerProps} style={{ minWidth: this.props.minWidthAutosuggest }}>
                {children}
            </div>
        );
    }

    renderInputComponent(inputProps) {
        let classNameInput = this.getClassNameInput();
        // this.log({ inputProps });
        return (
            <div>
                <input {...inputProps} className={classNameInput} />
                {util.hasValue(this.state.value) &&
                <div aria-hidden="true"
                     className="react-select__indicator react-select__clear-indicator css-tlfecz-indicatorContainer"
                     onClick={() => this.clearAutosuggest()} style={{
                    position: 'absolute',
                    top: '3px',
                    right: '10px'
                }} title="Clear value">
                    <span className="Select-clear"><span className="fa fa-times" /></span>
                </div>
                }

            </div>
        )
    };

    render() {
        const { value, suggestions } = this.state;
        let classNameInput = this.getClassNameInput();
        const inputProps = {
            placeholder: this.props.placeholder,
            value: util.hasValue(value) ? value : "",
            onChange: this.onChange
        };
        let readOnly = this.checkReadOnly();
        return (
            <>
                <FormInputWrapper
                    classGroup={this.props.classGroup}
                    classInputType={"Select2Component"}
                    name={this.props.name}
                    postfix={this.props.postfix}
                    prefix={this.props.prefix}
                    errors={this.props.errors}
                    title={this.props.title} formGroup={this.props.formGroup}
                    required={this.props.required}
                    isClearable={this.props.isClearable}
                    info={this.props.info}
                    relatedInfo={this.props.relatedInfo}
                    forceMedia={this.props.forceMedia}
                >
                    {readOnly ?
                        <div>
                            <input
                                value={this.props.value}
                                autoComplete="new-password"
                                className={classNameInput}
                                readOnly={true}
                            />
                        </div>
                        :
                        <div>
                            <Autosuggest
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={(e) => this.renderSuggestion(e)}
                                onSuggestionSelected={this.onSuggestionSelected}
                                renderSuggestionsContainer={(e) => this.renderSuggestionsContainer(e)}
                                inputProps={inputProps} renderInputComponent={(e) => this.renderInputComponent(e)}
                            />
                        </div>
                    }
                </FormInputWrapper>
            </>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default AutoSuggestComponent;
