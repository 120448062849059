import React, { Component } from 'react';
import appState from "../../../state/AppState";
import { observer } from 'mobx-react';
import WorkInMonthUnitDrag from "./WorkInMonthUnitDrag";
import ScheduleVisitModel from "../../../models/ScheduleVisitModel";
import PropTypes from 'prop-types';
import AppLogger from "../../../util/AppLogger";

const Types = {
    ITEM: 'workInMonthUnit'
}


const itemTarget = {
    hover(props, monitor, component) {
        if (!component) {
            return null
        }

        const dragIndex = monitor.getItem().name;
        const hoverIndex = props.name;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return
        }
        // dragIndex puede ser 5 y hover 1. Debo poner la posición 5 antes de la 1
        let preventOrders = appState.preventOrders;
        // Creo un elemento "provisional". Si al final se cancela el movimiento debería quitar el elemento "ghost"
        let currentElementMoving = null;
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
                currentElementMoving = preventOrders[i];
                currentElementMoving.date = "2019prov";
                currentElementMoving.type = "ghost-moving";
            }
        }
        let newPreventOrders = [];
        for (let i = 0; i < preventOrders.length; i++) {
            if (preventOrders[i].name == dragIndex) {
            } else {
                if (preventOrders[i].name == hoverIndex) {
                    newPreventOrders.push(currentElementMoving);
                }
                newPreventOrders.push(preventOrders[i]);
            }
        }
        appState.preventOrders = newPreventOrders;
    },
    drop(props, monitor, component) {
    }
}

function collectTarget(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class WorkInMonthUnit extends Component {
    static propTypes = {
        //Objeto que contiene el mes (code) y el nombre de la visita: {label:"Ene", year:19, code:'201901'},
        name: PropTypes.string,
        //Elemento que identifica el tipo de visita.
        visitType: PropTypes.instanceOf(ScheduleVisitModel),
        // Elemento que identifica a un elemento ya planificado en un mes. Tenemos el OrderModel asociado
        creatable: PropTypes.bool,
    };
    static defaultProps = {
        creatable: true,
    };

    checkDisabled() {
        let disabled = false;
        let order = this.props.order;
        if (!this.props.creatable || (order && !order?.editable)) {
            disabled = true;
        }
        return disabled;
    }

    render() {
        const { connectDropTarget } = this.props;
        let disabled = this.checkDisabled();

        if (!disabled && connectDropTarget) {
            return connectDropTarget(this.renderImpl());
        } else {
            return this.renderImpl();
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    renderImpl() {
        let disabled = this.checkDisabled();
        let orderId = "";
        if (this.props.order) {
            orderId = this.props.order.id;
        }
        let claseSlot = "drag-hours2 ";
        if (this.props.order && (appState.layoutState.backgroundScheduled == this.props.order.orderPreventiveId ||
            appState.layoutState.backgroundScheduled == this.props.order.id)) {
            claseSlot += " seleccionada "
        }
        return (
            <div className={claseSlot} style={{ width: 60 }} data-order-id={orderId}>
                <WorkInMonthUnitDrag {...this.props} disabled={disabled} />
            </div>
        )
    }
}

//export default DropTarget(Types.ITEM, itemTarget, collectTarget)(WorkInMonthUnit)
export default WorkInMonthUnit
