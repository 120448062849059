import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormButtons from "../../components/FormButtons";
import translate from "../../translator/translate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import Select2Component from "../../components/fields/Select2Component";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import MoreButtons from "../../components/MoreButtons";
import util from '../../util/Util';
import InfoBlock from "../../components/InfoBlock";
import VsfButtonNew from "../../components/VsfButtonNew";
import MapView from "../../components/modals/MapView";
import VsfModal from "../../components/modals/VsfModal";
import GqlErrors from "../../components/status/GqlErrors";
import ClientModel from "../../models/ClientModel";
import GqlWarnings from "../../components/status/GqlWarnings";
import ClientActions from "./ClientActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import ValidatesFormTable from "../../components/ValidatesFormTable";

@observer
class ClientPosForm extends BaseForm {

    nameMainType = "client";

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        appState.changesWithValidationsState.formValidations = false;
        appState.changesWithValidationsState.imageValidations = false;
        this.state = {
            errorFormulario: false,
            modalMap: false,
            modalGps: false,
            loading: true,
            client: {}
        }
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        if (!this.props.fromRightModal) {
            appState.clientState.isPos = this.graphDataMainType.isPos;
            appState.clientState.isClient = !this.graphDataMainType.isPos;
        }
        if (!util.hasValue(this.graphDataMainType.id)) {
            this.updateInput("isPos", true);
        }
        await appState.articleState.loadDeslocalizedArticles();
        await appState.clientCacheState.loadClientCacheClients();
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        this.setState({ loading: false })
    }

    getModelQuery() {
        return new ClientModel();
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        this.graphDataMainType.isPos = model.isPos;
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        this.validatesFormRef?.loadTable();

    }

    /**
     * Metodo que tras actualizar los valores del typified, actualiza el select
     *
     * @param e
     */
    async handleFormSubmitClientValue(e) {
        await this.handleFormSubmit(e);
        this.reloadClientValues();
    }

    /**
     * Actualiza el select jerárquico
     */
    reloadClientValues() {
        appState.clientCacheState.loadClientCache();
    }

    onChangeInput(name, value, relation) {
        if (name === "storeId") {
            if (util.hasValue(relation?.id)) {
                this.changeKey = value;
                this.graphDataMainType.store = relation
            }
        }
        this.updateInput(name, value)
    }

    renderImpl() {
        let id = this.getFormId();
        const t = translate;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);

        let client = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (client == null) {
            client = {};
        }
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmitClientValue(e)}>

                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                                     title={this.getPageTitle()}
                                                     subtitle={this.getPageSubtitle()}
                                                     mode={"edit"}
                                                     onCloseModal={() => this.onCloseModal()}
                                >
                                    {util.hasValue(this.getFormId()) &&
                                    <MoreButtons>
                                        {!appState.userState.isUserRoleClient() &&
                                        <ClientActions fromRightModal={this.props.fromRightModal}
                                                       getModelQuery={() => this.getModelQuery()} setErrors={(errors) => this.setErrors(errors)} model={client} />
                                        }
                                    </MoreButtons>
                                    }
                                </PageTitleRightModal>
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataPosClient()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}>
                                {util.hasValue(this.getFormId()) &&
                                <MoreButtons
                                    onReloadData={() => this.reloadData()}>
                                    <ClientActions fromRightModal={this.props.fromRightModal}
                                                   getModelQuery={() => this.getModelQuery()} setErrors={(errors) => this.setErrors(errors)} model={client} />
                                </MoreButtons>
                                }
                            </PageTitle>
                        }
                        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                        <GqlWarnings warnings={this.gqlWarnings} onSaveWarning={() => this.saveWithWarnings()}
                                     key={this.gqlWarnings}
                                     setWarnings={(warnings) => this.setWarnings(warnings)} />
                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className={modalClasses.body}>
                                    {!this.props.fromRightModal &&
                                    <TabsComponent
                                        id={this.graphDataMainType.id}
                                        tabs={this.tabsData.getDataPosClient()}
                                        active="Datos Generales"
                                        fromRightModal={this.props.fromRightModal}

                                    />
                                    }
                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking}
                                    />
                                    <ValidatesFormTable
                                        onRef={ref => (this.validatesFormRef = ref)}
                                        model={this.getModelQuery()}
                                        onChangeInput={(name, value, relation) => this.onChangeInput(name, value, relation)}
                                        graphDataMainType={this.graphDataMainType}
                                        previousRowForDiscard={this.previousRowForDiscard} />

                                    <GroupOfField
                                        title={t('Datos de Establecimiento')}
                                        icon='fas fa-store'
                                    >
                                        <InputTypeComponent
                                            value={client.code}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="code" required
                                            info={t("Este campo debe ser un código único para cada Cliente")}
                                            title={t("Número de establecimiento")}
                                            editable={this.editable}
                                            placeholder={t("Número de establecimiento")}
                                            classGroup={"col-12 col-md-6 col-xl-6"}
                                            type={"text"}
                                            dataCy={"numeroCliente"}
                                            instanceOf={"form-control"}
                                            validate={"alphanumeric"}
                                            errors={errorsMapped.code}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.name}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="name"
                                            title={t("Nombre")}
                                            dataCy={"nombreEstablecimiento"}
                                            placeholder="Nombre"
                                            classGroup={"col-12 col-md-6 col-xl-6"}
                                            editable={this.editable}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            readOnly={false}
                                            errors={errorsMapped.name}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.posAddress}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posAddress"}
                                            title={t("Dirección Establecimiento")}
                                            dataCy={"direccionEstablecimiento"}
                                            editable={this.editable}
                                            placeholder={t("Dirección Establecimiento")}
                                            classGroup={"col-12"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posAddress}
                                        />
                                        <Select2Component
                                            value={client.parentId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"parentId"}
                                            editable={this.editable}
                                            dataCy={"perteneceAFiscal"}
                                            title={t("Pertenece A")}
                                            classGroup={"col-12 col-md-6 col-xl-6"}
                                            options={appState.clientCacheState.clientPosCacheForSelect}
                                            errors={errorsMapped.parentId}
                                        />
                                        <Select2Component
                                            value={client.chainId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"chainId"}
                                            ordenar
                                            title={t("Grupo")}
                                            dataCy={"grupo"}
                                            classGroup={"col-12 col-md-6 col-xl-6"}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_CHAINS_POS)}
                                            errors={errorsMapped.parentId}
                                        />

                                        <InputTypeComponent
                                            value={client.posCity}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posCity"}
                                            title={t("Localidad Establecimiento")}
                                            editable={this.editable}
                                            placeholder={t("Localidad Establecimiento")}
                                            classGroup={"col-12 col-md-3"}
                                            type={"text"}
                                            dataCy={"localidadEstablecimiento"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posCity}
                                        />
                                        <InputTypeComponent
                                            value={client.posDistrict}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posDistrict"}
                                            title={t("Provincia")}
                                            placeholder={t("Provincia")}
                                            dataCy={"provinciaEstablecimiento"}
                                            classGroup={"col-12 col-md-3"}
                                            editable={this.editable}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posDistrict}
                                        />
                                        <InputTypeComponent
                                            value={client.posPostalCode}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"posPostalCode"}
                                            dataCy={"postalCodeEstablecimiento"}
                                            title={t('CP')}
                                            placeholder={"CP"}
                                            classGroup={"col-12 col-md-3"}
                                            type={"text"}
                                            required
                                            editable={this.editable}
                                            validate={"postalCode"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.posPostalCode}
                                        />

                                        <Select2Component
                                            value={client.posZoneId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"posZoneId"}
                                            title={t("Zona Establecimiento")}
                                            dataCy={"zonaEstablecimiento"}
                                            classGroup={"col-md-3"}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ZONE)}
                                            errors={errorsMapped.posZoneId}
                                        />
                                        <InputTypeComponent
                                            value={client.geoLatitude}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"geoLatitude"}
                                            editable={this.editable}
                                            title={t("Latitud")}
                                            placeholder={t("Latitud")}
                                            validate={"decimal"}
                                            dataCy={"latitudEstablecimiento"}
                                            classGroup={"col-md-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                        />
                                        <InputTypeComponent
                                            value={client.geoLongitude}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"geoLongitude"}
                                            title={t("Longitud")}
                                            dataCy={"longitudEstablecimiento"}
                                            validate={"decimal"}
                                            editable={this.editable}
                                            placeholder={t("Longitud")}
                                            classGroup={"col-md-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                        />
                                        <Select2Component
                                            value={client.localizationArticleId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"localizationArticleId"}
                                            title={t("Localización Km")}
                                            classGroup={"col-md-3"}
                                            options={appState.articleState.labelValuesDeslocalizationForSelect}
                                            errors={errorsMapped.localizationArticleId}
                                        />

                                        <VsfButtonNew
                                            onClick={() => this.setState({ modalMap: true, client })}
                                            label={t("Ver en mapa")}
                                            icon={'fas fa-map-marked-alt'}
                                            className={"btn btn--loader btn--auto"}
                                            classGroup={"col-md-3 d-flex align-items-center"}
                                        />
                                        {this.editable &&
                                        <div className="col-12 d-flex align-items-center">
                                            <img height={25} width={25}
                                                 src="/img/fileicons/not_listed_location-24px.svg"
                                                 alt="ayuda_ubicación" />
                                            <span onClick={() => this.setState({ modalGps: true })} style={{
                                                color: '#B3B3B3',
                                                cursor: 'pointer'
                                            }}>{t("¿Cómo añadir la geolocalización?")}</span>
                                        </div>
                                        }

                                    </GroupOfField>

                                    {this.state.modalMap &&
                                    <MapView
                                        title={t('Localización del Cliente')}
                                        clientModel={this.state.client} editable={this.editable}
                                        updateInputEvent={(e) => this.updateInputEvent(e)}
                                        openModal={this.state.modalMap}
                                        updateClient={true}
                                        {...this.props}
                                        closeModal={() => this.setState({ modalMap: false })}
                                    />
                                    }
                                    {this.state.modalGps &&
                                    <VsfModal
                                        isOpen={this.state.modalGps}
                                        onCloseModal={() => this.setState({ modalGps: false })}
                                        className=" center "
                                        containerCentered={true}
                                    >
                                        <div className="modal-push" style={{ maxHeight: 'none' }}>
                                            <div className="modal-head visible-text">
                                                <div className="d-flex align-items-center">
                                                    <div className="col-12 text-left">
                                                        <div className="modal-title">
                                                            <p>{t('Como añadir la localización de un cliente')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ paddingLeft: 45 }} className="modal-body-filters">
                                                <p className="m-0">Accede a:
                                                    <a style={{ color: '#0058F5' }} target="_blank"
                                                       href="https://www.google.es/maps/preview">{t('Google Maps')}</a>
                                                </p>
                                            </div>
                                            <div className="modal-body-filters" style={{
                                                maxHeight: 'none',
                                                paddingBottom: 45,
                                                paddingLeft: 45,
                                                paddingRight: 45
                                            }}>
                                                <img className="shadow" src="/img/help_map.jpg"
                                                     alt="ayuda_ubicación" />
                                            </div>
                                        </div>
                                    </VsfModal>
                                    }
                                    <FormButtons id={id} formStatus={this.graphStatus} dataCy={"guardarCliente"} />
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </form>
                </InfoBlock>
            </div>
        )
    }


}

export default withRouter(ClientPosForm);
