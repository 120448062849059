import BaseValidation from "./BaseValidation";

export default class EmailValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;
        if (this.event === "blur") {
            let regValidateEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

            if (!regValidateEmail.test(inputValue)) {
                isCorrect = false;
            }
            this.log({ event: this.event, isCorrect });
        }
        this.log({ inputValue: inputValue, args: JSON.stringify(args), isCorrect: isCorrect, event: this.event });
        return isCorrect;
    }

}
