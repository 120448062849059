import React, { Component } from 'react';
import { DragSource } from 'react-dnd'
import { observer } from 'mobx-react';
import PropsUtil from "../../../util/PropsUtil";
import { withRouter, } from "react-router-dom";
import AppLogger from "../../../util/AppLogger";
import appState from "../../../state/AppState";
import util from '../../../util/Util'

const Types = {
    ITEM: 'workInMonthUnit'
};
const itemSource = {
    beginDrag(props) {
        console.log("beginDrag");
        console.log({ props });
        return { ...props };
    },
};


function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        connectDragPreview: connect.dragPreview(),
    }
}

@observer
class WorkInMonthUnitDrag extends Component {
    render() {
        this.propsUtil = new PropsUtil(this.props);
        const { connectDragSource,connectDragPreview  } = this.props;

        if (this.props.disabled) {
            return (
                this.renderImpl()
            )
        } else {
            let connectionDragSource = connectDragSource(
                this.renderImpl()
            );
            return connectDragPreview(connectionDragSource, { offsetX: 5, offsetY: 20 });
        }

    }
    renderImpl(){
        let stateLoading = false;
        if (this.props.order) {
            if (this.props.order.loading) {
                stateLoading = true;
            }
        }
        let messageHours = '';
        if (this.props.order && util.hasValue(this.props.order.scheduledLabel)) {
            messageHours = this.props.order.scheduledLabel;
        } else {
            let messageHoursArr = [];
            if (this.props.visitType) {
                for (let scheduledVisitSlot of this.props.visitType.scheduleVisitSlots) {
                    messageHoursArr.push(scheduledVisitSlot.scheduledDuration + "h");
                }
            }
            messageHours = messageHoursArr.join("/");
        }
        return (
            <div className="drag-hours__item h-4" onClick={() => this.props.onClick(this.props.order)}
                 onMouseEnter={(e) => {
                     if (this.props.order) {
                         appState.layoutState.backgroundScheduled = this.props.order.id;
                     }
                 }} onMouseLeave={(e) => {
                if (this.props.order) {
                    appState.layoutState.backgroundScheduled = 0;
                }
            }}>
                {/*<div className="drag-hours__item h-4" onClick={e => this.onClick(e, this.props.order)}>*/}
                <div className="drag-hours__item__content">
                    {stateLoading ?
                        <div className="num-hours">
                            <i className="fa fa-spinner" />
                        </div>
                        :
                        <>
                            <div className="num-hours">
                                {messageHours}
                            </div>
                            <div className="bar-hours" />
                        </>
                    }
                </div>
            </div>
        )
    }

    onClick(e, order) {
        if (order) {
            this.propsUtil.changeUrlRequest({
                "rightModalTab": "order",
                "rightModal": "modalorderls",
                "orderId": order.id
            });
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DragSource(Types.ITEM, itemSource, collect)(withRouter(WorkInMonthUnitDrag))
