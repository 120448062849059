import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import util from "../../util/Util";


@observer
class ZoneColorList extends Component {

    static propTypes = {
        //Acción de descarga de Excel en el listado
        id: PropTypes.string,
        // Objeto con todos los campos que estamos mostrando
        row: PropTypes.object,
        // Nombre del campo a mostrar
        name: PropTypes.string,
        // Valor del campo a mostrar
        value: PropTypes.string,
        noAsignadoTexto: PropTypes.string,
    };
    static defaultProps = { noAsignadoTexto: " - " };


    render() {
        const t = translate;
        let props = this.props;
        let value = props.value;
        let color = props.row.zoneClient?.color;
        if (props.row.client?.zoneClient) {
            color = props.row.client?.zoneClient?.color;
        }
        //AppLogger.get().debug({client:toJS(props.row.client)}, this);
        return (
            <div>
                {!this.props.multiple ?
                    <div>
                        {util.hasValue(value) &&
                        <span>
                        <i style={{ color }} className={"fas fa-map-marked-alt"} />
                        </span>
                        }
                        <span style={{ marginLeft: 8 }}>
                            {value || t(this.props.noAsignadoTexto)}
                        </span>
                    </div>
                    :
                    <>
                        {props.row[props.name?.split('.')[0]]?.length === 0 ?
                            <span>
                            {t(this.props.noAsignadoTexto)}
                            </span>
                            :
                            props.row[props.name?.split('.')[0]]?.map(zona => (
                                <span key={zona.value}>
                                    <span style={{ marginLeft: 8 }}>
                                        <i style={{ color: zona?.color }}
                                           className={"fas fa-map-marked-alt"} />
                                    </span>
                                    <span style={{ marginLeft: 8 }}>
                                        {zona?.value || "No asignado"}
                                    </span>
                                </span>
                            ))}
                    </>
                }
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ZoneColorList
