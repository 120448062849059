import React from 'react'
import { observer } from 'mobx-react'
import arrayMove from 'array-move'
import Modal from 'react-modal'
import 'rc-checkbox/assets/index.css'
import Overlay from '../modals/Overlay'
import VsfModal from '../modals/VsfModal'
import AppLogger from '../../util/AppLogger'
import translate from '../../translator/translate'
import LocalStorage from '../../network/LocalStorage'
import SortableList from '../SortableList'

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    }
}

@observer
class ListItemSelect extends VsfModal {

    constructor (props) {
        super(props)
        this.localStorageLabel = props.name
        this.checkListFieldsInLocalStorage()
        this.reorderListFields()
        this.refreshListFieldsVisibility()
    }

    /* MODAL */

    openModal () {
        this.props.stateListColumnsSelect.modalOpen = true
    };

    closeModal () {
        this.props.stateListColumnsSelect.modalOpen = false
    }

    /**
     * Actualiza los datos del orden de las columnas del listado y su visibilidad del LocalStorage si existieran, y si no, se guardan los por defecto
     */
    checkListFieldsInLocalStorage () {
        let localStorage = new LocalStorage()
        if (localStorage.getItem(this.localStorageLabel) != null) {
            try {
                let fromLocalStorage = JSON.parse(localStorage.getItem(this.localStorageLabel))
                let sortable = fromLocalStorage.sortable
                let visible = fromLocalStorage.visible
                let fromJavascriptFields = {}
                for (let item of this.props.stateListColumnsSelect.listFields) {
                    fromJavascriptFields['' + item.name] = item
                }
                //elimino los que hayan desaparecido
                let newSortable = []
                for (let name of sortable) {
                    if (fromJavascriptFields.hasOwnProperty(name)) {
                        newSortable.push(name)
                    }
                }
                let newVisible = {}
                for (let [key, value] of Object.entries(visible)) {
                    if (fromJavascriptFields.hasOwnProperty(key)) {
                        newVisible[key] = value
                    }
                }
                //añadir los nuevos que hayamos incluido de nuevo en el JS
                for (let [key, value] of Object.entries(fromJavascriptFields)) {
                    if (!visible.hasOwnProperty(key)) {
                        newVisible[key] = false
                        newSortable.push(key)
                    }
                }
                this.props.stateListColumnsSelect.sortableFields = newSortable
                this.props.stateListColumnsSelect.visibleFields = newVisible
            } catch (e) {
                this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
            }
        } else {
            this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
        }
    }

    componentDidMount (): void {
        this.removeItemFromArr(this.props.stateListColumnsSelect.sortableFields, this.columnaFija())
    }

    /**
     * Obtiene los datos del orden y visibilidad de las columnas del listado a partir del state
     * @returns {{sortable: *, visible: *}}
     */
    getLocalStorageListFields () {
        // this.log("getLocalStorageListFields")
        // this.log(this.props.stateListColumnsSelect.sortableFields)
        // this.log(this.props.stateListColumnsSelect.visibleFields)
        if (this.props.stateListColumnsSelect.sortableFields.indexOf(this.columnaFija()) == -1) {
            this.props.stateListColumnsSelect.sortableFields.unshift(this.columnaFija())
        }
        let result = {
            sortable: this.props.stateListColumnsSelect.sortableFields,
            visible: this.props.stateListColumnsSelect.visibleFields
        }
        return result
    }

    setItemOnLocalStorage (key, value) {
        localStorage.setItem(key, value)
    }

    /**
     * Actualiza la visibilidad de las columnas una vez hemos presionado un check en el modal de las columnas visibles o al inicio de ejecucion
     */
    refreshListFieldsVisibility () {
        var visibleFields = this.props.stateListColumnsSelect.visibleFields
        this.props.stateListColumnsSelect.listFields.forEach(function (listField, index) {
            if (listField != null) {
                listField.visible = visibleFields[listField.name]
            }
        })
        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
    }

    /**
     * Reordena las columnas del listado despues de un movimiento en el modal o en la carga
     *
     */
    reorderListFields () {
        // Primero, reordenamos las columnas en el listado
        let listFields = []
        var baseListFields = this.props.stateListColumnsSelect.listFields
        if (this.props.stateListColumnsSelect.sortableFields.indexOf(this.columnaFija()) != -1) {
            this.removeItemFromArr(this.props.stateListColumnsSelect.sortableFields, this.columnaFija())
            this.props.stateListColumnsSelect.sortableFields.unshift(this.columnaFija())
        }
        this.props.stateListColumnsSelect.sortableFields.forEach(function (name, index) {
            let field = baseListFields.find(listField => listField != null && '' + listField.name === name)
            if (field != null) {
                listFields.push(field)
            }
        })
        // this.log({ reorderListFields: 1, listFields, sort: this.props.stateListColumnsSelect.sortableFields })
        // Segundo, guardamos en el state las columnas actualizadas
        this.props.stateListColumnsSelect.listFields = listFields
        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
    }

    removeItemFromArr (arr, item) {
        var i = arr.indexOf(item)
        if (i !== -1) {
            arr.splice(i, 1)
        }
    }

    /**SORTABLE
     * Se ejecuta tras mover columnas de posicion, obteniendo como parametro el antiguo y nuevo indice del elemento movido
     */
    onSortEnd ({
        oldIndex,
        newIndex
    }) {

        // Guardamos en el state directamente el nuevo array con el orden de las columnas para que la siguiente funcion
        // tenga la informacion actualizada, ya que setState es asincrono y es mas lento que la funcion en ejecutarse
        let sortableFields = arrayMove(this.props.stateListColumnsSelect.sortableFields.filter(name => this.columnaFija() !== name), oldIndex, newIndex)
        this.props.stateListColumnsSelect.sortableFields = sortableFields
        if (this.props.stateListColumnsSelect.sortableFields.indexOf(this.columnaFija()) == -1) {
            this.props.stateListColumnsSelect.sortableFields.unshift(this.columnaFija())
        }
        // this.log({
        //     onSortEnd: 1,
        //     lista: this.props.stateListColumnsSelect.sortableFields,
        //     sortableFields
        // })
        // Reordenamos las columnas
        this.reorderListFields()
        // Actualizamos el render modificando el state (hace que haya que dar doble click para salir)
        this.setState({
            sortableFields
        })
    };

    /**CHECKBOX
     * Controla el cambio de un checkbox y lo guarda en el estado
     */
    handleCheckBoxChange (name) {
        // Actualizamos el estado
        let visibleFields = this.props.stateListColumnsSelect.visibleFields
        visibleFields[name] = !visibleFields[name]
        if (visibleFields[this.columnaFija()] == null) {
            visibleFields[this.columnaFija()] = true
        }
        this.setState({
            visibleFields
        })
        // Actualizamos la visibilidad de las columnas
        this.refreshListFieldsVisibility()
        // Reordenamos las columnas segun el orden
        this.reorderListFields()
    };

    columnaFija () {
        let tipos = {
            'ordersConnection': 'code',
            'workOrdersConnection': 'code',
            'usersConnection': 'email',
            'assetsConnection': 'plate',
            'vehiclesConnection': 'plate',
            'warehousesConnection': 'code',
            'assetModelsConnection': 'manufacturer',
            'articlesConnection': 'code',
            'clientsConnection': 'companyName',
            'slotsConnection': 'code',
            'budgetsConnection': 'code',
            'workOrderCartArticlesConnection': 'articleCode',
            'clientPos': 'name',
            'stocksConnection': 'subInventory',
            'stockMovementsConnection': 'createdAt',
        }
        return tipos[this.props.name]
    }

    renderFlatItem (row) {
        if (this.columnaFija() !== row.name) {
            return (
                <div className={'ml-3'}>
                    <input
                        name={row.name}
                        type="checkbox"
                        checked={row.visible}
                        onChange={(e) => this.handleCheckBoxChange(row.name)}
                    />
                    <label htmlFor="id">{
                        <span>{row?.title}</span>
                    }</label>
                </div>
            )
        }
    }

    /**
     * http://react-component.github.io/badgeboard/
     * @returns {*}
     */
    render () {
        const t = translate
        let props = this.props
        return (
            <>
                {this.props.stateListColumnsSelect.modalOpen &&
                <>
                    <Overlay show={this.props.stateListColumnsSelect.modalOpen}
                             onClick={() => this.closeModal()} />
                    <Modal
                        isOpen={this.props.stateListColumnsSelect.modalOpen}
                        onRequestClose={() => this.closeModal()}
                        className="list-columns-modal"
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                        style={customStyles}>
                        <div className="modal-push">
                            <div className="modal-head">
                                <div className="row">
                                    <div className="col-10 text-left">
                                        <div className="modal-title">
                                            {/*<img src="img/filter-white.svg" alt="Filtros" />*/}
                                            <h2 ref={subtitle => this.subtitle = subtitle}>
                                                {t('Columnas')}{' '}
                                                <span>{props.subtitle}</span>
                                            </h2>
                                        </div>
                                        <p className="modal-title-info">{t('Puedes arrastrar tu selección hasta la posición deseada si aguantas pulsando') + '. ' + t('Solo se imprimirán aquellas que esten seleccionadas')}</p>
                                    </div>
                                    <div className="col-2 text-right">
                                        <button type="button" onClick={_ => this.closeModal()} className="close"
                                                aria-label="Cerrar"><span className="fas fa-times" /></button>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-body full-height">
                                <SortableList
                                    items={this.props.stateListColumnsSelect.listFields.filter(row => this.columnaFija() !== row.name)}
                                    onSortEnd={e => this.onSortEnd(e)}
                                    showDragHandle
                                    useDragHandle classGroup={'row'}
                                    onClickSortableItem={(value) => this.handleCheckBoxChange(value.name)}
                                    renderItem={(objParams) => this.renderFlatItem(objParams)}
                                    pressDelay={200} />
                            </div>
                        </div>
                    </Modal>
                </>
                }
            </>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default ListItemSelect
