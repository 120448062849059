import { computed, observable } from 'mobx';

class AssetCacheState {

    //bloque assets
    @observable assets = [];

    @computed get assetsPlateForSelect() {
        let result = [];
        for (let asset of this.assets) {
            result.push({ value: asset.id, label: asset.plate, q: asset.search })
        }
        console.log('array de CacheForSelect');
        console.log(result);
        return result;
    }


}

export default AssetCacheState;
