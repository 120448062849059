import AbstractModel from "./AbstractModel";
import FormQuestionModel from "./FormQuestionModel";

export default class FormModel extends AbstractModel {


    title;
    description;


    nameMainType = "form";
    graphFindByIdOperation = "form";
    graphFindOperation = "formsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "title": "",
            "description": "",
        };
        this.addRelatedTableGeneric(result, "formQuestion", "Relation", FormQuestionModel);
        return result;
    }

}
