import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class ConfigModel extends AbstractModel {

    static CODE_LOGO = "LOGO_APPLICATION";
    static CODE_CORPORATION_DATA = "CORPORATION_DATA";
    static CUSTOM_MOBILE_FLAVOR_QUALITY = "CUSTOM_MOBILE_FLAVOR_QUALITY";
    static CUSTOM_MOBILE_TASK_DIAGNOSIS = "CUSTOM_MOBILE_TASK_DIAGNOSIS";
    static END_WORK_ORDER = "END_WORK_ORDER";
    static CANCELATION_ORDER = "CANCELATION_ORDER";
    static SCHEDULED = "SCHEDULED";
    static RETURN = "RETURN";
    static NEW_ORDER = "NEW_ORDER";
    static PAUSE = "PAUSE";

    static MAINTENANCE_ON = "ON";
    static MAINTENANCE_OFF = "OFF";
    static MAINTENANCE_OFF_FOR_ADMIN_IP = "OFF_FOR_ADMIN_IP";


    id;
    code;
    value;


    nameMainType = "configuration";
    graphFindByIdOperation = "configuration";
    graphFindOperation = "configurationsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "code":  { label: "Código" },
            "value": { label: "Valor" },
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        return result;
    }

}
