import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import BaseForm from "../base/BaseForm";
import FormButtons from "../../components/FormButtons";
import translate from "../../translator/translate";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import Select2Component from "../../components/fields/Select2Component";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import MoreButtons from "../../components/MoreButtons";
import util from '../../util/Util';
import InfoBlock from "../../components/InfoBlock";
import GqlErrors from "../../components/status/GqlErrors";
import ClientModel from "../../models/ClientModel";
import GqlWarnings from "../../components/status/GqlWarnings";
import ClientActions from "./ClientActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import ValidatesFormTable from "../../components/ValidatesFormTable";
import VsfModal from "../../components/modals/VsfModal";
import MapView from "../../components/modals/MapView";
import DateInputFieldMobiscroll from "../../components/fields/DateInputFieldMobiscroll";

@observer
class ClientForm extends BaseForm {

    nameMainType = "client";

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        appState.changesWithValidationsState.formValidations = false;
        appState.changesWithValidationsState.imageValidations = false;
        this.state = {
            errorFormulario: false,
            modalMap: false,
            modalGps: false,
            loading: true,
            client: {}
        }
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        if (!this.props.fromRightModal) {
            appState.clientState.isPos = this.graphDataMainType.isPos;
            appState.clientState.isClient = !this.graphDataMainType.isPos;
        }
        if (!util.hasValue(this.graphDataMainType.id)) {
            let modelFromState = this.graphDataMainType;
            modelFromState['isPos'] = false;
        }
        await appState.articleState.loadDeslocalizedArticles();
        await appState.clientCacheState.loadClientCacheClients();
        await appState.contractState.getContracts();
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        this.setState({loading: false})
    }

    getModelQuery() {
        return new ClientModel();
    }

    /**
     * The form has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {
        this.graphDataMainType.isPos = model.isPos;
        this.graphDataMainType.saveOptions = this.getModelQuery().NOT_SAVE_WITH_WARNINGS;
        this.validatesFormRef?.loadTable();
    }

    /**
     * Metodo que tras actualizar los valores del typified, actualiza el select
     *
     * @param e
     */
    async handleFormSubmitClientValue(e) {
        await this.handleFormSubmit(e);
        this.reloadClientValues();
    }

    /**
     * Actualiza el select jerárquico
     */
    reloadClientValues() {
        appState.clientCacheState.loadClientCache();
    }

    onChangeInput(name, value, relation) {
        if (name === "storeId") {
            if (util.hasValue(relation?.id)) {
                this.changeKey = value;
                this.graphDataMainType.store = relation
            }
        }
        this.updateInput(name, value)
    }

    renderImpl() {
        let id = this.getFormId();
        const t = translate;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);

        let client = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (client == null) {
            client = {};
        }
        let statusOptions = [
            {label: "Activo", value: ClientModel.STATUS_ACTIVO},
            {label: "Bloqueado", value: ClientModel.STATUS_BLOCKED},
        ];
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmitClientValue(e)}>

                        {this.props.fromRightModal ?
                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                                     title={this.getPageTitle()}
                                                     subtitle={this.getPageSubtitle()}
                                                     mode={"edit"}
                                                     onCloseModal={() => this.onCloseModal()}
                                >
                                    {util.hasValue(this.getFormId()) &&
                                    <MoreButtons
                                    >
                                        <ClientActions fromRightModal={this.props.fromRightModal}
                                                       setErrors={(errors) => this.setErrors(errors)}
                                                       getModelQuery={() => this.getModelQuery()} model={client}/>
                                    </MoreButtons>
                                    }
                                </PageTitleRightModal>
                                <TabsComponent
                                    id={this.graphDataMainType.id}
                                    tabs={this.tabsData.getDataClient()}
                                    active={"Datos Generales"}
                                    fromRightModal={this.props.fromRightModal}
                                    classNameModal={" "}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}>
                                {util.hasValue(this.getFormId()) &&
                                <MoreButtons
                                    onReloadData={() => this.reloadData()}>
                                    <ClientActions getModelQuery={() => this.getModelQuery()}
                                                   fromRightModal={this.props.fromRightModal}
                                                   setErrors={(errors) => this.setErrors(errors)} model={client}/>
                                </MoreButtons>
                                }
                            </PageTitle>
                        }
                        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)}/>
                        <GqlWarnings warnings={this.gqlWarnings} onSaveWarning={() => this.saveWithWarnings()}
                                     key={this.gqlWarnings}
                                     setWarnings={(warnings) => this.setWarnings(warnings)}/>
                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className={modalClasses.body}>
                                    {!this.props.fromRightModal &&
                                    <TabsComponent
                                        id={this.graphDataMainType.id}
                                        tabs={this.tabsData.getDataClient(this.graphDataMainType.isPos)}
                                        active="Datos Generales"
                                        fromRightModal={this.props.fromRightModal}

                                    />
                                    }
                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                                       error={this.graphStatus.mutationError}
                                                       working={this.graphStatus.networkWorking}
                                    />
                                    <ValidatesFormTable
                                        onRef={ref => (this.validatesFormRef = ref)}
                                        model={this.getModelQuery()}
                                        onChangeInput={(name, value, relation) => this.onChangeInput(name, value, relation)}
                                        graphDataMainType={this.graphDataMainType}
                                        previousRowForDiscard={this.previousRowForDiscard}/>

                                    <GroupOfField
                                        title={t("Datos Fiscales")}
                                        icon="fas fa-file-invoice"
                                    >
                                        <Select2Component
                                            value={client.status}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"status"} isClearable={false}
                                            title={t("Estado")}
                                            editable={this.editable}
                                            classGroup={this.props.fromRightModal ? 'col-md-3 col-lg-3' : 'col-md-3 col-lg-3'}
                                            options={statusOptions}
                                            errors={errorsMapped.status}
                                        />
                                        <InputTypeComponent
                                            value={client.code}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="code"
                                            title={t("Número de Cliente")}
                                            placeholder={t("Número de Cliente")}
                                            classGroup={"col-md-3 col-xl-3"}
                                            type={"text"} required
                                            readOnly={util.hasValue(client.id)}
                                            info={t("Este campo debe ser un código único para cada Cliente")}
                                            dataCy={"numeroCliente"}
                                            editable={this.editable}
                                            instanceOf={"form-control"}
                                            validate={"alphanumeric"}
                                            errors={errorsMapped.code}
                                        />

                                        <InputTypeComponent
                                            value={client.codeErp}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="codeErp"
                                            title={t("Código ERP")}
                                            placeholder={t("Código ERP")}
                                            classGroup={"col-md-3 col-xl-3"}
                                            readOnly={true}
                                            editable={this.editable}
                                            errors={errorsMapped.codeErp}
                                        />

                                        <InputTypeComponent
                                            value={client.statusErp}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name="statusErp"
                                            title={t("Estado ERP")}
                                            placeholder={t("Estado ERP")}
                                            classGroup={"col-md-3 col-xl-3"}
                                            editable={this.editable}
                                            errors={errorsMapped.statusErp}
                                        />

                                        <InputTypeComponent
                                            required={true}
                                            value={client.companyName}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"companyName"}
                                            title={t("Razón Social")}
                                            dataCy={"companyName"}
                                            editable={this.editable}
                                            placeholder={t("Razón Social")}
                                            classGroup={"col-md-6 col-xl-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.name}

                                        />
                                        <InputTypeComponent
                                            editable={this.editable}
                                            value={client.address}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"address"}
                                            title={t("Dirección Físcal")}
                                            placeholder={t("Dirección Físcal")}
                                            classGroup={"col-md-6 col-xl-6"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.address}
                                            dataCy={"direccionFíscal"}
                                        />
                                        <InputTypeComponent
                                            required={true}
                                            value={client.vatID}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"vatID"}
                                            editable={this.editable}
                                            title={t("NIF")}
                                            placeholder={t("NIF")}
                                            classGroup={"col-md-6 col-xl-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.vatID}
                                            dataCy={"NIF"}
                                        />

                                        <InputTypeComponent
                                            value={client.city}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"city"}
                                            title={t("Localidad")}
                                            placeholder={t("Localidad")}
                                            classGroup={"col-md-6 col-xl-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.city}
                                            dataCy={"localidad"}
                                        />

                                        <InputTypeComponent
                                            value={client.district}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"district"}
                                            title={t("Provincia")}
                                            editable={this.editable}
                                            placeholder={t("Provincia")}
                                            classGroup={"col-md-6 col-xl-3"}
                                            type={"text"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.district}
                                            dataCy={"provincia"}
                                        />
                                        <InputTypeComponent
                                            value={client.postalCode}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"postalCode"}
                                            title={t("Código Postal")}
                                            placeholder={"CP"}
                                            classGroup={"col-md-6 col-xl-3"}
                                            type={"text"}
                                            validate={"postalCode"}
                                            instanceOf={"form-control"}
                                            errors={errorsMapped.postalCode}
                                            dataCy={"codigoPostal"}
                                        />

                                    </GroupOfField>
                                    <GroupOfField
                                        title={t("Contrato asociado")}
                                        icon="fas fa-file-invoice"
                                    >
                                        <InputTypeComponent
                                            value={client.contractNumber}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            name={"contractNumber"}
                                            title={t("Número de contrato")}
                                            placeholder={t("Número de contrato")}
                                            classGroup={"col-md-6 col-xl-3"}
                                            errors={errorsMapped.postalCode}
                                        />
                                        <Select2Component
                                            value={client.contractId}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={"contractId"}
                                            isClearable={false}
                                            title={t("Contrato")}
                                            editable={this.editable}
                                            classGroup={this.props.fromRightModal ? 'col-md-3 col-lg-3' : 'col-md-3 col-lg-3'}
                                            options={appState.contractState.labelValuesForSelect}
                                            errors={errorsMapped.contractId}
                                        />
                                        <DateInputFieldMobiscroll
                                            prefix={"fas fa-calendar-alt"}
                                            withMinMaxDate={true}
                                            classGroup={'col-3'}
                                            value={client.startDateContract}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            title={t("Fecha inicio del contrato")}
                                            name={"startDateContract"}
                                            type={"text"}
                                            minDate={util.getMoment()}
                                            allowEmptyDate={true}
                                            errors={errorsMapped.startDateContract}
                                        />

                                        <DateInputFieldMobiscroll
                                            prefix={"fas fa-calendar-alt"}
                                            withMinMaxDate={true}
                                            classGroup={'col-3'}
                                            value={client.endDateContract}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            editable={this.editable}
                                            title={t("Fecha fin del contrato")}
                                            name={"endDateContract"}
                                            type={"text"}
                                            minDate={util.getMoment()}
                                            allowEmptyDate={true}
                                            errors={errorsMapped.endDateContract}
                                        />

                                    </GroupOfField>

                                    {this.state.modalMap &&
                                    <MapView
                                        title={t('Localización del Cliente')}
                                        clientModel={this.state.client} editable={this.editable}
                                        updateInputEvent={(e) => this.updateInputEvent(e)}
                                        openModal={this.state.modalMap}
                                        updateClient={true}
                                        {...this.props}
                                        closeModal={() => this.setState({modalMap: false})}
                                    />
                                    }
                                    {this.state.modalGps &&
                                    <VsfModal
                                        isOpen={this.state.modalGps}
                                        onCloseModal={() => this.setState({modalGps: false})}
                                        className=" center "
                                        containerCentered={true}
                                    >
                                        <div className="modal-push">
                                            <div className="modal-head visible-text">
                                                <div className="d-flex align-items-center">
                                                    <div className="col-12 text-left">
                                                        <div className="modal-title">
                                                            <p>{t('Como añadir la localización de un cliente')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{paddingLeft: 45}} className="modal-body-filters">
                                                <p className="m-0">Accede a:
                                                    <a style={{color: '#0058F5'}} target="_blank"
                                                       href="https://www.google.es/maps/preview">{t('Google Maps')}</a>
                                                </p>
                                            </div>
                                            <div className="modal-body-filters" style={{
                                                maxHeight: 'none',
                                                paddingBottom: 45,
                                                paddingLeft: 45,
                                                paddingRight: 45
                                            }}>
                                                <img className="shadow" src="/img/help_map.jpg"
                                                     alt="ayuda_ubicación"/>
                                            </div>
                                        </div>
                                    </VsfModal>
                                    }
                                    <FormButtons id={id} formStatus={this.graphStatus} dataCy={"guardarCliente"}/>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>

                    </form>
                </InfoBlock>
            </div>

        )
    }


}

export default withRouter(ClientForm)
