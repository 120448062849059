import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FactoryValidation from "./validations/FactoryValidation";
import AppLogger from "../../util/AppLogger";
import appState from "../../state/AppState";
import PropTypes from "prop-types";
import ChangesWithValidationsModel from "../../models/ChangesWithValidationsModel";

@observer
class BaseFieldComponent extends Component {
    static defaultProps = {
        showView: false, // Muestra el campo en modo solo texto
        editable: true,
        readOnly: false
    };
    static propTypes = {
        showView: PropTypes.bool, // Muestra el campo en modo solo texto
        editable: PropTypes.bool,
        readOnly: PropTypes.bool,
    };

    customStyles = {

        valueContainer: (provided, state) => ({
            ...provided,
            position: 'unset',
        }),
        placeholder: (provided, state) => {
            return {
                ...provided,
                color: '#CED4DA',
            }
        },
        menu: (provided, state) => {
            return {
                ...provided,
                // width: state.selectProps.width, si se rompe algun ancho del select puede ser esto
                zIndex: 99,
                position: 'absolute',
                top: this.props.tenantSelector ? -25 : 32,
                boxShadow: '0 3px 15px 5px rgba(0, 0, 0, 0.2)',
            }
        },
        singleValue: (styles) => {
            return {
                ...styles,
                color: this.props.tenantSelector ? '#576267' : null,
                fontSize: this.props.tenantSelector ? '0.750rem' : null,
                fontFamily: this.props.tenantSelector ? "'roboto', sans-serif" : null,
                maxWidth: `calc(100% - 80px)`,
            }
        },
        indicatorsContainer: (styles) => {
            return {
                ...styles,
                display: this.props.tenantSelector ? 'none' : 'flex',
            }
        },
        control: (styles, { isFocused }) => {
            if (this.props.tenantSelector || this.props.groupFieldSelector) {
                return {
                    ...styles,
                    backgroundColor: isFocused ? '#EDEDED' : null,
                    border: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    height: '50px',
                    cursor: 'pointer',
                }
            } else {
                return {
                    ...styles,
                    boxShadow: isFocused ? '0 0 0 2px #BFDEFF' : null,
                    backgroundColor: appState.changesWithValidationsState.validateChanges[this.props.name]?.approved === ChangesWithValidationsModel.APPROVED ? '#FFE5E5' : '#fff',
                }
            }
        },
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isSelected ? "#6C757D" : isFocused ? "#EDEDED" : "#fff",
                color: isSelected ? "#fff" : isFocused && "#212529",
                cursor: isDisabled ? 'not-allowed' : 'default',
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? "#2D3B41" : "#2D3B41"),
                    color: !isDisabled && (isSelected ? "#fff" : "#fff"),
                }
            }
        },
    };


    onKeyDownPrevent(e) {
        if (e.keyCode === 13) {
            //keyCode 13 es el enter
            if (!this.props.submitOnEnter) {
                document.activeElement.blur();
            }
        }
    }


    constructor(props) {
        super(props);
        this.state = { errorsClientValidation: [], value: this.props.value, openDatePicker: false }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    getClassNameInput() {
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let hasErrors = errors.length > 0;
        let classNameInput = "form-control";
        if (appState.changesWithValidationsState.validateChanges[this.props.name]?.approved === ChangesWithValidationsModel.APPROVED) {
            classNameInput += " bg-pink ";
        }
        if (this.props.classInput) {
            classNameInput += this.props.classInput;
        }
        if (hasErrors) {
            classNameInput += " is-invalid text-danger";
        }
        if (this.props.hasNoErrors) {
            classNameInput += " is-valid text-success";
        }
        return classNameInput;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    checkReadOnly() {
        let props = this.props;
        let readOnly = props.readOnly;
        if (!props.editable) {
            readOnly = true;
        }
        return readOnly;
    }

    validateOnChangeFunction(e) {
        let props = this.props;
        let isCorrect = false;

        if (props.validate) {
            /*recibo una cadena formada por las validaciones a realizar separadas por (|)*/
            let validationList = props.validate.split('|');
            for (let validation of validationList) {
                let args = validation.split(':');
                let validationName = args[0];
                args.shift();
                if (validationName === "decimal") {
                    if (e.target.value[0] === "." || e.target.value === ",") {
                        e.target.value = "0" + e.target.value[0];
                    }
                    //El formato decimal solo acepta puntos. Si nos intruducen comas en el onBLur sustituimos
                    //la coma por punto
                    let newValue = e.target.value.replace(",", ".");
                    e.target.value = newValue;
                }
                if (validationName === "numberMinValue") {
                    if (e.target.value < args[0] && e.target.value !== "") {
                        e.target.value = args[0];
                        if (props.onChange != null) {
                            props.onChange(e);
                        }
                    }
                }
                let instanceValidation = FactoryValidation.createFromName(validationName, "change");
                isCorrect = instanceValidation.validate(e.target.name, e.target.value, args);
                this.log({ validation, args, validationName, isCorrect, value: e.target.value })
                if (isCorrect === false) {
                    e.target.value = "";
                    /*Cuando hay varias validaciones seguidas para el mismo input se va sobrescrbiendo y se queda únicamente
                    * con la última validación.
                      Si me encuentro algun false salgo del bucle y me quedo con el false
                   */
                    break;
                }
            }
            if (isCorrect) {
                if (props.onChange != null) {
                    this.setState({
                        value: e.target.value
                    });
                    props.onChange(e);
                }
            }
        } else {
            if (props.onChange != null) {
                let eventObj = {
                    target: {
                        name: this.props.name,
                        value: e.target.value
                    }
                };
                props.onChange(eventObj);
            }
        }
    }

    limpiaString(strin) {
        strin = strin.replace(/[áàâãªä]/gi, "a");
        strin = strin.replace(/[ÁÀÂÃÄ]/gi, "a");
        strin = strin.replace(/[éèêë]/gi, "e");
        strin = strin.replace(/[ÉÈÊË]/gi, "e");
        strin = strin.replace(/[íìîï]/gi, "i");
        strin = strin.replace(/[ÍÌÎÏ]/gi, "i");
        strin = strin.replace(/[óòôõºö]/gi, "o");
        strin = strin.replace(/[ÓÒÔÕÖ]/gi, "o");
        strin = strin.replace(/[úùûü]/gi, "u");
        strin = strin.replace(/[ÚÙÛÜ]/gi, "u");
        strin = strin.replace(/[ñÑ]/gi, "n");
        strin = strin.replace(/ /gi, "");
        strin = strin.toLowerCase();
        // this.log({ limpiaString: 1, strin });
        return strin;

    }

    /**
     * Metodo que filtra de forma personalizada
     */
    customFilter(candidate, input) {
        // Pasamos las variables a minuscula para no distinguir entre minuscula y mayuscula
        let inputLowerCase = this.limpiaString(input);
        let label = "";
        if (candidate.label !== undefined && typeof candidate.label == "string") {
            label = this.limpiaString(candidate.label.toLowerCase());
        }
        if (candidate.label?.props && candidate.label.props.search !== undefined && typeof candidate.label.props.search == "string") {
            label = this.limpiaString(candidate.label.props.search.toLowerCase());
        }
        let value = "";
        if (candidate.value !== undefined && typeof candidate.value == "string") {
            value = this.limpiaString(candidate.value.toLowerCase());
        }
        let q = "";
        if (candidate.q !== undefined && typeof candidate.q == "string") {
            q = this.limpiaString(candidate.q.toLowerCase());
        }
        // Si tenemos algun input comprobamos los filtros
        if (input) {
            return q.includes(inputLowerCase) || value.includes(inputLowerCase) || label.includes(inputLowerCase);
        }
        return true;

    };

    validateOnBlurFunction(e) {
        let message = 'Formato no válido';
        let props = this.props;
        let value = e.target.value;

        if (value != null) {
            let isCorrect = true;
            if (props.validate) {
                let validationList = props.validate.split('|');
                for (let validation of validationList) {
                    let args = validation.split(':');
                    let validationName = args[0];
                    args.shift();
                    if (validationName === "decimal") {
                        let length = value.length;
                        if (value[length - 1] === "." || value[length - 1] === ",") {
                            value += "0";
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    if (validationName === "numberMinValue") {
                        if (value < args[0]) {
                            value = args[0];
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    if (validationName === "required") {
                        let length = value.length;
                        if (length != 0) {
                            if (props.onChange != null) {
                                props.onChange(e);
                            }
                        }
                    }
                    let instanceValidation = FactoryValidation.createFromName(validationName, "blur");
                    isCorrect = instanceValidation.validate(e.target.name, value, args);
                    if (validationName === "almacenDestino") {
                        if (appState.typifiedState.getValue(appState.loginState.user.zoneAsignedId) === 'Levante'
                            && e.target.value !== "04" && e.target.value !== "") {
                            message = "Si es zona Levante sólo pueden mover al almacen destino 04";
                            isCorrect = false;
                        } else {
                            message = '';
                            isCorrect = true;
                        }
                    }
                }

                if (!isCorrect) {
                    //Mensaje de error
                    this.setState({
                        errorsClientValidation: [{
                            fieldName: e.target.name,
                            message: message
                        }]
                    });
                } else {
                    this.setState({ errorsClientValidation: [], value: e.target.value });
                }
            }
        }

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default BaseFieldComponent;

