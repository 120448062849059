import React, { Component } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import appState from "../state/AppState";
import '../scss/components/info-block.scss';
import AppLogger from "../util/AppLogger";

@observer
class InfoBlock extends Component {

    static propTypes = {
        componentName: PropTypes.string,
        componentObject: PropTypes.object,
        wrapper: PropTypes.string //En vez de div utilizar� este elemento para la maquetaci�n
    };

    static defaultProps = {
        componentName: "",
        componentObject: null,
        wrapper: null
    };

    getTypeName(obj) {

        return obj.constructor.name;
    }

    withInfo() {
        return appState.layoutState.infoBlockEnabled;
    }

    render() {
        let name = this.props.componentName;
        if (this.props.componentObject != null) {
            name = this.getTypeName(this.props.componentObject);
        }
        let inner = (
            <>
                <div className="block-info-label">{name}</div>
            </>
        );

        if (this.withInfo()) {

            return (<>
                    <div key="block-info" className="block-info">{inner}</div>
                    {this.props.children}
                </>
            )

        } else {
            return (this.props.children);
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default InfoBlock;

