import React, { Component } from 'react';
import VsfModal from "./modals/VsfModal";
import AppLogger from "../util/AppLogger";
import translation from "../translator/translate";
import VsfButtonNew from "./VsfButtonNew";
import { observer } from "mobx-react";
import GqlErrors from "./status/GqlErrors";
import { withRouter } from "react-router-dom";
import { observable } from "mobx";
import GraphException from "../network/GraphException";
import SelectSuggestComponent from "./fields/SelectSuggestComponent";
import OrderModel from "../models/OrderModel";
import TypifiedValueModel from "../models/TypifiedValueModel";
import appState from "../state/AppState";
import WorkOrderMovedModel from "../models/WorkOrderMovedModel";
import WorkOrderModel from "../models/WorkOrderModel";
import FetchProxy from "../network/FetchProxy";
import config from "../config/config";
import util from "../util/Util";

@observer
class WorkOrderMovedModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async updateEvent(event) {
        this[event.target.name] = event.target.value;
    }

    @observable orderId;
    @observable gqlErrors = [];


    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }


    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @returns Array
     */
    async getOrderSugestions(value) {
        let orderArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let orderQuery = new OrderModel();
            orderQuery.addRelatedTable("workOrder");
            orderQuery.status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, "APPROVED").id;
            orderQuery.clientId = this.props.workOrderModel.clientId;
            orderQuery.filters = [
                {"fieldName": "code", "fieldValue": value, "filterOperator": "SUBSTR"},
                {"fieldName": "id", "fieldValue": this.props.workOrderModel.orderId, "filterOperator": "NEQ"},
            ];
            let order = await orderQuery.findPlainObject();
            order.map((order) => {
                let orderObject = {};
                orderObject.label = order.code;
                orderObject.value = order.id;
                orderArray.push(orderObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return orderArray;
    }

    async sendWorkOrderFinished(workOrderId) {
        this.setState({loadingSending: true});
        const t = translation;
        let httpApi = new FetchProxy();
        httpApi.withAuthorization = true;
        try {
            let url;
            url = config.apiRestHostBaseUrl + "/workOrderFinished";
            let response = await httpApi.fetchUrlPost(url, {"workOrderId": workOrderId,});
            let responseJson = await response.json();
            if (responseJson.status !== "OK") {
                let gqlErrors = new GraphException().getErrorsFromException(responseJson);
                this.log({gqlErrors, responseJson});
                this.setState({loadingError: true, loading: false});
                this.gqlErrors = gqlErrors;
            }
            this.log({respuestaJson: 1, responseJson});
        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
            this.log(e);
        }
    }

    async saveStatus() {
        this.setState({loading: true});
        try {
            this.log({
                propsOrderId: this.props.workOrderModel.orderId,
                newOrderId: this.orderId,
                workOrderModel: this.props.workOrderModel
            })
            //El movimiento lleva el pedido antiguo, y el id de la OT movida
            let workOrderMovedQuery = new WorkOrderMovedModel();
            workOrderMovedQuery.workOrderId = this.props.workOrderModel?.id;
            workOrderMovedQuery.orderId = this.props.workOrderModel.orderId;
            await workOrderMovedQuery.persist();
            // Se actualiza la id movida para moverla al nuevo pedido
            let workOrderQuery = new WorkOrderModel();
            workOrderQuery.hidrate(this.props.workOrderModel);
            workOrderQuery.orderId = this.orderId;
            await workOrderQuery.persist();
            let workOrderId = "";
            let todasFinalizadas = true;
            let statusFinished = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS,"END_WORK_ORDER").id
            // TODO Esto da fallo, CCT dice que se deberia enviar si estan todas las OTs finalizadas
            for (let workOrder of this.props.workOrderModel.order?.workOrder) {
                //Si todas estan finalizadas. status === finished
                if (workOrder.status !== statusFinished) {
                    todasFinalizadas = false;
                } else {
                    if (workOrder.id !== this.props.workOrderModel?.id) {
                        workOrderId = workOrder.id;
                    }
                }
            }
            if (todasFinalizadas && util.hasValue(workOrderId)) {
                await this.sendWorkOrderFinished(workOrderId)
            }
            setTimeout(() => {
                this.setState({loading: false});
                this.props.closeModal();
            }, 3000)
        } catch (e) {
            this.setState({loading: false});
            let errors = new GraphException().getErrorsFromException(e);
            this.gqlErrors = errors;
        }
    }

    render() {
        const t = translation;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">

                        <div className="c-modal-info__cell__body row">

                            <SelectSuggestComponent
                                value={this.orderId}
                                onChange={(e) => this.updateEvent(e)}
                                title={t("Pedido")}
                                name={"orderId"}
                                classGroup={"col-md-6 col-lg-6"}
                                loadSuggestions={(value, firstCall) => this.getOrderSugestions(value, firstCall)}
                                placeholder={t("Escribe...")}
                            />
                        </div>
                    </div>

                    <div className="modal-footer ">
                        <div className="mt-4">
                            <div className="d-flex justify-content-end mb-2">
                                <VsfButtonNew
                                    label={t('Salir')}
                                    className="btn btn-secondary"
                                    onClick={() => this.props.closeModal()}
                                />
                                <VsfButtonNew
                                    label={t("Guardar")}
                                    loading={this.state.loading}
                                    icon="fa fa-save"
                                    loadedError={this.gqlErrors.length !== 0}
                                    className="btn btn--loader"
                                    onClick={() => this.saveStatus()}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)}/>

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(WorkOrderMovedModal)
