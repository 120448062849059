import React from 'react'
import { observer } from 'mobx-react'
import SlotModel from '../../models/SlotModel'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import BaseEditableRow from '../base/BaseEditableRow'
import { computed, observable, toJS } from 'mobx'
import appState from '../../state/AppState'
import Select2Component from '../../components/fields/Select2Component'
import util from '../../util/Util'
import InfoBlock from '../../components/InfoBlock'
import WorkOrdersSelectInModal from '../../components/modals/WorkOrdersSelectInModal'
import AppLogger from '../../util/AppLogger'
import translate from '../../translator/translate'
import DateInputHoursMobiscroll from '../../components/fields/DateInputHoursMobiscroll'
import PropsUtil from '../../util/PropsUtil'
import IconsSelectCapactitation from '../../components/fields/IconsSelectCapactitation'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import GqlErrors from '../../components/status/GqlErrors'
import GqlWarnings from '../../components/status/GqlWarnings'
import WorkLogModel from '../../models/WorkLogModel'

@observer
class OrderFormSlot extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    })
    @observable workOrderRelaccionada
    @observable workOrderCodeRelaccionada
    @observable canVisitBeStarted = false

    constructor (props) {
        super(props)
        this.foreingKeyfield = 'orderId'
        this.state = {
            loadingSlot: false,
            warningError: '',
        }

    }

    @computed
    get mobxListado () {
        return appState.slotState.slots
    }

    setMobxListado (newValue) {
        appState.slotState.slots = newValue
    }

    async componentDidMount () {
        await super.componentDidMount()
        if (this.props.fromWorkOrder) {
            await this.workOrderRelaccionadas()
            this.foreingKeyfield = 'workOrderId'
        }
        let slot = this.props.row
        slot.saveOptions = this.getModelTable().NOT_SAVE_WITH_WARNINGS
        if (util.hasValue(slot?.id) && util.hasValue(slot?.scheduledTime) && !slot.isFinished && !slot.isStarted) {
            if (util.getMoment(slot?.scheduledTime).format('YYYYMMDDHHmm') < util.getMoment(Date.now()).add(30, 'm')
                .format('YYYYMMDDHHmm')) {
                this.canVisitBeStarted = true
            }
        }
    }

    getModelTable () {
        return new SlotModel()
    }

    updateInputEvent (event) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row)
        }
        this.updateInput(event.target.name, event.target.value)
    }

    updateInput (key, value) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row)
        }
        let slot = this.props.row
        let newValue = slot.workOrderIds
        if (value === '-1') {
            newValue = '-1'
        } else {
            if (newValue == null) {
                newValue = value
            } else {
                if (slot.workOrderIds=="-1") {
                    newValue = value
                } else if (util.perteneceA(value, slot.workOrderIds)) {
                    if (newValue.endsWith(value)) {
                        newValue = newValue.replace(',' + value, '')
                    } else {
                        newValue = newValue.replace(value + ',', '')
                    }
                } else {
                    newValue += ',' + value
                }
            }
        }
        slot.workOrderIds = newValue
    }

    async rowDidDelete (slot) {
        if (util.hasValue(slot.id)) {
            await this.props.reload()
        } else {
            let arrCopy = toJS(this.mobxListado)
            arrCopy.splice(this.props.rowIndex, 1)
            this.log({ arrCopy })
            this.setMobxListado(arrCopy)
        }
    }

    /**
     * @param slot
     * @returns {Promise<void>}
     */
    async rowDidSave (slot, previousId) {
        await this.props.reload()
        slot.saveOptions = this.getModelTable().NOT_SAVE_WITH_WARNINGS
    }

    renderSupport (readOnly) {
        let slot = this.props.row
        let fechaNow = util.getMoment()
        let fechaRow = util.getMoment(slot.scheduledTime)
        fechaRow.add(1, 'h')
        let fecha = fechaRow.format('YYYYMMDDHH') < fechaNow.format('YYYYMMDDHH')
        if (slot.scheduledTime == null) {
            fecha = false
        }
        if (slot.slotUserRole !== SlotModel.SUPPORT && !readOnly) {
            return (
                <div className="remove-add">
                    <div onClick={() => {
                        if (!slot.isEditing && !this.props.hasSomeRowEditing && !fecha)
                            this.onOpenSupport(slot)
                    }} title="Asignar usuario de apoyo"
                    className={slot.isEditing || this.props.hasSomeRowEditing || fecha ? 'edit inactive' : 'edit'}>
                        <span className="fas fa-user-plus" />
                    </div>
                </div>
            )
        }
    }

    downloadPDF (slot) {
        let slotModel = this.getModelTable()
        return slotModel.downloadPDF(slot.id)
    }

    onFixedValue (name) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.props.row)
        }
        let slot = this.props.row
        slot[name] = !slot[name]
    }

    handleInputHours (event) {
        let slot = this.props.row
        let momentValue = util.getMoment(slot.scheduledTime)
        momentValue.hour(event.target.value)
        event.target.value = momentValue
        this.handleInputChange(event)
    }

    render () {
        let slot = this.props.row
        const t = translate
        this.propsUtil = new PropsUtil(this.props)

        if (slot.workOrderIds == null && this.props.workOrderId) {
            slot.workOrderIds = this.props.workOrderId
        }
        let workOrderCode = this.workOrdersCodes()
        let errorsMapped = this.getGraphErrorsFromStatus()
        let optionHours = this.getOptionsHours()
        let usuario = appState.userCacheState.getUserById(slot.userId)
        let backgroundRow = ''
        if (appState.layoutState.backgroundRowScheduled != 0 && appState.layoutState.backgroundRowScheduled != slot.id) {
            backgroundRow = ' rowSlotNoSeleccionada'
        }
        if (slot.isFinished) {
            backgroundRow = ' rowSlotIsFinished '
        }
        let icons = []
        if (util.hasValue(usuario.capacitationIds)) {
            for (let capacitation of usuario.capacitationIds.split(',')) {
                if (appState.typifiedState.getObject(capacitation)) {
                    icons.push(appState.typifiedState.getObject(capacitation))
                }
            }
        }

        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                {slot.isEditing &&
                this.props.children
                }

                <tr aria-rowspan="2" className={slot.isEditing ? 'editing' : backgroundRow}
                    style={{ 'borderBottom': 0 }}>
                    <td>
                        {slot.isEditing ?
                            <div className="d-flex">
                                <>{slot.slotUserRole === SlotModel.SUPPORT ?
                                    <div style={{ color: '#A2BFD0' }}>
                                        <i className="fa fa-user-alt" />&nbsp;
                                    </div>
                                    :
                                    <div style={{
                                        color: '#3D708D',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <i className="fa fa-user-check" />&nbsp;
                                    </div>
                                }
                                </>
                                {!slot.isUserFixed ?
                                    <Select2Component
                                        value={slot.userId}
                                        onChange={(e) => this.handleInputChange(e)}
                                        name={'userId'} ordenar={false}
                                        classGroup={'col-10 pr-1 pl-0'}
                                        options={appState.userCacheState.userCacheForSelect}
                                        errors={errorsMapped.userId}
                                        extraWidth={100}
                                    />
                                    :
                                    <div>
                                        {slot.userId != null ?
                                            <span className="text-click "
                                                onClick={() => this.openUserModal(slot.userId)}>
                                                {(usuario.firstName ? usuario.firstName : '') + ' ' + (usuario.lastName
                                                    ? usuario.lastName : '')}
                                            </span>
                                            :
                                            t('Técnico sin asignar')
                                        }
                                    </div>
                                }
                                {util.hasValue(slot.userId) &&
                                <span onClick={() => this.onFixedValue('isUserFixed')} className="text-click">
                                    <i className={slot.isUserFixed ? 'fas fa-lock' : 'fas fa-lock-open'} /></span>
                                }
                            </div>
                            :
                            <div className="d-flex">
                                <>{slot.slotUserRole === SlotModel.SUPPORT ?
                                    <div style={{ color: '#A2BFD0' }}>
                                        <i className="fa fa-user-alt" />&nbsp;
                                    </div>
                                    :
                                    <div style={{
                                        color: '#3D708D',
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                        <i className="fa fa-user-check" />&nbsp;
                                    </div>
                                }
                                </>
                                {slot.userId != null ?
                                    <span
                                        className="text-click "
                                        onClick={() => this.openUserModal(slot.userId)}>{
                                            <IconsSelectCapactitation
                                                color={'#3D708D'} usuario={usuario}
                                                iconClass={'col-3'}
                                                capacitationIds={icons}>{((usuario.firstName ? usuario.firstName
                                                    : '') + ' ' +
                                            (usuario.lastName ? usuario.lastName : ''))}
                                            </IconsSelectCapactitation>
                                        }</span>
                                    : t('Técnico sin asignar')}
                            </div>
                        }
                    </td>
                    <td>
                        {slot.isEditing ?
                            <div className={'d-flex pt-1'}>
                                {!slot.isDateTimeFixed ?
                                    <>
                                        {slot.slotUserRole === SlotModel.SUPPORT ?
                                            <>
                                                {slot.scheduledTime &&
                                                <Select2Component
                                                    title={util.getMoment(slot.scheduledTime).format('DD-MM-YYYY')}
                                                    placeholder={'Hora'}
                                                    value={util.getMoment(slot.scheduledTime).format('H')}
                                                    onChange={(e) => this.handleInputHours(e)}
                                                    classGroup={'col-8'}
                                                    name={'scheduledTime'}
                                                    options={optionHours}
                                                    errors={errorsMapped.scheduledTime} />
                                                }
                                            </>
                                            :
                                            <DateInputHoursMobiscroll
                                                prefix={'fas fa-calendar-alt'}
                                                value={slot.scheduledTime}
                                                onChange={(e) => this.handleInputChange(e)}
                                                name={'scheduledTime'} type={'text'}
                                                allowEmptyDate={true}
                                                errors={errorsMapped.scheduledTime} />
                                        }
                                    </>
                                    :
                                    <div>
                                        {util.hasValue(slot.scheduledTime)
                                            ? util.localizeIsoDateMinutes(slot.scheduledTime) : ' '}
                                    </div>
                                }
                                {(util.hasValue(slot.scheduledTime) || (!util.hasValue(slot.scheduledTime) && slot.isDateTimeFixed)) &&
                                <span onClick={() => this.onFixedValue('isDateTimeFixed')}
                                    className="text-click ">< i
                                        className={slot.isDateTimeFixed ? 'fas fa-lock'
                                            : 'fas fa-lock-open wo-date-lock-icon'} /></span>
                                }
                            </div>
                            :
                            <div>
                                {util.hasValue(slot.scheduledTime) ? util.localizeIsoDateMinutes(slot.scheduledTime)
                                    : ' '}

                            </div>
                        }
                    </td>
                    <td className="duration">
                        {slot.isEditing ?
                            <InputTypeComponent
                                value={slot.scheduledDuration}
                                onChange={(e) => this.handleInputChange(e)}
                                name={'scheduledDuration'}
                                className="form-control "
                                validate={'number|numberMinValue:1|numberMaxValue:10'}
                                errors={errorsMapped.scheduledDuration}
                            />
                            :
                            <>
                                {slot.scheduledDuration !== '' ? Math.round(slot.scheduledDuration * 100) / 100 : '1'}
                            </>
                        }
                    </td>
                    {!this.props.fromWorkOrder ?
                        <td className="workorders">
                            {slot.isEditing && slot.slotUserRole != SlotModel.SUPPORT ?
                                <>
                                    <WorkOrdersSelectInModal
                                        values={slot.workOrderIds}
                                        {...this.props}
                                        onChange={(e) => this.updateInputEvent(e)}
                                    />
                                </>
                                :
                                <div>{slot.workOrderIds != null ? slot.workOrderIds === '-1' ? 'Todas ' : workOrderCode
                                    : 'Todas '}</div>
                            }
                        </td>
                        :
                        <td>
                            {slot.workOrderIds !== this.props.workOrderId ? this.workOrderRelaccionada
                                : 'No hay O.T. relaccionadas '}
                        </td>
                    }
                    <td colSpan={1}>
                        {slot.isFinished ?
                            <a href={this.downloadPDF(slot)} target={'_blank'} rel="noreferrer">
                                <div className="remove-add">
                                    <div className="remove">
                                        <span onClick={() => this.downloadPDF(slot)}
                                            className="fas fa-file-pdf text-click" />
                                    </div>

                                </div>
                            </a>
                            :
                            <>
                                {!this.props.blocked &&
                                this.renderEditBlock(!slot.isFinished && slot.isStarted)
                                }
                            </>
                        }

                    </td>
                </tr>
                <tr style={{ borderBottom: 'none' }} aria-rowspan="3"
                    className={slot.isEditing ? 'editing' : backgroundRow}>
                    <th colSpan={slot.isEditing ? 2 : 3} style={{ paddingBottom: 0 }}>{t('Comentarios')}{' '}<span
                        className="js-info">{t('Estos comentarios no se muestran a los técnicos')}</span></th>
                    <th colSpan={slot.isEditing ? 2 : 1} style={{ paddingBottom: 0 }}>{t('Fecha hasta')}</th>
                    {!slot.isEditing &&
                    <th colSpan={1} style={{ paddingBottom: 0 }}>{t('Código')}</th>
                    }
                </tr>
                <tr style={{ borderBottom: 'none' }} aria-rowspan="2"
                    className={slot.isEditing ? 'editing' : backgroundRow}>
                    <td colSpan={slot.isEditing ? 2 : 3}>
                        {slot.isEditing ?
                            <InputTypeComponent
                                value={slot.comments}
                                onChange={(e) => this.handleInputChange(e)}
                                name={'comments'}
                                className="form-control "
                                errors={errorsMapped.comments}
                            />
                            :
                            <>
                                {util.hasValue(slot.comments) ? slot.comments : ''}
                            </>
                        }
                    </td>

                    <td colSpan={1}>
                        {slot.isEditing ?
                            <DateInputFieldMobiscroll
                                prefix={'fas fa-calendar-alt'}
                                withMinMaxDate={true}
                                value={slot.scheduledTimeUntil}
                                onChange={(e) => this.handleInputChange(e)}
                                name={'scheduledTimeUntil'}
                                type={'text'}
                                minDate={slot.scheduledTime}
                                allowEmptyDate={true}
                                errors={errorsMapped.scheduledTimeUntil}
                                styleCustomProps={true} />
                            :
                            <>
                                {util.hasValue(slot.scheduledTimeUntil) ?
                                    util.getMoment(slot.scheduledTimeUntil).format('DD/MM/YYYY') :
                                    t('Sin fecha')}
                            </>
                        }
                    </td>
                    {!slot.isEditing &&
                    <td><span
                        className="text-click"
                        onClick={() => this.openSlotModal(slot.id)}>
                        {slot.code}
                    </span>
                    </td>

                    }

                </tr>
                {!slot.isFinished && this.containsWorkOrdersReturned() &&
                <tr aria-rowspan="2" className={slot.isEditing ? 'editing' : backgroundRow}>
                    <td colSpan={5}><span
                        className={'text-danger'}><i
                            className="fas exclamationTriangleIcon" />{t('Contiene alguna order de trabajo que está devuelta')}</span>
                    </td>
                </tr>
                }
                {!slot.isFinished && slot.isStarted &&
                <tr aria-rowspan="2" className={slot.isEditing ? 'editing' : backgroundRow}>
                    <td colSpan={5}>
                        <span className={'text-info'}><i
                            className="fas exclamationTriangleIcon" />{t('Esta visita esta iniciada')}</span></td>
                </tr>
                }
                {slot.isEditing && this.canVisitBeStarted &&
                <tr aria-rowspan="2" className={slot.isEditing ? 'editing' : backgroundRow}>
                    <td colSpan={5}>
                        <span className={'text-danger'}><i
                            className="fas exclamationTriangleIcon" />{t('Cuidado! El técnico puede estar trabajando en este pedido ahora mismo, si hace alguna modificación, por favor, PONGANSE EN CONTACTO CON EL')}</span>
                    </td>
                </tr>
                }

                <GqlWarnings warnings={this.gqlWarnings} onSaveWarning={() => this.saveSlotWarning(slot)}
                    key={this.gqlWarnings} setWarnings={(warnings) => {
                        this.setState({ slot: {} })
                        this.gqlWarnings = warnings
                    }} />
                {this.state.popUp === 'eliminar' &&
                <AlertModal isOpen={this.state.popUp === 'eliminar'}
                    onCloseModal={() => this.setState({ popUp: '' })}>
                    {this.state.popUp === 'eliminar' &&
                    <PopUp
                        title={t('Eliminar')}
                        icon="exclamationTriangleIcon"
                        text={t('¿Estas Seguro de Eliminar esta visita?')}
                        label1={t('No eliminar')}
                        label3={t('Eliminar')}
                        onClick1={() => this.setState({
                            rightModal: false,
                            popUp: ''
                        })}
                        onClick3={() => {
                            super.onDeleteRow()
                            this.setState({
                                rightModal: false,
                                popUp: ''
                            })
                        }}
                    >
                    </PopUp>
                    }
                </AlertModal>
                }
            </InfoBlock>
        )
    }

    async onSaveRow () {
        let slot = this.props.row
        slot.saveOptions = this.getModelTable().NOT_SAVE_WITH_WARNINGS
        await super.onSaveRow()
    }

    onDeleteRow () {
        this.setState({
            rightModal: true,
            popUp: 'eliminar'
        })
    }

    getOptionsHours () {
        return ([
            {
                label: '07:00',
                value: 7
            },
            {
                label: '08:00',
                value: 8
            },
            {
                label: '09:00',
                value: 9
            },
            {
                label: '10:00',
                value: 10
            },
            {
                label: '11:00',
                value: 11
            },
            {
                label: '12:00',
                value: 12
            },
            {
                label: '13:00',
                value: 13
            },
            {
                label: '14:00',
                value: 14
            },
            {
                label: '15:00',
                value: 15
            },
            {
                label: '16:00',
                value: 16
            },
            {
                label: '17:00',
                value: 17
            },
            {
                label: '18:00',
                value: 18
            },
            {
                label: '19:00',
                value: 19
            },
            {
                label: '20:00',
                value: 20
            },
        ])
    }

    containsWorkOrdersReturned () {
        let slot = this.props.row
        let workOrders = this.props.order?.workOrder
        let result = false
        if (workOrders?.length > 0) {
            for (let workOrder of workOrders) {
                if (appState.typifiedState.getObject(workOrder.status).code === WorkLogModel.RETURN_WORK_ORDER) {
                    if (slot.workOrderIds=="-1") {
                        result = true
                    } else if (util.perteneceA(workOrder.id, slot.workOrderIds)) {
                        result = true
                    }
                }
            }
        }
        return result
    }

    workOrdersCodes () {
        let slot = this.props.row
        let workOrder = this.props.order?.workOrder
        let result = ''
        if (workOrder && workOrder.length > 0) {
            for (let workor of workOrder) {
                if (slot.workOrderIds=="-1") {
                    result += workor.code + ' '
                } else if (util.perteneceA(workor.id, slot.workOrderIds))
                    result += workor.code + ' '
            }
        }
        this.log({workOrdersCodes:slot.workOrderIds, result, slot_code:slot.code});
        return result
    }

    async workOrderRelaccionadas () {
        let slot = this.props.row
        if (slot.workOrder && slot.workOrder.length > 0) {
            let codigo = ''
            for (let workOr of slot.workOrder) {
                codigo += workOr.code + ' '
            }
            this.workOrderRelaccionada = codigo
        } else {
            this.workOrderRelaccionada = 'No hay O.T. Relaccionadas '
        }
    }

    async saveSlotWarning (slot) {
        this.setState({ loadingSlot: true })
        slot.saveOptions = this.getModelTable().SAVE_WITH_WARNINGS
        try {
            await super.onSaveRow()
        } catch (e) {
            this.log({
                error: 1,
                e
            })
        }
        setTimeout(() => {
            this.setState({ loadingSlot: false })
        }, 1500)
        this.gqlWarnings = []
    }

    openUserModal (userId) {
        this.propsUtil.changeUrlRequest({
            'rightModal': 'modaluserls',
            'userId': userId,
            'rightModalTab': 'general'
        })
    }

    openSlotModal (slotId) {
        this.propsUtil.changeUrlRequest({
            'rightModal': 'modalslots',
            'slotId': slotId,
            'rightModalTab': 'general'
        })
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default OrderFormSlot
