import { observable } from 'mobx';
import AppLogger from "../util/AppLogger";

class BaseModelState {

    /**
     * Guarda la información que devuelve la Query GraphQL. 1 objeto por cada query
     * @type {{}}
     */
    @observable graphData = {
        data:{}
    };

    @observable graphStatus = {
        mutationGraphQlResponse: {
            errors:[]
        },
        mutationLoading: false,
        mutationError: false,
        mutationWarning:false,
        mutationSuccess: false,
        networkWorking: false,
        formLoading:false,
        queryGraphQlResponse: {},
    };


    @observable row = {
        title: "Init",
        id: 0,
    };

    @observable rowStatus = {
        mutationGraphQlResponse: {
            errors:[]
        },
        mutationLoading: false,
        mutationError: false,
        mutationWarning:false,
        mutationSuccess: false,
        networkWorking: false
    };

    @observable listStatus = {
        formLoading:false,
        queryGraphQlResponse: {},
        networkWorking: false
    };

    @observable listData = {
        //deprecated
        rows:[],
        connection:{
            pageInfo: {
                endCursor:"",
                hasNextPage: false,
                hasPreviousPage: false,
                startCursor: ""
            },
            totalCount:0,
            items:[]
        }
    };



    /**
     * Campos necesarios para poder mostrar los campos y el orden asociado en los listados
     * @type {{modalOpen: boolean, listFields: Array, sortableFields: Array, visibleFields: Array}}
     */
    @observable listColumnsSelect= {
        modalOpen:false, //Indica si se debe abrir el desplegable con las columnas posibles
        listFields:[], //Listado de campos inicial
        sortableFields:[], //listado de campos ordenados
        visibleFields:[], //listado de campos visiblers
    }

    /**
     * Array con los objetos de las listas que tienen check activado
     * @type {Array}
     */
    @observable actionList= [];

    /**
     * Campos para mostrar el filtro aplicado
     * @type {{modalOpen: boolean}}
     */
    @observable listFilter= {
        modalOpen:false, //Indica si se debe abrir el desplegable con los filtros
    }

    @observable quickEdit= {
        modalOpen:false, //Indica si se debe abrir el desplegable con los filtros
        row: {}
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseModelState;
