import config from "../config/config";
import util from "../util/Util";

let singleton = null;

class AppLogger {

    withTime = false;

    constructor() {
        //this.withTime=true;
    }

    /**
     * @returns {AppLogger}
     */
    static get() {
        if (singleton == null) {
            singleton = new AppLogger();
        }
        return singleton;
    }

    info(msg, classObj) {
        this.debug(msg, classObj);
    }

    getNowStr() {

        let date = new Date();
        let time = date.getTime();
        let ms = "";
        if (this.oldTime == null) {

        } else {
            ms = time - this.oldTime;
        }
        this.oldTime = time;
        let timeResto = time % 1000;


        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        let seconds = date.getUTCSeconds();

        if (hours < 10)
            hours = "0" + hours;

        if (minutes < 10)
            minutes = "0" + minutes;

        if (seconds < 10)
            seconds = "0" + seconds;

        if (timeResto < 10) {
            timeResto = "00" + timeResto;
        } else if (timeResto < 100) {
            timeResto = "0" + timeResto;
        }
        return hours + ":" + minutes + ":" + seconds + "." + timeResto + "(" + ms + "ms)";

    }

    debug(msg, classObj) {
        let className = "[]";
        if (classObj) {
            className = classObj.constructor.name;
        }
        let message = "";
        if (this.withTime) {
            message = this.getNowStr();
        }
        message += "[" + className + "] ";
        if (!config.isLive || util.getCookie('console') === "true") {
            if (typeof msg === 'string') {
                message += msg;
                consoleapp.log(message);
            } else {
                consoleapp.log(message + " =>");
                consoleapp.log(msg);
            }
        }
    }

}


export default AppLogger;
