import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import Select2Component from "../../components/fields/Select2Component";
import appState from '../../state/AppState';
import FormButtons from "../../components/FormButtons";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import translate from "../../translator/translate";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import BaseWorkOrderForm from "../base/BaseWorkOrderForm";
import AssetModel from "../../models/AssetModel";
import OrderTaskTable from "../orders/OrderTaskTable";
import RightModalInList from "../../subpages/RightModalInList";
import OrderTaskModal from "../orders/OrderTaskModal";
import DateInputFieldMobiscroll from "../../components/fields/DateInputFieldMobiscroll";
import GqlErrors from "../../components/status/GqlErrors";
import OrderModel from "../../models/OrderModel";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import WorkOrderModel from "../../models/WorkOrderModel";
import VsfButtonNew from "../../components/VsfButtonNew";
import OFFER from "../../config/OFFER";
import SelectSuggestComponent from "../../components/fields/SelectSuggestComponent";
import {observable} from "mobx";

@observer
class WorkOrderCCForm extends BaseWorkOrderForm {

    async componentDidMountImpl() {
        super.componentDidMountImpl();
        if (util.hasValue(this.propsUtil.getRequest("assetId"))) {
            let assetId = this.propsUtil.getRequest("assetId");
            await appState.assetState.reloadAssetFromAssetId(assetId);
            this.handleAutosuggestSelection("assetId", assetId);
            this.graphDataMainType.asset = appState.assetState.asset;
            this.handleAutosuggestSelection("assetPlate", this.graphDataMainType.asset?.plate);
        }
        this.log({ componentDidMount: 1 })
    }

    componentWillReceiveProps(nextProps) {
        this.log({
            nextProps: nextProps.order.client?.id,
            props: this.props.order.client?.id,
            lastClientId: this.lastClientId
        })
        if (this.lastClientId !== this.props.order.client?.id) {
            this.changeSelect = !this.changeSelect;
            this.lastClientId = !nextProps.order.client?.id;
        }
    }

    @observable lastClientId = "";
    @observable changeSelect = false;

    /**
     * Obtiene los Assets en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @returns Array
     */
    async getAssetsSugestions(value) {
        let assetsArray = [];
        // Abortamos la anterior llamada
        this.abortFetch();
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController();
            let assetsQuery = new AssetModel();
            if (util.hasValue(this.graphDataMainType.client?.id)) {
                assetsQuery.storeId = this.graphDataMainType.client.id;
            } else if (util.hasValue(this.props.order.client?.id)) {
                assetsQuery.storeId = this.props.order.client?.id;
            }
            assetsQuery.filters = [
                { "fieldName": "plate", "fieldValue": value, "filterOperator": "SUBSTR" },
            ];
            let assets = await assetsQuery.find();
            assets.map((asset) => {
                let assetObject = {};
                assetObject.label = asset.plate;
                assetObject.value = asset;
                assetsArray.push(assetObject);
            });
        } catch (e) {
            this.log("Error => " + e);
        }
        return assetsArray;
    }

    getFormId() {
        let result = super.getFormId();
        if (this.props.fromOrderForm) {
            result = this.props.workOrder?.id;
        }
        return result;
    }

    async saveOrderBeforeWorkOrder() {
        if (this.props.changeFormOrder || !util.hasValue(this.props.order.id)) {
            await this.props.saveOrder();
        }
        this.handleAutosuggestSelection("orderId", this.props.order.id);
        if (appState.configState.checkOrderCocaCola(this.props.order)) {
            this.graphDataMainType.type = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORK_ORDER_TYPE, 'INT').id;
            try {
                this.graphDataMainType.subType = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORK_ORDER_TYPE, JSON.parse(this.props.order.dynamicField).sapNotifType).id;
            } catch (e) {
                this.log({ error: 1, e })
            }
        }
    }

    verXMLIntegracion(workOrder) {
        let workOrderModel = new WorkOrderModel();
        return workOrderModel.verXMLIntegracion(workOrder.id);
    }

    integrarWorkOrder(workOrder) {
        let workOrderModel = new WorkOrderModel();
        return workOrderModel.integrarWorkOrder(workOrder.id);
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        let workOrder = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (this.graphStatus.mutationError > 0) {
            this.state.errorFormulario = true;
        }
        let modalClasses = util.getModalClasses();
        if (this.props.fromOrderForm) {
            modalClasses = { body: "row", };
        }
        let relatedInfo = "";
        if (util.hasValue(workOrder.asset?.manufacturer)) {
            relatedInfo = util.getString(workOrder.asset?.manufacturer);
        }
        if (util.hasValue(workOrder.asset?.model)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.model);
        }
        if (util.hasValue(workOrder.asset?.serialNumber)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.serialNumber);
        }
        if (util.hasValue(workOrder.asset?.posAddress)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.posAddress);
        }
        if (util.hasValue(workOrder.asset?.location)) {
            relatedInfo += " - " + util.getString(workOrder.asset?.location);
        }
        let orderModel = new OrderModel();
        orderModel.addRelatedTable("originModel");
        orderModel.hidrate(this.props.order);
        let optionsType = orderModel.getValuesForSelect(TypifiedValueModel.CODE_WORK_ORDER_TYPE);
        let origin = this.propsUtil.getRequest("origin");

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <form
                        className="model-form"
                        name="formulario"
                        noValidate
                        onSubmit={(e) => this.handleFormSubmit(e)}>
                        <div className={'card mb-3'}>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className={modalClasses.body}>
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                                    <Select2Component
                                        value={workOrder.type}
                                        onChange={(e) => this.updateInputEventWorkOrderType(e)}
                                        editable={this.editable}
                                        name={"type"}
                                        isClearable={false} required
                                        title={t("Tipo Orden de Trabajo")}
                                        classGroup={"col-md-4 col-lg-4"}
                                        options={optionsType}
                                        errors={errorsMapped.type}
                                    />
                                    <Select2Component
                                        value={workOrder.subType}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"subType"}
                                        editable={this.editable}
                                        readOnly={!util.hasValue(workOrder.type)}
                                        title={t("Subtipo Orden de Trabajo")}
                                        classGroup={"col-md-4 col-lg-4"}
                                        options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_WORK_ORDER_TYPE, workOrder.type)}
                                        errors={errorsMapped.subType}
                                    />
                                    <div className="col-3" />
                                    <SelectSuggestComponent
                                        value={workOrder.asset?.plate}
                                        onChange={(assetSelected) => {
                                            if (util.hasValue(assetSelected.id)) {
                                                this.handleAutosuggestSelection("assetPlate", assetSelected.plate);
                                                this.handleAutosuggestSelection("assetId", assetSelected.id);
                                                this.props.changeClient(assetSelected.storeId);
                                                workOrder.asset = assetSelected;
                                                this.log({ workOrder, assetSelected })
                                            } else {
                                                this.handleAutosuggestSelection("assetId", "");
                                                workOrder.asset = {};
                                            }
                                        }}
                                        name={"assetPlate"}
                                        title={t("Número de Equipo")}
                                        editable={this.editable}
                                        classGroup={"col-md-4"}
                                        changeSelect={this.changeSelect}
                                        errors={errorsMapped.assetPlate}
                                        info={t('Matrícula o Placa')}
                                        loadSuggestions={(value) => this.getAssetsSugestions(value)}
                                        placeholder={t("Escribe...")}
                                        relatedInfo={relatedInfo}
                                    />
                                    <Select2Component
                                        value={util.hasValue(workOrder.assetId) ?
                                            workOrder.asset?.type
                                            : workOrder.assetType}
                                        onChange={(e) => this.updateInputEventAssetType(e)}
                                        editable={this.editable}
                                        name={"assetType"}
                                        isClearable={false}
                                        readOnly={util.hasValue(workOrder.assetId)}
                                        title={t("Tipo de Equipo")} required
                                        classGroup={"col-md-3"}
                                        placeholder={t("Tipo de Equipo")}
                                        errors={errorsMapped.assetType}
                                        options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ASSET_TYPE)}
                                    />
                                    <Select2Component
                                        value={util.hasValue(workOrder.assetId) ?
                                            workOrder.asset?.subType
                                            : workOrder.assetSubType}
                                        onChange={(e) => this.updateInputEvent(e)}
                                        name={"assetSubType"}
                                        editable={this.editable}
                                        title={t("Subtipo de Equipo")}
                                        placeholder={t("Subtipo de Equipo")}
                                        readOnly={util.hasValue(workOrder.assetId) || !util.hasValue(workOrder.assetType)}
                                        classGroup={"col-md-3"}
                                        errors={errorsMapped.assetSubType}
                                        options={appState.typifiedState.getLabelValuesForSelectChilds(TypifiedValueModel.CODE_ASSET_SUBTYPE, workOrder.assetType)} />
                                    <div className="col-md-2 pr-0 pl-0 d-flex align-items-end"
                                         style={{ marginBottom: 5 }}>
                                        <div className="button-form-group">
                                            {util.hasValue(workOrder.assetId) ?
                                                <span className="link-underline ico ico--edit"
                                                      onClick={() => this.onEditarAsset(workOrder)}
                                                >{t("Editar Datos del Equipo")}</span>
                                                :
                                                <span
                                                    className="link-underline disabled prohibido ico ico--edit"
                                                >{t("Editar Datos del Equipo")}</span>
                                            }
                                        </div>
                                    </div>
                                    {this.dataDynamicOrder()}
                                    {OFFER.xml_integration &&
                                    <div className="col-12 text-right">
                                        <div className="row">
                                            {util.hasValue(workOrder?.id) &&
                                            <div className={"mt-2"}>
                                                <a href={this.verXMLIntegracion(workOrder)} target={'_blank'}>
                                                    <VsfButtonNew className="btn btn--orange col-12"
                                                                  onClick={() => this.verXMLIntegracion(workOrder)}>
                                                        {t("Ver XML")}
                                                    </VsfButtonNew>
                                                </a>
                                            </div>
                                            }
                                            {util.hasValue(workOrder?.id) &&
                                            <div className={"mt-2"}>
                                                <a href={this.integrarWorkOrder(workOrder)} target={'_blank'}>
                                                    <VsfButtonNew className="btn btn--orange col-12"
                                                                  onClick={() => this.integrarWorkOrder(workOrder)}>
                                                        {t("Integrar")}
                                                    </VsfButtonNew>
                                                </a>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    }
                                    {this.props.fromRightModal ?
                                        <div className=" fixed-bottom">
                                            {OFFER.xml_integration &&
                                            <div className="col-4">
                                                {util.hasValue(workOrder?.id) &&
                                                <div className={"mt-3"}>
                                                    <a href={this.verXMLIntegracion(workOrder)} target={'_blank'}
                                                       className={"col-12"}>
                                                        <VsfButtonNew className="btn btn--orange col-12"
                                                                      onClick={() => this.verXMLIntegracion(workOrder)}>
                                                            {t("Ver XML")}
                                                        </VsfButtonNew>
                                                    </a>
                                                </div>
                                                }
                                                {util.hasValue(workOrder?.id) &&
                                                <div className={"mt-3"}>
                                                    <a href={this.integrarWorkOrder(workOrder)} target={'_blank'}
                                                       className={"col-12"}>
                                                        <VsfButtonNew className="btn btn--orange col-12"
                                                                      onClick={() => this.integrarWorkOrder(workOrder)}>
                                                            {t("Integrar")}
                                                        </VsfButtonNew>
                                                    </a>
                                                </div>
                                                }
                                            </div>
                                            }
                                            <FormButtons formStatus={this.graphStatus} />
                                        </div>
                                        :
                                        <div className="col-12">
                                            <FormButtons formStatus={this.graphStatus} />
                                        </div>
                                    }
                                    <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                                                onCloseModal={() => this.setState({
                                                    rightModal: false,
                                                    popUp: ""
                                                })}
                                    >
                                        {this.state.popUp == "eliminar" &&
                                        <PopUp
                                            title={t('Eliminar')}
                                            subtitle={t('Orden de trabajo ' + workOrder.code)}
                                            icon="exclamationTriangleIcon"
                                            text={t('¿Estas Seguro de Eliminar esta orden de trabajo y todos sus datos relaccionados?')}
                                            label1={t('No eliminar')}
                                            label2={t('Eliminar')}
                                            onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                                            onClick2={(workOrder) => this.deleteModel("workOrder")}
                                        >
                                        </PopUp>
                                        }
                                    </AlertModal>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>

                    </form>
                </InfoBlock>
            </div>
        )
    }

    dataDynamicOrder() {
        let order = this.props.order;
        const t = translate;
        let errorsMapped = this.getGraphErrorsFromStatus();
        if (this.graphStatus.mutationError > 0) {
            this.state.errorFormulario = true;
        }
        let workOrder = this.graphDataMainType;
        return (
            <>
                <InputTypeComponent
                    value={order.comments}
                    onChange={(e) => this.props.updateInputEvent(e)}
                    name={"comments"}
                    editable={this.editable}
                    title={t("Comentarios")}
                    classGroup={"col-md-6 col-lg-12"}
                    type={"text"}
                    info={t('Ej: Por favor, avisar el día antes de la visita')}
                    errors={errorsMapped.comments}
                />
                {order.dynamicField &&
                appState.dynamicGroupState.inputsAssetsOrder(JSON.parse(order.dynamicField).sapNotifType).map(code => {
                    if (appState.dynamicGroupState.dynamicsFieldsDict[code]) {
                        if (appState.dynamicGroupState.dynamicsFieldsDict[code].type === "TEXT") {
                            return (
                                <InputTypeComponent
                                    value={JSON.parse(order.dynamicField)[code]}
                                    onChange={(e) => this.props.updateInputEventDynamicFields(e.target.name, e.target.value,
                                        { code: appState.dynamicGroupState.dynamicsFieldsDict[code].codeGroup })}
                                    classGroup={"col-md-6 col-xl-3"}
                                    editable={this.editable}
                                    name={code}
                                    title={appState.dynamicGroupState.dynamicsFieldsDict[code].label}
                                    type={"text"}
                                    className="form-control"
                                />
                            )
                        }
                        if (appState.dynamicGroupState.dynamicsFieldsDict[code].type === "SELECT") {
                            return (
                                <Select2Component
                                    value={JSON.parse(order.dynamicField)[code]}
                                    onChange={(e) => this.props.updateInputEventDynamicFields(e.target.name, e.target.value,
                                        { code: appState.dynamicGroupState.dynamicsFieldsDict[code].codeGroup })}
                                    classGroup={"col-md-6 col-xl-3"}
                                    name={code}
                                    options={appState.configState.getCodeProblemForSelect(JSON.parse(order.dynamicField).sapNotifType)}
                                    editable={this.editable}
                                    title={appState.dynamicGroupState.dynamicsFieldsDict[code].label}
                                    type={"text"}
                                    className="form-control"
                                />
                            )
                        }
                        if (appState.dynamicGroupState.dynamicsFieldsDict[code].type === "DATE") {
                            return (
                                <DateInputFieldMobiscroll
                                    value={JSON.parse(order.dynamicField)[code]}
                                    onChange={(e) => this.props.updateInputEventDynamicFields(e.target.name, e.target.value,
                                        { code: appState.dynamicGroupState.dynamicsFieldsDict[code].codeGroup })}
                                    classGroup={"col-md-6 col-xl-3"}
                                    name={code}
                                    editable={this.editable}
                                    allowEmptyDate={true}
                                    title={appState.dynamicGroupState.dynamicsFieldsDict[code].label}
                                    type={"text"}
                                    className="form-control"
                                />
                            )
                        }
                    }
                })
                }

                {(util.hasValue(workOrder.assetId) && order.dynamicField && appState.configState.tableTasksOrder(JSON.parse(order.dynamicField).sapNotifType)) &&
                <OrderTaskTable order={order} openModalTask={() => this.setState({ task: true })}
                                onCloseModal={() => this.setState({ task: false })}
                                saveOrderTasks={() => this.saveOrderTasks()} listOrderForm={true}
                />
                }
                <RightModalInList
                    isOpen={this.state.task}
                    onCloseModal={() => this.setState({ task: false })}>
                    <OrderTaskModal
                        orderId={order.id} asset={this.graphDataMainType?.asset}
                        fromRightModal={true}
                        sapNotifType={order.dynamicField && JSON.parse(order.dynamicField).sapNotifType}
                        onCloseModal={() => this.setState({ task: false })}
                        saveOrderTasks={() => this.saveOrderTasks()}
                    />
                </RightModalInList>

            </>

        )
    }

    async saveOrderTasks() {
        if (appState.workLogState.saveWorkLogOrder) {
            await appState.workLogState.insertOrderTasks(this.graphDataMainType.clientId, this.props.order.id,
                appState.workLogState.workLogsTasksOfOrder);
            appState.workLogState.saveWorkLogOrder = false;
        }
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(WorkOrderCCForm)

