import React from 'react'
import { observer } from 'mobx-react'
import appState from '../../state/AppState'
import BaseForm from '../base/BaseForm'
import translate from '../../translator/translate'
import FormButtons from '../../components/FormButtons'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import Select2Component from '../../components/fields/Select2Component'
import DateInputFieldMobiscroll from '../../components/fields/DateInputFieldMobiscroll'
import ArticleModel from '../../models/ArticleModel'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import RadioButtonComponent from '../../components/fields/RadioButtonComponent'
import MoreButtons from '../../components/MoreButtons'
import AlertModal from '../../subpages/AlertModal'
import PopUp from '../../components/modals/PopUp'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import GqlErrors from '../../components/status/GqlErrors'
import PropsUtil from '../../util/PropsUtil'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import ArticleActions from './ArticleActions'
import CreateAndModify from "../../components/fields/CreateAndModify";
import VsfButtonNew from "../../components/VsfButtonNew";
import ArticlePrizeConsultModal from "./ArticlePrizeConsultModal";

@observer
class ArticleForm extends BaseForm {

    constructor(props) {
        super(props);
        this.nameMainType = "article";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.state = {
            errorFormulario: false,
            loading: true,
            copyArticle: {},
            modalClient: false
        }
    }


    getModelQuery() {
        return new ArticleModel();
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }

    duplicar(article) {
        this.setState(article.id = null)
        this.handleFormSubmit()
    }

    clickErroresFormulario() {
        this.setState({
            errorFormulario: false
        });
        this.graphStatus.mutationError = 0;
    }

    renderImpl() {
        const t = translate;
        let id = this.getFormId() || this.props.id;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let article = this.graphDataMainType;
        if (article == null) {
            article = {}
        }
        let errorsMapped = this.getGraphErrorsFromStatus();
        let textoErrores;
        if (this.graphStatus.mutationError > 0) {
            textoErrores = errorsMapped[""].map(error => error.message)
            this.state.errorFormulario = true;
        }

        let optionsSiNo = [
            { label: "si", value: "1", id: "1" },
            { label: "no", value: "0", id: "0" },
        ];
        let optionsWarranty = [
            { label: "si", value: "1", id: "warranty1" },
            { label: "no", value: "0", id: "warranty0" },
        ];
        let optionsItemType = [
            { label: "Material", value: ArticleModel.CODE_MATERIAL },
            { label: "Inmaterial", value: ArticleModel.CODE_INMATERIAL },
        ];
        let optionsSerialType = [
            { label: "Lote", value: ArticleModel.CODE_LOT },
            { label: "Serial", value: ArticleModel.CODE_SERIAL },
        ];
        let optionsServiceType = [
            { label: "Artículo", value: ArticleModel.CODE_ARTICLE },
            { label: "Desplazamiento", value: ArticleModel.CODE_DESLOCALIZATION },
            { label: "Mano de obra", value: ArticleModel.CODE_WORKFORCE },
        ];

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                mode={"edit"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <ArticleActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={this.graphDataMainType}
                                                    getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>

                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataArticles()}
                                active={"Datos Generales"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" "}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        > <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}
                            >
                                <ArticleActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={this.graphDataMainType}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <div className={'card'}>
                                    {!this.props.fromRightModal &&
                                        <TabsComponent
                                            id={this.graphDataMainType.id}
                                            tabs={this.tabsData.getDataArticles()}
                                            active={"Datos Generales"}
                                        />
                                    }
                                    <NetworkStatusInfo loading={this.graphStatus.mutationLoading}
                                        error={this.graphStatus.mutationError}
                                        working={this.graphStatus.networkWorking}
                                    />

                                    <GqlErrors errors={this.gqlErrors}
                                        setErrors={(errors) => this.setErrors(errors)} />
                                    <GroupOfField title={t("Datos")}
                                        subtitle={t("Artículo")}
                                        icon="fas fa-toolbox">
                                        <CreateAndModify
                                            updatedAt={article.updatedAt}
                                            updatedBy={article.updatedBy}
                                            createdBy={article.createdBy}
                                            createdAt={article.createdAt}
                                        />
                                    </GroupOfField>
                                    <SpinnerClipLoaderInPage loading={this.state.loading}>
                                        <div className={"row"}>
                                            <InputTypeComponent
                                                value={article.code}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"manufacturer"}
                                                title={t("Referencia (Interna)")}
                                                placeholder=""
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.manufacturer}
                                                readOnly={true}
                                            />

                                            <Select2Component
                                                value={article.type}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                editable={this.editable}
                                                name={"type"}
                                                title={t("Tipo")}
                                                classGroup="col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ARTICLE_TYPE)}
                                                //errors={errorsMapped.emploiment}
                                            />

                                            <div className={"col-lg-4"} style={{paddingTop: '19px'}}>
                                                <VsfButtonNew
                                                    formButton={false}
                                                    label={t("Consultar precio")}
                                                    className={"btn btn--loader btn--large btn-block"}
                                                    style={{width:'100%'}}
                                                    onClick={_ => {
                                                        this.setState({
                                                            modalClient: true
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <InputTypeComponent
                                                value={article.description}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"description"}
                                                editable={this.editable}
                                                title={t("Descripción")}
                                                placeholder="Descripción"
                                                classGroup={"col-8"}
                                                type={"text"}
                                                errors={errorsMapped.description}
                                            />

                                            <InputTypeComponent
                                                value={article.codeErp}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"codeErp"}
                                                title={t("Code ERP")}
                                                placeholder=""
                                                editable={this.editable}
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.codeErp}
                                                readOnly={true}
                                            />

                                            <Select2Component
                                                value={article.itemType}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"itemType"}
                                                editable={this.editable}
                                                title={t("Material/Inmaterial")}
                                                classGroup="col-lg-4"
                                                options={optionsItemType}
                                            />

                                            <Select2Component
                                                value={article.serialType}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                editable={this.editable}
                                                name={"serialType"}
                                                title={t("Tipo de serial")}
                                                classGroup="col-lg-4"
                                                options={optionsSerialType}
                                            />

                                            <Select2Component
                                                value={article.serviceType}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                editable={this.editable}
                                                name={"serviceType"}
                                                title={t("Tipo de servicio")}
                                                isClearable={false}
                                                classGroup="col-lg-4"
                                                options={optionsServiceType}
                                            />

                                            <InputTypeComponent
                                                value={article.documentationCodes}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"documentationCodes"}
                                                editable={this.editable}
                                                title={t("Códigos de referencia en manuales")}
                                                placeholder="Códigos de referencia en manuales"
                                                classGroup={"col-12"}
                                                type={"text"}
                                                info={t('Es una ayuda para que los técnicos puedan encontrar este artículo en su aplicación')}
                                                relatedInfo={t('Los códigos deben estar separados por espacios, máx. 10 códigos')}
                                                errors={errorsMapped.description}
                                            />

                                            <InputTypeComponent
                                                value={article.saleUnit}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"saleUnit"}
                                                editable={this.editable}
                                                title={t("Unidad de venta")}
                                                placeholder="Unidad de venta"
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.description}
                                            />

                                            <InputTypeComponent
                                                value={article.purchaseUnit}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"purchaseUnit"}
                                                editable={this.editable}
                                                title={t("Unidad de compra")}
                                                placeholder="Unidad de compra"
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.description}
                                            />

                                            <InputTypeComponent
                                                value={article.stockUnit}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"stockUnit"}
                                                editable={this.editable}
                                                title={t("Unidad de stock")}
                                                placeholder="Unidad de stock"
                                                classGroup={"col-lg-4"}
                                                type={"text"}
                                                errors={errorsMapped.stockUnit}
                                            />

                                            <Select2Component
                                                value={article.family}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                editable={this.editable}
                                                name={"family"}
                                                title={t("Familia")}
                                                classGroup="col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ARTICLE_FAMILY)}
                                                //errors={errorsMapped.emploiment}
                                            />

                                            <Select2Component
                                                value={article.subfamily}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"subfamily"}
                                                editable={this.editable}
                                                title={t("Sub-Familia")}
                                                classGroup="col-lg-4"
                                                options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ARTICLE_SUBFAMILY)}
                                                //errors={errorsMapped.emploiment}
                                            />

                                            <RadioButtonComponent
                                                value={article.enable}
                                                title={t("Disponibilidad")}
                                                styleLabel={'m-5'}
                                                options={optionsSiNo}
                                                editable={this.editable}
                                                checked={"1"}
                                                name={"enable"}
                                                classGroup={"col-md-5 col-lg-4"}
                                                onChange={(e) => this.updateInputEvent(e)}
                                            />

                                            <InputTypeComponent
                                                value={article.priceWithTax}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"priceWithTax"}
                                                editable={this.editable}
                                                title={t("Precio con tasa")}
                                                placeholder="Precio con tasa"
                                                classGroup={"col-lg-4"}
                                                validate={"number"}
                                                errors={errorsMapped.priceWithTax}
                                            />

                                            <InputTypeComponent
                                                value={article.priceWithoutTax}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"priceWithoutTax"}
                                                editable={this.editable}
                                                title={t("Precio sin tasa")}
                                                placeholder="priceWithoutTax"
                                                classGroup={"col-lg-4"}
                                                validate={"number"}
                                                errors={errorsMapped.priceWithoutTax}
                                            />

                                            <InputTypeComponent
                                                value={article.tax}
                                                onChange={(e) => this.updateInputEvent(e)}
                                                name={"tax"}
                                                editable={this.editable}
                                                title={t("Tasa")}
                                                placeholder="Tasa"
                                                classGroup={"col-lg-4"}
                                                validate={"number"}
                                                errors={errorsMapped.tax}
                                            />

                                            <RadioButtonComponent
                                                value={article.warranty || "0"}
                                                title={t("Garantía")}
                                                styleLabel={'m-5'}
                                                name={"warranty"}
                                                editable={this.editable}
                                                checked={"0"}
                                                options={optionsWarranty}
                                                classGroup={"col-md-4 col-lg-3 col-xl-2"}
                                                onChange={(e) => this.updateInputEvent(e)}
                                            />
                                            {article.warranty == 1 ?
                                                <DateInputFieldMobiscroll
                                                    value={article.endDateWarranty}
                                                    prefix={"fas fa-calendar-alt"} dateWithoutTimeZone
                                                    title={t("Fecha Fin de Garantía")}
                                                    editable={this.editable}
                                                    onChange={(e) => this.updateInputEvent(e)}
                                                    name={"endDateWarranty"}
                                                    type={"text"}
                                                    classGroup={"col-md-6 col-lg-4 col-xl-4"}

                                                />
                                                :
                                                <InputTypeComponent
                                                    value={""}
                                                    title={t("Fecha Fin de Garantía")}
                                                    classGroup={"col-md-6 col-lg-4 col-xl-4"}
                                                    readOnly={true}
                                                />
                                            }

                                        </div>
                                    </SpinnerClipLoaderInPage>
                                </div>

                                {this.props.fromRightModal ?
                                    <>
                                        <div className={'fixed-bottom-space-maker'} />
                                        <div className="fixed-bottom mr-3 mt-3">
                                            <FormButtons id={id} formStatus={this.graphStatus} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-12">
                                        <FormButtons id={id} formStatus={this.graphStatus} />
                                    </div>
                                }
                            </form>
                        </div>
                    </div>



                    <AlertModal isOpen={this.state.errorFormulario}
                        onCloseModal={() => this.clickErroresFormulario()}>
                        <PopUp
                            title={t('Error')}
                            icon="exclamationTriangleIcon"
                            text={textoErrores}
                            label1='Aceptar'
                            onClick1={() => this.clickErroresFormulario()}
                        >
                        </PopUp>
                    </AlertModal>

                    {this.state.modalClient &&
                    <ArticlePrizeConsultModal
                        fromRightModal={this.props.fromRightModal}
                        openModal={this.state.modalClient}
                        closeModal={() => this.setState({ modalClient: false })}
                        article={article}
                    />
                    }
                </InfoBlock>


            </div>

        )
    }
}


export default ArticleForm
