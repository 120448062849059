import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import appState from '../state/AppState'
import VsfHeader from '../components/VsfHeader'
import VsfFooter from '../components/VsfFooter'
import VsfBody from '../components/VsfBody'
import translate from '../translator/translate'
import AppLogger from '../util/AppLogger'
import PageNoData from '../pages/PageNoData'
import PropsUtil from '../util/PropsUtil'
import PageMaintenance from '../pages/PageMaintenance'
import VsfHeaderWebLinkStylesheet from '../components/VsfHeaderWebLinkStylesheet'
import DebugObjects from "../components/DebugObjects";

@observer
class AppWrapper extends Component {

    constructor() {
        super();
        this.state = { "sidebarOpened": false };
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location?.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        let props = this.props;
        let classApp = "";
        if (appState.layoutState.sidebarMinimized) {
            classApp = "App web-body header-minimized sidebar-minimized";
        } else {
            classApp = "App web-body";
        }
        //Si no estoy logado no muestro sidebar
        if (!appState.loginState.isUserLogged || this.ifPathStartsWith("/externalOrder")) {
            return (
                <>
                    {props.children}
                </>
            )
        }

        if (appState.maintenance) {
            return (
                <>
                    <VsfHeaderWebLinkStylesheet />
                    <PageMaintenance {...this.props} />
                </>
            )
        }
        if (appState.layoutState.mobileWmsHtmlWrapper) {
            return (
                <div className={classApp}>
                    <meta name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    <link rel="stylesheet" href="/css/fontawesome.css" crossOrigin="anonymous" />
                    <link href="https://fonts.googleapis.com/css?family=Raleway:300,400&amp;display=swap"
                        rel="stylesheet" />
                    <div className="web-main">
                        <main className="main">
                            <div className="container-fluid">
                                {appState.pageNoData ?
                                    <PageNoData {...this.props} />
                                    :
                                    props.children
                                }
                            </div>
                        </main>
                    </div>

                </div>
            )
        }
        return (
            <div className={classApp}>
                <DebugObjects key={this.props.location.key}/>
                <VsfHeader  {...this.props} />
                <VsfBody {...this.props} />
                <VsfFooter />
            </div>
        );
    }

}

export default withRouter(AppWrapper)
