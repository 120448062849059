import UserModel from "../models/UserModel";

class ClassFactory {

    constructor() {
        this.UserModel = UserModel;
    }
}

export default new ClassFactory();
