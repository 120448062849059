
export default class AppException {

    message="";

    constructor(message) {
        this.message=message;
    }

    getMessage() {
        let result=this.message;
        return result;
    }
}