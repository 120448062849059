import React, { Component } from 'react';
import { observer } from 'mobx-react';
import FieldListComponent from './FieldListComponent';
import AppLogger from "../../util/AppLogger";
import PropTypes from 'prop-types';
import SpinnerClipLoaderComponent from "../../network/SpinnerClipLoaderComponent";
import ScrollEvent from "react-onscroll";
import SortTableHeadsComponent from "./SortTableHeadsComponent";

@observer
export default class ListComponent extends Component {

    static defaultProps = {
        openQuickEdit: () => {
        }
    };

    static propTypes = {
        //Acci�n de pulsar en el bot�n de quick view
        openQuickEdit: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            scrollLeft: 0,
            scrollWidth: 0,
            offsetWidth: 0,
        };
        this.myRef = React.createRef()
    };

    handleScrollCallback() {
        const topPos = this.myRef?.getBoundingClientRect().top + window.pageYOffset;
        // this.log({
        //     handleScrollCallback: 1, getBoundingClientRect: this.myRef?.getBoundingClientRect,
        //     myRef: this.myRef, topPos,
        //     scrollY: window.scrollY, pageYOffset: window.pageYOffset,
        //     offsetTop: this.myRef?.offsetTop,
        // })
        if (window.scrollY >= topPos) {
            // visible = true;
            this.child.setVisibleHeader(true)
        } else {
            this.child.setVisibleHeader(false)

        }
    }

    render() {
        let props = this.props;
        return (
            <div className="table-main-list">
                <div className="table-responsive list-component" onScroll={(e) => this.child.handleScroll(e)}
                     ref={myRef => {
                         this.myRef = myRef
                     }}>
                    <ScrollEvent handleScrollCallback={() => this.handleScrollCallback()} />
                    {props.status.formLoading &&
                    <SpinnerClipLoaderComponent />
                    }

                    <table id="sortTable" className={"table"} cellSpacing="0">
                        <SortTableHeadsComponent fields={props.fields} status={props.status.formLoading}
                                                 quickEdit={props.quickEdit} quickView={props.quickView}
                                                 withoutCache={props.withoutCache} location={props.location}
                                                 fromRightModal={props.fromRightModal}
                                                 onRef={ref => (this.child = ref)} />
                        <FieldListComponent urlForm={props.urlForm} withoutCache={props.withoutCache}
                                            {...props} listState={props.listState}
                                            listData={props.listData}
                                            status={props.status}
                                            openQuickEdit={props.openQuickEdit}
                                            fields={props.fields} />
                    </table>
                </div>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
