import React from 'react'
import AbstractModel from './AbstractModel'
import BudgetArticleModel from './BudgetArticleModel'
import WorkOrderModel from './WorkOrderModel'
import ClientModel from './ClientModel'
import OrderModel from './OrderModel'
import SlotModel from './SlotModel'
import t from '../translator/translate'

export default class BudgetModel extends AbstractModel {

    static CODE_APPROVED = "APPROVED";
    static CODE_APPROVED_SCHEDULED = "APPROVED_SCHEDULED";
    static CODE_NOT_APPROVED_CLOSED = "CODE_NOT_APPROVED_CLOSED";
    static CODE_NOT_APPROVED = "NOT_APPROVED";
    static CODE_BORRADOR = "DRAFT";
    static CODE_CANCEL = "CANCEL";
    static CODE_EXPIRED = "EXPIRED";
    static CODE_PENDING_ERP = "PENDING_ERP";
    static CODE_CONFIRMED_ERP = "CONFIRMED_ERP";

    id;
    code;
    orderId;
    comments;
    totalTime;
    workOrderId;
    propietaryId;
    clientId;
    slotId;
    createdFrom;
    totalPrizeWithTax;
    dateRejected;
    dateApproved;
    codeErpVisible;
    title;
    quantityArticles;
    endDateBudget;
    status;
    codeErp;
    integrationErpDate;
    integrationErpStatus;
    nameMainType = "budget";
    graphFindByIdOperation = "budget";
    graphFindOperation = "budgetsConnection";
    graphExportOperation = "budgetsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "id": { label: "ID" },
            "code": { label: "Código" },
            "orderId": { label: "Código del pedido", relationName: "order", relationValue: "code" },
            "quantityArticles": { label: "Cantidad" },
            "clientId": { label: "Código del cliente", relationName: "client", relationValue: "name" },
            "workOrderId": { label: "ID de la orden de trabajo", relationName: "workOrder", relationValue: "code" },
            "dateRejected": { label: "Fecha rechazada" },
            "dateApproved": { label: "Fecha aprobada" },
            "comments": { label: "Comentarios" },
            "title": { label: "Título" },
            "slotId": { label: "Código de la visita", relationName: "slot", relationValue: "code" },
            "totalTime": { label: "Tiempo total" },
            "status": { label: "Estado" },
            "codeErpVisible": { label: "Referencia ERP" },
            "totalPrizeWithTax": "Precio total con Impuestos",
            "codeErp": { label: "Código ERP", readonly: true },
            "createdFrom": { label: "Creado desde" },
            "propietaryId": { label: "Cliente fiscal", readonly: true },
            "integrationErpDate": { label: "Fecha ERP", readonly: true },
            "integrationErpStatus": { label: "Estado ERP", readonly: true },
            "endDateBudget": { label: "Fecha fin de presupuesto" },
        };
        this.addRelatedTableGeneric(result, "budgetArticles", "Relation", BudgetArticleModel);
        this.addRelatedTableGeneric(result, "workOrder", "RelationOne", WorkOrderModel);
        this.addRelatedTableGeneric(result, "order", "RelationOne", OrderModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "propietary", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "slot", "RelationOne", SlotModel);

        return result;
    }

    /**
     * Campos que recibe de GraphQL en la respuesta de un persist
     * @returns {string[]}
     */
    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["code", "quantityArticles", "totalTime", "totalPrizeWithTax", "integrationErpStatus", ...baseFields];
    }

    static getTextFromCode(code, props) {
        let dict = {
            [BudgetModel.CODE_BORRADOR]: "Borrador",
            [BudgetModel.CODE_APPROVED]: "Aprobado",
            [BudgetModel.CODE_APPROVED_SCHEDULED]: "Aprobado. Planificado",
            [BudgetModel.CODE_NOT_APPROVED]: "Denegado",
            [BudgetModel.CODE_NOT_APPROVED_CLOSED]: "Denegado. Cerrado",
            [BudgetModel.CODE_CANCEL]: "Anulado",
            [BudgetModel.CODE_EXPIRED]: "Expirado",
            [BudgetModel.CODE_PENDING_ERP]: "Pendiente ERP",
        };
        let result = dict[code];
        if (result == null) {
            result = "";
        }
        if (props != null) {
            result = t(result);
        }
        return t(result)
    }
}
