import React, {Component} from 'react'
import {Circle, Map, Marker, Popup, TileLayer} from 'react-leaflet';
import VsfModal from "./VsfModal";
import translate from "../../translator/translate";
import AppLogger from "../../util/AppLogger";
import WorkLogModel from "../../models/WorkLogModel";
import * as Leaflet from "leaflet";
import {observer} from "mobx-react";
import {observable} from "mobx";
import VsfButtonNew from "../VsfButtonNew";

@observer
class MapView extends Component {
    @observable positionUpdate = [0, 0];

    constructor() {
        super();
    }

    locationStartVisit() {
        let result = [0, 0];
        let workLogs = this.props.slot && this.props.slot.workLog;
        if (workLogs && workLogs.length > 0) {
            for (let workLog of workLogs) {
                if (workLog.action == WorkLogModel.GEOLOCATION) {
                    if (workLog.slotId === this.props.slot.id) {
                        let obj = {
                            lat: JSON.parse(workLog.data).geolocation?.latitude,
                            lng: JSON.parse(workLog.data).geolocation?.longitude
                        };
                        // this.log({ map: 1, obj, data: JSON.parse(workLog.data) });
                        result = [obj.lat, obj.lng];
                    }
                }
            }
        }
        return result;
    }

    loadLocation() {
        if (this.props.fromWarehouse) {
            return this.locationWarehouse();
        } else {
            return this.locationClient();
        }
    }

    locationClient() {
        let result = [0, 0];
        if (this.props.clientModel) {
            let latitude = this.props.clientModel.geoLatitude ? parseFloat(this.props.clientModel.geoLatitude) : 0;
            let longitude = this.props.clientModel.geoLongitude ? parseFloat(this.props.clientModel.geoLongitude) : 0;
            let obj = { lat: latitude, lng: longitude };
            result = [obj.lat, obj.lng];
        }
        return result;
    }

    locationWarehouse() {
        let result = [0, 0];
        if (this.props.warehouseModel) {
            let latitude = this.props.warehouseModel.latitude ? parseFloat(this.props.warehouseModel.latitude) : 0;
            let longitude = this.props.warehouseModel.longitude ? parseFloat(this.props.warehouseModel.longitude) : 0;
            let obj = { lat: latitude, lng: longitude };
            result = [obj.lat, obj.lng];
        }
        return result;
    }

    onClick(e) {
        if (this.props.updateClient && this.props.editable) {
            this.positionUpdate = [e.latlng.lat, e.latlng.lng];
        }
    }

    handleLocationFound(e) {
        this.setState({
            hasLocation: true,
            latlng: e.latlng,
        })
    }

    saveLongLat() {
        let eventObj = {};
        if (this.props.fromWarehouse) {
            eventObj = {
                target: {
                    name: 'latitude',
                    value: this.positionUpdate[0].toString()
                }
            }
            ;
            this.props.updateInputEvent(eventObj);
            eventObj = {
                target: {
                    name: 'longitude',
                    value: this.positionUpdate[1].toString()
                }
            };
        } else {
            eventObj = {
                target: {
                    name: 'geoLatitude',
                    value: this.positionUpdate[0].toString()
                }
            }
            ;
            this.props.updateInputEvent(eventObj);
            eventObj = {
                target: {
                    name: 'geoLongitude',
                    value: this.positionUpdate[1].toString()
                }
            };
        }
        this.props.updateInputEvent(eventObj);
        this.props.closeModal();
    }

    render() {
        const positionClient = this.loadLocation();
        const t = translate;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">
                        {positionClient[0] != 0 ? this.renderWithLocation() : this.renderCenterMap()}
                    </div>
                    {this.positionUpdate[0] != 0 &&
                    <div className="modal-footer ">
                        <div className="col-12 d-flex justify-content-end mb-2">
                            <VsfButtonNew
                                label={t("Guardar")}
                                className="btn btn--loader"
                                onClick={() => this.saveLongLat()}
                            />
                        </div>
                    </div>
                    }
                </div>

            </VsfModal>

        );
    }

    renderCenterMap() {
        const position = this.locationStartVisit();
        const positionClient = this.loadLocation();
        const t = translate;
        return (
            <Map center={positionClient} zoom={2} scrollWheelZoom={true}
                 onClick={(e) => this.onClick(e)}
                 onLocationfound={(e) => this.handleLocationFound(e)}
                 style={{ width: '100%', height: '450px' }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {position[0] != 0 &&
                <Marker position={position}>
                    <Popup>
                        <span>{t("Localización del técnico al iniciar Jornada")}.</span>
                    </Popup>
                </Marker>
                }
                {positionClient[0] != 0 &&
                <>
                    <Circle center={positionClient} fillColor="red" radius={200} />
                    < Marker position={positionClient}>
                        <Popup>
                            <span>{this.props.clientModel?.posAddress || ""}.</span>
                        </Popup>
                    </Marker>
                </>
                }
                {this.positionUpdate[0] != 0 &&
                <>
                    <Circle center={this.positionUpdate} fillColor="yellow" radius={200} />
                    <Marker position={this.positionUpdate}>
                        <Popup>
                            <span>{t("Localización a actualizar")}.</span>
                        </Popup>
                    </Marker>
                </>
                }
            </Map>)
    }


    renderWithLocation() {
        const position = this.locationStartVisit();
        const positionClient = this.loadLocation();
        const t = translate;
        let center = Leaflet.latLngBounds([position[0] != 0 ? position : positionClient, positionClient]);
        return (
            <Map bounds={center} center={positionClient} zoom={1} scrollWheelZoom={true}
                 onClick={(e) => this.onClick(e)} maxZoom={16}
                 onLocationfound={(e) => this.handleLocationFound(e)}
                 style={{ width: '100%', height: '450px' }}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
                />
                {position[0] != 0 &&
                <Marker position={position}>
                    <Popup>
                        <span>{t("Localización del técnico al iniciar Jornada")}.</span>
                    </Popup>
                </Marker>
                }
                {positionClient[0] != 0 &&
                <>
                    <Circle center={positionClient} fillColor="red" radius={200} />
                    < Marker position={positionClient}>
                        <Popup>
                            <span>{this.props.clientModel?.posAddress || ""}.</span>
                        </Popup>
                    </Marker>
                </>
                }
                {this.positionUpdate[0] != 0 &&
                <>
                    <Circle center={this.positionUpdate} fillColor="yellow" radius={200} />
                    <Marker position={this.positionUpdate}>
                        <Popup>
                            <span>{t("Localización a actualizar")}.</span>
                        </Popup>
                    </Marker>
                </>
                }
            </Map>)
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default MapView
