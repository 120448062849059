
class Config {

    /**
     * Indica si estamos configurados como "REAL"
     */
    isLive = false;
    analyticsCode = "XXXXX";
    devMode = false;

    constructor() {
        let devHost = "localhost";
        //let devHost="192.168.4.94";
        let hostPort = window.location.host;
        if (window.location.host.startsWith("192.") || window.location.host.startsWith("10.0.")) {
            devHost = this.getDelim(hostPort, ":", 0);
        }
        //alert (window.location.host);
        //Si no es localhost y es una ip interna

        this.apiHost = 'http://' + devHost + ':5000';
        this.apiHost = 'https://nuddotest2.vsf.es';
        // this.apiHost = 'https://interbev.nuddofieldservice.com/';
        this.apiHostBaseUrl = this.apiHost + '/graphql';
        this.apiRestHostBaseUrl = this.apiHost + '/api';
        this.apiHostInt = (this.apiHost + '/api/integration').replace("5000", "1259");


        if (process && process.env && process.env.NODE_ENV !== "development") {
            // if (process.env.REACT_APP_API_GRAPHQL_ENDPOINT) {
            //     this.apiHostBaseUrl = process.env.REACT_APP_API_GRAPHQL_ENDPOINT;
            // }
            // if (process.env.REACT_APP_API_REST_ENDPOINT) {
            //     this.apiRestHostBaseUrl = process.env.REACT_APP_API_REST_ENDPOINT;
            // }
            if (process.env.REACT_APP_API_HOST_INT) {
                this.apiHostInt = process.env.REACT_APP_API_HOST_INT;
            }
            //A partir de 2020-12-06 estas constantes no se generan en el servidor a partir de los ficheros .env Porque se generará el build antes de cada subida y no podemos tener
            // estas constantes en el Js final. Tenemos que calcularlo para que funcione tanto en test2 como test3.
            // "production" significa que se hace un deploy con las lineas:
            //      npm run-script build
            this.apiHost = this.getCurrentProtocolDomain();
            this.apiHostBaseUrl = this.apiHost + "/graphql";
            this.apiRestHostBaseUrl = this.apiHost + "/api";
            if (this.apiHost === "https://nuddotest3.vsf.es" || this.apiHost === "https://interbev.nuddofieldservice.com") {
                this.isLive = true;
            }
        }
        this.apiSubscriptionHostBaseUrl = (this.apiHostBaseUrl + 'ws').replace('https://', 'wss://').replace('http://', 'ws://');
        //"apiSubscriptionHostBaseUrl apiHostBaseUrl apiRestHostBaseUrl process.env.*

    }

    isTest2() {
        let result=false;
        if (window.location.host.indexOf("nuddotest2")>=0) {
            result=true;
        }
        return result;
    }

    getNetsuiteUrl() {
        let result="https://5675038-sb2.app.netsuite.com/";
        if (this.isLive) {
            result = "https://5675038.app.netsuite.com/"
        }
        return result;
    }

    getCurrentProtocolDomain() {
        return window.location.protocol + '//' + window.location.host;
    }


    getDelim(cadena, delim, num) {
        let res = "";
        try {
            let v = cadena.split(delim);
            res = v[num];
        } catch (e) {
        }
        return res;
    }

}


export default new Config();
