import DocumentModel from "./DocumentModel";
import AbstractModel from "./AbstractModel";
import TypifiedValueModel from "./TypifiedValueModel";


export default class AssetModelModel extends AbstractModel {

    manufacturer;
    model;
    dimensions;
    dimensionsUnit;
    weight;
    weightUnit;
    energyEficiency;
    assetTypeId;
    nameMainType = "assetModel";
    graphFindByIdOperation = "assetModel";
    graphFindOperation = "assetModelsConnection";
    graphExportOperation = "assetModelsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "manufacturer": { label: 'Fabricante' },
            "model": { label: 'Modelo' },
            "dimensions": "",
            "dimensionsUnit": "",
            "assetTypeId": "",
            "weight": "",
            "weightUnit": "",
            "energyEficiency": "",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "assetType", "RelationOne", TypifiedValueModel);

        return result;
    }
};




















