import React from 'react';
import {observer} from "mobx-react";
import appState from '../../state/AppState';
import PropTypes from 'prop-types';
import {computed} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import ScheduleVisitSlotModel from "../../models/ScheduleVisitSlotModel";
import ScheduleVisitSlotForm from "./ScheduleVisitSlotForm";
import translate from "../../translator/translate";

@observer
class ScheduleVisitSlot extends BaseEditableTable {


    static defaultProps = {
        foreingKeyfield: "",
    }

    static propTypes = {
        foreingKeyfield: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.nameMainType = "scheduleVisit";
        this.initializeGraphData(this.nameMainType);
        this.foreingKeyfield = "scheduleVisitId";
    }

    @computed get mobxListado() {
        return appState.scheduleSlotState.scheduleSlots;
    }
    setMobxListado(newValue) {
        appState.scheduleSlotState.scheduleSlots = newValue;
    }
    getModelTable() {
        return new ScheduleVisitSlotModel();
    }


    async componentDidMountImpl() {
        await this.reloadTable();
    }

    async reloadTable() {
        appState.scheduleSlotState.scheduleSlots = this.props.scheduledVisit.scheduleVisitSlots || [];
    }

    calcularHoras() {
        let result = 0.0;
        this.mobxListado.map((visitSlot, index) => {
            if (visitSlot.scheduledDuration != null) {
                if (result == 0)
                    result = parseInt(visitSlot.scheduledDuration);
                else if (visitSlot.scheduledDuration != '') {
                    result = parseInt(visitSlot.scheduledDuration) + parseInt(result);
                }
            }
        });
        return result;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let horas = this.calcularHoras();
        return (


            <InfoBlock componentObject={this}>
                <div className={'col-12'}>
                    <div className="row">
                        <div className="col-xl-12 pb-2">
                            <div className="table-edit">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col" className="col-5">{t("Rol")}</th>
                                        <th scope="col" className="col-5">{t("Tiempo Estimado")}</th>
                                        <th className="col-2">&nbsp;</th>
                                    </tr>
                                    </thead>
                                    <tbody className="border-end">

                                    {this.mobxListado.map((code, index) => (
                                        <ScheduleVisitSlotForm
                                            key={code && code.id}
                                            rowIndex={index}
                                            lastRow={this.mobxListado.length === 1}
                                            row={code}
                                            hasSomeRowEditing={this.state.hasSomeRowEditing}
                                            setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                            {...this.props}
                                        />
                                    ))}

                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td>{t('TOTAL')}</td>
                                        <td colSpan="2">{horas + " h."}</td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/*<OrderSlot key={index} slot={slot} />*/}
                </div>
            </InfoBlock>

        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(ScheduleVisitSlot)
