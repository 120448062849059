import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import HistoryComponent from "../HistoryComponent";
import ClientModel from "../../models/ClientModel";
import {observable} from "mobx";
import MoreButtons from "../../components/MoreButtons";
import ClientActions from "./ClientActions";
import translate from "../../translator/translate";

@observer
class ClientHistory extends BaseForm {
    nameMainType = "client";

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.graphData.data[this.nameMainType] = {};
    }

    getModelQuery() {
        return new ClientModel();
    }

    @observable isPos;

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        if (!util.hasValue(this.isPos)) {
            let client = await this.graphDataMainType;
            this.isPos = client.isPos
        }
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    onCloseModal() {
        return this.propsUtil.changeUrlRequest({
            rightModal: null,
            idWorkOrder: null,
            rightModalTab: null,
            clientId: null,
            orderId: null,
            workOrderId: null

        });
    }


    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let client = this.graphDataMainType;
        const t = translate;
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()} mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons>
                                    <ClientActions fromRightModal={this.props.fromRightModal} getModelQuery={() => this.getModelQuery()}
                                                   setErrors={(errors) => this.setErrors(errors)} model={client}/>
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataClient(this.graphDataMainType.isPos)}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}>
                                <ClientActions fromRightModal={this.props.fromRightModal} getModelQuery={() => this.getModelQuery()}
                                               setErrors={(errors) => this.setErrors(errors)} model={client}/>
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataClient(this.graphDataMainType.isPos)}
                                active={"Histórico"}
                            />
                            }
                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />


                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(ClientHistory);
