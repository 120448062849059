import React, { Component } from 'react'
import version from '../static_data/version'

class VsfHeaderWebLinkStylesheet extends Component {

    constructor() {
        super();
    }

    render() {
        return (
            <>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,700&Raleway:300,400,700&display=swap" rel="stylesheet" />
                <link rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
                <link rel="stylesheet" href={"/css/fontawesome.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/select2.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/select2-bootstrap.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/main-structure.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/main-list.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/main-form.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/main-nuddo.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/ajax-actions.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/scheduler.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href={"/css/react-autosuggest.css?v="+version.version} crossOrigin="anonymous" />
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
                      integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
                      crossOrigin="" />
            </>
        )
    }
}

export default VsfHeaderWebLinkStylesheet;
