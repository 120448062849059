import { action, observable } from 'mobx';


class LoadingBar {

    @observable loadingBarProgress=0;

    @action
    finalize() {
        this.loadingBarProgress=100;
    }

    @action
    start() {
        this.loadingBarProgress=40;
        //Cada 200 ms deberáa ir subiendolo para que no esté "parado"
    }

    @action
    initialize() {
        this.loadingBarProgress=20;
        //Cada 200 ms deberáa ir subiendolo para que no esté "parado"
    }

}

export default LoadingBar;
