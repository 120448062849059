import { observable } from 'mobx';
import appState from './AppState'
import SlotModel from "../models/SlotModel";
import WorkOrderModel from "../models/WorkOrderModel";
import AppLogger from "../util/AppLogger";
import util from "../util/Util";
import moment from "moment";
import WorkLogModel from "../models/WorkLogModel";
import BaseModelState from "./BaseModelState";

class SlotState extends BaseModelState {
    @observable slots = [];
    @observable slotsMainPage = [];
    @observable allSlotsMainPage = [];
    @observable allSlotsMainPageFinished = [];
    @observable allSlotsMainPageNotFinished = [];
    @observable allSlotsMainPageDict = {};
    @observable changeSlot = '0';
    @observable orderBy = "pending";
    @observable viewList = "list";
    @observable slotCurrent = {};
    @observable workOrderCurrent = {};
    @observable slotsNotInMainPageDict = {};
    @observable date;

    getDateFromIso() {
        let requestFecha = this.date;
        let from;
        if (requestFecha == null) {
            from = moment().format("YYYY-MM-DD");
        } else {
            from = moment(requestFecha).format("YYYY-MM-DD");
        }
        return from;
    }

    getWorkLogsDontSignFromThisSlot(slot) {
        let result = "";
        if (slot.workLog?.length > 0) {
            for (let workLog of slot.workLog) {
                if (workLog.action === WorkLogModel.DONT_SIGN) {
                    result = JSON.parse(workLog.data)?.dontSign[0];
                }
            }
        }
        return result;
    }

    getDateFromForGql() {
        let requestFecha = this.getDateFromIso();
        let from;
        if (requestFecha == null) {
            from = util.formatToISOString(util.getMomentFromDateWithoutTimezone(util.getMomentFromDateWithoutTimezone(Date.now()).format("YYYY-MM-DD") + "T00:00:00"));
        } else {
            from = util.formatToISOString(util.getMomentFromDateWithoutTimezone(requestFecha + "T00:00:00"));
        }
        return from;
    }

    getDateToIso() {
        let requestFecha = this.getDateFromIso();
        let from;
        if (requestFecha == null) {
            from = util.formatToISOString(util.getMomentFromDateWithoutTimezone(util.getMomentFromDateWithoutTimezone(Date.now()).format("YYYY-MM-DD") + "T23:59:00"));
        } else {
            from = util.formatToISOString(util.getMomentFromDateWithoutTimezone(requestFecha + "T23:59:00"));
        }
        return from;
    }


    async loadSlotsTodayImpl() {
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToIso();
        let slotModelQuery = new SlotModel();
        slotModelQuery.userId = appState.loginState.getUserId();
        slotModelQuery.filters = [
            { "fieldName": "scheduledTime", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateTo, "filterOperator": "LTEQ" },
            { "fieldName": "technicalView", "fieldValue": "1", "filterOperator": "EQ" },
        ];
        slotModelQuery.orderBy = "scheduledTime";
        slotModelQuery.orderMode = "ASC";
        slotModelQuery.addRelatedTablesForSlotsDay();
        return await slotModelQuery.findPlainObject();

    }

    async watchSlotsSync(isOffline) {
        let dateFrom = this.getDateFromForGql();
        let dateTo = this.getDateToIso();
        let slotModelQuery = new SlotModel();
        slotModelQuery.userId = appState.loginState.getUserId();
        slotModelQuery.filters = [
            { "fieldName": "scheduledTime", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateTo, "filterOperator": "LTEQ" },
        ];
        slotModelQuery.orderBy = "scheduledTime";
        slotModelQuery.orderMode = "ASC";
        slotModelQuery._isOffline = isOffline;
        return await slotModelQuery.findPlainObject();

    }


    async generateSlotForCurrentWorkingSlot() {
        let slotFromMainPage = this.getCurrentSlot();
        if (slotFromMainPage?.id != null) {
            this.slotsNotInMainPageDict[slotFromMainPage.id] = slotFromMainPage;
        }
        let slotId = appState.workLogState.getCurrentWorkingSlotId();
        if (slotFromMainPage?.id == null) {
            if (util.hasValue(slotId)) {
                if (this.allSlotsMainPage) {
                    for (let slot of this.allSlotsMainPage) {
                        if (slot.id === slotId) {
                            this.slotsNotInMainPageDict[slot.id] = slot;
                        }
                    }
                }
            }
        }
    }

    async generateSlotCurrent() {
        let result = null;
        let slotId = appState.workLogState.getCurrentWorkingSlotId();
        if (this.slotsNotInMainPageDict[slotId]) {
            result = this.slotsNotInMainPageDict[slotId];
        }
        if (result == null) {
            if (this.allSlotsMainPage.length > 0) {
                for (let slot of this.allSlotsMainPage) {
                    if (slot.id === slotId) {
                        result = slot;
                    }
                }
            }
        }

        if (result == null) {
            result = new SlotModel();
        }
        this.slotCurrent = result;
    }

    generateWorkOrderCurrent() {
        let result = new WorkOrderModel();
        let slot = this.getCurrentSlot();
        let workOrderId = appState.workLogState.getCurrentWorkingWorkOrderId();
        if (slot.workOrder) {
            for (let workOrder of slot.workOrder) {
                if (workOrderId === workOrder.id) {
                    result = workOrder;
                }
            }
        }
        this.workOrderCurrent = result;
    }

    async generateSlotCurrentNotInMain() {
        let result = new SlotModel();
        let slotId = appState.workLogState.getCurrentWorkingSlotId();
        if (util.hasValue(slotId)) {
            let slotQuery = new SlotModel();
            slotQuery.addRelatedTablesForSlotsDay();
            slotQuery.id = slotId;
            let slotCurrent = await slotQuery.findFirst();
            if (slotCurrent != null) {
                result = slotCurrent.toPlainObject();
                this.slotsNotInMainPageDict[result.id] = result;
                this.slotCurrent = result;
            }
        }
    }

    /**
     * Finaliza un Slot del estado para que actualice su tarjeta
     * @param slot
     */
    async updateSlotState(slot) {
        for (let slotMain of this.allSlotsMainPage) {
            if (slotMain.id === slot.id) {
                slotMain = slot;
                this.slotsNotInMainPageDict[slotMain.id] = slotMain;
                if (this.changeSlot == '1') {
                    this.changeSlot = '0'
                } else {
                    this.changeSlot = '1'
                }
            }
        }
    }


    async getLastSlotUser(slot, time) {
        let lastSlotUser = new SlotModel();
        lastSlotUser.userId = slot.userId;
        let dateTo = util.formatToISOString(util.getMoment(time).add(12, 'hours'));
        lastSlotUser.filters = [
            { "fieldName": "scheduledTime", "fieldValue": time, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateTo, "filterOperator": "LTEQ" },
            { "fieldName": "id", "fieldValue": slot.id, "filterOperator": "NEQ" },
        ];
        lastSlotUser.orderBy = "scheduledTime";
        lastSlotUser.orderMode = "DESC";
        lastSlotUser._isOffline = true;
        return await lastSlotUser.findFirst();
    }

    /**
     * Genera una nueva visita mediante una visita pausada o devuelta
     * Si tiene como param time es porque esta finalizando el "demonio" sino es proceso normal
     * @param slot
     * @param time
     * @param t
     */
    async generateNewSlotPausesReturned(slot, time,t) {
        if (!util.hasValue(time)) {
            time = util.getDateIsoNow();
        }
        let lastSlotUser = await this.getLastSlotUser(slot, time);
        let workOrderIdsReturn = "";
        let workOrderIdsPause = "";
        for (let workOrder of slot.workOrder) {
            if (workOrder?.workLog?.length > 0) {
                if (workOrder.workLog[workOrder.workLog.length - 1].action === WorkLogModel.PAUSE_WORK_ORDER) {
                    workOrderIdsPause += workOrder.id + ",";
                }
                if (workOrder.workLog[workOrder.workLog.length - 1].action === WorkLogModel.RETURN_WORK_ORDER) {
                    workOrderIdsReturn += workOrder.id + ",";
                }
            }
        }
        // this.log({ slot, workOrderIdsPause, workOrderIdsReturn });
        if (workOrderIdsPause !== "") {
            let slotModel = new SlotModel();
            slotModel.hidrate(slot);
            slotModel.id = "";
            slotModel.workOrderIds = workOrderIdsPause.slice(0, -1);
            slotModel.saveOptions = slotModel.SAVE_WITH_WARNINGS;
            slotModel.slotIdFromPause = slot.id;
            slotModel.comments = t("Visita generada mediante una pausa");
            if (slotModel.scheduledDuration < 1) {
                slotModel.scheduledDuration = 1;
            }
            if (util.hasValue(lastSlotUser?.scheduledTime)) {
                time = util.formatToISOString(util.getMoment(lastSlotUser.scheduledTime).add((1 + lastSlotUser.scheduledDuration), "hours"));
            }
            slotModel.scheduledTime = time;
            await slotModel.persist();
        }
        if (workOrderIdsReturn !== "") {
            let slotModel = new SlotModel();
            slotModel.hidrate(slot);
            slotModel.id = "";
            slotModel.workOrderIds = workOrderIdsReturn.slice(0, -1);
            slotModel.saveOptions = slotModel.SAVE_WITH_WARNINGS;
            slotModel.slotIdFromPause = slot.id;
            slotModel.comments = t("Visita genera mediante una devolucion");
            if (slotModel.scheduledDuration < 1) {
                slotModel.scheduledDuration = 1;
            }
            slotModel.scheduledTime = "";
            if (!util.hasValue(slot.scheduledTimeUntil)) {
                slotModel.userId = "";
            } else {
                let scheduleTimeUntil = util.getMoment().add((1), "d");
                if (scheduleTimeUntil.format("YYYYMMDD") > util.getMoment(slot.scheduledTimeUntil).format("YYYYMMDD")) {
                    slotModel.scheduledTime = util.formatToISOString(scheduleTimeUntil);
                } else {
                    slotModel.userId = "";
                }
            }
            await slotModel.persist();
        }

    }


    getCurrentSlot() {
        return this.slotCurrent;
    }

    getCurrentWorkOrder() {
        return this.workOrderCurrent;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default SlotState;
