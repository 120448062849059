import { observable } from 'mobx';
import util from "../util/Util";
import ContactModel from "../models/ContactModel";
import BaseModelState from "./BaseModelState";


class ContactState extends BaseModelState {

    @observable contacts = [];

    async reloadContactsFromClientId(clientId, isPreventive) {
        let result = [];
        let result2 = [];
        let contactQuery = new ContactModel()
        contactQuery.orderBy = "firstName";
        contactQuery.orderMode = "ASC";
        contactQuery.clientId = clientId;
        if (util.hasValue(clientId)) {
            result = await contactQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
        }
        if (isPreventive) {
            contactQuery = new ContactModel();
            contactQuery.filters = [
                { "fieldName": "clientId", "fieldValue": '', "filterOperator": "EQ" },
            ];
            result2 = await contactQuery.findPlainObject();
        }

        this.contacts = [...result, ...result2];
    }
}

export default ContactState;
