import React, {Component} from 'react';
import {observer} from 'mobx-react';
import WorkLogModel from "../models/WorkLogModel";
import AppLogger from "../util/AppLogger";
import appState from "../state/AppState";
import PropsUtil from "../util/PropsUtil";
import {withRouter} from "react-router-dom";
import util from "../util/Util";
import translate from "../translator/translate";
import TypifiedValueModel from "../models/TypifiedValueModel";
import WorkOrderModel from "../models/WorkOrderModel";


@observer
class WorkOrderDetailRow extends Component {
    getWorkLogOfSlotAndWorkOrder(workOrder, slotId) {
        let result = [];
        if (workOrder.workLog != null) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.slotId === slotId && workLog.workOrderId === workOrder.id) {
                    if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                        workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                        workLog.action === WorkLogModel.START_WORK_ORDER ||
                        workLog.action === WorkLogModel.PENDING ||
                        workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    getWorkLogOfWorkOrder(workOrder) {
        let result = [];
        if (workOrder.workLog != null) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.workOrderId === workOrder.id) {
                    if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                        workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                        workLog.action === WorkLogModel.START_WORK_ORDER ||
                        workLog.action === WorkLogModel.PENDING ||
                        workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    getDurationWorkOrder(workOrder) {
        let totalTime = 0;
        let timeEntrada;
        let timeSalida;
        let arrayLogs = this.getWorkLogOfSlotAndWorkOrder(workOrder, this.props.slot.id);
        if (arrayLogs.length > 0) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(arrayLogs)) {
                if (workLog.slotId === this.props.slot.id) {
                    if (workLog.action === WorkLogModel.START_WORK_ORDER) {
                        timeEntrada = workLog.time;
                    }
                    if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                        workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                        workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                        timeSalida = workLog.time;
                        let entrada = util.getMoment(timeEntrada);
                        let salida = util.getMoment(timeSalida);
                        let total = salida.diff(entrada, 'hours', true);
                        let totalFixed = parseFloat(total).toFixed(2);
                        totalTime += parseFloat(totalFixed);
                    }
                }
            }
        }
        return totalTime;
    }

    renderAssetChange(workLogs, action) {
        const t = translate;
        let workLogModel = new WorkLogModel();
        let data = [];
        let workLogsCopy = workLogs ? workLogs.slice(0).reverse() : [];
        for (let workLog of workLogsCopy) {
            if (workLog.action == WorkLogModel[action] && workLog.slotId === this.props.slot.id) {
                data.push(workLog);
            }
        }
        if (data.length != 0) {
            return (
                <div className={"row"}>
                  <span className={"col-3"} style={{
                      color: '#6C757D',
                      marginBottom: 6,
                      fontSize: 12,
                  }}>
                        {workLogModel.getTitle(action) + ":"}
                    </span>
                    {data.map(result => (
                        <div key={result.id} className={"column col-12 table-comment"}>
                            <div className={"row col-12"}>
                                {/*<div key={result.id} style={{ flexDirection: 'row' }}>*/}
                                {/*<span>*/}
                                {/*    {util.getMoment(result.time).format("YYYY-MM-DD HH:mm")}*/}
                                {/*</span>*/}
                                {/*</div>*/}
                                {JSON.parse(result.data) &&
                                <span style={{fontSize: 12}}
                                      key={data.id}>{JSON.parse(result.data).request + " - "}{util.hasValue(JSON.parse(result.data).assetInitial) ? t("Equipo anterior") + ": " + JSON.parse(result.data).assetInitial + " - " : " "}
                                    {t("Equipo nuevo") + ": " + JSON.parse(result.data).assetChange}</span>
                                }
                            </div>
                        </div>

                    ))}
                </div>
            )
        }
    }

    /**
     * Devuelve el nombre de la acción última de esta workOrder: P ej: PAUSE_WORK_ORDER
     * @param workOrder
     */
    getActionOfWorkOrder(workOrder) {
        let workOrderStatusObj = appState.typifiedState.getObject(TypifiedValueModel.CODE_WORKORDER_STATUS, workOrder.status);
        let result = workOrderStatusObj.code;
        let workLogs = this.getWorkLogOfWorkOrder(workOrder);
        if (workLogs.length > 0) {
            //Cojo la última acción hecha con esta workOrder
            result = workLogs[workLogs.length - 1].action;
        }
        return result;
    }

    renderDataReturnWorkOrder(workLogs, action) {
        let data = [];
        let workLogModel = new WorkLogModel();
        let workLogsCopy = workLogs ? workLogs.slice(0).reverse() : [];
        let workOrder = this.props.workOrder || {};
        for (let workLog of workLogsCopy) {
            if (workLog.action == WorkLogModel[action]) {
                data.push(workLog);
            }
        }
        let result = data[data.length - 1];
        if (data.length != 0) {
            return (
                <div>
                    {(util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(action)]) &&
                    <>
                        <span key={result.id}
                              style={{fontSize: 12}}>{util.getMoment(result.time).format("YYYY-MM-DD HH:mm")}</span>
                        {JSON.parse(result.data)[workLogModel.nameForData(action)].reverse().map(obj => (
                            <span style={{marginBottom: 6, fontSize: 12, paddingLeft: 5}}
                                  key={obj.id}>
                                        {util.hasValue(obj.title) ? obj.title + " - " : ""}
                                {util.hasValue(obj.code) && obj.code + " - "}
                                {obj.value}
                                {util.hasValue(obj.comment) && " - " + obj.comment}
                                    </span>
                        ))}
                    </>
                    }
                    <span style={{marginBottom: 6, color: '#6C757D', fontSize: 12, paddingLeft: 5}}>
                        {appState.typifiedState.getValue(workOrder.returnStatusId)}
                    </span>
                </div>
            )
        }
    }

    /**
     * Devuelve el nombre de la acción última de esta workOrder: P ej: PAUSE_WORK_ORDER
     * @param workOrder
     */
    getStatusOfWorkOrder(workOrder) {
        let result = appState.typifiedState.getObject(workOrder.status);
        let workLogs = this.getWorkLogOfSlotAndWorkOrder(workOrder, this.props.slot.id);
        //Cojo la última acción hecha con esta workOrder
        if (workLogs.length > 0) {
            result = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, workLogs[workLogs.length - 1].action);
        }
        return result;
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        let workOrder = props.workOrder || {};
        const t = translate;
        let workOrderInstance = new WorkOrderModel();
        let workLogModel = new WorkLogModel();
        workOrderInstance.hidrate(workOrder);
        let backgroundColorType = workOrderInstance.getColor();
        let arrayLogs = this.getWorkLogOfSlotAndWorkOrder(workOrder, this.props.slot.id);
        return (
            <>
                <tr>
                    {/*<td valign="top">*/}
                    {/*    <div className="type-colour" style={{backgroundColor: backgroundColorType}}/>*/}
                    {/*</td>*/}
                    <td>
                     <span
                         className="text-click underline uppercase"
                         onClick={() => this.openWorkOrderModal(workOrder.id)}>{workOrder.code}</span>
                    </td>
                    <td>
                        <span className="uppercase"
                              style={{
                                  color: this.getStatusOfWorkOrder(workOrder).color,
                                  fontStyle: arrayLogs.length === 0 ? 'italic' : 'normal'
                              }}>
                        {appState.typifiedState.getValue(this.getStatusOfWorkOrder(workOrder).id)}</span>
                    </td>
                    <td>
                    <span className="underline uppercase">
                        {workOrder.asset &&
                        <span className="visits__text text-click"
                              onClick={() => this.openAssetModal(workOrder.asset?.id)}>{workOrder.asset?.plate}</span>}
                    </span>
                    </td>
                    <td>
             <span className="regular">
                        {workOrder.asset &&
                        <span className="visits__text ">{workOrder.asset?.manufacturer}</span>}
                    </span>
                    </td>
                    <td>
                 <span className="regular">
                        {workOrder.asset &&
                        <span className="visits__text ">{workOrder.asset?.model}</span>}
                    </span>
                    </td>
                    <td>
                 <span className="regular">
                        {workOrder.asset &&
                        <span className="visits__text ">{workOrder.asset?.serialNumber}</span>}
                    </span>
                    </td>
                    <td>
                        <span className="regular">{workOrder.asset != null ?
                            appState.typifiedState.getValue(workOrder.asset.type) :
                            appState.typifiedState.getValue(workOrder.assetType) || ""}</span>
                    </td>
                    <td>
                        <span className="regular">{workOrder.asset != null ?
                            appState.typifiedState.getValue(workOrder.asset.subType) :
                            appState.typifiedState.getValue(workOrder.assetSubType) || ""}</span>
                    </td>
                    {/*{this.props.fromSummary &&*/}
                    {/*<td>*/}
                    {/*    <span*/}
                    {/*        className="">{parseFloat(this.getDurationWorkOrder(workOrder)).toFixed(2) + t("h") + "."}</span>*/}
                    {/*</td>*/}
                    {/*}*/}

                </tr>
                {(this.getActionOfWorkOrder(workOrder) === WorkLogModel.RETURN_WORK_ORDER ||
                    workLogModel.getWorkLogsFromThisSlot(workOrder, WorkLogModel.ASSET_CHANGE, this.props.slot?.id).length > 0) &&
                <tr>
                    <td colSpan="8">
                        {this.getActionOfWorkOrder(workOrder) === WorkLogModel.RETURN_WORK_ORDER &&
                        <div className="table-comment">
                            <span>{this.renderDataReturnWorkOrder(workOrder.workLog, WorkLogModel.RETURN_WORK_ORDER)}</span>
                        </div>
                        }
                        <div>
                            <span>{this.renderAssetChange(workOrder.workLog, "ASSET_CHANGE")}</span></div>
                    </td>
                </tr>
                }
                {props.children}
            </>
        )
    }

    openWorkOrderModal(workOrderId) {
        let orderId = this.props.slot.orderId;
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modalworkorderls",
            "orderId": orderId,
            "workOrderId": workOrderId,
            "rightModalTab": "workorder"
        });
    }

    openAssetModal(assetId) {
        this.propsUtil.changeUrlRequest({
            rightModal: "modalassets",
            "assetId": assetId,
            "rightModalTab": "assets"
        });

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(WorkOrderDetailRow);
