import React, { Component } from 'react'
import AppLogger from '../../util/AppLogger'
import translate from '../../translator/translate'
import VsfButtonNew from '../../components/VsfButtonNew'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import PropsUtil from '../../util/DateUtil'
import { withRouter } from 'react-router-dom'
import VsfModalResizable from '../../components/modals/VsfModalResizable'
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import util from "../../util/Util";
import SelectSuggestComponent from "../../components/fields/SelectSuggestComponent";
import ArticleModel from "../../models/ArticleModel";
import ClientModel from "../../models/ClientModel";
import BaseForm from "../base/BaseForm";
import appState from "../../state/AppState";
import FetchProxy from "../../network/FetchProxy";
import config from "../../config/config";

@observer
class ArticlePrizeConsultModal extends BaseForm {

    nameMainType = 'client'
    @observable gqlErrors = null
    @observable client = {}
    @observable prize = null


    constructor (props) {
        super(props)
        this.nameMainType = 'client'
        this.initializeGraphData(this.nameMainType)
        this.state = {
            loading: false,
            disableConsultButton: true
        }

    }

    async componentDidMount () {
        if (this.props.fromClient) {
            this.setState({
                change: true,
            })
        }
    }

    /**
     * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
     * @param value
     * @param firstCall
     * @returns Array
     */
    async getClientsSuggestions (value, firstCall) {
        let clientArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            let clientQuery = new ClientModel()
            clientQuery.addRelatedTable('parentClient')
            if (firstCall) {
                clientQuery.id = this.graphDataMainType.clientId
            } else if (util.hasValue(value)) {
                clientQuery.isPos = true
                clientQuery.filters = [
                    {
                        'fieldName': 'name,code',
                        'fieldValue': value,
                        'filterOperator': 'SUBSTR'
                    },
                ]
            } else if (util.hasValue(this.parentClientIdRolClient)) {
                this.log('3')
                clientQuery.isPos = true
                clientQuery.parentId = this.parentClientIdRolClient
            }
            let client = await clientQuery.findPlainObject()
            client.map((client) => {
                let clientObject = {}
                clientObject.label = (client.code || '') + ' - ' + client.name
                clientObject.value = client
                clientArray.push(clientObject)
            })
        } catch (e) {
            this.log('Error => ' + e)
        }
        return clientArray
    }

    async getPropietarysSuggestions (value, firstCall) {
        let clientArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            let clientQuery = new ClientModel()
            if (firstCall) {
                clientQuery.id = this.graphDataMainType.propietaryId
            } else {
                // clientQuery.isPos = false;
                clientQuery.filters = [
                    {
                        'fieldName': 'companyName',
                        'fieldValue': value,
                        'filterOperator': 'SUBSTR'
                    },
                    {
                        'fieldName': 'isPos',
                        'fieldValue': 'false',
                        'filterOperator': 'EQ'
                    },
                ]
            }
            let client = await clientQuery.findPlainObject()
            client.map((client) => {
                let clientObject = {}
                clientObject.label = client.companyName
                clientObject.value = client
                clientArray.push(clientObject)
            })
        } catch (e) {
            this.log('Error => ' + e)
        }
        return clientArray
    }

    async onChangeClientId (clientId) {
        if (util.hasValue(clientId)) {
            this.handleAutosuggestSelection('clientId', clientId)
            await appState.clientState.reloadClientFromClientId(clientId)
            this.graphDataMainType.client = appState.clientState.client
        }
    }

    async loadPrizeArticle(articleSelected, parentClient){
        this.setState({ loading: true });
        let httpApi = new FetchProxy()
        httpApi.withAuthorization = true
        let result = ''

        try {
            this.loading = true
            let response = await httpApi.fetchUrlPost(config.apiHostInt + '/priceRequestNetsuite/PriceRequest',
                {
                    'article': {
                        'code': articleSelected.articleCode,
                        'codeErp': articleSelected.codeErp
                    },
                    'client': {
                        'code': parentClient.code,
                        'codeErp': parentClient.codeErp
                    }
                }
            )
            let responseJson = await response.json()
            this.log(responseJson)
            result = responseJson.data?.price

        } catch (e) {
            this.log(e)
        }
        this.setState({ loading: false });
        return result
    }

    async calculatePrize(articleSelected, parentClient) {
        this.prize = await this.loadPrizeArticle(articleSelected, parentClient);

    }

    render () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let article = new ArticleModel();
        article.hidrate(this.props.article)
        let client = this.graphDataMainType
        if (client == null){
            client={}
        }

        return (
            <>
                <VsfModalResizable
                    isOpen={this.props.openModal}
                    // initHeight={350}
                    onCloseModal={this.props.closeModal}
                    left={this.props.fromRightModal && '-45%'}>
                    <div style={{ display: 'block' }}>
                        <h5 className="card-title clientPreventModal">
                            <i style={{ fontSize: 20 }} className="fas fa-info-circle" />{t('Consulta de precio') + ' ' + article.code}
                        </h5>
                        <div className="modal-body-filters modal-body__no-overflow">
                            <div>
                                <div>
                                    <p>{t('Para poder consultar el precio del artículo, por favor indica el establecimiento o el cliente fiscal')}</p>
                                </div>
                                <div className="row">
                                    <InputTypeComponent
                                        value={article.code}
                                        autoComplete="new-password"
                                        title={t('Artículo')}
                                        classGroup={'col-md-12 col-lg-12'}
                                        type={'text'}
                                        readOnly={true}
                                    />
                                    <SelectSuggestComponent
                                        value={client?.name}
                                        key={client?.name}
                                        onChange={(event) => {
                                            let clientSelected = event.target.value;
                                            if (util.hasValue(clientSelected.id)) {
                                                this.handleAutosuggestSelection('clientId', clientSelected.id);
                                                this.handleAutosuggestSelection('propietaryId', clientSelected.parentId);
                                                this.onChangeClientId();
                                                client.parentClient = clientSelected.parentClient;
                                                this.setState({disableConsultButton: false});
                                            } else {
                                                client = {}
                                            }
                                        }}
                                        name={'clientId'}
                                        editable={this.editable}
                                        title={t('Establecimiento')}
                                        classGroup={'col-md-6 col-lg-6'}
                                        placeholder={t('Escribe...')}
                                        loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                                    />
                                    <SelectSuggestComponent
                                        value={client.parentClient?.companyName || t("Sin asignar")}
                                        key={client.parentClient?.companyName}
                                        onChange={(event) => {
                                            let clientSelected = event.target.value
                                            if (util.hasValue(clientSelected.id)) {
                                                this.handleAutosuggestSelection('propietaryId', clientSelected.id);
                                                this.setState({disableConsultButton: false});
                                            } else {
                                                this.handleAutosuggestSelection('propietaryId', '')
                                            }

                                        }}
                                        name={'propietaryId'}
                                        title={t('Cliente Fiscal')}
                                        classGroup={'col-md-6 col-lg-6'}
                                        editable={this.editable}
                                        placeholder={t('Escribe...')}
                                        loadSuggestions={(value, firstCall) => this.getPropietarysSuggestions(value, firstCall)}
                                    />
                                    <div className={'col-lg-6'}>
                                        <VsfButtonNew
                                            label={t('Consultar precio')}
                                            className="btn btn--loader btn--large btn-block"
                                            classNameExtra="btn-block btn--large"
                                            postIcon={"fa fa-arrow-right ml-2"}
                                            loading={this.state.loading}
                                            messageOnLoading= {t("Consultando")}
                                            messageOnSuccess= {t("Consulta realizada")}
                                            messageOnFailure= {t("Error al buscar")}
                                            disabled={this.state.disableConsultButton}
                                            onClick={_ => this.calculatePrize(article, client.parentClient)}
                                        />
                                    </div>
                                    <InputTypeComponent
                                        value={(this.prize || '-') + ' €'}
                                        autoComplete="new-password"
                                        classGroup={'col-md-6 col-lg-6'}
                                        type={'text'}
                                        readOnly={true}
                                        classInput={' text-right'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer modal-footer__in-flexible-modal">
                            <div className="d-flex justify-content-end">
                                <VsfButtonNew
                                    label={t('Cerrar')}
                                    className="btn btn-secondary "
                                    onClick={() => this.props.closeModal()}
                                />
                                <div style={{ width: 10 }} />
                            </div>
                        </div>
                    </div>
                </VsfModalResizable>
            </>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default withRouter(ArticlePrizeConsultModal)
