import DocumentModel from "./DocumentModel";
import AbstractModel from "./AbstractModel";
import util from "../util/Util";
import ClientModel from "./ClientModel";
import OrderModel from "./OrderModel";
import AssetModel from "./AssetModel";
import WorkLogModel from "./WorkLogModel";
import WorkOrderCartArticleModel from "./WorkOrderCartArticleModel";
import BudgetModel from "./BudgetModel";
import TypifiedValueModel from "./TypifiedValueModel";
import UserModel from "./UserModel";
import appState from "../state/AppState";
import config from "../config/config";

export default class WorkOrderModel extends AbstractModel {
    static CODE_IN_PROCESS = "PAUSE_WORK_ORDER";
    static CODE_IN_PROGRESS = "START_WORK_ORDER";
    static CODE_FINISHED = "END_WORK_ORDER";
    static CODE_PAUSED = "RETURN_WORK_ORDER";
    static CODE_PENDING = "PENDING";
    static CODE_AUTOMATIC_YES = "YES";
    static CODE_AUTOMATIC_NO = "NO";
    static CODE_DAYS = "DAY";
    static CODE_MONTHS = "MONTH";
    static CODE_YEARS = "YEAR";
    static CODE_REVISED = "Y";
    static CODE_NOT_REVISED = "N";
    static CODE_REVISED_PENDING = "";
    id;
    scheduleVisitId;
    status;
    assetId;
    /*Bloque Asset*/
    assetPlate;
    assetSubType;
    assetType;

    assignedToMainId;
    clientId;
    code;
    createdAt;
    createdBy;
    documents;
    generalDescription;
    orderId;
    priority;
    subType;
    commentsValidated;
    symptom;
    symptomOther;
    lastVisitDate;
    type;
    cantidad;
    dynamicField;
    nextScheduledDate;
    codeErp;
    reasonReturnId;
    lastTechnicalReturnId;
    /*Bloque Automatic*/
    automaticDuration;
    automaticPeriod;
    automaticWorkOrderTypeId;
    automaticNewWorkOrder;
    posZoneId;
    erpFinished;

    hasReturned;
    warrantyPossible;
    warrantyComments;
    warrantyInternalComments;
    warrantyRevised;
    returnStatusId;
    warrantyAccepted;
    warrantyManufacturerClientId;
    warrantyManufacturerCode;

    nameMainType = "workOrder";
    graphFindByIdOperation = "workOrder";
    graphFindOperation = "workOrdersConnection";
    graphExportOperation = "workOrdersExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            // "localHour": "",
            "assetId": { label: "Equipo", relationName: "asset", relationValue: "plate" },
            "assignedToMainId": { label: "Técnico Asignado", relationName: "userAssignedMain", relationValue: "firstName" },
            /*Bloque Asset*/
            "codeErp": { label: "Código ERP", readonly: true },
            "assetPlate": { label: "Nº matricula" },
            "assetSubType": { label: "Subtipo Equipo" },
            "assetType": { label: "Tipo Equipo" },
            "assignedEndDate": { label: "Fecha fin asignada" },
            "reasonReturnId": { label: "Motivo de devolución" },
            "posZoneId": { readonly: true, label: "Zona" },
            "dynamicField": { label: "Campo Dinámico" },
            "clientId": { readonly: true, label: "Cliente", relationName: "client", relationValue: "name" },
            "code": { label: "Número de OT" },
            "generalDescription": { label: "Descripción" },
            "orderId": { label: "Pedido", relationName: "order", relationValue: "code" },
            "priority": { label: "Prioridad" },
            "automaticDuration": { label: "Duración" },
            "automaticPeriod": { label: "Periodo" },
            "automaticWorkOrderTypeId": { label: "ID del Tipo de Orden de Trabajo" },
            "automaticNewWorkOrder": { label: "Nueva Orden de Trabajo" },
            "createdAt": { readonly: true, label: "Creado" },
            "status": { label: "Estado" },
            "subType": { label: "Subtipo OT" },
            "symptom": { label: "Síntoma" },
            "symptomOther": { label: "Otro síntoma" },
            "lastVisitDate": { label: "Última fecha de ejecución", readonly: true },
            "type": { label: "Tipo OT" },
            "commentsValidated": { label: "Comentarios Sin Validar" },
            "nextScheduledDate": { label: "Próxima planificación", readonly: true },
            "hasReturned": { label: "Ha sido devuelta" },
            "warrantyPossible": { label: "Posible garantía" },
            "warrantyComments": { label: "Comentarios de garantía" },
            // "warrantyInternalComments" : { label: "Comentarios internos de garantía" },
            "warrantyRevised": { type: "EnumField", label: "Garantía Revisada" },
            "returnStatusId": { label: "Estado - Seguimiento de devolución" },
            "warrantyAccepted": { type: "EnumField", label: "Garantía Aplicable" },
            "lastTechnicalReturnId": { label: "Devuelta por" },
            "erpFinished": { label: "ERP Finalizado" },
            "warrantyManufacturerClientId": { label: "Cliente Fiscal Fabricante" },
            "warrantyManufacturerCode": { label: "Código de Garantía de Fabricante" }
        };

        this.addRelatedTableGeneric(result, "userAssignedMain", "RelationOne", UserModel);
        this.addRelatedTableGeneric(result, "lastTechnicalReturn", "RelationOne", UserModel);
        this.addRelatedTableGeneric(result, "order", "RelationOne", OrderModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "reasonReturnWorkLog", "RelationOne", WorkLogModel);
        this.addRelatedTableGeneric(result, "asset", "RelationOne", AssetModel);
        this.addRelatedTableGeneric(result, "workLog", "Relation", WorkLogModel);
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "workOrderCartArticle", "Relation", WorkOrderCartArticleModel);
        this.addRelatedTableGeneric(result, "budget", "Relation", BudgetModel);
        this.addRelatedTableGeneric(result, "statusWorkOrder", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "reasonReturn", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "zoneClient", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "typeWorkOrder", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "subTypeWorkOrder", "RelationOne", TypifiedValueModel);


        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["code", ...baseFields];
    }

    async getWorkOrdersFromOrderId(orderId) {
        let result = [];
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.orderId = orderId;
        workOrderQuery.addRelatedTable("workLog");
        if (util.hasValue(workOrderQuery.orderId)) {
            result = await workOrderQuery.findPlainObject();
        }
        if (result == null) {
            result = [];
        }
        return result;
    }


    getColor() {
        let color = "";
        let typifiedSubType = appState.typifiedState.getObject(this.subType);
        if (typifiedSubType) {
            color = typifiedSubType.color;
        }
        if (!util.hasValue(color)) {
            let typifiedType = appState.typifiedState.getObject(this.type);
            if (util.hasValue(typifiedType.color)) {
                color = typifiedType.color;
            }
        }
        return color;
    }

    getLastTypeCode() {
        let result = "";
        let typifiedSubType = appState.typifiedState.getObject(this.subType);
        if (typifiedSubType) {
            result = typifiedSubType.code;
        }
        if (!util.hasValue(result)) {
            let typifiedType = appState.typifiedState.getObject(this.type);
            if (util.hasValue(typifiedType.code)) {
                result = typifiedType.code;
            }
        }
        return result;
    }

    verXMLIntegracion(workOrderId){
        let result = "";
        try {
            result = config.apiHostInt + "/soa/InformNotification?workOrderId="+workOrderId;
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }

    integrarWorkOrder(workOrderId){
        let result = "";
        try {
            result = config.apiHostInt + "/soa/SoaInformNotification?workOrderId="+workOrderId;
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }
}
