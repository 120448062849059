import React from 'react';
import {observer} from 'mobx-react';
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed} from 'mobx';
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import VsfButtonNew from "../../components/VsfButtonNew";
import GroupOfField from "../../components/layout/GroupOfField";
import PropsUtil from "../../util/PropsUtil";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import WorkOrderModel from "../../models/WorkOrderModel";
import translate from "../../translator/translate";
import {withRouter} from "react-router-dom";
import MoreButtons from "../../components/MoreButtons";
import BudgetModel from "../../models/BudgetModel";
import OrderFormBudgetRow from "../orders/OrderFormBudgetRow";
import WorkOrderActions from "./WorkOrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class WorkOrderFormBudgetTable extends BaseEditableTable {


    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            budget: null,
            hasSomeRowEditing: false,
            editable: false,
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable();
        this.setState({ loading: false })
    }

    @computed get mobxListado() {
        return appState.budgetState.budget;
    }

    setMobxListado(newValue) {
        appState.budgetState.budget = newValue;
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("order");
        result.addRelatedTable("client");
        result.addRelatedTable("workOrderCartArticle");
        result.addRelatedTable("documents");
        result.addRelatedTable("budget");
        result.addRelatedTable("budget.budgetArticles");
        result.addRelatedTable("order.slot");
        // result.addRelatedTable("slot");
        return result;
    }

    getModelTable() {
        return new BudgetModel();
    }

    async reloadTable() {
        if (util.hasValue(this.graphDataMainType.id)) {
            let result = [];
            for (let budget of this.graphDataMainType.budget) {
                result.push(budget)
            }
            appState.budgetState.budget = result;
        }
    }

    render() {
        return this.renderImpl();
    }


    renderImpl() {
        const t = translate;
        let workOrder = this.graphDataMainType;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={workOrder.code || t("Nueva")}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                      getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                active="Presupuestos"
                                numAlarm={3}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={workOrder.code || t("Nueva")}>
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                  getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                active={"Presupuestos"}
                            />
                            }

                            <GroupOfField
                                title={t('Otros')}
                                subtitle={t('Presupuestos')}
                                icon='fas fa-calendar'
                            />

                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <p className="o-parraf col-12 js-info-related admin">{t("workorder-budget-form-description")}</p>

                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ningun presupuesto creado')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <th scope="col">{t("Num. Presupuesto")}</th>
                                            <th scope="col">{t("Estado")}</th>
                                            <th scope="col">{t("Comentarios")}</th>
                                            {/*<th scope="col">{t('T.Presupuestado Total')}</th>*/}
                                            <th scope="col">{t("Cantidad")}</th>
                                            <th scope="col">{t("Precio Total con Impuestos")}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">
                                        {this.mobxListado.map((budget, index) => {
                                                if (util.hasValue(budget.id)) {
                                                    return (
                                                        <OrderFormBudgetRow
                                                            key={budget && budget.id}
                                                            budgetId={budget.id}
                                                            rowIndex={index}
                                                            row={budget}
                                                            disabled={this.state.editable}
                                                            workOrders={appState.workOrderState.workOrders}
                                                            hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                            setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                            setEditable={(newVal, newBudget) => this.setEditable(newVal, newBudget)}
                                                            {...this.props}
                                                        />)
                                                }
                                            }
                                        )}
                                        </tbody>
                                    </table>
                                    <div className="text-right">
                                        <VsfButtonNew
                                            disabled={this.state.editable}
                                            label={t("Nuevo Presupuesto")}
                                            isEditing={this.state.editable}
                                            showButton={this.creatable["budget"] || false}
                                            className="btn btn--loader"
                                            onClick={() => this.propsUtil.changeUrlRequest({
                                                "rightModal": "modalbudgetls",
                                                orderId: this.graphDataMainType.orderId,
                                                clientId: this.graphDataMainType.clientId,
                                                workOrderId: this.graphDataMainType.id,
                                                "rightModalTab": "budgetForm",
                                            })}
                                        />
                                    </div>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
            ;
    }

    setEditable(budget) {
        this.propsUtil.changeUrlRequest({
            "rightModal": "modalbudgetls",
            "budgetId": budget.id,
            workOrderId: this.graphDataMainType.id,
            clientId: this.graphDataMainType.clientId,
            orderId: this.graphDataMainType.orderId,
            "rightModalTab": "budgetForm"
        });

    }


}

export default withRouter(WorkOrderFormBudgetTable)
