import React from 'react'
import { observer } from 'mobx-react'
import BaseWorkLogs from './BaseWorkLogs'
import TextAreaComponent from './fields/TextAreaComponent'
import translate from '../translator/translate'

@observer
class SelectComment extends BaseWorkLogs {

  constructor (props) {
    super(props)
    this.state = {
      copiaDatos: [],
      tasksSelected: [],
      objOfActions: [],
      text: '',
      item: {},
    }
  }

  async componentDidMount () {
    super.componentDidMount()
    if (this.props.objDict?.['0'] != null) {
      this.setState({
        text: this.props.objDict['0'].comment
      })
    }
  }

  getComentarios () {
    const t = translate
    return (
      <>
        <p>{t('Comentarios del técnico')}</p>
        <TextAreaComponent
          onChange={(e) => this.onChangeComment(e)}
          value={this.state.text}
          name={'text'}
          placeholder={t('Comentarios del técnico')}
          type={'text'}
        />
      </>
    )
  }

  render () {
    const t = translate
    return (
      <div className={'col-12'}>
        {this.getComentarios()}
      </div>
    )
  }

}

export default SelectComment
