import GraphException from "./GraphException";
import appState from "../state/AppState";
import AppLogger from "../util/AppLogger";
import util from "../util/Util";
import jwt_decode from "jwt-decode";
import LocalStorage from "./LocalStorage";
import {observable} from "mobx";

export default class ApolloProxy {

    static MAINTENANCE_ON = "ON";
    static MAINTENANCE_OFF_FOR_ADMIN_IP = "OFF_FOR_ADMIN_IP";
    static CODE_USER_ROLE = "rolUser";
    @observable resultSubsctiption = {};

    constructor(apolloClient) {
        this.apolloClient = apolloClient;
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    logQuery(queryObj) {
        // let hostName = config.apiHostBaseUrl.split('/')[2];
        let variables = queryObj.variables;
        let query;
        if (queryObj.query != null) {
            query = queryObj.query.loc.source.body;
        }
        if (queryObj.mutation != null) {
            query = queryObj.mutation.loc.source.body;
        }
        query = util.replaceAll(query, "\n", "");
        query = util.replaceAll(query, "\"" + util.getLang() + "\"", '\\"' + util.getLang() + '\\"');
        query = util.removeDoubleSpaces(query);
        let connection = query.substring(
            query.trim().indexOf(" ") + 2,
            query.indexOf("(")
        );
        query = query.replaceAll(new RegExp(/([a-z]),([a-z])/, "g"), "$1 $2")
        let rawQuery = {query, variables};
        let token = appState.loginState.getAccessToken;
        let authorizationHeader = "";
        if (util.hasValue(token)) {
            authorizationHeader = " -H 'Authorization: Bearer " + token + "' ";
        }
        // let commandLine = "curl '" + config.apiHostBaseUrl + "' -X POST " + " " +
        //     "--data-binary '" + JSON.stringify(rawQuery) + "' " +
        //     " -H 'Host: " + hostName + "' " +
        //     " -H 'User-Agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10.12; rv:55.0) Gecko/20100101 Firefox/55.0' " +
        //     " -H 'Accept: application/json' -H 'Content-Type: application/json' " +
        //     " -H 'DEVICE: MOBILE' -H 'AppVersion: " + version.version + "'" +
        //     " -i " +
        //     authorizationHeader;
        // this.log({ [connection]: commandLine });
        // this.log("echo \"-- R. " + connection + "\" \n executeCmd '{\"query\":\"" + query + "\",\"variables\": " + JSON.stringify(variables) + " } '");
        // this.log(JSON.stringify(variables));
    }

    /**
     * Lanza una query contra graphQL
     * Si nuddo esta en mantenimiento y no eres ADMIN te redirige a la pagina
     * Si la version es mas pequeña que la que hay guardada, hace un reload
     * Si devuelve error Timeout lanza una excepci?n como esta: {extensions:{data:{field:""}}, message:"Error al conectar con servidor"}
     * Si devuelve un error 400 (con datos nulos) devuelve los mensajes de error de esta forma
     * En caso de exito devuelve la respuesta Graphql
     * @returns {Promise<void>}
     */
    async graphQuery(queryParams) {
        let resultQuery = {data: null};
        let errorMessages = [{message: "GraphException. Error al realizar llamada a servidor"}];
        try {
            let localStorage = new LocalStorage();
            this.logQuery(queryParams);
            resultQuery = await this.apolloClient.query({
                query: queryParams.query,
                variables: queryParams.variables
            });
            if (resultQuery?.data?.nuddoMaintenance == ApolloProxy.MAINTENANCE_ON && !util.perteneceA("maintenance", window.location.href)) {
                let accessToken = localStorage.getItem('accessToken');
                let token = jwt_decode(accessToken);
                if (!util.perteneceA("ADMIN", token.UserRole)) {
                    appState.maintenance = true;
                }
            } else {
                appState.maintenance = false;
            }
            let storageNuddoDbVersion = localStorage.getItem('nuddoDbVersion');
            let windowVersion = appState.nuddoDbVersion;
            let nuddoDbVersion = resultQuery?.data?.nuddoDbVersion;
            //Si no tiene valor storage de la version
            //o si la version de base de datos es mayor que el storage
            // o si windowVersion tiene version pero es diferente que storage (se ha actualizado en otra pestaña)
            if ((!util.hasValue(storageNuddoDbVersion) ||
                ((util.compareFloatStrings(nuddoDbVersion, storageNuddoDbVersion) ||
                    (util.hasValue(windowVersion) && windowVersion !== storageNuddoDbVersion))))) {
                if (util.hasValue(appState.loginState.user?.id)) {
                    if (util.hasValue(nuddoDbVersion)) {
                        if (util.hasValue(storageNuddoDbVersion)) {
                            if (util.hasValue(window.location.search)) {
                                window.location.href = window.location.href + "&updateVersion=" + nuddoDbVersion;
                            } else {
                                window.location.href = window.location.href + "?updateVersion=" + nuddoDbVersion;
                            }
                        }
                        appState.nuddoDbVersion = nuddoDbVersion;
                        localStorage.setItem('nuddoDbVersion', nuddoDbVersion);
                    }
                }

            }
            if (!util.hasValue(windowVersion)) {
                if (util.hasValue(nuddoDbVersion)) {
                    appState.nuddoDbVersion = nuddoDbVersion;
                }
            }
            // this.log({ graphQueryResult: 1, resultQuery: resultQuery });
            // console.log({ resultQuery });
        } catch (e) {
            this.log({graphQueryError: e});
            if (e.networkError && e.networkError.statusCode == "401") {
                await appState.loginState.doLogout();
            } else if (e.networkError && e.networkError.statusCode == "502") {
                appState.maintenance = true;
            } else {
                let jsonObj = JSON.parse(JSON.stringify(e));
                //Si hay error graphQlResponse ser? null. Pero se podr? obtener desde message
                let graphQlResponse = await jsonObj.networkError ? jsonObj.networkError.result : null;
                if (graphQlResponse == null) {
                    //Si el servidor no se alcanza entrar? por aqu?:. Por ejemplo con "Network error: Failed to fetch"
                    // En otro caso ser? un mensaje error 400 con un listado de errores de GraphQL
                    errorMessages = [{
                        extensions: {data: {field: ""}},
                        message: "Se ha quedado sin señal. Revise su conexión de red"
                    }];
                } else {
                    if (graphQlResponse.errors != null) {
                        errorMessages = graphQlResponse.errors;
                    }
                }
                throw new GraphException({
                    message: this.getErrorMessageFromErrors(errorMessages),
                    errors: errorMessages
                });
            }
        }
        // Si la query tiene errores, los muestro y lanzo excepción
        if (resultQuery?.errors && resultQuery.errors.length > 0) {
            if (this.objetoSoloTienePropiedadesNull(resultQuery.data)) {
                throw new GraphException({
                    message: this.getErrorMessageFromErrors(resultQuery.errors),
                    errors: resultQuery.errors
                });
            }
        }
        this.showLoggerFromQuery(resultQuery, queryParams);
        if (resultQuery && resultQuery.data == null) {
            let graphDataException = {message: this.getErrorMessageFromErrors(errorMessages), errors: errorMessages};
            throw new GraphException(graphDataException);
        }
        return resultQuery;
    }

    showLoggerFromQuery(resultQuery, queryParams) {
        if (resultQuery?.data?.["loggers"]) {
            let loggers = resultQuery.data?.["loggers"];
            if (loggers == null) {
                loggers = [];
            }
            let loggerStruct = {
                query: queryParams.queryRaw || queryParams.mutationRaw,
                variables: JSON.stringify(queryParams.variables),
                loggers
            };
            appState.queryLoggers.push(loggerStruct);
            //this.log( {queryLoggers:toJS(appState.queryLoggers)} );
            /*
            appState.logger.debug += "<hr/>Query: <span>" + util.getString(queryParams.queryRaw) + "</span>\n" +
                "Variables: <span>" + JSON.stringify(queryParams.variables) + "</span>\n" +
                util.getString(resultQuery.data?.["logger"]?.debug) + "\n";
            appState.logger.verbose += util.getString(resultQuery.data?.["logger"]?.verbose) + "\n";
            appState.logger.info += util.getString(resultQuery.data?.["logger"]?.info) + "\n";
            */
        }
    }

    objetoSoloTienePropiedadesNull(objeto) {
        let result = true;
        for (let [key, value] of Object.entries(objeto)) {
            if (objeto[key] == null) {

            } else {
                result = false;
            }
        }
        return result;
    }

    async mutate(mutationParams) {
        let resultQuery = {data: null};
        let errorMessages = [{message: "GraphException. Error processing request (001)"}];
        try {
            resultQuery = await this.apolloClient.mutate({
                mutation: mutationParams.mutation,
                variables: mutationParams.variables,
                errorPolicy: mutationParams.errorPolicy
            });
        } catch (e) {
            let jsonObj = JSON.parse(JSON.stringify(e));
            //Si hay error graphQlResponse será null. Pero se podrá obtener desde message
            let graphQlResponse = jsonObj.networkError.result;
            if (graphQlResponse == null) {
                //Si el servidor no se alcanza entrará por aquí:. Por ejemplo con "Network error: Failed to fetch"
                // En otro caso será un mensaje error 400 con un listado de errores de GraphQL
                errorMessages = [{extensions: {data: {field: ""}}, message: jsonObj.message}];
            } else {
                if (graphQlResponse.errors != null) {
                    errorMessages = graphQlResponse.errors;
                }
            }
            //this.log({msg:"Error 400 en llamada",e, resultQuery, apolloClient:this.apolloClient, errorMessages, graphQlResponse });
            //Aunque de error podemos tener resultados en la variable logger
            this.showLoggerFromQuery(graphQlResponse, mutationParams);
            throw new GraphException({message: this.getErrorMessageFromErrors(errorMessages), errors: errorMessages});
        }
        this.showLoggerFromQuery(resultQuery, mutationParams)
        if (resultQuery == null || resultQuery?.data == null) {
            throw new GraphException({message: "GraphException. Error processing request (003)", errors: []});
        }
        return resultQuery;
    }

    subscribe(queryParams) {
        let errorMessages = [{message: "GraphException. Error processing request (001)"}];
        try {
            this.resultSubsctiption = this.apolloClient.subscribe(queryParams);
        } catch (e) {
            let jsonObj = JSON.parse(JSON.stringify(e));
            //Si hay error graphQlResponse será null. Pero se podrá obtener desde message
            this.log({e, jsonObj});
            let graphQlResponse = jsonObj.networkError?.result;
            if (graphQlResponse == null) {
                //Si el servidor no se alcanza entrará por aquí:. Por ejemplo con "Network error: Failed to fetch"
                // En otro caso será un mensaje error 400 con un listado de errores de GraphQL
                errorMessages = [{extensions: {data: {field: ""}}, message: jsonObj.message}];
            } else {
                if (graphQlResponse.errors != null) {
                    errorMessages = graphQlResponse.errors;
                }
            }
            throw new GraphException({message: this.getErrorMessageFromErrors(errorMessages), errors: errorMessages});
        }
        return this.resultSubsctiption;
    }

    getErrorMessageFromErrors(errorMessages) {
        let result = "";
        if (errorMessages.length > 0) {
            result = errorMessages[0].message;
            if (errorMessages.length > 1) {
                result += " and " + (errorMessages.length - 1) + " more";
            }
        } else {
            result = "GraphException. Error processing request (005)"
        }
        return result;

    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
