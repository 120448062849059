import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import config from "../../config/config";
import FetchProxy from "../../network/FetchProxy";
import AlertModal from "../../subpages/AlertModal";
import PropsUtil from "../../util/PropsUtil";
import PopUp from "./PopUp";
import {observable} from "mobx";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";

@observer
class ModalEnviarContrasenya extends Component {

    @observable loading = false;

    constructor(props) {
        super(props);
    }

    onCancel() {
        return this.propsUtil.changeUrlRequest({ rightModal: null });
    }

    async onSendRecovery(email) {
        let httpApi = new FetchProxy();
        httpApi.withAuthorization=false;
        try {
            this.loading = true;
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/recovery",
                {
                    "Email": email,
                }
            );

            this.log('resultado del response');
            this.log(response);
            let responseJson = await response.json();
            this.log(responseJson.message)
            this.log(responseJson.errorCode);
            if (responseJson.errorCode == 0) {
                let respuesta = responseJson.data.access_token;
                this.log('respuesta');
                this.log(respuesta)
                // let localStorage= new LocalStorage();
                // localStorage.setItem('accessToken',this.accessToken);
                // localStorage.setItem('refreshToken',this.refreshToken);
                //window.location.reload();
            } else {
                this.log(responseJson);
            }
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        setTimeout(() => {
            this.loading = false;
        }, 1000);
        setTimeout(() => {
            return this.propsUtil.changeUrlRequest({ rightModal: null });
        }, 2500);
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        return (
            <AlertModal isOpen={this.propsUtil.getRequest("rightModal") === "recover"}
                        onCloseModal={() => this.propsUtil.changeUrlRequest({
                            rightModal: null,
                            rightModalTab: null,
                            clientId: null,
                        })}
            >
                {this.propsUtil.getRequest("rightModal") === "recover" &&
                <PopUp
                    title={t('Recuperar contraseña')}
                    icon="exclamationTriangleIcon"
                    text={t(`Se mandará un correo al usuario XXXX con las instrucciones para poder cambiar la contraseña`).replace("XXXX", this.props.user.email)}
                    label1={'Cancelar'}
                    label2={"Enviar mensaje"} loading2={this.loading}
                    messageOnLoading={"Enviando"}
                    messageOnSuccess={"Enviado"}
                    messageOnFailure={"Error al enviar"}
                    onClick2={() => this.onSendRecovery(this.props.user.email)}
                    onClick1={() => this.onCancel()}
                />
                }
            </AlertModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(ModalEnviarContrasenya)


