import React from 'react';
import {observer} from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import SlotModel from "../../models/SlotModel";
import {computed, observable} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import GroupOfField from "../../components/layout/GroupOfField";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import translate from "../../translator/translate";
import OrderFormSlot from "./OrderFormSlot";
import VsfButtonNew from "../../components/VsfButtonNew";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import OrderModel from "../../models/OrderModel";
import MoreButtons from "../../components/MoreButtons";
import OrderFormSlotClientsPending from "./OrderFormSlotClientsPending";
import OrderActions from "./OrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class OrderFormSlots extends BaseEditableTable {

    @observable clientsSlots = [];

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.state = {
            hasSomeRowEditing: false,
            loading: true,
        };

    }
    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable();
        await appState.userCacheState.loadUserCacheTechnicals();
        this.setState({ loading: false })
        await this.loadSlotsClients();

    }
    @computed get mobxListado() {
        return appState.slotState.slots;
    }

    setMobxListado(newValue) {
        appState.slotState.slots = newValue;
    }

    getModelTable() {
        return new SlotModel();
    }

    getModelQuery() {
        return new OrderModel();
    }


    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrderCartArticle");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.workOrderCartArticle");
        result.addRelatedTable("workOrder.asset");
        result.addRelatedTable("workOrder.workLog");
        result.addRelatedTable("client");
        result.addRelatedTable("slot");
        result.addRelatedTable("slot.workOrder");
        result.addRelatedTable("slot.order");
        result.addRelatedTable("slot.childSlots");
        return result;
    }




    getDateFromForGql() {
        let requestFechaIso = util.getMoment();
        return util.getMomentFromDateWithoutTimezone(requestFechaIso.format("YYYY-MM-DD") + "T" + requestFechaIso.format("HH:mm:ss")).toISOString();
    }

    async loadSlotsClients() {
        let dateFrom = this.getDateFromForGql();
        let slotModel = new SlotModel();
        slotModel.first = 6;
        slotModel.filters = [
            { "fieldName": "clientId", "fieldValue": this.graphDataMainType.clientId, "filterOperator": "EQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
        ];
        slotModel.orderBy = "scheduledTime";
        slotModel.orderMode = "ASC";
        this.clientsSlots = await slotModel.find();
    }

    async reloadTable() {
        if (util.hasValue(this.graphDataMainType.id)) {
            appState.slotState.slots = this.ordenarSlots(this.graphDataMainType.slot);
            appState.assetState.totalSlots = this.graphDataMainType.slot.length;
            appState.workOrderState.workOrders = this.graphDataMainType.workOrder;
        }
    }


    ordenarSlots(array) {
        let slotsGrouped = {};
        let slots = array.slice();
        slots.sort(function (a, b) {
            let key1 = util.getMoment(a.createdAt).format("YYMMDDHHmmsss");
            let key2 = util.getMoment(b.createdAt).format("YYMMDDHHmmsss");
            if (key1 < key2) {
                return -1;
            }
            if (key2 > key1) {
                return 1;
            }
            return 0;
        });
        for (let slot of slots) {
            let key = slot.assignedSlotMainId != null ? slot.assignedSlotMainId : slot.id;
            if (slotsGrouped[key] == null) {
                slotsGrouped[key] = [];
            }
            slotsGrouped[key].push(slot);
        }
        let keysSlotGrouped = Object.keys(slotsGrouped);
        let arrSlots = [];
        keysSlotGrouped.map(slotId => {
            slotsGrouped[slotId].map(slot => {
                arrSlots.push(slot);
            })
        });
        return arrSlots;
    }

    renderImpl() {
        let order = this.graphDataMainType;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let horas = this.calcularHoras();
        let orderId = this.props.id;
        let modalClasses = util.getModalClasses();

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>


                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                                  getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active="Planificación"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                              getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">

                        <div className={modalClasses.body ? modalClasses.body : 'card-body'}>

                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active={"Planificación"}
                            />
                            }
                            <GroupOfField
                                title={t('Pedido')} loading={true}
                                subtitle={t('Planificación')}
                            />
                            <SpinnerClipLoaderInPage  loading={this.state.loading}>

                                {appState.typifiedState.getObject(this.graphDataMainType.status).code !== 'APPROVED' &&
                                <td colSpan={5}>
                                <span
                                    className={"text-danger ml-3"}><i
                                    className="fas exclamationTriangleIcon" />
                                    {t('Solo se pueden crear y editar planificaciones cuando el estado del pedido es Aprobado')}
                                </span></td>
                                }
                                        <div className="table-edit">
                                            <table className="table visible-text">
                                                <thead>
                                                {this.mobxListado.length == 0 &&
                                                <tr>
                                                    <td colSpan="8"
                                                        className="title-cell">{t('No hay ninguna planificacion creada')}
                                                    </td>
                                                </tr>
                                                }
                                                {this.mobxListado.length != 0 &&
                                                <tr>
                                                    <th className="wo-technical-name" scope="col">{t('Técnico')}</th>
                                                    <th className="wo-width-date" scope="col">{t('Fecha y Hora')}</th>
                                                    <th className="duration" scope="col">{t('Duración')}</th>
                                                    <th className="wo-matched"
                                                        scope="col">{this.props.fromOrder ? t("Orden de Trabajo") : t("O.T. Relacionadas")}</th>
                                                    <th className="wo-buttons">&nbsp;</th>

                                                </tr>
                                                }
                                                </thead>
                                                <tbody className="border-end">


                                                {this.mobxListado.map((slot, index) => (
                                                    <OrderFormSlot
                                                        key={slot && slot.id}
                                                        order={this.graphDataMainType}
                                                        rowIndex={index}
                                                        row={slot}
                                                        workOrders={appState.workOrderState.workOrders}
                                                        reload={(e) => this.reloadData(e)}
                                                        lastRow={this.mobxListado.length === 1}
                                                        fromWorkOrder={false}
                                                        blocked={appState.typifiedState.getObject(this.graphDataMainType.status).code !== 'APPROVED'}
                                                        hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                        setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                        {...this.props}
                                                    >
                                                        {this.clientsSlots.length > 0 &&
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="row">

                                                                    <div className="col-12 blue">
                                                                        {t("Próximas 6 visitas en este establecimiento")}
                                                                    </div>
                                                                    {this.clientsSlots.map((slot, index) => (
                                                                        <OrderFormSlotClientsPending
                                                                            row={slot} key={index}
                                                                        />
                                                                    ))}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        }
                                                    </OrderFormSlot>
                                                ))}
                                                </tbody>
                                                <tfoot>
                                                <tr>
                                                    <td colSpan="2">{t('TOTAL')}</td>
                                                    <td colSpan="5">{isNaN(horas) ?
                                                        0 + " h."
                                                        :
                                                        Math.round(horas * 100) / 100
                                                        + " h."}</td>
                                                </tr>
                                                </tfoot>
                                            </table>
                                        {appState.typifiedState.getObject(this.graphDataMainType.status).code == 'APPROVED' &&
                                        <div className="text-right pb-3">
                                            <VsfButtonNew
                                                disabled={this.state.hasSomeRowEditing}
                                                showButton={this.creatable["slot"] || false}
                                                label={t("Crear nueva")}
                                                onClick={_ => this.onOpenRowNewEmpty()}
                                                isEditing={this.state.hasSomeRowEditing}
                                                className="btn btn--loader"
                                            />
                                        </div>}
                                        <AlertModal isOpen={this.state.orderCanceled}
                                                    onCloseModal={() => this.setState({ "orderCanceled": false })}>
                                            <PopUp
                                                title={t('Operación no permitida')}
                                                icon={"exclamationTriangleIcon"}
                                                text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                                                label1={'Cerrar'}
                                                onClick1={() => this.setState({ orderCanceled: false })}
                                            />
                                        </AlertModal>
                                        <AlertModal isOpen={this.state.orderFinished}
                                                    onCloseModal={() => this.setState({ "orderFinished": false })}>
                                            <PopUp
                                                title={t('Operación no permitida')}
                                                icon={"exclamationTriangleIcon"}
                                                text={t(`Este Pedido está finalizado, para poder añadir una visita
                                                 tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                                                label1={'Cerrar'}
                                                onClick1={() => this.setState({ orderFinished: false })}
                                            />
                                        </AlertModal>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(OrderFormSlots)
