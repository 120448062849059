import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";

@observer
class IsoDateListItem extends Component {

    static propTypes = {
        format: PropTypes.string,
    };
    static defaultProps = {
        format: "DD-MM-YYYY",
        noAsignadoTexto: " - "
    };


    render() {
        const t = translate;
        let props = this.props;
        let value = props.value;
        if (util.hasValue(props.value)) {
            value = util.getMoment(props.value).format(props.format);
        } else {
            value = t(this.props.noAsignadoTexto);
        }
        return (
            <div className="isoDate" style={{ textAlign: 'right' }}>
                {value}
                {props.children}
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default IsoDateListItem