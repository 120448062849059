import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import SidebarItem from './SidebarItem'
import SidebarSubItem from './SidebarSubItem'
import appState from '../state/AppState'
import translate from '../translator/translate'
import AppLogger from '../util/AppLogger'
import OFFER from '../config/OFFER'
import LocalStorage from '../network/LocalStorage'
import util from '../util/Util'
import debug from "../util/debug";

const localStorage = new LocalStorage()

@observer
class Sidebar extends Component {

    constructor (props) {
        super(props)
        this.state = {
            scheduleVisitSidebar: (localStorage.getItem('scheduleVisitSidebar') === '1'),
            resourceSidebar: (localStorage.getItem('resourceSidebar') === '1'),
            stockSidebar: (localStorage.getItem('stockSidebar') === '1'),
            reportsSidebar: (localStorage.getItem('reportsSidebar') === '1'),
        }
    }

    ifPathStartsWith (pathMatch) {
        let result = false
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true
        }
        return result
    }

    ifPathIsDashboard (pathMatch) {
        let result = false
        if (pathMatch.length == '1') {
            result = true
        }
        return result
    }

    getVisibleModels () {
        let isRoleClient = appState.userState.isUserRoleClient();
        let isRoleAdminOrSuper = appState.userState.isUserRoleAdminOrSuperAdmin();
        let isRoleDistpacherOrSuperAdmin = appState.userState.isUserRoleDistpacherOrSuperAdmin();

        let equiposObj = {
            href: '/asset/ls',
            title: 'Catálogo de equipos',
            icon: 'fas fa-suitcase',
            model: 'asset',
            active: this.ifPathStartsWith('/asset/')
        }
        let clientPosObj = {
            href: '/clientPos/ls',
            title: 'Establecimientos',
            icon: 'fas fa-suitcase',
            model: 'client',
            active: this.ifPathStartsWith('/clientPos/ls') || appState.clientState.isPos
        }

        let result = []
        result.push({
            href: '/',
            icon: 'fas fa-door-open',
            title: 'Panel de Control',
            alwaysVisible: true,
            active: this.ifPathIsDashboard(this.props.location.pathname)
        })
        result.push({
            href: '/schedule/bytechnical',
            icon: 'fas fa-calendar-alt',
            title: 'Calendario',
            hideInSidebar: isRoleClient,
            model: 'slot',
            active: this.ifPathStartsWith('/schedule/bytechnical')
        })
        result.push({
            href: '/order/ls',
            icon: 'fas fa-file-import',
            title: 'Pedidos',
            model: 'order',
            active: this.ifPathStartsWith('/order')
        })
        result.push({
            href: '/workOrder/ls',
            icon: 'fas fa-file-alt',
            title: 'Órdenes de Trabajo',
            model: 'workOrder',
            active: this.ifPathStartsWith('/workOrder/')
        })
        result.push({
            href: '/slot/ls',
            icon: 'fas fa-calendar-check',
            title: 'Visitas',
            hideInSidebar: isRoleClient,
            model: 'slot',
            active: this.ifPathStartsWith('/slot')
        })
        result.push({
            href: '/budget/ls',
            icon: 'fas fa-file-alt',
            title: 'Presupuestos',
            hideInSidebar: isRoleClient,
            model: 'budget',
            active: this.ifPathStartsWith('/budget')
        })
        result.push({
            href: '',
            icon: 'fas fa-calendar-check',
            arrow: true,
            model: '',
            hideInSidebar: isRoleClient,
            title: 'Visitas Preventivas',
            active: (this.ifPathStartsWith('/schedule/visit/byclient') || this.ifPathStartsWith('/schedule/visit/type')),
            state: 'scheduleVisitSidebar',
            arrayChilds: [
                {
                    href: '/schedule/visit/byclient',
                    title: 'Planificación Clientes',
                    model: 'scheduleVisit',
                    active: this.ifPathStartsWith('/schedule/visit/byclient')
                },
                {
                    href: '/schedule/visit/type/ls',
                    title: 'Tipos de Visita Preventivas',
                    model: 'scheduleVisit',
                    active: this.ifPathStartsWith('/schedule/visit/type'),
                },

            ]
        })
        result.push({
            href: '/user/ls',
            icon: 'fas fa-users',
            title: 'Usuarios',
            hideInSidebar: isRoleClient,
            model: 'user',
            active: this.ifPathStartsWith('/user')
        })

        if (isRoleClient) {
            result.push(clientPosObj)
            result.push(equiposObj)
        } else {
            result.push({
                href: '',
                icon: 'fas fa-suitcase',
                arrow: true,
                model: '',
                title: 'Recursos',
                active: (this.ifPathStartsWith('/client/ls') ||
                    this.ifPathStartsWith('/clientPos/ls') ||
                    this.ifPathStartsWith('/contract/ls') ||
                    this.ifPathStartsWith('/sla/ls') ||
                    this.ifPathStartsWith('/origin/ls') ||
                    this.ifPathStartsWith('/subcontract/ls') ||
                    this.ifPathStartsWith('/asset/') ||
                    this.ifPathStartsWith('/assetModel') ||
                    this.ifPathStartsWith('/gpsVehicle') ||
                    this.ifPathStartsWith('/vehicle')),
                state: 'resourceSidebar',
                arrayChilds: [
                    {
                        href: '/client/ls',
                        title: 'Clientes',
                        hideInSidebar: isRoleClient,
                        model: 'client',
                        active: this.ifPathStartsWith('/client/ls') || appState.clientState.isClient
                    },
                    clientPosObj,
                    {
                        href: '/contract/ls',
                        title: 'Contratos',
                        hideInSidebar: isRoleClient || !OFFER.showSidebarContract,
                        model: OFFER.new_models && 'contract',
                        active: this.ifPathStartsWith('/contract/')
                    },
                    {
                        href: '/sla/ls',
                        title: 'SLAs',
                        hideInSidebar: isRoleClient,
                        model: OFFER.new_models && 'sla',
                        active: this.ifPathStartsWith('/sla/')
                    },
                    {
                        href: '/origin/ls',
                        title: 'Orígenes',
                        hideInSidebar: isRoleClient,
                        model: 'origin',
                        active: this.ifPathStartsWith('/origin/')
                    },
                    {
                        href: '/subcontract/ls',
                        title: 'Subcontratas',
                        model: OFFER.new_models && 'subcontract',
                        hideInSidebar: isRoleClient,
                        active: this.ifPathStartsWith('/subcontract/')
                    },
                    equiposObj,
                    {
                        href: '/assetModel/ls',
                        title: 'Fabricantes y Modelos',
                        hideInSidebar: isRoleClient,
                        model: 'assetModel',
                        active: this.ifPathStartsWith('/assetModel')
                    },
                    {
                        href: '/vehicle/ls',
                        title: 'Vehículos',
                        hideInSidebar: isRoleClient,
                        model: 'vehicle',
                        active: this.ifPathStartsWith('/vehicle')
                    },
                    {
                        href: '/gpsVehicle/ls',
                        title: 'GPS Vehículos',
                        hideInSidebar: isRoleClient,
                        model: OFFER.vehicle_gps_list && 'vehicle',
                        active: this.ifPathStartsWith('/gpsVehicle'),
                    },
                ]
            })
        }
        result.push({
            href: '',
            icon: 'fas fa-box',
            arrow: true,
            model: '',
            line: true,
            title: 'Piezas',
            hideInSidebar: isRoleClient,
            active: (this.ifPathStartsWith('/article/ls') ||
                this.ifPathStartsWith('/warehouse/ls') ||
                this.ifPathStartsWith('/stock/ls') ||
                this.ifPathStartsWith('/bin/ls') ||
                this.ifPathStartsWith('/workOrderCartArticle/ls') ||
                this.ifPathStartsWith('/movementStock/ls')),
            state: 'stockSidebar',
            arrayChilds: [
                {
                    href: '/warehouse/ls',
                    title: 'Almacenes',
                    hideInSidebar: isRoleClient,
                    model: 'warehouse',
                    active: this.ifPathStartsWith('/warehouse')
                },
                {
                    href: '/article/ls',
                    title: 'Catálogo de artículos',
                    hideInSidebar: isRoleClient,
                    model: 'article',
                    active: this.ifPathStartsWith('/article'),
                },
                {
                    href: '/stock/ls',
                    title: 'Stock',
                    hideInSidebar: isRoleClient,
                    model: 'stock',
                    active: this.ifPathStartsWith('/stock'),
                },
                {
                    href: '/workOrderCartArticle/ls',
                    title: 'Consumos',
                    hideInSidebar: isRoleClient,
                    model: 'workOrderCartArticle',
                    active: this.ifPathStartsWith('/workOrderCartArticle'),
                },
                {
                    href: '/movementStock/ls',
                    title: 'Movimientos',
                    hideInSidebar: isRoleClient,
                    model: 'stockMovement',
                    active: this.ifPathStartsWith('/movementStock'),
                },
                OFFER.bin &&
                {
                    href: '/bin/ls',
                    title: 'BINs',
                    hideInSidebar: isRoleClient,
                    model: 'bin',
                    active: this.ifPathStartsWith('/bin'),
                }
            ]
        })
        result.push({
            href: '/aplication',
            icon: 'fas fa-mobile',
            title: 'Aplicación',
            hideInSidebar: isRoleClient,
            model: 'configuration',
            active: this.ifPathStartsWith('/aplication')
        })
        result.push({
            href: '/typifiedValue/ls?type=workOrderType',
            icon: 'fas fa-car',
            title: 'Maestros',
            hideInSidebar: isRoleClient,
            showInSidebar: isRoleDistpacherOrSuperAdmin,
            model: 'typifiedValue',
            active: this.ifPathStartsWith('/typifiedValue')
        })
        result.push({
            href: '',
            icon: 'fas fa-chart-line',
            arrow: true,
            title: 'Informes',
            state: 'reportsSidebar',
            active: (this.ifPathStartsWith('/reports/')),
            hideInSidebar: !isRoleAdminOrSuper,
            model: 'configuration',
            arrayChilds: [
                {
                    href: '/reports/resume',
                    title: 'Resumen',
                    active: this.ifPathStartsWith('/reports/resume'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/type',
                    title: 'Tipos',
                    active: this.ifPathStartsWith('/reports/type'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/task',
                    title: 'Tareas',
                    active: this.ifPathStartsWith('/reports/task'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/diagnosis',
                    title: 'Diagnósticos',
                    active: this.ifPathStartsWith('/reports/diagnosis'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/location',
                    title: 'Lugares',
                    active: this.ifPathStartsWith('/reports/location'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/technical',
                    title: 'Técnicos',
                    active: this.ifPathStartsWith('/reports/technical'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/matrixTechnical',
                    title: 'Matriz técnicos',
                    active: this.ifPathStartsWith('/reports/matrixTechnical'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                },
                {
                    href: '/reports/matrixModel',
                    title: 'Matriz equipos',
                    active: this.ifPathStartsWith('/reports/matrixModel'),
                    hideInSidebar: !isRoleAdminOrSuper,
                    model: 'typifiedValue',
                }
            ]
        })
        return result
    }

    onClick (model) {
        if (model.state != null) {
            this.state[model.state] = !this.state[model.state]
            this.setState({ ...this.state })
            let localStorageSidebar = '0'
            if (this.state[model.state]) {
                localStorageSidebar = '1'
            }
            localStorage.setItem(model.state, localStorageSidebar)
        }
    }

    render () {
        const t = translate
        let sidebar = []
        for (let visibleModel of this.getVisibleModels()) {
            let encontrado = false
            if (!visibleModel.hideInSidebar) {
                if (visibleModel.alwaysVisible) {
                    encontrado = true
                } else if (!util.hasValue(visibleModel.model)) {
                    for (let childModel of visibleModel.arrayChilds) {
                        if (OFFER.readable?.[childModel.model]) {
                            encontrado = true
                        }

                    }
                } else if (OFFER.readable?.[visibleModel.model]) {
                    encontrado = true
                }
            }
            if (encontrado) {
                sidebar.push(visibleModel)
            }
        }
        let offerWms = (appState.userState.isUserRoleWarehouseManagerOrSuperAdmin() || appState.userState.isUserRoleWarehouseOperator() || appState.userState.isUserRoleAdmin())
        if (window.location.hostname === 'localhost' && appState.userState.isUserRoleAdminOrSuperAdmin()) {
            offerWms = true
        }
        return (
            <nav className="sidebar-nav">
                <div className="nav">
                    {sidebar.map(model => {
                        let show=true;
                        if (show) {
                            //Si se incluye showInSidebar solo se muestra en ese caso
                            if (model.showInSidebar!=null) {
                                show = false;
                                if (model.showInSidebar) {
                                    //debug.d({model},this);
                                    show = true;
                                }
                            }
                        }
                        if (show) {
                            return (
                                <SidebarItem key={model.title} href={model?.href} icon={model?.icon}
                                             title={t(model?.title)}
                                             dataCy={model?.model} {...this.props}
                                             line={model?.line} active={model?.active}
                                             arrow={model?.arrow} stateOpen={this.state[model.state]}
                                             click={() => this.onClick(model)}>
                                    {model?.arrayChilds?.map(childModel => {

                                            let show = false;
                                            //Si no se muestra debido a showInSidebar proceso hide o readabla
                                            if (!childModel.hideInSidebar && OFFER.readable[childModel.model]) {
                                                show = true;
                                            }
                                            //Si se muestra pero tiene un campo de showInSidebar, solo se lo muestra a ese rol
                                            if (show) {
                                                if (childModel.showInSidebar != null) {
                                                    show = false;
                                                    if (childModel.showInSidebar) {
                                                        //debug.d({childModel}, this);
                                                        show = true;
                                                    }
                                                }
                                            }
                                            if (show) {
                                                return (
                                                    <SidebarSubItem key={childModel.title} href={childModel?.href}
                                                                    icon={childModel?.icon}{...this.props}
                                                                    title={t(childModel?.title)}
                                                                    active={childModel?.active}/>
                                                )
                                            } else {
                                                return null;
                                            }
                                        }
                                    )}

                                </SidebarItem>
                            )
                        } else {
                            return null;
                        }
                        }
                    )}
                    {offerWms &&
                    <SidebarItem id="app" href="/wms/main" icon="fas fa-warehouse" {...this.props}
                        active={this.ifPathStartsWith('/wms/main')} title={t('WMS')} />
                    }
                </div>
            </nav>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default React.memo(withRouter(Sidebar))

