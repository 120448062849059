import React from 'react';
import {observer} from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import SlotModel from "../../models/SlotModel";
import {computed} from "mobx";
import BaseEditableTable from "../base/BaseEditableTable";
import GroupOfField from "../../components/layout/GroupOfField";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import translate from "../../translator/translate";
import OrderModel from "../../models/OrderModel";
import EndVisitModal from "./EndVisitModal";
import MoreButtons from "../../components/MoreButtons";
import OrderSummarySlot from "./OrderSummarySlot";
import OrderSummaryWorkLogs from "./OrderSummaryWorkLogs";
import WorkOrderDetailRow from "../../components/WorkOrderDetailRow";
import GqlErrors from "../../components/status/GqlErrors";
import OrderActions from "./OrderActions";
import WorkLogModel from "../../models/WorkLogModel";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class OrderSummaryPage extends BaseEditableTable {

    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.state = {
            hasSomeRowEditing: false,
            showMore: "",
            loading: true,
        }
    }

    @computed get mobxListado() {
        return appState.summaryState.slots;
    }

    setMobxListado(newValue) {
        appState.summaryState.slots = newValue;
    }

    getModelTable() {
        return new SlotModel();
    }

    getModelQuery() {
        return new OrderModel();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.loadTable();
        await appState.userCacheState.loadUserCache();
        this.setState({loading: false})
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("client");
        result.addRelatedTable("slot");
        result.addRelatedTable("slot.workLog");
        result.addRelatedTable("slot.workOrder");
        result.addRelatedTable("slot.workOrder.workLog");
        result.addRelatedTable("slot.workOrder.workOrderCartArticle");
        result.addRelatedTable("slot.workOrder.workOrderCartArticle.article");
        result.addRelatedTable("slot.workOrder.asset");
        result.addRelatedTable("slot.workOrder.asset.assetModel");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    async loadTable() {
        let slots = [];
        if (this.graphDataMainType.slot) {
            for (let slot of this.graphDataMainType.slot) {
                if (slot.isFinished) {
                    slots.push(slot)
                }
            }
        }
        let copy = slots.sort(function (a, b) {
            let key1 = util.getMoment(a.scheduledTime).format("MMDDHHmm");
            let key2 = util.getMoment(b.scheduledTime).format("MMDDHHmm");
            if (key1 > key2) {
                return -1;
            }
            if (key2 < key1) {
                return 1;
            }
            return 0;
        });
        appState.summaryState.slots = copy;

    }

    getWorkLogOfSlotAndWorkOrder(workOrder, slotId) {
        let result = [];
        if (workOrder.workLog != null) {
            for (let workLog of WorkLogModel.ordenarWorkLogs(workOrder.workLog)) {
                if (workLog.slotId === slotId && workLog.workOrderId === workOrder.id) {
                    if (workLog.action === WorkLogModel.END_WORK_ORDER ||
                        workLog.action === WorkLogModel.RETURN_WORK_ORDER ||
                        workLog.action === WorkLogModel.START_WORK_ORDER ||
                        workLog.action === WorkLogModel.PENDING ||
                        workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                        result.push(workLog);
                    }
                }
            }
        }
        return result;
    }

    renderImpl() {
        let order = this.graphDataMainType;
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)}/>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal}
                                                  setErrors={(errors) => this.setErrors(errors)} model={order}
                                                  getModelQuery={() => this.getModelQuery()}/>
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active="Partes de trabajo"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            onReloadData={() => this.reloadData()}
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal}
                                              setErrors={(errors) => this.setErrors(errors)} model={order}
                                              getModelQuery={() => this.getModelQuery()}/>
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card ">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active={"Partes de trabajo"}
                            />
                            }
                            <GroupOfField
                                title={(this.mobxListado.length >= 1) ? t('Creadas') : t('Todavia no has finalizado ninguna visita')}
                                subtitle={(this.mobxListado.length >= 1) ? this.mobxListado.length + " " + t('Finalizadas') : ''}
                                icon='fas fa-file'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="table-edit table-edit--section">
                                    <table className="table visible-text table--section">
                                        {this.mobxListado.length == 0 &&
                                        <tbody>
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna planificacion finalizada')}
                                            </td>
                                        </tr>
                                        </tbody>
                                        }
                                    </table>
                                    <div className="c-modal-info__cell__body__table">
                                        {this.mobxListado.map((slot, index) => (
                                            <>
                                                <table className="w-100 mb-3" key={index}>
                                                    <OrderSummarySlot
                                                        key={slot.id}
                                                        orderId={order.id}
                                                        order={order}
                                                        rowIndex={index}
                                                        row={slot}
                                                        showMore={this.state.showMore}
                                                        changeShowMore={(slotId) => this.setState({showMore: slotId})}
                                                        {...this.props}
                                                    />
                                                </table>

                                                {this.state.showMore === slot.id &&
                                                <table className="w-100 mb-3" key={index}>
                                                    <thead>
                                                    <tr>
                                                        {/*<th>{t("Tipo")}</th>*/}
                                                        <th>{t("Orden de trabajo")}</th>
                                                        <th>{t("Estado")}</th>
                                                        <th>{t("Equipo matrícula")}</th>
                                                        <th>{t("Fabricante")}</th>
                                                        <th>{t("Modelo")}</th>
                                                        <th>{t("N Serie")}</th>
                                                        <th>{t("Tipo de equipo")}</th>
                                                        <th>{t("Subtipo de equipo")}</th>
                                                        {/*<th>{t("Duración")}</th>*/}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {slot.workOrder?.map((workOrder) => {
                                                        if (this.getWorkLogOfSlotAndWorkOrder(workOrder, slot.id)?.length > 0) {
                                                            return (
                                                                <WorkOrderDetailRow
                                                                    workOrder={workOrder} key={workOrder?.id}
                                                                    slot={slot} fromSummary>
                                                                    <tr>
                                                                        <td colSpan={8}/>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={8}>
                                                                            <OrderSummaryWorkLogs
                                                                                workLogs={workOrder.workLog}
                                                                                workOrder={workOrder}
                                                                                slot={slot}
                                                                                workOrderCartArticles={workOrder.workOrderCartArticle}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                </WorkOrderDetailRow>
                                                            )
                                                        }
                                                        return null;
                                                    })}
                                                    </tbody>

                                                </table>
                                                }
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                        {this.propsUtil.getRequest("modalTab") === "endVisit" &&
                        <EndVisitModal
                            openModal={this.propsUtil.getRequest("modalTab") === "endVisit"}
                            slotId={this.propsUtil.getRequest("slotId")}
                            closeModal={() => this.propsUtil.changeUrlRequest({
                                modalTab: null, slotId: null, fromRightModal: null,
                            })}
                            order={order}
                        />
                        }
                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(OrderSummaryPage)
