import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class ChangesWithValidationsState extends BaseModelState {

    @observable validates = [];
    @observable validatesForm = [];
    @observable validateChanges = {};
    @observable formValidations;
    @observable imageValidations;

}

export default ChangesWithValidationsState;
