import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import ScheduleVisitSlotModel from "./ScheduleVisitSlotModel";
import OriginModel from "./OriginModel";

export default class ScheduleVisitModel extends AbstractModel {

    id;

    name;
    estimatedPrincipalTime;
    estimatedtotalTime;
    technicalCount;
    originId;
    type;

    nameMainType="scheduleVisit";
    graphFindByIdOperation = "scheduleVisit";
    graphFindOperation="scheduleVisitsConnection";

    getArrayFields() {
        let resultBase=super.getArrayFields();
        let result = {
            ...resultBase,
            "name":"",
            "estimatedPrincipalTime":"",
            "estimatedtotalTime":"",
            "technicalCount":"",
            "originId":"",
            "type":"",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "originModel", "RelationOne", OriginModel);
        this.addRelatedTableGeneric(result, "scheduleVisitSlots", "Relation", ScheduleVisitSlotModel);
        return result;
    }

}
