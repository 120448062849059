import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class SlaModel extends AbstractModel {

    static CODE_HOUR = "HOUR";
    static CODE_DAY = "DAY";
    static CODE_WEEK = "WEEK";
    static CODE_MONTH = "MONTH";
    static CODE_MONDAY = "MONDAY";
    static CODE_TUESDAY = "TUESDAY";
    static CODE_WEDNESDAY = "WEDNESDAY";
    static CODE_THURSDAY = "THURSDAY";
    static CODE_FRIDAY = "FRIDAY";
    static CODE_SATURDAY = "SATURDAY";
    static CODE_SUNDAY = "SUNDAY";

    name;
    duration;
    period;
    weekDay;
    weekHour;

    nameMainType = "sla";
    graphFindByIdOperation = "sla";
    graphFindOperation = "slasConnection";
    graphExportOperation = "slasExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name" : { label: "Nombre" },
            "duration" : { label: "Duración" },
            "weekDay" : { label: "Día de la semana" },
            "weekHour" : { label: "Hora" },
            "period" : { label: "Periodo" },
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        return result;
    }

};
