import React from 'react';
import {observer} from 'mobx-react';
import translate from "../../translator/translate";
import PopUp from "../../components/modals/PopUp";
import AlertModal from "../../subpages/AlertModal";
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import BaseActions from "../base/BaseActions";
import appState from "../../state/AppState";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import WorkOrderMovedModal from "../../components/WorkOrderMovedModal";
import WorkLogModel from "../../models/WorkLogModel";
import OFFER from "../../config/OFFER";

@observer
class WorkOrderActions extends BaseActions {

    constructor(props) {
        super(props);
        this.state = {
            rightModal: false,
            popUp: "",
        }
    }

    changePageOrder(workOrder) {
        this.propsUtil.changeUrl("/order/form/" + workOrder.order?.id)
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let model = this.props.model;
        return (
            <>
                <div className="dropdown-sublist__item ">
                    <a onClick={() => {
                        this.changePageOrder(model);
                        this.closeModal()
                    }}>
                        {"Ir al pedido"}
                    </a>
                    {model.deletable &&
                    <a onClick={() => this.setState({ rightModal: true, popUp: "eliminar" })}>
                        {t("Eliminar Orden de trabajo")}
                    </a>
                    }
                    {!appState.userState.isUserRoleClient() &&
                        <a onClick={() => {
                            if (this.props.fromRightModal) {
                                this.propsUtil.changeUrlRequest({ "rightModalTab": "history" });
                            } else {
                                this.propsUtil.changeUrl(this.urlForHistoric());
                            }
                            this.closeModal()
                        }}>
                            {t("Control de cambios")}
                        </a>
                    }
                    {appState.userState.isUserRoleDispatcherOrMore() && OFFER.SOFinishedMoveOT &&
                    <>
                        {(model.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, WorkLogModel.RETURN_WORK_ORDER).id ||
                            model.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, WorkLogModel.PENDING).id) &&
                        <a onClick={() => this.setState({ workOrderMoved: true })}>
                            {"Mover a otro pedido"}
                        </a>
                        }
                    </>
                    }
                </div>

                <AlertModal isOpen={this.state.rightModal && this.state.popUp != ""}
                            onCloseModal={() => this.setState({
                                rightModal: false,
                                popUp: ""
                            })}
                >
                    {this.state.popUp == "eliminar" &&
                    <PopUp
                        title={t('Eliminar')}
                        subtitle={t('Orden de trabajo') + model.code}
                        icon="exclamationTriangleIcon"
                        text={t('¿Estas Seguro de Eliminar esta orden de trabajo y todos sus datos relaccionados?')}
                        label1={t('No eliminar')}
                        label2={t('Eliminar')}
                        onClick1={() => this.setState({ rightModal: false, popUp: "" })}
                        onClick2={() => this.deleteModel('workOrder')}
                    >
                    </PopUp>
                    }
                </AlertModal>
                {this.state.workOrderMoved &&
                <WorkOrderMovedModal
                    title={t('Movimiento de OT ')}
                    workOrderModel={model}
                    openModal={this.state.workOrderMoved}
                    closeModal={() => {
                        this.setState({ workOrderMoved: false })
                        this.closeModal()
                    }}
                />
                }
            </>
        )
    }
}

export default withRouter(WorkOrderActions)
