import React from 'react';
import { observer } from 'mobx-react';
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import BaseEditableRow from "../base/BaseEditableRow";
import { computed } from "mobx";
import appState from "../../state/AppState";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import ScheduleVisitSlotModel from "../../models/ScheduleVisitSlotModel";
import SlotModel from "../../models/SlotModel";


@observer
class ScheduleVisitSlotForm extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.foreingKeyfield = "scheduleVisitId";

    }

    @computed
    get mobxListado() {
        return appState.scheduleSlotState.scheduleSlots;
    }

    setMobxListado(newValue) {
        appState.scheduleSlotState.scheduleSlots = newValue;
    }

    getModelTable() {
        return new ScheduleVisitSlotModel();
    }

    async onOpenSupport(scheduledVisit) {
        let newModel = this.getModelTable().toPlainObject();
        newModel.slotUserRole = SlotModel.SUPPORT;
        newModel.scheduledDuration = scheduledVisit.scheduledDuration;
        newModel.isEditing = true;
        this.mobxListado.splice((this.props.rowIndex + 1), 0, newModel);
        this.props.setHasSomeRowEditing(true);
    }

    editSupport(scheduledVisit) {
        if (!scheduledVisit.isEditing && !this.props.hasSomeRowEditing) {
            this.onOpenSupport(scheduledVisit)
        }
    }

    renderSupport(readOnly) {
        let scheduledVisit = this.props.row;
        if (scheduledVisit.slotUserRole !== SlotModel.SUPPORT && !readOnly) {
            this.eliminar = this.props.rowIndex !== 0;
            return (
                <div className="remove-add">
                    <div onClick={() => this.editSupport(scheduledVisit)} title="Asignar usuario de apoyo"
                         className={scheduledVisit.isEditing || this.props.hasSomeRowEditing ? "edit inactive" : "edit"}>
                        <span className="fas fa-user-plus" />
                    </div>
                </div>
            )
        }
    }

    render() {
        let scheduledVisit = this.props.row;
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <tr className={scheduledVisit.isEditing ? "editing" : ""}>
                    <td>
                        {scheduledVisit.isEditing ?
                            <InputTypeComponent
                                prefix={scheduledVisit.slotUserRole === SlotModel.SUPPORT ? "fa fa-user-alt" : "fa fa-user-check"}
                                value={scheduledVisit.slotUserRole === SlotModel.SUPPORT ? "APOYO" : "PRINCIPAL"}
                                readOnly={true}
                                classGroup={"col-12"}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"slotUserRole"}
                                className="form-control"
                            />
                            :
                            <>
                                {scheduledVisit.slotUserRole === SlotModel.SUPPORT ?
                                    <div style={{ color: '#A2BFD0' }}>
                                        <i className="fa fa-user-alt" />&nbsp;
                                    </div>
                                    :
                                    <div style={{ color: '#3D708D' }}>
                                        <i className="fa fa-user-check" />&nbsp;
                                    </div>
                                }
                            </>
                        }
                    </td>
                    <td>
                        <InputTypeComponent
                            value={scheduledVisit.scheduledDuration}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"scheduledDuration"}
                            showView={!scheduledVisit.isEditing}
                            className="form-control"
                            validate={"number|numberMinValue:1|numberMaxValue:10"}
                        />
                    </td>
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default ScheduleVisitSlotForm;
