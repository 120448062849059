import React from 'react';
import {observer} from "mobx-react/index";
import appState from '../../state/AppState';
import PropsUtil from "../../util/PropsUtil";
import {withRouter} from "react-router-dom";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import TabsComponent from "../../components/fields/TabsComponent";
import PageTitle from "../../components/PageTitle";
import CheckboxUniqueComponent from "../../components/fields/CheckboxUniqueComponent";
import UserModel from "../../models/UserModel";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import BaseForm from "../base/BaseForm";
import FormButtons from "../../components/FormButtons";
import util from '../../util/Util';
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import NetworkStatusInfo from "../../components/status/NetworkStatusInfo";
import MoreButtons from "../../components/MoreButtons";
import {observable} from "mobx";
import TabsData from "../../layout/TabsData";
import UserActions from "./UserActions";
import GqlErrors from "../../components/status/GqlErrors";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class UserCapacitations extends BaseForm {

    nameMainType = "user";
    @observable capacitations = {};

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.tabsData.isClient = appState.userState.isUserRoleClient();
        this.state = {
            loading: true
        };
    }

    getModelQuery() {
        return new UserModel();

    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);
        await this.loadStateWithCurrentCapacitations();
        this.setState({ loading: false })
    }

    /**
     * Carga en el state las capacitaciones actuales del user actual
     */
    loadStateWithCurrentCapacitations() {
        let user = this.graphDataMainType;
        if (user.capacitationIds != null) {
            let arrayCurrentCapacitations = user.capacitationIds.split(",");
            for (let capacitation of arrayCurrentCapacitations) {
                this.capacitations[capacitation] = "";
            }
        }
    }

    async formDidSave(model, previousId) {
        if (this.ifPathStartsWith("/schedule/bytechnical")) {
            for (let usuario of appState.scheduleByTechDropState.tecnicosCalendario) {
                if (usuario.id === model.id) {
                    let icons = [];
                    if (util.hasValue(model.capacitationIds)) {
                        for (let capacitation of model.capacitationIds.split(',')) {
                            if (appState.typifiedState.getObject(capacitation)) {
                                icons.push(appState.typifiedState.getObject(capacitation));
                            }
                        }
                    }
                    usuario.capacitationIds = icons;
                }
            }
        }
    }

    onChangeCheck(typifiedId) {
        let value = typifiedId;
        if (this.capacitations[value] == null) {
            this.capacitations[value] = "";
        } else {
            delete this.capacitations[value];
        }
        this.updateInput("capacitationIds", Object.keys(this.capacitations).join(','));
    }

    renderImpl() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let user = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                                 getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataUser()}
                                active="Capacitación"
                                alarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            onReloadData={() => this.reloadData()}
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.getFormId() != null &&
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                             getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataUser()}
                                active={"Capacitación"}
                                fromRightModal={this.props.fromRightModal}
                            />
                            }
                            <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                                error={this.graphStatus.mutationError}
                                working={this.graphStatus.networkWorking}
                            />
                            <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                            <form
                                className="model-form"
                                name="formulario"
                                noValidate
                                onSubmit={(e) => this.handleFormSubmit(e)}>
                                <SpinnerClipLoaderInPage loading={this.state.loading}>

                                    <div className={'card mb-3 pb-3 pb-lg-0'}>
                                        <div className="mt-4">
                                            <div className="table-edit">
                                                <table className="table visible-text"
                                                       summary="Listado de capacitación de técnicos">
                                                    <thead>
                                                    <tr>
                                                        {appState.typifiedState.arrWithType[TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION]?.length === 0 &&
                                                        <td colSpan="8"
                                                            className="title-cell">{t('No hay ninguna capacitación')}</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <th scope="col">{t('Texto')}</th>
                                                        <th scope="col">{t('Icono')}</th>
                                                        <th scope="col">{t('Activo')}</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody className="border-end">
                                                    {appState.typifiedState.arrWithType[TypifiedValueModel.CODE_EMPLOYEE_CAPACITATION]?.map((typified) => {
                                                            if (!typified.logicalDelete || this.capacitations[typified.id] != null) {

                                                                return (
                                                                    <tr key={typified.id}
                                                                        className={typified.isEditing ? "editing" : ""}>
                                                                        <td>
                                                                            <div>
                                                                                {appState.typifiedState.getValue(typified.id)}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="icon-select-capacitation-box"
                                                                                 style={{ backgroundColor: util.hasValue(typified.color) ? typified.color : "#6C757D", }}>
                                                                                {util.getIconoStyled(typified.icon)}
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <CheckboxUniqueComponent
                                                                                checked={this.capacitations[typified.id] != null}
                                                                                onChange={() => this.onChangeCheck(typified.id)}
                                                                                name={typified.id}
                                                                                editable={this.editable}
                                                                                className={"approved"}
                                                                                classGroup={"col-12"}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                        {this.props.fromRightModal ?
                                            <>
                                                <div className={"fixed-bottom-space-maker"} />
                                                <div className="fixed-bottom mr-3 mt-3">
                                                    <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />
                                                </div>
                                            </>
                                            :
                                            <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />
                                        }

                                    </div>

                                </SpinnerClipLoaderInPage>
                            </form>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(UserCapacitations)
