import React from 'react'
import {observer} from 'mobx-react'
import {withRouter} from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import PropsUtil from '../../util/PropsUtil'
import InfoBlock from '../../components/InfoBlock'
import util from '../../util/Util'
import AssetModel from '../../models/AssetModel'
import t from '../../translator/translate'
import GroupOfField from '../../components/layout/GroupOfField'
import MoreButtons from '../../components/MoreButtons'
import CreateAndModify from '../../components/fields/CreateAndModify'
import GqlErrors from '../../components/status/GqlErrors'
import GqlWarnings from '../../components/status/GqlWarnings'
import {computed} from 'mobx'
import AssetActions from './AssetActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import appState from '../../state/AppState'
import BaseEditableTable from '../base/BaseEditableTable'
import PlanogramDetailModel from '../../models/PlanogramDetailModel'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import FormButtons from '../../components/FormButtons'
import MultiModel from '../../models/MultiModel'
import Select2Component from "../../components/fields/Select2Component";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import DateInputFieldMobiscroll from "../../components/fields/DateInputFieldMobiscroll";

@observer
class AssetPlanogram extends BaseEditableTable {

    constructor (props) {
        super(props)
        this.nameMainType = 'asset'
        this.foreingKeyfield = 'assetId'
        this.initializeGraphData(this.nameMainType)
        this.tabsData = new TabsData()
        this.state = {
            hasSomeRowEditing: false,
            loading: true,
        }
    }

    @computed get mobxListado () {
        return appState.assetState.assetPlanograms
    }

    setMobxListado (newValue) {
        appState.assetState.assetPlanograms = newValue
    }

    async reloadTable () {
        appState.assetState.assetPlanograms = this.graphDataMainType.planogramDetail?.slice(0, this.graphDataMainType.planogramDetailCount) || []

    }

    getModelQuery () {
        let result = new AssetModel()
        return result
    }

    getModelQueryForView () {
        let result = new AssetModel()
        result.addRelatedTable('planogramDetail')
        return result
    }

    async formDidSave (model, previousId) {
        let multiModels = []
        if (model.planogramDetailCount !== this.previousRowForDiscard.planogramDetailCount) {
            for (let i = this.graphDataMainType.planogramDetail?.length; i < model.planogramDetailCount; i++) {
                let planogramDetail = new PlanogramDetailModel()
                planogramDetail.assetId = model.id
                planogramDetail.numberRail = i.toString()
                multiModels.push(planogramDetail)
            }
        }
        if (multiModels.length > 0) {
            let multiModelQuery = new MultiModel()
            await multiModelQuery.persist(multiModels)
        }
        await this.reloadData()

    }

    getModelTable () {
        return new PlanogramDetailModel()
    }

    async persist(model) {
        await model.persist();
        //Guardo los planogramas que se hayan modificado
        for(let planogramDetailMobx of this.mobxListado) {
            if (planogramDetailMobx["rowModified"]) {
                let planogramDetail = new PlanogramDetailModel();
                planogramDetail.hidrate(planogramDetailMobx);
                await planogramDetail.persist()
            }
        }
    }

    handlePlanogramDetailInputChange(planogramDetail, event) {
        appState.layoutState.formWithoutChanges = false;
        this.previousRowForDiscard = this.previousRowForDiscard || {};
        planogramDetail[event.target.name]= event.target.value;
        planogramDetail["rowModified"]= true;
    }

    renderPostmixForm () {
        let asset = this.graphDataMainType
        return (
            <>
                <GroupOfField title={t('Configuración del planograma tipo PostMix')}
                    icon="fas fa-search-plus" />
                <CreateAndModify
                    updatedAt={asset.updatedAt}
                    updatedBy={asset.updatedBy}
                    createdBy={asset.createdBy}
                    createdAt={asset.createdAt}
                />
                <div className="table-edit">
                    <table className="table visible-text">
                        <thead>
                            {this.mobxListado.length == 0 &&
                        <tr>
                            <td colSpan="8"
                                className="title-cell">{t('No hay ningun planograma creado')}
                            </td>
                        </tr>
                            }
                            {this.mobxListado.length != 0 &&
                        <tr>
                            <th className="duration" scope="col">{t('Carril')}</th>
                            <th scope="col">{t('Producto')}</th>
                            <th scope="col">{t('Sabor')}</th>
                            <th className="duration" scope="col">{t('Ratio')}</th>
                            <th className="duration" scope="col">{t('Temp')}</th>
                            <th className="wo-width-date" scope="col">{t('F. Consumo')}</th>
                            <th scope="col">{t('Info')}</th>
                        </tr>
                            }
                        </thead>
                        <tbody className="border-end">
                            {this.mobxListado.map((planogramDetail, index) => (
                                <tr key={planogramDetail.id + "_" + index} aria-rowspan="2" className={planogramDetail.isEditing && 'editing'}
                                    style={{ 'borderBottom': 0 }}>
                                    <td>
                                        {planogramDetail.numberRail}
                                    </td>
                                    <td>
                                        <Select2Component
                                            value={planogramDetail.productId}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'productId'}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_PLANOGRAM_PRODUCT)}
                                            extraWidth={100}
                                        />
                                    </td>
                                    <td>
                                        <Select2Component
                                            value={planogramDetail.qualityTasteId}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'qualityTasteId'} ordenar={false}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_PLANOGRAM_TASTE)}
                                            extraWidth={100}
                                        />
                                    </td>
                                    <td>
                                        <InputTypeComponent
                                            value={planogramDetail.ratio}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'ratio'}
                                            className="form-control "
                                            validate={'number'}

                                        />
                                    </td>
                                    <td>
                                        <InputTypeComponent
                                            value={planogramDetail.temperature}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'temperature'}
                                            className="form-control "
                                            validate={'number'}
                                        />
                                    </td>
                                    <td>
                                        <DateInputFieldMobiscroll
                                            prefix={'fas fa-calendar-alt'}
                                            value={planogramDetail.dateExpire}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'dateExpire'}
                                            allowEmptyDate={true}
                                        />
                                    </td>
                                    <td>
                                        <InputTypeComponent
                                            value={planogramDetail.planogramInfo}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'planogramInfo'}
                                            className="form-control "
                                        />
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="text-right pb-3">
                    <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />

                </div>
            </>
        )
    }

    renderVendingForm () {
        let asset = this.graphDataMainType
        return (
            <>
                <GroupOfField title={t('Configuración del planograma tipo Vending')}
                    icon="fas fa-search-plus" />
                <CreateAndModify
                    updatedAt={asset.updatedAt}
                    updatedBy={asset.updatedBy}
                    createdBy={asset.createdBy}
                    createdAt={asset.createdAt}
                />
                <div className="table-edit">
                    <table className="table visible-text">
                        <thead>
                            {this.mobxListado.length == 0 &&
                        <tr>
                            <td colSpan="8"
                                className="title-cell">{t('No hay ningun planograma creado')}
                            </td>
                        </tr>
                            }
                            {this.mobxListado.length != 0 &&
                        <tr>
                            <th className="duration" scope="col">{t('Carril')}</th>
                            <th scope="col">{t('Producto')}</th>
                            <th scope="col">{t('Envase')}</th>
                            <th scope="col">{t('Precio')}</th>
                        </tr>
                            }
                        </thead>
                        <tbody className="border-end">
                            {this.mobxListado.map((planogramDetail, index) => (
                                <tr aria-rowspan="2" className={planogramDetail.isEditing && 'editing'}
                                    style={{ 'borderBottom': 0 }}>
                                    <td>
                                        {planogramDetail.numberRail}
                                    </td>
                                    <td>
                                        <Select2Component
                                            value={planogramDetail.productId}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'productId'}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_PLANOGRAM_PRODUCT)}
                                            extraWidth={100}
                                        />
                                    </td>
                                    <td>
                                        <Select2Component
                                            value={planogramDetail.canId}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'canId'}
                                            ordenar={false}
                                            options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_PLANOGRAM_CAN)}
                                            extraWidth={100}
                                        />
                                    </td>
                                    <td>
                                        <InputTypeComponent
                                            value={planogramDetail.price}
                                            onChange={(e) => this.handlePlanogramDetailInputChange(planogramDetail,e)}
                                            name={'price'}
                                            className="form-control "
                                            validate={'number'}
                                        />

                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="text-right pb-3">
                    <FormButtons id={this.graphDataMainType.id} formStatus={this.graphStatus} />
                </div>
            </>
        )
    }

    renderImpl () {
        this.propsUtil = new PropsUtil(this.props)
        let modalClasses = util.getModalClasses()
        let asset = this.graphDataMainType
        let errorsMapped = this.getGraphErrorsFromStatus()
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.type).config)
        }
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ''}>
                <InfoBlock componentObject={this}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                                onCloseModal={() => this.onCloseModal()}>
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal}
                                        setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={'Planograma'}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle title={this.getPageTitle()} subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal}
                                    setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active={'Planograma'}
                            />
                            }
                            <SpinnerClipLoaderInPage loading={this.state.loading}>

                                <form
                                    className="model-form"
                                    name="formulario" noValidate
                                    onSubmit={(e) => this.handleFormSubmit(e)}>
                                    <div className="row">
                                        <InputTypeComponent
                                            value={asset.planogramDetailCount}
                                            onChange={(e) => this.updateInputEvent(e)}
                                            name={'planogramDetailCount'}
                                            validate={'number|numberMaxValue:50'}
                                            title={t('Numero de carriles')}
                                            classGroup={'col-md-3 col-lg-3 col-xl-3'}
                                            errors={errorsMapped.planogramDetailCount}
                                        />
                                    </div>
                                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                                    <GqlWarnings warnings={this.gqlWarnings}
                                        onSaveWarning={() => this.saveSlotWarning()}
                                        key={this.gqlWarnings}
                                        setWarnings={(warnings) => this.cancelSlotWarning(warnings)} />
                                    {config?.vending === '1' &&
                                    this.renderVendingForm()
                                    }
                                    {config?.postmix === '1' &&
                                    this.renderPostmixForm()
                                    }
                                </form>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        )
    }

}

export default withRouter(AssetPlanogram)
