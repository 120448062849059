import React, { Component } from 'react'
import { observer } from 'mobx-react'
import VsfButtonNew from '../VsfButtonNew'
import SidebarModal from './SidebarModal'
import { observable } from 'mobx'
import OFFER from '../../config/OFFER'
import PropsUtil from '../../util/PropsUtil'
import translate from '../../translator/translate'
import appState from '../../state/AppState'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import { Accordion, Card } from 'react-bootstrap'

@observer
class SidebarModals extends Component {
    constructor () {
        super()
        this.state = {
            activeKey: 0
        }
    }

    @observable creatable = {}

    ifPathStartsWith (pathMatch) {
        let result = false
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true
        }
        return result
    }

    handleActiveKeyChange (activeKey) {
        if (this.state.activeKey === activeKey) {
            //allows us to close expanded item by clicking its toggle while open
            activeKey = -1
        }
        this.setState({ activeKey })
    }

    async componentDidMount () {
        this.creatable = OFFER.creatable
        this.loadOriginList()
    }

    loadOriginList () {
        const t = translate
        let result = {}
        for (let typified of appState.typifiedState.getArrayFromMaster(TypifiedValueModel.CODE_WORK_ORDER_TYPE)) {
            if (!typified.logicalDelete) {
                if (result[typified.originModel?.name || t('Maestros generales')]) {
                    result[typified.originModel?.name || t('Maestros generales')].push(typified)
                } else {
                    result[typified.originModel?.name || t('Maestros generales')] = [typified]
                }
            }
        }
        this.typifiedList = result
    }

    @observable typifiedList = {}

    render () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let showButton = false
        if (!this.state.openSidebarModal) {
            if (this.ifPathStartsWith('/schedule/bytechnical')) {
                showButton = true
            }
        }
        return (
            <>
                {showButton &&
                <>
                    <button
                        className={'rightActions-question'}
                        onClick={() => this.setState({ openSidebarHelper: true })}>
                        <span className="fas fa-question-circle" />
                    </button>
                    <button
                        className={'rightActions-show'}
                        onClick={() => this.setState({ openSidebarModal: true })}>
                        <span className="fas fa-arrow-left" />
                    </button>
                </>
                }
                {this.renderSidebarSchedules()}
            </>
        )
    }

    renderSidebarSchedules () {
        const t = translate
        return (
            <>
                <SidebarModal
                    isOpen={this.state.openSidebarModal}
                    onCloseModal={() => this.setState({ openSidebarModal: false })}>

                    <VsfButtonNew
                        onClick={async () => {
                            await this.setState({ openSidebarModal: false })
                            this.propsUtil.changeUrlRequest({
                                "rightModal": 'modalorderls',
                                'rightModalTab': 'order',
                                "orderInitial": null
                            })
                        }}
                        label={t('Crear pedido')}
                        showButton={this.creatable['order'] || false}
                        className={'btn btn-block btn--red btn--max '}
                        icon={'far fa-plus-square mr-1'}
                    />

                    {OFFER.ordenarTecnicos &&
                  <VsfButtonNew
                      onClick={async () => {
                          await this.setState({ openSidebarModal: false })
                          this.propsUtil.changeUrl('/schedule/bytechnical/orderBy')
                      }}
                      label={t('Ordenar técnicos')}
                      showButton={this.creatable['order'] || false}
                      className={'btn btn-block btn--red btn--max '}
                  />
                    }
                </SidebarModal>

                <SidebarModal
                    isOpen={this.state.openSidebarHelper}
                    onCloseModal={() => this.setState({ openSidebarHelper: false })}>
                    <div className={'sidebar-modals'}>
                        <span className={'title'}>{t('Disponibilidad del técnico')}</span>
                        <p
                            className={'comments'}>{t('Color de relleno de la fila del calendario que corresponde al técnico')}</p>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div className="c-tooltip__content-item-color vacaciones" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Vacaciones')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div
                                    className="c-tooltip__content-item-color guardia" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Guardia')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div
                                    className="c-tooltip__content-item-color baja" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Baja')}</p>
                        </div>
                    </div>


                    <div className={'sidebar-modals'}>
                        <span className={'title'}>{t('Conectividad del técnico')}</span>
                        <p
                            className={'comments'}>{t('Icono previo al nombre del técnico, solo se muestra el estado de conectividad si el técnico ha iniciado la jornada')}</p>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <i className={'fas fa-wifi'}
                                    style={{ color: 'green', }} />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Conexión con wifi')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <i className={'fas fa-signal'}
                                    style={{ color: 'blue', }} />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Conexión con datos móviles')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <i className={'fas fa-user-slash'}
                                    style={{ color: 'red', }} />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Offline')}</p>
                        </div>
                    </div>

                    <div className={'sidebar-modals'}>
                        <span className={'title'}>{t('Estado de la visita')}</span>
                        <p
                            className={'comments'}>{t('Color del marco del slot')}</p>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div
                                    className="c-tooltip__content-item-bordercolor finished" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Visita finalizada')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div
                                    className="c-tooltip__content-item-bordercolor proccess" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Visita en proceso')}</p>
                        </div>
                        <div className="c-tooltip__content-item">
                            <div className="c-tooltip__content-item-state"
                                aria-labelledby="state-events">
                                <div
                                    className="c-tooltip__content-item-bordercolor selected" />
                            </div>
                            <p id="state-events"
                                className="c-tooltip__content-item-text">{t('Visita seleccionada')}</p>
                        </div>
                    </div>
                    <div className={'sidebar-modals'}>
                        <span className={'title'}>{t('Tipo de wo que contiene la visita')}</span>
                        <p
                            className={'comments'}>{t('Color de relleno del slot, si el relleno contiene un rayado diagonal indica un técnico de apoyo')}</p>
                        <Accordion activeKey={this.state.activeKey.toString()}>
                            {Object.entries(this.typifiedList).map(([origin, arrayTypifieds], index) => (
                                <Card key={'origin' + origin + index}>
                                    <Accordion.Toggle as={Card.Header} eventKey={index.toString()}
                                        onClick={() => this.handleActiveKeyChange(index)}>
                                        <div className={'toggle-header'}>
                                            {origin}
                                            <i className={this.state.activeKey === index ? 'fa fa-angle-up'
                                                : 'fa fa-angle-down'} />
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={index.toString()}>
                                        <>
                                            {arrayTypifieds.map(typified => (
                                                <div key={typified.id} className="c-tooltip__content-item">
                                                    <div className="c-tooltip__content-item-state"
                                                        aria-labelledby="state-prevent">
                                                        <div className="c-tooltip__content-item-color "
                                                            style={{ backgroundColor: typified.color }} />
                                                    </div>
                                                    <p id="state-prevent"
                                                        className="c-tooltip__content-item-text">{appState.typifiedState.getValue(typified.id)}</p>
                                                </div>
                                            ))}

                                        </>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </div>

                </SidebarModal>
            </>

        )
    }
}

export default SidebarModals