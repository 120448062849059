import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { SortableContainer } from 'react-sortable-hoc'
import AppLogger from '../util/AppLogger'
import SortableItem from './SortableItem'

@observer
class SortableList extends Component<props> {

  render () {
    let items = this.props.items
    //this.log({ SortableList: 1, items, props: this.props });

    if (this.props.tableList) {
      return (
        <tbody className="border-end">
        {items?.map((row, index) => (
          <SortableItem key={`item-${index}`} tableList={this.props.tableList}
                        onClickSortableItem={() => {
                          if (this.props.onClickSortableItem != null) {
                            this.props.onClickSortableItem(row)
                          }
                        }}
                        renderItem={(e, i, component) => this.props.renderItem(e, i, component)}
                        index={index} row={row}
                        useDragHandle={this.props.useDragHandle}
                        showDragHandle={this.props.showDragHandle}
                        index2={index}
          />
        ))}
        </tbody>
      )
    } else if (this.props.axisClass) {
      return (
        <ul className={'checkbox-list axis'}>
          {items?.map((row, index) => (
            <SortableItem key={`item-${index}`}
                          onClickSortableItem={() => {
                            if (this.props.onClickSortableItem != null) {
                              this.props.onClickSortableItem(row)
                            }
                          }}
                          tableList={this.props.tableList}
                          classGroup={this.props.classGroup}
                          useDragHandle={this.props.useDragHandle}
                          showDragHandle={this.props.showDragHandle}
                          renderItem={(e, i) => this.props.renderItem(e, i)}
                          index={index} index2={index} row={row} />
          ))}
        </ul>
      )
    } else {
      return (
        <ul className={'checkbox-list'}>
          {items?.map((row, index) => (
            <SortableItem key={`item-${index}`} tableList={this.props.tableList}
                          onClickSortableItem={() => {
                            if (this.props.onClickSortableItem != null) {
                              this.props.onClickSortableItem(row)
                            }
                          }}
                          classGroup={this.props.classGroup}
                          useDragHandle={this.props.useDragHandle}
                          showDragHandle={this.props.showDragHandle}
                          renderItem={(e, i) => this.props.renderItem(e, i)}
                          index={index} index2={index} row={row} />
          ))}
        </ul>
      )
    }
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default SortableContainer(SortableList,)

