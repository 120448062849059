import BaseModelState from "./BaseModelState";
import { computed, observable } from "mobx";
import ConfigModel from "../models/ConfigModel";
import util from '../util/Util';
import BudgetModel from "../models/BudgetModel";
import FormModel from "../models/FormModel";
import TypifiedValueModel from "../models/TypifiedValueModel";
import appState from "../state/AppState";

class ConfigState extends BaseModelState {

    @observable configurationsDict = {};
    @observable equipmentNotifDict = {};
    @observable dataNotifDict = {};
    @observable forms = [];

    async loadForms() {
        if (this.forms.length === 0) {
            let formModel = new FormModel();
            let formsLoaded = await formModel.find();
            this.forms = formsLoaded;
        }
    }
    getOptionFromCustomMobileFlavorQuality(paramsToMatchOptionFlavorQuality) {
        let result = this.getOptionsFromConfig(paramsToMatchOptionFlavorQuality);
        return result;
    }
    @observable configurationsFlavorQualityDict = {};

    getOptionsFromConfig(paramsToMatchOptionFlavorQuality) {
        let result;
        let matching = false;
        if (util.hasValue(this.configurationsFlavorQualityDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY]?.value)) {
            for (let option of JSON.parse(this.configurationsFlavorQualityDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY]?.value).options) {
                if (util.hasValue(option.equipmentFamilyType) && paramsToMatchOptionFlavorQuality?.sapNotifType === option.sapNotifType) {
                    matching = true;
                }
                if (matching && (util.hasValue(option.equipmentFamilyType) && paramsToMatchOptionFlavorQuality?.equipmentFamilyType === option.equipmentFamilyType)) {
                    matching = true;
                }
                if (!matching && (util.hasValue(option.workOrderType) &&
                    appState.typifiedState.getObject(TypifiedValueModel.CODE_WORK_ORDER_TYPE, paramsToMatchOptionFlavorQuality.workOrderType).code === option.workOrderType)) {
                    matching = true;
                }
                if (matching) {
                    result = option;
                    break;
                }
            }
        }
        return result;

    }
    @computed get labelValuesForSelect() {
        let result = [];
        for (let item of this.forms) {
            result.push({ value: item.id, label: item.title });
        }
        return result;
    }

    @computed get notifTypeForSelect() {
        let result = [];
        if (this.dataNotifDict) {
            for (let notifType of Object.keys(this.dataNotifDict)) {
                result.push({ value: notifType, label: notifType })
            }
        }
        return result;
    }

    async configGetDict() {
        if (Object.keys(this.configurationsDict).length === 0) {
            let configModel = new ConfigModel();
            let result = await configModel.find();
            if (result.length > 0) {
                this.configurationsDict = util.getDictMultipleFromArray(result, "code");
            }
        }
    }

    configGetDictQuery() {
        let configModel = new ConfigModel();
        configModel.filters = [
            { "fieldName": "code", "fieldValue": "START_BREAK_FOOD,END_BREAK_FOOD", "filterOperator": "STRIN" },
        ];
        return configModel
    }

    getDictCustomMobile() {
        this.getDictDataOptions();
        this.getDictEquipmentOptions();
    }

    async loadConfigCustomData() {
        let configQuery = new ConfigModel();
        configQuery.code = ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS;
        let configs = await configQuery.findFirst();
        if (util.hasValue(configs?.value)) {
            this.configurationsDict[ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS] = configs;
        }
    }

    getDictEquipmentOptions() {
        let result = {};
        if (this.configurationsDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY]?.value &&
            JSON.parse(this.configurationsDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY].value).options?.length > 0) {
            for (let obj of JSON.parse(this.configurationsDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY].value).options) {
                if (result[obj.sapNotifType + obj.equipmentFamilyType] == null) {
                    result[obj.sapNotifType + obj.equipmentFamilyType] = obj;
                }
            }
        }
        // this.log({
        //     configurationsDataCUSTOM_MOBILE_FLAVOR_QUALITY: 3,
        //     json: this.configurationsDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY],
        //     result
        // });
        this.equipmentNotifDict = result;
    }

    getDictDataOptions() {
        let result = {};
        if (this.configurationsDict[ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS]?.value &&
            JSON.parse(this.configurationsDict[ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS].value).options?.length > 0) {
            for (let obj of JSON.parse(this.configurationsDict[ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS].value).options) {
                if (result[obj.sapNotifType] == null) {
                    result[obj.sapNotifType] = obj;
                }
            }
        }
        // this.log({
        //     configurationsDataCUSTOM_MOBILE_TASK_DIAGNOSIS: 3,
        //     json: this.configurationsDict[ConfigModel.CUSTOM_MOBILE_TASK_DIAGNOSIS],
        //     result
        // });
        this.dataNotifDict = result;
    }

    getCodeProblemForSelect(notifType) {
        let result = [];
        if (this.dataNotifDict[notifType]) {
            let array = [...this.dataNotifDict[notifType].diagnosisInfo, ...this.dataNotifDict[notifType].changeReason];
            for (let notifType of array) {
                if (util.hasValue(notifType.parentId)) {
                    result.push({ value: notifType.tagCode, label: notifType.tagCode })
                }
            }
        }
        return result;
    }


    validateImage(tableName) {
        this.log({ validateImage: 1, tableName, config: this.configurationsDict });
        let result = false;
        if (this.configurationsDict["VALIDATE_" + tableName.toUpperCase() + "_IMAGE"]?.value === "1") {
            result = true;
        }
        return result;
    }

    validateStockControl(tableName) {
        let result = false;
        // this.log({ validateImage: 1, tableName, config: this.configurationsDict });
        if (this.configurationsDict["UPDATE_STOCK_IN_NUDDO_FROM_WORK"]?.value === "1") {
            result = true;
        }
        return result;
    }

    validateGeolocation(tableName) {
        let result = false;
        // this.log({ validateImage: 1, tableName, config: this.configurationsDict });
        if (this.configurationsDict["VALIDATE_" + tableName.toUpperCase() + "_GEOLOCATION"]?.value === "1") {
            result = true;
        }
        return result;
    }

    validateForm(tableName) {
        let result = false;
        // this.log({ validateForm: 1, tableName, config: this.configurationsDict });
        if (this.configurationsDict["VALIDATE_" + tableName.toUpperCase() + "_FORM"]?.value === "1") {
            result = true;
        }
        return result;
    }

    validateOrderOriginMandatory(tableName) {
        let result = false;
        if (this.configurationsDict["ORDER_ORIGIN_MANDATORY"]?.value === "1") {
            result = true;
        }
        result = true;
        return result;
    }

    /**
     * Si hay que tener en cuenta que integramos con ERP devuelve true
     * @param status
     * @returns {boolean}
     */
    validateStatusBudget(status) {
        let result = false;
        if (this.configurationsDict["BUDGET_WITH_ERP"]?.value === "1") {
            result = true;
        }
        if (status !== BudgetModel.CODE_BORRADOR) {

        }
        return result;
    }

    /**
     * Bloque de .CUSTOM_MOBILE_FLAVOR_QUALITY
     * @param notifType
     * @returns {boolean}
     */
    checkSapNotifTypeEquipmentTypeExist(notifEquipmentType) {
        return util.hasValue(this.equipmentNotifDict[notifEquipmentType])
    }

    getQualityInfo(notifEquipmentType) {
        return this.equipmentNotifDict[notifEquipmentType]?.qualityInfo;
    }

    getProductInfo(notifEquipmentType) {
        return this.equipmentNotifDict[notifEquipmentType]?.productInfo;
    }

    getOptionsSelectEquipmentNotifTipe() {
        return JSON.parse(this.configurationsDict[ConfigModel.CUSTOM_MOBILE_FLAVOR_QUALITY].value).selectOptions;
    }

    /**
     * Bloque de CUSTOM_MOBILE_TASK_DIAGNOSIS
     * @param notifType
     * @returns {boolean}
     */
    checkDataSapNotifTypeExist(notifType) {
        return util.hasValue(this.dataNotifDict[notifType])
    }

    getDiagnosisInfo(notifType) {
        return this.dataNotifDict[notifType]?.diagnosisInfo;
    }

    getPauseInfo(notifType) {
        return this.dataNotifDict[notifType]?.pauseInfo;
    }

    getTasksInfo(notifType) {
        return this.dataNotifDict[notifType]?.tasksInfo;
    }

    getChangeReasonInfo(notifType) {
        return this.dataNotifDict[notifType]?.changeReason;
    }

    checkOrderCocaCola(order) {
        let result = false;
        let notifType = "";
        if (order?.dynamicField) {
            let camposDinamicos = JSON.parse(order?.dynamicField);
            notifType = camposDinamicos.sapNotifType?.toUpperCase();
            if (this.checkDataSapNotifTypeExist(notifType)) {
                result = true;
            }
        }
        // if (order?.origin === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_ORIGIN, "CCEP").id) {
        //     result = true;
        // }
        if (order?.originModel?.name === "CCEP") {
            result = true;
        }
        return result;
    }

    tableTasksOrder(notifType) {
        let tabla = {
            "FD": true
        };

        return tabla[notifType]
    }


}

export default ConfigState;
