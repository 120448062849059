import React from 'react';
import ContactModel from "../../models/ContactModel";
import appState from '../../state/AppState';
import {observer} from "mobx-react";
import TabsComponent from "../../components/fields/TabsComponent";
import ClientContact from "./ClientContact";
import PageTitle from "../../components/PageTitle";
import ClientModel from "../../models/ClientModel";
import translate from "../../translator/translate";
import GroupOfField from "../../components/layout/GroupOfField";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {computed} from 'mobx';
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import VsfButtonNew from "../../components/VsfButtonNew";
import BaseEditableTable from "../base/BaseEditableTable";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import MoreButtons from "../../components/MoreButtons";
import ClientActions from "./ClientActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import OFFER from "../../config/OFFER";

@observer
class ClientPosContacts extends BaseEditableTable {

    static defaultProps = {
        clientId: null,
    }

    static propTypes = {
        //Acción de descarga de Excel en el listado
        clientId: PropTypes.string,
    }
    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield;

    constructor(props) {
        super(props);
        this.nameMainType = "client";
        this.initializeGraphData(this.nameMainType);
        this.foreingKeyfield = "clientId";
        this.state = {
            loading: true
        }
    }

    @computed get mobxListado() {
        return appState.contactState.contacts;
    }

    setMobxListado(newValue) {
        appState.contactState.contacts = newValue;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }
orderMode() {
        return "ASC"
}

    async reloadTable() {
        await appState.contactState.reloadContactsFromClientId(this.graphDataMainType.id);
    }

    getModelQuery() {
        return new ClientModel();
    }

    getModelTable() {
        return new ContactModel();
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }


    renderImpl() {
        const t = translate;
        let client = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()} mode={"edit"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <ClientActions getModelQuery={() => this.getModelQuery()} fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={client} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id || this.props.clientId}
                                tabs={this.tabsData.getDataPosClient()}
                                active="Contactos"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}>
                                <ClientActions getModelQuery={() => this.getModelQuery()} fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={client} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className={'card mb-3'}>
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataPosClient()}
                                active="Contactos"
                                fromRightModal={this.props.fromRightModal}
                            />
                            }
                            <GroupOfField
                                title={t('Datos Contactos')}
                                icon="fas fa-id-card"
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <p className="contacts-num">
                                    <span>{t('Para seleccionar que contacto que va a recibir los partes de trabajo, marque la opción <<envío>>')}</span>
                                </p>
                                <div className=" align-items-center">
                                    <div className="text-left mt-2 mb-1">

                                        <p className="contacts-num">
                                            <span>{this.mobxListado.length}</span> {this.mobxListado.length === 1 ? t("Contacto Añadido ") : t('Contactos Añadidos')}
                                        </p>
                                    </div>
                                </div>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length === 0 ?
                                            <tr>
                                                <td colSpan="8"
                                                    className="title-cell">{t('No hay ningun contacto creado')}
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <th scope="col">{t('Nombre y Apellido')}</th>
                                                <th  scope="col">{t('Teléfono')}</th>
                                                <th scope="col">{t('Email')}</th>
                                                <th scope="col">{t('Cargo')}</th>
                                                {OFFER.mail_sales &&
                                                <th scope="col">{t('Envío')}</th>
                                                }
                                                <th scope="col">&nbsp;</th>
                                            </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">

                                        {this.mobxListado.map((contacto, index) => (
                                            <ClientContact
                                                key={contacto.id}
                                                clientId={this.props.clientId}
                                                rowIndex={index}
                                                row={contacto}
                                                reload={(e) => this.reloadData(e)}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            />
                                        ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className={this.props.fromRightModal ? "fixed-bottom mb-4" : " "}>
                                    <div className="text-right">
                                        <VsfButtonNew
                                            disabled={this.state.hasSomeRowEditing}
                                            showButton={this.creatable["contact"] || false}
                                            label={t("Crear nuevo contacto")}
                                            onClick={_ => this.onOpenRowNewEmpty()}
                                            className={"btn btn--loader"}
                                        />
                                    </div>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        )
    }

}

export default withRouter(ClientPosContacts);
