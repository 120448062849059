import { observable } from 'mobx'
import BaseModelState from './BaseModelState'
import util from '../util/Util'
import UserModel from '../models/UserModel'
import appState from './AppState'
import UserMeModel from '../models/UserMeModel'

class UserState extends BaseModelState {
    @observable users = [];
    @observable  userMe = {};

    async getUserById(userId) {
        let userQuery = new UserModel();
        userQuery.addRelatedTable("photoProfile");
        let result = {};
        if (util.hasValue(userId)) {
            result = await userQuery.findById(userId);
        }
        return result?.toPlainObject();
    }

    hasUserActionsEndDay() {
        let token = appState.loginState.getUserTokenFromDecode();
        let user = appState.loginState.user;
        let result = false;
        if (util.perteneceA(user.actions,UserModel.ACTIONS_END_DAY) || util.perteneceListaComa("SUPERADMIN", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleDistpacherOrSuperAdmin() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("DISPATCHER", token?.UserRole)|| util.perteneceListaComa("SUPERADMIN", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleDispatcherOrMore() {
        return (this.isUserRoleDistpacherOrSuperAdmin() ||this.isUserRoleAdmin())
    }

    isUserRoleAdminOrSuperAdmin() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("ADMIN", token?.UserRole)|| util.perteneceListaComa("SUPERADMIN", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleAdmin() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("ADMIN", token?.UserRole)) {
            result = true
        }
        return result
    }

    /**
     * TODO Hablar con Jose
     * @returns {boolean}
     */
    isUserRoleWarehouseManagerOrSuperAdmin() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (this.isUserRoleWarehouseManager() || util.perteneceListaComa("SUPERADMIN", token?.UserRole)) {
            result = true
        }
        let userMe = new UserMeModel();
        userMe.hidrate(appState.userState.userMe);
        if (userMe.isCustomizedWithWmsAccess()) {
            result = true
        }
        return result
    }

    isUserRoleTech() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("TECHNICIAN", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleWarehouseManager() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("WAREHOUSE_MANAGER", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleWarehouseOperator() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("WAREHOUSE_OPERATOR", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserOnlyWarehouseManager() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if ("WAREHOUSE_MANAGER" === token?.UserRole) {
            result = true
        }
        return result
    }


    isUserRoleClient() {
        let token = appState.loginState.getUserTokenFromDecode();
        let result = false;
        if (util.perteneceListaComa("CLIENT", token?.UserRole)) {
            result = true
        }
        return result
    }

    isUserRoleSubcontract() {
        let user = appState.loginState.user;
        let result = false;
        if (util.hasValue(user?.subcontractId)) {
            result = true
        }
        return result
    }

}

export default UserState;
