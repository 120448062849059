import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import AppLogger from "../util/AppLogger";
import VsfHeaderWebLinkStylesheet from "../components/VsfHeaderWebLinkStylesheet";

@observer
class AppWrapperNotAuth extends Component {

    constructor(props) {
        super(props);
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        return (
            <>
                <VsfHeaderWebLinkStylesheet />
                {this.props.children}
            </>
        );
    }


}

//withRouter es necesario para que AppWrapper pueda estar dentro de <Router> siendo @observable
export default withRouter(AppWrapperNotAuth);
