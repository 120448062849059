import AbstractModel from "./AbstractModel";


export default class ChangesWithValidationsModel extends AbstractModel {

    static APPROVED = "APPROVED";
    static REJECTED = "REJECTED";
    static PENDING = "PENDING";

    id;
    modelName;
    modelId;
    changes;
    label;
    approved;


    nameMainType = "changesWithValidation";
    graphFindByIdOperation = "changesWithValidation";
    graphFindOperation = "changesWithValidationsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "modelName": "",
            "modelId": "",
            "label": "",
            "changes": "",
            "approved": "",
        };

        return result;
    }


};





















