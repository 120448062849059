import React from "react";
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import GroupOfField from "../../components/layout/GroupOfField";
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import BaseEditableTable from "../base/BaseEditableTable";
import {computed, observable} from "mobx";
import InfoBlock from "../../components/InfoBlock";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import translate from "../../translator/translate";
import ArticleFormLocationRow from "./ArticleFormLocationRow";
import MoreButtons from "../../components/MoreButtons";
import ArticleModel from "../../models/ArticleModel";
import StockModel from "../../models/StockModel";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import ArticleActions from "./ArticleActions";

@observer
class ArticleFormLocationTable extends BaseEditableTable {

    @observable stocks = [];

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "article";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.loadTable();
        this.setState({ loading: false })
    }

    async loadTable() {
        let stockQuery = new StockModel();
        stockQuery.articleCode = this.graphDataMainType.code;
        stockQuery.filters = [{ fieldName: 'quantity', fieldValue: "0", filterOperator: 'NEQ' }]
        let stocks = await stockQuery.findPlainObject();
        this.stocks = stocks;
    }

    @computed get mobxListado() {
        return this.stocks;
    }

    setMobxListado(newValue) {
        this.stocks = newValue;
    }

    getModelTable() {
        return new ArticleModel();
    }

    getModelQuery() {
        return new ArticleModel();
    }

    renderImpl() {

        const t = translate;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);

        return (

            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()} mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            />
                            <TabsComponent
                                id={this.getFormId() || this.props.articleId}
                                tabs={this.tabsData.getDataArticles()}
                                active="Ubicación"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        > <MoreButtons
                            onReloadData={() => this.reloadData()}
                            showButton={this.creatable[this.getModelQuery().nameMainType] || false}
                        >
                            <ArticleActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}
                                            model={this.graphDataMainType}
                                            getModelQuery={() => this.getModelQuery()} />
                        </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataArticles()}
                                num={1}
                                active={"Ubicación"}
                            />
                            }

                            <GroupOfField
                                title={t('Ubicación')}
                                subtitle={t('Repuestos')}
                                icon='fas fa-calendar'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna pieza creada')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <th scope="col">{t("Almacén")}</th>
                                            <th scope="col">{t("Referencia Articulo")}</th>
                                            <th scope="col">{t("Lote|Num Serie")}</th>
                                            <th scope="col">{t("Cantidad")}</th>
                                            <th scope="col">{t("Tipo")}</th>


                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">

                                        {this.mobxListado.map((stock, index) => (
                                            <ArticleFormLocationRow
                                                row={stock}
                                            />
                                        ))}
                                        </tbody>
                                    </table>

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>

                    </div>

                </div>

            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(ArticleFormLocationTable)
