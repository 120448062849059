import React from 'react'
import appState from '../state/AppState'
import TypifiedValueModel from '../models/TypifiedValueModel'
import { observer } from 'mobx-react'
import util from '../util/Util'
import { observable } from 'mobx'
import BaseWorkLogs from './BaseWorkLogs'
import OrderModel from '../models/OrderModel'
import CheckboxUniqueComponent from './fields/CheckboxUniqueComponent'
import TextAreaComponent from './fields/TextAreaComponent'
import translate from '../translator/translate'

@observer
class SelectDiagnostic extends BaseWorkLogs {


    constructor(props) {
        super(props);
        this.state = {
            copiaDatos: [],
            tasksSelected: [],
            objOfActions: [],
            text: '', item: {},
        }
    }

    @observable diagnosticName = '';

    async componentDidMount() {
        super.componentDidMount();
        let slot = this.props.slot;
        let orderModel = new OrderModel();
        orderModel.addRelatedTable("originModel");
        orderModel.hidrate(slot.order);
        let data = orderModel.getArrWithType([TypifiedValueModel.CODE_DIAGNOSIS]);
        if (this.props.objDict?.["0"] != null) {
            this.setState({
                text: this.props.objDict["0"].comment
            })
        }
        let objOfActionsDict = this.ordenarByParentId(data);
        let objOfActions = this.getArrayObj(objOfActionsDict);
        let copiaDatos = this.getArrayObj(objOfActionsDict);
        this.setState({ objOfActions, copiaDatos });
    }


    ordenarByParentId(array) {
        let result = {};
        for (let diagnosis of array) {
            let key = diagnosis.id || diagnosis.tagCode;
            if (util.hasValue(diagnosis.parentId) && diagnosis.parentId !== util.getZeroIdIdentifierGUID()) {
                key = diagnosis.parentId;
            }
            if (result[key] == null) {
                result[key] = [];
            }
            let item = {
                code: diagnosis.code || "",
                value: key === diagnosis.tagCode ? (diagnosis.tagCode + " " + diagnosis.text) : (diagnosis.text || appState.typifiedState.getValue(diagnosis.id)),
                parentId: key,
                id: diagnosis.tagCode || diagnosis.id
            };
            result[key].push(item);
        }
        this.log({ ordenarByParentId: 1, result });
        return result;
    }

    getArrayObj(obj) {
        let copia = {};
        let result = [];
        for (let key of Object.keys(obj)) {
            for (let item of obj[key]) {
                if (copia[item.value] == null) {
                    copia[item.value] = "";
                    result.push(item)
                }
            }
        }
        let arrayOrdenado = result.sort(function (a, b) {
            return a.code - b.code
        })
        return arrayOrdenado;
    }

    onClickMultiples(item) {
        if (this.props.objDict[item.id] != null) {
            delete this.props.objDict[item.id];
        } else {
            this.props.objDict[item.id] = item;
        }
    }

    onClickOneResult(item) {
        this.props.objDict = {};
        this.props.objDict[item.id] = item;
        this.diagnosticName = item.value;
    }

    onClick(item) {
        if (this.checkOrderCocaCola()) {
            this.onClickOneResult(item)
        } else {
            this.onClickMultiples(item)
        }
        if (this.checkOrderCocaCola()) {
            this.setState({ modalVisible: true })
        }
        this.props.onChangeObjDict(this.props.objDict)
    }

    searchCampoQ(q) {
        this.state.q = q;
        this.setState({ ...this.state.q });
        if (!util.hasValue(q)) {
            this.setState({ objOfActions: this.state.copiaDatos });
        } else {
            let objOfActions = [];
            this.state.copiaDatos.map(data => {
                let regExp = new RegExp(q, 'gi');
                if (regExp.test(data.value)) {
                    objOfActions.push(data)
                }
            });
            this.setState({ objOfActions });
        }
    }


    getComentarios() {
        const t = translate;
        return (
            <>
                <p>{t('Comentarios del diagnóstico')}</p>
                <TextAreaComponent
                    onChange={(e) => this.onChangeComment(e)}
                    value={this.state.text}
                    name={"text"}
                    placeholder={t("Comentarios del diagnóstico")}
                    classGroup={""}
                    type={"text"}
                />
            </>
        )
    }

    render() {
        const t = translate;
        this.log({ render: 1, obj: this.props.objDict })
        return (
          <div className={'col-12'}>
          {this.getComentarios()}
                <p>{t('Selecciona los diagnósticos')}</p>
                <div className="row">
                    {this.state.objOfActions?.map((item, index) => (
                        <div className={"col-4"} key={index}>
                            <div className={"checkbox2"}>
                                <CheckboxUniqueComponent
                                    checked={this.props.objDict?.[item.id]}
                                    onChange={(e) => this.onClick(item)}
                                    name={item.id}
                                    value={this.props.objDict?.[item.id]}
                                    className={""}
                                />
                                <label htmlFor={item.id}>
                                    {item.code} - {item.value}
                                </label>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

}


export default SelectDiagnostic;
