import React from 'react';
import {observer} from 'mobx-react';
import BaseCommon from "./BaseCommon";
import GraphException from "../../network/GraphException";
import appState from "../../state/AppState";
import {computed, observable, toJS} from 'mobx';
import util from '../../util/Util';
import Links from "../../util/Links";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import ChangesWithValidationsModel from "../../models/ChangesWithValidationsModel";
import YesOrNoEnum from "../../models/enum/YesOrNoEnum";

@observer
class BaseForm extends BaseCommon {
    changeFormOrder = false;
    nameMainType = "";
    @observable changes = [];
    controller = null;
    signal = null;
    /**
     * Al cargar la información de base de datos la guardo aquí. De esa forma podré saber qué elementos mandar nuevos (solo los modificados)
     */
    modelPlainFirstLoaded = {};
    @observable gqlErrors = [];
    @observable gqlWarnings = [];

    constructor(props) {
        super(props);
        this.queryFieldWithId = null;
        this.propsUtil = new PropsUtil(this.props);
    }

    @computed get graphDataMainType() {
        return this.graphData.data[this.nameMainType];
    }

    @computed get graphStateObj() {
        let stateObj = appState[this.nameMainType + "State"];
        if (stateObj == null) {
            throw "graphStateObj() The Form must has a State of the form appState[\"" + this.nameMainType + "State\"]";
        }
        return stateObj;
    }

    @computed get graphData() {
        return this.graphStateObj.graphData;
    }

    @computed get graphStatus() {
        return this.graphStateObj.graphStatus;
    }

    async componentDidMount() {
        await this.componentDidMountImpl();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadForm();
    }

    async reloadData() {
        await this.reloadForm();
    }


    startCounts(model) {
        if (this.nameMainType === "order") {
            appState.assetState.getCountSlotsFromOrder(model);
            appState.assetState.countWorkOrdersNotAssigned(model);
            appState.assetState.getCountArticlesFromOrder(model);
            appState.assetState.orderBudgets = model.budget?.length !== 0 ? model.budget?.length : "0";
            appState.assetState.orderDocuments = model.documents?.length !== 0 ? model.documents?.length : "0";
        } else if (this.nameMainType === "workOrder") {
            appState.assetState.getCountSlotsFromWorkOrder(model);
            appState.assetState.getCountArticlesFromWorkOrder(model);
            appState.assetState.workOrderBudgets = model.budget?.length !== 0 ? model.budget?.length : "0";
            appState.assetState.workOrderDocuments = model.documents?.length !== 0 ? model.documents?.length : "0";
        } else if (this.nameMainType === "asset") {
            appState.assetState.assetDocuments = model.documents?.length !== 0 ? model.documents?.length : "0";
        }
    }

    initializeGraphData(nameMainType) {
        let id = this.getFormId();
        if (!util.hasValue(this.graphData.data[nameMainType]) || this.graphData.data[nameMainType].id !== id) {
            this.graphData.data[nameMainType] = {};
        }
    }

    /**
     * Metodo que se activa al seleccionar una opcion del autosuggest
     * @param fieldName
     * @param suggestSelected
     */
    handleAutosuggestSelection(fieldName, suggestSelected) {
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(fieldName, suggestSelected)
        // this.log({name:fieldName,value:suggestSelected})
    };

    updateInputEvent(event, op) {
        this.changeFormOrder = true;
        appState.layoutState.formWithoutChanges = false;
        this.updateInput(event.target.name, event.target.value);
        // this.log({name:event.target.name,value:event.target.value})
    }

    updateInputEventYesOrNoEnum(event) {
        let value = event.target.value;
        let name = event.target.name;
        this.log({name,value})
        if (value === YesOrNoEnum.YES) {
            value = YesOrNoEnum.NO
        } else {
            value = YesOrNoEnum.YES
        }
        let eventObj = {
            target: {
                value,
                name
            }
        }
        this.updateInputEvent(eventObj);
    }

    updateInputEventDynamicFields(name, value, group) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        let dynamicField = {};
        if (modelFromState.dynamicField) {
            dynamicField = JSON.parse(modelFromState.dynamicField);
        }
        if (util.hasValue(name)) {
            if (name === 'sapCodeProblem') {
                dynamicField['sapCodeProblem'] = value;
                dynamicField['sapCodeGroupProblem'] = value.split('-')[0];
            } else {
                dynamicField[name] = value;
            }
        }
        if (group != null) {
            if (dynamicField["groups"] != null) {
                if (!dynamicField["groups"].includes(group.code)) {
                    dynamicField["groups"].push(group.code);
                }
            } else {
                dynamicField["groups"] = [];
                dynamicField["groups"].push(group.code);
            }
        }
        // this.log({ updateInputEventDynamicFields: 1, name, value, dynamicField, group, modelFromState });
        modelFromState["dynamicField"] = JSON.stringify(dynamicField);
        appState.layoutState.formWithoutChanges = false;


    }

    deleteInputEventDynamicFields(group) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        let dynamicField = {};
        if (modelFromState.dynamicField) {
            dynamicField = JSON.parse(modelFromState.dynamicField);
        }
        for (let field of group.dynamicField) {
            delete dynamicField[field.code]
        }
        if (dynamicField["groups"] != null) {
            let index = dynamicField["groups"].indexOf(group.code);
            if (index != -1) {
                dynamicField["groups"].splice(index, 1);
            }
        }
        if (dynamicField["groups"].length == 0) {
            delete dynamicField["groups"]
        }
        modelFromState["dynamicField"] = JSON.stringify(dynamicField);
        appState.layoutState.formWithoutChanges = false;

    }

    updateInput(key, value) {
        if (this.previousRowForDiscard == null) {
            this.previousRowForDiscard = toJS(this.graphDataMainType);
        }
        let modelFromState = this.graphDataMainType;
        modelFromState[key] = value;
        appState.layoutState.formWithoutChanges = false;
    }


    onCloseModal() {
        if (!appState.layoutState.formWithoutChanges && !appState.layoutState.loadingForm) {
            appState.layoutState.modalChangeOpen = true;
        } else {
            this.propsUtil.changeUrlRequest({
                fromRightModal: null,
                rightModal: null,
                articleId: null,
                rightModalTab: null,
                workOrderId: null,
                orderId: null,
                clientId: null,
                userId: null,
                assetId: null,
                scheduleVisitId: null,
                slotId: null,
                budgetId: null,
            });

        }
    }

    getGraphErrorsFromStatus() {
        let result = new GraphException().getGraphErrorsFromStatus(this.graphStatus.mutationGraphQlResponse);
        let client = this.graphDataMainType;
        if (client == null) {
            if (result[""] == null) {
                result[""] = [];
            }
            let error = {message: "Data error"};
            result[""].push(error);
        }
        return result;
    }

    getModelQueryForView() {
        return this.getModelQuery();
    }

    getModelQuery() {
        return null;
    }

    async reloadForm() {
        let id = this.getFormId();
        this.graphStatus.formLoading = true;
        appState.loadingBarState.start();
        this.graphStatus.formError = false;
        this.graphStatus.mutationGraphQlResponse.errors = [];
        try {
            let modelQuery = this.getModelQueryForView();
            if (modelQuery != null) {
                let model;
                if (!util.hasValue(id)) {
                    model = this.getModelQueryForView();
                } else {
                    model = await modelQuery.findByIdNotNull(id);
                }
                appState.pageNoData = (!util.hasValue(model.id) && util.hasValue(id));
                this.modelPlainFirstLoaded = model.setAndGetInitialState();
                let modelPlain = Object.assign({}, model.toPlainObject())
                if (this.props.listOrderForm && !util.hasValue(this.props.order.id)) {
                    this.graphData.data[this.nameMainType] = this.props.order;
                } else {
                    this.graphData.data[this.nameMainType] = modelPlain;
                }
                await this.didReloadFormData(model);
                this.editable = util.hasValue(model.editable) ? model.editable : (this.creatable[this.getModelQuery().nameMainType] || false);
                this.deletable = model.deletable;
                this.log({
                    reloadForm: 1,
                    graphData: toJS(this.graphData.data[this.nameMainType]),
                    modelEditable: model.editable,
                    editable: this.editable,
                    offer: this.creatable[this.getModelQuery()?.nameMainType]
                });

            } else {
                this.log({reloadFormElse: 1, EXCECPTION: 'getModelQuery is not defined'});
            }
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.graphStatus.mutationGraphQlResponse.errors = errors;
            this.log({error: 1, e});
            if (util.hasValue(id)) {
                appState.pageNoData = true;
            }
        }
        appState.layoutState.formWithoutChanges = true;
        this.graphStatus.formLoading = false;
        this.graphStatus.mutationWarning = false;
        appState.loadingBarState.finalize();
    }

    ifPathStartsWith(pathMatch) {
        let result = false;
        if (this.props.location.pathname.startsWith(pathMatch)) {
            result = true;
        }
        return result;
    }

    /**
     * Devuelve el id: String del formulario
     */
    getFormId() {
        let result;
        // Lo coje de queryString
        if (this.queryFieldWithId != null) {
            this.propsUtil = new PropsUtil(this.props);
            let identifier = this.propsUtil.getRequest(this.queryFieldWithId);
            result = identifier;
        }
        // Lo coje de props
        if (result == null) {
            result = this.props[this.queryFieldWithId];
        }
        if (result == null) {
            // Lo coge de la url /order/form/4
            let links = new Links();
            result = links.getFormId(this.props);
        } else {
            result = result + "";
        }
        return result;
    }

    async saveValidations() {
        if (Object.keys(appState.changesWithValidationsState.validateChanges).length > 0) {
            try {
                for (let validate of appState.changesWithValidationsState.validatesForm) {
                    let changeJson = JSON.parse(validate.changes)
                    if (appState.changesWithValidationsState.validateChanges[Object.keys(changeJson)[0]]?.id != null) {
                        this.log({validate})
                        if (appState.changesWithValidationsState.validateChanges[Object.keys(changeJson)[0]]?.id === validate.id) {
                            let config = new ChangesWithValidationsModel();
                            config.hidrate(appState.changesWithValidationsState.validateChanges[Object.keys(changeJson)[0]]);
                            await config.persist();
                        } else if (validate.hasChange) {
                            let config = new ChangesWithValidationsModel();
                            config.hidrate(validate);
                            config.approved = ChangesWithValidationsModel.REJECTED;
                            await config.persist();
                        }
                    }
                }
            } catch (e) {
                let gqlErrors = new GraphException().getErrorsFromException(e);
                this.log({ChangesWithValidationsModelERR: gqlErrors});
                this.gqlErrors = gqlErrors;
            }
            appState.changesWithValidationsState.validateChanges = {};
        }
    }
    async persist(model) {
        await model.persist();
    }
    async handleFormSubmit(e, isFromCC) {
        if (e) {
            e.preventDefault()
        }
        try {
            appState.loadingBarState.start();
            this.graphStatus.networkWorking = true;
            this.graphStatus.mutationError = false;
            this.graphStatus.loadedWarning = false;
            this.graphStatus.mutationLoading = true;
            appState.layoutState.loadingForm = true;
            if (this.props.fromOrderForm) {
                await this.saveOrderBeforeWorkOrder();
            }
            this.graphStatus.mutationGraphQlResponse = {
                errors: []
            };
            let modelPlain = toJS(this.graphDataMainType);
            let model = this.getModelQuery();
            model.hidrate(modelPlain);
            model.recoverInitialState(this.modelPlainFirstLoaded);
            let previousId = model.id;
            if (this.previousRowForDiscard != null) {
                await this.formWillSave(model, previousId);
                await this.persist(model);
                // El campo id y el resto de campos necesarios los actualizo
                for (let fieldName of model.getResponseFieldsFromMutation()) {
                    this.graphDataMainType[fieldName] = model[fieldName];
                }
                this.modelPlainFirstLoaded = model.setAndGetInitialState();
                await this.saveValidations(model, previousId);
                await this.formDidSave(model, previousId);
                this.previousRowForDiscard = null;
            }
            let links = new Links();
            if ((!util.hasValue(links.getFormId(this.props)) && !this.props.fromRightModal)) {
                if (this.props.fromOrderForm) {
                    this.propsUtil.changeUrl(model.orderId)
                } else if (isFromCC) {
                    this.propsUtil.changeUrlRequest({orderId: model.id})
                } else {
                    this.propsUtil.changeUrl(model.id)
                }
            }
            this.changeFormOrder = false;
            this.gqlErrors = [];
            this.gqlWarnings = []
        } catch (e) {
            let graphExceptionModel = new GraphException();
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e);
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors;
            let warnings = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).warnings;
            this.graphStatus.mutationLoading = false;
            if (errors.length > 0) {
                this.graphStatus.mutationGraphQlResponse.errors = errors;
                this.graphStatus.mutationError = true;
                this.gqlErrors = errors;
            } else {
                this.graphStatus.mutationWarning = true;
                this.gqlWarnings = warnings;
            }
            this.catchErrImpl();
            this.log({GraphException: 1, errorsAndWarnings, gqlWarnings: this.gqlWarnings});
        }
        this.graphStatus.mutationLoading = false;
        appState.loadingBarState.finalize();
        setTimeout(() => {
            this.graphStatus.networkWorking = false
        }, 1000);
        setTimeout(() => {
            this.graphStatus.mutationWarning = false;
        }, 3000);
    }


    /**
     * The formulario has been saved.
     * model has the data saved
     */
    async formDidSave(model, previousId) {

    }


    /**
     * The formulario is going to be saved (but not yet)
     * model has the data saved
     */
    async formWillSave(model, previousId) {

    }


    catchErrImpl() {

    }

    saveOrderBeforeWorkOrder() {

    }

    /**
     * El componente ya ha cargado el tipo de datos principal
     * @returns {Promise<void>}
     */
    didReloadFormData(model) {
        this.startCounts(model);
    }

    showHistory = () => {
        this.setState({
            historyVisible: !this.state.historyVisible
        });
    };

    showItemHistory = (index) => () => {
        let changes = this.state.changes;
        changes[index].visible = !changes[index].visible;
        this.setState({
            changes
        });
    };

    /**
     * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
     */
    initializeAbortController() {
        this.controller = new AbortController();
        this.signal = this.controller.signal;
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async saveWithWarnings() {
        this.graphDataMainType.saveOptions = this.getModelQuery().SAVE_WITH_WARNINGS;
        await this.handleFormSubmit();
    }

    setWarnings(warnings) {
        appState.layoutState.formWithoutChanges = false;
        this.gqlWarnings = warnings;
    }


    /**
     * Cancela las llamadas fetch que esten asignadas al AbortController
     */
    abortFetch() {
        if (this.controller) {
            this.controller.abort();
        }
    }

    async deleteModel(lista) {
        let modelo = this.getModelQuery();
        modelo.id = this.getFormId();
        try {
            await modelo.remove();
            if (this.props.fromRightModal) {
                this.onCloseModal();
            } else {
                this.propsUtil.changeUrl("/" + lista + "/ls/")
            }
        } catch (e) {
            let graphExceptionModel = new GraphException();
            let errorsAndWarnings = graphExceptionModel.getErrorsFromException(e);
            let errors = graphExceptionModel.getObjErrorsAndWarnings(errorsAndWarnings).errors;
            if (errors.length > 0) {
                this.gqlErrors = errors;
            }
        }
    }

    renderModals() {

    }

    renderImpl() {

    }

    render() {
        return (
            <>
                {this.renderImpl()}
                {this.renderModals()}
            </>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseForm;
