import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import appState from "../state/AppState";
import Overlay from "../components/modals/Overlay";
import VsfLink from "../components/VsfLink";
import translate from "../translator/translate";
import AppLogger from "../util/AppLogger";
import { Animated } from "react-animated-css";
import VsfButtonNew from "../components/VsfButtonNew";
import { observable } from "mobx";
import OFFER from "../config/OFFER";

@observer
class ConfigButton extends Component {

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    @observable configArray = [];


    async componentDidMount() {
        let validationRoleClient = appState.userState.isUserRoleClient();
        if (Object.keys(OFFER.creatable).length === 0) {
            let offer = new OFFER();
            offer.loadCreatables();
        }
        let result = [];
        let configuraciones = [
            { title: "Alarmas", model: "dispatcherWarning", to: "/config/warnings" },
            { title: "Días Festivos", model: "dateRange", to: "/config/festives" },
            { title: "Validaciones", model: "changesWithValidation", to: "/config/validates" },
            { title: "Traducciones", model: "translation", to: "/config/translates" },
            { title: "Reglas de formularios", model: "form", to: "/config/form/ls" },
            { title: "Plantillas de formularios", model: "formAssignment", to: "/config/formAssignment/ls" },
            { title: "Tenants", model: "tenant", to: "/config/tenant/ls" },
            { title: "Importar traducciones desde excel", model: "translation", to: "/config/importTranslates" },
            { title: "Grupos Dinamicos", model: "dynamicGroup", to: "/config/dynamicGroups/ls" },
            {
                title: "Administración",
                model: "configuration",
                to: "/admin/form",
                validationRoleClient: validationRoleClient,
            },
            { title: "Configuración", model: "configuration", to: "/config/form" },
            /*OFFER.netsuite ?  */
            { title: "NetSuite", model: "configuration", to: "/config/netsuite" }
            /* : ""*/,
        ];

        for (let configuracion of configuraciones) {
            if (OFFER.creatable?.[configuracion.model]) {
                result.push(configuracion)
            }
        }
        this.configArray = result;
    }


    render() {
        const t = translate;
        return (
            <>
                {/*Botón Config*/}
                {this.configArray?.length > 0 &&
                <div style={{ marginLeft: '12px' }}>
                    <VsfButtonNew className="user-dropdown btn btn--conf"
                                  onClick={() => appState.layoutState.clickConfig()}>
                    <span className="config-icon-container">
                    <i className="fas fa-cog cog-main" />
                    <div style={{ display: 'grid' }}>
                    <i className="fas fa-cog cog-top" />
                    <i className="fas fa-cog cog-bottom" />
                    </div>
                    </span>
                    </VsfButtonNew>
                </div>
                }
                {appState.layoutState.config &&
                <Animated style={{ zIndex: 100, minWidth: 186, position: 'absolute', top: 0 }} animationIn="zoomIn"
                          animationInDuration={250} isVisible={true}>
                    <Overlay show={appState.layoutState.config}
                             style={{ backgroundColor: 'transparent' }}
                             onClick={_ => appState.layoutState.config = false} />
                    <dl className="dropdown-sublist" style={{ top: 0 }}>
                        {this.configArray.map(config =>
                            <dd className="dropdown-sublist__item" key={config.title}>
                                <VsfLink onClick={_ => appState.layoutState.config = false}
                                         to={config.to}>{t(config.title)}</VsfLink></dd>
                        )
                        }

                    </dl>
                </Animated>
                }
            </>
        )
    }


}

export default withRouter(ConfigButton)
