import { action, computed, observable, toJS } from 'mobx';
import FetchProxy from "../network/FetchProxy";
import config from "../config/config";
import AppLogger from "../util/AppLogger";
import appState from "./AppState";
import util from "../util/Util";
import LoadTranslations from "../translator/LoadTranslations";
import LocalStorage from "../network/LocalStorage";
import jwt_decode from "jwt-decode";

const localStorage = new LocalStorage();


class LoginState {


    @observable accessToken = localStorage.getItem('accessToken') || util.getCookie("AccessToken");
    @observable userId = localStorage.getItem('userId');
    @observable refreshToken = localStorage.getItem('refreshToken');
    @observable user = {};
    @observable loginButtonLoading = false;
    @observable errorLogin = "";
    @observable dataUser = {};


    constructor() {
        this.accessToken = localStorage.getItem('accessToken');
        let cookie = util.getCookie("AccessToken");
        if (cookie) {
            this.accessToken = cookie;
        }
        //alert(cookie);
        // console.log("AccessToken:");
        // console.log(cookie);

        //Si obtengo el AccessToken desde la cookie significa que estoy accediendo desde un webview del movil. Establezco el user_id
        this.setUserIdFromCookie();
    }

    isTenantCocaCola() {
        let result=false;
        if (this.user?.tenantId=="bf7d56cc-b9ee-4f72-a583-ad5a221b1ddc") {
            result=true;
        }
        return result;
    }

    setUserIdFromCookie() {
        if (util.getCookie("AccessToken") != null) {
            let token = jwt_decode(this.accessToken);
            this.userId = token.UserId;
        }
    }

    @computed get isUserLogged() {
        let result = false;
        let accessToken = this.accessToken;
        if (util.hasValue(accessToken)) {
            result = true;
        }
        return result;
    }

    @computed get getAccessToken() {
        return this.accessToken;
    }

    getUserId() {
        return this.userId;
    }

    async getUser() {
        // this.log({ getUser: 1, userId: this.userId, user: this.user });
        let user = null;
        if (util.hasValue(this.userId)) {
            if (this.user?.id != null) {
                user = this.user;
            } else {
                user = await appState.userState.getUserById(this.userId);
                this.user = user;
                let resultTimeZone = util.getTimezoneBrowser();
                if (user?.timeZone != null) {
                    if (util.hasValue(appState.typifiedState.getObject(user.timeZone).code)) {
                        resultTimeZone = toJS(appState.typifiedState.getObject(user.timeZone).code);
                    }
                }
                let resultCurrentLanguage = 'es';
                if (user?.locale != null) {
                    if (util.hasValue(appState.typifiedState.getObject(user.locale).code)) {
                        resultCurrentLanguage = toJS(appState.typifiedState.getObject(user.locale).code);
                    }

                }
                localStorage.setItem('currentTimeZone', resultTimeZone);
                localStorage.setItem('currentLanguage', resultCurrentLanguage);
                if (user?.locale != null) {
                    let loadTranslations = new LoadTranslations();
                    await loadTranslations.loadMessages();
                }
                this.currentLanguage = resultCurrentLanguage;
            }
        }
        return user;
    }

    @observable currentLanguage;

    async doLogin() {
        let httpApi = new FetchProxy();
        httpApi.withAuthorization=false;
        try {
            this.loginButtonLoading = true;
            let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/token",
                {
                    "email": this.dataUser.email,
                    "password": this.dataUser.password,
                }
            );
            let responseJson = await response.json();
            // this.log({ doLogin: 1, responese: responseJson.data });
            if (responseJson.errorCode === 0) {
                this.loadAccessTokenInfo(responseJson.data);
                await appState.typifiedState.loadMasters();
                let user = await this.getUser();
                if (user.id != null) {
                    await appState.tenantState.loadTenantsApi();
                    await appState.dynamicGroupState.loadDynamics();
                }
            } else {
                this.log(responseJson);
                this.errorLogin = responseJson.message;
            }
        } catch (e) {
            this.log({ doLoginException: 1, e });
            this.errorLogin = "Se ha producido un error. Por favor vuelva a intentarlo pasados unos minutos";
        }
        this.loginButtonLoading = false;
    }

    @action
    doLogout() {
        localStorage.setItem('accessToken', "");
        localStorage.setItem('refreshToken', "");
        localStorage.setItem('userId', "");
        window.location.href = "/";
    }

    loadAccessTokenInfo(jsonData) {
        this.accessToken = jsonData.access_token;
        this.accessTokenExpiration = jsonData.access_token_expiration;
        this.refreshToken = jsonData.refresh_token;
        this.userId = jsonData.user_id;
        //No son async porque pueden hacerse en el background
        localStorage.setItem('accessToken', this.accessToken);
        localStorage.setItem('accessTokenExpiration', this.accessTokenExpiration);
        localStorage.setItem('refreshToken', this.refreshToken);
        localStorage.setItem('userId', this.userId);
    }

    async doRefreshToken() {
        let httpApi = new FetchProxy();
        httpApi.withAuthorization=false;
        try {
            if (this.refreshToken != "") {
                let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + "/refresh",
                    { "refreshToken": this.refreshToken }
                );
                let responseJson = await response.json();
                this.loadAccessTokenInfo(responseJson);
            }
        } catch (e) {
            this.log({ doRefreshTokenException: 1, e });
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    /**
     * Pasa la autentificación desde el WebView de la aplicación movil. Para que podamos navegar como el usuario
     */
    async setAccessTokenFromWebView() {
        //Si es acceso desde mobile establezco el accessToken y el usuario.
        /*
        if (util.getCookie("AccessToken")!=null) {
            //Cargo el usuario actual, para que vea los accesos adecuados
            if (util.esVacio(this.userId)) {
                let userMeQuery = new UserMeModel();
                let userMe = await userMeQuery.findByIdNotNull("-");
                this.userId = userMe.id;
                this.user = userMe;
            }
        }
        */

        //this.log("setAccessTokenFromWebView");
        /*
        let webViewJwtToken=window.webViewJwtToken;
        if (webViewJwtToken!=null) {
            alert("setAccessTokenFromWebView");
            let establecerAccessToken=true;
            establecerAccessToken = this.accessToken == null || this.accessToken != webViewJwtToken.accessToken;
            if (establecerAccessToken) {
                alert("ModifyToken");
                let jsonData = {
                    access_token: webViewJwtToken.accessToken,
                    refresh_token: webViewJwtToken.refreshToken,
                    user_id: webViewJwtToken.userId
                };
                this.loadAccessTokenInfo(jsonData);
                //window.location.href="/wms/main";
                //this.log("Redirected");
                alert("Redirected");
            }
        } else {
            alert("setAccessTokenFromWebView: null");
        }

         */
    }

    getUserTokenFromDecode() {
        let token = {};
        if (util.hasValue(this.accessToken)) {
            token = jwt_decode(this.accessToken);
        }
        //RoleCode
        return token;
    }

}

export default LoginState;
