import React from 'react';
import { ClipLoader } from 'react-spinners';
import './../scss/components/SpinnerClipLoader.scss';
import AppLogger from "../util/AppLogger";

class SpinnerClipLoaderComponent extends React.Component {

    DOHERTY_LIMIT = 650;
    static defaultProps = {
        size:  50,
    };

    constructor(props) {
        super(props);
        if (props.dohertyLimit != null) {
            this.DOHERTY_LIMIT = props.dohertyLimit;
        }
        let loading = false;
        if (this.DOHERTY_LIMIT == 0) {
            loading = true;
        }
        this.state = {
            loading,
            isMounted: true
        }
    }

    componentDidMount() {
        this._isMounted = true;
        let callback = () => {
            if (this._isMounted) {
                this.setState({ ...this.state, loading: true })
            }
        }
        if (this.DOHERTY_LIMIT == 0) {
            callback();
        } else {
            setTimeout(_ => {
                callback();
            }, this.DOHERTY_LIMIT);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        let classesCss = "text-center loading";
        if (this.props.loading) {
            if (this.state.loading) {
                return (
                    <div className={classesCss}>
                        <ClipLoader
                            color={'red'} size={this.props.size}
                            loading={this.state.loading}
                        />
                    </div>
                )
            } else {
                return null;
            }

        } else {
            return (this.props.children)
        }


    }
}

export default SpinnerClipLoaderComponent
