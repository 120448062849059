import AbstractModel from "./AbstractModel";

export default class WorkOrderMovedModel extends AbstractModel {

    orderId;
    workOrderId;

    nameMainType = "workOrderMoved";
    graphFindByIdOperation = "workOrderMoved";
    graphFindOperation = "workOrderMovedsConnection";

    getArrayFields() {
        let result = {
            "orderId": "",
            "workOrderId": "",
        };
        return result;
    }
}
