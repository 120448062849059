import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import appState from '../../state/AppState';
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import BaseEditableTable from "../base/BaseEditableTable";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import {computed} from "mobx";
import InfoBlock from "../../components/InfoBlock";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import util from '../../util/Util';
import WorkOrderModel from "../../models/WorkOrderModel";
import OrderFormArticle from "../orders/OrderFormArticle";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import WorkOrderActions from "./WorkOrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import VsfButtonNew from "../../components/VsfButtonNew";

@observer
class WorkOrderFormArticles extends BaseEditableTable {

    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "workOrderId";
        this.state = {
            hasSomeRowEditing: false,
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.userCacheState.loadUserCacheTechnicals();
        await this.loadTable();
        this.setState({ loading: false })
    }

    async loadTable() {
        appState.workOrderCartArticlesState.workOrderArticles = this.graphDataMainType.workOrderCartArticle;
        this.loadSlots()
    }

    async reloadTable() {
        appState.workOrderCartArticlesState.workOrderArticles = this.graphDataMainType.workOrderCartArticle;
        this.loadSlots()
        appState.assetState.orderArticles = this.calculaCantidad();

    }

    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("order");
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("order.slot");
        result.addRelatedTable("order.slot.client");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    loadSlots() {
        let miSlot = [];
        if (this.graphDataMainType.order.slot?.length > 0) {
            for (let slot of this.graphDataMainType.order.slot) {
                slot.isEditing = false;
                if (util.perteneceA(this.graphDataMainType.id, slot.workOrderIds)) {
                    miSlot.push(slot);
                } else if (util.perteneceA("-1", slot.workOrderIds)) {
                    miSlot.push(slot);
                }
            }
        }
        appState.slotsState.slots = miSlot;
    }


    formatDecimal(number) {
        if (number == null) {
            number = 1;
        }
        return parseFloat((number.toString()).replace(",", "."));
    }

    onOpenRowNewEmpty() {
        if (appState.typifiedState.getObject(this.graphDataMainType?.status)?.code === "CANCELED" ||
            appState.typifiedState.getObject(this.graphDataMainType?.order?.status)?.code === "CANCELED") {
            this.setState({ orderCanceled: true });
        } else {
            let newModel = this.getModelTable().toPlainObject();
            if (this.state.fromWorkOrder) {
                newModel.orderId = this.graphDataMainType.order.id;
            }
            newModel.isEditing = true;
            this.mobxListado.push(newModel);
            this.setState({
                hasSomeRowEditing: true
            });
        }
    };

    calculaPrecio() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles || [];
        articulos.map((articulo, index) => {
            if (util.hasValue(articulo.quantity)) {
                if (util.hasValue(articulo.articlePrice)) {
                    if (result == 0)
                        result = (this.formatDecimal(articulo.articlePrice)) * (this.formatDecimal(articulo.quantity));
                    else
                        result += (this.formatDecimal(articulo.articlePrice)) * (this.formatDecimal(articulo.quantity));
                } else {
                    if (result == 0)
                        result = (this.formatDecimal(articulo.quantity));
                    else {
                        result += (this.formatDecimal(articulo.quantity));
                    }
                }
            }
        });
        return result.toFixed(2);
    }

    calculaCantidad() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (articulo.quantity != null) {
                if (result == 0)
                    result = (util.formatDecimal(articulo.quantity));
                else if (articulo.quantity != '') {
                    result += (util.formatDecimal(articulo.quantity));
                }
            }
        });
        return parseFloat(result).toFixed(2);
    }

    renderImpl() {
        const t = translate;
        let modalClasses = util.getModalClasses();
        let cantidad = this.calculaCantidad();
        this.propsUtil = new PropsUtil(this.props);
        let workOrder = this.graphDataMainType;

        return (

            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={workOrder.code || t("Nueva")}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                      getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId() || this.props.workOrderId}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                active="Piezas"
                                numAlarm={2}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={workOrder.code || t("Nueva")}
                        >
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                  getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card mb-3">
                        <div className={modalClasses.body ? modalClasses.body : 'card-body'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                num={1}
                                active={"Piezas"}
                            />
                            }

                            <GroupOfField
                                title={t('Orden de Trabajo')}
                                subtitle={t('Repuestos')}
                                icon='fas fa-calendar'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div
                                    style={{
                                        margin: 10,
                                        fontWeight: 'bold'
                                    }}>{t("Info para añadir piezas desde BO")}</div>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna pieza creada')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <th scope="col">{t("Codigo")}</th>
                                            <th scope="col">{t("Nombre")}</th>
                                            <th scope="duration">{t("Cantidad")}</th>
                                            <th scope="col">{t("Lote")}</th>
                                            <th scope="col">{t("Visita")}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">
                                        {this.mobxListado.map((article, index) => (
                                            <OrderFormArticle
                                                key={article && article.id}
                                                orderId={this.graphDataMainType.orderId}
                                                workOrderId={this.getFormId()}
                                                rowIndex={index}
                                                row={article} foreingKeyfield={this.foreingKeyfield}
                                                reload={(e) => this.reloadData(e)}
                                                fromWorkOrder={true}
                                                workOrders={appState.workOrderState.workOrders}
                                                slots={appState.slotsState.slots}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            />
                                        ))}

                                        </tbody>
                                        <tfoot>
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <td colSpan="2">{t('TOTAL')}</td>
                                            <td colSpan="7">
                                                {this.mobxListado.length}
                                            </td>
                                        </tr>
                                        }
                                        </tfoot>
                                    </table>
                                    <div className=" text-right">
                                        <VsfButtonNew
                                            disabled={this.state.hasSomeRowEditing}
                                            showButton={this.creatable["article"] || false}
                                            label={t("Añadir")}
                                            onClick={_ => this.onOpenRowNewEmpty()}
                                            isEditing={this.state.hasSomeRowEditing}
                                            className="btn btn--loader"
                                        />
                                    </div>

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>

            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(WorkOrderFormArticles)
