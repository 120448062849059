import {observable} from 'mobx';
import LocalStorage from "../network/LocalStorage";
import AppLogger from "../util/AppLogger";
import util from "../util/Util";

const localStorage = new LocalStorage();

class LayoutState {
    /**
     * Muestra o no muestra la cabecera sticky del calendario
     * @type {boolean}
     */
    @observable hoverDndrop = false;
    @observable scrollTop = false;
    @observable scrollBot = false;

    @observable mobileWmsHtmlWrapper = util.getCookie("Device") == "MobileNative" ? true : false;

    /**
     * Indica si se ve la barra de la derecha o no
     * @type {boolean}
     */
    @observable zIndex = 99;
    @observable backgroundScheduled = 0;
    @observable backgroundRowScheduled = 0;
    @observable scheduledDateBlocked = "";
    @observable scheduledSlotOpacity = {};
    @observable scheduledUserBlocked = "";

    @observable user = false;
    @observable alarm = false;
    @observable config = false;
    @observable menu = false;
    @observable downloadExcelCsv = false;
    @observable summaryModal = "";
    @observable slotview = false;

    @observable menuCreateNewActive = false;
    @observable formWithoutChanges = true;
    //si esto esta false, o ha pasado la validacion de contraseña o no es necesaria
    @observable badPassword = false;
    @observable modalChangeOpen = false;
    @observable loadingForm = false;
    @observable sidebarMinimized = (localStorage.getItem("sidebarMinimized") === "1");
    @observable favouritesDropMenuOpen = false;
    @observable slotsMenuOpen = false;
    @observable favouritesSaveMenuOpen = false;
    @observable favouritesleftMenuOpen = false;
    @observable workOrdersSelectEditing = false;
    @observable workOrdersSelectEditingPoint = {pageX: 0, pageY: 0};

    @observable infoBlockEnabled = false;
    @observable infoDebuggerEnabled = false;
    @observable schedudeByTime = "mes";

    clickHiddenMenu() {
        this.sidebarMinimized = !this.sidebarMinimized;
        let sidebarMinimizedNumber = "0";
        if (this.sidebarMinimized) {
            sidebarMinimizedNumber = "1";
        }
        //Si está minificado, también cambio el masterDataOpen
        if (this.sidebarMinimized) {
            this.masterDataOpen = false;
        }
        localStorage.setItem("sidebarMinimized", sidebarMinimizedNumber);
    }


    clickAddNew() {
        this.menuCreateNewActive = !this.menuCreateNewActive;
    }

    clickUser() {
        this.user = !this.user;
    }

    clickAlarm() {
        this.alarm = !this.alarm;
    }

    clickConfig() {
        this.config = !this.config;
    }

    clickMenu() {
        this.menu = !this.menu;
    }

    clickDownloadExcelCsv() {
        this.downloadExcelCsv = !this.downloadExcelCsv;
    }

    clickSummaryModal(slotId) {
        this.summaryModal = slotId
    }

    clickslotView() {
        this.slotview = !this.slotview;
    }

    clickFavouritesSaveMenuOpen() {
        this.favouritesSaveMenuOpen = !this.favouritesSaveMenuOpen;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default LayoutState;
