import DocumentModel from "./DocumentModel";
import TypifiedValueModel from "./TypifiedValueModel";
import appState from '../state/AppState'
import util from '../util/Util'
import SaveOptionsModel from "./SaveOptionsModel";

export default class ClientModel extends SaveOptionsModel {
    static Centro = "0";
    static  Sur = "10000000";
    static  Norte = "20000000";
    static  Madeira = "30000000";
    static  Azores = "40000000";

    static CODE_NETSUITE = "NETSUITE";
    static CODE_CCEP = "CCEP";
    static CODE_NUDDO = "NUDDO";


    static STATUS_ACTIVO = "ACTIVE";
    static STATUS_BLOCKED = "BLOCKED";
    address;
    city;
    code;
    status;
    companyName;
    createdAt;
    createdBy;
    contractId;
    contractNumber;
    startDateContract;
    endDateContract;
    district;
    id;
    isPos;
    name;
    posAddress;
    posCity;
    posDistrict;
    posPostalCode;
    postalCode;
    posZoneId;
    updatedAt;
    orderView;
    updatedBy;
    vatID;
    chainId;
    geoLongitude;
    geoLatitude;
    parentId;
    originDataClient;
    dynamicField;
    codeErp;
    statusErp;
    localizationArticleId;

    nameMainType = "client";
    graphFindByIdOperation = "client";
    graphFindOperation = "clientsConnection";
    graphExportOperation = "clientsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
                ...resultBase,
                "address": {label: "Direccion"},
                "city": {label: "Ciudad"},
                "code": {label: "Código"},
                "status": {label: "Status"},
                "companyName": {label: "Razón Social"},
                "district": {label: "Provincia"},
                "isPos": {type: "BoolField", label: "Datos"},
                "name": {label: "Cliente"},
                "posAddress": {label: "Dirección Est."},
                "posCity": {label: "Localidad"},
                "posDistrict": {label: "Provincia Est."},
                "posPostalCode": {label: "Código Postal Est."},
                "postalCode": {label: "Provincia Est."},
                "posZoneId": {label: "Zona"},
                "vatID": {type: "CodeField", label: "NIF"},
                "parentId": {label: "Pertenece a"},
                "chainId": {label: "Cadena"},
                "contractId": {label: "Contrato asociado"},
                "startDateContract": {label: "Fecha de inicio de contrato"},
                "contractNumber": {label: "Número de contrato"},
                "endDateContract": {label: "Fecha fin de contrato"},
                "geoLongitude": {label: "Longitud"},
                "geoLatitude": {label: "Latitud"},
                "codeErp": {label: "Código ERP"},
                "originDataClient": {readonly: true, label: "Origen de los datos de cliente"},
                "orderView": {label: "Vista de Pedido"},
                "dynamicField": {label: "Campo Dinámico"},
                "statusErp": {label: "Estado ERP"},
                "localizationArticleId": {label: "ID del Artículo de Localización"},
            }
        ;
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "parentClient", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "zoneClient", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "chain", "RelationOne", TypifiedValueModel);
        return result;
    }

    getArrayForExcelImports() {
        let result = {
            "Datos generales": [
                "code",
                "name",
                "posAddress",
                "posCity",
                "posDistrict",
                "posPostalCode",
                "posZoneId",
                "parentId",
                "chainId"
            ]
        };
        return result;
    }

    getOrderViewZone() {
        let result = '0';
        let tipos = {
            Madeira: 100000,
            Norte: 200000,
            Sur: 300000,
            Centro: 400000,
            Azores: 500000
        };
        if (util.hasValue(tipos[appState.typifiedState.getValue(this.posZoneId)])) {
            result = tipos[appState.typifiedState.getValue(this.posZoneId)];
        }
        return result;
    }

};





















