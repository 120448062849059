import BaseModelState from "./BaseModelState";
import {computed, observable} from "mobx";
import ContractModel from "../models/ContractModel";

class ContractState extends BaseModelState {

    @observable contracts = [];

    async getContracts() {
        let query = new ContractModel();
        let result = await query.find();
        if (result == null) {
            result = [];
        }
        this.contracts = result;
    }

    @computed get labelValuesForSelect() {
        let result = [];
        let copia = {};
        for (let item of this.contracts) {
            if (copia[item.name] == null) {
                copia[item.name] = "";
                result.push({
                    value: item.id,
                    label: item.name,
                    obj: item,
                });
            }
        }
        return result;
    }

}

export default ContractState;
