import React, { Component } from 'react'
import VsfModal from './modals/VsfModal'
import AppLogger from '../util/AppLogger'
import translation from '../translator/translate'
import VsfButtonNew from './VsfButtonNew'
import { observer } from 'mobx-react'
import GqlErrors from './status/GqlErrors'
import { withRouter } from 'react-router-dom'
import { observable } from 'mobx'
import GraphException from '../network/GraphException'
import SelectSuggestComponent from './fields/SelectSuggestComponent'
import OrderModel from '../models/OrderModel'
import ClientModel from '../models/ClientModel'

@observer
class OrderMovedToClientModal extends Component {

  constructor (props) {
    super(props)
    this.state = {
      loading: false,
    }
  }

  setErrors (errors) {
    this.gqlErrors = errors
  }

  async updateEvent (event) {
    this[event.target.name] = event.target.value
  }

  @observable client ={}
  @observable gqlErrors = []

  /**
   * Cancela las llamadas fetch que esten asignadas al AbortController
   */
  abortFetch () {
    if (this.controller) {
      this.controller.abort()
    }
  }

  /**
   * Inicializa el AbortController con una nueva señal para poder cancelar las llamadas fecth
   */
  initializeAbortController () {
    this.controller = new AbortController()
    this.signal = this.controller.signal
  }

  async getClientsSuggestions (value) {
    let clientArray = []
    // Abortamos la anterior llamada
    this.abortFetch()
    try {
      // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
      this.initializeAbortController()
      let clientQuery = new ClientModel()
      clientQuery.isPos = true
      clientQuery.filters = [
        {
          'fieldName': 'name,code',
          'fieldValue': value,
          'filterOperator': 'SUBSTR'
        },
      ]
      let client = await clientQuery.find()
      client.map((client) => {
        let clientObject = {}
        clientObject.label = client.code + ' - ' + client.name
        clientObject.value = client
        clientArray.push(clientObject)
      })
    } catch (e) {
      this.log('Error => ' + e)
    }
    return clientArray
  }

  async saveClient () {
    this.setState({ loading: true })
    try {
      let orderQuery = new OrderModel()
      orderQuery.hidrate(this.props.orderModel)
      orderQuery.clientId = this.client.id
      orderQuery.propietaryId = this.client.parentId
      await orderQuery.persist()
      setTimeout(() => {
        this.setState({ loading: false })
        this.props.closeModal()
        window.location.reload()
      }, 3000)
    } catch (e) {
      this.setState({ loading: false })
      let errors = new GraphException().getErrorsFromException(e)
      this.gqlErrors = errors
    }
  }

  render () {
    const t = translation
    return (
      <VsfModal
        isOpen={this.props.openModal}
        onCloseModal={this.props.closeModal}
        className="center top-to-bottom-client">
        <div className="modal-push">
          <div className="modal-head visible-text">
            <div className="d-flex align-items-center">
              <div className="col-12 text-left">
                <div className="modal-title">
                  <p>{this.props.title}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body-filters">

            <div className="c-modal-info__cell__body row">
              <span
                className={'col-12 mb-3 mt-3'}>{t('Esta cambiando un establecimiento.. Deberá revisar los equipos afectados en este pedido para modificar el nuevo establemcimiento ¿está usted seguro?')}</span>
              <SelectSuggestComponent
                value={this.client?.id}
                onChange={(e) => this.updateEvent(e)}
                title={t('Establecimiento')}
                name={'client'}
                classGroup={'col-md-6 col-lg-6'}
                loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                placeholder={t('Escribe...')}
              />
            </div>
          </div>

          <div className="modal-footer ">
            <div className="mt-4">
              <div className="d-flex justify-content-end mb-2">
                <VsfButtonNew
                  label={t('Salir')}
                  className="btn btn-secondary"
                  onClick={() => this.props.closeModal()}
                />
                <VsfButtonNew
                  label={t('Guardar')}
                  loading={this.state.loading}
                  icon="fa fa-save"
                  loadedError={this.gqlErrors.length !== 0}
                  className="btn btn--loader"
                  onClick={() => this.saveClient()}
                />
              </div>
            </div>

          </div>
        </div>
        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

      </VsfModal>
    )
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default withRouter(OrderMovedToClientModal)
