import React, {Component} from 'react';
import VsfModal from "../components/modals/VsfModal";
import {observer} from "mobx-react";
import AppLogger from "../util/AppLogger";
import {observable} from "mobx/lib/mobx";
import translate from "../translator/translate";
import VsfButtonNew from "../components/VsfButtonNew";

@observer
class ModalListFilter extends Component {

    /**
     * Campos para mostrar el filtro aplicado
     * @type {{modalOpen: boolean}}
     */
    @observable stateFilter = {
        modalOpen: true, //Indica si se debe abrir el desplegable con los filtros
    };

    onAplicarFiltros() {
        this.props.stateFilter.modalOpen = false;
        this.props.listComponent.onClickAplicarFiltros();
    }

    onCloseModal() {
        this.props.stateFilter.modalOpen = false;
    }

    render() {
        let stateFilter = this.props.stateFilter;
        const t = translate;
        return (
            <VsfModal
                stateFilter={stateFilter} isOpen={this.props.stateFilter.modalOpen}
                className="list-columns-modal" onCloseModal={() => this.onCloseModal()}
                contentLabel="Example Modal">
                <div className="modal-push">
                    <div className="modal-head">
                        <div className="row">
                            <div className="col-10 text-left">
                                <div className="modal-title">
                                    <p>{t("Filtros")}<span>{" "}{this.props.title}</span></p>
                                </div>
                            </div>
                            <div className="col-2 text-right">
                                <button type="button" className="close" aria-label="Close"
                                        onClick={() => this.onCloseModal()}>
                                    <span className="fas fa-times" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={ "modal-body-filters"}>
                        <div className="row">
                            {this.props.children}
                        </div>
                        <div className="mb-3 text-right">
                            <VsfButtonNew
                                label={t("Aplicar Filtros")}
                                className="btn btn--loader" showButton
                                onClick={() => this.onAplicarFiltros()}

                            />
                        </div>
                    </div>
                    {/*<div className="modal-footer">*/}
                        {/*<div className="mb-3 text-right">*/}
                            {/*<VsfButtonNew*/}
                                {/*className="btn btn--loader" showButton*/}
                                {/*onClick={() => this.onAplicarFiltros()}*/}
                                {/*label={"Aplicar Filtros"}*/}
                            {/*/>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                </div>

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default ModalListFilter
