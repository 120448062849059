import React, {Component} from 'react';
import VsfModal from "./modals/VsfModal";
import AppLogger from "../util/AppLogger";
import translate from "../translator/translate";
import VsfButtonNew from "./VsfButtonNew";
import {observer} from "mobx-react";
import GqlErrors from "./status/GqlErrors";
import {withRouter} from "react-router-dom";
import {observable} from "mobx";
import InputTypeComponent from "./fields/InputTypeComponent";
import AssetModel from "../models/AssetModel";
import GraphException from "../network/GraphException";

@observer
class DuplicateAssetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async updateEvent(event) {
        this[event.target.name] = event.target.value;
    }


    @observable plate;
    @observable serialNumber;
    @observable gqlErrors = [];

    async saveStatus() {
        this.setState({ loading: true });
        try {
            let asset = new AssetModel();
            asset.hidrate(this.props.assetModel);
            asset.id = "";
            asset.plate = this.plate;
            asset.serialNumber = this.serialNumber;
            await asset.persist();
            setTimeout(() => {
                this.setState({ loading: false });
                this.props.closeModal();
            }, 3000)
        } catch (e) {
            this.setState({ loading: false });
            let errors = new GraphException().getErrorsFromException(e);
            this.gqlErrors = errors;
        }
    }

    render() {
        const t = translate;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">

                        <div className="c-modal-info__cell__body row">

                            <InputTypeComponent
                                value={this.serialNumber}
                                onChange={(e) => this.updateEvent(e)}
                                title={t("Numero de serie")}
                                name={"serialNumber"}
                                classGroup={"col-md-6 col-lg-3"}
                                type={"text"}
                            />

                        </div>
                    </div>

                    <div className="modal-footer ">
                        <div className="mt-4">
                            <div className="d-flex justify-content-end mb-2">
                                <VsfButtonNew
                                    label={t('Salir')}
                                    className="btn btn-secondary"
                                    onClick={() => this.props.closeModal()}
                                />
                                <VsfButtonNew
                                    label={t("Guardar")}
                                    loading={this.state.loading}
                                    icon="fa fa-save"
                                    loadedError={this.gqlErrors.length !== 0}
                                    className="btn btn--loader"
                                    onClick={() => this.saveStatus()}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(DuplicateAssetModal)
