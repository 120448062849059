import React from "react";
import {observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import BaseEditableTable from "../base/BaseEditableTable";
import {computed, observable} from "mobx";
import InfoBlock from "../../components/InfoBlock";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import ArticleModel from "../../models/ArticleModel";
import WorkOrderArticleList from "./WorkOrderArticleList";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import ArticleActions from "./ArticleActions";

@observer
class ArticleWorkOrderList extends BaseEditableTable {

    @observable stocks = [];

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "article";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        this.setState({ loading: false })
    }

    @computed get mobxListado() {
        return this.stocks;
    }

    setMobxListado(newValue) {
        this.stocks = newValue;
    }

    getModelTable() {
        return new ArticleModel();
    }

    getModelQuery() {
        return new ArticleModel();
    }

    renderImpl() {

        const t = translate;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);

        return (

            <InfoBlock componentObject={this}>
                <SpinnerClipLoaderInPage loading={this.state.loading}>
                    <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                        {this.props.fromRightModal ?

                            <div className={modalClasses.head}>
                                <PageTitleRightModal componentObject={this}
                                                     title={this.getPageTitle()}
                                                     subtitle={this.getPageSubtitle()} mode={"view"}
                                                     onCloseModal={() => this.onCloseModal()}
                                />
                                <TabsComponent
                                    id={this.getFormId() || this.props.articleId}
                                    tabs={this.tabsData.getDataArticles()}
                                    active="Consumos"
                                    fromRightModal={this.props.fromRightModal}
                                />
                            </div>
                            :
                            <PageTitle
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()}
                            > <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}
                            >
                                <ArticleActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}
                                                model={this.graphDataMainType}
                                                getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            </PageTitle>
                        }
                        <div className="card mb-3">
                            <div className={modalClasses.body}>
                                {!this.props.fromRightModal &&
                                <TabsComponent
                                    id={this.getFormId()}
                                    tabs={this.tabsData.getDataArticles()}
                                    num={1}
                                    active={"Consumos"}
                                />
                                }

                                <WorkOrderArticleList
                                    {...this.props}
                                    articleId={this.graphDataMainType.id}
                                    key={this.graphDataMainType.id}
                                />
                            </div>
                        </div>

                    </div>
                </SpinnerClipLoaderInPage>
            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(ArticleWorkOrderList);
