import React from 'react';
import { observer } from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";
import Switch from "react-switch";

@observer
export default class SwitchComponent extends BaseFieldComponent {

    constructor(props) {
        super(props);
        this.state = { checked: this.props.checked };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.checked !== this.props.checked) {
            this.setState({
                checked: nextProps.checked
            })
        }
    }

    handleChange(checked) {
        let eventObj = {
            target: {
                name: this.props.name,
                value: checked
            }
        };
        if (this.props.withValue) {
            eventObj = {
                target: {
                    name: this.props.name,
                    value: this.props.value
                }
            };
        }
        this.setState({ checked });
        this.props.onChange(eventObj);
    }

    render() {
        let props = this.props;
        let readOnly = this.checkReadOnly();
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                relatedInfo={this.props.relatedInfo}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                labelClassName={this.props.labelClassName}
                data-cy={this.props.dataCy}
                style={{ marginBottom: 2, marginRight: 20 }}
                flexLabelInput={this.props.flexLabelInput || ""}
                {...props}
            >

                <Switch disabled={readOnly} onChange={(e) => this.handleChange(e)} checked={this.state.checked || false}
                        onColor={"#2844b9"} />


            </FormInputWrapper>
        );
    }
}

