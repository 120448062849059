import AbstractModel from "./AbstractModel";
import UserModel from "./UserModel";
import VehicleModel from "./VehicleModel";
import ArticleModel from "./ArticleModel";

export default class StockModel extends AbstractModel {

    subInventory;
    articleCode;
    quantity;
    type;
    lote;
    subInventoryCodeErp;
    loteCodeErp;
    articleCodeErp;

    nameMainType = "stock";
    graphFindByIdOperation = "stock";
    graphFindOperation = "stocksConnection";
    graphExportOperation = "stocksExport";


    newModel() {
        return new StockModel();
    }


    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "subInventory": { label: "Almacén" },
            "articleCode": { label: "Código de Artículo" },
            "lote": { label: "Lote" },
            "quantity": { label: "Cantidad" },
            "type": { label: "Tipo" },
            "subInventoryCodeErp": { label: "Código ERP del almacén"},
            "loteCodeErp": { label: "Código ERP del lote"},
            "articleCodeErp": { label: "Código ERP del artículo"},
        };
        this.addRelatedTableGeneric(result, "users", "Relation", UserModel);
        this.addRelatedTableGeneric(result, "article", "RelationOne", ArticleModel);
        this.addRelatedTableGeneric(result, "vehicles", "Relation", VehicleModel);
        return result;
    }


    getArrayForExcelImports() {
        let result = {
            "Datos generales": [
                "subInventory",
                "articleCode",
                "lote",
                "quantity",
            ]
        };
        return result;
    }


};
