import React from 'react'
import { observer } from 'mobx-react'
import BaseEditableRow from '../base/BaseEditableRow'
import InfoBlock from '../../components/InfoBlock'
import AppLogger from '../../util/AppLogger'
import translate from '../../translator/translate'
import PropsUtil from '../../util/PropsUtil'
import util from '../../util/Util'
import WorkLogModel from '../../models/WorkLogModel'
import appState from '../../state/AppState'
import { observable, toJS } from 'mobx'
import VsfButtonNew from '../../components/VsfButtonNew'
import DateInputHoursMobiscroll from '../../components/fields/DateInputHoursMobiscroll'
import GroupOfField from '../../components/layout/GroupOfField'
import GraphException from '../../network/GraphException'
import GqlErrors from '../../components/status/GqlErrors'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import Select2Component from '../../components/fields/Select2Component'
import SlotModel from '../../models/SlotModel'
import OFFER from '../../config/OFFER'
import SelectDiagnostic from '../../components/SelectDiagnostic'
import SelectTasks from '../../components/SelectTasks'
import SelectComment from '../../components/SelectComment'
import OrderSummaryWorkLogs from "../orders/OrderSummaryWorkLogs";
import debug from "../../util/debug";

@observer
class WorkOrderSummaryWorkLogs extends BaseEditableRow {

    constructor (props) {
        super(props)
        this.state = {
            isEditing: false,
            workLog: {},
            workLogAction: '',
        }
    }

    componentDidMount () {
        this.loadWorkLogsOfWorkOrder()
    }

    /**
     * Carga el Dict del action que se este actualizando
     */
    async loadWorkLogsOfWorkOrder () {
        let workOrder = this.props.workOrder
        let objDict = {}
        let workLogUpdate = {}
        let workLogModel = new WorkLogModel()
        for (let workLog of this.props.slot.workLog) {
            if (workLog.action === WorkLogModel.START_VISIT || workLog.action === WorkLogModel.END_VISIT) {
                workLogUpdate[workLog.action] = { ...workLog }
            }
            if (workLog.workOrderId === workOrder.id) {
                if (util.hasValue(workLog.data)) {
                    if (workLog.action === WorkLogModel.WORK_ORDER_TEST_QUALITY) {
                        let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(workLog.action)]
                        objDict[workLog.action] = util.getDictSingleFromArrayFieldName(arrDict, 'code')
                    } else if (workLog.action === WorkLogModel.FLAVOR_PLANOGRAM) {
                        let arrDict = JSON.parse(workLog.data)[workLogModel.nameForData(workLog.action)]
                        objDict[workLog.action] = this.getDictFlavor(arrDict)
                    } else {
                        let arrDict = JSON.parse(workLog.data)?.[workLogModel.nameForData(workLog.action)]
                        if (arrDict) {
                            objDict[workLog.action] = util.getDictSingleFromArray(arrDict)
                        }
                    }
                }
                if (workLog.action === WorkLogModel.END_WORK_ORDER || workLog.action === WorkLogModel.RETURN_WORK_ORDER || workLog.action === WorkLogModel.PAUSE_WORK_ORDER) {
                    workLogUpdate['FIN_OT'] = { ...workLog }
                } else {
                    workLogUpdate[workLog.action] = { ...workLog }
                }
            }
        }
        this.objDict = objDict
        this.workLogUpdate = workLogUpdate
    }

    @observable objDict = {}
    @observable objDictChange = {}
    @observable workLogUpdate = {}
    @observable workLogUpdateChange = {}

    getDictFlavor (array) {
        let result = {}
        for (let flavorObj of array) {
            for (let flavor of Object.keys(flavorObj))
                if (result[flavor] == null) {
                    result[flavor] = flavorObj[flavor]
                }
        }
        return result
    }

    getTimeSlot (arrWorkLogs, action) {
        let timeSlot
        let workLogs = arrWorkLogs
        for (let workLog of workLogs) {
            if (workLog.slotId === this.props.slot.id && workLog.action === WorkLogModel[action]) {
                timeSlot = workLog.time
            }
        }
        return timeSlot
    }

    onChangeObjDict (objDict, action) {
        this.objDictChange[action] = objDict
        this.log({ onChangeObjdict: objDict })
    }

    onChangeWorkLogDictStatus (action, e) {
        if (this.workLogUpdate[action] == null) {
            this.workLogUpdate[action] = {}
        }
        this.workLogUpdate[action].action = e.target.value
        if (this.workLogUpdateChange[action] == null) {
            this.workLogUpdateChange[action] = {}
        }
        this.workLogUpdateChange[action] = this.workLogUpdate[action]
        // this.log({ onChangeWorkLogDictStatus: 1, workLogUpdateChange: this.workLogUpdateChange[action] })
    }

    onChangeWorkLogDict (action, e) {
        if (this.workLogUpdate[action] == null) {
            this.workLogUpdate[action] = {}
        }
        this.workLogUpdate[action].time = e.target.value
        if (this.workLogUpdateChange[action] == null) {
            this.workLogUpdateChange[action] = {}
        }
        this.workLogUpdateChange[action] = this.workLogUpdate[action]
        this.log({
            onChangeWorkLogDict: 1,
            workLogUpdateChange: this.workLogUpdateChange
        })
    }

    renderFlavorData (workLogs, action) {
        let orderSummaryWorkLogs = new OrderSummaryWorkLogs(this.props);
        return orderSummaryWorkLogs.renderFlavorData(workLogs, action)
    }

    renderData (workLogs, action, editable) {
        const t = translate
        let data = []
        let workLogModel = new WorkLogModel()
        let workLogsCopy = workLogs.slice(0).reverse()
        let timeSlot = this.getTimeSlot(workLogs, action)
        for (let workLog of workLogsCopy) {
            if (workLog.action === WorkLogModel[action] &&
                ((util.getMoment(workLog.time).format('YYYYMMDDHH') <= util.getMoment(timeSlot).format('YYYYMMDDHH') &&
                    workLog.slotId === this.props.slot.id))) {
                data.push(workLog)
            }
        }
        if (this.objDict[action] == null) {
            this.objDict[action] = {}
        }
        if (data.length !== 0 || (this.state.isEditing && editable)) {
            return (
                <div className={'row'}>
                                        <span className={'col-3'} style={{
                                            marginBottom: 6,
                                            color: '#6C757D',
                                            fontSize: 12,
                                        }}>
                                                {workLogModel.getTitle(action)}
                                        </span>
                    {(!this.state.isEditing ||
                        (action !== WorkLogModel.DIAGNOSIS &&
                            action !== WorkLogModel.TASKS &&
                            action !== WorkLogModel.COMMENTS)) &&
                    <div className={'column col-12'}>
                        {data.map((result) => (
                            <div className={'row'}>
                                {/*<span key={result.id} style={{*/}
                                {/*        color: '#6C757D',*/}
                                {/*        fontSize: 12,*/}
                                {/*}}>*/}
                                {/*        {action === "TASKS" ? util.getMoment(result.time).format("YYYY-MM-DD HH:mm") : ""}*/}
                                {/*</span>*/}
                                {(util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(action)]) &&
                                JSON.parse(result.data)[workLogModel.nameForData(action)].reverse().map((obj) => (
                                    <>
                                        {(obj.id == '0' && util.hasValue(obj.comment)) &&
                                        <div className={'col-12'}>
                                                                        <span style={{
                                                                            marginBottom: 6,
                                                                            fontSize: 12,
                                                                            textTransform: 'none'
                                                                        }} key={obj.id}>
                                                                                 <span className={' table-comment'}>     <span>{obj.comment}</span></span>
                                                                        </span>
                                        </div>
                                        }
                                    </>
                                ))}
                                {(util.hasValue(result.data) && JSON.parse(result.data)[workLogModel.nameForData(action)]) &&
                                JSON.parse(result.data)[workLogModel.nameForData(action)].reverse().map(obj => (
                                    <>
                                        {obj.id != '0' &&
                                        <div className={'col-12'}>
                                                                        <span style={{
                                                                            marginBottom: 6,
                                                                            color: '#6C757D',
                                                                            fontSize: 12,
                                                                            textTransform: 'none'
                                                                        }} key={obj.id}>
                                                                                {util.hasValue(obj.title) ? obj.title + '    ' : ''}
                                                                            {OFFER.code_partes_trabajo && util.hasValue(obj.code) && obj.code + '    '}
                                                                            {typeof obj.value === 'boolean' ? (obj.value ? 'Si' : 'No') : (obj.value)}
                                                                        </span>
                                        </div>
                                        }
                                    </>
                                ))}
                            </div>
                        ))}
                    </div>}
                    {(action === WorkLogModel.DIAGNOSIS && editable && this.state.isEditing) &&
                    <SelectDiagnostic objDict={this.objDict[WorkLogModel.DIAGNOSIS]}
                                                        onChangeObjDict={(objDict) => this.onChangeObjDict(objDict, WorkLogModel.DIAGNOSIS)}
                                                        {...this.props} />
                    }

                    {(action === WorkLogModel.TASKS && editable && this.state.isEditing) &&
                    <SelectTasks objDict={this.objDict[WorkLogModel.TASKS]}
                                             onChangeObjDict={(objDict) => this.onChangeObjDict(objDict, WorkLogModel.TASKS)}
                                             {...this.props} />
                    }
                    {(action === WorkLogModel.COMMENTS && editable && this.state.isEditing) &&
                    <SelectComment objDict={this.objDict[WorkLogModel.COMMENTS]}
                                                 onChangeObjDict={(objDict) => this.onChangeObjDict(objDict, WorkLogModel.COMMENTS)}
                                                 {...this.props} />
                    }
                </div>

            )
        }
    }

    renderArticlesUsed (workOrder) {
        const t = translate
        let articles = workOrder.workOrderCartArticle?.filter(item => item.slotId === this.props.slot.id)

        if (articles?.length === 0) {
            return null
        }
        return (
            <div className={'worklog-div'}>
                <div className={'worklog-resume'}>
                    <div className={'row'}>
                        <div className={'col-3'}
                                 style={{
                                     marginBottom: 6,
                                     color: '#6C757D',
                                     fontSize: 12,
                            }}>{t('PIEZAS USADAS') + ':'}
                        </div>
                        <div className={'column col-9'}>
                            {articles.map((workOrderArticle) => (
                                <div style={{
                                    marginBottom: 6,
                                    color: '#6C757D',
                                    fontSize: 12,
                                }}>
                                    {workOrderArticle.articleCode}
                                    {workOrderArticle.stockLote && ' - ' + t('Lote') + ': ' + workOrderArticle.stockLote}
                                    {workOrderArticle.description && ' - ' + workOrderArticle.description}{': '}{workOrderArticle.quantity}
                                    {util.hasValue(workOrderArticle.article?.saleUnit) ? ' ' + t(workOrderArticle.article?.saleUnit)
                                        : ' '}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onEditRow () {
        this.setState({ isEditing: true })
        this.props.setHasSomeRowEditing(true)
    }

    downloadPDFSO (slot, salesOrderId) {
        let slotModel = new SlotModel()
        return slotModel.downloadPDFSO(slot.id, salesOrderId)
    }

    render () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let workLogs = []
        if (this.props.workLogs && this.props.workLogs.length > 0) {
            workLogs = this.props.workLogs
        }
        let workOrder = this.props.workOrder
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(workOrder.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(workOrder.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(workOrder.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(workOrder.type).config)
        }

        let configQualityMatch = false
        let isFromForms = false
        let flavorPlanogramMatch = false
        let objFlavorQuality = {
            equipmentFamilyType: '',
            sapNotifType: '',
            workOrderType: workOrder.type,
            workOrderSubType: workOrder.subType,
            assetModelId: workOrder.asset?.assetModel?.id,
            assetType: workOrder.asset?.type,
            assetSubType: workOrder.asset?.subType,
        }
        if (appState.configState.getOptionFromCustomMobileFlavorQuality(objFlavorQuality)?.qualityInfo?.length > 0) {
            configQualityMatch = true
        }
        if (appState.configState.getOptionFromCustomMobileFlavorQuality(objFlavorQuality)?.productInfo?.length > 0) {
            flavorPlanogramMatch = true
        }
        if (appState.formAssignmentState.getOptionsFromFormAssignment(objFlavorQuality)?.length > 0) {
            isFromForms = true
        }
        let usuario = appState.userCacheState.getUserName(this.props.slot.userId)
        let title = 'Visita ' + this.props.slot.code
        if (util.hasValue(usuario)) {
            title += ' de ' + usuario
        }
        title += ' a las ' + util.localizeIsoDateHour(this.workLogUpdate[WorkLogModel.START_VISIT]?.time)
        let options = []
        for (let obj of appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_WORKORDER_STATUS)) {
            if (obj.code !== WorkLogModel.PENDING) {
                options.push({
                    value: obj.code,
                    label: obj.label
                })
            }
        }

        let slot = this.props.slot
        let salesOrderId = ''
        if (slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS && util.hasValue(slot.integrationErpMessage)) {
            salesOrderId = slot.integrationErpMessage?.split(workOrder.code + ': ')[1]
            salesOrderId = salesOrderId?.split(' ')[0]
        }
        return (
            <InfoBlock componentObject={this} wrapper="row">
                <div className="col-12">
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                    <GroupOfField
                        title={title}
                        icon="fas fa-file" />
                    <div className="row">
                        {!appState.userState.isUserRoleClient() &&
                        <>
                            <DateInputHoursMobiscroll
                                value={this.workLogUpdate[WorkLogModel.START_VISIT]?.time}
                                prefix={'fas fa-calendar-alt'}
                                title={t('Fecha Inicio Visita')}
                                allowEmptyDate={true}
                                relatedInfo={util.hasValue(this.workLogUpdate[WorkLogModel.START_VISIT]?.data) && util.hasValue(JSON.parse(this.workLogUpdate[WorkLogModel.START_VISIT]?.data)?.backEndModifiedByEmail) && t('Editado por') + ' ' + JSON.parse(this.workLogUpdate[WorkLogModel.START_VISIT]?.data)?.backEndModifiedByEmail}
                                editable={this.state.isEditing}
                                onChange={(e) => this.onChangeWorkLogDict(WorkLogModel.START_VISIT, e)}
                                name={WorkLogModel.START_VISIT}
                                classGroup={'col-xs-4 col-lg-4'}
                            />
                            <DateInputHoursMobiscroll
                                value={this.workLogUpdate[WorkLogModel.END_VISIT]?.time}
                                prefix={'fas fa-calendar-alt'}
                                title={t('Fecha Fin Visita')}
                                allowEmptyDate={true}
                                relatedInfo={util.hasValue(this.workLogUpdate[WorkLogModel.END_VISIT]?.data) && util.hasValue(JSON.parse(this.workLogUpdate[WorkLogModel.END_VISIT]?.data)?.backEndModifiedByEmail) && t('Editado por') + ' ' + JSON.parse(this.workLogUpdate[WorkLogModel.END_VISIT]?.data)?.backEndModifiedByEmail}
                                editable={this.state.isEditing}
                                onChange={(e) => this.onChangeWorkLogDict(WorkLogModel.END_VISIT, e)}
                                name={WorkLogModel.END_VISIT}
                                classGroup={'col-xs-4 col-lg-4'}
                            />
                        </>
                        }
                        <div className="col-4">
                            {util.hasValue(salesOrderId) &&
                            <div className={'mt-3'}>
                                <a href={this.downloadPDFSO(this.props.slot, salesOrderId)} target={'_blank'}
                                     className={'col-12'}>
                                    <VsfButtonNew className="btn btn--orange col-12"
                                                                onClick={() => this.downloadPDFSO(this.props.slot, salesOrderId)}>
                                        {t('Descargar Parte de Trabajo')}
                                    </VsfButtonNew>
                                </a>
                            </div>
                            }
                        </div>
                        {!appState.userState.isUserRoleClient() &&
                        <>
                            {this.props.slot.slotUserRole == SlotModel.MAIN &&
                                <>
                                    <DateInputHoursMobiscroll
                                        value={this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.time}
                                        prefix={'fas fa-calendar-alt'}
                                        title={t('Fecha Inicio de OT')}
                                        allowEmptyDate={true}
                                        relatedInfo={util.hasValue(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.data) && util.hasValue(JSON.parse(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.data)?.backEndModifiedByEmail) && t('Editado por') + ' ' + JSON.parse(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.data)?.backEndModifiedByEmail}
                                        editable={this.state.isEditing}
                                        onChange={(e) => this.onChangeWorkLogDict(WorkLogModel.START_WORK_ORDER, e)}
                                        name={WorkLogModel.START_WORK_ORDER}
                                        classGroup={'col-xs-4 col-lg-4'}
                                    />
                                    <DateInputHoursMobiscroll
                                        value={this.workLogUpdate['FIN_OT']?.time}
                                        prefix={'fas fa-calendar-alt'}
                                        title={t('Fecha Fin de OT')}
                                        allowEmptyDate={true}
                                        relatedInfo={util.hasValue(this.workLogUpdate['FIN_OT']?.data) && util.hasValue(JSON.parse(this.workLogUpdate['FIN_OT']?.data)?.backEndModifiedByEmail) && t('Editado por') + ' ' + JSON.parse(this.workLogUpdate['FIN_OT']?.data)?.backEndModifiedByEmail}
                                        editable={this.state.isEditing}
                                        onChange={(e) => this.onChangeWorkLogDict('FIN_OT', e)}
                                        name={'FIN_OT'}
                                        classGroup={'col-xs-4 col-lg-4'}
                                    />
                                </>
                            }
                            <Select2Component
                                title={t('Estado')}
                                value={this.workLogUpdate['FIN_OT']?.action}
                                name={'status'}
                                editable={this.state.isEditing}
                                isClearable={false}
                                onChange={(e) => this.onChangeWorkLogDictStatus('FIN_OT', e)}
                                classGroup={'col-xs-4 col-lg-4'}
                                options={options}
                            />

                        </>
                        }
                    </div>
                    {this.props.slot.slotUserRole == SlotModel.MAIN &&
                        <>
                            {this.renderData(workLogs, 'COMMENTS', true)}
                            {this.renderData(workLogs, 'DIAGNOSIS', config?.diagnosis === '1')}
                            {this.renderData(workLogs, 'TASKS', config?.tasks === '1')}
                            {this.renderData(workLogs, 'WORK_ORDER_TEST_QUALITY', (configQualityMatch || isFromForms))}
                            {this.renderFlavorData(workLogs, 'FLAVOR_PLANOGRAM', flavorPlanogramMatch)}
                            {this.renderData(workLogs, 'CHANGE_REASONS')}
                            {this.renderArticlesUsed(workOrder)}
                        </>
                    }
                    {this.state.isEditing &&
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <VsfButtonNew
                                label={t('Cancelar')}
                                onClick={() => {
                                    this.setState({ isEditing: false, })
                                    this.props.setHasSomeRowEditing(false)

                                }}
                                className={'btn btn-secondary'}
                            />
                        </div>
                        <div className={'col-6 text-right'}>
                            <VsfButtonNew
                                label={t('Guardar')}
                                onClick={() => this.saveResults()}
                                loading={this.state.saving}
                                className={'btn btn--red'}
                            />
                        </div>
                    </div>
                    }
                    {appState.userState.isUserRoleAdminOrSuperAdmin() &&
                    <>
                        {(!this.state.isEditing && !this.props.hasSomeRowEditing) &&
                        <VsfButtonNew
                            label={t('Editar visita')}
                            onClick={() => this.onEditRow()}
                            className={'btn btn--red'}
                        />
                        }
                    </>
                    }
                </div>
            </InfoBlock>
        )
    }

    validateWorkLogs () {
        let startVisitTime = this.workLogUpdate[WorkLogModel.START_VISIT]?.time;
        let endVisitTime = this.workLogUpdate[WorkLogModel.END_VISIT]?.time;
        //debug.d({endVisitTime,startVisitTime});
        //this.log({"msg":"validateWorkLogs",endVisitTime,startVisitTime, "keys":Object.keys(this.workLogUpdate), workLogUpdate:toJS(this.workLogUpdate)} );
        let message = '';
        let workLogModel = new WorkLogModel()
        //this.log({ workLogUpdate: this.workLogUpdate })
        for (let action of Object.keys(this.workLogUpdate)) {
            if (action !== WorkLogModel.START_VISIT && action !== WorkLogModel.FIN_OT) {
                //this.log({ msg: "validateWorkLogs", action:action, finot: WorkLogModel.FIN_OT, starvisit:WorkLogModel.START_VISIT  })
                let timeAction = this.workLogUpdate[action]?.time
                if (util.getMoment(startVisitTime).format('YYYYMMDDHHmm') > util.getMoment(timeAction).format('YYYYMMDDHHmm')) {
                    this.log({
                        startVisitTime: util.getMoment(startVisitTime).format('YYYYMMDDHHmm'),
                        timeAction: util.getMoment(timeAction).format('YYYYMMDDHHmm')
                    })
                    message = 'La fecha de inicio de debe ser menor que ' + workLogModel.getTitle(action)
                }
                if (util.getMoment(endVisitTime).format('YYYYMMDDHHmm') < util.getMoment(timeAction).format('YYYYMMDDHHmm')) {
                    this.log({
                        endVisitTime: util.getMoment(endVisitTime).format('YYYYMMDDHHmm'),
                        timeAction: util.getMoment(timeAction).format('YYYYMMDDHHmm')
                    })
                    message = 'La fecha de fin de visita debe ser mayor que ' + workLogModel.getTitle(action);
                }
            }
        }
        if (util.getMoment(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.time)
            .format('YYYYMMDDHHmm') > util.getMoment(this.workLogUpdate['FIN_OT']?.time).format('YYYYMMDDHHmm')) {
            message = 'La fecha de inicio de OT debe ser menor que la de fin'
        }
        if (this.props.slot.slotUserRole==SlotModel.MAIN) {
            if (!util.hasValue(this.workLogUpdate[WorkLogModel.START_VISIT]) ||
                !util.hasValue(this.workLogUpdate[WorkLogModel.END_VISIT]) ||
                !util.hasValue(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]) ||
                !util.hasValue(this.workLogUpdate['FIN_OT'])) {
                message = 'Debe introducir todas las fechas'
            }
        } else if (this.props.slot.slotUserRole==SlotModel.SUPPORT) {
            if (!util.hasValue(this.workLogUpdate[WorkLogModel.START_VISIT]) ||
                !util.hasValue(this.workLogUpdate[WorkLogModel.END_VISIT])
                ) {
                message = 'Debe introducir fechas de inicio de visita y fin de visita'
            }
        }
        if (util.hasValue(message)) {
            throw new GraphException({
                message,
                errors: [{ message }]
            })
        }
    }

    async saveResults () {
        this.setState({ saving: true })
        let arrayItems = []
        try {
            this.validateWorkLogs()
            if (util.hasValue(this.workLogUpdateChange[WorkLogModel.START_VISIT]?.time)) {
                this.log({
                    time: this.workLogUpdateChange[WorkLogModel.START_VISIT].time,
                    replace: this.workLogUpdateChange[WorkLogModel.START_VISIT]?.time.replace('000Z', '01Z')
                })
                this.workLogUpdateChange[WorkLogModel.START_VISIT].time = this.workLogUpdateChange[WorkLogModel.START_VISIT]?.time.replace('000Z', '01Z')
                await appState.workLogState.startClient(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.workLogUpdateChange[WorkLogModel.START_VISIT])
            }
            if (util.hasValue(this.workLogUpdateChange[WorkLogModel.START_WORK_ORDER]?.time)) {
                this.workLogUpdateChange[WorkLogModel.START_WORK_ORDER].time = this.workLogUpdateChange[WorkLogModel.START_WORK_ORDER]?.time.replace('000Z', '11Z')
                await appState.workLogState.startWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot, this.props.workOrder, this.workLogUpdateChange[WorkLogModel.START_WORK_ORDER]?.time, this.workLogUpdateChange[WorkLogModel.START_WORK_ORDER])
            }
            let time = util.getMoment(this.workLogUpdate[WorkLogModel.START_WORK_ORDER]?.time)
            for (let action of Object.keys(this.objDictChange)) {
                if (!util.hasValue(this.workLogUpdate[action]?.time)) {
                    if (this.workLogUpdate[action] == null) {
                        this.workLogUpdate[action] = {}
                    }
                    time.add(1, 'minutes')
                    this.workLogUpdate[action].time = time
                }
                let arrayItems = []
                for (let itemId of Object.keys(this.objDictChange[action]).reverse()) {
                    arrayItems.push(this.objDictChange[action][itemId])
                }
                if (util.hasValue(this.workLogUpdate[action]?.time) && action === WorkLogModel.DIAGNOSIS) {
                    await appState.workLogState.insertDiagnosisWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.props.workOrder, arrayItems, this.workLogUpdate[action])
                } else if (util.hasValue(this.workLogUpdate[action]?.time) && action === WorkLogModel.TASKS) {
                    await appState.workLogState.insertTasksWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.props.workOrder, arrayItems, this.workLogUpdate[action])
                } else if (util.hasValue(this.workLogUpdate[action]?.time) && action === WorkLogModel.COMMENTS) {
                    await appState.workLogState.insertCommentsWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.props.workOrder, arrayItems, this.workLogUpdate[action])
                } else if (util.hasValue(this.workLogUpdate[action]?.time) && action === WorkLogModel.COMMENTS_DISPATCHER) {
                    await appState.workLogState.insertCommentsDispatcherWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.props.workOrder, arrayItems, this.workLogUpdate[action])
                } else if (util.hasValue(this.workLogUpdate[action]?.time) && action === WorkLogModel.WARRANTY_POSSIBLE) {
                    await appState.workLogState.insertWarrantyPossibleWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot.id, this.props.workOrder, arrayItems, this.workLogUpdate[action])
                }
            }
            if (util.hasValue(this.workLogUpdateChange['FIN_OT']?.time)) {
                this.workLogUpdateChange['FIN_OT'].time = this.workLogUpdateChange['FIN_OT']?.time.replace('000Z', '21Z')
                if (this.workLogUpdateChange['FIN_OT']?.action === WorkLogModel.END_WORK_ORDER) {
                    await appState.workLogState.endWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot, this.props.workOrder, this.workLogUpdateChange['FIN_OT'])
                } else if (this.workLogUpdateChange['FIN_OT']?.action === WorkLogModel.RETURN_WORK_ORDER) {
                    let causeOfPause = []
                    await appState.workLogState.returnWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot, this.props.workOrder, causeOfPause, this.workLogUpdateChange['FIN_OT']?.time, this.workLogUpdateChange['FIN_OT'])
                } else if (this.workLogUpdateChange['FIN_OT']?.action === WorkLogModel.PAUSE_WORK_ORDER) {
                    await appState.workLogState.pauseProcessWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot, this.props.workOrder, this.workLogUpdateChange['FIN_OT'])

                }
                this.log({
                    finllamada: 1,
                })
            }
            if (util.hasValue(this.workLogUpdateChange[WorkLogModel.END_VISIT]?.time)) {
                let dataToSave = {
                    satisfaction: -1,
                    startDayId: this.workLogUpdateChange[WorkLogModel.START_VISIT]?.id,
                    clientSign: false,
                }
                this.workLogUpdateChange[WorkLogModel.END_VISIT].time = this.workLogUpdateChange[WorkLogModel.END_VISIT]?.time.replace('000Z', '99Z')
                await appState.workLogState.endClientWorkOrder(this.props.slot.userId, this.props.slot.clientId, this.props.slot.orderId, this.props.slot, dataToSave, this.workLogUpdateChange[WorkLogModel.END_VISIT]?.time, this.workLogUpdateChange[WorkLogModel.END_VISIT])
            }
            await this.props.reloadData()
            this.setState({
                isEditing: false,
                saving: false
            })
            this.props.setHasSomeRowEditing(false)
            await this.loadWorkLogsOfWorkOrder()
        } catch (e) {
            this.setState({ saving: false })
            this.gqlErrors = new GraphException().getErrorsFromException(e)
            this.log({ ERROR: e })
        }
        this.log({
            saveResult: 1,
            objData: this.objDictChange,
            arrayItems,
            workLogUpdateChange: this.workLogUpdateChange,
            workLogUpdate: this.workLogUpdate
        })

    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default WorkOrderSummaryWorkLogs
