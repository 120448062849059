import appState from '../state/AppState'
import OFFER from '../config/OFFER'
import AppLogger from '../util/AppLogger'

export default class TabsData {

    isClient = false
    isReadableAsset = false
    isReadableDashboard = true
    isReadableSlot = false

    getDataWorkOrder (cocaCola) {
        let tabs = [
            {
                id: '1',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/workOrder/form/',
                valuePage: '',
                rightModalTab: 'workorder'
            },
        ]
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '2',
                name: 'Planificación',
                label: 'Planificación',
                value: '/workOrder/form/',
                valuePage: '/assigment',
                rightModalTab: 'schedule',
                alarm: appState.assetState.workOrderSlots
            },)
        }
        tabs.push({
            id: '3',
            name: 'Piezas',
            label: 'Piezas',
            value: '/workOrder/form/',
            valuePage: '/items',
            rightModalTab: 'articles',
            alarm: appState.assetState.workOrderArticlesLength
        })
        if (!cocaCola && !appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '208',
                name: 'Presupuestos',
                label: 'Presupuestos ',
                value: '/workOrder/form/',
                valuePage: '/budget',
                rightModalTab: 'budget',
                alarm: appState.assetState.workOrderBudgets
            })
        }
        tabs.push({
            id: '7',
            name: 'Visitas',
            label: 'Visitas',
            value: '/workOrder/form/',
            valuePage: '/workLogSummary',
            rightModalTab: 'workLogSummary'
        })
        tabs.push(
            {
                id: '5',
                name: 'Documentos',
                label: 'Documentos',
                value: '/workOrder/form/',
                valuePage: '/document',
                rightModalTab: 'document',
                alarm: appState.assetState.workOrderDocuments
            },
        )
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '6',
                name: 'Campos dinamicos',
                label: 'Campos dinamicos',
                value: '/workOrder/form/',
                valuePage: '/dynamicGroups',
                rightModalTab: 'dynamicGroups'
            })
        }
        return tabs
    }

    getDataClient () {
        let tabs = [
            {
                id: '110',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/client/form/',
                valuePage: '',
                rightModalTab: 'general',
                alarmExclamation: appState.changesWithValidationsState.formValidations
            },
            {
                id: '112',
                name: 'Contactos',
                label: 'Contactos',
                value: '/client/form/',
                valuePage: '/contacts',
                rightModalTab: 'contacts'
            },
            ,
            {
                id: '114',
                name: 'Documentos',
                label: 'Documentos',
                value: '/client/form/',
                valuePage: '/document',
                rightModalTab: 'document',
                alarmExclamation: appState.changesWithValidationsState.imageValidations
            }]
        return tabs
    }

    getDataPosClient () {
        let tabs = [
            {
                id: '110',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/clientPos/form/',
                valuePage: '',
                rightModalTab: 'general',
                alarmExclamation: appState.changesWithValidationsState.formValidations
            },

        ]
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '101',
                name: 'Preventivas',
                label: 'Preventivas',
                value: '/clientPos/form/',
                valuePage: '/prevent',
                rightModalTab: 'prevent'
            },)
        }
        tabs.push({
            id: '112',
            name: 'Contactos',
            label: 'Contactos',
            value: '/clientPos/form/',
            valuePage: '/contacts',
            rightModalTab: 'contacts'
        },
        {
            id: '114',
            name: 'Documentos',
            label: 'Documentos',
            value: '/clientPos/form/',
            valuePage: '/document',
            rightModalTab: 'document',
            alarmExclamation: appState.changesWithValidationsState.imageValidations
        })
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '115',
                name: 'Campos dinamicos',
                label: 'Campos dinamicos',
                value: '/clientPos/form/',
                valuePage: '/dynamicGroups',
                rightModalTab: 'dynamicGroups'
            })
        }

        return tabs
    }

    getDataWarehouse () {
        let tabs = [
            {
                id: '910',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/warehouse/form/',
                valuePage: '',
                rightModalTab: 'general'
            },
            OFFER.bin &&
            {
                id: '912',
                name: 'BINs',
                label: 'BINs',
                value: '/warehouse/form/',
                valuePage: '/binList',
                rightModalTab: 'binList'
            },

        ]
        return tabs
    }

    getDataOrigin () {
        let tabs = [
            {
                id: '910',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/origin/form/',
                valuePage: '',
                rightModalTab: 'general'
            },
            {
                id: '910',
                name: 'Maestros',
                label: 'Maestros',
                value: '/origin/form/',
                valuePage: '/typifiedList?type=workOrderType',
                rightModalTab: 'typifiedList?type=workOrderType'
            },

        ]
        return tabs
    }

    getDataDashboard () {
        let tabs = []
        if (this.isReadableDashboard) {
            tabs.push({
                id: '910',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/',
                valuePage: '',
            }
            )
        }
        if (this.isReadableAsset) {
            tabs.push({
                id: '910',
                name: 'Datos Equipo',
                label: 'Datos Equipo',
                value: '/dashboardAsset/',
                valuePage: '',
            }
            )
        }
        if (this.isReadableSlot) {
            tabs.push(
                {
                    id: '910',
                    name: 'Datos Visita',
                    label: 'Datos Visita',
                    value: '/dashboardSlots/',
                    valuePage: '',
                })
        }
        return tabs
    }

    getDataOrder (cocaCola) {
        let tabs = [
            {
                id: '200',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/order/form/',
                valuePage: '',
                rightModalTab: 'order'
            },

        ]
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '202',
                name: 'Planificación',
                label: 'Planificación',
                value: '/order/form/',
                valuePage: '/slots',
                rightModalTab: 'slots',
                alarm: appState.assetState.orderSlots
            })
        }
        tabs.push({
            id: '201',
            name: 'Órdenes de Trabajo',
            label: 'Órdenes de Trabajo',
            value: '/order/form/',
            valuePage: '/workOrder',
            rightModalTab: 'workorders',
            alarmExclamation: appState.assetState.workOrdersNotAsigned,
            alarm: appState.assetState.orderWorkOrders
        },
        {
            id: '203',
            name: 'Piezas',
            label: 'Piezas',
            value: '/order/form/',
            valuePage: '/articles',
            rightModalTab: 'articles',
            alarm: appState.assetState.orderArticlesLength
        }
        )
        if (!cocaCola && !appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '208',
                name: 'Presupuestos',
                label: 'Presupuestos ',
                value: '/order/form/',
                valuePage: '/budget',
                rightModalTab: 'budget',
                alarm: appState.assetState.orderBudgets
            })
        }
        tabs.push({
            id: '206',
            name: 'Partes de trabajo',
            label: 'Partes de trabajo',
            value: '/order/form/',
            valuePage: '/summary',
            rightModalTab: 'summary'
        },
        {
            id: '205',
            name: 'Documentos',
            label: 'Documentos',
            value: '/order/form/',
            valuePage: '/document',
            rightModalTab: 'document',
            alarm: appState.assetState.orderDocuments
        },
        )
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '207',
                name: 'Campos dinamicos',
                label: 'Campos dinamicos',
                value: '/order/form/',
                valuePage: '/dynamicGroups',
                rightModalTab: 'dynamicGroups'
            })
        }
        return tabs
    }

    getDataOrderExternal () {
        let tabs = [
            {
                id: '200',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/order/form/',
                valuePage: '',
                rightModalTab: 'order'
            }]
        return tabs
    }

    getDataArticles () {
        let tabs = [
            {
                id: '300',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/article/form/',
                valuePage: '',
                rightModalTab: 'articles'
            },
            {
                id: '301',
                name: 'Ubicación',
                label: 'Ubicación',
                value: '/article/form/',
                valuePage: '/location',
                rightModalTab: 'location'
            },
        ]
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '301',
                name: 'Consumos',
                label: 'Consumos',
                value: '/article/form/',
                valuePage: '/workOrderCartList',
                rightModalTab: 'workOrderCartList'
            },)
        }
        tabs.push({
            id: '303',
            name: 'Documentos',
            label: 'Documentos',
            value: '/article/form/',
            valuePage: '/document',
            rightModalTab: 'document',
        })
        return tabs
    }

    getDataStocks () {
        let tabs = [
            {
                id: '310',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/stock/form/',
                valuePage: '',
                rightModalTab: 'stocks'
            },
            /*{
                id: "301",
                name: "Ubicación",
                label: "Ubicación",
                value: "/stock/form/",
                valuePage: "/location",
                rightModalTab: "location"
            },
            {
                id: "301",
                name: "Consumos",
                label: "Consumos",
                value: "/stock/form/",
                valuePage: "/workOrderCartList",
                rightModalTab: "workOrderCartList"
            },
            {
                id: "303",
                name: "Documentos",
                label: "Documentos",
                value: "/stock/form/",
                valuePage: "/document",
                rightModalTab: "document",
            }*/
        ]
        return tabs
    }

    getDataAssets (typeAssetPlanogram) {
        let tabs = [
            {
                id: '400',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/asset/form/',
                valuePage: '',
                rightModalTab: 'general',
                alarmExclamation: appState.changesWithValidationsState.formValidations
            },
            {
                id: '403',
                name: 'Gestión de Garantías',
                label: 'Gestión de Garantías',
                value: '/asset/form/',
                valuePage: '/warranty',
                rightModalTab: 'warranty'
            },
            {
                id: '301',
                name: 'Órdenes de trabajo',
                label: 'Órdenes de trabajo',
                value: '/asset/form/',
                valuePage: '/workOrderList',
                rightModalTab: 'workOrderList'
            },
        ]
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '301',
                name: 'Consumos',
                label: 'Consumos',
                value: '/asset/form/',
                valuePage: '/workOrderCartList',
                rightModalTab: 'workOrderCartList'
            })
        }
        if (typeAssetPlanogram && OFFER.planogram) {
            tabs.push({
                id: '301',
                name: 'Planograma',
                label: 'Planograma',
                value: '/asset/form/',
                valuePage: '/planogram',
                rightModalTab: 'planogram'
            })
        }
        tabs.push({
            id: '405',
            name: 'Documentos',
            label: 'Documentos',
            value: '/asset/form/',
            valuePage: '/document',
            rightModalTab: 'document',
            alarm: appState.assetState.assetDocuments,
            alarmExclamation: appState.changesWithValidationsState.imageValidations
        },)
        if (!appState.userState.isUserRoleClient()) {
            tabs.push({
                id: '406',
                name: 'Campos dinamicos',
                label: 'Campos dinamicos',
                value: '/asset/form/',
                valuePage: '/dynamicGroups',
                rightModalTab: 'dynamicGroups'
            })
        }
        return tabs
    }

    getDataAssetModels () {
        let tabs = [
            {
                id: '710',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/assetModel/form/',
                valuePage: '',
                rightModalTab: 'general'
            },
            {
                id: '715',
                name: 'Documentos',
                label: 'Documentos',
                value: '/assetModel/form/',
                valuePage: '/document',
                rightModalTab: 'document'
            },
        ]
        return tabs
    }

    getDataScheduleVisit () {
        let tabs = [
            {
                id: '600',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/schedule/visit/type/form/',
                valuePage: ''
            },
            {
                id: '601',
                name: 'Documentos',
                label: 'Documentos',
                value: '/schedule/visit/type/form/',
                valuePage: '/document'
            },
        ]
        return tabs
    }

    getDataGroups () {
        let tabs = [
            {
                id: '800',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/config/dynamicGroups/form/',
                valuePage: ''
            },
        ]
        return tabs
    }

    getDataBudget () {
        let tabs = [
            {
                id: '610',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/budget/form/',
                valuePage: '',
                rightModalTab: 'budgetForm'
            },
        ]
        return tabs
    }

    getDataVehicle () {
        let tabs = [
            {
                id: '610',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/vehicle/form/',
                valuePage: ''
            },
            {
                id: '611',
                name: 'Kilometraje',
                label: 'Kilometraje',
                value: '/vehicle/form/',
                valuePage: '/vehicleKmLogs',
                rightModalTab: 'vehicleKmLogs'
            },
            {
                id: '602',
                name: 'Documentos',
                label: 'Documentos',
                value: '/vehicle/form/',
                valuePage: '/document',
                rightModalTab: 'document'
            },
        ]
        return tabs
    }

    getDataUser () {
        let tabs = [
            {
                id: '700',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/user/form/',
                valuePage: '',
                rightModalTab: 'general'
            }]
        if (!this.isClient) {
            tabs.push({
                id: '700',
                name: 'Trabajos de campo',
                label: 'Trabajos de campo',
                value: '/user/form/',
                valuePage: '/userFieldWorks',
                rightModalTab: 'fieldwork'
            }, {
                id: '703',
                name: 'Capacitación',
                label: 'Capacitación',
                value: '/user/form/',
                valuePage: '/userCapacitation',
                rightModalTab: 'capacitation'
            },
            {
                id: '705',
                name: 'Disponibilidad',
                label: 'Disponibilidad',
                value: '/user/form/',
                valuePage: '/userAvailability',
                rightModalTab: 'availability'
            },
            {
                id: '706',
                name: 'Fichajes',
                label: 'Fichajes',
                value: '/user/form/',
                valuePage: '/userClockingInTable',
                rightModalTab: 'clockingInTable'
            })
        }
        tabs.push(
            {
                id: '702',
                name: 'Documentos',
                label: 'Documentos',
                value: '/user/form/',
                valuePage: '/document',
                rightModalTab: 'document'
            })
        return tabs
    }

    getDataSla () {
        let tabs = [
            {
                id: '810',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/sla/form/',
                valuePage: '',
                rightModalTab: 'general'
            }]
        return tabs
    }

    getDataSubcontract () {
        let tabs = [
            {
                id: '820',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/subcontract/form/',
                valuePage: '',
                rightModalTab: 'general'
            },
            {
                id: '821',
                name: 'Contactos',
                label: 'Contactos',
                value: '/subcontract/form/',
                valuePage: '/contacts',
                rightModalTab: 'contacts'
            },

            , {
                id: '822',
                name: 'Documentos',
                label: 'Documentos',
                value: '/subcontract/form/',
                valuePage: '/document',
                rightModalTab: 'document'
            }]
        return tabs
    }

    getDataSlot () {
        let tabs = [
            {
                id: '830',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/slot/form/',
                valuePage: '',
                rightModalTab: 'general'
            },
            {
                id: '203',
                name: 'Piezas',
                label: 'Piezas',
                value: '/slot/form/',
                valuePage: '/articles',
                rightModalTab: 'articles',
                // alarm: appState.assetState.orderArticles
            },
        ]
        return tabs
    }

    getDataStockMovement () {
        let tabs = [{
            id: '940',
            name: 'Datos Generales',
            label: 'Datos Generales',
            value: '/movementStock/form/',
            valuePage: '',
            rightModalTab: 'general'
        }]
        return tabs
    }

    getDataBin () {
        let tabs = [
            {
                id: '930',
                name: 'Datos Generales',
                label: 'Datos Generales',
                value: '/bin/form/',
                valuePage: '',
                rightModalTab: 'general'
            }]
        return tabs
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
