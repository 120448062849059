import React, {Component} from 'react';
import {observer} from 'mobx-react';

@observer
export default class IconsInputsComponent extends Component {

    onClickIcon() {
        if (this.props.onClickIcon != null) {
            this.props.onClickIcon();
        }
    }

    render() {
        let props = this.props;
        let styleCalendar = props.readOnly ? "" : "";
        if (props.onClickIcon != null) {
            styleCalendar += " text-click "
        }
        if (props.styleCalendar) {
            styleCalendar = "style-calendar-div-input-datepicker ";
        }
        let postfixClass = "input-group-text";
        if (props.postfixClass) {
            postfixClass = props.postfixClass;
        }
        let prefixClass = "input-group-text";
        if (props.prefixClass) {
            prefixClass = props.prefixClass;
        }

        return (
            <div className={'input-group'} style={{width: 'auto', flex: 5}}>
                {props.prefix &&
                <div className={"input-group-prepend " + styleCalendar} onClick={() => this.onClickIcon()}>
                                <span className={prefixClass} style={{color: props.color}}>
                                    <span className={props.prefix}/>
                                </span>
                </div>
                }
                {props.children}
                {props.postfix &&
                <div className={"input-group-prepend " + styleCalendar} onClick={() => this.onClickIcon()}>
                               <span className={postfixClass}>
                                    <span className={props.postfix}/>
                                </span>
                </div>
                }
            </div>
        );
    }

}
