import React, { Component } from 'react'
import { observer } from 'mobx-react'
import AppLogger from '../util/AppLogger'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

@observer
class SortableItem extends Component<props> {

  onClick (value) {
    if (this.props.onClickSortableItem != null) {
      this.props.onClickSortableItem(value)
    }
  }

  render () {
    let row = this.props.row
    let index = this.props.index2
    //this.log({ SortableItem: 1, props: this.props, me: this });

    let className = 'non-selectable '
    if (this.props.classGroup) {
      className += ' ' + this.props.classGroup
    }
    let DragHandleComponent = () => {}
    if (this.props.showDragHandle) {
      DragHandleComponent = () => (<DragHandle index={index} />)
    }

    if (this.props.tableList) {
      return (
        <>
          {this.props.renderItem(row, index, DragHandleComponent)}
        </>
      )
    } else {
      return (
        <li className="drag-li">
          <div className={className} onClick={() => this.onClick(row)}>
            {this.props.showDragHandle &&
            <DragHandle index={index} />
            }
            {this.props.renderItem(row, index)}
          </div>
        </li>
      )
    }
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

}

export default SortableElement(SortableItem)

const DragHandle = SortableHandle(({ index }) => (
  <div className="dragHandle"  style={{ cursor: 'move' }}>
    <i className="fas fa-arrows"></i> {(index + 1)}.
  </div>
))
