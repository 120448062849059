import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import translate from '../translator/translate'
import OFFER from '../config/OFFER'
import AppLogger from '../util/AppLogger'
import { observable } from 'mobx'
import VsfButtonNew from '../components/VsfButtonNew'
import PropsUtil from '../util/PropsUtil'

@observer
class CreateNewButton extends Component {

    @observable newFormsArray = []

    constructor () {
        super()
    }

    async componentDidMount () {
        if (Object.keys(OFFER.creatable).length === 0) {
            let offer = new OFFER()
            offer.loadCreatables()
        }
        let result = []
        let formulariosHeader = [
            {
                title: 'Pedido',
                model: 'order',
                url: 'order'
            },
            {
                title: 'Cliente',
                model: 'client',
                url: 'client'
            },
            {
                title: 'Establecimiento',
                model: 'client',
                url: 'clientPos'
            },
            {
                title: 'Usuario',
                model: 'user',
                url: 'user'
            },
            {
                title: 'Equipo',
                model: 'asset',
                url: 'asset'
            },
            {
                title: 'Pieza',
                model: 'article',
                url: 'article'
            },
        ]
        for (let formulario of formulariosHeader) {
            if (OFFER.creatable?.[formulario.model]) {
                result.push(formulario)
            }
        }
        this.newFormsArray = result
    }

    render () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        this.log({render: this.props})
        return (
            <div className="header-new mr-2">
                {/*{this.newFormsArray.length > 0 &&*/}
                {/*<VsfButtonNew*/}
                {/*    label={t('Crear nuevo')} postIcon={"fas fa-caret-down ml-2"}*/}
                {/*    onClick={() => appState.layoutState.clickAddNew()}*/}
                {/*    className="btn btn--new"*/}
                {/*/>*/}
                {/*}*/}
                {/*{appState.layoutState.menuCreateNewActive &&*/}
                {/*<Animated style={{ zIndex: 99, position: 'absolute', top: 10, left: 0, right: 0 }} animationIn="zoomIn"*/}
                {/*          animationInDelay={150} animationInDuration={250} isVisible={true}>*/}
                {/*    <Overlay show={appState.layoutState.menuCreateNewActive} style={{ backgroundColor: 'transparent' }}*/}
                {/*             onClick={_ => appState.layoutState.menuCreateNewActive = false} />*/}
                {/*    <dl className="dropdown-sublist" style={{ top: 9, width: 220 }}>*/}
                {/*        {this.newFormsArray.map(modelForm =>*/}
                {/*            <dd className="dropdown-sublist__item" key={modelForm.title}>*/}
                {/*                <VsfLink onClick={_ => appState.layoutState.menuCreateNewActive = false}*/}
                {/*                         to={"/" + modelForm.url + "/form/"}>{t(modelForm.title)}</VsfLink></dd>*/}
                {/*        )*/}
                {/*        }*/}
                {/*    </dl>*/}
                {/*</Animated>*/}
                {/*}*/}
                <VsfButtonNew
                    onClick={() => this.propsUtil.changeUrlRequest({
                        "rightModal": 'modalorderls',
                        'rightModalTab': 'order',
                        "orderInitial": null
                    })} label={t('Crear pedido')}
                    showButton={OFFER.creatable['order'] || false}
                    className={'btn btn--red btn-padding-small '}
                    icon={'far fa-plus-square mr-1'}
                />
            </div>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default withRouter(CreateNewButton)
