import React, {Component} from 'react';
import VsfModal from "./modals/VsfModal";
import AppLogger from "../util/AppLogger";
import translate from "../translator/translate";
import VsfButtonNew from "./VsfButtonNew";
import {observer} from "mobx-react";
import appState from '../state/AppState';
import Select2Component from "./fields/Select2Component";
import GqlErrors from "./status/GqlErrors";
import {withRouter} from "react-router-dom";
import util from '../util/Util'
import {observable, toJS} from "mobx";
import InputTypeComponent from "./fields/InputTypeComponent";
import SlotModel from "../models/SlotModel";
import BudgetModel from "../models/BudgetModel";

@observer
class StatusOrderModal extends Component {

    @observable cause;
    @observable gqlErrors = [];

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            causesOfTypified: [],
            cause: {},
        }
    }

    async componentDidMount() {
        let order = this.props.orderModel;
        let notifType = "";
        if (this.props.cocaCola) {
            if (order?.dynamicField) {
                let camposDinamicos = JSON.parse(order?.dynamicField);
                notifType = camposDinamicos.sapNotifType?.toUpperCase();
            }
            let data = appState.configState.getPauseInfo(notifType);
            let causesOfTypifiedDict = this.ordenarByParentId(data);
            let causesOfTypified = this.getArrayObj(causesOfTypifiedDict);
            this.setState({ causesOfTypified });
        }

    }

    ordenarByParentId(array) {
        let result = {};
        for (let cancel of array) {
            let key = cancel.id || cancel.tagCode;
            if (util.hasValue(cancel.parentId) && cancel.parentId !== util.getZeroIdIdentifierGUID()) {
                key = cancel.parentId;
            }
            if (result[key] == null) {
                result[key] = [];
            }
            if (key !== cancel.tagCode) {
                let item = {
                    code: cancel.tagCode,
                    value: key === cancel.tagCode ? cancel.tagCode + " " + cancel.text : cancel.text || cancel.value,
                    parentId: key,
                    id: cancel.tagCode || cancel.id
                };
                result[key].push(item);
            }
        }
        this.log({ ordenarByParentId: 1, result });
        return result;
    }

    getArrayObj(obj) {
        let result = [];
        for (let key of Object.keys(obj)) {
            for (let item of obj[key]) {
                let obj = {};
                obj.label = item.value;
                obj.value = item.value;
                obj.item = item;
                result.push(obj)
            }
        }
        return result;
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async onChangeCause(event) {
        this.cause = event.target.value;
    }

    async saveStatusCancel() {
        this.setState({ loading: true });
        let order = this.props.orderModel;
        let userId = appState.loginState.getUserId();
        let clientId = order.clientId;
        let orderId = order.id;
        let cause = [];
        if (this.props.cocaCola) {
            for (let item of this.state.causesOfTypified) {
                if (item.value === this.cause) {
                    cause.push(item.item);
                }
            }
        } else {
            cause.push({ title: "cancelacion", value: this.cause });
        }
        await appState.workLogState.insertCancelOrder(userId, clientId, orderId, cause);
        try {
            for (let slot of this.props.orderModel.slot) {
                if (!slot.isFinished) {
                    let slotQuery = new SlotModel();
                    slotQuery.id = slot.id;
                    await slotQuery.remove();
                }
            }
        } catch (e) {
            this.log({ errorSaveStatus: 1, e })
        }
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.handleFormSubmit();
            this.props.closeModal();
        }, 3000)

    }

    async saveStatusNotApproved() {
        this.setState({ loading: true });
        let order = this.props.orderModel;
        let userId = appState.loginState.getUserId();
        let clientId = order.clientId;
        let orderId = order.id;
        let cause = [];
        cause.push({ title: "Motivo No Aprobado", value: this.cause });
        await appState.workLogState.insertNotApprovedOrder(userId, clientId, orderId, cause);
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.handleFormSubmit();
            this.props.closeModal();
        }, 3000)

    }

    isBudgetApproved() {
        let result = false;
        if (this.props.orderModel?.budget?.length > 0) {
            for (let budget of this.props.orderModel.budget) {
                if (budget.status === BudgetModel.CODE_APPROVED) {
                    result = true;
                }
            }
        }
        return result;
    }

    async saveStatus() {
        if (this.props.cancelModal) {
            await this.saveStatusCancel();
        } else if (this.props.notApprovedModal) {
            await this.saveStatusNotApproved();
        }
    }


    render() {
        const t = translate;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">
                        <div className="c-modal-info__cell__body">
                            {this.props.cancelModal &&
                            <>
                                {this.isBudgetApproved() &&
                                <p>{t("Este pedido tiene un prespuesto con estado Aprobado. Al cancelar el pedido se eliminará la posibilidad de planificar su visita")}</p>
                                }
                                <p>{t("Es necesario que incluya el motivo")}</p>
                                {this.props.cocaCola ?
                                    <Select2Component
                                        value={toJS(this.cause)}
                                        onChange={(e) => this.onChangeCause(e)}
                                        name={"status"}
                                        isClearable={false}
                                        classGroup={"col-12"}
                                        options={this.state.causesOfTypified}
                                    />
                                    :
                                    <InputTypeComponent
                                        value={this.cause}
                                        onChange={(e) => this.onChangeCause(e)}
                                        name={"externalCode"}
                                        classGroup={"col-12"}
                                        type={"text"}
                                    />
                                }
                            </>
                            }
                            {this.props.notApprovedModal &&
                            <>
                                <p>{t("Es necesario que incluya el motivo")}</p>
                                <InputTypeComponent
                                    value={this.cause}
                                    onChange={(e) => this.onChangeCause(e)}
                                    name={"externalCode"}
                                    classGroup={"col-12"}
                                    type={"text"}
                                />
                            </>
                            }

                        </div>
                    </div>

                    <div className="modal-footer ">
                        <div className="mt-4">
                            <div className="d-flex justify-content-end mb-2">
                                <VsfButtonNew
                                    label={this.props.textCancelar}
                                    className="btn btn-secondary"
                                    loading={this.state.loading}
                                    loadedError={this.gqlErrors.length !== 0}
                                    onClick={() => this.saveStatus()}
                                    disabled={!util.hasValue(this.cause)}
                                />
                                <div className="m-2" />
                                <VsfButtonNew
                                    label={this.props.textButton}
                                    className="btn btn--loader"
                                    onClick={this.props.discardModal}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(StatusOrderModal)
