import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import DynamicFieldsModel from "../models/DynamicFieldsModel";

class DynamicFieldsState extends BaseModelState {

    @observable dynamics = [];

    async getDynamicsDictGroup() {
        let dynamicModel = new DynamicFieldsModel();
        let dynamics = await dynamicModel.find();
        return this.getDictDynamicFieldsGroup(dynamics)

    }

    getDictDynamicFieldsGroup(arrayDynamics) {
        let result = {};
        for (let dynamic of arrayDynamics) {
            if (result[dynamic.code] == null) {
                result[dynamic.code] = {code:dynamic.groupId}
            }
        }
        return result;
    }
}

export default DynamicFieldsState;
