import React from 'react';
import {observer} from "mobx-react/index";
import PageTitle from "../../components/PageTitle"
import appState from '../../state/AppState';
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed} from 'mobx';
import BaseEditableTable from "../base/BaseEditableTable";
import util from "../../util/Util";
import GroupOfField from "../../components/layout/GroupOfField";
import OrderFormArticle from "./OrderFormArticle";
import WorkOrderCartArticleModel from "../../models/WorkOrderCartArticleModel";
import PropsUtil from "../../util/PropsUtil";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import OrderModel from "../../models/OrderModel";
import translate from "../../translator/translate";
import {withRouter} from "react-router-dom";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import MoreButtons from "../../components/MoreButtons";
import OrderActions from "./OrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";
import VsfButtonNew from "../../components/VsfButtonNew";

@observer
class OrderFormArticles extends BaseEditableTable {


    constructor(props) {
        super(props);
        this.nameMainType = "order";
        this.tabsData = new TabsData();
        this.foreingKeyfield = "orderId";
        this.state = {
            loading: true,
        }
        this.initializeGraphData(this.nameMainType);
    }


    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable();
        await appState.userCacheState.loadUserCacheTechnicals();
        this.setState({ loading: false })
    }


    async reloadTable() {
        appState.workOrderState.workOrders = this.graphDataMainType.workOrder;
        appState.slotsState.slots = this.graphDataMainType.slot;
        appState.workOrderCartArticlesState.workOrderArticles = this.graphDataMainType.workOrderCartArticle;
        appState.assetState.orderArticles = this.calculaCantidad();
    }


    @computed get mobxListado() {
        return appState.workOrderCartArticlesState.workOrderArticles;
    }

    setMobxListado(newValue) {
        appState.workOrderCartArticlesState.workOrderArticles = newValue;
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("slot");
        result.addRelatedTable("client");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    getModelTable() {
        return new WorkOrderCartArticleModel();
    }

    workOrderArticlesUsed() {
        let result = [];
        if (this.graphDataMainType?.workOrderCartArticle?.length != 0) {
            for (let article of this.graphDataMainType.workOrderCartArticle) {
                result.push(article);
            }
        }
        return result;
    }

    calculaCantidad() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (articulo.quantity != null) {
                if (result == 0)
                    result = (util.formatDecimal(articulo.quantity));
                else if (articulo.quantity != '') {
                    result += (util.formatDecimal(articulo.quantity));
                }
            }
        });
        return parseFloat(result).toFixed(2);
    }

    onOpenRowNewEmpty() {
        if (appState.typifiedState.getObject(this.graphDataMainType?.status)?.code === "CANCELED" ||
            appState.typifiedState.getObject(this.graphDataMainType?.order?.status)?.code === "CANCELED") {
            this.setState({ orderCanceled: true });
        } else {
            let newModel = this.getModelTable().toPlainObject();
            if (this.state.fromWorkOrder) {
                newModel.orderId = this.graphDataMainType.order.id;
            }
            newModel.isEditing = true;
            this.mobxListado.push(newModel);
            this.setState({
                hasSomeRowEditing: true
            });
        }
    };

    calculaPrecio() {
        let result = 0;
        let articulos = appState.workOrderCartArticlesState.workOrderArticles;
        articulos.map((articulo, index) => {
            if (util.hasValue(articulo.quantity)) {
                if (util.hasValue(articulo.articlePrice)) {
                    if (result == 0)
                        result = (util.formatDecimal(articulo.articlePrice)) * (util.formatDecimal(articulo.quantity));
                    else
                        result += (util.formatDecimal(articulo.articlePrice)) * (util.formatDecimal(articulo.quantity));
                } else {
                    if (result == 0)
                        result = (util.formatDecimal(articulo.quantity));
                    else {
                        result += (util.formatDecimal(articulo.quantity));
                    }
                }
            }
        });
        return result.toFixed(2);
    }

    renderImpl() {
        const t = translate;

        let order = this.graphDataMainType;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();

        return (
            <InfoBlock componentObject={this}>

                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                {this.graphDataMainType.id &&
                                <MoreButtons integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                                  getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                                }
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active="Piezas"
                                numAlarm={3}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={order}
                                              getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className="card mb-3">

                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                                active={"Piezas"}
                            />
                            }
                            <GroupOfField
                                title={t('Pedido')}
                                subtitle={t('Piezas')}
                                icon='fas fa-calendar'
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div
                                    style={{
                                        margin: 10,
                                        fontWeight: 'bold'
                                    }}>{t("Info para añadir piezas desde BO")}</div>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna pieza creada')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&

                                        <tr>
                                            <th scope="col">{t("Codigo")}</th>
                                            <th scope="duration">{t("Nombre")}</th>
                                            <th scope="duration">{t("Cantidad")}</th>
                                            <th scope="duration">{t("Lote")}</th>
                                            <th scope="wo-width-date">{t("Visita")}</th>
                                            <th scope="wo-matched">{t('Orden de Trabajo')}</th>
                                            <th>&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">
                                        {this.mobxListado.map((article, index) => (
                                            <OrderFormArticle
                                                key={article && article.id}
                                                orderId={order.id}
                                                reload={(e) => this.reloadData(e)}
                                                rowIndex={index} foreingKeyfield={this.foreingKeyfield}
                                                row={article}
                                                slots={appState.slotsState.slots}
                                                workOrders={appState.workOrderState.workOrders}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            />
                                        ))}

                                        </tbody>
                                        <tfoot>
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <td colSpan="2">{t('TOTAL')}</td>
                                            <td colSpan="7">
                                                {this.mobxListado.length}
                                            </td>
                                        </tr>
                                        }
                                        </tfoot>
                                    </table>

                                    <div className="col-12 text-right mb-3">
                                        <VsfButtonNew
                                            disabled={this.state.hasSomeRowEditing}
                                            showButton={this.creatable["article"] || false}
                                            label={t("Añadir Piezas")}
                                            isEditing={this.state.hasSomeRowEditing}
                                            className="btn btn--loader"
                                            onClick={_ => this.onOpenRowNewEmpty()}
                                        />
                                    </div>
                                    <AlertModal isOpen={this.state.orderCanceled}
                                                onCloseModal={() => this.setState({ "orderCanceled": false })}>
                                        <PopUp
                                            title={t('Operación no permitida')}
                                            icon={"exclamationTriangleIcon"}
                                            text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                                            label1={'Cerrar'}
                                            onClick1={() => this.setState({ orderCanceled: false })}
                                        />
                                    </AlertModal>

                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }

}

export default withRouter(OrderFormArticles)
