import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import VehicleGpsModel from "../models/VehicleGpsModel";

class VehicleGpsState extends BaseModelState {


    @observable vehicles = [];

    async getVehiclesGps() {
        let vehicleGpsQuery = new VehicleGpsModel();{
            let result = await vehicleGpsQuery.find();
            if (result == null) {
                result = [];
            }
            this.vehicles = result;
        }
    }

}

export default VehicleGpsState;