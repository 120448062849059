import React, {Component} from 'react';
import {observer} from 'mobx-react';
import Sidebar from "../layout/Sidebar";
import appState from "../state/AppState";
import RightModals from "../components/modals/RightModals";
import AppLogger from "../util/AppLogger";
import PageNoData from "../pages/PageNoData";
import SidebarModals from "./modals/SidebarModals";

@observer
class VsfBody extends Component {

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        let user = appState.loginState.user;
        let props = this.props;
        window.onscroll = function () {
            var y = window.scrollY;
            appState.layoutState.scrollTop = y > 150;
            appState.layoutState.scrollBot = parseInt(y + 500) < (document.body.scrollHeight - 150);
        };
        return (
            <>
                {appState.layoutState.scrollTop && appState.layoutState.hoverDndrop &&
                <div className={"header-fixed-top"} onDragOver={() => {
                    window.scrollTo(0, window.scrollY - 2);
                }}/>
                }
                <div className="web-main">
                    <div className="sidebar ">
                        <Sidebar {...this.props} key={user?.tenantId}
                                 currentLanguage={appState.loginState.currentLanguage}/>
                    </div>
                    <main className="main u-overflow-hidden">
                        <div className="container-fluid">
                            <RightModals  {...this.props} />
                            <SidebarModals  {...this.props} />

                            {appState.pageNoData ?
                                <PageNoData {...this.props} />
                                :
                                props.children
                            }
                        </div>
                    </main>
                </div>
                {appState.layoutState.scrollBot && appState.layoutState.hoverDndrop &&
                <div className={"header-fixed-bottom"} onDragOver={() => {
                    window.scrollTo(0, window.scrollY + 2);
                }}/>
                }
            </>)
    }
}

export default VsfBody
