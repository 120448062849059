import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import PageTitle from '../../components/PageTitle'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import BaseEditableTable from '../base/BaseEditableTable'
import { observable } from 'mobx'
import InfoBlock from '../../components/InfoBlock'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import util from '../../util/Util'
import AppLogger from '../../util/AppLogger'
import PropsUtil from '../../util/PropsUtil'
import translate from '../../translator/translate'
import MoreButtons from '../../components/MoreButtons'
import AssetModel from '../../models/AssetModel'
import WorkOrderArticleList from '../articles/WorkOrderArticleList'
import AssetActions from './AssetActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'
import appState from '../../state/AppState'

@observer
class ArticleWorkOrderList extends BaseEditableTable {

    @observable stocks = [];

    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "asset";
        this.initializeGraphData(this.nameMainType);
        this.state = {
            loading: true
        }
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.setState({ loading: false })
    }

    getModelTable() {
        return this.getModelQuery()
    }

    getModelQuery() {
        return new AssetModel();
    }

    renderImpl() {

        const t = translate;
        let modalClasses = util.getModalClasses();
        let asset = this.graphDataMainType;
        this.propsUtil = new PropsUtil(this.props);
        let config = {}
        if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.subType).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.subType).config)
        } else if (util.hasValue(appState.typifiedState.getObject(this.graphDataMainType.type).config)) {
            config = JSON.parse(appState.typifiedState.getObject(this.graphDataMainType.type).config)
        }
        return (

            <InfoBlock componentObject={this}>
                <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={this.getPageTitle()}
                                subtitle={this.getPageSubtitle()} mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons
                                    showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                    <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                        getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                active="Consumos"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.reloadData()}
                                showButton={this.creatable[this.getModelQuery().nameMainType] || false}>
                                <AssetActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={asset}
                                    getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }
                    <div className="card">
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataAssets(config?.vending === '1' || config?.postmix === '1')}
                                num={1}
                                active={"Consumos"}
                            />
                            }
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                {util.hasValue(this.graphDataMainType.id) &&
                                <WorkOrderArticleList
                                    assetId={this.graphDataMainType.id}
                                    key={this.graphDataMainType.id}
                                    {...this.props}
                                />
                                }
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </div>
            </InfoBlock>
        );
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(ArticleWorkOrderList);
