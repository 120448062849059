import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ListPageCounts from './ListPageCounts'
import appState from '../../state/AppState'
import Overlay from '../modals/Overlay'
import PropTypes from 'prop-types'
import FavouriteModal from '../FavouriteModal'
import Links from '../../util/Links'
import SavedFilterModel from '../../models/SavedFilterModel'
import AppLogger from '../../util/AppLogger'
import PropsUtil from '../../util/PropsUtil'
import translate from '../../translator/translate'
import FilterSharedComponent from '../FilterSharedComponent'
import LeftModalInList from '../modals/LeftModalInList'
import ReactTooltip from 'react-tooltip'
import LocalStorage from '../../network/LocalStorage'
import util from '../../util/Util'
import VsfButtonNew from '../VsfButtonNew'
import InputTypeComponent from '../fields/InputTypeComponent'

@observer
class SearchComponent extends Component {

    static defaultProps = {
        onDownloadExcel: _ => {
        }
    }

    static propTypes = {
        //Acción de descarga de Excel en el listado
        onDownloadExcelOrCsv: PropTypes.func,
        model: PropTypes.object,
    }

    constructor (props) {
        super(props)
        this.state = {
            savedFilterModels: [],
            savedFilterModelsShared: [],
            valueFilterQ: ''
        }
    }

    openModalWithListColumns () {
        this.props.listState.listColumnsSelect.modalOpen = true
    }

    openModalWithFilter () {
        this.props.listState.listFilter.modalOpen = true
    }

    reloadPage () {
        console.log('refresh')
        window.location.reload()
    }

    getInFilterValues (filter) {
        let filterKeysArray = filter.value.split(",")
        let filterLabelsArray = filter.value.split("',")
        if (filter.label != null) {
            filterLabelsArray = filter.label.split("',")
        }
        let filterArrayWithoutCommas = []
        filterLabelsArray.map((label) => {
            filterArrayWithoutCommas.push({ label: label.replaceAll('/\'', '') })
        })
        filterKeysArray.map((value, key) => {
            if (filterArrayWithoutCommas[key]) {
                filterArrayWithoutCommas[key].value = value
            }
        })
        this.log({
            filter,
            filterKeysArray,
            filterArrayWithoutCommas
        })
        return filterArrayWithoutCommas
    };

    getTitle (nameField) {
        let model = this.props.model
        let result = nameField
        if (model != null) {
            result = model.getLabelFromFieldName(nameField)
        }
        return result
    }

    getValueFilter (valueObj) {
        let result = valueObj.label
        if (valueObj.op == 'GT' || valueObj.op == 'GTEQ' || valueObj.op == 'LT' || valueObj.op == 'LTEQ') {
            result = util.getMoment(result).format('DD-MM-YYYY')
        }

        return result
    }

    getNameFilter (valueObj) {
        let result = this.getTitle(valueObj.name)
        if (valueObj.op == 'GT' || valueObj.op == 'GTEQ') {
            result += ' (Desde)'
        }
        if (valueObj.op == 'LT' || valueObj.op == 'LTEQ') {
            result += ' (Hasta)'
        }
        return result
    }

    async reloadFilters () {
        let savedFilterModel = new SavedFilterModel()
        if (util.hasValue(this.props.nameFilters)) {
            savedFilterModel.modelName = this.props.nameFilters
        } else {
            savedFilterModel.modelName = this.props.model.nameMainType
        }
        let savedFilters = await savedFilterModel.findPlainObject()

        let savedFilterModelsShared = []
        let savedFilterModels = []
        for (let filter of savedFilters) {
            if (filter.isShared) {
                savedFilterModelsShared.push(filter)
            }
            if (filter.userId === appState.loginState.userId) {
                savedFilterModels.push(filter)
            }
        }
        this.setState({
            savedFilterModels,
            savedFilterModelsShared
        })
    }

    async componentDidMount () {
        if (this.props.includeSavedFavourites) {
            await this.reloadFilters()
        }
        const q = this.propsUtil.getRequest('q')
        if (util.hasValue(q)) {
            this.setState({ valueFilterQ: q })

        }

    }

    async onClickFavourite (favourite) {
        let configuration = JSON.parse(favourite.configuration)
        // FILTROS
        let filtersArray = []
        for (let [key, favourite] of Object.entries(configuration.currentFilter)) {
            this.log(favourite)
            if (favourite != null) {
                filtersArray.push({
                    fieldKey: favourite.key,
                    fieldName: favourite.name,
                    fieldLabel: favourite.label,
                    fieldValue: favourite.value,
                    filterOperator: favourite.op
                })
            }
        }

        await this.propsUtil.changeUrlRequest({ filters: JSON.stringify(filtersArray) })
        this.props.listComponent.setListComponent(filtersArray)
        // COLUMNAS
        let oldVisibleFields = this.props.stateListColumnsSelect.visibleFields
        this.refreshVisibileFields(configuration.columns, oldVisibleFields)
        this.refreshListFieldsVisibility()
        this.refreshSortableFields(configuration.columns, oldVisibleFields)
        this.reorderListFields(oldVisibleFields)
        // Cierra el menu
        appState.layoutState.favouritesleftMenuOpen = false

    }

    refreshVisibileFields (columns, oldVisibleFields) {
        let newVisibleFields = {}
        for (let [key, column] of Object.entries(columns)) {
            if (column != null) {
                newVisibleFields[column.name] = column.visible
            }
        }
        for (let name of Object.keys(oldVisibleFields)) {
            if (newVisibleFields[name] == null) {
                newVisibleFields[name] = false
            }
        }
        this.props.stateListColumnsSelect.visibleFields = newVisibleFields
    }

    refreshSortableFields (columns, oldVisibleFields) {
        let newSortableFields = []
        for (let [key, column] of Object.entries(columns)) {
            newSortableFields.push(column.name)
        }
        for (let name of Object.keys(oldVisibleFields)) {
            if (!newSortableFields.includes(name)) {
                newSortableFields.push(name)
            }
        }
        this.props.stateListColumnsSelect.sortableFields = newSortableFields
    }

    /* METODOS COMPARTIDOS CON LISTITEMSSELECT */

    /**
     * Obtiene los datos del orden y visibilidad de las columnas del listado a partir del state
     *
     * @returns {{sortable: *, visible: *}}
     */
    getLocalStorageListFields () {
        let result = {
            sortable: this.props.stateListColumnsSelect.sortableFields,
            visible: this.props.stateListColumnsSelect.visibleFields
        }
        return result
    }

    setItemOnLocalStorage (key, value) {
        let localStorage = new LocalStorage()
        localStorage.setItem(key, value)
    }

    /**
     * Actualiza la visibilidad de las columnas una vez hemos presionado un check en el modal de las columnas visibles o al inicio de ejecucion
     */
    refreshListFieldsVisibility () {
        // Primero, recorro los listFiels y por cada uno de ellos, actualizo su visibilidad
        var visibleFields = this.props.stateListColumnsSelect.visibleFields
        this.props.stateListColumnsSelect.listFields.forEach(function (listField, index) {
            if (listField != null) {
                listField.visible = visibleFields[listField.name]
            }
        })
        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
        //console.log("refreshListFieldsVisibility: " + JSON.stringify(this.getLocalStorageListFields()) );
    }

    /**
     * Reordena las columnas del listado despues de un movimiento en el modal o en la carga
     *
     */
    reorderListFields (oldVisibleFields) {
        // Primero, reordenamos las columnas en el listado
        let listFields = []
        var baseListFields = this.props.stateListColumnsSelect.listFields
        this.props.stateListColumnsSelect.sortableFields.forEach((name) => {
            //console.log({baseListFields});
            let field = baseListFields.find(listField => listField != null && '' + listField.name === name)
            if (field != null) {
                listFields.push(field)
            }
        })
        for (let name of Object.keys(oldVisibleFields)) {
            let field = baseListFields.find(listField => listField != null && '' + listField.name === name)
            if (field != null && !listFields.includes(field)) {
                listFields.push(field)
            }
        }
        // Segundo, guardamos en el state las columnas actualizadas
        this.props.stateListColumnsSelect.listFields = listFields
        // Por ultimo, guardamos la informacion en LocalStorage
        this.setItemOnLocalStorage(this.localStorageLabel, JSON.stringify(this.getLocalStorageListFields()))
    }

    /* FIN METODOS COMPARTIDOS CON LISTITEMSSELECT */

    updateQ (event) {
        let valueFilterQ = event.target.value
        this.setState({ valueFilterQ })
    }

    async aplicarFiltroQ () {
        await this.propsUtil.changeUrlRequest({
            'q': this.state.valueFilterQ,
            "page": 1
        })
        if (this.props.didAplicarFiltroQ != null) {
            await this.props.didAplicarFiltroQ()
        }
    }

    render () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let withoutResults = true
        if (this.props.withoutResults != null)
            withoutResults = this.props.withoutResults
        let props = this.props

        return (
            <div className={this.props.displayRow ? 'search row align-items-center' : 'search'}>
                <div className="d-flex align-items-center">
                    {withoutResults ?
                        <ListPageCounts numEntriesPerPage={this.props.numEntriesPerPage} {...props} />
                        :
                        <h2 className="subtitle listSubtitle">
                            {this.props.subtitle}
                        </h2>
                    }
                    {this.renderButtons()}

                </div>
                <div className="row">
                    <div className="col-10">
                        {!this.props.noFilters &&
                        this.renderFilters()
                        }
                    </div>
                    {this.props.includeSavedFavourites && this.propsUtil.getRequest('filters') != null &&
                    <div className={'col-2'}>
                        <span onClick={() => this.props.toggleFavouriteModal()}
                            className="mt-2 link-underline ico ico--save">{t('Guardar Filtros')}</span>
                    </div>
                    }
                </div>


                {this.props.listState.actionList.length > 0 &&
                <div className="col-12 col-lg-12 order-2 order-lg-0 mb-3 p-0">
                    {this.props.renderActionsList()}
                </div>
                }
                {appState.layoutState.downloadExcelCsv &&
                <>
                    <Overlay show={appState.layoutState.downloadExcelCsv}
                        onClick={_ => appState.layoutState.downloadExcelCsv = false} />
                    <div className="dropdown-sublist" style={{
                        top: '8rem',
                        right: 22,
                        zIndex: 100
                    }}>
                        <div className="dropdown-sublist__item ">
                            <a onClick={(e) => this.props.onDownloadExcelOrCsv('EXCEL')}>{t('Excel ')}<span>
                                <i className="fa fa-file-excel" /></span></a>
                        </div>
                        <div className="dropdown-sublist__item ">
                            <a onClick={(e) => this.props.onDownloadExcelOrCsv('CSV')}>{t('CSV ')}<span>
                                <i className="fa fa-file-csv" /></span></a>
                        </div>
                    </div>
                </>
                }
                <form id="downloadExcelForm" name="downloadExcelForm" method="get" target="_blank"></form>
                <LeftModalInList
                    isOpen={appState.layoutState.favouritesleftMenuOpen}
                    title={'Filtros'}
                    onCloseModal={() => appState.layoutState.favouritesleftMenuOpen = false}>
                    <FilterSharedComponent
                        savedFilterModels={this.state.savedFilterModels}
                        savedFilterModelsShared={this.state.savedFilterModelsShared}
                        onClickFavourite={(e) => this.onClickFavourite(e)}
                        stateListColumnsSelect={this.props.stateListColumnsSelect}
                        reloadFilters={() => this.reloadFilters()}
                    />
                </LeftModalInList>
                {this.props.includeSavedFavourites &&
                <FavouriteModal
                    reloadFilters={() => this.reloadFilters()}
                    openModal={this.props.favouriteModalOpen}
                    closeModal={() => this.props.toggleFavouriteModal()}
                    nameFilters={this.props.nameFilters}
                    columns={this.props.listState.listColumnsSelect.listFields}
                    currentFilter={new Links(this.props.location).getCurrentFilterFromUrl()}
                    listComponent={this.props.listComponent}
                    model={this.props.model}
                    {...this.props}
                />
                }

            </div>
        )
    }

    renderButtons () {
        const t = translate
        let borderBottomRightRadius = 0
        let borderTopRightRadius = 0
        if (this.props.fromCalendar) {
            borderBottomRightRadius = 4
            borderTopRightRadius = 4
        }
        return (
            <div
                className={this.props.withoutFlex ? 'justify-content-end flex-wrap '
                    : 'd-flex justify-content-end flex-wrap '}>
                {this.props.includeSearch &&
                <div className={this.props.withoutMargin ? 'filterQ' : 'ml-2 filterQ'}>
                    <form onSubmit={(e) => {
                        e.preventDefault()
                        this.aplicarFiltroQ()
                    }}>
                        <InputTypeComponent
                            value={this.state.valueFilterQ} submitOnEnter
                            onChange={(e) => this.updateQ(e)}
                            placeholder={t('Buscar')}
                            name={'valueFilterQ'}
                            className="form-control" onClickIcon={() => this.aplicarFiltroQ()}
                            postfix={'fa fa-search'} isClearable={true}
                        />
                    </form>
                </div>
                }
                {this.props.includeFilters &&
                <>
                    <div data-tip data-for="filterTooltip"
                        className={this.props.withoutMargin ? ' ' : 'ml-2 '}>
                        <VsfButtonNew style={{
                            borderTopRightRadius,
                            borderBottomRightRadius
                        }}
                        onClick={() => this.openModalWithFilter()} type="button"
                        className="btn btn-outline-secondary remove-default-shadow m-0.5">
                            {this.props.textFilter &&
                            <span className="text-button mr-2">{this.props.textFilter}</span>
                            }
                            <span className="fa fa-filter" />
                        </VsfButtonNew>
                    </div>
                    <ReactTooltip data-for="filterTooltip" id="filterTooltip" place="bottom"
                        textColor="#2D3B41"
                        backgroundColor="#F5F5F5" arrowColor="#F5F5F5"
                        multiline={false}>
                        {t('Filtros')}
                    </ReactTooltip>
                </>
                }
                {(this.props.includeSavedFavourites || this.props.includeChangeColumns || this.props.includeDownload) &&
                <div className="mb-3">
                    <div role="group"
                        aria-label="Button group with nested dropdown">
                        {this.props.includeSavedFavourites &&
                        <div className="btn-group mb-1 ml-2" role="group" data-tip
                            data-for="favouriteFiltersTooltip">

                            {this.props.refresh &&
                            <VsfButtonNew onClick={() => this.reloadPage()} type="button"
                                className="btn-refresh btn btn-outline-secondary ">
                                <span className="fa fa-sync-alt"> </span>
                            </VsfButtonNew>
                            }
                            <VsfButtonNew onClick={() => {
                                appState.layoutState.favouritesleftMenuOpen = true
                            }} id="btnGroupColumns" type="button"
                            className="btn btn-outline-secondary remove-default-shadow dropdown-toggle"
                            data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                                <span className="fa fa-bookmark" />
                            </VsfButtonNew>
                            <ReactTooltip data-for="favouriteFiltersTooltip" id="favouriteFiltersTooltip"
                                place="bottom" textColor="#2D3B41"
                                backgroundColor="#F5F5F5" arrowColor="#F5F5F5"
                                multiline={false}>
                                {t('Filtros Favoritos')}
                            </ReactTooltip>
                        </div>
                        }
                        {this.props.includeChangeColumns &&
                        <div className="btn-group mb-1 ml-2" role="group" data-tip data-for="columnsTooltip">
                            <VsfButtonNew data-tip data-for="columnsTooltip"
                                onClick={() => this.openModalWithListColumns()}
                                id="btnGroupColumns"
                                type="button"
                                style={{ shadowColor: '#fff' }}
                                className="btn btn-outline-secondary remove-default-shadow dropdown-toggle"
                                data-toggle="dropdown" aria-haspopup="true"
                                aria-expanded="false">
                                <span className="fa fa-columns"> </span>
                            </VsfButtonNew>
                            <ReactTooltip data-for="columnsTooltip" id="columnsTooltip" place="bottom"
                                textColor="#2D3B41"
                                backgroundColor="#F5F5F5" arrowColor="#F5F5F5"
                                multiline={false}>
                                {t('Gestor de Columnas')}
                            </ReactTooltip>

                        </div>
                        }
                        {this.props.includeDownload &&
                        <div className="btn-group mb-1 ml-2" role="group" data-tip data-for="downloadsTooltip">
                            <VsfButtonNew type="button"
                                onClick={() => appState.layoutState.clickDownloadExcelCsv()}
                                className={'btn btn-outline-secondary remove-default-shadow dropdown-toggle'}
                                icon={'fa fa-download'}
                                disabled={false}
                            />
                            <ReactTooltip data-for="downloadsTooltip" id="downloadsTooltip"
                                place="bottom" textColor="#2D3B41"
                                backgroundColor="#F5F5F5" arrowColor="#F5F5F5"
                                multiline={false}>
                                {t('Descargas')}
                            </ReactTooltip>
                        </div>
                        }
                    </div>
                </div>
                }
            </div>
        )
    }

    renderFilters () {
        const t = translate
        this.propsUtil = new PropsUtil(this.props)
        let links = new Links(this.props.location)
        const currentFilter = links.getCurrentFilterFromUrl()
        if (Object.entries(currentFilter).length === 0) {
            return null
        }
        return (
            <div className="filter-tags__block" style={{ display: 'flex' }}>
                {Object.entries(currentFilter).map(([index, valueObj]) => (
                    (valueObj.op !== 'IN' && valueObj.op !== 'STRIN' && valueObj.op !== 'INLIST') ?
                        <ul key={index} className="filter-tags__block__tags"
                            aria-labelledby="type-order">
                            <label
                                className="m-2 filter-tags__block__label2">{this.getNameFilter(valueObj)}:</label>
                            <li>
                                <span className="tag-item tag-item--text">{this.getValueFilter(valueObj)}</span>
                                <span onClick={() => this.props.deleteFilter(valueObj)}
                                    className="tag-item tag-item--close text-click"
                                    title="Cerrar etiqueta"><span className="fas fa-times" /></span>
                            </li>
                        </ul>
                        :
                        <ul key={index} className="filter-tags__block__tags row"
                            aria-labelledby="type-order">
                            <label style={{ textTransform: 'capitalize' }}
                                className="m-2 filter-tags__block__label2">{this.getNameFilter(valueObj)}:</label>
                            {this.getInFilterValues(valueObj).map((filter, index) => (
                                <li><span
                                    className="tag-item tag-item--text">{this.getValueFilter(filter)}</span>
                                <span
                                    onClick={() => this.props.deleteFilter(valueObj, filter.value, filter.label)}
                                    className="tag-item tag-item--close text-click"
                                    title="Cerrar etiqueta"><span className="fas fa-times"> </span>
                                </span>
                                </li>
                            ))}
                        </ul>
                )
                )}
            </div>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default SearchComponent
