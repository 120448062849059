import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropsUtil from '../../util/PropsUtil'
import AppLogger from '../../util/AppLogger'
import { withRouter } from 'react-router-dom'

@observer
class ClientListItem extends Component {
    openClientModal () {
        this.propsUtil.changeUrlRequest({
            clientId: this.props.propietary ? this.props.row.propietaryId : this.props.row.clientId,
            rightModal: this.props.propietary ? 'modalclients' : 'modalposclients',
            rightModalTab: 'general',
            fromRightModal: true
        })
    }

    render () {
        this.propsUtil = new PropsUtil(this.props)
        let props = this.props

        return (
          <div className={'ancho-responsive text-truncate'}>
              <span onClick={() => this.openClientModal()} className="text-click text-underline ">{props.value}</span>
          </div>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default withRouter(ClientListItem)