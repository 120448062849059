import AbstractModel from "./AbstractModel";
import FormModel from "./FormModel";
import TypifiedValueModel from "./TypifiedValueModel";
import AssetModelModel from "./AssetModelModel";

export default class FormAssignmentModel extends AbstractModel {

    assetTypeIds;
    workOrderTypeIds;
    assetModelIds;
    formId;

    nameMainType = "formAssignment";
    graphFindByIdOperation = "formAssignment";
    graphFindOperation = "formAssignmentsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "assetTypeIds": "",
            "workOrderTypeIds": "",
            "formId": "",
            "assetModelIds": "",
        };
        this.addRelatedTableGeneric(result, "form", "RelationOne", FormModel);
        this.addRelatedTableGeneric(result, "workOrderTypes", "Relation", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "assetTypes", "Relation", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "assetModels", "Relation", AssetModelModel);
        return result;
    }

}
