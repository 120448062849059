import { observable, runInAction } from 'mobx'
import util from '../util/Util'
import AppLogger from '../util/AppLogger'
import moment from 'moment'
import WorkLogModel from '../models/WorkLogModel'
import appState from './AppState'

class ScheduleByTechDropState {

  @observable horas = 0
  @observable scheduleVisits = []
  @observable workOrdersSlotGroupedCopia = []
  @observable workOrdersNotSlotGroupedCopia = []
  @observable flagSlots = false
  @observable zona
  @observable clientId
  @observable slotsFromTech = {}
  /**
   * Almaceno un diccionario con la key el id de ScheduleVisit y el value el objeto
   * @type {{}}
   */
  @observable scheduleVisitsDict = {}
  @observable rowsWithoutTechnicals = []
  //Slots para la parte de planificación
  @observable allSlotsDict = {}
  //Slots agrupados para poder saber el número de elementos en base a técnico - dia - etc
  @observable allSlotsGrouped = {}
  @observable cacheWorkUnits = {}
  @observable cacheWorkUnitsFlag = {}
  @observable slotClicked = {}
  @observable clients = []
  @observable copiaClients = []
  @observable slotsFromClientDict = {}
  @observable copiaTecnicosCalendario = []
  @observable tecnicosCalendario = []
  @observable tecnicosCalendarioSolapados = {}
  @observable lineasObjTecn = {}
  @observable showMoreTecnicos = true
  @observable tecnicosCalendarioDict = {}
    @observable userConfigsDict = {};

  /**
   * Contendrá un array con las horas forzadas. Por ejemplo: {"2019-01-01":{min:5,max:23}}
   * A la hora de pintar tendrá en cuenta este array para que sea el que se utilice
   * @type {{}}
   */
  @observable dateRangeForced = {}
  @observable dateRangesFestivos = []

  /**
   * A partir de; hora - técnico - lineasinasignar - fecha devuelve el índice dentro de la tabla allSlotsDict con el objeto asociado.
   * @returns {{}}
   */
  loadCacheWorkUnits (optionTime) {
      let cacheWorkUnits = {}
      for (let slotId of Object.keys(this.allSlotsDict)) {
          let slot = this.allSlotsDict[slotId]
          let hour = ''
          if (optionTime === 'mes') {
              if (util.getHourFromDatetime(slot.scheduledTime) < 14) {
                  hour = 8
              } else {
                  hour = 14
              }
          } else {
              hour = slot.scheduledTime ? util.getString(util.getHourFromDatetime(slot.scheduledTime)) : ''
          }
          let technical = util.getString(slot.userId)
          let isoDate = slot.scheduledTime ? util.getString(util.getDateFromDatetime(slot.scheduledTime)) : ''
          let lineaSinAsignar = util.getString(slot.lineaSinAsignar)
          let key = hour + ';' + util.getString(technical) + ';' + lineaSinAsignar + ';' + isoDate
          if (cacheWorkUnits[key] == null) {
              cacheWorkUnits[key] = []
          }
          cacheWorkUnits[key].push(slotId)
          cacheWorkUnits[slotId] = key
      //console.log({cached:1,key,hour,technical,isoDate,lineaSinAsignar,workOrder});
      }
      this.log({
          loadCacheWorkUnits: 1,
          cacheWorkUnits
      })
      appState.scheduleByTechDropState.allSlotsGrouped = cacheWorkUnits
  }

  /**
   * Coloca los slots y los junta a partir de las 8 o la primera hora anterior a ella.
   * @returns {{}}
   */
  loadCacheWorkUnitsFlags () {
      let cacheWorkUnitsFlags = {}
      let horaFlag = {}
      let objWithInitialHourByTechnical = this.getInitialHourByTechnical()
      for (let slot of util.ordenarFechasAsc(Object.values(this.allSlotsDict), 'scheduledTime')) {
          let technical = util.getString(slot.userId)
          let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime))
          let lineaSinAsignar = util.getString(slot.lineaSinAsignar)
          let keyFlags = util.getString(technical) + ';' + lineaSinAsignar + ';' + isoDate
          // Me devolvera un array como este: {"{technical}-{date}":8, ... }
          if (!slot.isDateTimeFixed && !slot.isFinished) {
              if (horaFlag[keyFlags] == null) {
                  horaFlag[keyFlags] = objWithInitialHourByTechnical[technical + '-' + isoDate] + Math.ceil(slot.scheduledDuration)
                  if (cacheWorkUnitsFlags[objWithInitialHourByTechnical[technical + '-' + isoDate] + ';' + keyFlags] == null) {
                      cacheWorkUnitsFlags[objWithInitialHourByTechnical[technical + '-' + isoDate] + ';' + keyFlags] = []
                  }
                  cacheWorkUnitsFlags[objWithInitialHourByTechnical[technical + '-' + isoDate] + ';' + keyFlags].push(slot.id)
              } else {
                  if (cacheWorkUnitsFlags[horaFlag[keyFlags] + ';' + keyFlags] == null) {
                      cacheWorkUnitsFlags[horaFlag[keyFlags] + ';' + keyFlags] = []
                  }
                  cacheWorkUnitsFlags[horaFlag[keyFlags] + ';' + keyFlags].push(slot.id)
                  horaFlag[keyFlags] = util.getString(parseInt(horaFlag[keyFlags]) + Math.ceil(slot.scheduledDuration))
              }
          } else {
              let hour = util.getString(util.getHourFromDatetime(slot.scheduledTime))
              if (cacheWorkUnitsFlags[hour + ';' + keyFlags] == null) {
                  cacheWorkUnitsFlags[hour + ';' + keyFlags] = []
              }
              cacheWorkUnitsFlags[hour + ';' + keyFlags].push(slot.id)
          }
      }
      appState.scheduleByTechDropState.allSlotsGrouped = cacheWorkUnitsFlags
  }

  getInitialHourByTechnical () {
      let result = {}
      for (let slotId of Object.keys(this.allSlotsDict)) {
          let slot = this.allSlotsDict[slotId]
          let technical = util.getString(slot.userId)
          let isoDate = util.getString(util.getDateFromDatetime(slot.scheduledTime))
          if (result[technical + '-' + isoDate] == null) {
              result[technical + '-' + isoDate] = 8
          }
          result[technical + '-' + isoDate] = Math.min(result[technical + '-' + isoDate], util.getHourFromDatetime(slot.scheduledTime))
      }
      //this.log("getInitialHourByTechnical=>");
      //this.log({result});
      return result
  }

  loadPendingClients (cliente) {
      let clientesDict = {}
      let slotsFromClientDict = {}
      let clientesArray = []
      for (let slotId in this.allSlotsDict) {
          let slot = this.allSlotsDict[slotId]
          if (util.hasValue(slot.client?.id)) {
              if (!util.hasValue(cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              } else if (util.perteneceA(slot.clientId, cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              }
              if (!slot.isFinished && !util.hasValue(slot.scheduledTime) && !this.containsWorkOrdersReturned(slot)) {
                  if (slotsFromClientDict[slot.client.id] == null) {
                      slotsFromClientDict[slot.client.id] = []
                  }
                  slotsFromClientDict[slot.client.id].push(slot)
              }
          }
      }
      runInAction(() => {
          let clientsOrderView = util.arrayModelToPlainObjects(clientesArray)
          this.copiaClients = clientsOrderView
          this.clients = clientsOrderView
          this.slotsFromClientDict = slotsFromClientDict
          localStorage.setItem('loadClients', 'Pendiente')
      })

  }

  containsWorkOrdersReturned (slot) {
      let workOrders = slot?.workOrder
      let result = false
      if (workOrders?.length > 0) {
          for (let workOrder of workOrders) {
              if (appState.typifiedState.getObject(workOrder.status).code === WorkLogModel.RETURN_WORK_ORDER) {
                  result = true
              }
          }
      }
      return result
  }

  loadDevueltasClients (cliente) {
      let clientesDict = {}
      let slotsFromClientDict = {}
      let clientesArray = []
      for (let slotId in this.allSlotsDict) {
          let slot = this.allSlotsDict[slotId]
          if (util.hasValue(slot.client?.id)) {
              if (!util.hasValue(cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              } else if (util.perteneceA(slot.clientId, cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              }
              if (!slot.isFinished && this.containsWorkOrdersReturned(slot)) {
                  if (slotsFromClientDict[slot.client.id] == null) {
                      slotsFromClientDict[slot.client.id] = []
                  }
                  slotsFromClientDict[slot.client.id].push(slot)
              }
          }
      }
      runInAction(() => {
          let clientsOrderView = util.arrayModelToPlainObjects(clientesArray)
          this.copiaClients = clientsOrderView
          this.clients = clientsOrderView
          this.slotsFromClientDict = slotsFromClientDict
          localStorage.setItem('loadClients', 'Devueltas')
      })

  }

  loadClients (cliente) {
      let slotsFromClientDict = {}
      let clientesDict = {}
      let clientesArray = []
      for (let slotId in this.allSlotsDict) {
          let slot = this.allSlotsDict[slotId]
          if (util.hasValue(slot.client?.id)) {
              if (!util.hasValue(cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              } else if (util.perteneceA(slot.clientId, cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              }
              if (!slot.isFinished) {
                  if (slotsFromClientDict[slot.client.id] == null) {
                      slotsFromClientDict[slot.client.id] = []
                  }
                  slotsFromClientDict[slot.client.id].push(slot)
              }
          }
      }
      runInAction(() => {
          let clientsOrderView = util.arrayModelToPlainObjects(clientesArray)
          this.clients = clientsOrderView
          this.copiaClients = clientsOrderView
          this.slotsFromClientDict = slotsFromClientDict
          localStorage.setItem('loadClients', 'Todas')
      })
  }

  loadPendingClientsSlotDict (allSlotsDict, cliente) {
      let clientesDict = {}
      let slotsFromClientDict = {}
      let clientesArray = []
      for (let slotId in allSlotsDict) {
          let slot = allSlotsDict[slotId]
          if (util.hasValue(slot.client?.id)) {
              if (!util.hasValue(cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              } else if (util.perteneceA(slot.clientId, cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              }
              if (!slot.isFinished && !util.hasValue(slot.scheduledTime)) {
                  if (slotsFromClientDict[slot.client.id] == null) {
                      slotsFromClientDict[slot.client.id] = []
                  }
                  slotsFromClientDict[slot.client.id].push(slot)
              }
          }
      }
      runInAction(() => {
          let clientsOrderView = util.arrayModelToPlainObjects(clientesArray)
          this.clients = clientsOrderView
          this.copiaClients = clientsOrderView
          this.slotsFromClientDict = slotsFromClientDict
      })
  }

  loadClientsSlotDict (allSlotsDict, cliente) {
      let slotsFromClientDict = {}
      let clientesDict = {}
      let clientesArray = []
      for (let slotId in allSlotsDict) {
          let slot = allSlotsDict[slotId]
          if (util.hasValue(slot.client?.id)) {
              if (!util.hasValue(cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              } else if (util.perteneceA(slot.clientId, cliente)) {
                  if (clientesDict[slot.client.id] == null) {
                      clientesDict[slot.client.id] = slot.client
                      clientesArray.push(slot.client)
                  }
              }
              if (!slot.isFinished) {
                  if (slotsFromClientDict[slot.client.id] == null) {
                      slotsFromClientDict[slot.client.id] = []
                  }
                  slotsFromClientDict[slot.client.id].push(slot)
              }
          }
      }
      runInAction(() => {
          let clientsOrderView = util.arrayModelToPlainObjects(clientesArray)
          this.clients = clientsOrderView
          this.copiaClients = clientsOrderView
          this.slotsFromClientDict = slotsFromClientDict
      })
  }

  loadTechnicalsQ (q) {
      if (!util.hasValue(q)) {
          if (this.tecnicosCalendario.length < 20) {
              this.showMoreTecnicos = true
              this.tecnicosCalendario = this.copiaTecnicosCalendario.slice(0, 20)
          }
      } else {
          let tecnicosCalendario = []
          this.copiaTecnicosCalendario.map(technical => {
              let regExp = new RegExp(util.limpiaString(q), 'gi')
              if (regExp.test(util.limpiaString(technical.name))) {
                  tecnicosCalendario.push(technical)
              }
          })
          this.showMoreTecnicos = false
          this.tecnicosCalendario = tecnicosCalendario
      }
  }

  loadClientsQ (q) {
      if (!util.hasValue(q)) {
          this.clients = this.copiaClients
      } else {
          let clients = []
          this.copiaClients.map(client => {
              let regExp = new RegExp(q, 'gi')
              if (regExp.test(client.name)) {
                  clients.push(client)
              }
          })
          this.clients = clients
      }
  }

  createTecnicosSinAsignar () {
      let maxLineas = 0
      for (let slot of Object.values(this.allSlotsDict)) {
          if (slot.lineaSinAsignar != null && slot.userId == null) {
              maxLineas = Math.max(slot.lineaSinAsignar, maxLineas)
          }
      }
      maxLineas++
      let result = []
      for (let i = 1; i <= maxLineas; i++) {
          result.push({
              id: i,
              name: i
          })
      }
      this.rowsWithoutTechnicals = result
  }

  /**
   *
   * @param slot
   * @param lineas objeto con un indice cada linea { 0: [slot1, slot2] }
   * @param j indice que indica si esta OT se puede incluir en esta linea
   */
  cabeEnLinea (slot, lineas, j) {
      let slotsEnLinea = lineas[j]
      let inicio = moment(slot.scheduledTime)
      let final = moment(slot.scheduledTime)
      final.add(slot.scheduledDuration, 'hours')
      let cabe = true
      for (let slotComparar of slotsEnLinea) {
          let inicioComparar = moment(slotComparar.scheduledTime)
          let finalComparar = moment(slotComparar.scheduledTime)
          finalComparar.add(slotComparar.scheduledDuration, 'hours')
          if (inicioComparar.unix() <= inicio.unix() && inicio.unix() < finalComparar.unix()) {
              cabe = false
              break
          }
          if (inicioComparar.unix() < final.unix() && final.unix() <= finalComparar.unix()) {
              cabe = false
              break
          }
      }
      return cabe
  }

  /**
   *
   * @param slot
   * @param lineas objeto con un indice cada linea { 0: [slot1, slot2] }
   * @param j indice que indica si esta OT se puede incluir en esta linea
   */
  cabeEnLineaTech (slot, lineas, j) {
      let slotsEnLinea = lineas[slot.userId]?.[j]
      let inicio = moment(slot.scheduledTime)
      let final = moment(slot.scheduledTime)
      final.add(slot.scheduledDuration, 'hours')
      let cabe = true
      if (slotsEnLinea?.length > 0) {
          for (let slotComparar of slotsEnLinea) {
              let inicioComparar = moment(slotComparar.scheduledTime)
              let finalComparar = moment(slotComparar.scheduledTime)
              finalComparar.add(slotComparar.scheduledDuration, 'hours')
              if (inicioComparar.unix() <= inicio.unix() && inicio.unix() < finalComparar.unix()) {
                  cabe = false
              }
              if (inicioComparar.unix() < final.unix() && final.unix() <= finalComparar.unix()) {
                  cabe = false
              }
              if (inicio.unix() < inicioComparar.unix() && final.unix() >= finalComparar.unix()) {
                  cabe = false
              }
              // this.log({
              //   cabe,
              //   slotCompararId: slotComparar.id,
              //   slotId: slot.id,
              //   slot: slot,
              //   slotComparar,
              //   final : final.format("HH"),
              //   inicio : inicio.format("HH"),
              //   finalComparar : finalComparar.format("HH"),
              //   inicioComparar : inicioComparar.format("HH"),
              //   primeraprimera:inicioComparar.unix() <= inicio.unix(),
              //   primerasegunda: inicio.unix() < finalComparar.unix(),
              //   segundaprimera:  inicioComparar.unix() < final.unix(),
              //   segundasegunda:  final.unix() <= finalComparar.unix()
              // })
          }
      }
      // this.log({
      //   slot: slot.id,
      //   userid: slot.userId,
      //   slotsEnLinea,
      // })
      return cabe
  }

  /**
   * A partir de un listado de slots, los que no tengan técnico debe asignarles un campo "lineaSinAsignar" desde 1 hasta n
   */
  addEmptyCellToSlotsMount () {
      const tecnicosCalendarioSolapados = {}
      const slotsArr = Object.values(this.allSlotsDict)
      const lineasObj = { 1: [] }
      const lineasObjTecn = {}
      for (let i = 0; i < slotsArr.length; i++) {
      // this.log("addEmptyCellToSlots i:" + i + " slotsArr[i].id:" + slotsArr[i].id);
          const slot = slotsArr[i]
          if (slot.scheduledTime != null && slot.userId == null) {
              let numLineas = Object.keys(lineasObj).length
              let haCabidoEnFila = false
              // this.log("addEmptyCellToSlots itero sobre numLineas:" + numLineas);
              for (let j = 1; j <= numLineas; j++) {
                  if (this.cabeEnLinea(slot, lineasObj, j)) {
                      slot.lineaSinAsignar = j
                      if (lineasObj[j] == null) {
                          lineasObj[j] = []
                      }
                      lineasObj[j].push(slot)
                      haCabidoEnFila = true
                      break
                  }
              }
              if (!haCabidoEnFila) {
                  lineasObj[numLineas + 1] = []
                  slot.lineaSinAsignar = numLineas + 1
                  lineasObj[numLineas + 1].push(slot)
              }
          } else {
              if (slot.scheduledTime != null && slot.userId != null) {
                  //calcular los slots solapados con tecnicos
                  if (lineasObjTecn[slot.userId] == null) {
                      lineasObjTecn[slot.userId] = {}
                  }
                  let numLineas = tecnicosCalendarioSolapados[slot.userId]?.length || 0
                  let haCabidoEnFila = false
                  for (let j = 1; j <= numLineas; j++) {
                      if (this.cabeEnLineaTech(slot, lineasObjTecn, j)) {
                          slot.lineaSinAsignar = j
                          if (!lineasObjTecn[slot.userId]?.[j]) {
                              lineasObjTecn[slot.userId][j] = []
                          }
                          lineasObjTecn[slot.userId][j].push(slot)
                          haCabidoEnFila = true
                          break
                      }
                  }
                  if (!haCabidoEnFila) {
                      if (tecnicosCalendarioSolapados[slot.userId] == null) {
                          tecnicosCalendarioSolapados[slot.userId] = [slot]
                      } else {
                          tecnicosCalendarioSolapados[slot.userId].push(slot)
                      }
                      slot.lineaSinAsignar = tecnicosCalendarioSolapados[slot.userId].length
                      lineasObjTecn[slot.userId][numLineas + 1] = []
                      this.log({
                          userId: slot.userId,
                          lineaSinAsignar: slot.lineaSinAsignar,
                          lineasObjTecn,
                          slot: slot.scheduledTime,
                          tecnicosCalendarioSolapados: this.tecnicosCalendarioSolapados[slot.userId]
                      })
                      lineasObjTecn[slot.userId][numLineas + 1].push(slot)
                  }
              }
          }
      }
      this.tecnicosCalendarioSolapados = tecnicosCalendarioSolapados
      this.lineasObjTecn = lineasObjTecn
  }

  getWorkOrders (order, workOrderIds) {
      if (order != null) {
          let workOrders = order.workOrder
          let workOrdersSlot = []
          let workOrdersNotSlot = []
          for (let workOrder of workOrders) {
              if (workOrderIds=="-1") {
                  workOrdersSlot.push(workOrder)
              } else {
                  if (util.perteneceA(workOrder.id, workOrderIds)) {
                      workOrdersSlot.push(workOrder)
                  } else {
                      workOrdersNotSlot.push(workOrder)
                  }
              }
          }
          this.workOrdersSlotGroupedCopia = this.ordenar(workOrdersSlot)
          this.workOrdersNotSlotGroupedCopia = this.ordenar(workOrdersNotSlot)
      }
  }

  getPaginationWorkOrdersGrouped (array, desde) {
      return array.slice(desde, desde + 10)

  }

  ordenar (array) {
      let workOrdersGrouped = {}
      for (let workOrder of array) {
          let key = (util.getString(workOrder.type) || '.') + ' ' + (util.getString(workOrder.subType) || '.')
          if (workOrdersGrouped[key] == null) {
              workOrdersGrouped[key] = []
          }
          workOrdersGrouped[key].push(workOrder)
      }
      let result = []
      for (let types of Object.keys(workOrdersGrouped)) {
          result = [...result, ...workOrdersGrouped[types]]
      }
      return result
  }

  calcHorasMinMax (allSlotsDict, optionTime, propsUtil) {
      let objWithDateRange = {}
      let tamanyo = 7
      if (optionTime === 'twodias') {
          tamanyo = 2
      } else if (optionTime === 'mes') {
          tamanyo = 31
      } else if (optionTime === 'oneDay') {
          tamanyo = 1
      }
      let momentDate = util.getMomentFromDateWithoutTimezone(this.getDateFromIso(propsUtil))
      for (let i = 0; i < tamanyo; i++) {
          let dateiso = momentDate.format('YYYY-MM-DD')
          objWithDateRange[dateiso] = {
              min: 8,
              max: 20
          }
          momentDate = momentDate.add(1, 'days')
      }
      for (let key of Object.keys(allSlotsDict)) {
          let slot = allSlotsDict[key]
          if (util.hasValue(slot.scheduledTime)) {
              let momentDate = util.getMoment(slot.scheduledTime)
              let hora = momentDate.hour()
              let isoDate = momentDate.format('YYYY-MM-DD')
              if (objWithDateRange[isoDate] == null) {
                  objWithDateRange[isoDate] = {
                      min: 8,
                      max: 20
                  }
              }

              objWithDateRange[isoDate]['min'] = Math.min(objWithDateRange[isoDate]['min'], hora)
              objWithDateRange[isoDate]['max'] = Math.max(objWithDateRange[isoDate]['max'], hora)
              if ((Math.round((hora + slot.scheduledDuration) - 1)) > objWithDateRange[isoDate]['max']) {
                  objWithDateRange[isoDate]['max'] = Math.floor(Math.max(objWithDateRange[isoDate]['max'], Math.round((hora + slot.scheduledDuration) - 1)))
                  if (objWithDateRange[isoDate]['max'] > 23) {
                      objWithDateRange[isoDate]['max'] = 23
                  }
              }
              if ((Math.round((hora + slot.scheduledDuration) - 1)) > 23) {
                  momentDate = momentDate.add(1, 'days')
                  isoDate = momentDate.format('YYYY-MM-DD')
                  if (objWithDateRange[isoDate] == null) {
                      objWithDateRange[isoDate] = {
                          min: 8,
                          max: 20
                      }
                  }
                  objWithDateRange[isoDate]['min'] = 0
              }
              if (objWithDateRange[isoDate]['max'] > 23) {
                  objWithDateRange[isoDate]['max'] = 23
              }
          }
      }
      // this.log({
      //   calcHorasMinMax: 1,
      //   objWithDateRange,
      //   dateRangeForced: this.dateRangeForced
      // })
      this.dateRangeForced = { ...objWithDateRange }
  }

  getDateFromIso (propsUtil) {
      let requestFecha = propsUtil.getRequest('date')
      let from
      if (requestFecha == null) {
          from = util.getMoment().format('YYYY-MM-DD')
      } else {
          from = moment(requestFecha).format('YYYY-MM-DD')
      }
      return from
  }

  log (msg) {
      AppLogger.get().debug(msg, this)
  }
}

export default ScheduleByTechDropState
