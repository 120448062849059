import React from 'react';
import { observer } from 'mobx-react';
import { computed } from "mobx";
import appState from "../../state/AppState";
import StockModel from "../../models/StockModel";
import BaseEditableRow from "../base/BaseEditableRow";

@observer
class ArticleFormLocationRow extends BaseEditableRow {

    constructor(props) {
        super(props);
        this.foreingKeyfield = "articleCode";
    }

    @computed
    get mobxListado() {
        return appState.stockState.stocks;
    }


    setMobxListado(newValue) {
        appState.stockState.stocks = newValue;
    }

    getModelTable() {
        return new StockModel();
    }

    render() {
        let stock = this.props.row;

        return (
            <tr>
                <td scope="row">
                    {stock.subInventory}
                </td>
                <td scope="row">
                    {stock.articleCode}
                </td>
                <td scope="row">
                    {stock.lote}
                </td>
                <td scope="row">
                    {stock.quantity}
                </td>
                <td scope="row">
                    {stock.type}
                </td>
            </tr>
        );
    }
}

export default ArticleFormLocationRow;
