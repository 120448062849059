import React, {Component} from 'react';
import VsfModal from "../../components/modals/VsfModal";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import VsfButtonNew from "../../components/VsfButtonNew";
import {observer} from "mobx-react";
import {observable} from "mobx";
import GqlErrors from "../../components/status/GqlErrors";
import PropsUtil from "../../util/DateUtil";
import {withRouter} from "react-router-dom";
import SlotModel from "../../models/SlotModel";
import appState from '../../state/AppState'
import OrderSummarySlot from "./OrderSummarySlot";
import CreateAndModify from "../../components/fields/CreateAndModify";
import OrderSummaryWorkLogs from "./OrderSummaryWorkLogs";
import WorkOrderDetailRow from "../../components/WorkOrderDetailRow";

@observer
class EndVisitModal extends Component {
    @observable    gqlErrors = null;
    @observable    slot = {};

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async componentDidMount() {

        let slotQuery = new SlotModel();
        slotQuery.id = this.props.slotId;
        slotQuery.addRelatedTable("workLog");
        slotQuery.addRelatedTable("workOrder");
        slotQuery.addRelatedTable("workOrder.workOrderCartArticle");
        slotQuery.addRelatedTable("workOrder.asset");
        slotQuery.addRelatedTable("workOrder.workLog");
        this.slot = await slotQuery.findFirst();
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let order = this.props.order;
        let backgroundRow = "";
        if (appState.layoutState.backgroundRowScheduled === this.slot.id) {
            backgroundRow = " rowSlotSeleccionada"
        }
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center ">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <div className="modal-push modal-push--height-full" style={{
                    display: "block", position: 'absolute', marginLeft: 'auto',
                    marginRight: 'auto', width: '50%', left: '0', right: '0',
                    top: '5%'
                }}>
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{t('Resumen')}<span>{" "}{t('Visita')}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="mt-2">
                            <div className="col-12">
                                <CreateAndModify
                                    createdBy={order.createdBy}
                                    createdAt={order.createdAt}
                                    code={order.code}
                                    getFormId={order.id}
                                    client={order.client != null ? order.client.name : null}
                                    fromModal={true}
                                />
                                <div className="table-edit table-edit--section">
                                    <table
                                        className="table visible-text table--section c-modal-info__cell__body__table mb-2">
                                        <OrderSummarySlot
                                            orderId={order.id}
                                            row={this.slot}
                                            {...this.props}
                                            fromEndModal={true}
                                            order={order}
                                        />
                                    </table>

                                    <table
                                        className="table visible-text table--section c-modal-info__cell__body__table">
                                        <tr className="thead">
                                            <th>{t("Tipo")}</th>
                                            <th>{t("O.trabajo")}</th>
                                            <th>{t("Estado")}</th>
                                            <th>{t("Equipo matrícula")}</th>
                                            <th>{t("Tipo")}</th>
                                            <th>{t("Subtipo")}</th>
                                            <th>{t("Inicio")}</th>
                                            <th>{t("Fin")}</th>
                                        </tr>
                                        {this.slot.workOrder?.map(workOrder => (
                                            <WorkOrderDetailRow
                                                workOrder={workOrder}
                                                slot={this.slot}
                                            >
                                                <tr>
                                                    <td colSpan={8}>
                                                        <OrderSummaryWorkLogs
                                                            workLogs={workOrder.workLog}
                                                            workOrder={workOrder}
                                                            slot={this.slot}
                                                            workOrderCartArticles={workOrder.workOrderCartArticle}
                                                        />
                                                    </td>
                                                </tr>
                                            </WorkOrderDetailRow>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer ">
                            <div className="row mt-4">
                                <div className="col-12 d-flex justify-content-end mb-2">
                                    <VsfButtonNew
                                        label={t("Salir")}
                                        className="btn btn-secondary "
                                        onClick={() => this.props.closeModal()}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </VsfModal>
        )
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(EndVisitModal)
