import React from 'react';
import { observer } from 'mobx-react';
import PageTitle from '../../components/PageTitle';
import SearchComponent from '../../components/list/SearchComponent';
import ListComponent from '../../components/list/ListComponent';
import PaginationComponent from "../../components/list/PaginationComponent";
import BaseList from "../base/BaseList";
import appState from "../../state/AppState";
import ModalListFilter from "../../subpages/ModalListFilter";
import withRouter from "react-router-dom/es/withRouter";
import translate from "../../translator/translate";
import t from "../../translator/translate";
import TextListItem from "../../components/list/TextListItem";
import WorkOrderStateListItem from "../../components/list/custom/WorkOrderStateListItem";
import ListItemSelect from "../../components/listaddons/ListItemsSelect";
import WorkOrderModel from "../../models/WorkOrderModel";
import DynamicGroupModel from "../../models/DynamicGroupModel";
import ZoneColorList from "../../components/list/ZoneColorList";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import WorkOrderListFilters from "./WorkOrderListFilters";
import ListPageCounts from "../../components/list/ListPageCounts";
import CreatedByListItem from "../../components/list/CreatedByListItem";
import DateListItem from "../../components/list/DateListItem";
import IsoDateListItem from "../../components/list/IsoDateListItem";
import MoreButtons from "../../components/MoreButtons";
import GqlErrors from "../../components/status/GqlErrors";
import RelatedTableConfig from "../../models/RelatedTableConfig";
import ClientListItem from "../../components/list/ClientListItem";
import util from "../../util/Util";

@observer
class WorkOrderList extends BaseList {


    constructor(props) {
        super(props);
        this.quickEdit = true;
        let zoneId = appState.userZoneFilters.currentFilter.zoneAsignedId;
        if (util.hasValue(zoneId)) {
            this.filterQueryList.push({
                fieldName: 'posZoneId',
                fieldValue: zoneId,
                filterOperator: "STRIN",
            });
        }
        if (util.hasValue(this.props.assetId)) {
            this.filterQueryList.push({ fieldName: 'assetId', fieldValue: this.props.assetId });
        }
        this.quickView = true;
        this.deleteFilter = this.deleteFilter.bind(this);
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.nameVariableStateFilter = "workOrderListFilters";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        // Typical usage (don't forget to compare props):
        let reload = false;
        let anyadido = false;
        let zoneId = appState.userZoneFilters.currentFilter.zoneAsignedId;
        for (let filterQuery of this.filterQueryList) {
            if (filterQuery.fieldName === "posZoneId") {
                anyadido = true;
            }
        }
        if (util.hasValue(zoneId)) {
            reload = true;
            if (!anyadido) {
                this.filterQueryList.push({
                    fieldName: 'posZoneId',
                    fieldValue: zoneId,
                    filterOperator: "STRIN",
                });
            } else {
                for (let i = 0; i < this.filterQueryList.length; i++) {
                    if (this.filterQueryList[i].fieldName === "posZoneId") {
                        this.filterQueryList[i] = {
                            fieldName: 'posZoneId',
                            fieldValue: zoneId,
                            filterOperator: "STRIN",
                        }
                    }
                }
            }
        } else if (anyadido) {
            let index = 0;
            for (let i = 0; i < this.filterQueryList.length; i++) {
                if (this.filterQueryList[i].fieldName === "posZoneId") {
                    index = i;
                }
            }
            this.filterQueryList.splice(index, 1);
            reload = true;
        }
        if (reload) {
            this.reloadList();
        }
    }

    getDefaultOrder() {
        return "lastVisitDate";
    }

    getDefaultOrderMode() {
        return "DESC";
    }

    getListFields() {
        let fieldsList = [
            {
                title: t("Código OT"),
                name: "code",
                link: true,
                component: (props) => <WorkOrderStateListItem workOrderCode={true} link={true} {...props} />,
                visible: true
            },
            {
                title: t("Estado"),
                name: "statusWorkOrder.value",
                component: (props) => <WorkOrderStateListItem  {...props} />,
                visible: true
            },
            {
                title: t("Tipo OT"),
                name: "typeWorkOrder.value",
                orderName:"typeWorkOrder.code",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Código Pedido"),
                name: "order.code",
                withOrder: false,
                component: (props) => <TextListItem link={true} onClick={(e) => this.openModalOrder(e)} {...props} />,
                visible: true
            },
            {
                title: t("Matrícula Equipo"),
                name: "asset.plate",
                link: false,
                component: (props) => <TextListItem align="left" link={true}
                                                    onClick={(e) => this.openModalAsset(e)}{...props} />,
                visible: true
            },
            {
                title: t("Tipo Equipo"),
                name: "asset.typeAsset.value",
                withOrder: false,
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Próxima planificación"),
                name: "nextScheduledDate",
                component: (props) => <DateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Establecimiento"),
                name: "client.name",
                withOrder: true,
                link: false,
                component: (props) => <ClientListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Técnico Principal"),
                name: "userAssignedMain.firstName",
                link: false,
                component: (props) => <CreatedByListItem link={true} onClick={(e) => this.openModalTechnician(e)}  {...props} />,
                visible: true
            },
            {
                title: t("Última fecha de ejecución"),
                name: "lastVisitDate",
                component: (props) => <IsoDateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Zona"),
                link: false,
                orderName:"zoneClient.code",
                name: "zoneClient.value",
                component: (props) => <ZoneColorList link={true} {...props} />,
                visible: true
            },
            {
                title: t("F. Modificación"),
                name: "createdAt",
                component: (props) => <IsoDateListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Subtipo OT"),
                name: "subTypeWorkOrder.value",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Subtipo Equipo"),
                name: "asset.subTypeAsset.value",
                withOrder: false,
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Fabricante"),
                name: "asset.manufacturer",
                withOrder:false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Modelo"),
                name: "asset.model",
                withOrder:false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Ha Sido Devuelta"),
                name: "hasReturned",
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Motivo devolución"),
                name: "reasonReturn.value",
                orderName:"reasonReturn.code",
                link: false,
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
            {
                title: t("Devuelta por"),
                name: "lastTechnicalReturn.firstName",
                component: (props) => <CreatedByListItem link={true} concatLastName {...props} />,
                visible: true
            },
            {
                title: t("Posible Garantía"),
                name: "warrantyPossible",
                component: (props) => <TextListItem fromWarranty link={true} {...props} />,
                visible: true
            },
            {
                title: t("Estado - Seguimiento de Devolución"),
                name: "returnStatusId",
                component: (props) => <TextListItem fromTypified link={true} {...props} />,
                visible: true
            },
            {
                title: t("Garantía Revisada"),
                name: "warrantyRevised",
                component: (props) => <TextListItem fromWarranty link={true} {...props} />,
                visible: true
            },
            {
                title: t("Garantía Aplicable"),
                name: "warrantyAccepted",
                component: (props) => <TextListItem fromWarrantyAccepted link={true} {...props} />,
                visible: true
            },
            {
                title: t("Código de Garantía de Fabricante"),
                name: "warrantyManufacturerCode",
                component: (props) => <TextListItem link={true} {...props} />,
                visible: true
            },
        ];
        if (!util.hasValue(this.props.assetId)) {
            fieldsList.push(
                {
                    title: t("Descripción de la Avería"),
                    name: "generalDescription",
                    component: (props) => <TextListItem link={true} {...props} />,
                    visible: true
                },
            )
        }

        let fieldDynamics = this.getFieldsDynamics(DynamicGroupModel.WORK_ORDER);
        let result = [...fieldsList, ...fieldDynamics];
        return result;
    }

    openModalAsset(asset) {
        return this.propsUtil.changeUrlRequest({
            "assetId": asset.assetId,
            "rightModal": "modalassets",
            "rightModalTab": "general",
            "fromRightModal": true
        });
    }

    openModalOrder(workOrder) {
        return this.propsUtil.changeUrlRequest({
            "orderId": workOrder.orderId,
            "rightModal": "modalorderls",
            "rightModalTab": "order",
            "fromRightModal": true
        });
    }
    openModalTechnician(workOrder) {
        return this.propsUtil.changeUrlRequest({
            "rightModal": "modaluserls",
            "userId": workOrder.assignedToMainId,
            "rightModalTab": "general"
        });
    }
    getListState() {
        return appState.workOrderState;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.userCacheState.loadUserCacheTechnicals();

    }

    getModelQuery() {
        let result = new WorkOrderModel();
        result.addRelatedTableOnlyFields("order", new RelatedTableConfig(["code"]));
        result.addRelatedTableOnlyFields("userAssignedMain", new RelatedTableConfig(["id", "firstName", "lastName"]));
        result.addRelatedTableOnlyFields("lastTechnicalReturn", new RelatedTableConfig(["id", "firstName", "lastName"]));
        result.addRelatedTableOnlyFields("client", new RelatedTableConfig(["id", "name", "posAddress", "posZoneId"]));
        result.addRelatedTableOnlyFields("asset", new RelatedTableConfig(["plate", "type", "subType"]));
        result.addRelatedTableOnlyFields("asset.typeAsset", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("asset.subTypeAsset", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("zoneClient", new RelatedTableConfig(["value", "color"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("asset.manufacturer", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("asset.model", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("statusWorkOrder", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("typeWorkOrder", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("reasonReturn", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        result.addRelatedTableOnlyFields("subTypeWorkOrder", new RelatedTableConfig(["value"], 'lang:"' + util.getLang() + '"'));
        return result;
    }

    openQuickEdit(row) {
        this.getListState().quickEdit.row = row;
        this.propsUtil.changeUrlRequest({
            workOrderId: row.id,
            rightModal: "modalworkorderls",
            rightModalTab: "workorder"
        })
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let props = this.props;
        let fields = this.stateListColumnsSelect.listFields;

        return (
            <InfoBlock componentObject={this}>

                {!util.hasValue(this.props.assetId) &&
                <>
                    <ListItemSelect stateListColumnsSelect={this.stateListColumnsSelect}
                                    name={this.graphOperation}
                                    posZoneId={appState.userZoneFilters.currentFilter.posZoneId}
                                    subtitle={t('Órdenes de Trabajo')}
                    />

                    <ModalListFilter
                        title={'Órdenes de Trabajo'} listComponent={this} stateFilter={this.stateListFilter}>
                        {this.renderFilters()}
                    </ModalListFilter>
                    <PageTitle
                        title={t("Órdenes de Trabajo")}
                        subtitle={t("Listado")}

                    ><MoreButtons
                        onReloadData={() => this.reloadData()}
                    />
                    </PageTitle>
                </>
                }
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <div className="main-content">
                    {!util.hasValue(this.props.assetId) &&
                    <>    <SearchComponent deleteFilter={this.deleteFilter}
                                           listState={this.getListState()}
                                           listData={this.getListData()}
                                           numEntriesPerPage={this.numEntriesPerPage}
                                           fields={fields}
                                           model={this.getModelQuery()}
                                           renderActionsList={() => this.renderActionsList()}
                                           includeSavedFavourites={this.includeSavedFavourites}
                                           includeChangeColumns={this.includeChangeColumns}
                                           onDownloadExcelOrCsv={e => this.onDownloadExcelOrCsv(e)}
                                           includeFilters={this.includeFilters}
                                           includeDownload={this.includeDownload}
                                           includeSearch={this.includeSearch}
                                           listComponent={this}
                                           favouriteModalOpen={this.state.favouriteModalOpen}
                                           toggleFavouriteModal={this.toggleFavouriteModal.bind(this)}
                                           stateListColumnsSelect={this.stateListColumnsSelect}
                                           onClickAplicarFiltros={() => this.onClickAplicarFiltros()}
                                           {...props}
                                           updateFilters={(e) => this.updateInputFilterEvent(e)}
                    />
                    </>
                    }
                    <ListComponent
                        quickView={this.quickView}
                        {...props}
                        withActions={this.withActions}
                        listState={this.getListState()}
                        listData={this.getListData()}
                        status={this.getListStatus()}
                        fields={fields}
                        urlForm={"/workOrder/form"}
                        openQuickEdit={(row) => this.openQuickEdit(row)}
                    />
                    <div className="o-flex-between mt-3">
                        <ListPageCounts numEntriesPerPage={this.numEntriesPerPage}
                                        listState={this.getListState()}
                                        listData={this.getListData()} {...props} />
                        <PaginationComponent
                            listData={this.getListData()}
                            numEntriesPerPage={this.numEntriesPerPage}
                            {...props}
                        />
                    </div>
                </div>


            </InfoBlock>
        );
    }

    renderFilters() {
        return (
            <React.Fragment>
                <WorkOrderListFilters key={"workOrderlist"} />
            </React.Fragment>
        )
    }


}

export default withRouter(WorkOrderList);
