import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';

const customStyles = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.3)",
    },
};

const customStyles1 = {
    overlay: {
        backgroundColor: "rgba(0,0,0,0.3)",
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

@observer
export default class VsfModal extends Component {
    body;

    constructor(props) {
        super(props);
        this.body = document.getElementsByTagName('body')[0];
    }

    closeModal() {
        this.body.classList.remove("ReactModal__Body--open");
        if (this.props.onCloseModal != null) {
            this.props.onCloseModal();
        }
    }

    render() {
        let isOpen = this.props.isOpen;
        if (isOpen) {
            this.body.classList.add("ReactModal__Body--open");
        }
        return (
            <Modal
                isOpen={isOpen}
                onRequestClose={() => this.closeModal()}
                className={this.props.className}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
                ariaHideApp={false}
                style={this.props.containerCentered ? customStyles1 : customStyles}>
                {this.props.children}
            </Modal>
        );
    }
}
