import React from 'react';
import appState from '../../state/AppState';
import {observer} from "mobx-react";
import TabsComponent from "../../components/fields/TabsComponent";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import translate from "../../translator/translate";
import GroupOfField from "../../components/layout/GroupOfField";
import {withRouter} from "react-router-dom";
import {computed} from 'mobx';
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util';
import CheckWorkDaysModel from "../../models/CheckWorkDaysModel";
import TabsData from "../../layout/TabsData";
import UserModel from "../../models/UserModel";
import PageTitle from "../../components/PageTitle";
import MoreButtons from "../../components/MoreButtons";
import DateInputMonthMobiscroll from "../../components/fields/DateInputMonthMobiscroll";
import BaseEditableTable from "../base/BaseEditableTable";
import UserCheckInRow from "./UserCheckInRow";
import TypifiedValueModel from "../../models/TypifiedValueModel";
import UserActions from "./UserActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class UserCheckInTable extends BaseEditableTable {
    nameMainType = "user";

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.tabsData = new TabsData();
        this.tabsData.isClient = appState.userState.isUserRoleClient();
        this.foreingKeyfield = "userId";
        let date = util.getMoment();
        date.set('date', 15).format("YYYY-MM-DD");
        this.state = {
            date,
            showMore: "",
            loading: true,
        }
    }

    @computed get mobxListado() {
        return appState.checkWorkDaysState.checkWorkDays;
    }

    setMobxListado(newValue) {
        appState.checkWorkDaysState.checkWorkDays = newValue;
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await appState.workLogState.loadWorkLogsOfUser(this.graphDataMainType.id);
        this.tabsData.isClient = util.perteneceA(appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "CLIENT").id, this.graphDataMainType.role);
        this.reloadTable();
        this.loadJornadaInicioAFin();
        this.setState({ loading: false })
    }

    getModelQuery() {
        return new UserModel();
    }

    getModelQueryForView() {
        let result = new UserModel();
        result.addRelatedTable("checkWorkDays");
        return result;
    }


    getModelTable() {
        return new CheckWorkDaysModel();
    }

    reloadTable() {
        appState.checkWorkDaysState.checkWorkDaysOrderBy = this.graphDataMainType.checkWorkDays;
    }

    loadJornadaInicioAFin() {
        let jornadas = [];
        let index = 0;
        for (let workDay of appState.checkWorkDaysState.checkWorkDaysOrderBy) {
            if (jornadas[index] == null) {
                jornadas[index] = { isEditing: false, editable: true }
            }
            if (workDay.action === CheckWorkDaysModel.START_DAY) {
                jornadas[index][CheckWorkDaysModel.START_DAY] = workDay
            }
            if (workDay.action === CheckWorkDaysModel.END_DAY) {
                jornadas[index][CheckWorkDaysModel.END_DAY] = workDay;
                index++
            }
        }
        appState.checkWorkDaysState.checkWorkDays = jornadas.reverse();
    }

    changeDate(e) {
        this.setState({ date: util.getMoment(e.target.value) })
    }

    renderImpl() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let user = this.graphDataMainType;
        let modalClasses = util.getModalClasses();
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>

                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                                 getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId() || this.props.userId}
                                tabs={this.tabsData.getDataUser()}
                                active="Fichajes"
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}
                        >
                            {this.getFormId() != null &&
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <UserActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={user}
                                             getModelQuery={() => this.getModelQuery()} fromForm />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }
                    <div className={'card mb-3'}>
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId() || this.props.userId}
                                tabs={this.tabsData.getDataUser()}
                                active="Fichajes"
                                fromRightModal={this.props.fromRightModal}
                            />
                            }


                            <GroupOfField
                                title={t('Fichajes Usuario')}
                                icon="fas fa-clock"
                            />
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <DateInputMonthMobiscroll
                                    prefix={"fas fa-calendar-alt"}
                                    value={this.state.date}
                                    dateWithoutMoment
                                    classGroup="input-group input-group--calendar"
                                    dateWithoutTimeZone={true} initialDay={15}
                                    onChange={(e) => this.changeDate(e)}
                                    name={"scheduleDate"}
                                    type={"text"}
                                />
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>

                                        <tr>
                                            <th scope="col">{t('Fecha')}</th>
                                            <th scope="col">{t('Inicio Jornada')}</th>
                                            <th scope="col">{t('Fin de Jornada')}</th>
                                            <th scope="col">{t('Tiempo Total')}</th>
                                            <th scope="col">{t('Comentarios')}</th>
                                            <th>&nbsp;</th>
                                            <th>&nbsp;</th>
                                        </tr>

                                        </thead>
                                        <tbody className="border-end">

                                        {this.mobxListado.map((timeItem, index) => (
                                            <UserCheckInRow
                                                row={timeItem}
                                                key={index} graphDataMainType={this.graphDataMainType}
                                                rowIndex={index}
                                                showMore={this.state.showMore}
                                                changeShowMore={(joranadaId) => this.setState({ showMore: joranadaId })}
                                                reload={(e) => this.reloadData(e)}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                                dateFilter={util.getMoment(this.state.date).format("YYYYMM")}
                                            />
                                        ))
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </SpinnerClipLoaderInPage>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        )
    }

}

export default withRouter(UserCheckInTable);

