import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import BaseForm from "../base/BaseForm";
import HistoryComponent from "../HistoryComponent";
import OrderModel from "../../models/OrderModel";
import translate from "../../translator/translate";
import MoreButtons from "../../components/MoreButtons";
import appState from '../../state/AppState';
import OrderActions from "./OrderActions";

@observer
class OrderHistory extends BaseForm {
    constructor(props) {
        super(props);
        this.tabsData = new TabsData();
        this.nameMainType = "order";
        this.initializeGraphData(this.nameMainType);
    }

    getModelQuery() {
        return new OrderModel();
    }

    getModelQueryForView() {
        let result = new OrderModel();
        result.addRelatedTable("budget");
        result.addRelatedTable("documents");
        result.addRelatedTable("originModel");
        result.addRelatedTable("client");
        result.addRelatedTable("clientPropietary");
        result.addRelatedTable("workOrder");
        result.addRelatedTable("workOrder.workOrderCartArticle");
        result.addRelatedTable("workOrderCartArticle");
        result.addRelatedTable("slot");
        result.addRelatedTable("request");
        result.addRelatedTable("contact");
        return result;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        let order = this.graphDataMainType;
        const t = translate;
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                title={t("Pedido") + " " + this.graphDataMainType.code}
                                subtitle={(this.graphDataMainType.client && this.graphDataMainType.client.name)}
                                mode={"view"}
                                onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons  integracionId={order.id}>
                                    <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={order} getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType))}
                                active={"Histórico"}
                                fromRightModal={this.props.fromRightModal}
                            />
                        </div>
                        :
                        <PageTitle
                            title={t("Pedido") + " " + this.graphDataMainType.code}
                            subtitle={(this.graphDataMainType.client && this.graphDataMainType.client.name)}
                        >
                            {this.graphDataMainType.id &&
                            <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                                <OrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)}model={order} getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                            }
                        </PageTitle>
                    }

                    <div className="card mb-3">
                        <div className="card-body">
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType))}
                                active={"Histórico"}
                            />
                            }
                            <HistoryComponent
                                id={this.getFormId()} graphDataMainType={this.graphDataMainType}
                                modelo={this.getModelQueryForView()}
                            />
                        </div>

                    </div>
                </InfoBlock>
            </div>
        );
    }

}

export default withRouter(OrderHistory)
