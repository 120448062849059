import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";
import util from "../util/Util";
import FormAssignmentModel from "../models/FormAssignmentModel";

class FormAssignmentState extends BaseModelState {

    @observable forms = [];
    @observable formsAssignments = [];

    async getFormsAssignments() {
        let queryModel = new FormAssignmentModel();
        queryModel._isOffline = true;
        queryModel.addRelatedTable("form");
        queryModel.addRelatedTable("form.formQuestion");
        this.formsAssignments = await queryModel.findPlainObject();

    }

    getOptionsFromFormAssignment(paramsToMatchOptionFlavorQuality) {
        let result = [];
        let matching = false;
        for (let option of this.formsAssignments) {
            matching = false;
            if (util.hasValue(paramsToMatchOptionFlavorQuality.workOrderSubType) && util.perteneceA(paramsToMatchOptionFlavorQuality.workOrderSubType, option.workOrderTypeIds)) {
                matching = true
            } else if (util.hasValue(paramsToMatchOptionFlavorQuality.workOrderType) && util.perteneceA(paramsToMatchOptionFlavorQuality.workOrderType, option.workOrderTypeIds)) {
                matching = true
            }
            // matching de tipo subtipo ok
            if (matching) {
                if (util.hasValue(option.assetTypeIds)) {
                    // compruebo tipo de equipo o subtipo de equipo
                    if (!util.hasValue(option.assetTypeIds) || util.perteneceA(paramsToMatchOptionFlavorQuality.assetSubType, option.assetTypeIds)) {
                        matching = true;
                    } else if (util.perteneceA(paramsToMatchOptionFlavorQuality.assetType, option.assetTypeIds)) {
                        matching = true;
                    } else {
                        matching = false;
                    }
                }
            }
            // matching tipo subtipo de equipo ok
            if (matching) {
                if (util.hasValue(option.assetModelIds)) {
                    if (util.perteneceA(paramsToMatchOptionFlavorQuality.assetModelId, option.assetModelIds)) {
                        matching=true;
                    } else {
                        matching=false;
                    }
                }
            }
            // matching asset model OK
            // this.log({getOptionsFromFormAssignment:1, paramsToMatchOptionFlavorQuality, matching, assetModelIds:option.assetModelIds})
            if (matching) {
                result.push({ ...option.form });
            }
        }
        return result;
    }
}

export default FormAssignmentState;
