import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import GroupOfField from '../../components/layout/GroupOfField'
import PageTitle from '../../components/PageTitle'
import appState from '../../state/AppState'
import BaseForm from '../base/BaseForm'
import FormButtons from '../../components/FormButtons'
import translate from '../../translator/translate'
import NetworkStatusInfo from '../../components/status/NetworkStatusInfo'
import Select2Component from '../../components/fields/Select2Component'
import TabsComponent from '../../components/fields/TabsComponent'
import TabsData from '../../layout/TabsData'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import OrderModel from '../../models/OrderModel'
import CreateAndModify from '../../components/fields/CreateAndModify'
import { observable } from 'mobx'
import PropsUtil from '../../util/PropsUtil'
import util from '../../util/Util'
import ContactModel from '../../models/ContactModel'
import PopUp from '../../components/modals/PopUp'
import PageTitleRightModal from '../../components/modals/PageTitleRightModal'
import AlertModal from '../../subpages/AlertModal'
import MoreButtons from '../../components/MoreButtons'
import InfoBlock from '../../components/InfoBlock'
import SlotModel from '../../models/SlotModel'
import AppLogger from '../../util/AppLogger'
import StatusOrderModal from '../../components/StatusOrderModal'
import ClientModel from '../../models/ClientModel'
import WorkLogModel from '../../models/WorkLogModel'
import WorkOrderCCForm from '../workOrders/WorkOrderCCForm'
import GqlErrors from '../../components/status/GqlErrors'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'
import SubcontractModel from '../../models/SubcontractModel'
import SlaModel from '../../models/SlaModel'
import OFFER from '../../config/OFFER'
import GqlWarnings from '../../components/status/GqlWarnings'
import OrderActions from './OrderActions'
import SpinnerClipLoaderInPage from '../../network/SpinnerClipLoaderInPage'

@observer
class OrderForm extends BaseForm {

  nameMainType = 'order'
  @observable dynamicGroup = {}
  @observable readOnlyPropietary = true
  @observable parentClientIdRolClient = ''
  @observable changeSelect = false

  constructor (props) {
    super(props)
    this.nameMainType = 'order'
    this.initializeGraphData(this.nameMainType)
    this.tabsData = new TabsData()
    //Si este campo está relleno, lo busca a la hora de obtener el registro
    this.queryFieldWithId = 'orderId'
    this.state = {
      errorFormulario: false,
      modalLoading: false,
      modalCancel: false,
      modalNotApproved: false,
      loading: true,
    }
  }

  async componentDidMountImpl () {
    await super.componentDidMountImpl()
    if (!util.hasValue(this.graphDataMainType.id)) {
      // await appState.clientCacheState.loadClientCache();
      if (this.graphDataMainType.storeId == null && !this.props.fromRightModal && this.getFormId() == null) {
        if (util.hasValue(this.propsUtil.getRequest('origin'))) {
          let origin = this.propsUtil.getRequest('origin')
          this.handleAutosuggestSelection('origin', origin)
        }
        if (util.hasValue(this.propsUtil.getRequest('storeId'))) {
          let clientId = this.propsUtil.getRequest('storeId')
          this.handleAutosuggestSelection('clientId', clientId)
          await appState.clientState.reloadClientFromClientId(clientId)
          await appState.contactState.reloadContactsFromClientId(clientId, util.hasValue(this.graphDataMainType.scheduledDate))
          this.graphDataMainType.client = appState.clientState.client
          this.graphDataMainType.clientPropietary = appState.clientState.client?.parentClient
          this.log({ client: appState.clientState.client })
          this.handleAutosuggestSelection('propietaryId', this.graphDataMainType.clientPropietary.id)
        }
      }
      if (appState.userState.isUserRoleClient()) {
        let status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'PDT_APPROVED').id
        this.handleAutosuggestSelection('status', status)
        let from = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_FROM, 'CLIENT').id
        this.handleAutosuggestSelection('from', from)
        this.parentClientIdRolClient = appState.loginState.user?.clientId
      }
    }
    this.setState({ loading: false })
    if (appState.userState.isUserRoleClient()) {
      this.changeSelect = true
    }
    try {
      await this.loadWorkLogTasks()
      await this.loadWorkLogCancelAndNotApproved()
    } catch (e) {
      this.log({
        loadWorkLogTasksError: 1,
        e
      })
    }
    await appState.configState.configGetDict()
    await appState.originState.getOrigins()
    appState.configState.getDictCustomMobile()
  }

  /**
   * The form has been saved.
   * model has the data saved
   */
  async formDidSave (model, previousId) {
    if (this.props.fromRightModal) {
      this.propsUtil.changeUrlRequest({
        'orderId': model.id
      })
    }
    await this.saveOrderTasks()
    let crearSlot = (util.hasValue(model.id) && util.esVacio(previousId)) || (appState.typifiedState.getObject(this.previousRowForDiscard.status).code === 'CANCELED' && appState.typifiedState.getObject(model.status).code === 'APPROVED')
    if (appState.typifiedState.getObject(this.previousRowForDiscard.status).code !== 'APPROVED' && appState.typifiedState.getObject(model.status).code === 'APPROVED' && this.graphDataMainType.slot?.length === 0) {
      crearSlot = true
    }
    if (crearSlot) {
      await this.crearNuevoSlotVacio(model, previousId)
      await appState.assetState.getCountSlotsFromOrder(this.graphDataMainType)
    }
  }

  async crearNuevoSlotVacio (model, previousId) {
    let slotModel = new SlotModel()
    slotModel.orderId = model.id
    slotModel.workOrderIds = '-1'
    slotModel.scheduledDuration = 1
    slotModel.slotUserRole = SlotModel.MAIN
    try {
      await slotModel.persist()
      this.graphDataMainType.slot = []
      this.graphDataMainType.slot.push(slotModel)
    } catch (e) {
      this.log('EXCEPCION=>')
      this.log(e)
    }
  }

  getModelQueryForView () {
    let result = new OrderModel()
    result.addRelatedTable('budget')
    result.addRelatedTable('documents')
    result.addRelatedTable('workOrderCartArticle')
    result.addRelatedTable('workOrder')
    result.addRelatedTable('workOrder.documents')
    result.addRelatedTable('workOrder.asset')
    result.addRelatedTable('workOrder.workOrderCartArticle')
    result.addRelatedTable('client')
    result.addRelatedTable('clientPropietary')
    result.addRelatedTable('originModel')
    result.addRelatedTable('slot')
    return result
  }

  getModelQuery () {
    return new OrderModel()
  }

  /**
   * El componente ya ha cargado el tipo de datos principal
   * @returns {Promise<void>}
   */
  async didReloadFormData (model) {
    await super.didReloadFormData(model)
    await appState.contactState.reloadContactsFromClientId(model.clientId, util.hasValue(model.scheduledDate))
  }

  onEditarContacto (order) {
    return this.propsUtil.changeUrlRequest({
      rightModal: 'modalposclients',
      'clientId': order.clientId,
      'orderId': order.id,
      'rightModalTab': 'contacts'
    })
  }

  async loadWorkLogTasks () {
    appState.workLogState.saveWorkLogOrder = false
    let arrTasks = []
    if (util.hasValue(this.graphDataMainType.id) &&
      this.graphDataMainType.id.dynamicField &&
      appState.configState.tableTasksOrder(JSON.parse(this.graphDataMainType.id.dynamicField).sapNotifType)) {
      let tasksSelectedDict = {}
      let workLogModel = new WorkLogModel()
      workLogModel.orderId = this.graphDataMainType.id
      workLogModel.action = WorkLogModel.TASKS_ORDER
      let workLogsTasks = await workLogModel.findPlainObject()
      for (let workLog of workLogsTasks) {
        if (workLog.action === WorkLogModel.TASKS_ORDER) {
          arrTasks = JSON.parse(workLog.data).orderTasks
          tasksSelectedDict = util.getDictSingleFromArray(arrTasks)
          tasksSelectedDict.workLogId = workLog.id
          this.log({
            loadWorkLogTasks: 1,
            workLog,
            result: JSON.parse(workLog.data)
          })
        }
      }
      appState.workLogState.tasksSelectedDict = tasksSelectedDict
    }
    appState.workLogState.workLogsTasksOfOrder = arrTasks
  }

  async loadWorkLogCancelAndNotApproved () {
    let arrCancelOrder = [{}]
    let arrNotApprovedOrder = [{}]
    if (util.hasValue(this.graphDataMainType.id) && (appState.typifiedState.getObject(this.graphDataMainType.status).code === 'CANCELED' ||
      appState.typifiedState.getObject(this.graphDataMainType.status).code === 'NOT_APPROVED')) {
      let orderCancelSelectedDict = {}
      let orderNotApprovedSelectedDict = {}
      let workLogModel = new WorkLogModel()
      workLogModel.orderId = this.graphDataMainType.id
      workLogModel.filters = [
        {
          'fieldName': 'action',
          'fieldValue': (WorkLogModel.CANCELATION_ORDER + ',' + WorkLogModel.NOT_APPROVED_ORDER),
          'filterOperator': 'STRIN'
        }
      ]
      let workLogsTasks = await workLogModel.findPlainObject()
      this.log({
        loadWorkLogCancelAndNotApproved: 1,
        workLogsTasks
      })
      for (let workLog of workLogsTasks) {
        if (workLog.action === WorkLogModel.CANCELATION_ORDER) {
          arrCancelOrder = JSON.parse(workLog.data)[workLogModel.nameForData(WorkLogModel.CANCELATION_ORDER)]
          orderCancelSelectedDict = util.getDictSingleFromArray(arrCancelOrder)
          orderCancelSelectedDict.workLogId = workLog.id
        }
        if (workLog.action === WorkLogModel.NOT_APPROVED_ORDER) {
          arrNotApprovedOrder = JSON.parse(workLog.data)[workLogModel.nameForData(WorkLogModel.NOT_APPROVED_ORDER)]
          orderNotApprovedSelectedDict = util.getDictSingleFromArray(arrNotApprovedOrder)
          orderNotApprovedSelectedDict.workLogId = workLog.id
        }
      }
      appState.workLogState.orderCancelSelectedDict = orderCancelSelectedDict
      appState.workLogState.orderNotApprovedSelectedDict = orderNotApprovedSelectedDict
    }

    appState.workLogState.workLogsOrderCancelOfOrder = arrCancelOrder
    appState.workLogState.workLogsOrderNotApprovedOrder = arrNotApprovedOrder
  }

  async onChangeClientId (clientId) {
    let order = this.graphDataMainType
    await appState.contactState.reloadContactsFromClientId(order.clientId, util.hasValue(order.scheduledDate))
    if (util.hasValue(clientId)) {
      this.handleAutosuggestSelection('clientId', clientId)
      await appState.clientState.reloadClientFromClientId(clientId)
      this.graphDataMainType.client = appState.clientState.client
    }
  }

  getContactFromContactId (contactId) {
    let result = {}
    for (let contact of appState.contactState.contacts) {
      if (util.hasValue(contactId)) {
        if (contact.id == contactId) {
          result = contact
          break
        }
      }
    }
    return result
  }

  getOptionsContact (fromContact) {
    let result = []
    for (let contactJs of appState.contactState.contacts) {
      let contactModel = new ContactModel()
      contactModel.hidrate(contactJs)
      if (fromContact && util.hasValue(contactModel.clientId)) {
        result.push({
          'value': contactModel.id,
          'label': contactModel.getFullName()
        })
      } else if (!fromContact) {
        result.push({
          'value': contactModel.id,
          'label': contactModel.getFullName()
        })
      }
    }
    return result
  }

  getZoneFromAppState (storeId) {
    let result = ''
    if (util.hasValue(storeId)) {
      let client = this.graphDataMainType.client || appState.clientState.client
      result = appState.typifiedState.getValue(client?.posZoneId)
    }
    return result
  }

  getUbicacionFromAppState (storeId) {
    let result = ''
    if (util.hasValue(storeId)) {
      let client = this.graphDataMainType.client || appState.clientState.client
      result = client?.posAddress || ''
    }
    return result

  }

  updateInputStatusEvent (e) {
    this.updateInputEvent(e)
    if (appState.typifiedState.getObject(this.graphDataMainType.status).code === 'CANCELED') {
      if (util.hasValue(this.graphDataMainType.id)) {
        this.setState({ modalCancel: true })
      }
    } else if (appState.typifiedState.getObject(this.graphDataMainType.status).code === 'NOT_APPROVED') {
      if (util.hasValue(this.graphDataMainType.id)) {
        this.setState({ modalNotApproved: true })
      }
    }
  }

  /**
   * Obtiene los Clients en cada llamada de Autosuggest y los transforma en array
   * @param value
   * @param firstCall
   * @returns Array
   */
  async getClientsSuggestions (value, firstCall) {
      let clientArray = []
      // Abortamos la anterior llamada
      this.abortFetch()
      try {
          // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
          this.initializeAbortController()
          let clientQuery = new ClientModel()
          clientQuery.addRelatedTable('parentClient')
          if (firstCall) {
              clientQuery.id = this.graphDataMainType.clientId
          } else if (util.hasValue(value)) {
              clientQuery.isPos = true
              clientQuery.filters = [
                  {
                      'fieldName': 'name,code',
                      'fieldValue': value,
                      'filterOperator': 'SUBSTR'
                  },
              ]
          } else if (util.hasValue(this.parentClientIdRolClient)) {
              this.log('3')
              clientQuery.isPos = true
              clientQuery.parentId = this.parentClientIdRolClient
          }
          let client = await clientQuery.findPlainObject()
          client.map((client) => {
              let clientObject = {}
              clientObject.label = (client.code || '') + ' - ' + client.name
              clientObject.value = client
              clientArray.push(clientObject)
          })
      } catch (e) {
          this.log('Error => ' + e)
      }
      return clientArray
  }

  async getSubcontractsSuggestions (value, firstCall) {
    let subcontractArray = []
    // Abortamos la anterior llamada
    this.abortFetch()
    try {
      // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
      this.initializeAbortController()
      let subcontractQuery = new SubcontractModel()
      if (firstCall) {
        subcontractQuery.id = this.graphDataMainType.subcontractId
      } else {
        subcontractQuery.filters = [
          {
            'fieldName': 'name',
            'fieldValue': value,
            'filterOperator': 'SUBSTR'
          },
          {
            'fieldName': 'status',
            'fieldValue': 'ACTIVE',
            'filterOperator': 'EQ'
          },
        ]
      }
      let subcontracts = await subcontractQuery.findPlainObject()
      for (let subcontract of subcontracts) {
        let subcontractObject = {}
        subcontractObject.label = (subcontract.name || '')
        subcontractObject.value = subcontract.id
        subcontractArray.push(subcontractObject)
      }
    } catch (e) {
      this.log('Error => ' + e)
    }
    return subcontractArray
  }

  async getSlasSuggestions (value, firstCall) {
    let slaArray = []
    // Abortamos la anterior llamada
    this.abortFetch()
    try {
      // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
      this.initializeAbortController()
      let slaQuery = new SlaModel()
      if (firstCall) {
        slaQuery.id = this.graphDataMainType.slaId
      } else {
        slaQuery.filters = [
          {
            'fieldName': 'name',
            'fieldValue': value,
            'filterOperator': 'SUBSTR'
          },
        ]
      }
      let slas = await slaQuery.findPlainObject()
      for (let sla of slas) {
        let slaObject = {}
        slaObject.label = sla.name
        slaObject.value = sla
        slaArray.push(slaObject)
      }
    } catch (e) {
      this.log('Error => ' + e)
    }
    return slaArray
  }

  async getPropietarysSuggestions (value, firstCall) {
    let clientArray = []
    // Abortamos la anterior llamada
    this.abortFetch()
    try {
      // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
      this.initializeAbortController()
      let clientQuery = new ClientModel()
      if (firstCall) {
        clientQuery.id = this.graphDataMainType.propietaryId
      } else {
        // clientQuery.isPos = false;
        clientQuery.filters = [
          {
            'fieldName': 'companyName',
            'fieldValue': value,
            'filterOperator': 'SUBSTR'
          },
          {
            'fieldName': 'isPos',
            'fieldValue': 'false',
            'filterOperator': 'EQ'
          },
        ]
      }
      let client = await clientQuery.findPlainObject()
      client.map((client) => {
        let clientObject = {}
        clientObject.label = client.companyName
        clientObject.value = client
        clientArray.push(clientObject)
      })
    } catch (e) {
      this.log('Error => ' + e)
    }
    return clientArray
  }

  renderImpl () {
    const t = translate
    this.propsUtil = new PropsUtil(this.props)
    let order = this.graphDataMainType
    if (order == null) {
      order = {}
    }
    let errorsMapped = this.getGraphErrorsFromStatus()
    if (this.graphStatus.mutationError > 0) {
      this.state.errorFormulario = true
    }
    let assetId = this.propsUtil.getRequest('assetId')
    let modalClasses = util.getModalClasses()

    return (
      <div className={this.props.fromRightModal ? modalClasses.push : ''}>
        <InfoBlock componentObject={this}>
          {this.props.fromRightModal ?
            <div className={modalClasses.head}>
              <PageTitleRightModal componentObject={this}
                                   title={this.getPageTitle()}
                                   subtitle={this.getPageSubtitle()}
                                   mode={'view'}
                                   onCloseModal={() => this.onCloseModal()}>
                {this.graphDataMainType.id &&
                <MoreButtons>
                  <OrderActions integracionId={order.id}
                                setErrors={(errors) => this.setErrors(errors)} model={order}
                                getModelQuery={() => this.getModelQuery()}
                                fromRightModal={this.props.fromRightModal}/>
                </MoreButtons>
                }
              </PageTitleRightModal>
              <TabsComponent
                id={this.graphDataMainType.id}
                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                active="Datos Generales"
                alarm={2}
                fromRightModal={this.props.fromRightModal}
              />
            </div>
            :
            <PageTitle
              title={this.getPageTitle()}
              subtitle={this.getPageSubtitle()}>
              {this.graphDataMainType.id &&
              <MoreButtons onReloadData={() => this.reloadData()} integracionId={order.id}>
                <OrderActions fromRightModal={this.props.fromRightModal}
                              setErrors={(errors) => this.setErrors(errors)} model={order}
                              getModelQuery={() => this.getModelQuery()} />
              </MoreButtons>
              }
            </PageTitle>
          }
          <div className="card mb-3">
            <div className={modalClasses.body}>
              {!this.props.fromRightModal &&
              <TabsComponent
                id={this.graphDataMainType.id}
                tabs={this.tabsData.getDataOrder(appState.configState.checkOrderCocaCola(order))}
                active={'Datos Generales'}
                fromRightModal={this.props.fromRightModal}
              />
              }
              <NetworkStatusInfo //loading={this.graphStatus.mutationLoading}
                error={this.graphStatus.mutationError}
                working={this.graphStatus.networkWorking} />

              <form
                className="model-form"
                name="formulario"
                noValidate
                onSubmit={(e) => this.handleFormSubmit(e)}>
                <div className={'card'}>
                  <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                  <GqlWarnings warnings={this.gqlWarnings}
                               onSaveWarning={() => this.saveWithWarnings()}
                               key={this.gqlWarnings}
                               setWarnings={(warnings) => this.setWarnings(warnings)} />
                  <GroupOfField
                    title={t('Datos') + '.'}
                    subtitle={t('Solicitud')}
                    icon="fas fa-file-import">
                    <CreateAndModify
                      updatedAt={order.updatedAt}
                      updatedBy={order.updatedBy}
                      createdBy={order.createdBy}
                      createdAt={order.createdAt}
                      code={order.code}
                      fromOrder={true}
                      getFormId={order.id}
                    />
                  </GroupOfField>
                  <SpinnerClipLoaderInPage loading={this.state.loading}>
                    {(appState.configState.checkOrderCocaCola(order)) ?
                      this.renderFormCC(order)
                      :
                      this.renderForm(order)
                    }
                  </SpinnerClipLoaderInPage>
                </div>
              </form>
              {!this.state.loading &&
              <>
                {(appState.configState.checkOrderCocaCola(order) || util.hasValue(assetId)) &&
                this.renderWorkOrderForm(order)
                }
              </>
              }
            </div>
          </div>

        </InfoBlock>
        {/*{this.props.children}*/}
      </div>

    )
  }

  log (msg) {
    AppLogger.get().debug(msg, this)
  }

  renderForm (order) {
    const t = translate
    let id = this.getFormId() || this.props.id
    let errorsMapped = this.getGraphErrorsFromStatus()
    if (this.graphStatus.mutationError > 0) {
      this.state.errorFormulario = true
    }
    let valueLabelContacts = this.getOptionsContact(true)
    let valueLabelRequest = this.getOptionsContact()
    let ubicacion = this.getUbicacionFromAppState(order.clientId) || ''
    let zona = this.getZoneFromAppState(order.clientId) || ''
    let relatedInfo = ''
    if (util.hasValue(ubicacion)) {
      relatedInfo = ubicacion
    }
    let assetId = this.propsUtil.getRequest('assetId')
    if (zona) {
      relatedInfo += ' - ' + zona
    }
    return (
      <div className={'row'}>

        <Select2Component
          value={order.status}
          onChange={(e) => this.updateInputStatusEvent(e)}
          name={'status'} isClearable={false}
          title={t('Estado')} required
          editable={this.editable} readOnly={appState.userState.isUserRoleClient()}
          classGroup={'col-md-3'}
          options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ORDER_STATUS)}
          errors={errorsMapped.status}
        />
        {!appState.userState.isUserRoleClient() &&
        <Select2Component
          value={order.originModel?.name}
          onChange={(event) => {
            let originSelected = event.target.value
            this.log({ originSelected })
            if (util.hasValue(originSelected)) {
              this.handleAutosuggestSelection('originId', originSelected)
              order.originModel = event.target.selectedOption.obj
            } else {
              order.originId = ''
              order.originModel = {}
            }
          }}
          name={'originId'}
          title={t('Origen')}
          options={appState.originState.labelValuesForSelect}
          readOnly={util.hasValue(this.graphDataMainType.id)}
          classGroup={'col-md-3 col-lg-3'}
          editable={this.editable}
          errors={errorsMapped.originId}
          placeholder={t('Seleccionar')}
        />
        }
        {appState.typifiedState.getObject(this.graphDataMainType.status).code === 'CANCELED' &&
        <InputTypeComponent
          value={appState.workLogState.workLogsOrderCancelOfOrder?.[0]?.value}
          editable={this.editable}
          title={t('Motivo de cancelacion')}
          classGroup={'col-md-12'}
          type={'text'}
          readOnly={true}
        />
        }
        {appState.typifiedState.getObject(this.graphDataMainType.status).code === 'NOT_APPROVED' &&
        <InputTypeComponent
          value={appState.workLogState.workLogsOrderNotApprovedOrder?.[0]?.value}
          editable={this.editable}
          title={t('Motivo de No aprobado')}
          classGroup={'col-md-12'}
          type={'text'}
          readOnly={true}
        />
        }
        <InputTypeComponent
          value={order.externalCode}
          onChange={(e) => this.updateInputEvent(e)}
          name={'externalCode'}
          title={t('Código externo')}
          classGroup={'col-md-3'}
          editable={this.editable}
          type={'text'}
          info={!this.props.fromRightModal && t('Propio del cliente')}
          errors={errorsMapped.externalCode}
        />
        <Select2Component
          value={order.from}
          onChange={(e) => this.updateInputEvent(e)}
          name={'from'} isClearable
          title={t('Medio de entrada')}
          readOnly={appState.userState.isUserRoleClient()}
          editable={this.editable}
          classGroup={'col-md-3'}
          options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ORDER_FROM)}
          errors={errorsMapped.from}
        />
              <SelectSuggestComponent
                  value={order.client?.name} required concatLabel={(order.client?.code || '') + ' - '}
                  key={order.client?.name}
                  changeSelect={this.changeSelect}
                  onChange={(event) => {
                      let clientSelected = event.target.value
                      if (util.hasValue(clientSelected.id)) {
                          this.handleAutosuggestSelection('clientId', clientSelected.id)
                          this.handleAutosuggestSelection('propietaryId', clientSelected.parentId)
                          this.onChangeClientId()
                          order.client = clientSelected
                          order.clientPropietary = clientSelected.parentClient
                      } else {
                          order.clientId = ''
                          order.client = {}
                      }
                  }}
                  readOnly={util.hasValue(this.graphDataMainType.id)}
                  name={'clientId'}
                  editable={this.editable}
                  title={t('Establecimiento')}
                  classGroup={'col-md-4'}
                  loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
                  placeholder={t('Escribe...')}
                  relatedInfo={relatedInfo}
              />
              {!appState.userState.isUserRoleClient() &&
        <>
          <SelectSuggestComponent
              value={order.clientPropietary?.companyName || t('Sin asignar')}
              key={order.clientPropietary?.companyName}
              onChange={(event) => {
                  let clientSelected = event.target.value
                  if (util.hasValue(clientSelected.id)) {
                      this.handleAutosuggestSelection('propietaryId', clientSelected.id)
                  } else {
                      this.handleAutosuggestSelection('propietaryId', '')
                  }
              }}
              name={'propietaryId'}
              title={t('Cliente Fiscal')}
              readOnly={this.readOnlyPropietary}
              classGroup={'col-md-4 col-lg-4'}
              editable={this.editable}
              errors={errorsMapped.propietaryId}
              loadSuggestions={(value, firstCall) => this.getPropietarysSuggestions(value, firstCall)}
              placeholder={t('Escribe...')}

          />
          <div className={this.props.fromRightModal ? 'pr-0 pl-0 col-md-2' : 'col-md-2 pl-0'}>
            <div className={'button-form-group mb-0  width-text-edit-client'}>

                    <span className="link-underline ico ico--edit"
                          onClick={() => this.readOnlyPropietary = !this.readOnlyPropietary}
                    >{t('Cambiar Cliente Fiscal')}</span>
            </div>
          </div>
        </>
        }

        <Select2Component
          value={order.requestContactId}
          onChange={(e) => this.updateInputEvent(e)}
          name={'requestContactId'}
          title={t('Nombre (Solicitante)')} required
          editable={this.editable}
          classGroup={'col-md-4'}
          readOnly={!util.hasValue(order.clientId) || util.hasValue(order.scheduledDate)}
          options={valueLabelRequest}
          errors={errorsMapped.requestContactId}
          forceMedia={this.props.fromRightModal ? 'md' : ''}
          relatedInfo={util.getString(this.getContactFromContactId(order.requestContactId).phoneNumber)}
        />

        <Select2Component
          value={order.contactId}
          onChange={(e) => this.updateInputEvent(e)}
          name={'contactId'}
          title={t('Nombre (Contacto)')}
          classGroup={'col-md-4'}
          editable={this.editable}
          readOnly={!util.hasValue(order.clientId)}
          options={valueLabelContacts}
          errors={errorsMapped.contactId}
          relatedInfo={util.getString(this.getContactFromContactId(order.contactId).phoneNumber)}
          forceMedia={this.props.fromRightModal ? 'md' : ''}
        />
        <div className={this.props.fromRightModal ? 'pr-0 pl-0 col-md-2' : 'col-md-2 pl-0'}>
          <div className={'button-form-group mb-0  width-text-edit-client'}>
            {order.clientId != null ?
              <span className="link-underline ico ico--edit"
                    onClick={() => {
                      this.onEditarContacto(order)
                    }}
              >{t('Editar Datos de Cliente')}</span>
              :
              <span className="link-underline disabled prohibido ico ico--edit"
              >{t('Editar Datos de Cliente')}</span>
            }
          </div>
        </div>

        {OFFER.new_models &&
        <>
          {!appState.userState.isUserRoleSubcontract() && !appState.userState.isUserRoleClient() &&

          <SelectSuggestComponent
            value={order.subcontractId}
            onChange={(event) => {
              let subcontractSelected = event.target.value
              if (util.hasValue(subcontractSelected.id)) {
                this.handleAutosuggestSelection('subcontractId', subcontractSelected.id)
                order.subcontractModel = subcontractSelected
              } else {
                order.subcontractId = ''
                order.subcontractModel = {}
              }
            }}
            name={'subcontractId'}
            title={t('Subcontrata')}
            classGroup={'col-md-4 col-lg-4'}
            editable={this.editable}
            errors={errorsMapped.subcontractId}
            loadSuggestions={(value, firstCall) => this.getSubcontractsSuggestions(value, firstCall)}
            placeholder={t('Escribe...')}

          />
          }
          {!appState.userState.isUserRoleClient() &&
          <SelectSuggestComponent
            value={order.slaId}
            onChange={(event) => {
              let slaSelected = event.target.value
              if (util.hasValue(slaSelected.id)) {
                this.handleAutosuggestSelection('slaId', slaSelected.id)
                order.slaModel = slaSelected
              } else {
                order.slaId = ''
                order.slaModel = {}
              }
            }}
            name={'slaId'}
            title={t('SLA')}
            classGroup={'col-md-4 col-lg-4'}
            editable={this.editable}
            errors={errorsMapped.slaId}
            loadSuggestions={(value, firstCall) => this.getSlasSuggestions(value, firstCall)}
            placeholder={t('Escribe...')}

          />
          }
        </>
        }
        {!util.hasValue(assetId) &&
        <InputTypeComponent
          value={order.comments}
          onChange={(e) => this.updateInputEvent(e)}
          name={'comments'}
          editable={this.editable}
          title={t('Comentarios')}
          classGroup={'col-md-12 col-lg-12'}
          type={'text'}
          info={t('Ej: Por favor, avisar el día antes de la visita')}
          errors={errorsMapped.comments}
        />
        }
        {!util.hasValue(assetId) &&
        <>
          {this.props.fromRightModal ?
            <>
              <div className={'fixed-bottom-space-maker'} />
              <div className="fixed-bottom mr-3 mt-3">
                <FormButtons id={id} formStatus={this.graphStatus} />
              </div>
            </>
            :
            <div className="col-12">
              <FormButtons id={id} formStatus={this.graphStatus} />
            </div>
          }
        </>
        }
      </div>
    )
  }

  renderFormCC (order) {
    const t = translate
    let errorsMapped = this.getGraphErrorsFromStatus()
    if (this.graphStatus.mutationError > 0) {
      this.state.errorFormulario = true
    }
    let ubicacion = this.getUbicacionFromAppState(order.clientId) || ''
    let zona = this.getZoneFromAppState(order.clientId) || ''
    let relatedInfo = ''
    if (util.hasValue(ubicacion)) {
      relatedInfo = ubicacion
    }
    if (zona) {
      relatedInfo += ' - ' + ubicacion
    }
    return (
      <div className={'row'}>
        <Select2Component
          value={order.status}
          editable={this.editable}
          onChange={(e) => this.updateInputStatusEvent(e)}
          name={'status'} isClearable={false}
          title={t('Estado')} required
          classGroup={'col-md-3'}
          options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ORDER_STATUS)}
          errors={errorsMapped.status}
        />
        {!appState.userState.isUserRoleClient() &&
        <Select2Component
          value={order.originModel?.name}
          onChange={(event) => {
            let originSelected = event.target.value
            this.log({ originSelected })
            if (util.hasValue(originSelected)) {
              this.handleAutosuggestSelection('originId', originSelected)
              order.originModel = event.target.selectedOption.obj
            } else {
              order.originId = ''
              order.originModel = {}
            }
          }}
          name={'originId'}
          title={t('Origen')}
          options={appState.originState.labelValuesForSelect}
          readOnly={util.hasValue(this.graphDataMainType.id)}
          classGroup={'col-md-3 col-lg-3'}
          editable={this.editable}
          errors={errorsMapped.originId}
          placeholder={t('Seleccionar')}
        />
        }
        <Select2Component
          value={order.dynamicField && JSON.parse(order.dynamicField).sapNotifType}
          onChange={(e) => this.updateInputEventDynamicFields(e.target.name, e.target.value, { code: 'CcepAsset' })}
          classGroup={'col-md-6 col-xl-3'}
          editable={this.editable}
          name={'sapNotifType'}
          title={t('Tipo de aviso')}
          readOnly={util.hasValue(this.graphDataMainType.id)}
          type={'text'}
          options={appState.configState.notifTypeForSelect}
          className="form-control"
        />

        <InputTypeComponent
          editable={this.editable}
          value={order.externalCode}
          onChange={(e) => this.updateInputEvent(e)}
          name={'externalCode'}
          title={t('Código externo')}
          classGroup={'col-md-3'}
          type={'text'}
          info={!this.props.fromRightModal && t('Propio del cliente')}
          errors={errorsMapped.externalCode}
        />
        {appState.typifiedState.getObject(this.graphDataMainType.status).code === 'CANCELED' &&
        <InputTypeComponent
          value={appState.workLogState.workLogsOrderCancelOfOrder?.[0]?.value}
          editable={this.editable}
          title={t('Motivo de cancelacion')}
          classGroup={'col-md-12'}
          type={'text'}
          readOnly={true}
        />
        }
        {appState.typifiedState.getObject(this.graphDataMainType.status).code === 'NOT_APPROVED' &&
        <InputTypeComponent
          value={appState.workLogState.workLogsOrderNotApprovedOrder?.[0]?.value}
          editable={this.editable}
          title={t('Motivo de No aprobado')}
          classGroup={'col-md-12'}
          type={'text'}
          readOnly={true}
        />
        }
        <SelectSuggestComponent
          value={order.client?.name} required
          concatLabel={(order.client?.code || '') + ' - '}
          key={order.client?.name}
          changeSelect={this.changeSelect}
          onChange={(event) => {
            let clientSelected = event.target.value
            this.log(clientSelected)
            if (util.hasValue(clientSelected.id)) {
              this.handleAutosuggestSelection('clientId', clientSelected.id)
              this.handleAutosuggestSelection('propietaryId', clientSelected.parentId)
              this.onChangeClientId()
              order.client = clientSelected
              order.clientPropietary = clientSelected.parentClient
            } else {
              order.clientId = ''
              order.client = {}
            }
          }}
          readOnly={util.hasValue(this.graphDataMainType.id)}
          name={'clientId'}
          editable={this.editable}
          title={t('Establecimiento')}
          classGroup={'col-md-4'}
          loadSuggestions={(value, firstCall) => this.getClientsSuggestions(value, firstCall)}
          placeholder={t('Escribe...')}
          relatedInfo={relatedInfo}
        />
        <InputTypeComponent
          value={order.dynamicField && JSON.parse(order.dynamicField)['sapContact1']}
          onChange={(e) => this.updateInputEventDynamicFields(e.target.name, e.target.value,
            { code: appState.dynamicGroupState.dynamicsFieldsDict['sapContact1']?.codeGroup })}
          classGroup={'col-md-4'}
          editable={this.editable}
          name={'sapContact1'}
          title={appState.dynamicGroupState.dynamicsFieldsDict['sapContact1']?.label || t('sapContact1')}
          type={'text'}
          className="form-control"
        />
        <InputTypeComponent
          value={order.dynamicField && JSON.parse(order.dynamicField)['sapPhoneNumber1']}
          onChange={(e) => this.updateInputEventDynamicFields(e.target.name, e.target.value,
            { code: appState.dynamicGroupState.dynamicsFieldsDict['sapPhoneNumber1']?.codeGroup })}
          classGroup={'col-md-4'}
          editable={this.editable}
          name={'sapPhoneNumber1'}
          title={appState.dynamicGroupState.dynamicsFieldsDict['sapPhoneNumber1']?.label || t('sapPhoneNumber1')}
          type={'text'}
          className="form-control"
        />
      </div>
    )
  }

  renderWorkOrderForm (order) {
    if (order.workOrder == null) {
      order.workOrder = [{}]
    }
    let origin = this.propsUtil.getRequest('origin')
    return (
      <>
        <WorkOrderCCForm fromOrderForm={true}
                         changeFormOrder={this.changeFormOrder}
                         updateInputEventDynamicFields={(a, b, c) => this.updateInputEventDynamicFields(a, b, c)}
                         changeClient={(e) => this.onChangeClientId(e)}
                         origin={origin}
                         updateInputEvent={(e) => this.updateInputEvent(e)}
                         key={order.workOrder[order.workOrder.length - 1]?.id}
                         order={order}
                         workOrder={order.workOrder[order.workOrder.length - 1]}
                         saveOrder={(e) => this.handleFormSubmit(e, true)} />
      </>
    )
  }

  async saveOrderTasks () {
    if (appState.workLogState.saveWorkLogOrder) {
      await appState.workLogState.insertOrderTasks(this.graphDataMainType.clientId, this.graphDataMainType.id,
        appState.workLogState.workLogsTasksOfOrder)
      appState.workLogState.saveWorkLogOrder = false
    }
  }

  renderModals () {
    const t = translate
    let order = this.graphDataMainType
    if (order == null) {
      order = {}
    }
    return (
      <>
        {this.state.modalCancel &&
        <StatusOrderModal
          title={t('Motivo de cancelación')}
          textButton={'No cancelar el pedido'}
          textCancelar={'Cancelar'}
          orderModel={order} cocaCola={appState.configState.checkOrderCocaCola(order)}
          openModal={this.state.modalCancel} cancelModal
          handleFormSubmit={() => this.handleFormSubmit()}
          discardModal={() => {
            this.handleAutosuggestSelection('status', this.previousRowForDiscard.status)
            this.setState({ modalCancel: false })
          }}
          closeModal={() => this.setState({ modalCancel: false })}
        />
        }
        {this.state.modalNotApproved &&
        <StatusOrderModal
          title={t('Motivo de no aprobado')}
          textButton={'No rechazar el pedido'}
          textCancelar={'Rechazar'}
          orderModel={order} notApprovedModal
          openModal={this.state.modalNotApproved}
          handleFormSubmit={() => this.handleFormSubmit()}
          discardModal={() => {
            this.handleAutosuggestSelection('status', this.previousRowForDiscard.status)
            this.setState({ modalNotApproved: false })
          }}
          closeModal={() => this.setState({ modalNotApproved: false })}
        />
        }
        <AlertModal isOpen={this.state.rightModal && this.state.popUp != ''}
                    onCloseModal={() => this.setState({
                      rightModal: false,
                      popUp: ''
                    })}>
          {this.state.popUp == 'change' &&
          <PopUp
            title={t('Va a salir sin guardar')}
            icon="exclamationTriangleIcon"
            text={t('Guarde los cambios del pedido antes de editar los datos del cliente')}
            label2={'Salir'}
            onClick2={() => this.setState({
              rightModal: false,
              popUp: ''
            })}
          />
          }
        </AlertModal>

      </>
    )
  }

}

export default withRouter(OrderForm)
