import React, {Component} from 'react';
import {observer} from "mobx-react/index";
import translate from "../translator/translate";

@observer
class PageMaintenance extends Component {


    render() {
        const t = translate;
        return (
            <section style={{ overflowX: 'hidden' }}>
                <div className="login d-flex justify-content-center" style={{ height: '100vh' }}>
                    <div className="row" style={{ alignContent: 'center' }}>
                        <img className="shadow " src="/img/maintenance.png"
                             alt="mantenimiento" />
                        <div className="column mr-5" style={{ padding: '30px', alignSelf: 'center' }}>
                            <h1 style={{ backgroundColor: '#fce1b5' }}>{t("Estamos trabajando para mejorar el servicio.")}</h1>
                            <span className="js-info"
                                  style={{
                                      backgroundColor: '#fce1b5',
                                      fontSize: 17
                                  }}>{t("Disculpa las molestias.")}</span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default PageMaintenance

