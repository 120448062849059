import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";

export default class OriginModel extends AbstractModel {

    name;
    description;
    customizedTypified;

    nameMainType = "origin";
    graphFindByIdOperation = "origin";
    graphFindOperation = "originsConnection";
    graphExportOperation = "originsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name" : "",
            "customizedTypified" : "",
            "description" : "",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        return result;
    }

};
