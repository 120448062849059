import React from 'react';
import { Datepicker, localeEn, localeEs, localePtPT } from '@mobiscroll/react';
import { observer } from 'mobx-react';
import AppLogger from "../../util/AppLogger";
import util from '../../util/Util';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import BaseFieldComponent from "./BaseFieldComponent";
import FormInputWrapper from "./FormInputWrapper";
import '@mobiscroll/react/dist/css/mobiscroll.react.scss';
import '../../scss/components/date-picker.scss';


registerLocale('es', es);
registerLocale('pt', pt);

@observer
export default class DateInputHoursMobiscroll extends BaseFieldComponent {

    static defaultProps = Object.assign(BaseFieldComponent.defaultProps, {
        controls: ['calendar', 'time']
    });


    constructor(props) {
        super(props);
    }

    /**
     * A partir de una feche en formato del navegador
     * (por ejemplo Fri Sep 13 2019 15:00:00 GMT+0200 (hora de verano de Europa central))
     * devuelve 2019-09-13T15:00:00
     * @param today
     * @returns {string}
     */
    getDateStringFromDateLocalizedBroser(today) {
        return util.getDateStringFromDateLocalizedBroser(today);
    }

    handleChange(eventChange) {
        let dateInitial = eventChange.value;
        let dateAsString = "";
        if (dateInitial != null) {
            dateAsString = this.getDateStringFromDateLocalizedBroser(dateInitial);
        }
        let momentDate = util.getMomentFromDateWithoutTimezone(dateAsString);
        let date = momentDate.toDate();
        let eventObj;
        if (this.props.onChange != null) {
            if (util.hasValue(dateInitial)) {
                if (this.props.dateWithoutTimeZone) {
                    /*Para scheduledTimeUntil necesitamos el día sin la zona horaria*/
                    eventObj = {
                        target: {
                            name: this.props.name,
                            value: date.toISOString()
                        }
                    };
                } else {
                    eventObj = {
                        target: {
                            name: this.props.name,
                            value: date.toISOString()
                        }
                    };
                }
            } else {
                eventObj = {
                    target: {
                        name: this.props.name,
                        value: ""
                    }
                };
            }
            let seletedDateStr = util.getMoment(this.props.value).format(("YYYY-MM-DDTHH:mm:ss"));
            if (seletedDateStr !== dateAsString) {
                // this.log({ dateInitial, momentDate, dateAsString, date, eventObj, eventChange, seletedDateStr });
                this.props.onChange(eventObj);
            }
        }
    }

    onBlurFunction(e) {
        if (this.props.onBlur != null) {
            this.props.onBlur(e)
        } else {
            this.validateOnBlurFunction(e)
        }
    }

    getLocale() {
        let result = localeEs;
        if (util.getLang() === "en") {
            result = localeEn;

        } else if (util.getLang() === "pt") {
            result = localePtPT;

        }
        return result;
    }


    fechaSeleccionada() {
        let value = this.props.value;
        if (util.hasValue(value)) {
            if (!this.props.dateWithoutMoment) {
                value = util.getMoment(value).format("DD-MM-YYYY HH:mm");
            }
        } else if (this.props.allowEmptyDate) {
            value = "";
        } else {
            value = util.getMoment().format(("DD-MM-YYYY HH:mm"));
        }
        // this.log({ fechaSelecc: 1, value, propsva: this.props.value, getTimezone: util.getTimezone(), })
        return value;
    }
    fechaMinAndMax(value) {
        if (util.hasValue(value)) {
            if (!this.props.dateWithoutMoment) {
                value = util.getMoment(value).format("DD-MM-YYYY HH:mm");
            }
        } else if (this.props.allowEmptyDate) {
            value = "";
        }
        // this.log({ fechaSelecc: 1, value, propsva: this.props.value })
        return value;
    }

    render() {
        let seletedDateStr = this.fechaSeleccionada();
        let props = this.props;
        let readOnly = this.checkReadOnly();
        let classNameInput = this.getClassNameInput();
        let inputProps = { className: 'mbsc-overwrite', placeholder: "DD-MM-YYYY HH:mm", };
        // https://docs.mobiscroll.com/react/calendar#override-colors Para editar los estilos
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                styleCalendar={!readOnly}
                prefix={this.props.prefix}
                errors={this.props.errors}
                onClickIcon={() => this.setState({ openDatePicker: true })}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                relatedInfo={this.props.relatedInfo}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                labelClassName={this.props.labelClassName}
                {...props}>
                {readOnly ?
                    <input
                        value={seletedDateStr }
                        autoComplete="new-password"
                        className={classNameInput}
                        readOnly={true}
                    />
                    :
                    <div className={"react-datepicker-wrapper"}>
                        <div className="react-datepicker__input-container">
                            <Datepicker
                                showOuterDays={true} dateFormat="DD-MM-YYYY" disabled={false} timeFormat={"HH:mm"}
                                showWeekNumbers={false} theme={'ios'} locale={this.getLocale()}
                                showOnClick={false}
                                min={this.fechaMinAndMax(this.props.minDate)}
                                max={this.fechaMinAndMax(this.props.maxDate)}
                                isOpen={this.state.openDatePicker}
                                onClose={() => this.setState({ openDatePicker: false })}
                                themeVariant='light' placeHolder={"Seleccione Fecha"} returnFormat={'moment'}
                                responsive={{
                                    xsmall: {
                                        controls: ['calendar', 'time'], display: 'bottom', touchUi: true
                                    },
                                    small: {
                                        controls: ['calendar', 'time'], display: 'anchored', touchUi: true
                                    },
                                    custom: { // Custom breakpoint
                                        breakpoint: 800, controls: ['calendar', 'time'],
                                        display: 'anchored', touchUi: true
                                    }
                                }}
                                touchUi={util.isUserAgentMobile()}
                                value={seletedDateStr} onChange={(date) => this.handleChange(date)}
                                controls={this.props.controls} inputProps={inputProps} inputComponent="input"
                            />

                        </div>

                    </div>
                }
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

