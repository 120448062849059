import React from 'react'
import { observer } from 'mobx-react'
import translate from '../../translator/translate'
import PopUp from '../../components/modals/PopUp'
import AlertModal from '../../subpages/AlertModal'
import SlotModel from '../../models/SlotModel'
import appState from '../../state/AppState'
import moment from 'moment'
import PropsUtil from '../../util/PropsUtil'
import { withRouter } from 'react-router-dom'
import GqlErrors from '../../components/status/GqlErrors'
import BaseActions from '../base/BaseActions'
import TypifiedValueModel from '../../models/TypifiedValueModel'
import OrderMovedToClientModal from '../../components/OrderMovedToClientModal'

@observer
class OrderActions extends BaseActions {

  constructor (props) {
    super(props)
    this.state = {
      rightModal: false,
      popUp: '',
      orderMoved: false
    }
  }

  async urlScheduleFromOrder (orderId) {
    let slotQuery = new SlotModel()
    slotQuery.orderId = orderId
    let slot = await slotQuery.findPlainObject()
    appState.layoutState.backgroundScheduled = orderId
    let url = '/schedule/bytechnical?date=' + moment(slot[0].scheduledTime).format('YYYYMMDD')
    this.props.history.push(url)
  }

  render () {
    const t = translate
    this.propsUtil = new PropsUtil(this.props)
    let model = this.props.model
    return (
      <>
        <div className="dropdown-sublist__item ">
          {model.deletable &&
          <a onClick={() => this.setState({
            rightModal: true,
            popUp: 'eliminar'
          })}>
            {t('Eliminar pedido')}
          </a>
          }
          {!appState.userState.isUserRoleClient() &&
          <a onClick={() => {
            if (this.props.fromRightModal) {
              this.propsUtil.changeUrlRequest({ 'rightModalTab': 'history' })
            } else {
              this.propsUtil.changeUrl(this.urlForHistoric())
            }
            this.closeModal()
          }}>
            {t('Control de cambios')}
          </a>
          }
          {appState.userState.isUserRoleDispatcherOrMore() &&
          <>
            {model.status === appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, 'CLOSED').id &&
            <a onClick={() => this.setState({ orderMoved: true })}>
              {'Mover a otro establecimiento'}
            </a>
            }
          </>
          }
        </div>
        <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

        <AlertModal isOpen={this.state.rightModal && this.state.popUp != ''}
                    onCloseModal={() => this.setState({
                      rightModal: false,
                      popUp: ''
                    })}>
          {this.state.popUp == 'eliminar' &&
          <PopUp
            title={t('Eliminar')}
            subtitle={t('Pedido') + ' ' + model.code}
            icon="exclamationTriangleIcon"
            text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
            label1={t('No eliminar')}
            label3={t('Eliminar')}
            onClick1={() => this.setState({
              rightModal: false,
              popUp: ''
            })}
            onClick3={() => this.deleteModel('order')}
          />
          }
        </AlertModal>
        {this.state.orderMoved &&
        <OrderMovedToClientModal
          title={t('Movimiento de establecimiento')}
          orderModel={model}
          openModal={this.state.orderMoved}
          closeModal={() => {
            this.setState({ orderMoved: false })
            this.closeModal()
          }}
        />
        }
      </>
    )
  }
}

export default withRouter(OrderActions)
