import React from 'react'
import AbstractModel from './AbstractModel'
import DocumentModel from './DocumentModel'
import ArticleModel from './ArticleModel'
import WorkOrderModel from './WorkOrderModel'
import UserModel from './UserModel'
import ClientModel from './ClientModel'

export default class WorkOrderCartArticleModel extends AbstractModel {


    static CODE_DISPONIBLE= "DISPONIBLE";
    static CODE_SIN_STOCK = "SIN_STOCK";
    static CODE_RESERVADA = "RESERVADA";
    static CODE_UTILIZADA = "UTILIZADA";
    static CODE_PPRESUPUESTADA = "PRESUPUESTADA";


    id;
    orderId;
    workOrderId;
    status;
    quantity;
    articleCode;
    articleName;
    articleId;
    used;
    articlePrice;
    articleCost;
    assetId;
    externalArticle;
    userId;
    saleUnit;
    itemType;
    description;
    slotId;
    shoppingCenter;
    stockLote;
    serviceType;

    nameMainType = "workOrderCartArticle";
    graphFindByIdOperation = "workOrderCartArticle";
    graphFindOperation = "workOrderCartArticlesConnection";

    newModel() {
        return new WorkOrderCartArticleModel();
    }

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "orderId": "",
            "workOrderId": "",
            "assetId": "",
            "status": "",
            "itemType": "",
            "saleUnit": "",
            "quantity": "",
            "articleCode": "",
            "articleName": "",
            "articleId": "",
            "used": { type: "BoolField" },
            "articlePrice": "",
            "articleCost": "",
            "externalArticle": { type: "BoolField" },
            "userId": "",
            "description": "",
            "slotId": "",
            "stockLote": "",
            "shoppingCenter": "",
            "serviceType": "",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "article", "RelationOne", ArticleModel);
        this.addRelatedTableGeneric(result, "user", "RelationOne", UserModel);
        this.addRelatedTableGeneric(result, "workOrder", "RelationOne", WorkOrderModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        return result;
    }

    async getOrderArticlesFromOrder(orderId) {
        let result = [];
        if (orderId != null) {
            let orderQuery = new WorkOrderCartArticleModel();
            orderQuery.orderId = orderId;
            result = await orderQuery.find();
        }
        return result;
    }


}
