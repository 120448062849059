import { observable } from 'mobx';
import BaseModelState from "./BaseModelState";

class WarningsState extends BaseModelState {
    @observable warnings = [];
    @observable copiaWarnings = [];


}

export default WarningsState;
