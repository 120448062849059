import React, {Component} from 'react';
import VsfModal from "./modals/VsfModal";
import AppLogger from "../util/AppLogger";
import translate from "../translator/translate";
import VsfButtonNew from "./VsfButtonNew";
import {observer} from "mobx-react";
import GqlErrors from "./status/GqlErrors";
import {withRouter} from "react-router-dom";
import {observable} from "mobx";
import InputTypeComponent from "./fields/InputTypeComponent";
import BudgetModel from "../models/BudgetModel";
import GraphException from "../network/GraphException";
import PropsUtil from "../util/PropsUtil";
import BudgetArticleModel from "../models/BudgetArticleModel";
import util from "../util/Util";

@observer
class DuplicateBudgetModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    async updateEvent(event) {
        this[event.target.name] = event.target.value;
    }


    @observable title;
    @observable budget = {};
    @observable gqlErrors = [];

    async saveStatus() {
        try {
            let budget = new BudgetModel();
            budget.hidrate(this.props.budgetModel);
            budget.id = "";
            budget.code = "";
            budget.endDateBudget = "";
            budget.title = this.title;
            budget.status = BudgetModel.CODE_BORRADOR;
            await budget.persist();
            this.budget = { ...budget }
            for (let budgetArticles of this.props.budgetModel.budgetArticles) {
                if (util.hasValue(budgetArticles.articleCode)) {
                    let budgetArticlesModel = new BudgetArticleModel();
                    budgetArticlesModel.hidrate(budgetArticles);
                    budgetArticlesModel.budgetId = budget.id;
                    budgetArticlesModel.workOrderId = budget.workOrderId;
                    await budgetArticlesModel.persist();
                }
            }
            this.setState({ loading: false, loadingGo: false });
            this.props.closeModal();
        } catch (e) {
            this.setState({ loading: false, loadingGo: false });
            this.gqlErrors = new GraphException().getErrorsFromException(e);
        }
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        return (
            <VsfModal
                isOpen={this.props.openModal}
                onCloseModal={this.props.closeModal}
                className="center top-to-bottom-client">
                <div className="modal-push">
                    <div className="modal-head visible-text">
                        <div className="d-flex align-items-center">
                            <div className="col-12 text-left">
                                <div className="modal-title">
                                    <p>{this.props.title}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body-filters">

                        <div className="c-modal-info__cell__body row">

                            <InputTypeComponent
                                value={this.title}
                                onChange={(e) => this.updateEvent(e)}
                                title={t("Titulo")}
                                name={"title"}
                                classGroup={"col-md-6 col-lg-3"}
                                type={"text"}
                            />

                        </div>
                    </div>

                    <div className="modal-footer ">
                        <div className="mt-4">
                            <div className="d-flex justify-content-end mb-2">
                                <VsfButtonNew
                                    label={t('Salir')}
                                    className="btn btn-secondary"
                                    onClick={() => this.props.closeModal()}
                                />
                                <VsfButtonNew
                                    label={t("Guardar")}
                                    loading={this.state.loading}
                                    icon="fa fa-save"
                                    loadedError={this.gqlErrors.length !== 0}
                                    className="btn btn--loader"
                                    onClick={() => {
                                        this.setState({ loading: true });
                                        this.saveStatus()
                                        this.props.closeModal();
                                    }}
                                />
                                <VsfButtonNew
                                    label={t('Guardar e ir al presupuesto')}
                                    loading={this.state.loadingGo}
                                    icon="fa fa-save"
                                    loadedError={this.gqlErrors.length !== 0}
                                    className="btn btn--loader"
                                    onClick={async() => {
                                        this.setState({ loadingGo: true });
                                        await this.saveStatus();
                                        this.propsUtil.changeUrl('/budget/form/' + this.budget.id);
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

            </VsfModal>
        )
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(DuplicateBudgetModal)
