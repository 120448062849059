import React from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import GroupOfField from "../../components/layout/GroupOfField";
import appState from '../../state/AppState';
import PageTitle from "../../components/PageTitle";
import TabsComponent from "../../components/fields/TabsComponent";
import TabsData from "../../layout/TabsData";
import {computed, observable} from "mobx";
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import PropsUtil from "../../util/PropsUtil";
import SlotModel from "../../models/SlotModel";
import InfoBlock from "../../components/InfoBlock";
import util from '../../util/Util'
import WorkOrderModel from "../../models/WorkOrderModel";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";
import OrderFormSlot from "../orders/OrderFormSlot";
import VsfButtonNew from "../../components/VsfButtonNew";
import BaseEditableTable from "../base/BaseEditableTable";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import MoreButtons from "../../components/MoreButtons";
import GqlErrors from "../../components/status/GqlErrors";
import OrderFormSlotClientsPending from "../orders/OrderFormSlotClientsPending";
import WorkOrderActions from "./WorkOrderActions";
import SpinnerClipLoaderInPage from "../../network/SpinnerClipLoaderInPage";

@observer
class WorkOrderFormSlots extends BaseEditableTable {

    @observable clientsSlots = [];

    constructor(props) {
        super(props);
        this.nameMainType = "workOrder";
        this.initializeGraphData(this.nameMainType);
        this.tabsData = new TabsData();
        this.foreingKeyfield = "workOrderId";
        this.state = {
            hasSomeRowEditing: false,
            order: {},
            fromWorkOrder: true,
            loading: true,
        }
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadTable();
        await appState.userCacheState.loadUserCacheTechnicals();
        await this.loadSlotsClients();
        this.setState({ loading: false })
    }

    @computed get mobxListado() {
        return appState.slotState.slots;
    }

    setMobxListado(newValue) {
        appState.slotState.slots = newValue;
    }

    getModelQuery() {
        return new WorkOrderModel();
    }

    getModelQueryForView() {
        let result = new WorkOrderModel();
        result.addRelatedTable("order");
        result.addRelatedTable("order.workOrder");
        result.addRelatedTable("order.client");
        result.addRelatedTable("order.slot");
        result.addRelatedTable("order.slot.workOrder");
        result.addRelatedTable("documents");
        result.addRelatedTable("budget");
        result.addRelatedTable("workOrderCartArticle");
        return result;
    }

    getModelTable() {
        return new SlotModel();
    }


    getDateFromForGql() {
        let requestFechaIso = util.getMoment();
        return util.getMomentFromDateWithoutTimezone(requestFechaIso.format("YYYY-MM-DD") + "T" + requestFechaIso.format("HH:mm:ss")).toISOString();
    }

    async loadSlotsClients() {
        let dateFrom = this.getDateFromForGql();
        let slotModel = new SlotModel();
        slotModel.first = 6;
        slotModel.filters = [
            { "fieldName": "clientId", "fieldValue": this.graphDataMainType.clientId, "filterOperator": "EQ" },
            { "fieldName": "scheduledTime", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
        ];
        slotModel.orderBy = "scheduledTime";
        slotModel.orderMode = "ASC";
        this.clientsSlots = await slotModel.find();
    }

    async reloadTable() {
        if (util.hasValue(this.graphDataMainType.order)) {
            let miSlot = [];
            if (this.graphDataMainType.order.slot?.length > 0) {
                for (let slot of this.graphDataMainType.order.slot) {
                    if (util.perteneceA(this.graphDataMainType.id, slot.workOrderIds)) {
                        miSlot.push(slot);
                    } else if (util.perteneceA("-1", slot.workOrderIds)) {
                        miSlot.push(slot);
                    }
                }
            }
            appState.slotState.slots = this.ordenarSlots(util.arrayModelToPlainObjects(miSlot));
            appState.assetState.workOrderSlots = miSlot.length;
        }
    }

    ordenarSlots(array) {
        let slotsGrouped = {};
        let slots = array.slice();
        slots.sort(function (a, b) {
            let key1 = util.getMoment(a.createdAt).format("YYMMDDHHmmsss");
            let key2 = util.getMoment(b.createdAt).format("YYMMDDHHmmsss");
            if (key1 < key2) {
                return -1;
            }
            if (key2 > key1) {
                return 1;
            }
            return 0;
        });
        for (let slot of slots) {
            let key = slot.assignedSlotMainId != null ? slot.assignedSlotMainId : slot.id;
            if (slotsGrouped[key] == null) {
                slotsGrouped[key] = [];
            }
            slotsGrouped[key].push(slot);
        }
        let keysSlotGrouped = Object.keys(slotsGrouped);
        let arrSlots = [];
        keysSlotGrouped.map(slotId => {
            slotsGrouped[slotId].map(slot => {
                arrSlots.push(slot);
            })
        });
        return arrSlots;
    }

    renderImpl() {
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        const t = translate;
        let workOrder = this.graphDataMainType;
        let errorsMapped = this.getGraphErrorsFromStatus();
        let horas = this.calcularHoras();


        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                    {this.props.fromRightModal ?

                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={workOrder.code || t("Nuevo")}
                                                 mode={"view"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                      getModelQuery={() => this.getModelQuery()} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                active={"Planificación"}
                                fromRightModal={this.props.fromRightModal}

                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={workOrder.code || t("Nuevo")}
                        >
                            <MoreButtons onReloadData={() => this.reloadData()}>
                                <WorkOrderActions fromRightModal={this.props.fromRightModal} setErrors={(errors) => this.setErrors(errors)} model={workOrder}
                                                  getModelQuery={() => this.getModelQuery()} />
                            </MoreButtons>
                        </PageTitle>
                    }

                    <div className="card mb-3">

                        <div className={modalClasses.body ? modalClasses.body : 'card-body'}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.getFormId()}
                                tabs={this.tabsData.getDataWorkOrder(appState.configState.checkOrderCocaCola(this.graphDataMainType.order))}
                                active={"Planificación"}
                            />
                            }
                            <div className="align-items-center">
                                <GroupOfField
                                    title='Orden de Trabajo'
                                    subtitle='Asignación'
                                />
                            </div>
                            <SpinnerClipLoaderInPage loading={this.state.loading}>
                                <div className="table-edit">
                                    <table className="table visible-text">
                                        <thead>
                                        {this.mobxListado.length == 0 &&
                                        <tr>
                                            <td colSpan="8"
                                                className="title-cell">{t('No hay ninguna planificacion creada')}
                                            </td>
                                        </tr>
                                        }
                                        {this.mobxListado.length != 0 &&
                                        <tr>
                                            <th className="wo-technical-name" scope="col">{t('Técnico')}</th>
                                            <th className="wo-width-date" scope="col">{t('Fecha y Hora')}</th>
                                            <th className="duration" scope="col">{t('Duración')}</th>
                                            <th className="wo-matched"
                                                scope="col">{this.props.fromOrder ? t("Orden de Trabajo") : t("O.T. Relacionadas")}</th>
                                            <th className="wo-buttons">&nbsp;</th>
                                        </tr>
                                        }
                                        </thead>
                                        <tbody className="border-end">

                                        {this.mobxListado.map((slot, index) => (
                                            <OrderFormSlot
                                                key={slot && slot.id}
                                                order={this.graphDataMainType.order}
                                                workOrderId={this.getFormId()}
                                                rowIndex={index}
                                                row={slot}
                                                reload={(e) => this.reloadData(e)}
                                                lastRow={this.mobxListado.length == 1}
                                                fromWorkOrder={true}
                                                graphDataMainType={this.graphDataMainType}
                                                hasSomeRowEditing={this.state.hasSomeRowEditing}
                                                setHasSomeRowEditing={(newVal) => this.setHasSomeRowEditing(newVal)}
                                                {...this.props}
                                            >
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="row">
                                                            {this.clientsSlots.length > 0 &&
                                                            <div className="col-12">
                                                                {t("Próximas 6 visitas en este establecimiento")}
                                                            </div>
                                                            }
                                                            {this.clientsSlots.map((slot, index) => (
                                                                <OrderFormSlotClientsPending
                                                                    key={slot.id + index}
                                                                    row={slot}
                                                                />
                                                            ))}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </OrderFormSlot>
                                        ))}

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan="2">{t('TOTAL')}</td>
                                            <td colSpan="5">{isNaN(horas) ?
                                                0 + " h."
                                                :
                                                Math.round(horas * 100) / 100
                                                + " h."}</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <div className="text-right">
                                    <VsfButtonNew
                                        disabled={this.state.hasSomeRowEditing}
                                        label={t("Crear nueva")}
                                        onClick={_ => this.onOpenRowNewEmpty()}
                                        showButton={this.creatable["slot"] || false}
                                        isEditing={this.state.hasSomeRowEditing}
                                        className="btn btn--loader"
                                    />
                                </div>
                            </SpinnerClipLoaderInPage>
                            <AlertModal isOpen={this.state.orderCanceled}
                                        onCloseModal={() => this.setState({ "orderCanceled": false })}>
                                <PopUp
                                    title={t('Operación no permitida')}
                                    icon={"exclamationTriangleIcon"}
                                    text={t(`No se puede crear un presupuesto porque el pedido está cancelado.`)}
                                    label1={'Cerrar'}
                                    onClick1={() => this.setState({ orderCanceled: false })}
                                />
                            </AlertModal>
                            <AlertModal isOpen={this.state.orderFinished}
                                        onCloseModal={() => this.setState({ "orderFinished": false })}
                            >
                                <PopUp
                                    title={t('Operación no permitida')}
                                    icon={"exclamationTriangleIcon"}
                                    text={t(`Este Pedido está finalizado, para poder añadir una visita
                                                 tienes que modificar el estado  desde el apartado Datos Generales del Pedido.`)}
                                    label1={'Cerrar'}
                                    onClick1={() => this.setState({ orderFinished: false })}
                                />
                            </AlertModal>
                        </div>
                    </div>
                </InfoBlock>
            </div>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(WorkOrderFormSlots)
