import AbstractModel from "./AbstractModel";

export default class DynamicFieldsModel extends AbstractModel {
    static CODE_TEXT = "TEXT";
    static CODE_NUMBER = "NUMBER";
    static CODE_SELECT = "SELECT";
    static CODE_DATE = "DATE";
    static CODE_RADIO_BUTTON = "CHECKLIST";
    id;

    validation;
    groupId;
    optionValues;
    type;
    code;
    label;
    listing;
    required;

    nameMainType = "dynamicField";
    graphFindByIdOperation = "dynamicField";
    graphFindOperation = "dynamicFieldsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "required": "",
            "listing": "",
            "label": "",
            "code": "",
            "type": "",
            "optionValues": "",
            "groupId": "",
            "validation": "",

        };
        return result;
    }


}
