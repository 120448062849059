import { observable } from 'mobx';
import util from "../util/Util";
import BaseModelState from "./BaseModelState";


class ArticleCodesState extends BaseModelState{

    @observable articleCodes=[];
    async reloadAssetCodesFromAssetId(articleId) {
        console.log("ArticleCodesState.reloadContactsFromOrderId:"+articleId);
        let articleCodesQuery = new ArticleCodesModel();
        articleCodesQuery.articleId = articleId;
        if (util.hasValue(articleCodesQuery.articleId)) {
            let result = await articleCodesQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            this.assetCodes = result;
        }
    }


}

export default ArticleCodesState;
