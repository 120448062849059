import React from 'react'
import { observer } from 'mobx-react'
import InputTypeComponent from '../../components/fields/InputTypeComponent'
import BaseEditableRow from '../base/BaseEditableRow'
import { computed, toJS } from 'mobx'
import appState from '../../state/AppState'
import ArticleModel from '../../models/ArticleModel'
import InfoBlock from '../../components/InfoBlock'
import translate from '../../translator/translate'
import util from '../../util/Util'
import BudgetArticleModel from '../../models/BudgetArticleModel'
import BudgetModel from '../../models/BudgetModel'
import GqlErrors from '../../components/status/GqlErrors'
import AppLogger from '../../util/AppLogger'
import OFFER from '../../config/OFFER'
import FetchProxy from '../../network/FetchProxy'
import config from '../../config/config'
import SelectSuggestComponent from '../../components/fields/SelectSuggestComponent'

@observer
class OrderFormBudgetArticlesRow extends BaseEditableRow {

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    })

    constructor (props) {
        super(props)
        this.foreingKeyfield = 'orderId'
        this.state = {
            change: 0
        }
    }

    @computed get mobxListado () {
        return appState.workOrderCartArticlesState.budgetArticles
    }

    setMobxListado (newValue) {
        appState.workOrderCartArticlesState.budgetArticles = newValue
    }

    async componentDidMountImpl () {
        super.componentDidMountImpl()
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

    async getArticlesConection (value, firstCall) {
        let articlesArray = []
        // Abortamos la anterior llamada
        this.abortFetch()
        try {
            // Inicializamos la singal que hay que enviar para cancelar la llamada en caso de recibir otra
            this.initializeAbortController()
            // Obtenemos los Assets
            let articlesQuery = new ArticleModel()
            if (firstCall) {
                articlesQuery.code = value
            } else if (util.hasValue(value)) {
                articlesQuery.filters = [
                    {
                        'fieldName': 'code,description',
                        'fieldValue': value,
                        'filterOperator': 'SUBSTR'
                    },
                ]
            }
            let articles = await articlesQuery.find()
            // Transformamos a formato Autosuggest
            articles.map((article) => {
                let articleObject = {}
                articleObject.label = article.code + ' - ' + article.description
                articleObject.value = {
                    ...article,
                    typeBudget: BudgetArticleModel.CODE_ARTICLE
                }
                articlesArray.push(articleObject)
            })

        } catch (e) {
            this.log('Error => ' + e)
        }
        return articlesArray
    }

    async loadPrizeArticle (articleSelected) {
        let httpApi = new FetchProxy()
        httpApi.withAuthorization = true
        this.log({ props: this.props })
        try {
            this.loading = true
            let response = await httpApi.fetchUrlPost(config.apiHostInt + '/priceRequestNetsuite/PriceRequest',
                {
                    'article': {
                        'code': articleSelected.code,
                        'codeErp': articleSelected.codeErp
                    },
                    'client': {
                        'code': this.props.clientModel?.parentClient?.code,
                        'codeErp': this.props.clientModel?.parentClient?.codeErp
                    }
                }
            )
            let responseJson = await response.json()
            this.log({ responseJson })
            let precio = responseJson.data?.price
            this.handleAutosuggestSelection('prizeWithoutTax', precio)

        } catch (e) {
            this.log(e)
        }
    }

    getModelTable () {
        return new BudgetArticleModel()
    }

    updateInput (e) {
        appState.layoutState.formWithoutChanges = false
        this.handleInputChange(e)
    }

    getModelQuery () {
        return new BudgetArticleModel()
    }

    isStatusReadOnly () {
        let result = false
        if (this.props.budget.status === BudgetModel.CODE_PENDING_ERP) {
            result = true
        }
        if (this.props.budget.status === BudgetModel.CODE_APPROVED) {
            result = true
        }
        if (this.props.budget.status === BudgetModel.CODE_CONFIRMED_ERP) {
            result = true
        }
        return result
    }

    render () {
        const t = translate
        let budgetArticle = this.props.row
        let errorsMapped = this.getGraphErrorsFromStatus()
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                <tr>
                    <td scope="row">
                        {this.props.editable && !appState.userState.isUserRoleClient() ?
                            <SelectSuggestComponent
                                value={budgetArticle.articleCode || ''}
                                concatLabel={' - ' + (budgetArticle.description || '')}
                                changeSelect={this.changeSelect}
                                onChange={(event) => {
                                    let codeSelected = event.target.value
                                    appState.layoutState.formWithoutChanges = false
                                    if (util.hasValue(codeSelected.id)) {
                                        this.handleAutosuggestSelection('articleCode', codeSelected.code)
                                        this.handleAutosuggestSelection('description', codeSelected.description)
                                        this.handleAutosuggestSelection('type', codeSelected.typeBudget)
                                        this.loadPrizeArticle(codeSelected)
                                    } else {
                                        this.handleAutosuggestSelection('articleCode', codeSelected)
                                        this.handleAutosuggestSelection('type', BudgetArticleModel.CODE_ARTICLE)
                                    }
                                }}
                                name={'articleCode'}
                                readOnly={this.isStatusReadOnly()}
                                editable={budgetArticle.editable}
                                errors={errorsMapped.articleCode}
                                loadSuggestions={(value, firstCall) => this.getArticlesConection(value, firstCall)}
                                placeholder={t('Escribe...')}
                            />
                            :
                            <>
                                {budgetArticle.articleCode}
                            </>
                        }

                    </td>
                    <td scope="row">
                        {budgetArticle.description}
                    </td>
                    <td>
                        {this.props.editable && !appState.userState.isUserRoleClient() ?
                            <InputTypeComponent
                                value={budgetArticle.quantity || ''}
                                onChange={(e) => this.updateInput(e)}
                                name={'quantity'}
                                type={'text'}
                                validate={'decimal'}
                                readOnly={this.isStatusReadOnly() || !util.hasValue(budgetArticle.articleCode)}
                                showView={!this.props.editable}
                                editable={budgetArticle.editable}
                                className="form-control"
                                errors={errorsMapped.quantity}
                                formGroup={'inputheight-s'}
                            />
                            :
                            <>
                                {budgetArticle.quantity || 1}
                            </>
                        }
                    </td>
                    <td>
                        {(this.props.editable && !appState.configState.validateStatusBudget(this.props.budget?.status) && !appState.userState.isUserRoleClient())
                            ?
                            <InputTypeComponent
                                value={budgetArticle.prizeWithoutTax || ''}
                                onChange={(e) => this.updateInput(e)}
                                editable={budgetArticle.editable}
                                name={'prizeWithoutTax'}
                                validate={'decimal'}
                                readOnly={this.isStatusReadOnly() || !util.hasValue(budgetArticle.articleCode)}
                                showView={!this.props.editable}
                                className="form-control"
                                errors={errorsMapped.prizeWithoutTax}
                            />
                            :
                            <>
                                {budgetArticle.prizeWithoutTax ? this.formatMoneda(budgetArticle.prizeWithoutTax) :
                                    budgetArticle.prizeWithoutTax === 0 ? '-' : '-'}
                            </>
                        }
                    </td>
                    <td>
                        {this.getTotalPriceWithoutTaxArticle(budgetArticle)}
                    </td>
                    <td>
                        {this.getTotalPriceWithTaxArticle(budgetArticle)}
                    </td>

                    <td>
                        {(budgetArticle.editable || OFFER.creatable['budgetArticle']) &&
                        <>
                            {(this.props.editable && this.props.budget.status !== BudgetModel.CODE_PENDING_ERP && this.props.budget.status !== BudgetModel.CODE_APPROVED && !appState.userState.isUserRoleClient()) &&
                            <div className={'row'}>
                                {this.props.budgetArticles?.length !== 1 &&
                                <div className="remove-add">
                                    <div
                                        id={'deleteBudget'}
                                        onClick={() => this.deleteBudget(budgetArticle)}
                                        title="Nuevo" className="edit">
                                        <span className="fas fa-minus-circle" style={{ fontSize: '16px' }} />
                                    </div>
                                </div>
                                }
                                {this.props.rowIndex === (this.props.budgetArticles?.length - 1) &&
                                <div className="remove-add">
                                    <div
                                        id={'newBudget'} onClick={() => this.props.newBudget()}
                                        title="Nuevo" className="edit">
                                        <span className="fas fa-plus-circle" style={{ fontSize: '16px' }} />
                                        <input type="radio" onFocus={() => this.props.newBudget()} style={{
                                            width: '20px',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            borderRadius: '4px',
                                            cursor: 'pointer'
                                        }} />
                                    </div>
                                </div>
                                }
                            </div>
                            }
                        </>
                        }
                    </td>
                </tr>
            </InfoBlock>
        )
    }

    formatMoneda (valor) {
        let result
        result = parseFloat(valor).toFixed(2) + ' €'
        return result
    }

    getTotalPriceWithTaxArticle (budgetArticle) {
        let result = ''
        if (util.hasValue(budgetArticle.totalPrizeWithTax)) {
            result = this.formatMoneda(budgetArticle.totalPrizeWithTax)
        } else {
            result = ((!util.hasValue(budgetArticle.prizeWithTax) || !util.hasValue(budgetArticle.quantity)) ||
                isNaN((parseFloat(budgetArticle.prizeWithTax) * parseFloat(budgetArticle.quantity)))) ?
                ('-') : (budgetArticle.prizeWithTax === 0) ? '-' :
                    this.formatMoneda((budgetArticle.prizeWithTax) *
                        (budgetArticle.quantity))
        }
        if ((parseInt(result) === 0)) {
            result = '-'
        }
        return result
    }

    getTotalPriceWithoutTaxArticle (budgetArticle) {
        let result = ''
        if (util.hasValue(budgetArticle.totalPrizeWithoutTax)) {
            result = this.formatMoneda(budgetArticle.totalPrizeWithoutTax)
        } else {
            result = ((!util.hasValue(budgetArticle.prizeWithoutTax) || !util.hasValue(budgetArticle.quantity)) ||
                isNaN((parseFloat(budgetArticle.prizeWithoutTax) * parseFloat(budgetArticle.quantity)))) ?
                ('-') : (parseInt(budgetArticle.prizeWithoutTax) === 0) ? '-' :
                    this.formatMoneda((budgetArticle.prizeWithoutTax) *
                        (budgetArticle.quantity))
        }
        if ((parseInt(result) === 0)) {
            result = '-'
        }
        return result
    }

    async deleteBudget (budgetArticle) {
        if (util.hasValue(budgetArticle.id)) {
            let budgetQuery = new BudgetArticleModel()
            budgetQuery.id = budgetArticle.id
            await budgetQuery.remove()
        }
        let budgetArticles = toJS(this.props.budget.budgetArticles)
        for (let index in budgetArticles) {
            if (util.hasValue(budgetArticle.id)) {
                if (budgetArticles[index]?.id === budgetArticle.id) {
                    budgetArticles.splice(index, 1)
                }
            } else {
                budgetArticles.splice(this.props.rowIndex, 1)
            }
        }
        this.log({
            budgetArticles,
            real: this.props.budget.budgetArticles
        })
        this.props.budget.budgetArticles = budgetArticles
    }
}

export default OrderFormBudgetArticlesRow
