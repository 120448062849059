import React from 'react';
import {observer} from 'mobx-react';
import FormInputWrapper from "./FormInputWrapper";
import BaseFieldComponent from "./BaseFieldComponent";


@observer
export default class TextAreaComponent extends BaseFieldComponent {

    onChange(e) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        let props = this.props;
        let errors = this.props.errors;
        if (errors == null) {
            errors = [];
        }
        let readOnly = this.checkReadOnly();

        return (

            <FormInputWrapper
                classGroup={props.classGroup}
                classInputType={"Select2Component"}
                name={props.name}
                ariaLabelledby={this.props.ariaLabelledby}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                title={this.props.title}
                errorsClientValidation={this.state.errorsClientValidation}
                required={this.props.required}
                isClearable={this.props.isClearable}
                relatedInfo={this.props.relatedInfo}
                info={this.props.info}
                forceMedia={this.props.forceMedia}
            >
                <textarea
                    value={props.value || ''}
                    name={props.name}
                    id={props.name}
                    cols={props.col || "15"}
                    rows={props.row || "5"} readOnly={readOnly}
                    placeholder={(props.placeholder || props.title)}
                    className="form-control"
                    onChange={e => this.onChange(e)}
                />
                {errors.map(error => <div>{error.message}</div>)}
            </FormInputWrapper>
        );
    }
}


