import { computed, observable, toJS } from 'mobx';
import BaseModelState from "./BaseModelState";

class WarehouseState extends BaseModelState {

    @observable warehouses = [];

    //@observable transferOrderls = [];

    @computed get labelValuesForSelect() {
        let result = [];
        let copia = {};
        for (let item of this.warehouses) {
            if (copia[item.netsuiteInternalId] == null) {
                copia[item.netsuiteInternalId] = "";
                result.push({ value: item.netsuiteInternalId, label: item.subinventory });
            }
        }
        return result;
    }


    @computed get labelValuesForSelectBin() {
        let result = [];
        let copia = {};
        for (let item of this.warehouses) {
            if (item.useBins && copia[item.netsuiteInternalId] == null) {
                copia[item.netsuiteInternalId] = "";
                result.push({ value: item.netsuiteInternalId, label: item.subinventory });
            }
        }
        return result;
    }

    getNetsuiteInternalId(subinventory) {
        let result = "";
        for (let item of this.warehouses) {
            if (item.subinventory === subinventory) {
                result = item.netsuiteInternalId;
                break;
            }
        }
        return result;
    }

    getNetsuiteSubinventoryOfId(subinventory) {
        let result = "";
        for (let item of this.warehouses) {
            if (item.subinventory === subinventory) {
                this.log({ getNetsuiteInternalId: 1, item, subinventory: toJS(subinventory) })
                result = item.sublocationOfId;
                break;
            }
        }
        return result;
    }

    getWarehouseItemFromInternalId(internalId){
        let result = "";
        for (let item of this.warehouses) {
            if (item.netsuiteInternalId === internalId) {
                result = item;
                break;
            }
        }
        return result;
    }

    /* Select para el filtro por estado, pero recorre el array entero (126) para sacar 7 campos
    @computed get labelValuesForStatus() {
        let result = [];
        let copia = {};
        this.log({wareh:this.transferOrderls})
        for (let item of this.transferOrderls) {
            if (copia[item.status] == null) {
                copia[item.status] = "";
                result.push({ value: item.status, label: item.status });
            }
        }
        return result;
    }*/
}

export default WarehouseState;
