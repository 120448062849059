import appState from "../state/AppState";
import config from "../config/config";

class Debug {
    currentIndex=0;

    d(object, possibleClass) {
        let withDebug=true;
        if (config.isLive) {
            withDebug=false;
        }
        if (config.isTest2()) {
            withDebug=false;
        }
        if (withDebug) {
            let className = "";
            if (possibleClass) {
                className = possibleClass.constructor.name;
            }
            let key = this.currentIndex;
            if (className) {
                key = className;
                //Si el objeto sólo tiene 1 valor lo meto directamente.
                if (typeof object === "object" && !Array.isArray(object)) {
                    let keys = Object.keys(object);
                    if (keys.length == 1) {
                        object = object[keys[0]];
                        key += "." + keys[0];
                    }
                }
                let indexRepetido = 1;
                let currentKey = this.currentIndex + "." + key;
                while (appState.debugObjects[currentKey] != null) {
                    currentKey = this.currentIndex + "." + key + "(" + indexRepetido + ")";
                    indexRepetido++;
                }
                appState.debugObjects[currentKey] = object;
                this.currentIndex++;
            } else {
                appState.debugObjects[key] = object;
                this.currentIndex++;
            }
        }
    }
}

export default new Debug();
