import React, { Component, Suspense } from 'react';
import './App.scss';
import { observer } from 'mobx-react';
import { ApolloProvider } from 'react-apollo';
import ApolloClientInstance from "./storage/ApolloClientInstance";
import AppRouter from "./AppRouter";
import LoginRouter from "./LoginRouter";
import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend';
import LoadTranslations from "./translator/LoadTranslations";
import VsfLoadingBar from "./network/VsfLoadingBar";
import appState from "./state/AppState";
import AppLogger from "./util/AppLogger";
import { runInAction } from "mobx";
import LocalStorage from "./network/LocalStorage";
import ClassFactory from "./factory/ClassFactory";


const Spinner = () => {
    return <h1>Loading</h1>
};
//Al hacer un cambio de URL no hace scrollToTop
window.consoleapp = {};
window.consoleapp.log = window.console.log;
window.console.log = () => {
};
/*
window.consoleapp.prueba = () => {
};

window.onQrScanned= {};
window.onQrScanned.onScan= data => {
    alert("window.onQrScanned "+ data);
}
*/

@observer
class App extends Component {

    cargarInfoInicial = true;

    state = {
        loadedMessages: false,
        loadedFinished: false
    };

    async componentDidMount() {
        try {
            appState.loadingBarState.initialize();
            let localStorage = new LocalStorage();
            appState.layoutState.infoDebuggerEnabled = (localStorage.getItem("debug") === "true") || false;
            appState.classFactory = ClassFactory;
            let loadTranslations = new LoadTranslations();
            await loadTranslations.loadMessages();
            setTimeout(function () {
                if (appState.loadingBarState.loadingBarProgress !== 100) {
                    appState.loadingBarState.finalize();
                }
            }, 1000);
            if (appState.loginState.isUserLogged) {
                runInAction(async() => {
                    if (this.cargarInfoInicial) {
                        await appState.typifiedState.loadMasters();
                        await appState.loginState.getUser();
                        await appState.tenantState.loadTenantsApi();
                        await appState.dynamicGroupState.loadDynamics();
                        this.setState({ loadedFinished: true });
                    }
                });
            } else {
                this.setState({ loadedFinished: true });
            }
        } catch (e) {
            this.log({ ErrorAPPLoading: e })
            this.setState({ loadedFinished: true });
        }

        this.setState({ loadedMessages: true });
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    render() {
        if (!this.state.loadedMessages) {
            return (
                <React.Fragment>
                    <VsfLoadingBar />
                </React.Fragment>
            )
        }
        return (

            <div>
                {!appState.loginState.isUserLogged ?
                    <LoginRouter />
                    :
                    <Suspense fallback={<Spinner />}>
                        <ApolloProvider client={ApolloClientInstance}>
                            {this.state.loadedFinished &&
                            <DragDropContextProvider backend={HTML5Backend}>
                                {/* Esto permite funcionalidad dnd en multiples pagnias. No se puede tener 2 veces el componente HTML5Backend */}
                                <AppRouter />
                            </DragDropContextProvider>
                            }
                        </ApolloProvider>
                    </Suspense>
                }
            </div>

        );
    }
}

export default App;
