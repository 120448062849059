import JsonData from '../pages/users/customized-permissions'
import React, { Component } from 'react'
import AppLogger from '../util/AppLogger'
import translate from '../translator/translate'
import { observer } from 'mobx-react'
import GqlErrors from './status/GqlErrors'
import { withRouter } from 'react-router-dom'
import util from '../util/Util'
import { observable } from 'mobx'
import { Accordion, Card } from 'react-bootstrap'
import VsfModalBootstrap from './modals/VsfModalBootstrap'
// import "bootstrap/dist/css/bootstrap.min.css";
import SwitchComponent from './fields/SwitchComponent'
import VsfButtonNew from './VsfButtonNew'

@observer
class PermissionsModal extends Component {
    @observable gqlErrors = []

    constructor (props) {
        super(props)
        this.state = {
            loading: false,
            activeKey: 0
        }
    }

    async componentDidMount () {
        if (util.hasValue(this.props.userModel?.permission)) {
            this.objPermissions = JSON.parse(this.props.userModel?.permission)
        }
        this.log({ objPermissions: this.objPermissions })
    }

    setErrors (errors) {
        this.gqlErrors = errors
    }

    handleActiveKeyChange (activeKey) {
        if (this.state.activeKey === activeKey) {
            //allows us to close expanded item by clicking its toggle while open
            activeKey = -1
        }
        this.log({
            handleActiveKeyChange: 1,
            activeKey
        })
        this.setState({ activeKey })
    }

    async savePermissions () {
        let jsonStr = JSON.stringify(this.objPermissions)
        this.log({ objPermissions: jsonStr })
        this.props.updateInputEvent(jsonStr)
        this.props.closeModal()
    }

    handleInputChange (event, nameMainType) {
        // this.log({ event, nameMainType })
        let value = event.target.value
        let name = event.target.name
        if (value) {
            this.objPermissions = {
                ...this.objPermissions,
                [nameMainType]: ((this.objPermissions[nameMainType] || '') + name)
            }
        } else {
            this.objPermissions[nameMainType] = this.objPermissions[nameMainType]?.replace(name, '')
        }
        this.log({ objPermissions: this.objPermissions })
    }

    @observable objPermissions = {}

    render () {
        const t = translate
        // this.log({ render: 1, JsonData, keys: Object.keys(JsonData) });
        return (
            <VsfModalBootstrap
                isOpen={this.props.openModal} title={this.props.title}
                onCloseModal={this.props.closeModal} size={'lg'}
                className="center top-to-bottom-client">
                <Accordion activeKey={this.state.activeKey.toString()}>
                    {Object.keys(JsonData)?.map((title, index) => (
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}
                                onClick={() => this.handleActiveKeyChange(index)}>
                                {t(title)}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index.toString()}>
                                <Card.Body>
                                    {Object.keys(JsonData[title]).map(childTitle => (
                                        <div>
                                            {t(childTitle)}
                                            <div className="row">
                                                {JsonData[title][childTitle].permissions.split('').map(permiso => (
                                                    <SwitchComponent
                                                        checked={util.perteneceA(permiso, this.objPermissions[JsonData[title][childTitle].nameMainType])}
                                                        value={this.objPermissions[JsonData[title][childTitle].nameMainType]}
                                                        readOnly={util.perteneceA(permiso, JsonData[title][childTitle].required)}
                                                        title={this.getTitle(permiso)}
                                                        onChange={(e) => this.handleInputChange(e, JsonData[title][childTitle].nameMainType)}
                                                        name={permiso}
                                                        classGroup={'col-3'}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}


                </Accordion>
                <div className="mt-4">
                    <div className="d-flex justify-content-end mb-2">
                        <VsfButtonNew
                            label={'Cancelar'}
                            className="btn btn btn-secondary"
                            onClick={() => this.props.closeModal()}
                        />
                        <div className="m-2" />
                        <VsfButtonNew
                            label={t('Guardar permisos')}
                            className="btn btn--loader" disabled={Object.keys(this.objPermissions).length === 0}
                            loading={this.state.loading}
                            loadedError={this.gqlErrors.length !== 0}
                            onClick={() => this.savePermissions()} />
                    </div>
                </div>
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

            </VsfModalBootstrap>
        )
    }

    getTitle (permiso) {
        let result = ''
        if (permiso === 'C') {
            result = 'Crear'
        }
        if (permiso === 'R') {
            result = 'Leer'
        }
        if (permiso === 'U') {
            result = 'Actualizar'
        }
        if (permiso === 'D') {
            result = 'Borrar'
        }
        return result
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

}

export default withRouter(PermissionsModal)
