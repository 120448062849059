import React from 'react';
import { Datepicker, localeEn, localeEs, localePtPT } from '@mobiscroll/react';
import { observer } from 'mobx-react';
import AppLogger from "../../util/AppLogger";
import util from '../../util/Util';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import BaseFieldComponent from "./BaseFieldComponent";
import FormInputWrapper from "./FormInputWrapper";
import '@mobiscroll/react/dist/css/mobiscroll.react.scss';
import '../../scss/components/date-picker.scss';


registerLocale('es', es);
registerLocale('pt', pt);

@observer
export default class DateRangeMobiscroll extends BaseFieldComponent {

    static defaultProps = Object.assign(BaseFieldComponent.defaultProps, {
        controls: ['date']
    });


    constructor(props) {
        super(props);
    }

    /**
     * A partir de una feche en formato del navegador
     * (por ejemplo Fri Sep 13 2019 15:00:00 GMT+0200 (hora de verano de Europa central))
     * devuelve 2019-09-13T15:00:00
     * @param today
     * @returns {string}
     */
    getDateStringFromDateLocalizedBroser(today) {
        return util.getDateStringFromDateLocalizedBroser(today);
    }

    handleChange(eventChange) {
        let dateInitialFrom = eventChange.value[0];
        if (util.hasValue(dateInitialFrom)) {
            this.handleChangeFrom(dateInitialFrom);
        }
        let dateInitialTo = eventChange.value[1];
        if (util.hasValue(dateInitialTo)) {
            this.handleChangeTo(dateInitialTo)
        }
    }

    handleChangeFrom(dateInitial) {
        let value = "";
        let eventObj;
        if (this.props.onChange != null) {
            if (util.hasValue(dateInitial)) {
                let dateAsString = this.getDateStringFromDateLocalizedBroser(dateInitial);
                dateAsString = dateAsString.replace(dateAsString.split("T")[1],"00:00:00");
                let momentDate = util.getMomentFromDateWithoutTimezone(dateAsString);
                let date = momentDate.toDate();
                value = date.toISOString();
                //this.log({ handleChangeFrom: 1, dateInitial, dateAsString, date, eventObj, value, momentDate });
            }
            eventObj = {
                target: {
                    name: this.props.name,
                    value
                }
            };
            this.props.onChange(eventObj);
        }
    }

    handleChangeTo(dateInitial) {
        let value = "";
        let eventObj;
        if (this.props.onChangeTo != null) {
            if (util.hasValue(dateInitial)) {
                let dateAsString = this.getDateStringFromDateLocalizedBroser(dateInitial);
                dateAsString = dateAsString.replace(dateAsString.split("T")[1],"23:59:00");
                let momentDate = util.getMomentFromDateWithoutTimezone(dateAsString);
                let date = momentDate.toDate();
                value = date.toISOString();
                //this.log({ handleChangeTo: 1, dateInitial, dateAsString, date, eventObj, value, momentDate });
            }
            eventObj = {
                target: {
                    name: this.props.nameTo,
                    value
                }
            };
            this.props.onChangeTo(eventObj);
        }
    }


    onBlurFunction(e) {
        if (this.props.onBlur != null) {
            this.props.onBlur(e)
        } else {
            this.validateOnBlurFunction(e)
        }
    }

    getLocale() {
        let result = localeEs;
        if (util.getLang() === "en") {
            result = localeEn;

        } else if (util.getLang() === "pt") {
            result = localePtPT;

        }
        return result;
    }


    /**
     * Si el props.value viene en formato moment ya, hay que pasar la prop dateWithoutMoment = true
     * Ejemplos claros ScheduleByTechFilters - OrderFormSlot
     * @returns {*}
     */
    fechaSeleccionadaDesde() {
        let value = this.props.value;
        if (util.hasValue(value)) {
            if (!this.props.dateWithoutMoment) {
                value = util.getMoment(value).format("DD-MM-YYYY");
            }
        } else if (this.props.allowEmptyDate) {
            value = "";
        } else {
            value = util.getMoment();
        }
        // this.log({ fechaSelecc: 1, value, propsva: this.props.value })
        return value;
    }

    /**
     * Si el props.value viene en formato moment ya, hay que pasar la prop dateWithoutMoment = true
     * Ejemplos claros ScheduleByTechFilters - OrderFormSlot
     * @returns {*}
     */
    fechaSeleccionadaHasta() {
        let valueTo = this.props.valueTo;
        if (util.hasValue(valueTo)) {
            if (!this.props.dateWithoutMoment) {
                valueTo = util.getMoment(valueTo).format("DD-MM-YYYY");
            }
        } else if (this.props.allowEmptyDate) {
            valueTo = "";
        } else {
            valueTo = util.getMoment();
        }
        return valueTo;
    }

    render() {
        let seletedDateStrFrom = this.fechaSeleccionadaDesde();
        let seletedDateStrTo = this.fechaSeleccionadaHasta();
        let props = this.props;
        let readOnly = this.checkReadOnly();
        let classNameInput = this.getClassNameInput();
        let inputProps = { className: 'mbsc-overwrite', placeholder: "DD-MM-YYYY - DD-MM-YYYY ", };
        //this.log({ render: 2, seletedDateStrFrom, seletedDateStrTo })
        // https://docs.mobiscroll.com/react/calendar#override-colors Para editar los estilos
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                styleCalendar={!readOnly}
                prefix={this.props.prefix}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                relatedInfo={this.props.relatedInfo}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                labelClassName={this.props.labelClassName}
                {...props}>
                {readOnly ?
                    <input
                        value={seletedDateStr && util.getMoment(seletedDateStr).format("DD-MM-YYYY")}
                        autoComplete="new-password"
                        className={classNameInput}
                        readOnly={true}
                    />
                    :
                    <div className={"react-datepicker-wrapper"}>
                        <div className="react-datepicker__input-container">
                            <Datepicker
                                showOuterDays={true} dateFormat="DD-MM-YYYY" disabled={false}
                                showWeekNumbers={false} theme={'ios'} locale={this.getLocale()}
                                min={this.props.minDate} max={this.props.maxDate} select="range"
                                themeVariant='light' placeHolder={"Seleccione Fecha"}
                                responsive={{
                                    xsmall: {
                                        controls: ['date'], display: 'bottom', touchUi: true
                                    },
                                    small: {
                                        controls: ['calendar'], display: 'anchored', touchUi: true
                                    },
                                    custom: { // Custom breakpoint
                                        breakpoint: 800, controls: ['calendar'], display: 'anchored', touchUi: false
                                    }
                                }}
                                touchUi={util.isUserAgentMobile()}
                                value={[seletedDateStrFrom, seletedDateStrTo]}
                                onChange={(date) => this.handleChange(date)}
                                controls={this.props.controls} inputProps={inputProps} inputComponent="input"
                            />

                        </div>

                    </div>
                }
            </FormInputWrapper>
        );
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

