import React, {Component} from 'react';
import {observer} from 'mobx-react';
import translate from "../translator/translate";
import Helmet from "react-helmet";
import util from "../util/Util";

@observer
class PageTitle extends Component {
    constructor(props) {
        super(props);
    }

    onClick() {
        if (this.props.onClickTitle != null) {
            this.props.onClickTitle();
        }
    }


    render() {
        let props = this.props;
        const t = translate;
        let className = "col-12 col-lg-7 d-flex align-items-center";
        if (this.props.onClickTitle != null) {
            className += " text-click";
        }
        return (
            <div className="title-section">
                <div className="title-section--inner">
                    <div className="row">
                        {props.title &&
                        <Helmet>
                            <title>{props.title || "Nuddo Field Service"}{" "}{props.subtitle || ""}</title>
                        </Helmet>
                        }
                        <div className={className}
                             onClick={() => this.onClick()}>
                            <h1 className={util.hasValue(props.subtitle) ? "title title--inline" : "title"}>{props.title}</h1>
                            <h2 className={this.props.toLowerCase ? "text-lowercase subtitle " : "subtitle "}>{props.subtitle}</h2>
                        </div>
                        <div className="col-12 col-lg-5 d-flex align-items-center justify-content-end">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PageTitle

