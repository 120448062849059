import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { RetryLink } from 'apollo-link-retry'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { persistCache } from 'apollo-cache-persist';
import config from '../config/config';
import appState from '../state/AppState';
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from 'apollo-utilities'
import { SubscriptionClient } from "subscriptions-transport-ws";

const cache = new InMemoryCache();

persistCache({
    cache,
    storage: window.localStorage,
    debug: true,
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path, extensions }) =>
            console.error(`[GraphQL Error]: Message: ${message}, Location:${locations} , Path: ${path} , extensions: ${JSON.stringify(extensions)}`))
    }
    if (networkError) {
        console.error(`[GraphQL Network Error]: Message: ${networkError}`);
    }
});

let currentPromiseForRefresh = null;
let evaluatingPromise = false;


// action which happens on relevant error
let recoveryLink = new RetryLink({
    delay: {
        initial: 0,
    },
    attempts: {
        max: 2,
        retryIf: (error, operation) => {
            if (error.statusCode === 401) {
                if (currentPromiseForRefresh == null && !evaluatingPromise) {
                    evaluatingPromise = true;
                    let promise = new Promise((resolve, reject) => {
                        // your refresh query here
                        appState.loginState.doRefreshToken().then(response => {
                            // do something with response
                            // retry original query
                            currentPromiseForRefresh = null;
                            evaluatingPromise = false;
                            resolve(true);
                        }).catch(() => {
                            currentPromiseForRefresh = null;
                            evaluatingPromise = false;
                            resolve(false);
                        })
                    });
                    currentPromiseForRefresh = promise;
                }
                return currentPromiseForRefresh;
            }
            return false;
        }
    }
});

const gqlHeaders = {
    "DEVICE": "WEB",
    "Accept-Encoding": "gzip, deflate, br",
    "Connection": "keep-alive"
};
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    let token = appState.loginState.getAccessToken;
    return {
        headers: {
            ...headers,
            "Authorization": token ? `Bearer ${token}` : "",
            ...gqlHeaders
        }
    }
});

//
// Añade al resultado de GQL las cabeceras que necesitamos procesar
// Para que funcione las variables que añadimos a response tenemos que poner defaultOptions.query.fetchPolicy desde network-only hasta no-cache. Para que nunca cachee queries
//
const retrieveResponseHeadersLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        let headers = operation.getContext("response").response.headers;
        if (response != null && response.data != null) {
            response.data.nuddoMaintenance = headers.get("nuddoMaintenance");
            response.data.nuddoDbVersion = headers.get("nuddoDbVersion");
        }
        return response;
    });
});


// const customFetch = (uri, options) => {
//     let responseFetch = fetch(uri, options);
//     consoleapp.log({responseFetch});
//     return responseFetch;
// };

//const httpLinkRaw = new HttpLink({ uri: config.apiHostBaseUrl });
//const httpLink = createHttpLink({ uri: config.apiHostBaseUrl, useGETForQueries: false });
//La URL aunque sea POST pone en la propia URL el tipo de busqueda que hace
const httpLink = createHttpLink(
    {
        uri: (params) => {
            //window.consoleapp.log({params});
            return config.apiHostBaseUrl+"?op="+params?.operationName
        },
        useGETForQueries: false
    });


let wsClient = new SubscriptionClient(config.apiSubscriptionHostBaseUrl, {
    reconnect: false,
    lazy: true,
    connectionParams: async() => {
        const authorization = "Bearer " + appState.loginState.getAccessToken;
        // consoleapp.log({ authorization });
        return authorization ? { headers: { authorization } } : null
    },
});

//const httpLink = retrieveResponseHeadersLink.concat(httpLinkRaw);
//const httpLink = createHttpLink({uri: config.apiHostBaseUrl, fetch: customFetch });
const wsLink = new WebSocketLink(wsClient);
/*{
    uri: config.apiSubscriptionHostBaseUrl,
    options: {
        reconnect: true,
        lazy: true,
        connectionParams2: {
            auth: {
                prueba:2
            },
        },
        connectionParams: async () => {
            //const token = appState.loginState.getAccessToken;
            //consoleapp.log({connectionParams,token});
            return {
                auth: {
                    prueba:appState.loginState.getAccessToken,
                },
                headers: {
                    Authorization: "Bearer "+appState.loginState.getAccessToken
                }
            }
        },
        connectionParams: {
            authToken2: appState.loginState.getAccessToken,
        }

    }
});
*/
/*
const subscriptionMiddleware = {
    applyMiddleware(options, next) {
        consoleapp.log("subscriptionMiddleware.applyMiddleware");
        let token = appState.loginState.getAccessToken;
        let tokenBearer = token ? `Bearer ${token}` : null;
        options.setContext({
            headers: {
                authorization: tokenBearer() || null,
            },
        });
        next();
    },
};
wsLink.subscriptionClient.use([subscriptionMiddleware]);
*/

let linkHttp = ApolloLink.from([
        recoveryLink,
        errorLink,
        retrieveResponseHeadersLink,
        authLink,
        httpLink,
    ]
);
const link = split(
    ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === 'OperationDefinition' && operation === 'subscription'
    },
    //wsLink.concat(authLink),
    wsLink,
    linkHttp
);
const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    query: {
        fetchPolicy: 'no-cache', //cambiado a no-cache para que funcione retrieveResponseHeadersLink
        errorPolicy: 'all',
    },
    mutate: {
        errorPolicy: 'none',
    }
};
const client = new ApolloClient({
    link,
    cache,
    connectToDevTools: true,
    defaultOptions,
});

export default client;
