import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withRouter} from 'react-router-dom'
import appState from '../../state/AppState';
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import util from '../../util/Util';
import PageTitleRightModal from "../../components/modals/PageTitleRightModal";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import VsfButtonNew from "../../components/VsfButtonNew";
import SwitchComponent from "../../components/fields/SwitchComponent";

@observer
class OrderTaskModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tasksOfTypified: [],
            loading: false,
            weight: 0
        }
    }

    async componentDidMount() {
        let notifType = this.props.sapNotifType;
        let data = appState.configState.getTasksInfo(notifType) || [];
        let tasksOfTypifiedDict = this.ordenarByParentId(data);
        let tasksOfTypified = this.getArrayObj(tasksOfTypifiedDict);
        this.log({
            componentDidMount: 1,
            data, tasksOfTypified, tasksOfTypifiedDict,
            asset: this.props.asset,
            notifType, dict: appState.configState.dataNotifDict
        });
        this.setState({ tasksOfTypified });
        this.calcWeightTask();
    }

    getFilterOfType(type) {
        let data = {
            "BOT": "ZRDVTBOT",
            "PA": "ZEFRETPA",
            "DISP": "ZRDDISP",
            "VEND": "ZRDVEND",
        };
        return data[type]
    }

    ordenarByParentId(array) {
        let assetType = appState.typifiedState.getObject(this.props.asset?.type)?.code;
        let assetSubype = appState.typifiedState.getObject(this.props.asset?.subType)?.code;
        let result = {};
        for (let task of array) {
            let key = task.id || task.tagCode;
            if (util.hasValue(task.parentId) && task.parentId !== util.getZeroIdIdentifierGUID()) {
                key = task.parentId;
            }
            if (result[key] == null) {
                result[key] = [];
            }
            let item = {
                code: task.code || "",
                value: key === task.tagCode ? task.tagCode + " " + task.text : task.text || task.value,
                parentId: key,
                weigth: (task.weight || util.getRandomInt(0, 10)),
                id: task.tagCode || task.id
            };
            if (key === this.getFilterOfType(assetSubype) || task.code === this.getFilterOfType(assetSubype)) {
                result[key].push(item);
            }
        }
        return result;
    }

    getArrayObj(obj) {
        let objExistente = {};
        let result = [];
        for (let key of Object.keys(obj)) {
            for (let item of obj[key]) {
                // this.log({ getArrayObj: 1, key, objVal: obj[key], item });
                if (objExistente[item.value] == null) {
                    objExistente[item.value] = "";
                    result.push(item)
                }
            }
        }
        return result;
    }

    calcWeightTask() {
        let result = 0;
        for (let itemId of Object.keys(appState.workLogState.tasksSelectedDict)) {
            if (util.hasValue(appState.workLogState.tasksSelectedDict[itemId].id)) {
                result += appState.workLogState.tasksSelectedDict[itemId].weigth
            }
        }
        this.setState({ weight: result })
    }

    async onPressMultiples(item) {
        if (appState.workLogState.tasksSelectedDict[item.id] != null) {
            delete appState.workLogState.tasksSelectedDict[item.id];
        } else {
            appState.workLogState.tasksSelectedDict[item.id] = item;
        }
        this.calcWeightTask();
        appState.layoutState.formWithoutChanges = false;
        this.log({ onPressMultiples: 1, app: appState.workLogState.tasksSelectedDict[item.id], item })
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let modalClasses = util.getModalClasses();
        return (
            <InfoBlock componentObject={this}>
                <div className={modalClasses.push}>
                    <div className={modalClasses.head}>
                        <PageTitleRightModal componentObject={this}
                                             title={t('Asignar Tareas')}
                                             onBackModal={() => this.props.onCloseModal()}
                                             mode={"view"}
                                             onCloseModal={() => this.props.onCloseModal()}>
                        </PageTitleRightModal>
                    </div>
                    <div className="card">
                        <div className={modalClasses.body}>
                            <div className={"row"}>
                                {this.state.tasksOfTypified?.map(item => (
                                    <>{item.parentId === item.id
                                        ?
                                        <div className={"col-12"}>
                                            {item.value}
                                        </div>
                                        :
                                        <SwitchComponent
                                            value={item.value}
                                            checked={appState.workLogState.tasksSelectedDict[item.id]}
                                            title={item.value + " Peso: " + item.weigth}
                                            onChange={(e) => this.onPressMultiples(item)}
                                            name={item.value}
                                            className={"col-12"}
                                            classGroup={"col-6"}
                                            flexChildren={"0"}
                                            flexLabelInput="d-flex justify-content-between"
                                        />

                                    }
                                    </>
                                ))}
                            </div>
                            <div className=" fixed-bottom">

                                <div className="col-6">
                                    {t("Valoración para el aviso")}{": "}{this.state.weight}{this.state.weight < 9 ? "Básica" : this.state.weight > 20 ? "Máxima" : "Media"}
                                </div>
                                <div className="col-4">
                                    <VsfButtonNew
                                        label={t('Guardar')}
                                        loading={this.state.loading} icon={"fas fa-save"}
                                        className="btn btn--red"
                                        onClick={() => this.saveResults()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </InfoBlock>

        )
    }

    saveResults() {
        this.setState({ loading: true });
        let tasksSelected = [];
        appState.workLogState.saveWorkLogOrder = true;
        for (let itemId of Object.keys(appState.workLogState.tasksSelectedDict)) {
            if (util.hasValue(appState.workLogState.tasksSelectedDict[itemId].id)) {
                tasksSelected.push(appState.workLogState.tasksSelectedDict[itemId]);
            }
        }
        appState.workLogState.workLogsTasksOfOrder = tasksSelected;
        if (util.hasValue(this.props.orderId)) {
            this.props.saveOrderTasks()
        }
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000)
        setTimeout(() => {
            this.setState({ loading: false });
            this.props.onCloseModal();
        }, 3000)
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default withRouter(OrderTaskModal)
