import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import FormInputWrapper from './FormInputWrapper'
import BaseFieldComponent from './BaseFieldComponent'
import MezclarObj from '../../util/MezclarObj'

@observer
export default class RadioButtonComponent extends BaseFieldComponent {

    static defaultProps = MezclarObj.assign(BaseFieldComponent.defaultProps, {
        onChange: _ => {
        },
        options: [
            {
                label: 'Si',
                value: true,
                id: '1',

            },
            {
                label: 'No',
                value: false,
                id: '0',

            },
        ]
    })

    static propTypes = MezclarObj.assign(BaseFieldComponent.propTypes, {
        onChange: PropTypes.func,
        options: PropTypes.array
    })

    onChange (event) {
        this.props.onChange(event)
    }

    render () {
        let props = this.props
        let readOnly = this.checkReadOnly()
        return (
            <FormInputWrapper
                classGroup={props.classGroup}
                name={props.name}
                postfix={this.props.postfix}
                prefix={this.props.prefix}
                errors={this.props.errors}
                errorsClientValidation={this.state.errorsClientValidation}
                title={this.props.title}
                required={this.props.required}
                info={this.props.info}
                relatedInfo={this.props.relatedInfo}
                hasNoErrors={this.props.hasNoErrors}
                forceMedia={this.props.forceMedia}
                labelClassName={this.props.labelClassName}
                data-cy={this.props.dataCy}
                {...props}
            >

                <div className="row m-0">
                    {props.options.map(opp => {
                        if (readOnly) {
                            return (
                                    <>
                                        {opp.value === this.props.value &&
                                        <label className="form-radio-label pl-4"
                                            htmlFor={opp.id}>
                                            {opp.label}
                                        </label>
                                        }
                                    </>
                            )
                        }
                        return (
                            <div key={opp.id} className="form-checkform-check-inline mr-1">
                                <input autoComplete="off"
                                    type="radio"
                                    className="form-radio-input"
                                    value={opp.value || ''}
                                    id={opp.id}
                                    name={this.props.name}
                                    checked={opp.value === this.props.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                                <label className="form-radio-label pl-4"
                                    htmlFor={opp.id}>
                                    {opp.label}
                                </label>
                            </div>
                        )
                    }
                    )}
                </div>
            </FormInputWrapper>
        )
    }
}

