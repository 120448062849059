/** Permite que al hacer click la página suba al TOP **/
import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import posed from 'react-pose'
import SpinnerClipLoaderComponent from './network/SpinnerClipLoaderComponent'
import AppWrapper from './layout/AppWrapper'
import AppLogger from './util/AppLogger'

const WarehouseImportExcel = React.lazy(() => import('./pages/importExcel/WarehouseImportExcel'))
const StockImportExcel = React.lazy(() => import('./pages/importExcel/StockImportExcel'))
const StockMovementList = React.lazy(() => import('./pages/stockMovements/StockMovementList'))
const StockMovementForm = React.lazy(() => import('./pages/stockMovements/StockMovementForm'))
const AssetWorkOrderArticleList = React.lazy(() => import('./pages/assets/AssetWorkOrderArticleList'))
const AssetWorkOrderList = React.lazy(() => import('./pages/assets/AssetWorkOrderList'))
const BinList = React.lazy(() => import('./pages/bins/BinList'))
const BinForm = React.lazy(() => import('./pages/bins/BinForm'))
const ContractForm = React.lazy(() => import('./pages/contracts/ContractForm'))
const ContractList = React.lazy(() => import('./pages/contracts/ContractList'))
const WorkOrderList = React.lazy(() => import('./pages/workOrders/WorkOrderList'))
const WorkOrderForm = React.lazy(() => import('./pages/workOrders/WorkOrderForm'))
const OrderList = React.lazy(() => import('./pages/orders/OrderList'))
const OrderForm = React.lazy(() => import('./pages/orders/OrderForm'))
const ClientList = React.lazy(() => import('./pages/clients/ClientList'))
const ClientImportExcel = React.lazy(() => import('./pages/importExcel/ClientImportExcel'))
const AssetImportExcel = React.lazy(() => import('./pages/importExcel/AssetImportExcel'))
const ArticleImportExcel = React.lazy(() => import('./pages/importExcel/ArticleImportExcel'))
const ClientForm = React.lazy(() => import('./pages/clients/ClientForm'))
const ClientPosForm = React.lazy(() => import('./pages/clients/ClientPosForm'))
const UserList = React.lazy(() => import('./pages/users/UserList'))
const UserWithoutEndDayList = React.lazy(() => import('./pages/users/UserWithoutEndDayList'))
const UserForm = React.lazy(() => import('./pages/users/UserForm'))
const UserCapacitations = React.lazy(() => import('./pages/users/UserCapacitations'))
const AssetList = React.lazy(() => import('./pages/assets/AssetList'))
const AssetWarranty = React.lazy(() => import('./pages/assets/AssetWarranty'))
const AssetPlanogram = React.lazy(() => import('./pages/assets/AssetPlanogram'))
const AssetForm = React.lazy(() => import('./pages/assets/AssetForm'))
const OriginTypifiedList = React.lazy(() => import('./pages/origins/OriginTypifiedList'))
const OriginTypifiedForm = React.lazy(() => import('./pages/origins/OriginTypifiedForm'))
const TypifiedValueList = React.lazy(() => import('./pages/typifieds/TypifiedValueList'))
const TypifiedValueForm = React.lazy(() => import('./pages/typifieds/TypifiedValueForm'))
const ScheduleByClient = React.lazy(() => import('./dndrop/scheduleByClient/ScheduleByClient'))
const ScheduleByClientPrevent = React.lazy(() => import('./pages/clients/clientSchedule/ScheduleByClientPrevent'))
const ScheduleVisitList = React.lazy(() => import('./pages/scheduledVisits/ScheduleVisitList'))
const WorkOrderFormSlots = React.lazy(() => import('./pages/workOrders/WorkOrderFormSlots'))
const WorkOrderFormItems = React.lazy(() => import('./pages/workOrders/WorkOrderFormArticles'))
const ScheduleByTechnical = React.lazy(() => import('./dndrop/scheduleByTechnical/ScheduleByTechnical'))
const ClientContacts = React.lazy(() => import('./pages/clients/ClientContacts'))
const ClientPosContacts = React.lazy(() => import('./pages/clients/ClientPosContacts'))
const WorkOrderArticleList = React.lazy(() => import('./pages/articles/WorkOrderArticleList'))
const ArticleList = React.lazy(() => import('./pages/articles/ArticleList'))
const ArticleForm = React.lazy(() => import('./pages/articles/ArticleForm'))
const ProfileForm = React.lazy(() => import('./pages/ProfileForm'))
const OrderFormSlots = React.lazy(() => import('./pages/orders/OrderFormSlots'))
const ChangePassword = React.lazy(() => import('./pages/ChangePassword'))
const OrderFormWorkOrders = React.lazy(() => import('./pages/orders/OrderFormWorkOrders'))
const OrderFormArticles = React.lazy(() => import('./pages/orders/OrderFormArticles'))
const ArticleFormLocationTable = React.lazy(() => import('./pages/articles/ArticleFormLocationTable'))
const ArticleWorkOrderList = React.lazy(() => import('./pages/articles/ArticleWorkOrderList'))
const PruebasForm = React.lazy(() => import('./pages/PruebasForm'))
const ApplicationPage = React.lazy(() => import('./pages/ApplicationPage'))
const ScheduleVisitForm = React.lazy(() => import('./pages/scheduledVisits/ScheduleVisitForm'))
const WorkOrderHistory = React.lazy(() => import('./pages/workOrders/WorkOrderHistory'))
const OrderHistory = React.lazy(() => import('./pages/orders/OrderHistory'))
const AssetHistory = React.lazy(() => import('./pages/assets/AssetHistory'))
const ArticleHistory = React.lazy(() => import('./pages/articles/ArticleHistory'))
const ClientHistory = React.lazy(() => import('./pages/clients/ClientHistory'))
const ClientPosHistory = React.lazy(() => import('./pages/clients/ClientPosHistory'))
const SubcontractHistory = React.lazy(() => import('./pages/subcontracts/SubcontractHistory'))
const PageDocument = React.lazy(() => import('./pages/PageDocument'))
const PageDocumentSortable = React.lazy(() => import('./pages/PageDocumentSortable'))
const UserFieldWorks = React.lazy(() => import('./pages/users/UserFieldWorks'))
const UserHistory = React.lazy(() => import('./pages/users/UserHistory'))
const ErrorBoundary = React.lazy(() => import('./components/ErrorBoundary'))
const ClientPosList = React.lazy(() => import('./pages/clients/ClientPosList'))
const OrderHistoryTech = React.lazy(() => import('./pages/orders/OrderHistoryTech'))
const AssetHistoryTech = React.lazy(() => import('./pages/assets/AssetHistoryTech'))
const UserAvailabilityTable = React.lazy(() => import('./pages/users/UserAvailabilityTable'))
const VehicleGpsList = React.lazy(() => import('./pages/vehiclesGps/VehicleGpsList'))
const VehicleList = React.lazy(() => import('./pages/vehicles/VehicleList'))
const VehicleForm = React.lazy(() => import('./pages/vehicles/VehicleForm'))
const BudgetForm = React.lazy(() => import('./pages/budgets/BudgetForm'))
const BudgetHistory = React.lazy(() => import('./pages/budgets/BudgetHistory'))
const VehicleKmLogs = React.lazy(() => import('./pages/vehicles/VehicleKmLogs'))
const VehicleHistory = React.lazy(() => import('./pages/vehicles/VehicleHistory'))
const ConfigTranslates = React.lazy(() => import('./pages/config/ConfigTranslates'))
const ConfigFormForm = React.lazy(() => import('./pages/config/ConfigFormForm'))
const ConfigTenantForm = React.lazy(() => import('./pages/config/ConfigTenantForm'))
const ConfigFormAssignmentForm = React.lazy(() => import('./pages/config/ConfigFormAssignmentForm'))
const ConfigTranslatesImport = React.lazy(() => import('./pages/config/ConfigTranslatesImport'))
const ConfigFormList = React.lazy(() => import('./pages/config/ConfigFormList'))
const ConfigTenantList = React.lazy(() => import('./pages/config/ConfigTenantList'))
const ConfigFormAssignmentList = React.lazy(() => import('./pages/config/ConfigFormAssignmentList'))
const AssetModelForm = React.lazy(() => import('./pages/assetModels/AssetModelForm'))
const AssetModelList = React.lazy(() => import('./pages/assetModels/AssetModelList'))
const AssetModelHistory = React.lazy(() => import('./pages/assetModels/AssetModelHistory'))
const OrderSummaryPage = React.lazy(() => import('./pages/orders/OrderSummaryPage'))
const ConfigFestives = React.lazy(() => import('./pages/config/ConfigFestives'))
const ConfigValidates = React.lazy(() => import('./pages/config/ConfigValidatesTable'))
const ConfigWarningsForm = React.lazy(() => import('./pages/config/ConfigWarningsForm'))
const ConfigWarningList = React.lazy(() => import('./pages/config/ConfigWarningList'))
const ConfigDynamicsList = React.lazy(() => import('./pages/config/ConfigDynamicsList'))
const ConfigDynamicForm = React.lazy(() => import('./pages/config/ConfigDynamicForm'))
const ConfigNetsuiteSync = React.lazy(() => import('./pages/config/ConfigNetsuiteSync'))
const ScheduleByClientOrderView = React.lazy(() => import('./dndrop/scheduleByClient/ScheduleByClientOrderView'))
const ScheduleByTechnicalOrderView = React.lazy(() => import('./dndrop/scheduleByTechnical/ScheduleByTechnicalOrderView'))
const ConfigForm = React.lazy(() => import('./pages/config/ConfigForm'))
const Page404 = React.lazy(() => import('./pages/Page404'))
const PageDynamic = React.lazy(() => import('./pages/PageDynamic'))
const OrderFormBudgetTable = React.lazy(() => import('./pages/orders/OrderFormBudgetTable'))
const StockList = React.lazy(() => import('./pages/stocks/StockList'))
const WorkOrderFormBudgetTable = React.lazy(() => import('./pages/workOrders/WorkOrderFormBudgetTable'))
const WorkOrderSummary = React.lazy(() => import('./pages/workOrders/WorkOrderSummary'))
const WarehouseList = React.lazy(() => import('./pages/warehouses/WarehouseList'))
const WarehouseForm = React.lazy(() => import('./pages/warehouses/WarehouseForm'))
const SubcontractList = React.lazy(() => import('./pages/subcontracts/SubcontractList'))
const SubcontractForm = React.lazy(() => import('./pages/subcontracts/SubcontractForm'))
const SubcontractContacts = React.lazy(() => import('./pages/subcontracts/SubcontractContacts'))
const BudgetList = React.lazy(() => import('./pages/budgets/BudgetList'))
const SlotList = React.lazy(() => import('./pages/slots/SlotList'))
const ExternalOrderForm = React.lazy(() => import('./pages/orders/ExternalOrderForm'))
const UserCheckWorkDaysTable = React.lazy(() => import('./pages/users/UserCheckInTable'))
const AdminForm = React.lazy(() => import('./pages/config/AdminForm'))
const Home = React.lazy(() => import('./pages/Home'))
const DashboardAsset = React.lazy(() => import('./pages/dashboard/DashboardAsset'))
const DashboardSlots = React.lazy(() => import('./pages/dashboard/DashboardSlots'))
const WmsMain = React.lazy(() => import('./pages/wms/WmsMain'))
const WmsPurchaseOrderLs = React.lazy(() => import('./pages/wms/WmsPurchaseOrderLs'))
const WmsPurchaseOrderDetail = React.lazy(() => import('./pages/wms/WmsPurchaseOrderDetail'))
const WmsItemFullfilmentDetail = React.lazy(() => import('./pages/wms/WmsItemFullfilmentDetail'))
const WmsTransferOrderLs = React.lazy(() => import('./pages/wms/WmsTransferOrderLs'))
const WmsTransferOrderDetail = React.lazy(() => import('./pages/wms/WmsTransferOrderDetail'))
const WmsInventoryLs = React.lazy(() => import('./pages/wms/WmsInventoryLs'))
const WmsTransferBinLs = React.lazy(() => import('./pages/wms/WmsTransferBinLs'))
const WmsInventoryDetail = React.lazy(() => import('./pages/wms/WmsInventoryDetail'))
const WmsTransferBinDetail = React.lazy(() => import('./pages/wms/WmsTransferBinDetail'))
const StockForm = React.lazy(() => import('./pages/stocks/StockForm'))
const SlaList = React.lazy(() => import('./pages/slas/SlaList'))
const SlaForm = React.lazy(() => import('./pages/slas/SlaForm'))
const OriginList = React.lazy(() => import('./pages/origins/OriginList'))
const OriginForm = React.lazy(() => import('./pages/origins/OriginForm'))
const SlotForm = React.lazy(() => import('./pages/slots/SlotForm'))
const SlotFormArticles = React.lazy(() => import('./pages/slots/SlotFormArticles'))
const SlotHistory = React.lazy(() => import('./pages/slots/SlotHistory'))
const WarehouseBinList = React.lazy(() => import('./pages/warehouses/WarehouseBinList'))
const ReportResume = React.lazy(() => import('./pages/reports/ReportResume'))
const ReportTechnical = React.lazy(() => import('./pages/reports/ReportTechnical'))
const ReportTechnicalDetail = React.lazy(() => import('./pages/reports/ReportTechnicalDetail'))
const ReportType = React.lazy(() => import('./pages/reports/ReportType'))
const ReportTypeDetail = React.lazy(() => import('./pages/reports/ReportTypeDetail'))
const ReportTask = React.lazy(() => import('./pages/reports/ReportTask'))
const ReportTaskDetail = React.lazy(() => import('./pages/reports/ReportTaskDetail'))
const ReportDiagnosis = React.lazy(() => import('./pages/reports/ReportDiagnosis'))
const ReportDiagnosisDetail = React.lazy(() => import('./pages/reports/ReportDiagnosisDetail'))
const ReportLocation = React.lazy(() => import('./pages/reports/ReportLocation'))
const ReportLocationDetail = React.lazy(() => import('./pages/reports/ReportLocationDetail'))
const ReportMatrixTechnical = React.lazy(() => import('./pages/reports/ReportMatrixTechnical'))
const ReportMatrixModel = React.lazy(() => import('./pages/reports/ReportMatrixModel'))
const ReportMatrixModelDetail = React.lazy(() => import('./pages/reports/ReportMatrixModelDetail'))
const ReportMatrixModelDate = React.lazy(() => import('./pages/reports/ReportMatrixModelDate'))
const ReportMatrixModelDay = React.lazy(() => import('./pages/reports/ReportMatrixModelDay'))

const RouteContainer = posed.div({
    enter: {
        y: 0,
        opacity: 1,
        delay: 0,
    },
    visible: {
        opacity: 1,
        transition: { duration: 0 }
    },
    exit: {
        y: 0,
        opacity: 0,
    }
})

let lastPathName = ''
const history = createBrowserHistory()
history.listen(historyObject => {
    let currentPathName = historyObject.pathname
    if (currentPathName != lastPathName) {
    //AppLogger.get().debug({history: historyObject}, this);
        window.scrollTo(0, 0)
    }
    lastPathName = currentPathName
})

/** Fin pagina subir a TOP */

/**
 * HashRouter hace que al cambiar las rutas se haga un scroll to TOP.
 * Router hace que no haga ningún scroll to top por defecto. Pero podemos hacer que con páginas nuevas si lo haga.
 */
@observer
class AppRouter extends Component {
    render () {
        let props = this.props
        let PoseGroupEmpty = React.Fragment

        return (
            <Router hashType2="hashbang" history={history}>
                <AppWrapper {...props.children}>
                    <React.Suspense fallback={<SpinnerClipLoaderComponent />}>
                        <Route render={({ location }) => (
                            <PoseGroupEmpty>
                                <RouteContainer key={location.pathname}>
                                    <Switch>
                                        {this.renderOrders().map(Child => Child)}
                                        {this.renderWmsRoutes().map(Child => Child)}
                                        {this.renderUsers().map(Child => Child)}
                                        {this.renderWorkOrders().map(Child => Child)}
                                        {this.renderConfigurations().map(Child => Child)}
                                        {this.renderAssets().map(Child => Child)}
                                        {this.renderDocuments().map(Child => Child)}
                                        {this.renderDynamics().map(Child => Child)}
                                        {this.renderVehicles().map(Child => Child)}
                                        {this.renderVehiclesGps().map(Child => Child)}
                                        {this.renderBudgets().map(Child => Child)}
                                        {this.renderClients().map(Child => Child)}
                                        {this.renderPosClients().map(Child => Child)}
                                        {this.renderSlas().map(Child => Child)}
                                        {this.renderContracts().map(Child => Child)}
                                        {this.renderImports().map(Child => Child)}
                                        {this.renderOrigins().map(Child => Child)}
                                        {this.renderSchedules().map(Child => Child)}
                                        {this.renderArticles().map(Child => Child)}
                                        {this.renderStocks().map(Child => Child)}
                                        {this.renderSlots().map(Child => Child)}
                                        {this.renderStockMovements().map(Child => Child)}
                                        {this.renderBins().map(Child => Child)}
                                        {this.renderWarehouses().map(Child => Child)}
                                        {this.renderSubcontracts().map(Child => Child)}
                                        {this.renderTypified().map(Child => Child)}
                                        {this.renderReports().map(Child => Child)}
                                        {this.renderFinal().map(Child => Child)}
                                    </Switch>
                                </RouteContainer>
                            </PoseGroupEmpty>
                        )} />
                    </React.Suspense>
                </AppWrapper>
            </Router>
        )
    }

    renderWmsRoutes () {
        let props = this.props
        let Try = ErrorBoundary

        return [
            <Route path="/wms/main" key="wms-main" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsMain
                        key="wms-main" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/purchaseOrderLs" key="wms-purchaseOrderLs" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsPurchaseOrderLs
                        key="wms-purchaseOrderLs" {...routeprops} {...props} /></Try>
                    </div>
                )} />,

            <Route path="/wms/purchaseOrderDetail" key="wms-purchaseOrderDetail" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsPurchaseOrderDetail
                        key={'wms-purchaseOrderDetail-' + this.getParam(routeprops, 'internalId') + this.getParam(routeprops, 'v')} {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/transferOrderLs" key="wms-transferOrderLs" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsTransferOrderLs
                        key="wms-transferOrderLs" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/transferOrderDetail" key="wms-transferOrderDetail" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try>
                        <WmsTransferOrderDetail
                            key={'wms-transferOrderDetail-' + (this.getParam(routeprops, 'internalId') || '') + (this.getParam(routeprops, 'v') || '')} {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/itemFullfilmentDetail" key="wms-itemFullfilmentDetail" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsItemFullfilmentDetail
                        key="wms-itemFullfilmentDetail" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/inventoryLs" key="wms-inventoryLs" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsInventoryLs
                        key="wms-inventoryLs" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/inventoryDetail" key="wms-inventoryDetail" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsInventoryDetail
                        key={"wms-inventoryArticleDetail" + this.getParam(routeprops, "id")} {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/transferBinLs" key="wms-transferBinLs" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsTransferBinLs
                        key="wms-transferBinLs" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
            <Route path="/wms/transferBinDetail" key="wms-transferBinDetail" exact={true}
                render={routeprops => (
                    <div className="wmsroot"><Try><WmsTransferBinDetail
                        key="wms-transferBinDetail" {...routeprops} {...props} /></Try>
                    </div>
                )} />,
        ]
    }

    getParam (routeprops, param) {
        const params = new URLSearchParams(routeprops.location.search)
        return params.get(param)
    }

    renderUsers () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/userWithoutEndDay/ls" key="user-ls" exact={true}
                render={routeprops => (
                    <Try><UserWithoutEndDayList
                        key="user-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/ls" key="user-ls" exact={true}
                render={routeprops => (
                    <Try><UserList
                        key="user-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?/history" key="user-history" exact={true}
                render={routeprops => (
                    <Try><UserHistory
                        key="user-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?" key="user-form" exact={true}
                render={routeprops => (
                    <Try><UserForm
                        key="user-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?/userAvailability" key="user-availability" exact={true}
                render={routeprops => (
                    <Try><UserAvailabilityTable
                        key="user-availability" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?/userClockingInTable" key="user-clockingInTable" exact={true}
                render={routeprops => (
                    <Try><UserCheckWorkDaysTable
                        key="user-clockingInTable" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?/userCapacitation" key="user-capactitations" exact={true}
                render={routeprops => (
                    <Try><UserCapacitations
                        key="user-capactitations" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/user/form/:id?/UserFieldWorks" key="user-fieldworks" exact={true}
                render={routeprops => (
                    <Try><UserFieldWorks
                        key="user-fieldworks" {...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderWorkOrders () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/workOrder/form/:id?" key="workOrder-form-items" exact={true}
                render={routeprops => (
                    <Try><WorkOrderForm
                        key="workOrder-ls2form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/assigment" key="workOrder-form-assigment" exact={true}
                render={routeprops => (
                    <Try><WorkOrderFormSlots
                        key="workOrder-form-assigment" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/items" key="workOrder-formu" exact={true}
                render={routeprops => (
                    <Try><WorkOrderFormItems
                        key="workOrder-items" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/history" key="workOrder-history" exact={true}
                render={routeprops => (
                    <Try><WorkOrderHistory
                        key="workOrder-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/workLogSummary" key="workOrder-workLogSummary" exact={true}
                render={routeprops => (
                    <Try><WorkOrderSummary
                        key="workOrder-workLogSummary" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/budget" key={'workOrder-budget'} exact={true}
                render={routeprops => (
                    <Try><WorkOrderFormBudgetTable
                        key="workOrder-budget" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/ls" key="workOrder-ls" exact={true}
                render={routeprops => (
                    <Try><WorkOrderList
                        key="workOrder-ls" {...routeprops} {...props} /></Try>
                )} />,
        ]
    }

    renderOrders () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/order/ls" key={'order-ls'} exact={false}
                render={routeprops => (
                    <Try><OrderList
                        key="order-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/historytech" key="order-historytech" exact={true}
                render={routeprops => (
                    <Try><OrderHistoryTech
                        key="order-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?" key={'order-form'} exact={true} saveLocation
                render={routeprops => (
                    <Try><OrderForm
                        key="order-form" {...routeprops} {...props} >
                        {/*<OrderFormWorkOrders*/}
                        {/*    key="order-/workOrder" {...routeprops} {...props} />*/}
                        {/*<OrderHistoryTech*/}
                        {/*    key="order-history" {...routeprops} {...props} />*/}
                        {/*<OrderFormSlots*/}
                        {/*    key="order-form-slots" {...routeprops} {...props} />*/}
                        {/*<OrderSummaryPage*/}
                        {/*    key="order-form-summary" {...routeprops} {...props} />*/}
                        {/*<OrderFormArticles*/}
                        {/*    key="order-articles" {...routeprops} {...props} />*/}
                        {/*<OrderFormBudgetTable*/}
                        {/*    key="order-budget" {...routeprops} {...props} />*/}
                        {/*<OrderHistory*/}
                        {/*    key="order-history" {...routeprops} {...props} />*/}
                    </OrderForm>
                    </Try>
                )} />,
            <Route path="/externalOrder/form/:id?" key={'order-externalOrder'} exact={true}
                render={routeprops => (
                    <Try><ExternalOrderForm
                        key="order-externalOrder" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/slots" key="order-form-slots" exact={true}
                render={routeprops => (
                    <Try><OrderFormSlots
                        key="order-form-slots" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/summary" key="order-form-summary" exact={true}
                render={routeprops => (
                    <Try><OrderSummaryPage
                        key="order-form-summary" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/workOrder" key={'order-/workOrder'} exact={true}
                render={routeprops => (
                    <Try><OrderFormWorkOrders
                        key="order-/workOrder" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/articles" key={'order-articles'} exact={true}
                render={routeprops => (
                    <Try><OrderFormArticles
                        key="order-articles" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/budget" key={'order-budget'} exact={true}
                render={routeprops => (
                    <Try><OrderFormBudgetTable
                        key="order-budget" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/budget/ls" exact={false} key={'budget-ls'}
                render={routeprops => (
                    <Try><BudgetList
                        key="budget-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/history" key="order-history" exact={true}
                render={routeprops => (
                    <Try><OrderHistory
                        key="order-history" {...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderConfigurations () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/config/translates" key="config-translates" exact={true}
                render={routeprops => (
                    <Try><ConfigTranslates
                        key="config-translates" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/form/ls" key="config-form-list" exact={true}
                render={routeprops => (
                    <Try><ConfigFormList
                        key="config-form-list" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/tenant/ls" key="config-tenant-list" exact={true}
                render={routeprops => (
                    <Try><ConfigTenantList
                        key="config-tenant-list" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/tenant/form/:id?" key="config-tenant-form" exact={true}
                render={routeprops => (
                    <Try><ConfigTenantForm
                        key="config-tenant-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/formAssignment/ls" key="config-formAssignment-list" exact={true}
                render={routeprops => (
                    <Try><ConfigFormAssignmentList
                        key="config-formAssignment-list" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/formAssignment/form/:id?" key="config-formAssignment-form" exact={true}
                render={routeprops => (
                    <Try><ConfigFormAssignmentForm
                        key="config-formAssignment-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/form/form/:id?" key="config-form-form" exact={true}
                render={routeprops => (
                    <Try><ConfigFormForm
                        key="config-form-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/festives" key="config-festives" exact={true}
                render={routeprops => (
                    <Try><ConfigFestives
                        key="config-festives" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/importTranslates/" key="importTranslates" exact
                render={routeprops => (
                    <Try><ConfigTranslatesImport
                        key="importTranslates" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/admin/form" key="admin-form" exact={true}
                render={routeprops => (
                    <Try><AdminForm
                        key="admin-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/form" key="config-form" exact={true}
                render={routeprops => (
                    <Try><ConfigForm
                        key="config-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/validates" key="config-validates" exact={true}
                render={routeprops => (
                    <Try><ConfigValidates
                        key="config-validates" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/warnings" key="config-warnings" exact={true}
                render={routeprops => (
                    <Try><ConfigWarningList
                        key="config-warnings" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/warnings/form/:id?" key="config-warnings-form" exact={true}
                render={routeprops => (
                    <Try><ConfigWarningsForm
                        key="config-warnings-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/dynamicGroups/ls" key="dynamicGroups" exact={true}
                render={routeprops => (
                    <Try><ConfigDynamicsList
                        key="dynamicGroups" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/dynamicGroups/form/:id?" key="dynamicGroupsForm" exact={true}
                render={routeprops => (
                    <Try><ConfigDynamicForm
                        key="dynamicGroupsForm" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/config/netsuite" key="config-netsuite" exact={true}
                render={routeprops => (
                    <Try><ConfigNetsuiteSync
                        key="config-netsuite" {...routeprops} {...props} /></Try>
                )} />,
        ]
    }

    renderAssets () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/asset/ls" key="asset-ls" exact={true}
                render={routeprops => (
                    <Try><AssetList
                        key="asset-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?" key="asset-form" exact={true}
                render={routeprops => (
                    <Try><AssetForm
                        key="asset-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/history" key="asset-history" exact={true}
                render={routeprops => (
                    <Try><AssetHistory
                        key="asset-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/assetModel/form/:id?/history" key="asset-model-history" exact={true}
                render={routeprops => (
                    <Try><AssetModelHistory
                        key="asset-model-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/historyComment" key="asset-historyComment" exact={true}
                render={routeprops => (
                    <Try><AssetHistoryTech
                        key="asset-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/workOrderCartList" key="asset-workOrderCartList" exact={true}
                render={routeprops => (
                    <Try><AssetWorkOrderArticleList
                        key="asset-workOrderCartList" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/workOrderList" key="asset-workOrderCartList" exact={true}
                render={routeprops => (
                    <Try><AssetWorkOrderList
                        key="asset-workOrderCartList" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/warranty" key="asset-warranty" exact={true}
                render={routeprops => (
                    <Try><AssetWarranty
                        key="asset-warranty" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/planogram" key="asset-planogram" exact={true}
                render={routeprops => (
                    <Try><AssetPlanogram
                        key="asset-planogram" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/assetModel/form/:id?" key="asset-model-form" exact={true}
                render={routeprops => (
                    <Try><AssetModelForm
                        key="asset-model-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/assetModel/ls" key="assetModel-ls" exact={true}
                render={routeprops => (
                    <Try><AssetModelList
                        key="assetModel-ls" {...routeprops} {...props} /></Try>
                )} />,
        ]
    }

    renderDocuments () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/user/form/:id?/document" key="user-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="asset-form-document" model="user" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/document" key="asset-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocumentSortable
                        key="asset-form-document" model="asset" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/subcontract/form/:id?/document" key="subcontract-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="subcontract-form-document" model="subcontract" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/document" key="order-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="order-form-document" model="order" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/document" key="workOrder-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="workOrder-form-document" model="workOrder" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/article/form/:id?/document" key="article-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="article-form-document" model="article"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/stock/form/:id?/document" key="stock-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="stock-form-document" model="stock"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/client/form/:id?/document" key="client-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocumentSortable
                        key="client-form-document" model="client"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/clientPos/form/:id?/document" key="clientPos-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocumentSortable
                        key="clientPos-form-document" model="clientPos"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/schedule/visit/type/form/:id?/document" key="schedule-visit-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="schedule-visit-form-document" model="scheduleVisit"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/assetModel/form/:id?/document" key="asset-model-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="asset-model-form-document" model="assetModel"{...routeprops} {...props} /></Try>
                )} />,
            <Route path="/vehicle/form/:id?/document" key="vehicle-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDocument
                        key="vehicle-form-document" model="vehicle"{...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderDynamics () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/clientPos/form/:id?/dynamicGroups" key="client-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDynamic
                        key="client-form-document" model="client" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/asset/form/:id?/dynamicGroups" key="asset-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDynamic
                        key="asset-form-document" model="asset" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/order/form/:id?/dynamicGroups" key="order-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDynamic
                        key="order-form-document" model="order" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/workOrder/form/:id?/dynamicGroups" key="workOrder-form-document" exact={true}
                render={routeprops => (
                    <Try><PageDynamic
                        key="workOrder-form-document" model="workOrder" {...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderVehicles () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/vehicle/ls" key="vehicle-ls" exact={true}
                render={routeprops => (
                    <Try><VehicleList
                        key="vehicle-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/vehicle/form/:id?" key="vehicle-form" exact={true}
                render={routeprops => (
                    <Try><VehicleForm
                        key="vehicle-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/vehicle/form/:id?/history" key="vehicle-history" exact={true}
                render={routeprops => (
                    <Try><VehicleHistory
                        key="vehicle-history" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/vehicle/form/:id?/vehicleKmLogs" key="vehicle-vehicleKmLogs" exact={true}
                render={routeprops => (
                    <Try><VehicleKmLogs
                        key="vehicle-vehicleKmLogs" {...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderBudgets () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/budget/form/:id" key="budget-form" exact={true}
                render={routeprops => (
                    <Try><BudgetForm
                        key="budget-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/budget/form/:id?/history" key="budget-history" exact={true}
                render={routeprops => (
                    <Try><BudgetHistory
                        key="budget-history" {...routeprops} {...props} /></Try>
                )} />,
        ]
    }

    renderVehiclesGps () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/gpsVehicle/ls" key="gps-vehicle-ls" exact={true}
                render={routeprops => (
                    <Try><VehicleGpsList
                        key="gps-vehicle-ls" {...routeprops} {...props} /></Try>
                )} />
        ]
    }

    renderClients () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/client/form/:id/contacts" key={'client-contacs'}
                render={routeprops => (
                    <Try><ClientContacts
                        key="client-contacs" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/client/ls" key="client-ls" exact={true}
                render={routeprops => (
                    <Try><ClientList
                        key="client-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/client/form/:id?" key="client-form" exact={true}
                render={routeprops => (
                    <Try><ClientForm
                        key="client-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/client/form/:id?/history" key="client-history" exact={true}
                render={routeprops => (
                    <Try><ClientHistory
                        key="client-history" {...routeprops} {...props} /></Try>
                )} />,

        ]
    }

    renderPosClients () {
        let props = this.props
        let Try = ErrorBoundary
        return [
            <Route path="/clientPos/form/:id/prevent" key="clientPos-prevent"
                render={routeprops => (
                    <Try><ScheduleByClientPrevent
                        key="clientPos-prevent" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/clientPos/form/:id/contacts" key={'clientPos-contacs'}
                render={routeprops => (
                    <Try><ClientPosContacts
                        key="clientPos-contacs" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/clientPos/ls" key="posClient-ls" exact={true}
                render={routeprops => (
                    <Try><ClientPosList
                        key="posClient-ls" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/clientPos/form/:id?" key="clientPos-form" exact={true}
                render={routeprops => (
                    <Try><ClientPosForm
                        key="clientPos-form" {...routeprops} {...props} /></Try>
                )} />,
            <Route path="/clientPos/form/:id?/history" key="clientPos-history" exact={true}
                render={routeprops => (
                    <Try><ClientPosHistory
                        key="clientPos-history" {...routeprops} {...props} /></Try>
                )} />,

        ]
    }

  RouteCustom = (props) => {
      let Try = ErrorBoundary
      const Component = props.component
      return <Route path={props.path}
          key={'keyCustom' + props.path}
          render={routeprops => (
              <Try><Component
                  key={'keyPath' + props.path} {...routeprops} {...props} /></Try>
          )} />
  }

  renderImports () {
      const RouteCustom = this.RouteCustom
      return [
          <RouteCustom path="/clientPos/importExcel" key={'keyCustom/clientPos/importExcel'} component={ClientImportExcel} />,
          <RouteCustom path="/warehouse/importExcel" key={'keyCustom/warehouse/importExcel'} component={WarehouseImportExcel} />,
          <RouteCustom path="/stock/importExcel" key={'keyCustom/stock/importExcel'} component={StockImportExcel} />,
          <RouteCustom path="/asset/importExcel" key={'keyCustom/asset/importExcel'} component={AssetImportExcel} />,
          <RouteCustom path="/article/importExcel" key={'keyCustom/article/importExcel'} component={ArticleImportExcel} />,
      ]
  }

  renderContracts () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/contract/ls" key={'routecontract-list'}
              render={routeprops => (
                  <Try><ContractList
                      key="contract-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/contract/form/:id?" exact={true} key={'routecontract-form'}
              render={routeprops => (
                  <Try><ContractForm
                      key="contract-form" {...routeprops} {...props} /></Try>
              )} />,
      ]
  }

  renderSlas () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/sla/ls" key={'sla-list'}
              render={routeprops => (
                  <Try><SlaList
                      key="sla-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/sla/form/:id?" exact={true} key={'sla-form'}
              render={routeprops => (
                  <Try><SlaForm
                      key="sla-form" {...routeprops} {...props} /></Try>
              )} />,
      ]
  }

  renderOrigins () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/origin/ls" key={'origin-list'}
              render={routeprops => (
                  <Try><OriginList
                      key="origin-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/origin/form/:id?" exact={true} key={'origin-form'}
              render={routeprops => (
                  <Try><OriginForm
                      key="origin-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/origin/form/:id?/typifiedList" key="originTypifiedValue-ls" exact={true}
              render={routeprops => {
                  return (
                      <Try><OriginTypifiedList
                          key={'originTypifiedValue-ls'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/origin/form/:id?/typifiedForm" key="originTypifiedForm-ls" exact={true}
              render={routeprops => {
                  return (
                      <Try><OriginTypifiedForm
                          key="originTypifiedForm-form" {...routeprops} {...this.props} /></Try>
                  )
              }} />,
      ]
  }

  renderSchedules () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/schedule/visit/type/ls" key={'schedule-visit-list'}
              render={routeprops => (
                  <Try><ScheduleVisitList
                      key="schedule-visit-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/schedule/visit/type/form/:id?" exact={true} key={'schedule-visit-form'}
              render={routeprops => (
                  <Try><ScheduleVisitForm
                      key="schedule-visit-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/schedule/visit/byclient/" key="client-schedulebyclient" exact={true}
              render={routeprops => (
                  <Try><ScheduleByClient
                      key="client-schedulebyclient" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/schedule/visit/byclient/orderBy" key="client-schedule-orderBy" exact={true}
              render={routeprops => (
                  <Try><ScheduleByClientOrderView
                      key="client-schedule-orderBy" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/schedule/bytechnical" key="schedule-detail" exact={true}
              render={routeprops => {
                  const params = new URLSearchParams(routeprops.location.search)
                  return (<Try><ScheduleByTechnical
                      key={'schedule-detail' + params.get('withoutTech')}    {...routeprops} {...props} /></Try>)
              }} />,
          <Route path="/schedule/bytechnical/orderBy" key="client-schedule-byTech-orderBy" exact={true}
              render={routeprops => (
                  <Try><ScheduleByTechnicalOrderView
                      key="client-schedule-byTech-orderBy" {...routeprops} {...props} /></Try>
              )} />,
      ]
  }

  renderArticles () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/article/ls" key={'article-list'}
              render={routeprops => (
                  <Try><ArticleList
                      key="article-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/article/form/:id?" exact={true} key={'article-form'}
              render={routeprops => (
                  <Try><ArticleForm
                      key="article-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/article/form/:id?/history" key="article-history" exact={true}
              render={routeprops => (
                  <Try><ArticleHistory
                      key="article-history" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/article/form/:id?/workOrderCartList" key="article-workOrderCartList" exact={true}
              render={routeprops => (
                  <Try><ArticleWorkOrderList
                      key="article-workOrderCartList" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/article/form/:id?/location" exact={true} key={'article-form'}
              render={routeprops => (
                  <Try><ArticleFormLocationTable
                      key="article-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/workOrderCartArticle/ls" key={'workOrderCartArticle-list'}
              render={routeprops => (
                  <Try><WorkOrderArticleList
                      key="workOrderCartArticle-list" {...routeprops} {...props} /></Try>
              )} />
      ]
  }

  renderStocks () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/stock/ls" key={'stock-list'}
              render={routeprops => (
                  <Try><StockList
                      key="stock-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/stock/form/:id?" exact={true} key={'stock-form'}
              render={routeprops => (
                  <Try><StockForm
                      key="stock-form" {...routeprops} {...props} /></Try>
              )} />
      ]
  }

  renderStockMovements () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/movementStock/ls" key={'movementStock-list'}
              render={routeprops => (
                  <Try><StockMovementList
                      key="movementStock-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/movementStock/form/:id?" exact={true} key={'movementStock-form'}
              render={routeprops => (
                  <Try><StockMovementForm
                      key="movementStock-form" {...routeprops} {...props} /></Try>
              )} />
      ]
  }

  renderBins () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/bin/ls" key={'bin-list'}
              render={routeprops => (
                  <Try><BinList
                      key="bin-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/bin/form/:id?" exact={true} key={'bin-form'}
              render={routeprops => (
                  <Try><BinForm
                      key="bin-form" {...routeprops} {...props} /></Try>
              )} />,

      ]
  }

  renderSlots () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/slot/ls" key={'slot-list'} exact={true}
              render={routeprops => (
                  <Try><SlotList
                      key="slot-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/slot/form/:id?" exact={true} key={'slot-form'}
              render={routeprops => (
                  <Try><SlotForm
                      key="slot-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/slot/form/:id?/articles" key={'slot-articles'} exact={true}
              render={routeprops => (
                  <Try><SlotFormArticles
                      key="slot-articles" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/slot/form/:id?/history" key="slot-history" exact={true}
              render={routeprops => (
                  <Try><SlotHistory
                      key="slot-history" {...routeprops} {...props} /></Try>
              )} />,
      ]
  }

  renderWarehouses () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/warehouse/ls" key={'warehouse-list'}
              render={routeprops => (
                  <Try><WarehouseList
                      key="warehouse-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/warehouse/form/:id?" exact={true} key={'warehouse-form'}
              render={routeprops => (
                  <Try><WarehouseForm
                      key="warehouse-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/warehouse/form/:id?/binList" key="warehouse-warehouseBinList" exact={true}
              render={routeprops => (
                  <Try><WarehouseBinList
                      key="warehouse-warehouseBinList" {...routeprops} {...props} /></Try>
              )} />,
      ]
  }

  renderSubcontracts () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/subcontract/ls" key={'subcontract-list'}
              render={routeprops => (
                  <Try><SubcontractList
                      key="subcontract-list" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/subcontract/form/:id?" exact={true} key={'subcontract-form'}
              render={routeprops => (
                  <Try><SubcontractForm
                      key="subcontract-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/subcontract/form/:id?/history" key="order-historytech" exact={true}
              render={routeprops => (
                  <Try><SubcontractHistory
                      key="order-history" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/subcontract/form/:id/contacts" key={'subcontract-contacs'}
              render={routeprops => (
                  <Try><SubcontractContacts
                      key="subcontract-contacs" {...routeprops} {...props} /></Try>
              )} />
      ]
  }

  renderTypified () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/typifiedValue/ls" key="typifiedValue-ls" exact={true}
              render={routeprops => {
                  const params = new URLSearchParams(routeprops.location.search)
                  return (
                      <Try><TypifiedValueList
                          key={'typifiedValue-ls' + params.get('type')} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/typifiedValue/form/:id?" key="typifiedValue-form" exact={true}
              render={routeprops => (
                  <Try><TypifiedValueForm
                      key="typifiedValue-form" {...routeprops} {...this.props} /></Try>
              )} />,

      ]
  }

  renderReports () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/reports/resume" key="reports-resume" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportResume
                          key={'reports-resume'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/technical" key="reports-technical" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportTechnical
                          key={'reports-technical'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/technical/detail/:technicalId" key="reports-technical-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportTechnicalDetail
                          key={'reports-technical-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/type" key="reports-type" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportType
                          key={'reports-type'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/type/detail/:typeId" key="reports-type-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportTypeDetail
                          key={'reports-type-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/task" key="reports-task" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportTask
                          key={'reports-task'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/task/detail/:taskId" key="reports-task-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportTaskDetail
                          key={'reports-task-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/diagnosis" key="reports-diagnosis" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportDiagnosis
                          key={'reports-diagnosis'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/diagnosis/detail/:diagnosisId" key="reports-diagnosis-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportDiagnosisDetail
                          key={'reports-diagnosis-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/location" key="reports-location" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportLocation
                          key={'reports-location'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/location/detail" key="reports-location-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportLocationDetail
                          key={'reports-location-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/matrixTechnical" key="reports-matrix-technical" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportMatrixTechnical
                          key={'reports-matrix-technical'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/matrixModel" key="reports-matrix-model" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportMatrixModel
                          key={'reports-matrix-model'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/matrixModel/detail" key="reports-matrix-model-detail" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportMatrixModelDetail
                          key={'reports-matrix-model-detail'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/matrixModel/date" key="reports-matrix-model-date" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportMatrixModelDate
                          key={'reports-matrix-model-date'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,
          <Route path="/reports/matrixModel/day" key="reports-matrix-model-day" exact={true}
              render={routeprops => {
                  return (
                      <Try><ReportMatrixModelDay
                          key={'reports-matrix-model-day'} {...routeprops} {...this.props} /></Try>
                  )
              }} />,

      ]
  }

  renderFinal () {
      let props = this.props
      let Try = ErrorBoundary
      return [
          <Route path="/dashboardAsset" key={'dashboardAsset'}
              render={routeprops => (
                  <Try><DashboardAsset
                      key="dashboardAsset" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/dashboardSlots" key={'dashboardSlots'}
              render={routeprops => (
                  <Try><DashboardSlots
                      key="dashboardSlots" {...routeprops} {...props} /></Try>
              )} />,

          <Route path="/profile/form/:id?" key="profile-form" exact={true}
              render={routeprops => (
                  <Try><ProfileForm
                      key="profile-form" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/change/password" key="change-password" exact={true}
              render={routeprops => (
                  <Try><ChangePassword
                      key="change-password" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/aplication/" key="aplication" exact
              render={routeprops => (
                  <Try><ApplicationPage
                      key="aplication" {...routeprops} {...props} /></Try>
              )} />,

          <Route path="/pruebas" key="pruebas" exact
              render={routeprops => (
                  <Try><PruebasForm
                      key="pruebas" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="/" key={'home'}
              render={routeprops => (
                  <Try><Home
                      key="home" {...routeprops} {...props} /></Try>
              )} />,
          <Route path="*" key={'page404'}
              render={routeprops => (
                  <Page404
                      key="page404" {...routeprops} {...props} />
              )} />
      ]
  }

  log (msg) {
      AppLogger.get().debug(msg, this)
  }

}

export default AppRouter

