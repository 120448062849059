import { observable } from 'mobx'
import BaseModelState from './BaseModelState'
import util from '../util/Util'
import appState from '../state/AppState'
import MultiModel from '../models/MultiModel'
import ReportModel from '../models/ReportModel'
import SlotModel from '../models/SlotModel'
import WorkOrderModel from '../models/WorkOrderModel'
import BudgetModel from '../models/BudgetModel'
import ChangesWithValidationsModel from '../models/ChangesWithValidationsModel'
import TypifiedValueModel from '../models/TypifiedValueModel'
import UserModel from '../models/UserModel'

let timeoutLoadData;

class AlertState extends BaseModelState {

    @observable totalAlarms = 0;
    @observable salesOrderError = 0;
    @observable techniciansWithoutScheduledToday = 0;
    @observable techniciansWithoutScheduledTodayDict = {};
    @observable validations = 0;
    @observable techsWithoutEndDay = 0;
    @observable slotsWithoutStartDay = 0;
    @observable workOrdersReturned = 0;
    @observable workOrdersReturnedFollowed = 0;
    @observable workOrdersPossibleWarranty = 0;
    @observable budgetsAccepted = 0;
    @observable commentsValidated = 0;
    @observable budgetsPending = 0;
    @observable budgetsExpired = 0;
    @observable budgetsDenied = 0;
    @observable pdtApproval = 0;
    @observable notApproved = 0;
    @observable budgetPending = 0;
    @observable orderBudgetPending = 0;
    @observable orderBudgetNotApproved = 0;

    async getQuerys() {
        let arrayQuerys = [];
        arrayQuerys.push(this.loadSalesOrderFailed());
        arrayQuerys.push(this.loadSlotsWithoutStartDay());
        arrayQuerys.push(this.loadWorkOrdersReturned());
        arrayQuerys.push(this.loadWorkOrdersReturnedFollowed());
        arrayQuerys.push(this.loadWorkOrdersPossibleWarranty());
        arrayQuerys.push(this.loadBudgetsAccepted());
        arrayQuerys.push(this.loadBudgetsPending());
        arrayQuerys.push(this.loadBudgetsDenied());
        arrayQuerys.push(this.loadValidations());
        if (appState.userState.hasUserActionsEndDay) {
            arrayQuerys.push(this.techniciansWithoutEndDayQuery());
        }
        return arrayQuerys;
    }


    async loadData() {
        let multiModel = new MultiModel();
        let arrayQuerys = await this.getQuerys();
        let [salesOrderResult, slotsWithoutStarResult, workOrdersReturned, workOrdersReturnedFollowed, workOrdersPossibleWarranty, budgetsAccepted, budgetsPending, budgetsDenied, validations, techsWithoutEndDay] = await multiModel.find(arrayQuerys);
        let reportQuery = new ReportModel();
        let reportResult = await reportQuery.findByIdNotNull("-");
        this.techniciansWithoutScheduledToday = reportResult.techniciansWithoutScheduledTodayCount;
        this.salesOrderError = salesOrderResult.length;
        this.slotsWithoutStartDay = slotsWithoutStarResult.length;
        this.workOrdersReturned = workOrdersReturned.length;
        this.workOrdersReturnedFollowed = workOrdersReturnedFollowed.length;
        this.workOrdersPossibleWarranty = workOrdersPossibleWarranty.length;
        this.budgetsAccepted = budgetsAccepted.length;
        this.budgetsPending = budgetsPending.length;
        this.budgetsDenied = budgetsDenied.length;
        this.validations = validations.length;
        if (appState.userState.hasUserActionsEndDay) {
            this.techniciansWithoutEndDay(techsWithoutEndDay);
        }
        this.calcNotifications();
        if (timeoutLoadData != null) {
            clearTimeout(timeoutLoadData)
        }
        timeoutLoadData = setTimeout(() => (
            this.loadData()
        ), 5 * (1000 * 60))
    }


    techniciansWithoutEndDayQuery() {
        let usersTechQuery = new UserModel();
        usersTechQuery.filters.push({
            "fieldName": "role",
            "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_USER_ROLE, "TECHNICIAN").id,
            "filterOperator": "INLIST"
        });
        return usersTechQuery;
    }

    techniciansWithoutEndDay(usersTechs) {
        let result = 0;
        for (let user: UserModel of usersTechs) {
            if (util.hasValue(user.lastStartDay) || util.hasValue(user.lastEndDay)) {
                let lastStartDay = util.getMoment(user.lastStartDay)
                if (!util.hasValue(user.lastStartDay)) {
                    lastStartDay = util.getMoment();
                }
                let lastEndDay = util.getMoment(user.lastEndDay)
                if (!util.hasValue(user.lastEndDay) || (lastStartDay.format("YYYYMMDDHHmm") > lastEndDay.format("YYYYMMDDHHmm"))) {
                    user.lastEndDay = "";
                    lastEndDay = util.getMoment();
                    let total = lastEndDay.diff(lastStartDay, 'hours');
                    if (total > 16) {
                        result++;
                    }
                }
            }
        }
        this.techsWithoutEndDay = result;
    }

    loadSlotsWithoutStartDay() {
        let slotsQuery = new SlotModel();
        slotsQuery.filters.push({
                "fieldName": "scheduledTime",
                "fieldValue": util.getMoment(),
                "filterOperator": "LTEQ"
            },
            {
                "fieldName": "scheduledTime",
                "fieldValue": util.getMoment().add(-7, 'day'),
                "filterOperator": "GTEQ"
            },
            {
                "fieldName": "isStarted",
                "fieldValue": 0,
                "filterOperator": "EQ"
            },
        );
        return slotsQuery
    }

    loadWorkOrdersReturned() {
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.filters.push({
                "fieldName": "status",
                "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "RETURN_WORK_ORDER").id,
                "filterOperator": "EQ"
            },
        );
        return workOrderQuery;
    }

    loadWorkOrdersReturnedFollowed() {
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.filters.push({
                "fieldName": "hasReturned",
                "fieldValue": "Y",
                "filterOperator": "EQ"
            },
            {
                "fieldName": "status",
                "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id,
                "filterOperator": "NEQ"
            },
        );
        return workOrderQuery
    }

    loadWorkOrdersPossibleWarranty() {
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.filters.push({
                "fieldName": "warrantyPossible",
                "fieldValue": "Y",
                "filterOperator": "EQ"
            },
            {
                "fieldName": "warrantyRevised",
                "fieldValue": "N",
                "filterOperator": "EQ"
            },
        );
        return workOrderQuery
    }

    loadBudgetsAccepted() {
        let budgetQuery = new BudgetModel();
        budgetQuery.filters.push({
                "fieldName": "status",
                "fieldValue": BudgetModel.CODE_APPROVED,
                "filterOperator": "EQ"
            },
        );
        return budgetQuery
    }

    loadBudgetsPending() {
        let budgetQuery = new BudgetModel();
        budgetQuery.filters.push({
                "fieldName": "status",
                "fieldValue": BudgetModel.CODE_PENDING_ERP,
                "filterOperator": "EQ"
            },
        );
        return budgetQuery
    }


    loadValidations() {
        let validatesQuery = new ChangesWithValidationsModel();
        validatesQuery.approved = ChangesWithValidationsModel.PENDING;
        validatesQuery.orderBy = "createdAt";
        validatesQuery.orderMode = "DESC";

        return validatesQuery
    }

    loadBudgetsDenied() {
        let budgetQuery = new BudgetModel();
        budgetQuery.filters.push({
                "fieldName": "status",
                "fieldValue": BudgetModel.CODE_NOT_APPROVED,
                "filterOperator": "EQ"
            },
        );
        return budgetQuery
    }

    calcNotifications() {
        let result = this.salesOrderError;
        result += this.techniciansWithoutScheduledToday;
        result += this.techsWithoutEndDay;
        result += this.slotsWithoutStartDay;
        result += this.workOrdersReturned;
        result += this.workOrdersReturnedFollowed;
        result += this.budgetsAccepted;
        result += this.budgetsPending;
        result += this.budgetsDenied;
        result += this.validations;
        this.totalAlarms = result;
    }

    /**
     * Aqui cargan los dispatcher
     * @returns {Promise<void>}
     */
    loadSalesOrderFailed() {
        let twoDays = util.getMoment().add(-7, 'day')
        let date = util.getMomentFromDateWithoutTimezone(twoDays.format("YYYY-MM-DDTHH:mm:ss")).toISOString();
        let slotQuery = new SlotModel();
        slotQuery.filters.push({
                "fieldName": "integrationErpStatus",
                "fieldValue": SlotModel.CODE_INTEGRATION_FAIL,
                "filterOperator": "EQ"
            },
            {
                "fieldName": "integrationErpDate",
                "fieldValue": date,
                "filterOperator": "GTEQ"
            });
        return slotQuery
    }


    getUrlValidations() {
        return '/config/validates';
    }

    getUrlSalesOrderError() {
        let twoDays = util.getMoment().add(-7, 'day')
        let date = util.getMomentFromDateWithoutTimezone(twoDays.format("YYYY-MM-DDTHH:mm:ss")).toISOString();
        let url = '/slot/ls?filters=';
        url += `[{"fieldKey":"integrationErpStatus","fieldName":"integrationErpStatus","fieldValue":"${SlotModel.CODE_INTEGRATION_FAIL}","filterOperator":"EQ","fieldLabel":"${SlotModel.CODE_INTEGRATION_FAIL}"},{"fieldKey":"integrationErpDate_From","fieldName":"integrationErpDate","fieldValue":"${date}","filterOperator":"GTEQ","fieldLabel":"${date}"}]`;
        // url += `[{"fieldKey":"integrationErpStatus","fieldName":"integrationErpStatus","fieldValue":"${SlotModel.CODE_INTEGRATION_FAIL}","filterOperator":"EQ","fieldLabel":"${SlotModel.CODE_INTEGRATION_FAIL}"}]`;
        return url;
    }

    getUrlTechniciansWithoutScheduledToday() {
        let url = '/schedule/bytechnical';
        return url;
    }

    getUrlSlotsWithoutStartDay() {
        let today = util.getMoment().toISOString();
        let lastWeek = util.getMoment().add(-7, 'day')
        let lastWeekDate = util.getMomentFromDateWithoutTimezone(lastWeek.format("YYYY-MM-DD")).toISOString();
        let url = '/slot/ls?filters=';
        url += `[{"fieldKey":"scheduledTime","fieldName":"scheduledTime","fieldValue":"${today}","filterOperator":"LTEQ","fieldLabel":"${today}"}, {"fieldKey":"scheduledTime","fieldName":"scheduledTime","fieldValue":"${lastWeekDate}","filterOperator":"GTEQ","fieldLabel":"${lastWeekDate}"}, {"fieldKey":"isStarted","fieldName":"isStarted","fieldValue":${0},"filterOperator":"EQ","fieldLabel":"No"}]`;
        return url;
    }

    getUrlTechsWithoutEndDay() {
        let url = '/userWithoutEndDay/ls/';
        return url;
    }

    getWorkOrdersReturnedFromApp() {
        let url = '/workOrder/ls?filters=';
        let status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "RETURN_WORK_ORDER").id;
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Devuelta"}]`;
        return url;
    }

    getWorkOrdersReturnedFollowed() {
        let url = '/workOrder/ls?filters=';
        let statusId = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, "END_WORK_ORDER").id;
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${statusId}","filterOperator":"NEQ","fieldLabel":"Sin Finalizar"}, {"fieldKey":"hasReturned","fieldName":"hasReturned","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Devuelta"}]`;
        return url;
    }

    getWorkOrdersWithPossibleWarranty() {
        let url = '/workOrder/ls?filters=';
        url += `[{"fieldKey":"warrantyPossible","fieldName":"warrantyPossible","fieldValue":"Y","filterOperator":"EQ","fieldLabel":"Sí"}, {"fieldKey":"warrantyRevised","fieldName":"warrantyRevised","fieldValue":"N","filterOperator":"EQ","fieldLabel":"No"}]`;
        return url;
    }

    getBudgetsAccepted() {
        let url = '/budget/ls?filters=';
        let status = BudgetModel.CODE_APPROVED;
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Aceptado"}]`;
        return url;
    }

    getBudgetsPending() {
        let url = '/budget/ls?filters=';
        let status = BudgetModel.CODE_PENDING_ERP;
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Pendiente"}]`;
        return url;
    }

    getBudgetsDenied() {
        let url = '/budget/ls?filters=';
        let status = BudgetModel.CODE_NOT_APPROVED;
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Denegado"}]`;
        return url;
    }

}

export default AlertState;
