import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import translate from '../translator/translate'
import { Animated } from 'react-animated-css'
import AppLogger from '../util/AppLogger'
import VsfButtonNew from '../components/VsfButtonNew'
import appState from '../state/AppState'
import Overlay from '../components/modals/Overlay'
import VsfLink from '../components/VsfLink'
import TypifiedValueModel from '../models/TypifiedValueModel'
import util from '../util/Util'
import SlotModel from '../models/SlotModel'
import YesOrNoEnum from '../models/enum/YesOrNoEnum'
import BaseAlertsButtons from './Base/BaseAlertsButtons'

@observer
class AlertButton extends BaseAlertsButtons {

    constructor (props) {
        super(props)
        this.state = { alert: false }
    }


    getUrlCommentsValidated () {
        let url = '/workOrder/ls?filters='
        url += `[{"fieldKey":"commentsValidated","fieldName":"commentsValidated","fieldValue":"${YesOrNoEnum.NO}","filterOperator":"EQ","fieldLabel":"${YesOrNoEnum.NO}"}]`
        return url
    }

    getUrlSalesOrderError () {
        let twoDays = util.getMoment().add(-7, 'day')
        let date = util.getMomentFromDateWithoutTimezone(twoDays.format('YYYY-MM-DDTHH:mm:ss')).toISOString()
        let url = '/slot/ls?filters='
        url += `[{"fieldKey":"integrationErpStatus","fieldName":"integrationErpStatus","fieldValue":"${SlotModel.CODE_INTEGRATION_FAIL}","filterOperator":"EQ","fieldLabel":"${SlotModel.CODE_INTEGRATION_FAIL}"},{"fieldKey":"integrationErpDate_From","fieldName":"integrationErpDate","fieldValue":"${date}","filterOperator":"GTEQ","fieldLabel":"${date}"}]`
        // url += `[{"fieldKey":"integrationErpStatus","fieldName":"integrationErpStatus","fieldValue":"${SlotModel.CODE_INTEGRATION_FAIL}","filterOperator":"EQ","fieldLabel":"${SlotModel.CODE_INTEGRATION_FAIL}"}]`;
        return url
    }

    getUrlSlotsWithoutStartDay () {
        let today = util.getMoment().toISOString()
        let lastWeek = util.getMoment().add(-7, 'day')
        let lastWeekDate = util.getMomentFromDateWithoutTimezone(lastWeek.format('YYYY-MM-DD')).toISOString()
        let url = '/slot/ls?filters='
        url += `[{"fieldKey":"scheduledTime","fieldName":"scheduledTime","fieldValue":"${today}","filterOperator":"LTEQ","fieldLabel":"${today}"}, {"fieldKey":"scheduledTime","fieldName":"scheduledTime","fieldValue":"${lastWeekDate}","filterOperator":"GTEQ","fieldLabel":"${lastWeekDate}"}, {"fieldKey":"isStarted","fieldName":"isStarted","fieldValue":${0},"filterOperator":"EQ","fieldLabel":"No"}]`
        return url
    }

    getWorkOrdersReturnedFromApp () {
        let url = '/workOrder/ls?filters='
        let status = appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_WORKORDER_STATUS, 'RETURN_WORK_ORDER').id
        url += `[{"fieldKey":"status","fieldName":"status","fieldValue":"${status}","filterOperator":"EQ","fieldLabel":"Devuelta"}]`
        return url
    }

    render () {
        const t = translate
        let totalAlarms = appState.alertState.commentsValidated
        totalAlarms += appState.alertState.salesOrderError
        totalAlarms += appState.alertState.slotsWithoutStartDay
        totalAlarms += appState.alertState.workOrdersReturned
        return (
            <div className="header-alerts dropdown "
                style={{
                    marginRight: '0',
                    marginLeft: '10px',
                    padding: '12px 7px 12px 7px'
                }}>

                <VsfButtonNew type="button" className="alerts-dropdown btn btn--alert red--alert text-danger"
                    icon={'fa fa-bell'}
                    onClick={() => this.setState({ alert: true })}>
                    <i className="badge badge-danger" style={{ padding: '2px 4px' }}>{totalAlarms}</i>
                </VsfButtonNew>

                {this.state.alert &&
                <Animated style={{
                    zIndex: 100,
                    position: 'absolute',
                    top: 10,
                    left: 0,
                    right: 0
                }}
                animationInDelay={150} animationIn="zoomIn" animationInDuration={250}
                isVisible={true}>
                    <Overlay show={this.state.alert} style={{ backgroundColor: 'transparent' }}
                        onClick={_ => this.setState({ alert: false })} />
                    <dl className="dropdown-sublist" style={{
                        top: 10,
                        right: 22,
                        width: 420
                    }}>
                        {totalAlarms === 0 ?
                            <dt className="dropdown-sublist__head">
                                {t('No tienes notificaciones pendientes')}
                            </dt>
                            :
                            <>
                                <dt className="dropdown-sublist__head">
                                    {totalAlarms === 1 ?
                                        t('Tienes #total# notificacion').replace('#total#', totalAlarms)
                                        : t('Tienes #total# notificaciones').replace('#total#', totalAlarms)}
                                </dt>
                                {appState.alertState.commentsValidated !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlCommentsValidated()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.commentsValidated}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Comentarios sin validar')}</div>
                                    </VsfLink>
                                </dd>
                                }

                                {appState.alertState.salesOrderError !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlSalesOrderError()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.salesOrderError}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Sales order erroneas')}</div>
                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.slotsWithoutStartDay !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>
                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getUrlSlotsWithoutStartDay()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.slotsWithoutStartDay}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Visitas pendientes de iniciar')}</div>

                                    </VsfLink>
                                </dd>
                                }
                                {appState.alertState.workOrdersReturned !== 0 &&
                                <dd className="dropdown-sublist__item" style={{ marginTop: 10 }}>

                                    <VsfLink onClick={_ => this.setState({ alert: false })}
                                        to={this.getWorkOrdersReturnedFromApp()}>
                                        <i className="badge badge-danger"
                                            style={{
                                                left: 25,
                                                right: 0,
                                                width: '20px'
                                            }}>{appState.alertState.workOrdersReturned}</i>
                                        <div style={{ marginLeft: 25 }}>{t('Órdenes de Trabajo Devueltas')}</div>
                                    </VsfLink>
                                </dd>
                                }
                            </>
                        }
                    </dl>
                </Animated>

                }
            </div>
        )
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default withRouter(AlertButton)
