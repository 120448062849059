import BaseValidation from "./BaseValidation";
import util from "../../../util/Util";

export default class NumberMinValueValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;
        if (this.event === "change") {
            if (util.hasValue(inputValue)) {
                if (isNaN(parseFloat(inputValue))) {
                    isCorrect = false;
                }
            }
        }
        return isCorrect;
    }

}
