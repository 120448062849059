import WorkOrderModel from "./WorkOrderModel";
import DocumentModel from "./DocumentModel";
import ClientModel from "./ClientModel";
import util from "../util/Util";
import ContactModel from "./ContactModel";
import SlotModel from "./SlotModel";
import WorkOrderCartArticleModel from "./WorkOrderCartArticleModel";
import BudgetModel from "./BudgetModel";
import TypifiedValueModel from "./TypifiedValueModel";
import OriginModel from "./OriginModel";
import SlaModel from "./SlaModel";
import appState from "../state/AppState";
import SubcontractModel from "./SubcontractModel";
import SaveOptionsModel from "./SaveOptionsModel";


export default class OrderModel extends SaveOptionsModel {
    static INTEG_PENDING = "INTEG_PENDING";//Bola azul claro. Indica que se ha pulsado el botón de “mandar a cocacola”
    static INTEG_SENT = "INTEG_SENT";//Bola “azul” oscuro. Indica que se ha mandado a CocaCola y estamos a la espera.
    static INTEG_OK = "INTEG_OK";//Bola “verde”. Indica que CocaCola ha integrado correctamente.
    static INTEG_ERROR = "INTEG_ERROR";//Bola “roja”. Indica que ha habido un error al integrar con CocaCola


    clientId;
    clientName;
    contactId;
    requestContactId;
    code;
    comments;
    createdAt;
    createdBy;
    integrationStatus;
    generalDescription;
    id;
    status;
    updatedAt;
    updatedBy;
    posZoneId;
    weekNumber;
    orderPreventiveId;
    hourPreventive;
    dynamicField;
    dayNumber;
    externalCode;
    propietaryId;
    scheduleVisitId;
    origin;
    scheduledDate;
    scheduledLabel;
    subcontractId;
    from;
    originId;
    slaId;

    nameMainType = "order";
    graphFindByIdOperation = "order";
    graphFindOperation = "ordersConnection";
    graphExportOperation = "ordersExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "clientId": { label: "Establecimiento", relationName: "client", relationValue: "name" },
            "contactId": { label: "Contacto", relationName: "contact", relationValue: "firstName" },
            "requestContactId": { label: "Solicitante", relationName: "request", relationValue: "firstName" },
            "weekNumber": "",
            "hourPreventive": "",
            "integrationStatus": "",
            "from": "",
            "origin": "",
            "propietaryId": { label: "Cliente Fiscal", relationName: "clientPropietary", relationValue: "companyName" },
            "posZoneId": { readonly: true, label: "Zona" },
            "orderPreventiveId": "",
            "dayNumber": "",
            "dynamicField": { label: "Campos Dinamicos" },
            "scheduledLabel":{ label: "Horas de preventivas" },
            "externalCode": { label: "Codigo Externo" },
            "scheduleVisitId": "",
            "scheduledDate": "",
            "code": { label: 'Número Pedido' },
            "comments": { label: "Comentarios" },
            "createdAt": { readonly: true, label: "Creado" },
            "createdBy": { readonly: true, label: "Creado por" },
            "status": { label: "Estado" },
            "updatedAt": { readonly: true, label: "Actualizado en" },
            "updatedBy": { readonly: true, label: "Actualizado por" },
            "subcontractId": "",
            "originId":  { label: "Origen", relationName: "originModel", relationValue: "name" },
            "slaId": "",
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "clientPropietary", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "slot", "Relation", SlotModel);
        this.addRelatedTableGeneric(result, "workOrder", "Relation", WorkOrderModel);
        this.addRelatedTableGeneric(result, "workOrderCartArticle", "Relation", WorkOrderCartArticleModel);
        this.addRelatedTableGeneric(result, "budget", "Relation", BudgetModel);
        this.addRelatedTableGeneric(result, "request", "RelationOne", ContactModel);
        this.addRelatedTableGeneric(result, "contact", "RelationOne", ContactModel);
        this.addRelatedTableGeneric(result, "statusOrder", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "originOrder", "RelationOne", TypifiedValueModel);
        this.addRelatedTableGeneric(result, "originModel", "RelationOne", OriginModel);
        this.addRelatedTableGeneric(result, "subcontract", "RelationOne", SubcontractModel);
        this.addRelatedTableGeneric(result, "slaModel", "RelationOne", SlaModel);
        this.addRelatedTableGeneric(result, "zoneClient", "RelationOne", TypifiedValueModel);
        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["code", "scheduledDate", ...baseFields];
    }

    async getWorkOrdersFromOrderId(orderId) {
        let workOrderQuery = new WorkOrderModel();
        workOrderQuery.orderId = orderId;
        if (util.hasValue(workOrderQuery.orderId)) {
            let result = await workOrderQuery.findPlainObject();
            if (result == null) {
                result = [];
            }
            return result;
        }
    }

    /**
     * Funcion que devuelve los maestros del originId del pedido, si este los tuviera
     * @param masterType
     * @returns {Array}
     */
    getValuesForSelect(masterType) {
        let typifiedValues = appState.typifiedState.getLabelValuesForSelect(masterType);
        let result = [];
        if (util.hasValue(this.originId) && util.hasValue(this.originModel?.customizedTypified) && util.hasValue(this.originModel?.id)) {
            if (this.originModel?.customizedTypified?.includes(masterType)) {
                for (let typifiedValue: TypifiedValueModel of typifiedValues) {
                    if (this.originModel?.id === typifiedValue.originId) {
                        result.push(typifiedValue)
                    }
                }
            } else {
                result = typifiedValues.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
            }
        } else {
            result = typifiedValues.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
        }
        return result;
    }

    getValuesForSelectExclude(masterType, codeExclude) {
        let typifiedValues = appState.typifiedState.getLabelValuesForSelectExcludeCode(masterType, codeExclude);
        let result = [];
        if (util.hasValue(this.originId) && util.hasValue(this.originModel?.customizedTypified) && util.hasValue(this.originModel?.id)) {
            if (this.originModel?.customizedTypified?.includes(masterType)) {
                for (let typifiedValue: TypifiedValueModel of typifiedValues) {
                    if (this.originModel?.id === typifiedValue.originId) {
                        result.push(typifiedValue)
                    }
                }
            } else {
                result = typifiedValues.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
            }
        } else {
            result = typifiedValues.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
        }
        return result;
    }

    getArrWithType(masterType) {
        let arrayTypes = appState.typifiedState.getArrWithType(masterType);
        let result = [];
        if (util.hasValue(this.originModel?.customizedTypified) && util.hasValue(this.originModel?.id)) {
            if (this.originModel?.customizedTypified?.includes(masterType)) {
                for (let typifiedValue: TypifiedValueModel of arrayTypes) {
                    if (this.originModel?.id === typifiedValue.originId) {
                        result.push(typifiedValue)
                    }
                }
            } else {
                result = arrayTypes.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
            }
        } else {
            result = arrayTypes.filter(typified => (typified.originId === util.getZeroIdIdentifierGUID() || typified.originId == null));
        }
        return result;
    }

}

