import React from 'react'
import appState from '../state/AppState'
import { observer } from 'mobx-react'
import TypifiedValueModel from '../models/TypifiedValueModel'
import util from '../util/Util'
import { observable } from 'mobx'
import BaseWorkLogs from './BaseWorkLogs'
import OrderModel from '../models/OrderModel'
import CheckboxUniqueComponent from './fields/CheckboxUniqueComponent'
import TextAreaComponent from './fields/TextAreaComponent'
import translate from '../translator/translate'

@observer
class SelectTasks extends BaseWorkLogs {

  constructor (props) {
    super(props)
    this.state = {
      copiaDatos: [],
      tasksSelected: [],
      objOfActions: [],
      text: '',
      item: {},
    }
  }

  @observable taskName = ''

  async componentDidMount () {
    super.componentDidMount()
    let slot = this.props.slot
    let orderModel = new OrderModel()
    orderModel.addRelatedTable('originModel')
    orderModel.hidrate(slot.order)
    let data = orderModel.getArrWithType([TypifiedValueModel.CODE_APP_TASK])
    if (this.props.objDict?.['0'] != null) {
      this.setState({
        text: this.props.objDict['0'].comment
      })
    }
    let objOfActionsDict = this.ordenarByParentId(data)
    let objOfActions = this.getArrayObj(objOfActionsDict)
    let copiaDatos = this.getArrayObj(objOfActionsDict)
    this.setState({
      objOfActions,
      copiaDatos
    })
    this.log({
      componentDidMount: 1,
      obj: this.props.objDict,
      objOfActions: this.state.objOfActions
    })

  }

  ordenarByParentId (array) {
    let result = {}
    for (let task of array) {
      let key = task.id || task.tagCode
      if (util.hasValue(task.parentId) && task.parentId !== util.getZeroIdIdentifierGUID()) {
        key = task.parentId
      }
      if (result[key] == null) {
        result[key] = []
      }
      let item = {
        code: task.code || '',
        value: key === task.tagCode ? task.tagCode + ' ' + task.text
          : (task.text || appState.typifiedState.getValue(task.id)),
        parentId: key,
        id: task.tagCode || task.id
      }
      result[key].push(item)
    }
    this.log({
      ordenarByParentId: 1,
      result
    })
    return result
  }

  getArrayObj (obj) {
    let copia = {}
    let result = []
    for (let key of Object.keys(obj)) {
      for (let item of obj[key]) {
        // this.log({ getArrayObj: 1, key, objVal: obj[key], item });
        if (copia[item.value] == null) {
          copia[item.value] = ''
          result.push(item)
        }
      }
    }
    return result
  }

  onClickMultiples (item) {
    if (this.props.objDict?.[item.id] != null) {
      delete this.props.objDict[item.id]
    } else {
      if (this.props.objDict?.[item.id] == null) {
        let time = util.getDateIsoNow()
        this.props.objDict[item.id] = {}
        this.props.objDict[item.id] = item
        this.props.objDict[item.id].time = time
        this.props.objDict[item.id].createdBy = appState.loginState.getUserId()
      }
    }
  }

  onClickOneResult (item) {
    this.props.objDict = {}
    this.props.objDict[item.id] = item
    this.taskName = item.value
  }

  onClick (item) {
    if (this.checkOrderCocaCola()) {
      this.onClickMultiples(item)
    } else {
      // this.onClickOneResult(item)
      this.onClickMultiples(item)
    }
    if (this.checkOrderCocaCola()) {
      this.setState({ modalVisible: true })
    }
    this.props.onChangeObjDict(this.props.objDict)
  }

  getComentarios () {
    const t = translate
    return (
      <>
        <p>{t('Comentarios de la tarea')}</p>
        <TextAreaComponent
          onChange={(e) => this.onChangeComment(e)}
          value={this.state.text}
          name={'text'}
          placeholder={t('Comentarios del técnico')}
          classGroup={''}
          type={'text'}
        />
      </>
    )
  }

  render () {
    const t = translate
    return (
      <div className={'col-12'}>
        {this.getComentarios()}
        <p>{t('Selecciona las tareas')}</p>
        <div className="row">
          {this.state.objOfActions?.map((item, index) => (
            <div className={'col-4'} key={index}>
              <div className={'checkbox2'}>
                <CheckboxUniqueComponent
                  checked={util.hasValue(this.props.objDict?.[item.id]?.id)}
                  onChange={(e) => this.onClick(item)}
                  name={item.id}
                  value={this.props.objDict?.[item.id]}
                  className={''}
                />
                <label htmlFor={item.id}>
                  {item.code} - {item.value}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

}

export default SelectTasks
