export default class RelatedTableConfig {


    onlyQueryFields;
    args;

    constructor(onlyQueryFields, args) {
        this.onlyQueryFields = onlyQueryFields || [];
        this.args = args || "";
    }


};





















