import AbstractModel from "./AbstractModel";

export default class Access extends AbstractModel {

    deletable;
    readable;
    creatable;
    model;
    editable;

    nameMainType = "access";
    graphFindByIdOperation = "access";
    graphFindOperation = "accesssConnection";

    getArrayFields() {
        let result = {
            "deletable": "",
            "readable": "",
            "creatable": "",
            "model": "",
            "editable": "",
        };
        return result;
    }
}
