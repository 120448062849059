import React, { Component } from 'react';
import { DropTarget } from 'react-dnd'
import appState from "../../../state/AppState";
import { observer } from 'mobx-react';
import OrderModel from "../../../models/OrderModel";
import AppLogger from "../../../util/AppLogger";
import GraphException from "../../../network/GraphException";

const Types = {
    ITEM: 'workInMonthUnit'
}

class EmptyDropHandler {
    async drop(props, monitor, component) {
        console.log("EmptyDrop.drop");
        if (monitor.didDrop()) {
            return;
        }
        // Obtain the dragged item
        const propsFromVisitDragged = monitor.getItem();

        if (propsFromVisitDragged.order != null) {

            let orderObservable = propsFromVisitDragged.order;
            let order = new OrderModel();
            order.hidrate(orderObservable);
            //Elimino el order
            let slotsArray = orderObservable.slot || [];
            let conRelaciones = false;
            for (let slot of slotsArray) {
                if (slot.scheduledTime != null) {
                    conRelaciones = true;
                }
            }

            if (conRelaciones) {
                props.onDeleteWithSlot(orderObservable);
            } else {
                try {
                    await order.remove();
                    appState.schedulePreventDropState.scheduledOrders = appState.schedulePreventDropState.scheduledOrders.filter(orderIterate => orderIterate.id != order.id);
                    props.getTotals();
                    props.loadClients();
                    props.getSlots();
                } catch (e) {
                    let gqlErrors = new GraphException().getErrorsFromException(e);
                    props.setErrors(gqlErrors)
                }
            }
        }
    }
}


function log(msg) {
    AppLogger.get().debug(msg, this);
}

let emptyDropHandler = new EmptyDropHandler();

const itemTarget = {
    drop(props, monitor, component) {
        emptyDropHandler.drop(props, monitor, component);
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
    }
}

@observer
class EmptyDrop extends Component {


    render() {
        const { connectDropTarget } = this.props;
        return connectDropTarget(
            <div className="empty-drop">
                {this.props.children}
            </div>
        )
    }
}

export default DropTarget(Types.ITEM, itemTarget, collect)(EmptyDrop)
