import React from 'react';
import {observer} from 'mobx-react';
import SlotModel from "../../models/SlotModel";
import BaseEditableRow from "../base/BaseEditableRow";
import {computed, observable} from "mobx";
import appState from "../../state/AppState";
import util from "../../util/Util";
import InfoBlock from "../../components/InfoBlock";
import AppLogger from "../../util/AppLogger";
import PropsUtil from "../../util/PropsUtil";
import OFFER from "../../config/OFFER";
import FetchProxy from "../../network/FetchProxy";
import GraphException from "../../network/GraphException";
import config from "../../config/config";
import AlertModal from "../../subpages/AlertModal";
import PopUp from "../../components/modals/PopUp";
import VsfButtonNew from "../../components/VsfButtonNew";
import Overlay from "../../components/modals/Overlay";
import ReactTooltip from "react-tooltip";
import WorkLogModel from "../../models/WorkLogModel";
import translate from "../../translator/translate";

@observer
class OrderSummarySlot extends BaseEditableRow {

    @observable textoEnviar = "Enviar";
    @observable textPopUp = "¿Seguro que desea enviar a Netsuite las Sales Order?";
    @observable textData = "";
    @observable salesOrderSent = false;

    static defaultProps = Object.assign(BaseEditableRow.defaultProps, {
        workOrders: [],
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    });
    @observable objWithWorkOrders = {};

    constructor(props) {
        super(props);
        this.state = {
            imageFirmUrl: "",
            loading: false,
            mailSalesUser: {},
        }
    }

    async componentDidMount() {
        await this.loadEndVisit()
    }

    async loadEndVisit() {
        let workLogQuery = new WorkLogModel();
        workLogQuery.slotId = this.props.row?.id;
        workLogQuery.action = WorkLogModel.END_VISIT;
        let workLog = await workLogQuery.findFirst();
        let withFirm = !util.hasValue(JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.dontSign);
        this.setState({
            satisfaction: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.satisfaction,
            contact: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.contact,
            mailSalesUser: JSON.parse(workLog.data)[workLogQuery.nameForData(WorkLogModel.END_VISIT)]?.mailSalesUser,
            withFirm
        });
        this.log({loadEndVisit: 1, withFirm, contact: this.state.contact, mailSalesUser: this.state.mailSalesUser})
    }

    @computed
    get mobxListado() {
        return appState.slotState.slots;
    }

    setMobxListado(newValue) {
        appState.slotState.slots = newValue;
    }

    getModelTable() {
        return new SlotModel();
    }

    downloadPDF(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadPDF(slot.id);
    }

    downloadExcelRelatorio(slot) {
        let slotModel = this.getModelTable();
        return slotModel.downloadExcelRelatorio(slot.id);
    }

    downloadPDFSOUnites(slot) {
        let slotModel = new SlotModel();
        return slotModel.downloadPDFSOUnites(slot.id);
    }

    openSlotModal(slotId) {
        this.propsUtil.changeUrlRequest({
            "rightModal": "modalslots",
            "slotId": slotId,
            "rightModalTab": "general"
        });
    }

    openUserModal(userId) {
        this.propsUtil.changeUrlRequest({
            "rightModal": "modaluserls",
            "userId": userId,
            "rightModalTab": "general"
        });
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let slot = this.props.row || {};
        let slotModel = new SlotModel();
        let usuario = appState.userCacheState.getUserById(slot.userId);
        let backgroundRow = "";
        if (appState.layoutState.backgroundRowScheduled === slot.id) {
            backgroundRow = " rowSlotSeleccionada"
        }
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <thead>
                {/*<GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />*/}
                <tr className="thead">
                    <th scope="col">{t('Visita')}</th>
                    <th scope="col">{t('Fecha, hora')}</th>
                    {/*<th scope="col">{t('Inicio')}</th>*/}
                    {/*<th scope="col">{t('Fin')}</th>*/}
                    {/*<th scope="col">{t('Duración')}</th>*/}
                    {!appState.userState.isUserRoleClient() &&
                    <th scope="col">{t('Técnico')}</th>
                    }
                    <th scope="col">{t('Envío SO')}</th>
                    <th rowSpan="2">
                        <div className="links-row">
                            <VsfButtonNew className="btn btn--orange"
                                          onClick={() => {
                                              if (this.props.showMore !== slot.id) {
                                                  this.props.changeShowMore(slot.id)
                                              } else {
                                                  this.props.changeShowMore('')
                                              }
                                          }}>
                                {/*<span className="fa fa-eye pr-1" />*/}
                                {this.props.showMore !== slot.id ? t("Ver mas") : t("Ver menos")}
                            </VsfButtonNew>

                            {OFFER.sales_order && (appState.userState.isUserRoleAdminOrSuperAdmin() || appState.userState.isUserRoleDistpacherOrSuperAdmin()) &&
                            <div className="links-row ">
                                {(slot.integrationErpStatus !== SlotModel.CODE_INTEGRATION_SUCCESS) &&
                                <VsfButtonNew className="btn btn--orange"
                                              onClick={() => {
                                                  this.salesOrderSent = false;
                                                  this.setState({sendIntegration: true})
                                              }}>
                                    {t(this.textoEnviar)}
                                </VsfButtonNew>
                                }
                            </div>
                            }
                            <div className="ml-2" role="group" data-tip data-for="downloadsTooltip">
                                <VsfButtonNew className="btn btn--orange"
                                              onClick={() => appState.layoutState.clickSummaryModal(slot.id)}
                                              icon={"fa fa-download"}
                                              disabled={false}
                                />
                                <ReactTooltip data-for="downloadsTooltip" id="downloadsTooltip"
                                              place="bottom" textColor="#2D3B41"
                                              backgroundColor="#F5F5F5" arrowColor="#F5F5F5"
                                              multiline={false}>
                                    {t("Descargas")}
                                </ReactTooltip>
                            </div>

                        </div>
                        {appState.layoutState.summaryModal === slot.id &&
                        <>
                            <Overlay show={appState.layoutState.summaryModal === slot.id}
                                     onClick={_ => appState.layoutState.summaryModal = ""}/>
                            <div className="dropdown-sublist" style={{top: '12rem', right: 22, zIndex: 100}}>
                                <div className="dropdown-sublist__item ">
                                    <a href={this.downloadPDF(slot)} target={'_blank'}> {t("PDF")}</a>
                                </div>
                                <div className="dropdown-sublist__item ">
                                    <a href={this.downloadExcelRelatorio(slot)}
                                       target={'_blank'}> {t("Relatorio Parte de trabajo")}</a>
                                </div>
                                {slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS && util.hasValue(slot.integrationErpMessage) &&
                                <div className="dropdown-sublist__item ">
                                    <a href={this.downloadPDFSOUnites(slot)}
                                       target={'_blank'}>{t("Descargar Todos los PT")}</a>
                                </div>
                                }
                            </div>
                        </>
                        }

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr className={"tbody " + backgroundRow}>
                    <td>
                        <span className="bold">
                             <span
                                 className="text-click underline capitalize"
                                 onClick={() => this.openSlotModal(slot.id)}> {util.hasValue(slot.code) ? slot.code : " "}</span>
                        </span>
                    </td>
                    <td>
                        <span className="bold">
                            {util.hasValue(slot.scheduledTime) ? util.getMoment(slot.scheduledTime).format("DD/MM/YYYY") : " "}
                            {", "}
                            {util.hasValue(slot.scheduledTime) ? util.getMoment(slot.scheduledTime).format("HH:mm") : " "}
                            {" a "}
                            {util.hasValue(slot.scheduledEndTime) ? util.getMoment(slot.scheduledEndTime).format("HH:mm") : " "}
                        </span>
                    </td>
                    {/*<td>*/}
                    {/*    {util.hasValue(slot.scheduledTime) ? util.getMoment(slot.scheduledTime).format("HH:mm") : " "}*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*    {util.hasValue(slot.scheduledEndTime) ? util.getMoment(slot.scheduledEndTime).format("HH:mm") : " "}*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*   <span className="regular">*/}
                    {/*    {slot.scheduledDuration !== "" ? parseFloat(slot.scheduledDuration).toFixed(2) + t("h") + "." : "1"}*/}
                    {/*    </span>*/}

                    {/*</td>*/}
                    {!appState.userState.isUserRoleClient() &&
                    <td>
                        <div className="">
                                <span
                                    className="text-click underline capitalize"
                                    onClick={() => this.openUserModal(slot.userId)}>{((usuario.firstName ? usuario.firstName : "") + " " +
                                    (usuario.lastName ? usuario.lastName : ""))}</span>
                        </div>
                    </td>
                    }
                    <td>
                            <span datatype data-for={"info"}
                                  onMouseEnter={(e) => {
                                      this.setState({mouseEnter: true});
                                  }}
                                  className={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS ? "text-success" :
                                      slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_FAIL ? "text-danger" : ""}>
                                {util.hasValue(slot.integrationErpStatus) ?
                                    t(slot.integrationErpStatus) + " - " + util.localizeIsoDateMinutes(slot.integrationErpDate)
                                    :
                                    t("Pendiente de Enviar")
                                }
                            </span>
                    </td>
                    <td/>
                </tr>
                </tbody>
                {this.props.showMore &&
                <>
                    {!appState.userState.isUserRoleClient() &&
                    <tr>
                        <td colSpan={10}
                            className={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS ? "text-success" :
                                slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_FAIL ? "text-danger" : ""}>
                            {slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS &&
                            <>{t(slot.integrationErpStatus)}{" - "}{util.localizeIsoDateMinutes(slot.integrationErpDate)} </>}
                            {" - "}{slot.integrationErpMessage}
                            {/*{" - "}{util.left(slot.integrationErpMessage, 100)}*/}
                        </td>
                    </tr>
                    }
                    {!appState.userState.isUserRoleClient() && util.hasValue(this.state.mailSalesUser?.email) &&
                    <tr>
                        <td colSpan={10}>
                                                          <span  style={{
                                                              fontSize:' 0.75rem',
                                                              color: '#6C757D',
                                                              lineHeight: '14px',
                                                              marginBottom: 6,
                                                              fontWeight: '400',
                                                              textTransform: 'capitalize',
                                                          }}>{t("Destinatarios del parte de trabajo")}</span>
                            <span className={" table-comment"}>   <span>{this.state.mailSalesUser?.email}</span>
                                                            </span>
                        </td>
                    </tr>
                    }
                </>
                }
                <AlertModal isOpen={this.state.sendIntegration}>
                    <PopUp
                        title={t('Enviar un mensaje')}
                        icon="exclamationTriangleIcon"
                        classNameText={this.salesOrderSent ? (slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_FAIL ? "text-danger" : "text-success") : " "}
                        text={this.textPopUp}
                        textData={this.textData}
                        hideButton1={this.salesOrderSent}
                        label1={'Cerrar'} loading3={this.state.loading}
                        loadedError3={this.gqlErrors?.length !== 0}
                        label3={'Aceptar'} disabled3={slot.integrationErpStatus === SlotModel.CODE_INTEGRATION_SUCCESS}
                        onClick1={() => {
                            if (this.salesOrderSent) {
                                window.location.reload()
                            } else {
                                this.closeSlotFinished()
                            }
                        }}
                        onClick3={() => this.sendSlotFinished(slot)}
                        fromSO
                    >
                    </PopUp>
                </AlertModal>

            </InfoBlock>
        );
    }

    async sendSlotFinished(slot) {
        this.setState({loading: true});
        let slotId = slot.id;
        const t = translate;
        let httpApi = new FetchProxy();
        httpApi.withAuthorization = true;
        try {
            let url; //config.apiHostInt + "/api/integration/slotFinished";
            url = config.apiRestHostBaseUrl + "/slotFinished";
            let response = await httpApi.fetchUrlPost(url, {"slotId": slotId,});
            let responseJson = await response.json();
            if (responseJson.status !== "OK") {
                let gqlErrors = new GraphException().getErrorsFromException(responseJson);
                this.log({gqlErrors, responseJson});
                this.setState({loadingError: true, loading: false});
                this.gqlErrors = gqlErrors;
                this.textPopUp = t("Ha habido un error con el envío") + "." + responseJson.data?.message;
                slot.integrationErpStatus = responseJson.status;
                slot.integrationErpMessage = responseJson.data?.message
            } else {
                this.textPopUp = t("Gracias, todo ha ido correctamente. Ya se ha envíado al ERP") + "." + responseJson.data?.message;
                slot.integrationErpStatus = SlotModel.CODE_INTEGRATION_SUCCESS;
            }
            this.log({respuestaJson: 1, responseJson});

        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
            this.log(e);
            slot.integrationErpStatus = SlotModel.CODE_INTEGRATION_FAIL;
        }
        this.salesOrderSent = true;
    }

    closeSlotFinished() {
        this.setState({sendIntegration: false});
        this.textPopUp = "¿Seguro que desea enviar a Netsuite las Sales Order?";
        this.gqlErrors = [];
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default OrderSummarySlot
