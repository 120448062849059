import React, { Component } from 'react';
import { observer } from 'mobx-react';
import AppLogger from "../../util/AppLogger";
import OFFER from "../../config/OFFER";
import { observable } from "mobx";
import appState from "../../state/AppState";
import Links from "../../util/Links";
import LocalStorage from "../../network/LocalStorage";
import util from "../../util/Util";
import translate from "../../translator/translate";

@observer
class BaseCommon extends Component {
    @observable creatable = {};
    @observable editable = true;
    @observable deletable = false;
    nameVariableStateFilter = "listFilters-personalizar-en-subclase";
    @observable tabsData;

    constructor(props) {
        super(props);
        this.state = {
            currentFilter: {},
            currentFilterOps: {},
            currentFilterLabels: {},
        };
    }


    @observable gqlErrors = [];

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    async componentDidMountImpl() {
        this.recoverCurrentFilters();
        this._isMounted = true;
        appState.queryLoggers = [];
        if (Object.keys(OFFER.creatable).length === 0) {
            this.log({
                componentDidMountImploadCreatables: 1,
                creatable: OFFER.creatable,
                keys: Object.keys(OFFER.creatable).length
            });
            let offer = new OFFER();
            offer.loadCreatables();
        }
        this.creatable = OFFER.creatable;
    }

    setErrors(errors) {
        this.gqlErrors = errors;
    }

    componentWillUnmount() {
        this._isMounted = false;
        appState.typifiedState.orderOrigin = {};
    }

    getPageSubtitle() {
        const t = translate;
        let tipos = {
            "order": this.graphDataMainType?.client ? this.graphDataMainType?.client.name : t("Nuevo"),
            "workOrder": this.graphDataMainType?.code ? ( t("Pedido") +" "+ this.graphDataMainType?.order?.code) : t("Nueva"),
            "budget": this.graphDataMainType?.code || t("Nuevo"),
            "asset": this.graphDataMainType?.plate ? (this.graphDataMainType?.plate + " / " + (this.graphDataMainType?.model || "") + (util.hasValue(this.graphDataMainType?.serialNumber) ? (" / " + this.graphDataMainType?.serialNumber) : "")) : t("Nuevo"),
            "contract": this.graphDataMainType?.name,
            "article": this.graphDataMainType?.code || t("Nueva"),
            "client": (!this.graphDataMainType?.isPos ? (this.graphDataMainType?.companyName || "") : (this.graphDataMainType?.name || "")) + " " + (this.graphDataMainType?.code || ""),
            "clientPos": (!this.graphDataMainType?.isPos ? (this.graphDataMainType?.companyName || "") : (this.graphDataMainType?.name || "")) + " " + (this.graphDataMainType?.code || ""),
            "scheduleVisit": this.graphDataMainType?.name || t('Nuevo'),
            "user": this.graphDataMainType?.email || t('Nuevo'),
            "scheduledType": this.graphDataMainType?.type,
            "warehouse": util.getString(this.graphDataMainType?.code),
            "vehicle": this.graphDataMainType?.model,
            "assetModel": this.graphDataMainType?.model,
            "origin": this.graphDataMainType?.name,
            "dynamicGroup": this.graphDataMainType?.name || t("Nueva"),
            "slot": this.graphDataMainType?.name || t("Nuevo"),
            "bin": this.graphDataMainType?.name || t("Nuevo"),
            "formAssignment": this.graphDataMainType?.title || t("Nuevo"),
            "form": this.graphDataMainType?.title || t("Nuevo"),
            "configuration": this.graphDataMainType?.title || t("Nuevo"),
            "stockMovement": this.graphDataMainType?.code || t("Nuevo"),
        };

        return tipos[this.nameMainType];
    }

    getPageTitle() {
        const t = translate;
        let tipos = {
            "order": t("Pedido") + " " + util.getString(this.graphDataMainType?.code) + " ",
            "workOrder": t("Orden de trabajo")+" "+this.graphDataMainType?.code ,
            "asset": t("Equipo"),
            "contract": t("Contrato"),
            "article": t("Artículo"),
            "budget": t("Presupuesto"),
            "stock": t("Stock"),
            "client": !this.graphDataMainType?.isPos ? t("Cliente") : t("Establecimiento"),
            "clientPos": !this.graphDataMainType?.isPos ? t("Cliente") : t("Establecimiento"),
            "scheduledType": util.getString(this.graphDataMainType?.type),
            "user": t("Usuario"),
            "scheduleVisit": t("Visitas Preventivas"),
            "vehicle": t("Vehículo"),
            "warehouse": t("Almacén"),
            "dynamicGroup": t("Grupo dinamico"),
            "assetModel": t('Fabricante') + " " + util.getString(this.graphDataMainType?.manufacturer) + " ",
            "sla": t('SLA'),
            "origin": t('Origen'),
            "subcontract": t('Subcontrata') + " " + util.getString(this.graphDataMainType?.name) + " ",
            "slot": t('Slot'),
            "bin": t("BIN"),
            "formAssignment": t('Reglas de formularios'),
            "configuration": t('Configuración'),
            "form": t('Plantillas de formularios'),
            "stockMovement": t('Movimiento'),
        };
        return tipos[this.nameMainType];
    }

    recoverCurrentFilters() {
        let links = new Links(this.props.location);
        let currentFilterFromUrl = links.getCurrentFilterFromUrl();
        let currentFilter = {};
        let currentFilterOps = {};
        let currentFilterLabels = {};
        for (let [i, obj] of Object.entries(currentFilterFromUrl)) {
            currentFilter[obj.key] = obj.value;
            currentFilterOps[obj.key] = obj.op;
            currentFilterLabels[obj.key] = obj.label;
        }
        appState[this.nameVariableStateFilter] = {currentFilter, currentFilterOps, currentFilterLabels};
        this.setState({
            ...this.state,
            currentFilter, currentFilterOps,
            currentFilterLabels, favouriteModalOpen: false
        });
    }

    recoverFiltersForStorage() {
        let localStorage = new LocalStorage();
        let filtersJSON = localStorage.getItemTenantId(this.nameVariableStateFilter);
        let newCurrentFilter = JSON.parse(filtersJSON);
        let filtersArray;
        if (this.nameVariableStateFilter != null && newCurrentFilter != null) {
            appState[this.nameVariableStateFilter].currentFilter = newCurrentFilter.currentFilter;
            appState[this.nameVariableStateFilter].currentFilterLabels = newCurrentFilter.currentFilterLabels;
            appState[this.nameVariableStateFilter].currentFilterOps = newCurrentFilter.currentFilterOps;
            filtersArray = this.getFiltersFromStateForUrlObj(appState[this.nameVariableStateFilter]);
        }
        let links = new Links(this.props.location);
        let newUrl = links;
        if (filtersArray?.length !== 0) {
            newUrl = links.getUrlLinkWithFilters(filtersArray);
        } else {
            newUrl = links.getUrlLinkWithoutFilters();
        }
        this.props.history.push(newUrl);
    }

    getFiltersForStorage() {
        let localStorage = new LocalStorage();
        let filtersJSON = localStorage.getItemTenantId(this.nameVariableStateFilter);
        return JSON.parse(filtersJSON);
    }


    /**
     * Actualiza el valor de un filtro.
     * @param event
     */
    updateInputFilterEvent(event, op) {
        let name = event.target.name;
        let value = event.target.value;
        let label = event.target.label;
        if (op == null) {
            op = "EQ";
        }
        let currentFilter = {...this.state.currentFilter, [name]: value};
        let currentFilterOps = {...this.state.currentFilterOps, [name]: op};
        let currentFilterLabels = {...this.state.currentFilterLabels, [name]: label};
        this.setState({currentFilter, currentFilterOps, currentFilterLabels});
        appState[this.nameVariableStateFilter] = {currentFilter, currentFilterOps, currentFilterLabels};
    }

    /**
     * A partir del parámetro currentFilterState (que puede ser el estado) devuelve un array con los campos que se mostrarán en la URL de los filtros
     * @param currentFilterState
     * @returns {Array}
     */
    getFiltersFromStateForUrlObj(currentFilterState) {
        let filtersArray = [];
        for (let [key, value] of Object.entries(currentFilterState.currentFilter)) {
            let fieldName = util.getDelim(key, "_", 0);
            let op = currentFilterState.currentFilterOps[key];
            let label = currentFilterState.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            if (util.hasValue(value)) {
                filtersArray.push({
                    fieldKey: key,
                    fieldName: fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })

            }
        }
        return filtersArray;
    }

    /**
     * A partir del parámetro currentFilterState (que puede ser el estado) devuelve un array con los campos que se mostrarán en la URL de los filtros
     * @param currentFilterState
     * @returns {Array}
     */
    getFiltersFromStateForUrl(currentFilterState) {
        let filtersArray = [];
        for (let [key, value] of Object.entries(currentFilterState)) {
            let fieldName = util.getDelim(key, "_", 0);
            let op = this.state.currentFilterOps[key];
            let label = this.state.currentFilterLabels[key];
            if (op == null) {
                op = "EQ";
            }
            if (label == null) {
                label = value;
            }
            if (value != null && value != "") {
                filtersArray.push({
                    fieldKey: key,
                    fieldName: fieldName,
                    fieldValue: value,
                    filterOperator: op,
                    fieldLabel: label
                })
            }
        }
        return filtersArray;
    }

    /** Aplica los filtros en la pantalla de filtros **/
    async onClickAplicarFiltros() {
        await this.willAplicarFiltros();
        let objWithFilters = appState[this.nameVariableStateFilter];
        let localStorage = new LocalStorage();
        localStorage.setItemTenantId(this.nameVariableStateFilter, JSON.stringify(objWithFilters));
        let filtersArray = this.getFiltersFromStateForUrlObj(objWithFilters);
        let links = new Links(this.props.location);
        let newUrl = links.getUrlLinkWithFilters(filtersArray);
        await this.props.history.push(newUrl);
        await this.didAplicarFiltros();
    }

    willAplicarFiltros() {
    }

    didAplicarFiltros() {
    }

    async deleteFilter(filter, value, label) {
        let newCurrentFilter;
        let newCurrentLabels;
        let currentFilterState = this.state;
        if (this.nameVariableStateFilter != null) {
            currentFilterState = appState[this.nameVariableStateFilter];
        }
        if (filter.op == "IN" || filter.op == "STRIN" || filter.op == "INLIST") {
            newCurrentFilter = currentFilterState.currentFilter;
            newCurrentLabels = currentFilterState.currentFilterLabels;
            // Eliminar values
            let values = newCurrentFilter[filter.key];
            let newValues = "";
            if (values != null) {
                newValues = values.replace("" + value + ",", "");
            }
            if (values === newValues) {
                newValues = values.replace("," + value + "", "");
            }
            if (values === newValues) {
                newValues = values.replace("" + value + "", "");
            }
            newCurrentFilter[filter.key] = newValues;
            let labels = newCurrentLabels[filter.key];
            if (labels != null) {
                let newLabels = labels.replace("/'" + label + "/',", "");
                if (labels === newLabels) {
                    newLabels = labels.replace(",/'" + label + "/'", "");
                }
                if (labels === newLabels) {
                    newLabels = labels.replace("/'" + label + "/'", "");
                }
                newCurrentLabels[filter.key] = newLabels;
                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter,
                        currentFilterLabels: newCurrentLabels
                    });
                }
            } else {
                if (newValues == "") {
                    delete newCurrentFilter[filter.key];
                } else {
                    this.setState({
                        currentFilter: newCurrentFilter
                    });
                }
            }
        } else {
            newCurrentFilter = currentFilterState.currentFilter;
            delete newCurrentFilter[filter.key];
            newCurrentLabels = currentFilterState.currentFilterLabels;
            delete newCurrentLabels[filter.key];
        }
        this.willDeleteFilters();
        let filtersArray = this.getFiltersFromStateForUrl(newCurrentFilter);
        if (this.nameVariableStateFilter != null) {
            appState[this.nameVariableStateFilter].currentFilter = newCurrentFilter;
            appState[this.nameVariableStateFilter].currentFilterLabels = newCurrentLabels;
            filtersArray = this.getFiltersFromStateForUrlObj(appState[this.nameVariableStateFilter]);
        }
        let links = new Links(this.props.location);
        let newUrl = links;
        if (filtersArray.length !== 0) {
            newUrl = links.getUrlLinkWithFilters(filtersArray);
        } else {
            newUrl = links.getUrlLinkWithoutFilters();
        }
        await this.props.history.push(newUrl);
        this.didDeleteFilters();
    }


    didDeleteFilters() {
    }

    willDeleteFilters() {
    }

}

export default BaseCommon;
