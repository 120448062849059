import React from 'react'
import { observer } from 'mobx-react'
import Links from '../../util/Links'
import { computed } from 'mobx'
import TabsData from '../../layout/TabsData'
import AppLogger from '../../util/AppLogger'
import util from '../../util/Util'
import BaseForm from './BaseForm'
import appState from '../../state/AppState'

@observer
class BaseEditableTable extends BaseForm {

    /**
     * Nombre del campo de la clase principal
     */
    foreingKeyfield
    modalClases

    /**
     * crear el elemento foreingKeyField
     * this.foreingKeyfield = "clientId";
     * Inicialmente no muestro ningun contacto
     *  appState.contactState.contacts = [];
     * @param props
     */
    constructor (props) {
        super(props)
        this.tabsData = new TabsData()
        this.state = {
            hasSomeRowEditing: false, // Muestra este formulario en modo edición en algono de los elementos
            change: '1',
        }
        this.modalClases = util.getModalClasses()
    }

    /**
     * Get del estado para poder usarlo en eliminarTabla
     * Cambiar por el estado que se quiera conseguir
     * return appState.contactState.contacts;
     * @returns {Array}
     */
    @computed get mobxListado () {
        return []
    }

    setMobxListado (newValue) {
    }

    componentWillUnmount () {
        super.componentWillUnmount()
        this.setMobxListado([])
    }

    async reloadData () {
        await super.reloadData()
        await this.reloadTable(this.getFormId())
    }

    onOpenRowNewEmpty () {
        if (appState.typifiedState.getObject(this.graphDataMainType?.status)?.code === 'CLOSED' ||
            appState.typifiedState.getObject(this.graphDataMainType?.order?.status)?.code === 'CLOSED') {
            this.setState({ orderFinished: true })
        } else if (appState.typifiedState.getObject(this.graphDataMainType?.status)?.code === 'CANCELED' ||
            appState.typifiedState.getObject(this.graphDataMainType?.order?.status)?.code === 'CANCELED') {
            this.setState({ orderCanceled: true })
        } else {
            let newModel = this.getModelTable().toPlainObject()
            if (this.state.fromWorkOrder) {
                newModel.orderId = this.graphDataMainType.order.id
            }
            newModel.isEditing = true
            //En planificacion en articulos de orden de trabajo había desaparecido el bloque con botones de editar. Porque no tenía editable=true
            //newModel.editable=true;
            newModel.deletable = true
            this.mobxListado.push(newModel)
            this.setState({
                hasSomeRowEditing: true
            })
        }
    };

    getFormId () {
        let id = super.getFormId()
        if (this.props[this.foreingKeyfield] != null) {
            id = this.props[this.foreingKeyfield]
        }
        return id
    }

    /**
     * Cambiar por el modelo que se quiera conseguir
     * return  new ContactModel();
     */
    getModelTable () {
    }

    async componentDidMountImpl () {
        await super.componentDidMountImpl()
        await this.reloadTable(this.getFormId())
        this.setState({ loading: false })
    }

    async reloadTable (id) {

    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps[this.foreingKeyfield] !== this.props[this.foreingKeyfield]) {
    //         this.reloadTable(nextProps[this.foreingKeyfield]);
    //     }
    // }

    getFormId () {
        let result = this.props[this.foreingKeyfield]
        if (result == null) {
            let links = new Links()
            result = links.getFormId(this.props)
        } else {
            result = result + ''
        }
        return result
    }

    setHasSomeRowEditing (newHasSomeRowEditing) {
        this.setState({
            hasSomeRowEditing: newHasSomeRowEditing
        })
        //Si es false quito el isEditing De todos los contactos
        if (!newHasSomeRowEditing) {
            for (let row of this.mobxListado) {
                row.isEditing = false
            }
        }
    };

    calcularHoras () {
        let result = 0.0
        this.mobxListado.map((slot) => {
            if (slot.scheduledDuration != null) {
                //parseFloat(slot.scheduledDuration).replace(',', '');
                if (result == 0)
                    result = parseFloat(slot.scheduledDuration)
                else if (slot.scheduledDuration != '') {
                    result = parseFloat(slot.scheduledDuration) + parseFloat(result)
                }
            }
        })
        return result
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}

export default BaseEditableTable
