import AbstractModel from "./AbstractModel";
import DocumentModel from "./DocumentModel";
import SubcontractContactModel from "./SubcontractContactModel";

export default class SubcontractModel extends AbstractModel {
    static STATUS_ACTIVO = "ACTIVE";
    static STATUS_BAJA = "BLOCKED";
    static TYPE_TRANSPORTE = "TRANSPORTE";
    static TYPE_LEVANTE = "LEVANTE";
    static TYPE_SERVICIO_TECNICO = "SERVICIO TÉCNICO";

    name;
    vatID;
    type;
    status;
    address;
    city;
    district;
    postalCode;

    nameMainType = "subcontract";
    graphFindByIdOperation = "subcontract";
    graphFindOperation = "subcontractsConnection";
    graphExportOperation = "subcontractsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "name" : { label: "Subcontrata" },
            "vatID" : { type: "CodeField", label: "NIF" },
            "type": { label: "Tipo" },
            "status": { label: "Status" },
            "address": { label: "Dirección" },
            "city": { label: "Localización" },
            "district": { label: "Provincia" },
            "postalCode": { label: "Código Postal" },
        };
        this.addRelatedTableGeneric(result, "documents", "Relation", DocumentModel);
        this.addRelatedTableGeneric(result, "subcontractContact", "Relation", SubcontractContactModel);
        return result;
    }

};
