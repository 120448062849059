import AppLogger from "./AppLogger";

export default class Links {
    paramOrder="order";
    paramOrderMode="orderMode";
    paramPage="page";
    paramPagination="pagination";
    paramAfter="after";
    paramBefore="before";
    paramFilters="filters";

    constructor(location) {
        this.location = location;
    }

    /**
     * A partir de la URL cambia el/los parametros identificado en el el objeto parametersObject
     * orderObject => {order:"XX"}
     * @param parametersObject
     * @returns {string}
     */
    getUrlLink(parametersObject) {
        let resultArr = [];
        parametersObject[this.paramAfter] = null;
        parametersObject[this.paramBefore] = null;
        const pathname = this.location.pathname;
        resultArr.push(pathname + "?");
        const params = new URLSearchParams(this.location.search);
        let i = 0;
        for (let param of params) {
            let name = param[0];
            // let value = param[1];
            let value = encodeURIComponent(param[1]);
            if (parametersObject[name] != null) {
                value = parametersObject[name];
                parametersObject[name] = null;
            } else if (parametersObject[name] === null) {
                value = null;
            }
            if (value != null) {
                if (i !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value !== "") {
                    resultArr.push("=" + value);
                }
                i++;
            }
        }
        for (let key of Object.keys(parametersObject)) {
            let name = key;
            let paramValue = parametersObject[key];
            if (paramValue != null) {
                let value = encodeURIComponent(parametersObject[key]);
                if (i !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value !== "") {
                    resultArr.push("=" + value);
                }
                i++;
            }
        }
        return resultArr.join("");
    }

    /**
     * Devuelve la URL sin filters en la url
     * @returns {string}
     */
    getUrlLinkWithoutFilters() {
        let resultArr = [];
        const pathname = this.location.pathname;
        resultArr.push(pathname + "?");
        const params = new URLSearchParams(this.location.search);
        let i = 0;
        for (let param of params) {
            let name = param[0];
            let value = param[1];
            if (name !== this.paramFilters && value != null) {
                if (i++ !== 0) {
                    resultArr.push("&");
                }
                resultArr.push(name);
                if (value !== "") {
                    resultArr.push("=" + value);
                }
            }
        }
        return resultArr.join("");
    }

    getUrlLinkPageNext(after) {
        const params = new URLSearchParams(this.location.search);
        let page = parseInt(params.get(this.paramPage));
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }
        page++;
        let linkObj={};
        linkObj[this.paramAfter]=after;
        linkObj[this.paramBefore]=null;
        linkObj[this.paramPage]=page;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkPagination(pagination) {
        let linkObj={};
        linkObj[this.paramPagination]=pagination;
        return this.getUrlLink(linkObj);
    }

    /**
     * Devuelve un array de elementos indexados por key
     * @returns ["key": {key: "", name:"", value:"", op:""}]
     */
    getCurrentFilterFromUrl() {
        let result = {};
        let search = {};
        if (this.location !== undefined) {
            search = this.location.search;
        }
        const params = new URLSearchParams(search);
        let filters = params.get(this.paramFilters);
        let filterArray = JSON.parse(filters);
        if (filterArray == null) {
            filterArray = [];
        }
        for (let filterObj of filterArray) {
            result[filterObj.fieldKey] = {
                key: filterObj.fieldKey,
                name: filterObj.fieldName,
                value: filterObj.fieldValue,
                label: filterObj.fieldLabel,
                op: filterObj.filterOperator
            };
        }
        return result;
    }

    getUrlLinkWithFilters(filterArray) {
        let filters = JSON.stringify(filterArray);
        let before = null;
        let after = null;
        let page = null;
        let linkObj={};
        linkObj[this.paramAfter]=after;
        linkObj[this.paramBefore]=before;
        linkObj[this.paramPage]=page;
        linkObj[this.paramFilters]=filters;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkPageNumber(number) {
        let page = parseInt(number);
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }
        let linkObj={};
        linkObj[this.paramAfter]=null;
        linkObj[this.paramBefore]=null;
        linkObj[this.paramPage]=page;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkPagePrev(before) {
        const params = new URLSearchParams(this.location.search);
        let page = parseInt(params.get(this.paramPage));
        if (isNaN(page)) {
            page = 1;
        }
        if (page === 0) {
            page = 1;
        }
        page--;
        let linkObj={};
        linkObj[this.paramAfter]=null;
        linkObj[this.paramBefore]=before;
        linkObj[this.paramPage]=page;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkPageFirst() {
        let linkObj={};
        linkObj[this.paramAfter]=null;
        linkObj[this.paramBefore]=null;
        linkObj[this.paramPage]=null;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkPageLast() {
        let linkObj={};
        linkObj[this.paramAfter]=null;
        linkObj[this.paramBefore]=null;
        linkObj[this.paramPage]=null;
        return this.getUrlLink(linkObj);
    }

    getUrlLinkOrder(newFieldOrder) {
        const params = new URLSearchParams(this.location.search);
        const previousOrder = params.get(this.paramOrder);
        let previousOrderMode = params.get(this.paramOrderMode);
        if (previousOrderMode === null) {
            previousOrderMode = "ASC";
        }
        let newOrderMode = "ASC";
        if (previousOrder === newFieldOrder) {
            if (previousOrderMode === "ASC") {
                newOrderMode = "DESC";
            }
        }
        let linkObj={};
        linkObj[this.paramOrder]=newFieldOrder;
        linkObj[this.paramOrderMode]=newOrderMode;
        linkObj[this.paramAfter]=null;
        linkObj[this.paramBefore]=null;
        linkObj[this.paramPage]=null;
        let result = this.getUrlLink(linkObj);
        return result;
    }


    /**
     * Devuelve el id: String del formulario
     */
    getFormId(props) {
        let id = "";
        try {
            id = props.match.params?.id;
            if (id == null) {
                id = props?.id;
            }
        } catch (e) {
        }
        if (id != null) {
            id = "" + id;
        }
        return id;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}
