import util from "../util/Util";
import AbstractModel from "./AbstractModel";

export default class ContactModel extends AbstractModel {

    static CODE_MAIL_SALES = "MAIL_SALES";
    static  CODE_DEFAULT = "DEFAULT";

    id;
    email;
    firstName;
    clientId;
    phoneNumber;
    mobileNumber;
    position;
    contactRole;

    nameMainType = "contact";
    graphFindByIdOperation = "contact";
    graphFindOperation = "contactsConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "email": "",
            "firstName": { label: "Nombre" },
            "clientId": "",
            "phoneNumber": { label: "Télefono" },
            "mobileNumber": "",
            "contactRole": "",
            "position": "",

        };
        return result;
    }

    getFullName() {
        let result = "";
        if (util.hasValue(this.firstName)) {
            result += this.firstName + " ";
        }
        if (util.hasValue(this.lastName)) {
            result += this.lastName;
        }
        result = util.trim(result);
        return result;
    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return baseFields;
    }
}
