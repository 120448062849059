import React from 'react'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import MonthDrop from './MonthDrop'
import WorkInMonthUnit from './WorkInMonthUnit'
import translate from '../../../translator/translate'
import util from '../../../util/Util'
import appState from '../../../state/AppState'
import GraphException from '../../../network/GraphException'
import GqlErrors from '../../../components/status/GqlErrors'
import TabsComponent from '../../../components/fields/TabsComponent'
import EmptyDrop from './EmptyDrop'
import TabsData from '../../../layout/TabsData'
import PageTitle from '../../../components/PageTitle'
import BaseForm from '../../base/BaseForm'
import { withRouter } from 'react-router-dom'
import PageTitleRightModal from '../../../components/modals/PageTitleRightModal'
import PropsUtil from '../../../util/PropsUtil'
import ClientModel from '../../../models/ClientModel'
import OrderModel from '../../../models/OrderModel'
import InfoBlock from '../../../components/InfoBlock'
import AppLogger from '../../../util/AppLogger'
import VsfButtonNew from '../../../components/VsfButtonNew'
import Select2Component from '../../../components/fields/Select2Component'
import AlertModal from '../../../subpages/AlertModal'
import PopUp from '../../../components/modals/PopUp'
import GroupOfField from '../../../components/layout/GroupOfField'
import moment from 'moment'
import MoreButtons from '../../../components/MoreButtons'
import ClientPreventModalMulti from './ClientPreventModalMulti'
import TypifiedValueModel from '../../../models/TypifiedValueModel'
import ClientActions from '../ClientActions'
import SpinnerClipLoaderInPage from '../../../network/SpinnerClipLoaderInPage'

@observer
class ScheduleByClientPrevent extends BaseForm {
    nameMainType = "client";
    @observable gqlErrors = [];
    @observable slots = [];

    constructor(props) {
        super(props);
        this.graphData.data[this.nameMainType] = {};
        this.state = {
            totalHours: 0,
            totalVisits: 0,
            order: {},
            eliminar: false,
            fromClient: false,
            modalClient: false,
            loadingDelete: false,
            loading: true,
        };
        this.tabsData = new TabsData();
        moment.suppressDeprecationWarnings = true;

    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        appState.schedulePreventDropState.slotSchedule = {};
        this.year = this.getYearFromUrl() || 2019;
        try {
            await appState.schedulePreventDropState.loadScheduledVisits();
            await this.loadTable();
        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);
        }
        this.setState({ loading: false })
    }

    getFormId() {
        let id = super.getFormId();
        if (this.props.clientId != null) {
            id = this.props.clientId;
        }
        return id;
    }

    getModelQuery() {
        return new ClientModel();
    }

    async loadOrdersWithinMonth() {
        let dateFrom = this.propsUtil.getRequest("date") || moment().format("YYYYMM");
        await appState.schedulePreventDropState.loadClientsFromScheduledByClient(dateFrom);
    }

    getMonth(type) {
        let tipos = {
            '01': "Enero",
            '02': "Febrero",
            '03': "Marzo",
            '04': "Abril",
            '05': "Mayo",
            '06': "Junio",
            '07': "Julio",
            '08': "Agosto",
            '09': "Septiembre",
            '10': "Octubre",
            '11': "Noviembre",
            '12': "Diciembre",
        };
        return tipos[type];
    }

    getMonths() {
        let year = this.year;
        return [
            { label: "Ene", year, code: this.year + '01' },
            { label: "Feb", year, code: this.year + '02' },
            { label: "Mar", year, code: this.year + '03' },
            { label: "Abr", year, code: this.year + '04' },
            { label: "May", year, code: this.year + '05' },
            { label: "Jun", year, code: this.year + '06' },
            { label: "Jul", year, code: this.year + '07' },
            { label: "Ago", year, code: this.year + '08' },
            { label: "Sep", year, code: this.year + '09' },
            { label: "Oct", year, code: this.year + '10' },
            { label: "Nov", year, code: this.year + '11' },
            { label: "Dic", year, code: this.year + '12' },
        ];
    }


    async loadTable() {
        await this.loadScheduledOrders();
        this.getSlots();
        this.getTotals();
    }

    async loadClients() {
        if (this.ifPathStartsWith("/schedule/visit/byclient")) {
            await this.loadOrdersWithinMonth();
        }
    }

    async deleteOrder() {
        this.setState({ loadingDelete: true });
        try {
            let orderModel = new OrderModel();
            orderModel.hidrate(this.state.order);
            await orderModel.remove();
            await this.loadTable();
            appState.schedulePreventDropState.scheduledOrders = appState.schedulePreventDropState.scheduledOrders.filter(orderIterate => orderIterate.id != this.state.order.id);

        } catch (e) {
            this.gqlErrors = new GraphException().getErrorsFromException(e);

        }
        this.setState({
            loadingDelete: false,
            eliminar: false
        });

    }

    onDeleteWithSlot(order) {
        this.setState({
            order,
            eliminar: true
        });
    }

    noCancel() {
        this.setState({
            eliminar: false
        });
    }

    /**
     * Carga las planificaciones realizadas con la herramienta (de ese cliente), para ello, la tabla "orders" tendrá los campos:
     * - clientId: para saber la tienda
     * - scheduledDate: Para saber el mes en el que está planificado (inicialmente)
     * */
    async loadScheduledOrders() {
        let orderQuery = new OrderModel();
        orderQuery.addRelatedTable("slot");
        orderQuery.addRelatedTable("slot.order");
        orderQuery.clientId = this.getFormId();
        let dateFrom = this.year + "01";
        let dateTo = this.year + "12";
        orderQuery.filters = [
            { "fieldName": "scheduledDate", "fieldValue": dateFrom, "filterOperator": "GTEQ" },
            { "fieldName": "scheduledDate", "fieldValue": dateTo, "filterOperator": "LTEQ" },
            {
                "fieldName": "status",
                "fieldValue": appState.typifiedState.getObjectFromCode(TypifiedValueModel.CODE_ORDER_STATUS, "CANCELED").id,
                "filterOperator": "NEQ"
            },
        ];
        appState.schedulePreventDropState.scheduledOrders = await orderQuery.findPlainObject();
    }

    async changeYear(e) {
        await this.propsUtil.changeUrlRequest({ year: e.target.value });
        this.year = e.target.value;
        this.loadTable();
    }

    getYearFromUrl() {
        return this.propsUtil.getRequest("year") || util.getMoment().format("YYYY");
    }

    getSlots() {
        let slots = [];
        for (let order of appState.schedulePreventDropState.scheduledOrders) {
            if (order.scheduleVisitId != null && order.scheduledDate.startsWith(this.year)) {
                if (util.hasValue(order.slot)) {
                    slots = [...order.slot, ...slots]
                }
            }
        }
        let result = {};
        if (slots.length > 0) {
            result = util.getDictMultipleFromArrayToArray(slots, "scheduledDate");
        }
        Object.keys(result).map(scheduledDate =>
            result[scheduledDate] = util.ordenarFechasAsc(result[scheduledDate], "scheduledTime")
        )
        appState.schedulePreventDropState.slotSchedule = result
    }

    getTotals() {
        let visits = [];
        for (let order of appState.schedulePreventDropState.scheduledOrders) {
            if (order.scheduleVisitId != null && order.scheduledDate.startsWith(this.year)) {
                if (appState.schedulePreventDropState.scheduleVisitsDict[order.scheduleVisitId]) {
                    visits.push(appState.schedulePreventDropState.scheduleVisitsDict[order.scheduleVisitId])
                }
            }
        }
        let sumHours = 0;
        let sumVisits = 0;
        let objectVisitsDict = util.getDictSingleFromArray(visits);
        for (let order of appState.schedulePreventDropState.scheduledOrders) {
            if (order.scheduleVisitId != null && order.scheduledDate.startsWith(this.year)) {
                if (util.hasValue(order.scheduledLabel)) {
                    let horas = order.scheduledLabel.split('/');
                    for (let hora of horas) {
                        sumHours += parseInt(hora);
                    }
                } else {
                    if (objectVisitsDict[order.scheduleVisitId]?.scheduleVisitSlots.length > 0) {
                        for (let scheduleVisitSlot of objectVisitsDict[order.scheduleVisitId]?.scheduleVisitSlots) {
                            sumHours += parseInt(scheduleVisitSlot.scheduledDuration);
                        }
                    }
                }
                sumVisits++;
            }
        }
        this.setState({
            totalHours: sumHours, totalVisits: sumVisits
        });
    }

    onOpenOrderModal(order) {
        if (util.hasValue(order)) {
            this.setState({
                modalClient: true,
                order
            })
        }
    }

    renderFilters() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let optionsYear = [
            { label: "2019", value: "2019" },
            { label: "2020", value: "2020" },
            { label: "2021", value: "2021" },
            { label: "2022", value: "2022" },
            { label: "2023", value: "2023" },
            { label: "2024", value: "2024" },
            { label: "2025", value: "2025" },
            { label: "2026", value: "2026" },
            { label: "2027", value: "2027" },
            { label: "2028", value: "2028" },
            { label: "2029", value: "2029" },
        ];
        let scheduledInMonth = {};
        for (let scheduledOrder of appState.schedulePreventDropState.scheduledOrders) {
            if (scheduledInMonth[scheduledOrder.scheduledDate] == null) {
                scheduledInMonth[scheduledOrder.scheduledDate] = [];
            }
            scheduledInMonth[scheduledOrder.scheduledDate].push(scheduledOrder);
        }
        return (
            <div className="row">
                <div className="col-4 table-four table-four--small">
                    <table className="table visible-text"
                           summary="Suma total de horas y de visitas">
                        <thead>
                        <tr>
                            <th scope="col"
                                className="cell-total"
                                style={{ 'borderBottom': 0 }}>
                                <span>{t('Año')}</span>
                            </th>
                            <th scope="col"
                                className="cell-total">{t('total')}<span>{t('horas')}</span>
                            </th>
                            <th scope="col"
                                className="cell-total">{t('total')}<span>{t('visitas')}</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{ width: '150px' }}>
                                <Select2Component
                                    value={this.getYearFromUrl()}
                                    onChange={(e) => this.changeYear(e)}
                                    name={"scheduleDate"}
                                    type={"text"}
                                    isClearable={false}
                                    forceMedia={this.props.fromRightModal ? "md" : ""}
                                    options={optionsYear}
                                />
                            </td>
                            <td>
                                <div
                                    className="total">{this.state.totalHours}<span
                                    className="small">h</span></div>
                            </td>
                            <td>
                                <div
                                    className="total">{this.state.totalVisits}<span
                                    className="small">{t('Visitas')}</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    className="col-8 d-flex align-items-center o-flex-end table-four table-four--small">
                    <VsfButtonNew
                        label={t("Planificar 12 meses")}
                        showButton={this.creatable["order"] || false}
                        onClick={_ => {
                            this.setState({
                                fromClient: true, modalClient: true
                            })
                        }}
                        className="btn btn--loader"
                    />
                </div>
            </div>
        )
    }

    renderImpl() {
        const t = translate;
        let modalClasses = util.getModalClasses();
        this.propsUtil = new PropsUtil(this.props);
        let months = this.getMonths();
        let client = this.graphDataMainType;
        let visitsType = appState.schedulePreventDropState.scheduleVisits;
        let scheduledInMonth = {};
        for (let scheduledOrder of appState.schedulePreventDropState.scheduledOrders) {
            if (scheduledInMonth[scheduledOrder.scheduledDate] == null) {
                scheduledInMonth[scheduledOrder.scheduledDate] = [];
            }
            scheduledInMonth[scheduledOrder.scheduledDate].push(scheduledOrder);
        }
        return (
            <div className={this.props.fromRightModal ? modalClasses.push : ""}>
                <InfoBlock componentObject={this}>
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />

                    {this.props.fromRightModal ?
                        <div className={modalClasses.head}>
                            <PageTitleRightModal componentObject={this}
                                                 title={this.getPageTitle()}
                                                 subtitle={this.getPageSubtitle()} mode={"edit"}
                                                 onCloseModal={() => this.onCloseModal()}
                            >
                                <MoreButtons>
                                    <ClientActions fromRightModal={this.props.fromRightModal} getModelQuery={() => this.getModelQuery()} setErrors={(errors) => this.setErrors(errors)} model={client} />
                                </MoreButtons>
                            </PageTitleRightModal>
                            <TabsComponent
                                id={this.graphDataMainType.id || this.props.clientId}
                                tabs={this.tabsData.getDataPosClient()}
                                active={"Preventivas"}
                                fromRightModal={this.props.fromRightModal}
                                classNameModal={" "}
                            />
                        </div>
                        :
                        <PageTitle
                            title={this.getPageTitle()}
                            subtitle={this.getPageSubtitle()}>
                            <MoreButtons
                                onReloadData={() => this.loadTable()}>
                                <ClientActions fromRightModal={this.props.fromRightModal} getModelQuery={() => this.getModelQuery()} setErrors={(errors) => this.setErrors(errors)} model={client} />
                            </MoreButtons>
                        </PageTitle>

                    }
                    <div className={'card mb-3'}>
                        <div className={modalClasses.body}>
                            {!this.props.fromRightModal &&
                            <TabsComponent
                                id={this.graphDataMainType.id}
                                tabs={this.tabsData.getDataPosClient()}
                                active="Preventivas"
                                fromRightModal={this.props.fromRightModal}

                            />
                            }

                            <EmptyDrop getTotals={() => this.getTotals()} getSlots={() => this.getSlots()}
                                       loadClients={() => this.loadClients()}
                                       setErrors={(errors) => this.setErrors(errors)}
                                       onDeleteWithSlot={(e) => this.onDeleteWithSlot(e)}>
                                <SpinnerClipLoaderInPage loading={this.state.loading}>

                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade" id="detalles" role="tabpanel"
                                             aria-labelledby="detalles-tab">{t('Detalles')}
                                        </div>
                                        {this.renderFilters()}
                                        <div
                                            className={this.props.fromRightModal ? "" : " table-four"}>
                                            <div className="">
                                                <table className="table"
                                                       summary="Visitas acordadas con el cliente donde muestra las horas por meses">
                                                    <thead>
                                                    <tr>
                                                        {months.map((monthItem, index) =>
                                                            <th key={index}
                                                                scope="col">{monthItem.label}
                                                            </th>
                                                        )}
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className={"preventivas"}>
                                                        {months.map((monthItem, index) =>
                                                            <MonthDrop monthItem={monthItem} key={index}
                                                                       loadClients={() => this.loadClients()}
                                                                       getTotals={() => this.getTotals()}
                                                                       getSlots={() => this.getSlots()}
                                                                       storeId={this.getFormId()}
                                                                       propietaryId={client.parentId}>
                                                                {scheduledInMonth[monthItem.code] != null && scheduledInMonth[monthItem.code].map(order =>
                                                                    <WorkInMonthUnit
                                                                        name={order.id}
                                                                        order={order}
                                                                        onClick={(e) => this.onOpenOrderModal(e)}
                                                                        visitType={appState.schedulePreventDropState.scheduleVisitsDict[order.scheduleVisitId]}
                                                                        creatable={true}
                                                                        key={index + order.id} />
                                                                )}
                                                            </MonthDrop>
                                                        )}
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {visitsType.length === 0 &&
                                        <span className="title-cell text-click"
                                              onClick={() => this.propsUtil.changeUrl('/schedule/visit/type/form/')}>
                                                            {t('No hay creado ningún tipo de visita Preventiva. Por favor crear un tipo de visita preventiva para poder asignarlo al establecimiento')}</span>
                                        }
                                        <div className="row">
                                            {visitsType.map((visitType, index) =>
                                                <div className="col-lg-3" key={index}>
                                                    <div className="visits">
                                                        <p className="visits__text">{t(visitType.name)}</p>
                                                        <WorkInMonthUnit creatable={this.creatable["order"] || false}
                                                                         onClick={() => this.onOpenOrderModal()}
                                                                         visitType={visitType} />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {/*Si no sale el nombre de la visita preventiva es que se ha borrado de los tipos de preventivas*/}
                                        <GroupOfField
                                            title={t('Agenda') + "."}
                                            icon="fas fa-calendar-check"
                                            classGroup="sinestilo" />

                                        <div className="table-edit">
                                            <table className="table">
                                                <thead>
                                                <tr>
                                                    <th scope="col" className="col-3">{t("Pedido")}</th>
                                                    <th scope="col" className="col-3">{t("Fecha")}</th>
                                                    <th scope="col" className="col-3">{t("Hora")}</th>
                                                    <th scope="col" className="col-3">{t("Duración")}</th>
                                                </tr>
                                                </thead>
                                                {Object.keys(appState.schedulePreventDropState.slotSchedule).map(scheduledDate =>
                                                    appState.schedulePreventDropState.slotSchedule[scheduledDate].map(slot => {
                                                        if (util.left(slot.scheduledDate, 4) === this.year && slot.createdByPreventive) {
                                                            return (
                                                                <>
                                                                    <tbody className="border-end">
                                                                    <td className="c-modal-info-cell__orders__item text-click"
                                                                        onClick={() => this.openModalOrder(slot.order)}>
                                                                        {slot.order?.code}
                                                                    </td>
                                                                    <td className="c-modal-info-cell__orders__item">
                                                                        {(util.hasValue(slot.scheduledTime) &&
                                                                            util.isValidDate(new Date(util.getMoment(slot.scheduledTime)))) ?
                                                                            util.getMoment(slot.scheduledTime).format("DD/MM/YY") :
                                                                            slot.scheduledDate && this.getMonth(util.right(slot.scheduledDate, 2))}
                                                                    </td>
                                                                    <td className="c-modal-info-cell__orders__item">
                                                                        {(util.hasValue(slot.scheduledTime) &&
                                                                            util.isValidDate(new Date(util.getMoment(slot.scheduledTime)))) ?
                                                                            util.getMoment(slot.scheduledTime).format("HH:mm") + "h." :
                                                                          t("Sin asignar")
                                                                        }
                                                                    </td>
                                                                    <td className="c-modal-info-cell__orders__item"
                                                                        style={{ 'minWidth': '50px' }}>
                                                                        {slot.scheduledDuration + "h."}
                                                                    </td>
                                                                    </tbody>
                                                                </>
                                                            )
                                                        }
                                                    })
                                                )
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </SpinnerClipLoaderInPage>
                            </EmptyDrop>
                        </div>
                    </div>
                    <AlertModal isOpen={this.state.eliminar}
                                onCloseModal={() => this.setState({
                                    eliminar: false
                                })}>
                        <PopUp
                            title={t('Eliminar')}
                            subtitle={t('Pedido ')}
                            icon="exclamationTriangleIcon"
                            text={t('¿Estas Seguro de Eliminar este pedido y todos sus datos relaccionados?')}
                            label1={t('No eliminar')}
                            label2={t('Eliminar')}
                            loading2={this.state.loadingDelete}
                            onClick1={() => this.noCancel()}
                            onClick2={() => this.deleteOrder()}
                            messageOnLoading={"Eliminando"}
                            messageOnSuccess={"Eliminado"}
                        >
                        </PopUp>
                    </AlertModal>
                    {this.state.modalClient &&
                    <ClientPreventModalMulti
                        order={this.state.order}
                        year={this.year} fromRightModal={this.props.fromRightModal}
                        getTotals={() => this.getTotals()}
                        loadTable={() => this.loadTable()}
                        loadClients={() => this.loadClients()}
                        fromClient={this.state.fromClient}
                        openModal={this.state.modalClient}
                        closeModal={() => this.setState({ modalClient: false, fromClient: false })}
                    />
                    }
                </InfoBlock>
            </div>
        )
    }

    openModalOrder(order) {
        return this.propsUtil.changeUrlRequest({
            "orderId": order.id,
            "rightModal": "modalorderls",
            "rightModalTab": "order",
            "fromRightModal": true
        });
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(ScheduleByClientPrevent);
