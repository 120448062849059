import React, { Component } from 'react';
import { observer } from 'mobx-react';

@observer
export default class GroupOfField extends Component {
    render() {
        return (
            <div className={this.props.classGroup ? this.props.classGroup : ''}>
                {this.props.title != null &&
                <h5 style={{ backgroundColor: this.props.tintColor }} className="card-title">
                    <span className={this.props.icon}/>
                    {this.props.title}
                    <span className='card-title__name'>{this.props.subtitle}</span>
                </h5>
                }

                <div className="row">
                    {this.props.children}
                </div>
            </div>
        );
    }
}
