import OrderModel from "./OrderModel";
import WorkOrderModel from "./WorkOrderModel";
import config from "../config/config";
import ClientModel from "./ClientModel";
import WorkLogModel from "./WorkLogModel";
import SaveOptionsModel from "./SaveOptionsModel";
import UserModel from "./UserModel";
import TypifiedValueModel from "./TypifiedValueModel";
import WorkOrderCartArticleModel from "./WorkOrderCartArticleModel";


export default class SlotModel extends SaveOptionsModel {

    static MAIN = "MAIN";
    static SUPPORT = "SUPPORT";
    static CODE_VIEWED_YES = "YES";
    static CODE_VIEWED_NO = "NO";
    static CODE_INTEGRATION_SUCCESS = "OK";
    static CODE_INTEGRATION_FAIL = "KO";
    static CODE_INTEGRATION_UNSENT = "PENDING";
    static CODE_INTEGRATION_NOT_SEND = "[NULL]";
    static CODE_VIEWED_PENDIG = "YES_CHANGES_PENDING";

    id;
    orderId;
    code;
    integrationErpDate;
    integrationErpStatus;
    integrationErpMessage;
    scheduledDuration;
    scheduledTime;
    scheduledDate;
    slotUserRole;
    userId;
    createdByPreventive;
    workOrderIds;
    isDateTimeFixed;
    posZoneId;
    isUserFixed;
    isDurationEdited;
    comments;
    scheduleVisitId;
    clientId;
    scheduledEndTime;
    scheduledTimeUntil;
    isFinished;
    isStarted;
    viewedByTechnical;
    viewedByTechnicalDate;
    assignedSlotMainId;
    typeWorkOrderId;
    satisfaction;
    clientSign;
    fileSign;
    signContactName;

    lang;

    nameMainType = "slot";
    graphFindByIdOperation = "slot";
    graphFindOperation = "slotsConnection";
    graphExportOperation = "slotsExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "orderId": { label: "Codigo del Pedido", relationName: "order", relationValue: "code" },
            "scheduledDuration": { label: "Duración", subscription: true },
            "scheduledTime": { label: "F. Planificación", type: "DateTimeField", subscription: true },
            "slotUserRole": { label: "Tipo de técnico", subscription: true },
            "scheduledDate": { readonly: true },
            "userId": { label: "Técnico", subscription: true },
            "posZoneId": { readonly: true, label: "Zona" },
            "createdByPreventive": { label: "Creado desde preventivas", },
            "comments": {label: "Comentarios"},
            "createdByDaemon": { label: "Creado desde la aplicación", readonly: true },
            "integrationErpMessage": { label: "Mensaje de integración ERP", readonly: true },
            "scheduleVisitId": { label: "ID de visita planificada", readonly: true },
            "viewedByTechnical": { label: "Visto por técnico", readonly: true, subscription: true },
            "viewedByTechnicalDate": { label: "Fecha visto por técnico", readonly: true, subscription: true },
            "isDurationEdited": { label: "Duración editada"},
            "scheduledTimeUntil": { label: "Fecha de planificación hasta", type: "DateTimeField" },
            "scheduledEndTime": { label: "Fin de fecha de planificación", readonly: true },
            "isUserFixed": {label: "Usuario fijado"},
            "isFinished": { label: "Estado finalizado", readonly: true, subscription: true },
            "isDateTimeFixed": { label: "Fecha fijada", subscription: true },
            "clientId": { label: "ID de cliente", readonly: true },
            "workOrderIds": { label: "Ordenes de trabajo", subscription: true },
            "isStarted": { label: "Iniciada", subscription: true,readonly: true },
            "typeWorkOrderId": { label: "Tipo de OT", readonly: true, subscription: true },
            "assignedSlotMainId": {label: "ID de la visita principal asignada"},
            "code": { label: "Código", readonly: true },
            "integrationErpDate": { label: "Fecha Envio a ERP", readonly: true },
            "integrationErpStatus": { label: "Estado ERP" },
            "lang": { label: "Idioma", readonly: true },
            "satisfaction": { label: "Satisfacción", readonly: true },
            "satisfactionInterpreted": { label: "Satisfacción", readonly: true },
            "clientSign": { label: "Firma del cliente", readonly: true },
            "fileSign": { label: "Firma", readonly: true },
            "signContactName": { label: "Firma de nombre del contacto",readonly: true },
        };
        this.addRelatedTableGeneric(result, "workLog", "Relation", WorkLogModel);
        this.addRelatedTableGeneric(result, "workOrder", "Relation", WorkOrderModel);
        this.addRelatedTableGeneric(result, "order", "RelationOne", OrderModel);
        this.addRelatedTableGeneric(result, "client", "RelationOne", ClientModel);
        this.addRelatedTableGeneric(result, "user", "RelationOne", UserModel);
        this.addRelatedTableGeneric(result, "childSlots", "Relation", SlotModel);
        this.addRelatedTableGeneric(result, "workOrderCartArticle", "Relation", WorkOrderCartArticleModel);
        this.addRelatedTableGeneric(result, "typeWorkOrder", "RelationOne", TypifiedValueModel);
        return result;
    }

    downloadPDF(slotId) {
        let result = "";
        try {
            result = config.apiRestHostBaseUrl + "/report?idSlot=" + slotId
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }

    downloadPDFSO(slotId,salesOrderId) {
        let result = "";
        try {
            result = config.apiHostInt + "/salesOrder?salesOrderId=" + salesOrderId+"&slotId="+slotId;
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }

    downloadPDFSOUnites(slotId) {
        let result = "";
        try {
            result = config.apiHostInt + "/salesOrderUnites?slotId="+slotId;
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }

    downloadExcelRelatorio(slotId) {
        let result = "";
        try {
            result = config.apiRestHostBaseUrl + "/excel/ExcelSlotWithImages?slotId=" + slotId
        } catch (e) {
            this.log('EXCEPTION');
            this.log(e);
        }
        return result;
    }

    async getSlots() {
        let slotsQuery = new SlotModel();
        return await slotsQuery.find();
    }


    addRelatedTablesForSlotsDay() {
        this.addRelatedTable("client");
        this.addRelatedTable("workOrder");
        this.addRelatedTable("workOrder.workLog");
        this.addRelatedTable("order");
        this.addRelatedTable("workLog");

    }

    getResponseFieldsFromMutation() {
        let baseFields = super.getResponseFieldsFromMutation();
        return ["typeWorkOrderId", ...baseFields];
    }

};





















