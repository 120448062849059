import AbstractModel from "./AbstractModel";

export default class VehicleGpsModel extends AbstractModel {

    id;
    time;
    latitude;
    longitude;
    plate;


    nameMainType = "vehicleGps";
    graphFindByIdOperation = "vehicleGps";
    graphFindOperation = "vehicleGpssConnection";
    graphExportOperation = "vehicleGpssExport";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "time": "",
            "latitude": "",
            "longitude": "",
            "plate":"",
        };
        return result;
    }

};
