import BaseValidation from "./BaseValidation";
import util from "../../../util/Util";


export default class DecimalValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;
        if (this.event === "change") {
            if (util.hasValue(inputValue)) {
                let rexValidateDecimal = /^(-?[0-9]*[\,|\.]?[0-9]*)$/;
                let arr = inputValue.match(rexValidateDecimal);
                if (arr == null) {
                    isCorrect = false;
                }
            }
        }
        return isCorrect;
    }
}
