import debouncei from 'lodash.debounce';

export default function (wait, options = {}) {
    return function (target, name, descriptor) {
        options.leading=true;
        if (wait==null) {
            wait=1000;
        }
        return {
            configurable: true,
            enumerable: descriptor.enumerable,
            get: function () {
                Object.defineProperty(this, name, {
                    configurable: true,
                    enumerable: descriptor.enumerable,
                    value: debouncei(descriptor.value, wait, {leading:false,trailing:true})
                });

                return this[name];
            }
        };
    };
}
