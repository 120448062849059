import BaseValidation from "./BaseValidation";

export default class AlphanumericValidation extends BaseValidation {

    validate(name, inputValue, args) {
        let isCorrect = true;
        if (this.event==="change") {
            let rexAlphanumeric = /^[a-zA-Z0-9-*_/]{0,100}$/;
            if (!rexAlphanumeric.test(inputValue)) {
                isCorrect = false;
            }
        }
        return isCorrect;
    }
}
31231231