import React, {Component} from 'react';
import 'filepond/dist/filepond.min.css';
import {observer} from "mobx-react/index";
import appState from "../state/AppState";
import translate from "../translator/translate";
import AppLogger from "../util/AppLogger";
import Overlay from "./modals/Overlay";
import DynamicGroupModel from "../models/DynamicGroupModel";
import DynamicGroupSelectInModal from "./modals/DynamicGroupSelectInModal";
import {observable} from "mobx";
import InputTypeComponent from "./fields/InputTypeComponent";
import util from '../util/Util'
import GroupOfField from "./layout/GroupOfField";
import Select2Component from "./fields/Select2Component";
import DateInputHoursMobiscroll from "./fields/DateInputHoursMobiscroll";
import VsfButtonNew from "./VsfButtonNew";

@observer
class DynamicFieldsComponent extends Component {

    @observable dynamicsGroups = {};

    constructor(props) {
        super(props);
    };

    async componentDidMount() {
        await this.loadGroups();

    }

    async loadGroups() {
        let dynamicQuery = new DynamicGroupModel();
        dynamicQuery.filters = [
            {
                "fieldName": "relatedModels",
                "fieldValue": this.props.nameMainType?.toUpperCase(),
                "filterOperator": "SUBSTR"
            },
        ];
        dynamicQuery.addRelatedTable('dynamicField');
        let dynamicsGroups = await dynamicQuery.findPlainObject();
        this.dynamicsGroups = util.getDictMultipleFromArray(dynamicsGroups, 'code');

    }

    updateInputEvent(dynamicGroup) {
        if (this.props.dynamicFields?.groups?.includes(dynamicGroup.code)) {
            this.props.deleteInputEventDynamicFields(dynamicGroup);
        } else {
            this.props.updateInputEventDynamicFields("", "", dynamicGroup)
        }
    }

    render() {
        const t = translate;

        return (
            <div>
                {Object.values(this.dynamicsGroups).length === 0 &&
                <span className="title-cell">{t("No hay ningún campo dinámico añadido")}
                    </span>
                }
                {Object.values(this.dynamicsGroups).length > 0 &&
                <VsfButtonNew label={t("Añadir grupo de campos dinámicos")} onClick={() => {
                    appState.layoutState.favouritesDropMenuOpen = true
                }} className="btn btn-outline-secondary dropdown-toggle" />
                }
                {this.props.dynamicFields?.groups?.map((groupId, index) => (
                    <>
                        <GroupOfField
                            title={this.dynamicsGroups[groupId] && this.dynamicsGroups[groupId].name}
                            icon='fas fa-file-import'>
                            {this.dynamicsGroups[groupId]?.dynamicField?.map(fieldType => (
                                this.getInput(fieldType)
                            ))}
                        </GroupOfField>
                    </>
                ))
                }
                <Overlay show={appState.layoutState.favouritesDropMenuOpen}
                         onClick={_ => appState.layoutState.favouritesDropMenuOpen = false} />
                {appState.layoutState.favouritesDropMenuOpen &&
                <div className="table-responsive">
                    <table className="dropdown-sublist table"
                           style={{ zIndex: 100, backgroundColor: '#fff', left: 0, width: '500px' }}>
                        <tbody>
                        <DynamicGroupSelectInModal
                            values={this.props.dynamicFields?.groups}
                            onChange={(group) => this.updateInputEvent(group)}
                            dynamicsGroups={Object.values(this.dynamicsGroups)}
                            {...this.props}
                        />
                        </tbody>
                    </table>
                </div>
                }
            </div>
        );
    }


    getInput(fieldType, index) {
        let result = null;
        if (fieldType.type === "SELECT") {
            let optionsSelect = [];
            fieldType.optionValues?.split(',').map((opcion) => {
                optionsSelect.push({ label: opcion, value: opcion })
            });
            result = <Select2Component
                value={this.props.dynamicFields[fieldType.code]}
                title={fieldType.label}
                name={fieldType.code}
                classGroup={"col-12 col-md-4"}
                editable={true}
                onChange={(e) => this.updateInputEventField(e, fieldType)}
                options={optionsSelect}
            />
        } else if (fieldType.type === "TEXT") {
            result = <InputTypeComponent
                key={fieldType.id}
                value={this.props.dynamicFields[fieldType.code]}
                onChange={(e) => this.updateInputEventField(e, fieldType)}
                editable={this.props.editable}
                name={fieldType.code}
                classGroup={"col-12 col-md-4"}
                title={fieldType.label}
            />
        } else if (fieldType.type === "NUMBER") {
            result = <InputTypeComponent
                key={fieldType.id}
                value={this.props.dynamicFields[fieldType.code]}
                onChange={(e) => this.updateInputEventField(e, fieldType)}
                editable={this.props.editable}
                name={fieldType.code}
                classGroup={"col-12 col-md-4"}
                title={fieldType.label}
            />
        } else if (fieldType.type === "DATE") {
            result = <DateInputHoursMobiscroll
                value={this.props.dynamicFields[fieldType.code]}
                classGroup={"col-12 col-md-4"}
                title={fieldType.label}
                name={fieldType.code}
                prefix={"fas fa-calendar-alt"}
                onChange={(e) => this.updateInputEventField(e, fieldType)}
                type={"text"}
                hours={true}
                allowEmptyDate={true} />
        }
        return result;
    }


    updateInputEventField(e, fieldType) {
        this.props.updateInputEventDynamicFields(fieldType.code, e.target.value)
    }


    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default DynamicFieldsComponent
