import React from 'react';
import {observer} from 'mobx-react';
import appState from "../../state/AppState";
import BaseCommon from "./BaseCommon";
import ApolloProxy from "../../network/ApolloProxy";
import GraphException from "../../network/GraphException";
import AppException from "../../util/AppException";
import {computed} from 'mobx';
import util from "../../util/Util";
import AppLogger from "../../util/AppLogger";
import TextListItem from "../../components/list/TextListItem";
import t from "../../translator/translate";

@observer
class BaseList extends BaseCommon {

    includeSavedFavourites;
    includeChangeColumns;
    includeFilters;
    includeDownload;
    withActions;
    includeSearch;
    reloadBaseList;
    filterQueryList = [];

    constructor(props) {
        super(props);
        //graphOperation se utiliza para guardar los filtros de este usuario
        this.graphOperation = this.getModelQuery().graphFindOperation;
        this.numEntriesPerPage = 20;
        appState.formWithoutData = false;

        this.reloadBaseList = true;
        this.includeSavedFavourites = true;
        this.includeChangeColumns = true;
        this.includeFilters = true;
        this.includeDownload = true;
        this.includeSearch = true;
        this.withActions = false;
        /*Para el desplegable de campos listado seleccionable*/
        this.stateListColumnsSelect.modalOpen = false;
        this.stateListColumnsSelect.listFields = this.getListFields();
        //Array simple de campos que se van a ordenar. Pueden ser los "names" de getListFields();
        this.stateListColumnsSelect.sortableFields = this.getListFields().map(listItem => listItem.name);
        this.stateListColumnsSelect.visibleFields = this.getVisibleFields();
        appState.clientState.isPos = false;
        appState.clientState.isClient = false;
    }

    @computed get stateListColumnsSelect() {
        return this.getListState().listColumnsSelect;
    }

    @computed get stateListFilter() {
        return this.getListState().listFilter;
    }

    @computed get stateListQuickEdit() {
        return this.getListState().quickEdit;
    }

    toggleFavouriteModal() {
        this.setState({
            favouriteModalOpen: !this.state.favouriteModalOpen
        })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        let result = true;
        if (nextProps.location?.search?.includes("rightModal")) {
            result = false;
            if (this.props.fromRightModal) {
                result = true;
            }
        }
        return result;
    }

    getParamOrder(params) {
        let result = params.get('order');
        if (this.props.fromRightModal) {
            result = params.get('modalOrder');
        }
        return result;
    }

    getParamOrderMode(params) {
        let result = params.get('orderMode');
        if (this.props.fromRightModal) {
            result = params.get('modalOrderMode');
        }
        return result;
    }

    getParamFilters(params) {
        let result = params.get('filters');
        if (this.props.fromRightModal) {
            result = params.get('modalFilters');
        }
        return result;
    }

    getParamQ(params) {
        let result = params.get('q');
        if (this.props.fromRightModal) {
            result = params.get('modalQ');
        }
        return result;
    }

    getParamPage(params) {
        let result = params.get('page');
        if (this.props.fromRightModal) {
            result = params.get('modalPage');
        }
        return result;
    }

    getParamPagination(params) {
        let result = params.get('pagination');
        if (this.props.fromRightModal) {
            result = params.get('modalPagination');
        }
        return result;
    }

    async onDownloadExcelOrCsv(event) {
        appState.layoutState.downloadExcelCsv = false;
        let newData = null;
        let modelQuery = this.getModelQuery();
        if (modelQuery != null) {
            const params = new URLSearchParams(this.props.location.search);
            // Establezco los valores de igualdad en modelQuery para que la busqueda se realice con esos filtros
            modelQuery.filters = this.getFiltersForGraph();
            for (let filterQuery of this.filterQueryList) {
                if (filterQuery.fieldName != null && filterQuery.fieldValue != null) {
                    if (filterQuery.filterOperator == null) {
                        modelQuery[filterQuery.fieldName] = filterQuery.fieldValue
                    } else {
                        modelQuery.filters.push({
                            ...filterQuery
                        })
                    }
                }
            }

            let order = this.getParamOrder(params);
            if (order == null || (util.hasValue(this.props.articleId) || !util.hasValue(this.props.assetId))) {
                order = this.getDefaultOrder();
            }
            let orderMode = this.getParamOrderMode(params);
            if (orderMode == null || (util.hasValue(this.props.articleId) || !util.hasValue(this.props.assetId))) {
                orderMode = this.getDefaultOrderMode();
            }
            let {fieldsNames, fieldsLabels} = this.getFieldNamesAndLabels(modelQuery);

            let q = this.getParamQ(params);
            modelQuery.orderBy = order;
            modelQuery.orderMode = orderMode;
            let responseWithUrl = await modelQuery.exportExcelOrCsv(event, q, fieldsNames, fieldsLabels);
            if (responseWithUrl != null) {
                //window.location.href = responseWithUrl;
                //Realizamos la descarga del excel desde un FORM en vez de un window.location
                this.formSubmitGet(responseWithUrl);
            }
        }
        if (newData !== null) {
            this.getListData().connection = newData;
        }
    }

    getFieldNamesAndLabels(modelQuery) {
        let fieldsNames = [];
        let fieldsLabels = [];
        this.stateListColumnsSelect.listFields.forEach(campo => {
            //campo es de la forma Asset.AssetType.Value
            // [asset.AssetType.Value]
            //     [AssetType]
            if (campo.visible && !campo.noExport) {
                fieldsLabels.push(campo.title);
                let name = campo.name;
                let fieldName = name.replace(/\b\w/g, l => l.toUpperCase());
                if (name.endsWith(".value")) {
                    fieldName = this.getFieldNameTypifieds(modelQuery, name.replace(".value", ""));
                }
                if (campo.prefixName != null) {
                    fieldName = campo.prefixName + fieldName;
                }
                fieldsNames.push(fieldName);
            }
        });
        return {fieldsNames, fieldsLabels};
    }

    formSubmitGet(responseWithUrl) {
        //let form = window.document.forms.downloadExcelForm;
        var form = document.createElement("form");
        form.action = responseWithUrl;
        form.innerHTML = '';
        //form.target = '_blank';
        document.body.appendChild(form);
        this.log({ responseWithUrl });
        const urlParams = new URLSearchParams(util.getDelim(responseWithUrl, "?", 1));
        urlParams.forEach((value, name) => {
            this.addParamToGetForm(form, name, value);
        });
        form.submit();
        this.log({formSubmitGet:"",form});
        form.remove();
    }

    addParamToGetForm(form, name, value) {
        var hiddenElement = document.createElement("input");
        hiddenElement.setAttribute("type", "hidden");
        hiddenElement.setAttribute("name", name);
        hiddenElement.setAttribute("value", value);
        form.appendChild(hiddenElement);
    }

    getFieldNameTypifieds(modelQuery, campoWithoutValue) {
        let fieldName = "";
        let relatedTable = modelQuery._relatedTables[campoWithoutValue];
        if (relatedTable?.args !== "") {
            fieldName = campoWithoutValue.replace(/\b\w/g, l => l.toUpperCase()) + "(" + relatedTable?.args + ").Value";
        }
        return fieldName;
    }

    /**
     * Nombre de la operaci�n de Graphql
     * @returns {string}
     */
    getGraphQLOperation() {
        return "";
    }

    getListState() {
        throw "Method must be implemented";
    }

    getListStatus() {
        return this.getListState().listStatus;
    }

    getListData() {
        return this.getListState().listData;
    }

    getDefaultOrder() {
        return "createdAt";
    }

    getDefaultOrderMode() {
        return "DESC";
    }

    /**
     * A partir de la URL obtiene los parámetros de la llamada a GraphQL
     * @returns {*}
     */
    getFiltersForGraph() {
        const params = new URLSearchParams(this.props.location.search);
        let filtersText = this.getParamFilters(params);
        let filters = [];
        if (util.hasValue(filtersText)) {
            try {
                let filtersWithKey = JSON.parse(filtersText);
                filters = filtersWithKey.map((obj) => {
                    let filterItem = obj;
                    delete filterItem["fieldKey"];
                    delete filterItem["fieldLabel"];
                    //Si nos llega [NULL] estamos buscando la cadena vacia
                    if (filterItem["fieldValue"] == "[NULL]") {
                        filterItem["fieldValue"] = "";
                    }
                    return filterItem;
                })
            } catch (e) {
                throw new AppException("Filters sintax error");
            }
        }
        return filters;
    }

    getGraphQLQueryVariables() {
        const params = new URLSearchParams(this.props.location.search);
        let order = params.get('order');
        if (order == null) {
            order = this.getDefaultOrder();
        }
        let orderMode = this.getParamOrderMode(params);
        if (orderMode === null) {
            orderMode = this.getDefaultOrderMode();
        }
        let after = params.get('after');
        let before = params.get('before');
        let filters = this.getFiltersForGraph();
        let q = params.get('q');
        let first = this.numEntriesPerPage;
        return {
            query: {
                q,
                filters
            },
            sort: {
                field: order,
                orderMode: orderMode
            },
            first,
            after,
            before
        };
    }


    async componentDidMount() {
        await this.componentDidMountImpl();
    }

    async componentDidMountImpl() {
        await super.componentDidMountImpl();
        await this.reloadList();
    }


    /**
     * Si ha cabiado el orden del listado recargo el listado
     * @param prevProps
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        // Typical usage (don't forget to compare props):
        let reload = false;
        const paramsOld = new URLSearchParams(prevProps.location.search);
        if (prevProps.location.search !== this.props.location.search) {
            reload = true;
        }
        const params = new URLSearchParams(this.props.location.search);
        const orderOld = this.getParamOrder(paramsOld) + this.getParamOrderMode(paramsOld);
        const order = this.getParamOrder(params) + this.getParamOrderMode(params);
        let encontrado = false;
        for (let filterQuery of this.filterQueryList) {
            if (filterQuery.fieldName === "posZoneId") {
                encontrado = true;
            }
        }
        if (!encontrado && this.props.reloadZoneList) {
            let zoneId = appState.userZoneFilters.currentFilter.zoneAsignedId;
            if (util.hasValue(zoneId)) {
                this.filterQueryList.push({
                    fieldName: 'posZoneId',
                    fieldValue: zoneId,
                    filterOperator: "STRIN",
                });
            }
            reload = true;
        }
        if (orderOld !== order) {
            reload = true;
        }
        if (reload && this.reloadBaseList) {
            this.reloadList();
        }
    }

    getGraphErrorsFromStatus() {
        return new GraphException().getGraphErrorsFromStatus(this.getListStatus().queryGraphQlResponse);
    }

    getModelQuery() {
        return null;
    }

    async reloadData() {
        await this.reloadList();
    }

    async reloadList() {
        let apolloProxy = new ApolloProxy(this.props.client);
        appState.loadingBarState.start();
        this.getListStatus().formLoading = true;
        this.getListStatus().formError = false;
        try {
            let newData = {};
            let modelQuery = this.getModelQuery();
            if (modelQuery != null) {
                const params = new URLSearchParams(this.props.location.search);
                let page = this.getParamPage(params);
                if (page == null) {
                    page = 1;
                }
                let pagination = this.getParamPagination(params);
                if (pagination == null) {
                    pagination = this.numEntriesPerPage;
                }
                // Establezco los valores de igualdad en modelQuery para que la busqueda se realice con esos filtros
                modelQuery.filters = this.getFiltersForGraph();
                let q = this.getParamQ(params);
                for (let filterQuery of this.filterQueryList) {
                    if (filterQuery.fieldName != null && filterQuery.fieldValue != null) {
                        if (filterQuery.filterOperator == null) {
                            modelQuery[filterQuery.fieldName] = filterQuery.fieldValue
                        } else {
                            modelQuery.filters.push({
                                ...filterQuery
                            })
                        }
                    }
                }
                let order = this.getParamOrder(params);
                let orderMode = this.getParamOrderMode(params);
                if (order == null) {
                    order = this.getDefaultOrder();
                }
                if (orderMode === null) {
                    orderMode = this.getDefaultOrderMode();
                }
                modelQuery.orderBy = order;
                modelQuery.orderMode = orderMode;
                modelQuery.refineWhere["q"] = q;
                let models = await modelQuery.findLimit(page, pagination);
                let items = [];
                for (let model of models) {
                    let modelPlain = Object.assign({}, model);
                    items.push(modelPlain);
                }
                newData.totalCount = modelQuery.totalCount;
                newData.pageInfo = modelQuery.pageInfo;
                newData.items = items;
                this.log({ reloadList: 1, models: items })
            } else {
                let variables = this.getGraphQLQueryVariables();
                let resultQuery = await apolloProxy.graphQuery({
                    query: this.getGraphQLQuery(),
                    variables,
                });
                let operationName = this.getGraphQLOperation();
                if (resultQuery != null && resultQuery.data != null) {
                    newData = resultQuery.data[operationName];
                }
            }
            if (newData !== null) {
                this.getListData().connection = newData;
            }
        } catch (e) {
            let errors = new GraphException().getErrorsFromException(e);
            this.gqlErrors = errors;
            this.getListStatus().queryGraphQlResponse.errors = errors;
            this.getListStatus().formError = true;
        }
        this.getListStatus().formLoading = false;
        appState.loadingBarState.finalize();
    }


    /**
     * Guarda el elemento en edicion rapida
     */
    onClickSaveQuickEdit() {
        this.getListState().quickEdit.modalOpen = false;
    }


    /**
     *  Funciones para modal con campos visibles  y orden
     *  Funciones para modal con campos visibles  y orden
     *  Funciones para modal con campos visibles  y orden
     *  Obtiene el array de columnas visibles por defecto (campo visible:true)
     * @returns {Array}
     */
    getVisibleFields() {
        let visibleFields = {};
        this.getListFields().forEach(function (listField, index) {
            visibleFields[listField.name] = listField.visible;
        });
        return visibleFields;
    }

    getFieldsDynamics(relatedModels) {
        let result = [];
        for (let dynamicGroup of appState.dynamicGroupState.dynamics) {
            for (let dynamicField of dynamicGroup.dynamicField) {
                if (dynamicField.listing && util.perteneceA(relatedModels, dynamicGroup.relatedModels)) {
                    let item = {
                        title: t(dynamicField.label),
                        prefixName: "DynamicField.",
                        name: dynamicField.code,
                        component: (props) => <TextListItem link={true} fromDynamic={true}{...props} />,
                        visible: true,
                    };
                    result.push(item);
                }
            }
        }
        return result;
    }

    /**
     * Obtiene el listado de elementos que aparecerán. Los campos que se pueden usar son los de este ejemplo
     * @returns {*}
     */
    getListFields() {
        return [];
    }

    didDeleteFilters() {
        this.reloadList();
    }

    setListComponent(currentFilterValues) {
        let newCurrentFilter = {};
        let newCurrentOps = {};
        let newCurrentLabels = {};
        currentFilterValues.map((value, index) => {
            newCurrentFilter[value.fieldName] = value.fieldValue;
            newCurrentOps[value.fieldName] = value.filterOperator;
            newCurrentLabels[value.fieldName] = value.fieldLabel;
        });
        this.state.currentFilter = newCurrentFilter;
        this.state.currentFilterOps = newCurrentOps;
        this.state.currentFilterLabels = newCurrentLabels;
        this.recoverCurrentFilters()

    }

    renderActionsList() {

    }

    updateInputActionList(e) {
        let value = e.target.value;
        let name = e.target.name;
        appState[this.nameVariableStateFilter].actionList[name] = value
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default BaseList;
