import { computed, observable } from 'mobx';
import ClientModel from "../models/ClientModel";
import AppLogger from "../util/AppLogger";

class ClientCacheState {

    //bloque establecimientos
    @observable clients = [];
    //bloque clientes
    @observable clientsPos = [];
    @observable arrClients = [];
    @observable formId = 0;

    /**
     * Obtiene el array de valores creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get clientCacheForSelect() {
        // Primero, obtiene el arbol ordenado de los establecimientos
        let valuesHierarchy = this.getValuesHierarchy(this.clients);
        // Segundo, obtenemos el array en orden
        return this.getValuesHierarchyArray(valuesHierarchy);
    }
    /**
     * Obtiene el array de valores creando el arbol e iterando en preorden posteriormente
     *
     * @returns {Array}
     */
    @computed get clientPosCacheForSelect() {
        // Primero, obtiene el arbol ordenado de los establecimientos
        let valuesHierarchy = this.getValuesHierarchy(this.clientsPos);
        // Segundo, obtenemos el array en orden
        return this.getValuesHierarchyArray(valuesHierarchy);
    }

    @computed get clientCacheForOrderSelect() {
        // Primero, obtiene el arbol ordenado de los establecimientos
        let valuesHierarchy = this.getValuesHierarchy(this.clients);
        // Segundo, obtenemos el array en orden
        return this.getValuesHierarchyArrayOrder(valuesHierarchy);
    }

    /**
     * Esto son Establecimientos
     * @returns {Promise<void>}
     */
    async loadClientCache() {
        let clientModel = new ClientModel();
        clientModel.filters = [
            { "fieldName": "isPos", "fieldValue": 'true', "filterOperator": "EQ" },
        ];
        this.clients = await clientModel.find();
    }


    /**
     * Esto son clientes
     * @returns {Promise<void>}
     */
    async loadClientCacheClients() {
        let clientModel = new ClientModel();
        clientModel.filters = [
            { "fieldName": "isPos", "fieldValue": 'false', "filterOperator": "EQ" },
        ];
        this.clientsPos = await clientModel.find();
    }


    /**
     * Obtiene el objeto cliente desde el array de clientes (cargados en memoria)
     * @param clientId
     */
    getClientById(clientId) {
        let client = new ClientModel();
        let plainClientFounded = null;
        for (let client of this.clients) {
            if (client.id == clientId) {
                plainClientFounded = client;
            }
        }
        if (plainClientFounded != null) {
            client.hidrate(plainClientFounded);
        }
        this.log({getClient: this.clients})
        return client.toPlainObject();
    }

    /**
     * Metodo que recorre los nodos padres para llamar recursivamente al metodo que creara los hijos
     *
     * @param nodos
     * @returns {Array}
     */
    getValuesHierarchy(nodos) {
        let result = [];
        let nodosLibres = nodos.slice(0);
        for (let nodo of nodos) {
            // Si es un nodo padre, construimos el arbol hijo
            if (this.isParent(nodo, nodos)) {
                // Quitamos el nodo padre de la lista de nodos libres
                nodosLibres = nodosLibres.filter(function (obj) {
                    return obj.id !== nodo.id;
                });
                result.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    companyName: nodo.companyName,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    parentId: nodo.parentId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            } else {
                result.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    parentId: nodo.parentId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }

        }
        return result;
    }

    /**
     * Metodo que a partir de un nodo padre, busca nodos hijos y los coloca recursivamente
     *
     * @param parentId
     * @param nodos
     * @returns {Array}
     */
    getValuesHierarchyChilds(parentId, nodos) {
        let childs = [];
        // Estos seran los nodos libres
        let nodosLibres = nodos.filter(function (obj) {
            return obj.parentId != parentId;
        });
        for (let nodo of nodos) {
            // Si es un nodo hijo, lo agregamos al array de hijos
            if (nodo.parentId == parentId) {
                childs.push({
                    id: nodo.id,
                    name: nodo.name,
                    code: nodo.code,
                    vatID: nodo.vatID,
                    companyName: nodo.companyName,
                    isPos: nodo.isPos,
                    posZoneId: nodo.posZoneId,
                    childs: this.getValuesHierarchyChilds(nodo.id, nodosLibres)
                })
            }
        }
        return childs;
    }

    /**
     * A partir del arbol ordenado de valores, obtiene el array en preorden recursivamente para ser mostrado en el select
     *
     * @param nodos
     * @param list
     * @param altura
     * @returns {Array}
     */
    getValuesHierarchyArray(nodos, list = false, altura = 0) {
        let result = [];
        for (let nodo of nodos) {
            // No añadimos la jerarquia si no es establecimiento
            if (nodo.isPos === false) {
                if (list) {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura, true),
                        parent: nodo.parentId,
                        altura

                    });
                } else {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        code: nodo.code,
                        vatID: nodo.vatID,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        companyName: nodo.companyName,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.companyName + " " + (nodo.code || "") + " " + (nodo.vatID || ""), altura),
                        parent: nodo.parentId,
                        altura
                    });
                }
                // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
                if (nodo.childs.length != 0) {
                    let childs = this.getValuesHierarchyArray(nodo.childs, list, altura + 1);
                    result = result.concat(childs);
                }
            }

        }
        return result;
    }


    getValuesHierarchyArrayOrder(nodos, list = false, altura = 0) {
        let result = [];
        for (let nodo of nodos) {
            // No añadimos la jerarquia si no es VAT
            if (nodo.isPos === true) {
                if (list) {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura, true),
                        parent: nodo.parentId,
                        altura,
                    });
                } else {
                    result.push({
                        id: nodo.id,
                        name: nodo.name,
                        code: nodo.code,
                        vatID: nodo.vatID,
                        isPos: nodo.isPos,
                        posZoneId: nodo.posZoneId,
                        value: nodo.id,
                        label: this.getValueHierarchyLabel(nodo.name, altura),
                        parent: nodo.parentId,
                        altura,
                    });
                }
                // Si el nodo tiene hijos, los añadimos al array despues de este elemento sumando uno a la altura
                if (nodo.childs.length != 0) {
                    let childs = this.getValuesHierarchyArrayOrder(nodo.childs, list, altura + 1);
                    result = result.concat(childs);
                }
            }
        }
        return result;
    }

    /**
     * Obtiene la etiqueta con guiones para una opcion de un selector jerarquico o una lista jerarquica
     * @param value
     * @param altura
     * @param list
     * @returns {string}
     */
    getValueHierarchyLabel(value, altura, list = false) {
        let separacion = "";
        let separador = " - ";
        if (list) {
            separador = "----";
        }
        for (let i = 0; i < altura; ++i) {
            separacion += separador;
        }
        return separacion + " " + value;
    }

    /**
     * Devuelve si es un nodo padre.
     * Es un nodo padre si no tiene parentId o el parentId apunta a un elemento que no existe
     * @param nodo
     * @param nodos
     * @returns {boolean}
     */
    isParent(nodo, nodos) {
        let result = false;
        // Si el parentId es null, directamente ya es padre
        if (nodo.parentId == null) {
            result = true;
        } else {
            // Si el parentId no es null, cabe aun la posibilidad de que sea padre, ya sea porque su padre ya no exista
            let parentIdFound = nodos.find(function (obj) {
                return obj.id == nodo.parentId;
            });
            if (parentIdFound === undefined) {
                result = true;
            }
        }
        return result;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

}

export default ClientCacheState;
