import React from 'react';
import {observer} from 'mobx-react';
import translate from "../../translator/translate";
import PropsUtil from "../../util/PropsUtil";
import util from "../../util/Util";
import appState from '../../state/AppState';
import AppLogger from "../../util/AppLogger";
import SlotModel from "../../models/SlotModel";
import VsfButtonNew from "../../components/VsfButtonNew";
import VsfModal from "../../components/modals/VsfModal";
import DateInputHoursMobiscroll from "../../components/fields/DateInputHoursMobiscroll";
import {observable} from "mobx";
import CheckWorkDaysModel from "../../models/CheckWorkDaysModel";
import GqlErrors from "../../components/status/GqlErrors";
import GraphException from "../../network/GraphException";
import BaseCommon from "../base/BaseCommon";

@observer
class UserEndJourney extends BaseCommon {

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    @observable timeEndJourney = "";

    constructor(props) {
        super(props);
        this.state = {
            openModal: false, loading: false,
            daemonEndDate: "",
        }
    }

    async componentDidMount(): void {
        if (appState.workLogState.hasUserStartDay(this.props.userId)) {
            appState.workLogState.setUserStartDayKm(this.props.userId);
            await appState.slotsState.generateSlotCurrent();
            appState.slotsState.generateWorkOrderCurrent();
            if (!util.hasValue(appState.slotsState.slotCurrent.id)) {
                await appState.slotsState.generateSlotCurrentNotInMain();
                appState.slotsState.generateWorkOrderCurrent();
            }
        }
    }

    async finalizarJornadaDemonio() {
        const t = translate;
        this.setState({ loading: true });
        await appState.workLogState.loadWorkLogsOfUser(this.props.userId);
        if (appState.workLogState.hasUserStartDay(this.props.userId)) {
            await appState.slotsState.generateSlotCurrent();
            if (!util.hasValue(appState.slotsState.slotCurrent.id)) {
                await appState.slotsState.generateSlotCurrentNotInMain();
            }
            appState.slotsState.generateWorkOrderCurrent();
            let workLogsOfUser = appState.workLogState.workLogsOfUser;
            let lastWorkLogTime = util.getMoment(workLogsOfUser?.[0]?.time);
            let time = util.getMoment(this.timeEndJourney);
            let now = util.getMoment();
            if (lastWorkLogTime.format("YYYYMMDDHHmm") > time.format("YYYYMMDDHHmm")) {
                time = util.getMoment(workLogsOfUser?.[0]?.time);
            } else if (time.format("YYYYMMDDHHmm") > now.format("YYYYMMDDHHmm")) {
                time = util.getMoment();
            } else if (util.getMoment(appState.workLogState.workLogStart.momentStart).format("YYYYMMDDHHmm") > time.format("YYYYMMDDHHmm")) {
                time = util.getMoment(appState.workLogState.workLogStart.momentStart);
            }
            appState.slotsState.date = util.getMoment(appState.workLogState.workLogStart.momentStart).format("YYYY-MM-DD");
            let slotsDiaDeInicio = await appState.slotsState.loadSlotsTodayImpl();
            let userId = this.props.userId;
            let slotCurrent = appState.slotsState.getCurrentSlot();
            this.log({ finalizarJornadaDemonio: 1, time, slotsDiaDeInicio });
            if (appState.workLogState.isThereSomeSlotInProgress()) {
                if (util.hasValue(slotCurrent.id)) {
                    try {
                        this.log({ slotCurrent });
                        await appState.workLogState.pauseWorkOrdersDaemon(userId, slotCurrent.clientId, slotCurrent.orderId, slotCurrent, time);
                        time.add(1, 'minutes');
                        let causeDontSign = [{
                            code: "0",
                            id: "0",
                            type: "dontSign",
                            value: t("Finalizado manualmente desde BO")
                        }];
                        await appState.workLogState.insertDontSignVisit(userId, slotCurrent.clientId, slotCurrent.orderId, slotCurrent, causeDontSign, '', time);
                        time.add(1, 'minutes');
                        let dataToSave = {
                            satisfaction: -1,
                            startDayId: appState.workLogState.workLogStart?.id,
                            clientSign: false,
                        };
                        let dontSign = appState.slotsState.getWorkLogsDontSignFromThisSlot(slotCurrent);
                        if (util.hasValue(dontSign?.value)) {
                            dataToSave["dontSign"] = [dontSign]
                        }
                        this.log({ dataToSave });
                        await appState.workLogState.endClient(userId, slotCurrent.clientId, slotCurrent.orderId, slotCurrent, dataToSave, util.formatToISOString(time), t);
                    } catch (e) {
                        let gqlErrors = new GraphException().getErrorsFromException(e);
                        this.log({ gqlErrors });
                        this.gqlErrors = gqlErrors;
                    }
                }
            }
            try {
                for (let slotDaemon of slotsDiaDeInicio) {
                    if (!slotDaemon.isStarted && slotDaemon.id !== slotCurrent.id) {
                        let slotModel = new SlotModel();
                        slotModel.hidrate(slotDaemon);
                        if (!util.hasValue(slotDaemon.scheduledTimeUntil)) {
                            slotModel.scheduledTime = "";
                            slotModel.userId = "";
                        } else {
                            slotModel.scheduledTime = util.formatToISOString(util.getMoment());
                        }
                        await slotModel.persist();
                    }
                }
            } catch (e) {
                let gqlErrors = new GraphException().getErrorsFromException(e);
                this.log({ gqlErrors });
                this.gqlErrors = gqlErrors;
            }
            try {
                time.add(1, 'minutes');
                let extra = t("Finalizado manualmente desde BO")
                await appState.workLogState.endDay(userId, util.formatToISOString(time), extra);
            } catch (e) {
                let gqlErrors = new GraphException().getErrorsFromException(e);
                this.log({ gqlErrors });
                this.gqlErrors = gqlErrors;
            }
            if (this.props.row[CheckWorkDaysModel.END_DAY] == null) {
                this.props.row[CheckWorkDaysModel.END_DAY] = {};
                this.props.row[CheckWorkDaysModel.END_DAY].action = CheckWorkDaysModel.END_DAY;
                this.props.row[CheckWorkDaysModel.END_DAY].time = util.formatToISOString(time);
            }
            await this.props.reload();
        }
        this.setState({ loading: false, openModal: false });
    }

    handleInputChange(event) {
        this.timeEndJourney = event.target.value;
        let totalTime = util.calcularDuracionEntreHoras(appState.workLogState.workLogStart.momentStart, event.target.value);
        this.setState({ totalTime })
    }

    render() {
        this.propsUtil = new PropsUtil(this.props);
        const t = translate;
        let workLogsOfUser = appState.workLogState.workLogsOfUser;
        let lastWorkLogTime = workLogsOfUser?.[0]?.time;
        let totalTimeSinceLastWorkLog = util.calcularDuracionEntreHoras(appState.workLogState.workLogStart.momentStart, lastWorkLogTime);
        return (
            <>
                {appState.workLogState.hasUserStartDay(this.props.userId) &&
                <VsfButtonNew
                    onClick={() => this.setState({ openModal: true })}
                    label={t("Cerrar jornada del técnico por problemas técnicos")}
                    className="btn btn--loader btn--max"
                />
                }
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <VsfModal
                    isOpen={this.state.openModal} onCloseModal={() => this.setState({ openModal: false })}
                    className="center top-to-bottom-client">
                    <div className="modal-push">
                        <div className="modal-head visible-text">
                            <div className="d-flex align-items-center">
                                <div className="col-12 text-left">
                                    <div className="modal-title">
                                        <p>{t("Cerrar Jornada del técnico por problemas técnicos")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body-filters">
                            <div className="c-modal-info__cell__body">
                                <DateInputHoursMobiscroll
                                    prefix={"fas fa-calendar-alt"}
                                    value={this.timeEndJourney}
                                    minDate={lastWorkLogTime}
                                    title={t("Introduzca la hora")}
                                    onChange={(e) => this.handleInputChange(e)}
                                    name={"scheduledTime"} type={"text"}
                                    allowEmptyDate={true} />
                            </div>
                            {(this.state.totalTime > CheckWorkDaysModel.MAX_TIME_JOURNEY &&
                                totalTimeSinceLastWorkLog < CheckWorkDaysModel.MAX_TIME_JOURNEY) &&
                            <span
                                className={"text-danger"}><i
                                className="fas exclamationTriangleIcon" />{t('La jornada no puede ser superior a 16 horas')}</span>
                            }

                        </div>

                        <div className="modal-footer ">
                            <div className="mt-4">
                                <div className="d-flex justify-content-end mb-2">
                                    <VsfButtonNew
                                        label={t("Cerrar Jornada")}
                                        className="btn btn--loader"
                                        loading={this.state.loading}
                                        disabled={(!util.hasValue(this.timeEndJourney) ||
                                            (this.state.totalTime > CheckWorkDaysModel.MAX_TIME_JOURNEY &&
                                                totalTimeSinceLastWorkLog < CheckWorkDaysModel.MAX_TIME_JOURNEY))}
                                        loadedError={this.state.loadingError}
                                        onClick={() => this.finalizarJornadaDemonio()}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>
                </VsfModal>
            </>
        )
    }
}

export default UserEndJourney
