import moment from './moment';
import DocumentModel from "../models/DocumentModel";
import AppLogger from "./AppLogger";
import LocalStorage from "../network/LocalStorage";
import styled from '@emotion/styled'
import React from "react";
import _ from "lodash";

class Util {

    isObjectEqual(a, b) {
        return _.isEqual(a, b);
    }

    /**
     * La duración es de tipo 01:00 (hh:mm)
     * @param strDuracion
     * @returns {*}
     */
    getDurationFromApi(strDuracion) {
        let partes = strDuracion.split(":");
        let duracion = 0;
        try {
            duracion = parseInt(partes[0]) * 60 + parseInt(partes[1]);
        } catch (e) {
        }
        return duracion + " min";
    }

    /**
     * Devuelve las fechas en formato "8/11/17"
     * strDate es en formato AAAAMMDD
     */
    getDateWithoutHours(strDate) {
        let mDate = moment(strDate, 'YYYYMMDD');
        let result = mDate.format("DD/MM/YYYY");
        return result;
    }

    objectFlip(obj) {
        const ret = {};
        Object.keys(obj).forEach(key => {
            ret[obj[key]] = key;
        });
        return ret;
    }

    /**
     *
     * @param isoDate En formato ISO: 2019-06-20T12:46:19Z
     */
    localizeIsoDate(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate, 'YYYY-MM-DD');
            result = mDate.format("DD/MM/YYYY");
        }
        return result;
    }

    /**
     * Devuelve una cadena con el numero "numero" y con el tamaño size añadiendo 0 al inicio para conseguir el tamaño
     * Ejemplo numDigitos(5,4)=> "0005"
     * @param numero
     * @param size
     * @returns {*}
     */
    numDigitos(numero, size) {
        let i;
        let res = numero;
        for (i = 0; i < size; i++) res = "0" + res;
        return this.right(res, size);
    }


    formatDecimal(number) {
        if (number == null) {
            number = 1;
        }
        return parseFloat((number.toString()).replace(",", "."));
    }

    numberFormat(number) {
        let result = 0;
        try {
            result = number.toFixed(2);
        } catch (e) {
            //this.log({numberFormatError: e, number})
        }
        return result;
    }

    right(cadena, size) {
        return cadena.substring(cadena.length - size);
    }

    left(cadena, size) {
        return cadena.substr(0, size);
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    // Retorna un entero aleatorio entre min (incluido) y max (excluido)
// ¡Usando Math.round() te dará una distribución no-uniforme!
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    //timezone="Europe/Madrid";
    //timezone="Europe/Lisbon";
    getTimezone() {
        let localStorage = new LocalStorage();
        let timezone = localStorage.getItem('currentTimeZone');
        //this.log({getTimezone:1,getUser:1,timezone});
        if (!this.hasValue(timezone)) {
            timezone = this.getTimezoneBrowser();
        }

        return timezone;
    }

    getTimezoneBrowser() {
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (!this.hasValue(timezone)) {
            timezone = "Europe/Madrid";
        }
        return timezone;
    }

    //timezone="Europe/Madrid";
    //timezone="Europe/Lisbon";
    getLang() {
        let localStorage = new LocalStorage();
        let timezone = localStorage.getItem('currentLanguage');
        if (!this.hasValue(timezone)) {
            timezone = "es";
        }
        return timezone;
    }

    //timezone="Atlantic/Azores";
    //timezone="America/Toronto";
    //PT	+3843-00908	Europe/Lisbon	mainland	+00:00	+01:00
    //PT	+3238-01654	Atlantic/Madeira	Madeira Islands	+00:00	+01:00
    //PT	+3744-02540	Atlantic/Azores	Azores	−01:00	+00:00
    /**
     * Deveulve la fecha hasta el segundo transformado al timezone del usuario
     * @param isoDate En formato ISO: 2019-06-20T12:46:19Z
     */
    localizeIsoDateHour(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate);
            result = mDate.tz(this.getTimezone()).format("DD-MM-YYYY HH:mm:ss");
        }
        //return isoDate + " **" + this.getTimezone() + " ** " + result;
        return result;
    }

    localizeIsoDateMinutes(isoDate) {
        let result = "";
        if (this.hasValue(isoDate)) {
            let mDate = moment(isoDate);
            result = mDate.tz(this.getTimezone()).format("DD-MM-YYYY HH:mm");
        }
        //return isoDate + " **" + this.getTimezone() + " ** " + result;
        return result;
    }

    /**
     * Deveulve la fecha hasta el segundo transformado al timezone del usuario
     * @param isoDate En formato ISO: 2019/02/28T12:46:19Z
     */
    localizeBDDateHour(isoDate) {
        let result = "";
        // this.log({ localizeIsoDateHour: "", isoDate });
        if (this.hasValue(isoDate)) {
            result = this.getMoment(isoDate).format("YYYY-MM-DD") + "T";
            result += this.getMoment(isoDate).format("HH:mm:ss") + "Z";
        }
        return result;
    }

    getArray(inicio, numElementos) {
        let result = [];
        for (let i = inicio; i <= numElementos; i++) {
            result.push(i);
        }
        return result;
    }

    getDelim(cadena, delim, num) {
        let res = "";
        try {
            let v = cadena.split(delim);
            res = v[num];
        } catch (e) {
        }
        return res;
    }

    left(cadena, size) {
        let result = "";
        if (cadena != null) {
            if (size > 0) {
                if (cadena.length >= size) {
                    result = cadena.substring(0, size);
                }
            }
        }
        return result;
    }

    rad2degr(rad) {
        return rad * 180 / Math.PI;
    }

    degr2rad(degr) {
        return degr * Math.PI / 180;
    }

    /**
     * @param latLngInDeg array of arrays with latitude and longtitude
     *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
     *   [longtitude2] ...]
     *
     * @return array with the center latitude longtitude pairs in
     *   degrees.
     */
    getGeoLatLngCenter(latLngInDegr) {
        let LATIDX = "geo_latitude";
        let LNGIDX = "geo_longitude";
        let sumX = 0;
        let sumY = 0;
        let sumZ = 0;

        for (let i = 0; i < latLngInDegr.length; i++) {
            let lat = this.degr2rad(latLngInDegr[i][LATIDX]);
            let lng = this.degr2rad(latLngInDegr[i][LNGIDX]);
            // sum of cartesian coordinates
            sumX += Math.cos(lat) * Math.cos(lng);
            sumY += Math.cos(lat) * Math.sin(lng);
            sumZ += Math.sin(lat);
        }

        let avgX = sumX / latLngInDegr.length;
        let avgY = sumY / latLngInDegr.length;
        let avgZ = sumZ / latLngInDegr.length;

        // convert average x, y, z coordinate to latitude and longtitude
        let lng = Math.atan2(avgY, avgX);
        let hyp = Math.sqrt(avgX * avgX + avgY * avgY);
        let lat = Math.atan2(avgZ, hyp);

        return ({geo_latitude: this.rad2degr(lat), geo_longitude: this.rad2degr(lng)});
    }

    getGeoDelta(center, latLngInDegr) {
        let LATIDX = "geo_latitude";
        let LNGIDX = "geo_longitude";
        let result = {};
        result[LATIDX] = 0.001;
        result[LNGIDX] = 0.001;
        for (let i = 0; i < latLngInDegr.length; i++) {
            let latDiff = Math.abs(latLngInDegr[i][LATIDX] - center[LATIDX]);
            let longDiff = Math.abs(latLngInDegr[i][LNGIDX] - center[LNGIDX]);
            if (result[LATIDX] < latDiff) {
                result[LATIDX] = latDiff;
            }
            if (result[LNGIDX] < longDiff) {
                result[LNGIDX] = longDiff;
            }
        }
        return result;
    }

    isEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    isPhone(phoneNumber) {
        let phoneno = /^\+?[0-9]{9,12}$/;
        let result;
        if (phoneNumber == null) phoneNumber = "";
        if (phoneNumber.match(phoneno)) {
            result = true;
        } else {
            result = false;
        }
        console.log("isPhone " + phoneNumber + " =>" + result);
        return result;
    }

    esVacio(str) {
        let result = !this.hasValue(str);
        return result;
    }

    hasValue(str) {
        let result = true;
        if (str == null) {
            result = false;
        } else if (str === "") {
            result = false;
        }
        return result;
    }

    /**
     * Devuelve true si el primer parametro es mayor que el segundo parametro
     * @param floatDB
     * @param floatJS
     * @returns {boolean}
     */
    compareFloatStrings(mayorFloatString, menorFloatString) {
        let mayor1 = this.getDelim(mayorFloatString, ".", 0);
        let mayor2 = this.getDelim(mayorFloatString, ".", 1);
        let mayor3 = this.getDelim(mayorFloatString, ".", 2);
        let menor1 = this.getDelim(menorFloatString, ".", 0);
        let menor2 = this.getDelim(menorFloatString, ".", 1);
        let menor3 = this.getDelim(menorFloatString, ".", 2);
        let resultMayor = this.numDigitos(mayor1, 4) + this.numDigitos(mayor2, 4) + this.numDigitos(mayor3, 4);
        let resultMenor = this.numDigitos(menor1, 4) + this.numDigitos(menor2, 4) + this.numDigitos(menor3, 4);
        return parseInt(resultMayor) > parseInt(resultMenor);
    }


    getNumSeg1970() {
        return Math.round(Date.now() / 1000)
    }

    getSecondsForMs(sec) {
        return 1000 * sec;
    }

    getMinutsForMs(min) {
        return min * (1000 * 60);
    }

    getString(str) {
        let result = "";
        if (this.hasValue(str)) {
            result += str;
        }
        return result;
    }

    firstUpperCase(string) {
        let result = "";
        try {
            result = string[0].toUpperCase() + string.substring(1);
        } catch (e) {
        }
        return result;
    }

    firstLowerCase(string) {
        let result = "";
        try {
            result = string[0].toLowerCase() + string.substring(1);
        } catch (e) {
        }
        return result;
    }

    removeDoubleSpaces(text) {
        let result = text.replace(/([\ \t]+(?=[\ \t])|^\s+|\s+$)/g, '');
        return result;
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    getCookie(name) {
        let start = document.cookie.indexOf(name + "=");
        let len = start + name.length + 1;
        if ((!start) && (name != document.cookie.substring(0, name.length))) return null;
        if (start == -1) return null;
        let end = document.cookie.indexOf(";", len);
        if (end == -1) end = document.cookie.length;
        return unescape(document.cookie.substring(len, end));
    }

    /**
     * Convierte una cadena en un entero y lo devuelve
     * @param valor
     * @returns {number}
     */
    str2int(valor) {
        let res = 0;
        if (valor === null) valor = "0";
        try {
            res = parseInt(valor);
            if (isNaN(res)) {
                res = 0;
            }
        } catch (e) {
            res = 0;
        }
        return res;
    }

    /**
     * Devuelve el ID cero en formato GUID
     * @returns {string}
     */
    getZeroIdIdentifierGUID() {
        return "00000000-0000-0000-0000-000000000000";
    }

    /*
     ** Dado un numero en español con decimales devuelve el float asociado. Por ejemplo 4,4 devuelve float(4.4)
     */
    str2float(valor) {
        let res = 0.0;
        if (valor == null) {
            valor = "0";
        }
        valor = this.getString(valor);
        try {
            valor = valor.replace(",", ".");
            res = parseFloat(valor);
            if (isNaN(res)) {
                res = 0.0;
            }
        } catch (e) {
        }
        return res;
    }

    toPascalCase(string) {
        return `${string}`
            .replace(new RegExp(/[-_]+/, 'g'), ' ')
            .replace(new RegExp(/[^\w\s]/, 'g'), '')
            .replace(
                new RegExp(/\s+(.)(\w+)/, 'g'),
                ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
            )
            .replace(new RegExp(/\s/, 'g'), '')
            .replace(new RegExp(/\w/), s => s.toUpperCase());
    }

    trim(valor) {
        return valor.trim();
    }

    perteneceLista(valor, lista) {
        let res = false;
        let pos = (";" + lista + ";").indexOf(";" + valor + ";");
        if (pos != -1) {
            res = true;
        }
        return res;
    }

    perteneceListaComa(valor, lista) {
        let res = false;
        let pos = ("," + lista + ",").indexOf("," + valor + ",");
        if (pos != -1) {
            res = true;
        }
        return res;
    }

    getDocumentPhoto(foto) {
        let photo;
        if (foto != null) {
            photo = new DocumentModel();
            photo.hidrate(foto);
        }
        return photo
    }

    perteneceA(valor, lista) {
        let res = false;
        if (lista != null) {
            if (valor === '-1' && lista === '-1') {
                res = true;
            } else {
                let pos = (lista).indexOf(valor, 0);
                if (pos !== -1 && lista !== '-1') {
                    res = true;
                }

            }
        }
        return res;
    }

    arrayModelToPlainObjects(arr) {
        return arr.map((a) => {
            try {
                return a.toPlainObject()
            } catch (e) {
                this.log({"arrayModelToPlainObjects": 1, e})
            }
        })
    }


    /**
     * //date es de la forma 2019-06-09T23:00:00Z
     * Para verlo en mes, cambiar el return por 8 o por 16
     * @param date
     * @returns {number}
     */
    getHourFromDatetime(date) {
        //return 16;
        return this.getMoment(date).format("H");
    }

    getDateFromDatetime(date) {
        return this.getMoment(date).format("YYYY-MM-DD");
    }

    /**
     *
     * @param date Debe estar en formato Universal (acabado en Z). No funciona si se le pasa una fecha por ejemplo 2019-09-10
     * @returns {*}
     */
    getMoment(date) {
        moment.suppressDeprecationWarnings = true;
        // this.log({ getTimezone: this.getTimezone(), date, moment: moment(date).tz(this.getTimezone()) })
        return moment(date).tz(this.getTimezone());
    }

    /**
     * A partir de una fecha sin timezone (2013-11-18T11:55:00) deveulve un objeto moment con la fecha en el timezone seleccionado.
     * @param date
     * @returns {*}
     */
    getMomentFromDateWithoutTimezone(date) {
        return moment.tz(date, this.getTimezone());
    }

    inArray(valor, array) {
        let result = true;
        if (array.indexOf(valor) == -1) {
            result = false;
        }
        return result;
    }

    /**
     * Devuelve true si una fecha es valida
     * @param d
     * @returns {boolean}
     */
    isValidDate(d) {
        return d instanceof Date && !isNaN(d) && d != null;
    }

    /**
     * A partir de un formato moment devuelve la fecha en ISOString (2013-11-18T11:55:00Z)
     */
    formatToISOString(moment) {
        return this.left(moment.toISOString(), 19) + "Z";
    }

    deepCloneOBJ(object) {
        return JSON.parse(JSON.stringify(object));
    }

    isObjectEmptyOrNull(obj) {
        let result = false;
        if (obj == null) {
            result = true;
        } else if (JSON.stringify(obj) === "{}") {
            result = true;
        }
        return result;
    }

    hasObjectValue(obj) {
        return !this.isObjectEmptyOrNull(obj);
    }

    /**
     * A partir de una feche en formato del navegador
     * (por ejemplo Fri Sep 13 2019 15:00:00 GMT+0200 (hora de verano de Europa central))
     * devuelve 2019-09-13T15:00:00
     * @param today
     * @returns {string}
     */
    getDateStringFromDateLocalizedBroser(today) {
        let dd = this.numDigitos(today.getDate(), 2);
        let mm = this.numDigitos(today.getMonth() + 1, 2); //Enero es 0!
        let yyyy = today.getFullYear();

        let hours = this.numDigitos(today.getHours(), 2);
        let minutes = this.numDigitos(today.getMinutes(), 2);
        let seconds = this.numDigitos(today.getSeconds(), 2);

        return "" + yyyy + "-" + mm + "-" + dd + "T" + hours + ":" + minutes + ":" + seconds;
    }

    ordenarFechasAsc(array, campo) {
        return array?.slice().sort((a, b) => {
            let key1 = this.getMoment(a?.[campo]).format("YYYYMMDD");
            let key2 = this.getMoment(b?.[campo]).format("YYYYMMDD");
            if (key1 < key2) {
                return -1;
            }
            if (key2 > key1) {
                return 1;
            }
            return 0;
        });
    }

    ordenarFechasDesc(array, campo) {
        return array?.slice().sort((a, b) => {
            let key1 = this.getMoment(a?.[campo]).format("YYYYMMDD");
            let key2 = this.getMoment(b?.[campo]).format("YYYYMMDD");
            if (key1 > key2) {
                return -1;
            }
            if (key2 < key1) {
                return 1;
            }
            return 0;
        });
    }

    ordenarFechasFormato(array, campo, format) {
        return array?.slice().sort((a, b) => {
            let key1 = this.getMoment(a?.[campo]).format(format);
            let key2 = this.getMoment(b?.[campo]).format(format);
            if (key1 < key2) {
                return -1;
            }
            if (key2 > key1) {
                return 1;
            }
            return 0;
        });
    }

    getModalClasses() {
        return {
            push: "modal-push",
            body: "modal-body",
            footer: "modal-footer",
            head: "modal-head"
        };
    }

    getDictSingleFromArray(arr) {
        let result = {};
        if (arr?.length > 0) {
            let fieldName = "id";
            for (let obj of arr) {
                let key = obj[fieldName];
                result[key] = obj;
            }
        }
        return result;
    }

    getDictMultipleFromArray(arr, fieldName) {
        let result = {};
        if (arr?.length > 0) {
            for (let obj of arr) {
                let key = obj[fieldName];
                result[key] = obj;
            }
        }
        return result;
    }

    getDictMultipleFromArrayToArray(arr, fieldName) {
        let result = {};
        for (let obj of arr) {
            let key = obj[fieldName];
            if (result[key] == null) {
                result[key] = [];
            }
            result[key].push(obj)
        }
        return result;
    }

    calcularDuracionEntreHoras(inicio, fin) {
        let inicioMoment = this.getMoment(inicio);
        let finMoment = this.getMoment(fin);
        let total = finMoment.diff(inicioMoment, 'hours', true);
        return parseFloat(total).toFixed(2);
    }

    cancularDuracionEntreHorasEnMinutos(inicio, fin) {
        let inicioMoment = this.getMoment(inicio);
        let finMoment = this.getMoment(fin);
        let total = finMoment.diff(inicioMoment, 'hours', true);
        return this.calcularDecimalAHorasMinutos(parseFloat(total).toFixed(2));
    }

    calcularDecimalAHorasMinutos(duracion) {
        let result = 0 + " min";
        if (duracion) {
            let horas = duracion.toString().split(".")[0];
            let minutos = duracion.toString().split(".")[1];
            result = (parseInt(horas) !== 0) ? horas + "h " : "";
            result += minutos ? parseInt(((parseInt(minutos) * 60) / 100)) + " min" : "";
        }
        return result;
    }

    formatBytes(bytes, decimals = 2) {
        if (bytes == 0) return '0 Bytes';
        var k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getFileExtension(path) {
        let extension = path.split('.').pop();
        return extension;
    }

    getIconoStyled(icono, color) {
        let Icono = styled.i`
          &:before {
            content: '\\${icono}';
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            color: #fff;
            font-size: 0.7rem;
            font-style: initial;
            background-color: ${color};
            padding: 0.2rem;
            min-width: 19px;
            text-align: center;
          }
        `;
        if (this.hasValue(icono) && !icono?.startsWith("fa")) {
            return <Icono/>;
        } else {
            return <span style={{
                color: "#FFF", fontSize: "0.7rem", backgroundColor: color, padding: '0.2rem',
                minWidth: '19px', textAlign: 'center'
            }}
                         className={icono}/>
        }
    }

    /**
     * A partir de un fichero tipo /carpeta/fichero.txt obtiene el campo fichero
     * @param path
     */
    getFileName(path) {
        return this.getDelim(path, "/", -1);
    }

    /**
     * Devuelve la fecha actual en formato ISOString con time offset 0 (2013-11-18T11:55:00Z)
     * @returns {string}
     */
    getDateIsoNow() {
        let mDate = this.getMoment(Date.now());
        return this.formatToISOString(mDate);
    }

    /**
     * Devuelve true si el userAgent del navegador pertenece a un dispositivo movil;
     * @returns {boolean}
     */
    isUserAgentMobile() {
        let userAgent = window.navigator.userAgent;
        // this.log({ isUserAgentMobile: 1, userAgent });
        let regExpCode = new RegExp(/Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/, 'gi');
        return regExpCode.test(userAgent);
    }

    mapObject(obj, f) {
        let result = [];
        if (obj != null) {
            result = Object.keys(obj).map(key => {
                return {item: obj[key], key}
            }).map(({item, key}) => f(item, key));
        }
        // this.log({ mapObject: 1, result, obj })
        return result;
    }

    getDictSingleFromArrayFieldName(arr, fieldName) {
        if (arr === undefined) {
            throw new Exception("getDictSingleFromArray with null parameter");
        }
        let result = {};
        for (let obj of arr) {
            let key = obj[fieldName];
            if (result[key] == null) {
                result[key] = obj;
            }
        }
        return result;
    }

    limpiaString(texto) {
        let result = "";
        if (this.hasValue(texto)) {
            texto = texto.replace(/[áàâãªä]/gi, "a");
            texto = texto.replace(/[ÁÀÂÃÄ]/gi, "a");
            texto = texto.replace(/[éèêë]/gi, "e");
            texto = texto.replace(/[ÉÈÊË]/gi, "e");
            texto = texto.replace(/[íìîï]/gi, "i");
            texto = texto.replace(/[ÍÌÎÏ]/gi, "i");
            texto = texto.replace(/[óòôõºö]/gi, "o");
            texto = texto.replace(/[ÓÒÔÕÖ]/gi, "o");
            texto = texto.replace(/[úùûü]/gi, "u");
            texto = texto.replace(/[ÚÙÛÜ]/gi, "u");
            texto = texto.replace(/[ñÑ]/gi, "n");
            texto = texto.replace(/ /gi, "");
            texto = texto.toLowerCase();
            texto = texto.trim();
            // log({ limpiaString: 2, texto });
            result = texto.toLowerCase();
        }
        return result
    }

    /*async*/
    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    arrToDictionary(vArticleIds) {
        let result={};
        for(let item of vArticleIds) {
            result[item]=item;
        }
        return result;
    }
}

export default new Util();
