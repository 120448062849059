import FetchProxy from "../network/FetchProxy";
import config from "../config/config";
import AppLogger from "../util/AppLogger";
import util from '../util/Util'
import LocalStorage from "../network/LocalStorage";
import appState from "../state/AppState";
import GraphException from "../network/GraphException";

let messages = {};

export default class LoadTranslations {


    getLang() {
        let localStorage= new LocalStorage();
        let userLocale = localStorage.getItem('currentLanguage');
        if (util.hasValue(userLocale)) {
            return userLocale
        } else
            return "es";

    }

    async jsonTranslationsApi() {
        let httpApi = new FetchProxy();
        let result = {};
        let responseJson;
        try {
            httpApi.withAuthorization=false;
            let response = await httpApi.fetchUrlGet(config.apiRestHostBaseUrl + "/translations?lang=" + this.getLang());
            // this.log(config.apiRestHostBaseUrl + "/translations?lang=" + this.getLang());
            if (response.status == 200) {
                responseJson = await response.json();
                for (let obj of await responseJson) {
                    if (result[this.limpiaString(obj.label)] == null) {
                        result[this.limpiaString(obj.label)] = obj.message;
                    }
                }
            }
        } catch (e) {
            this.log({TrasnlationEXCEPTION:1,e});
            appState.maintenance = true;
            throw new GraphException({
                message: "Error traducciones",
                errors: []
            });
        }
        return result;
    };

    limpiaString(archivo) {
        archivo = archivo.replace(/[áàâãªä]/gi, "a");
        archivo = archivo.replace(/[ÁÀÂÃÄ]/gi, "a");
        archivo = archivo.replace(/[éèêë]/gi, "e");
        archivo = archivo.replace(/[ÉÈÊË]/gi, "e");
        archivo = archivo.replace(/[íìîï]/gi, "i");
        archivo = archivo.replace(/[ÍÌÎÏ]/gi, "i");
        archivo = archivo.replace(/[óòôõºö]/gi, "o");
        archivo = archivo.replace(/[ÓÒÔÕÖ]/gi, "o");
        archivo = archivo.replace(/[úùûü]/gi, "u");
        archivo = archivo.replace(/[ÚÙÛÜ]/gi, "u");
        archivo = archivo.replace(/[ñÑ]/gi, "n");
        archivo = archivo.replace(/ /gi, "");
        archivo = archivo.toLowerCase();
        // this.log({ limpiaString: 1, archivo });
        return archivo;

    }

    async loadMessages() {
        messages = await this.jsonTranslationsApi();
    }

    getMessages() {
        return messages;
    }

    setMessages(label, message) {
        // this.log({ setMessages: 1, label: this.limpiaString(label), message, messages });
        if (messages[this.limpiaString(label)] == null) {
            messages[this.limpiaString(label)] = message;
        }
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}
