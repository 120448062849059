import React, {Component} from 'react';
import {observer} from "mobx-react";
import translate from "../translator/translate";
import {withRouter} from "react-router-dom";
import PropsUtil from "../util/PropsUtil";
import InfoBlock from "../components/InfoBlock";
import util from '../util/Util';
import AppLogger from "../util/AppLogger";
import appState from '../state/AppState';
import ChangesWithValidationsModel from "../models/ChangesWithValidationsModel";
import {computed, observable} from "mobx";
import ClientModel from "../models/ClientModel";
import CheckboxUniqueComponent from "./fields/CheckboxUniqueComponent";
import GroupOfField from "./layout/GroupOfField";

@observer
class ValidatesFormTable extends Component {

    constructor(props) {
        super(props);
        this.props.onRef(this);
    }

    @computed get mobxListado() {
        return appState.changesWithValidationsState.validatesForm;
    }

    setMobxListado(newValue) {
        appState.changesWithValidationsState.validatesForm = newValue;
    }

    @observable clients = {};

    getModelTable() {
        return new ChangesWithValidationsModel();
    }

    async componentDidMount() {
        await this.loadTable();
        await this.loadDataGuid();
    }

    async loadDataGuid() {
        await this.loadStoreId();
    }

    async loadStoreId() {
        let guids = []
        for (let obj of this.mobxListado) {
            if (this.startWith(Object.keys(JSON.parse(obj.changes))[0], "storeId")) {
                guids.push(Object.values(JSON.parse(obj.changes))[0]);
            }
        }
        let clientQuery = new ClientModel();
        clientQuery.filters = [
            { "fieldName": "id", "fieldValue": guids.join(','), "filterOperator": "STRIN" },
        ];
        let clients = await clientQuery.find();
        this.clients = util.getDictSingleFromArray(clients);
        this.clients[this.props.graphDataMainType?.storeId] = this.props.graphDataMainType?.store;
    }

    async loadTable() {
        let validatesQuery = new ChangesWithValidationsModel();
        validatesQuery.approved = ChangesWithValidationsModel.PENDING;
        // TODO si queremos que ordene por el campo
        // validatesQuery.orderBy = "changes";
        validatesQuery.orderBy = "updatedAt";
        validatesQuery.orderMode = "DESC";
        validatesQuery.modelId = this.props.graphDataMainType.id;
        let validates=[];
        if (util.hasValue(this.props.graphDataMainType.id)) {
            validates = await validatesQuery.find();
        }
        this.setMobxListado(util.arrayModelToPlainObjects(validates));
        let changesWithValidatiosForms = this.mobxListado.filter(obj => !this.startWith(Object.keys(JSON.parse(obj.changes))[0], "document"))
        let changesWithValidatiosImages = this.mobxListado.filter(obj => this.startWith(Object.keys(JSON.parse(obj.changes))[0], "document"))
        appState.changesWithValidationsState.formValidations = changesWithValidatiosForms.length > 0;
        appState.changesWithValidationsState.imageValidations = changesWithValidatiosImages.length > 0;

    }

    updateInputEventConfig(e, validate, name) {
        appState.layoutState.formWithoutChanges = false;
        let value = JSON.parse(validate.changes)[name]
        let relation = {};
        if (this.startWith(e.target.name, "approved")) {
            if (validate.approved === ChangesWithValidationsModel.PENDING || validate.approved === ChangesWithValidationsModel.REJECTED) {
                validate.approved = ChangesWithValidationsModel.APPROVED;
                if (name === "storeId") {
                    relation = this.clients[value];
                }
                this.props.onChangeInput(name, value, relation);
                for (let validateMbx of this.mobxListado) {
                    let nameMbx = Object.keys(JSON.parse(validateMbx.changes))[0];
                    if (name === nameMbx) {
                        if (validateMbx.id !== validate.id) {
                            validateMbx.approved = ChangesWithValidationsModel.REJECTED;
                            validateMbx.hasChange = true;
                        }
                    }
                }
            } else {
                if (name === "storeId") {
                    relation = this.clients[this.props.previousRowForDiscard?.[name]];
                }
                validate.approved = ChangesWithValidationsModel.PENDING;
                this.props.onChangeInput(name, this.props.previousRowForDiscard?.[name], relation);
            }
        } else {
            if (name === "storeId") {
                relation = this.clients[this.props.previousRowForDiscard?.[name]];
            }
            if (validate.approved === ChangesWithValidationsModel.REJECTED) {
                validate.approved = ChangesWithValidationsModel.PENDING
            } else {
                validate.approved = ChangesWithValidationsModel.REJECTED;
                if (this.props.graphDataMainType?.[name] !== this.props.previousRowForDiscard?.[name]) {
                    this.props.onChangeInput(name, this.props.previousRowForDiscard?.[name], relation);
                }
            }
        }
        appState.changesWithValidationsState.validateChanges[name] = validate;
    }

    getTitle(nameField) {
        let model = this.props.model;
        return model.getLabelFromFieldName(nameField);
    }

    getValue(key, validate) {
        let changeJson = JSON.parse(validate.changes)
        let result = appState.typifiedState.getValue(changeJson[key]) || changeJson[key];
        if (key === "storeId") {
            result = this.clients[changeJson[key]]?.name;
        }
        return result;
    }

    render() {
        const t = translate;
        this.propsUtil = new PropsUtil(this.props);
        let changesWithValidatiosForms = this.mobxListado.filter(obj => !this.startWith(Object.keys(JSON.parse(obj.changes))[0], "document"))
        return (
            <InfoBlock componentObject={this}>
                {changesWithValidatiosForms?.length > 0 &&
                <>
                    <GroupOfField title={t('Validaciones')}
                                  subtitle={t('Pendientes')}
                                  icon="fas fa-file-import">
                        <div className="col-12">
                            <div className="validatesFormTable">
                                {changesWithValidatiosForms.map((validate, index) => {
                                    if (!this.startWith(Object.keys(JSON.parse(validate.changes))[0], "document")) {
                                        return (
                                            <div key={index} className="validatesFormTable-changes">
                                                {Object.keys(JSON.parse(validate.changes)).map((key, index) => (
                                                    <div className="validatesFormTable-item" key={index}>
                                                        <CheckboxUniqueComponent
                                                            checked={validate.approved === ChangesWithValidationsModel.APPROVED}
                                                            name={"approved" + validate.id}
                                                            value={validate.approved}
                                                            onChange={(e) => this.updateInputEventConfig(e, validate, key)}
                                                            className={"approved"}
                                                        />
                                                        <CheckboxUniqueComponent
                                                            checked={validate.approved === ChangesWithValidationsModel.REJECTED}
                                                            name={"rejected" + validate.id}
                                                            value={validate.approved}
                                                            onChange={(e) => this.updateInputEventConfig(e, validate, key)}
                                                            className={"rejected"}
                                                        />
                                                        <div
                                                            className="validatesFormTable-title">{this.getTitle(key)}</div>
                                                        <div
                                                            className="validatesFormTable-value"> {this.getValue(key, validate)}</div>
                                                        <div
                                                            className="validatesFormTable-user">{appState.userCacheState.getUserName(validate.createdBy)}</div>
                                                        <div
                                                            className="validatesFormTable-date">{util.hasValue(validate.createdAt) ? util.localizeIsoDateMinutes(validate.createdAt) : " "}</div>
                                                    </div>
                                                ))}

                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </GroupOfField>
                </>
                }
            </InfoBlock>
        )
    }

    startWith(pathMatch, text) {
        let result = false;
        if (pathMatch.startsWith(text)) {
            result = true;
        }
        return result;
    }

    log(msg) {
        AppLogger.get().debug(msg, this);
    }
}

export default withRouter(ValidatesFormTable)
