import React, { Component } from 'react'
import { observer } from 'mobx-react'
import appState from '../state/AppState'
import CreateNewButton from '../layout/CreateNewButton'
import ProfileButton from '../layout/ProfileButton'
import ConfigButton from '../layout/ConfigButton'
import VsfLink from '../components/VsfLink'
import Select2Component from '../components/fields/Select2Component'
import { observable } from 'mobx'
import GraphException from '../network/GraphException'
import AppLogger from '../util/AppLogger'
import config from '../config/config'
import FetchProxy from '../network/FetchProxy'
import GqlErrors from './status/GqlErrors'
import VsfHeaderWebLinkStylesheet from './VsfHeaderWebLinkStylesheet'
import VsfButtonNew from './VsfButtonNew'
import AlertButton from '../layout/AlertButton'
import TypifiedValueModel from '../models/TypifiedValueModel'
import LocalStorage from '../network/LocalStorage'
import WarningButton from '../layout/WarningButton'
import InfoButton from '../layout/InfoButton'

@observer
class VsfHeader extends Component {
    nameVariableStateFilter = 'userZoneFilters'
    @observable gqlErrors = []

    constructor (props) {
        super(props)
        this.recoverFiltersForStorage()
    }

    recoverFiltersForStorage () {
        let localStorage = new LocalStorage()
        let filtersJSON = localStorage.getItemTenantId(this.nameVariableStateFilter)
        let newCurrentFilter = JSON.parse(filtersJSON)
        if (newCurrentFilter != null) {
            appState.userZoneFilters.currentFilter = newCurrentFilter?.currentFilter
            appState.userZoneFilters.currentFilterLabels = newCurrentFilter.currentFilterLabels
            appState.userZoneFilters.currentFilterOps = newCurrentFilter.currentFilterOps
        }
    }

    updateInputFilterEvent (event, op) {
        let name = event.target.name
        let value = event.target.value
        let label = event.target.label
        if (op == null) {
            op = 'EQ'
        }
        let currentFilter = { [name]: value }
        let currentFilterOps = { [name]: op }
        let currentFilterLabels = { [name]: label }
        appState.userZoneFilters = {
            currentFilter,
            currentFilterOps,
            currentFilterLabels
        }
        let localStorage = new LocalStorage()
        localStorage.setItemTenantId(this.nameVariableStateFilter, JSON.stringify(appState.userZoneFilters))
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }

    setErrors (errors) {
        this.gqlErrors = errors
    }

    async updateInputTenant (event) {
        let user = appState.loginState.user
        let value = event.target.value
        if (value !== user?.tenantId) {
            let name = event.target.name
            appState.loginState.user[name] = value
            try {
                let httpApi = new FetchProxy()
                httpApi.withAuthorization = true
                let response = await httpApi.fetchUrlPost(config.apiRestHostBaseUrl + '/changeSelectedTenantId', {
                    'Tenant': value
                })
                let responseJson = await response.json()
                if (responseJson.errorCode !== 0) {
                    appState.loginState.loadAccessTokenInfo(responseJson)
                }
                window.location.reload()
            } catch (e) {
                this.log({
                    updateInputTenantException: 1,
                    e
                })
                this.gqlErrors = new GraphException().getErrorsFromException(e)
            }
        }
    }

    render () {
        let user = appState.loginState.user

        // this.log({ tenantActual: user?.tenantId });

        return (
            <header className="web-header">
                <VsfHeaderWebLinkStylesheet />
                <div className="web-header__left">
                    <VsfButtonNew className={'header-minimizer sidebar-minimizer'} icon={'fa fa-bars'}
                        onClick={() => appState.layoutState.clickHiddenMenu()}>
                    </VsfButtonNew>
                    <VsfLink to="" className="header-brand">
                        <img src="/img/logo.png" alt="Nuddo" className="header-brand-full" />
                    </VsfLink>
                </div>
                {!appState.userState.isUserRoleClient() &&
                <div className="web-header__right">
                    <Select2Component
                        value={user?.tenantId}
                        onChange={(e) => this.updateInputTenant(e)}
                        name={'tenantId'}
                        classGroup="mt-tenant-selector"
                        inputClass="mb-0"
                        readOnly={appState.tenantState.tenantsOptions?.length <= 1}
                        isClearable={false}
                        options={appState.tenantState.tenantsOptions}
                        tenantSelector={true}
                    />
                    <Select2Component
                        inputClass="mb-0" valueContainerMore
                        isClearable={true}
                        tenantSelector={true}
                        value={appState.userZoneFilters.currentFilter.zoneAsignedId}
                        onChange={(e) => this.updateInputFilterEvent(e, 'STRIN')}
                        hideSelectedOptions={true}
                        name="zoneAsignedId"
                        classGroup="mt-tenant-selector"
                        options={appState.typifiedState.getLabelValuesForSelect(TypifiedValueModel.CODE_ZONE)}
                        multi={true}
                    />
                    <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                </div>
                }

                <div className="web-header__right__end">
                    <CreateNewButton key={user?.id} tenantId={user?.tenantId} {...this.props} />
                    <AlertButton key={user?.updatedAt} tenantId={user?.tenantId} />
                    <WarningButton key={user?.tenantId} tenantId={user?.tenantId} />
                    <InfoButton key={user?.createdBy} tenantId={user?.tenantId} />
                    <div className="header-user dropdown d-flex justify-content-end align-items-center">
                        <ProfileButton />
                        <ConfigButton key={user?.createdAt} tenantId={user?.tenantId} />
                    </div>
                </div>
            </header>
        )
    }
}

export default VsfHeader
