import AbstractModel from "./AbstractModel";
import Access from "./Access";
import AppLogger from "../util/AppLogger";

export default class UserMeModel extends AbstractModel {

    locale;
    timeZone;
    mobileNumber;
    phoneNumber;
    permission;

    nameMainType = "userMe";
    graphFindByIdOperation = "userMe";
    graphFindOperation = "userMesConnection";

    getArrayFields() {
        let resultBase = super.getArrayFields();
        let result = {
            ...resultBase,
            "locale": "",
            "mobileNumber": "",
            "phoneNumber": "",
            "timeZone": "",
            "permission": { label: 'Permiso' },
            "role": { readonly: true },
        };
        this.addRelatedTableGeneric(result, "access", "Relation", Access);
        return result;
    }

    hasQueryIdParams() {
        return false;
    }

    hasMutationIdParams() {
        return false;
    }

    isCustomizedWithWmsAccess() {
        let result=false;
        //this.log({"isCustomizedWithWmsAccess":this});
        try {
            let permissions = JSON.parse(this.permission);
            if (permissions["InternalWmsTransfers"].indexOf("U")>-1) {
                result=true;
            }
        } catch (e) {

        }
        return result;
    }

    log (msg) {
        AppLogger.get().debug(msg, this)
    }
}
