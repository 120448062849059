import React, {Component} from 'react';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import VsfLink from "../VsfLink";
import CheckboxUniqueComponent from "../fields/CheckboxUniqueComponent";
import AppLogger from "../../util/AppLogger";
import translate from "../../translator/translate";

@observer
export default class FieldListComponent extends Component {

    static defaultProps = {
        openQuickEdit: () => {
        }
    };

    static propTypes = {
        //Acción de pulsar en el botón de quick view
        openQuickEdit: PropTypes.func,
    };

    log(msg) {
        AppLogger.get().debug(msg, this);
    }

    /**
     * Obtiene el valor de esa expresión path (tipo cliente.nombre) dentro de este objeto javascript "row"
     *  pathStringWithDots puede tener valores tipo {titulo:"Mi titulo", client: { name:"nombre" } por lo que este sistema puede acceder a esas propiedades como "titulo" o como "client.name"
     * @param row
     * @param pathStringWithDots
     * @returns {string}
     */
    getValueFromPath(row, pathStringWithDots) {
        let pathNames = pathStringWithDots.split(".");
        let parcial = row;
        let i = 0;
        for (let pathName of pathNames) {
            if (parcial != null) {
                let newValue = parcial?.[pathName];
                let isArray = Array.isArray(newValue);
                //Si es un array solo le permito asignarlo para el último elemento
                if (isArray) {
                    if (i == pathNames.length - 1) {
                        parcial = parcial[pathName];
                    }
                } else {
                    parcial = parcial[pathName];
                }

            }
            i++;
        }
        return parcial;
    }

    openQuickEdit(row) {
        this.props.openQuickEdit(row);
    }

    updateInputEvent(event, row) {
        row.actionChequed = event.target.value;
        let index = this.props.listState.actionList.indexOf(row);
        if (index !== -1) {
            this.props.listState.actionList.splice(index, 1);
        } else {
            this.props.listState.actionList.push(row);
        }
    }


    render() {
        const t = translate;

        let props = this.props;
        let fields = this.props.fields;
        let urlForm = this.props.urlForm;
        let rows = this.props.listData.connection.items;
        let showColumnActions = false;
        if (props.quickEdit || props.quickView || props.withActions) {
            showColumnActions = true;
        }

        showColumnActions = true;

        return (
            <tbody>
            {rows.map(((row, index) =>
                    <tr key={props.urlForm + "-" + row.id + "-" + index}>

                        {showColumnActions &&
                        <td>
                            <div className="block-btns">
                                {props.quickView &&
                                <div onClick={() => this.openQuickEdit(row)}
                                     className="list-quickview-button block-btns__eye">
                                    <span className="far fa-eye" title={t("Ver detalle")} />
                                </div>
                                }
                                {props.quickEdit &&
                                <VsfLink to={`${urlForm}/${row.id}`} className="list-edit-button ml-2 block-btns__edit">
                                    <span className="fas fa-edit" />
                                </VsfLink>
                                }
                                {props.withActions &&
                                <CheckboxUniqueComponent
                                    checked={row.actionChequed}
                                    onChange={(e) => this.updateInputEvent(e, row)}
                                    name={"actionChequed" + index}
                                    value={row.actionChequed}
                                    className={"block-btns__checkbox"}
                                />
                                }
                            </div>
                        </td>
                        }
                        {fields.map(((field, fieldIndex) => {
                                let CustomItemComponent = field.component;
                                let showLink = field.link === true;
                                let value = this.getValueFromPath(row, field.name);
                                return (
                                    (field.visible || props.withoutCache) &&
                                    <td key={props.urlForm + "-" + row.name + " - " + field.name + " - " + fieldIndex}>
                                        {showLink ?
                                            <VsfLink to={`${urlForm}/${row.id}`} title={t("Ver detalle")}>
                                                <CustomItemComponent id={row.id} row={row} name={field.name}
                                                                     value={props.hierarchy && field.name === "code" ? row.label : value}
                                                                     urlForm={urlForm} />
                                            </VsfLink>
                                            :
                                            <CustomItemComponent id={row.id} row={row} name={field.name}
                                                                 value={value} urlForm={urlForm} />
                                        }
                                    </td>
                                )
                            }
                        ))}

                    </tr>
            ))}
            </tbody>
        );
    }

}
