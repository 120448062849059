import React from 'react';
import {observer} from 'mobx-react';
import appState from '../../state/AppState';
import ContactModel from "../../models/ContactModel";
import InputTypeComponent from "../../components/fields/InputTypeComponent";
import PropTypes from 'prop-types';
import {computed} from 'mobx';
import BaseEditableRow from "../base/BaseEditableRow";
import InfoBlock from "../../components/InfoBlock";
import CheckboxUniqueComponent from "../../components/fields/CheckboxUniqueComponent";
import OFFER from "../../config/OFFER";
import translate from "../../translator/translate";
import GqlErrors from "../../components/status/GqlErrors";

@observer
class ClientContact extends BaseEditableRow {
    static propTypes = {
        setHasSomeRowEditing: PropTypes.func, //Función que se llama cuando el elemento editable actual pasa a ser no editable
        hasSomeRowEditing: PropTypes.bool, //Algún elemento se está editando actualmente. No tiene por qué ser este elemento. Hace que se "nublen" los botones de "editar"
    };
    static defaultProps = {
        setHasSomeRowEditing: () => {
        },
        hasSomeRowEditing: false
    };

    constructor(props) {
        super(props);
        this.state = {};
        this.foreingKeyfield = "clientId";
    }

    @computed
    get mobxListado() {
        return appState.contactState.contacts;
    }

    setMobxListado(newValue) {
        appState.contactState.contacts = newValue;
    }

    getModelTable() {
        return new ContactModel();
    }

    handleInputChangeContactRole(e) {
        if (e.target.value === ContactModel.CODE_MAIL_SALES) {
            this.handleAutosuggestSelection(e.target.name, ContactModel.CODE_DEFAULT)
        } else {
            this.handleAutosuggestSelection(e.target.name, ContactModel.CODE_MAIL_SALES)
        }
    }

    /**
     * @param model
     * @returns {Promise<void>}
     */
    async rowDidSave(model, previousId) {
        await this.props.reload();
    }

    render() {
        let contact = this.props.row;
        let errorsMapped = {} //this.getGraphErrorsFromStatus();
        // let errorsMapped = this.getGraphErrorsFromStatus();
        return (
            <InfoBlock componentObject={this} wrapper="tbody">
                <GqlErrors errors={this.gqlErrors} setErrors={(errors) => this.setErrors(errors)} />
                <tr>
                    <td>
                        <div className="form-group">
                            <InputTypeComponent
                                value={contact.firstName}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"firstName"}
                                type={"text"}
                                showView={!contact.isEditing}
                                className="form-control"
                            />
                        </div>
                    </td>
                    <td>
                        <InputTypeComponent
                            value={contact.phoneNumber}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"phoneNumber"}
                            type={"text"}
                            validate={"phone"}
                            showView={!contact.isEditing}
                            className="form-control"
                             errors={errorsMapped.phoneNumber}
                        />
                    </td>

                    <td>
                        <div className="form-group text-lowercase">
                            <InputTypeComponent
                                className={" form-control"}
                                value={contact.email}
                                onChange={(e) => this.handleInputChange(e)}
                                name={"email"}
                                type={"text"}
                                showView={!contact.isEditing}
                                errors={errorsMapped.email}
                                validate={"email"}
                            />
                        </div>
                    </td>

                    <td>
                        <InputTypeComponent
                            className={" form-control"}
                            value={contact.position}
                            onChange={(e) => this.handleInputChange(e)}
                            name={"position"}
                            type={"text"}
                            showView={!contact.isEditing}
                            errors={errorsMapped.position}

                        />
                    </td>
                    {OFFER.mail_sales &&
                    <td>
                        <CheckboxUniqueComponent
                            onChange={(e) => this.handleInputChangeContactRole(e)}
                            checked={contact.contactRole === ContactModel.CODE_MAIL_SALES}
                            value={contact.contactRole}
                            name={"contactRole"}
                            withValue
                            editable={this.editable}
                            showView={!contact.isEditing}
                            readOnly={!contact.isEditing}
                            className={"approved"}
                            classGroup={"col-12"}
                        />
                    </td>
                    }
                    <td>
                        {this.renderEditBlock()}
                    </td>
                </tr>
            </InfoBlock>
        );
    }
}

export default ClientContact;
